import moment from "moment";
import { formatNumberVND } from "../../../../../utils/formatNumberVND";

export const columns = [
  {
    title: 'Ngày',
    dataIndex: 'ngay',
    key: 'ngay',
    width: 100,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
    
  },
  {
    title: 'Ngày khai trương',
    dataIndex: 'ngayKhaiTruong',
    key: 'ngayKhaiTruong',
    width: 150,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
    
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenChiNhanh',
    key: 'tenChiNhanh',
    width: 250,
  },
  {
    title: 'Thống kê',
    dataIndex: 'thongke',
    key: 'thongke',
    width: 100,
    children: [
      {
        title: 'Khám nhi',
        dataIndex: 'khamNhi',
        key: 'khamNhi',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text)
      },
      {
        title: 'Tiêm chủng',
        dataIndex: 'tiemChung',
        key: 'tiemChung',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text)
      },
      {
        title: 'Sản khoa',
        dataIndex: 'sanKhoa',
        key: 'sanKhoa',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text)
      },
      {
        title: 'Phụ khoa',
        dataIndex: 'phuKhoa',
        key: 'phuKhoa',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text)
      },
      {
        title: 'Đa khoa',
        dataIndex: 'daKhoa',
        key: 'daKhoa',
        align:'right',
        width: 100,
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Lão khoa',
        dataIndex: 'laoKhoa',
        key: 'laoKhoa',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'PHCN',
        dataIndex: 'phcn',
        key: 'phcn',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Nhãn khoa',
        dataIndex: 'nhanKhoa',
        key: 'nhanKhoa',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Khúc xạ',
        dataIndex: 'khucXa',
        key: 'khucXa',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Khí dung',
        dataIndex: 'khiDung',
        key: 'khiDung',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Thủ thuật',
        dataIndex: 'thuThuat',
        key: 'thuThuat',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Xét nghiệm',
        dataIndex: 'xetNghiem',
        key: 'xetNghiem',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Siêu âm',
        dataIndex: 'sieuAm',
        key: 'sieuAm',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Soi CTC',
        dataIndex: 'soiCTC',
        key: 'soiCTC',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Điện tim',
        dataIndex: 'dienTim',
        key: 'dienTim',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Monitor',
        dataIndex: 'monitor',
        key: 'monitor',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Nội soi TH',
        dataIndex: 'noiSoiTH',
        key: 'noiSoiTH',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Nôi soi TMH',
        dataIndex: 'noiSoiTMH',
        key: 'noiSoiTMH',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'X-Quang',
        dataIndex: 'xQuang',
        key: 'xQuang',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
      {
        title: 'Khác',
        dataIndex: 'khac',
        key: 'khac',
        width: 100,
        align:'right',
        render:(text) => formatNumberVND(text ?? 0)
      },
    ],
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'tongTien',
    key: 'tongTien',
    width: 100,
    fixed: 'right',
    align:'right',
    render:(text) => formatNumberVND(text ?? 0)
  },
]
