import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from 'antd'
import LayoutApp from '../../../HOCs/LayoutApp'
import TableTTV from './TableTTV/TableTTV'
import TablePhieuThu from './TablePhieuThu/TablePhieuThu'
import { getCacDanhMucSearchTTVAction } from '../../../store/actions/cardAction'
import { listBranchAction } from '../../../store/actions/BranchAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import TableTheHuy from './TableTheHuy/TableTheHuy'

const CardReceive = () => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(listBranchAction(id_company))
    dispatch(getCacDanhMucSearchTTVAction())
  }, [])
  return (
    <>
      <div className="bg-[#EBEBEB] h-full w-full p-2 ">
        {/* <div className='w-full '>
          <Static />
        </div> */}
        <div className="rounded-lg h-full px-2 border bg-white">
          <Tabs className="h-full">
            <Tabs.TabPane tab="Phiếu thu" key={1}>
              <TablePhieuThu />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Thẻ thành viên" key={2}>
              <TableTTV />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Thẻ hủy" key={3}>
              <TableTheHuy />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default CardReceive
