import { https } from '../apiService'

export const maternityClinicService = {
  getBNCho: (idcnPk) => https.get(`BenhNhanTTC/DanhSachChoKham?idCnPk=${idcnPk}`),
  getDetailBNKham: (idbnttc) => https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`),
  updateDetailBN: (form) => https.put('BenhNhanTTC', form),
  getLoiDan: () => https.get('LoiDanBacSi/GetAll'),
  getKetoaThuoc: (idChiNhanh) => https.get(`TonKho/KhoDuocByChiNhanh?IDChiNhanh=${idChiNhanh}`),
  postToaThuoc: (data) => https.post('BenhNhanThuoc/ThemBenhNhanThuoc', data),
  getBNDichVu: (idbnttc) => https.get(`BenhNhanDichVu/GetBenhNhanDichVu/${idbnttc}`),
  getDetailPhieuThu: (idbnttc) =>
    https.get(`BenhNhanThongTinChung/GetThongTinPhieuThu?idbnttc=${idbnttc}`),
  getBNDangKham: (idnv, idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanTTC/GetDanhSachBenhNhanDangkham?idbskham=${idnv}&idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBNDaKham: (idnv, idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanTTC/GetDanhSachBenhNhanDakham?idbskham=${idnv}&idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getChanDoan: (keyword) => https.get(`ChanDoan/SearchChanDoan?keyword=${keyword}`),
  getDetailToaThuocBNKham: (idbnttc) => https.get(`BenhNhanTTC/toa?id=${idbnttc}`),
  deleteBNThuocBSKhongKeToa: (idbnttc) =>
    https.delete(`BenhNhanThuoc/DeleteBNThuocAndBNPhieuthuByIDBNTTC?idbnttc=${idbnttc}`),
  putBNThuocBSKeToaLai: (idbnttc, list) =>
    https.put(`BenhNhanThuoc/UpdateBenNhanThuocForBacSi?idbnttc=${idbnttc}`, list),
  getListLoaiKham: () => https.get('BenhNhanSan/GetAllLoaiBenhNhanSan'),
  postDetailBNKhamSan: (form) => https.post('BenhNhanSan/AddBenhNhanSan', form),
  getDetailBNKhamSan: (idbnttc) =>
    https.get(`BenhNhanSan/GetBenhNhanSanByIDBNTTC?idbnttc=${idbnttc}`),
  getListLyDoKhongKeToa: () => https.get(`LyDoKoLayThuoc/GetAllLyDo_BacSi`),
  putLyDoKhongKeToaBNTTC: (idbnttc, idlido) =>
    https.put(`BenhNhanTTC/UpdateLiDoKhongLayThuoc?idbnttc=${idbnttc}&idlido=${idlido}`),
  putDetailBNKhamSan: (form) => https.put('BenhNhanSan/UpdateBenhNhanSanByIDBNTTC', form),
}
