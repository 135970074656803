import * as Yup from "yup";

export const maternityClinicSchema = Yup.object().shape({
    lydokham: Yup.string().required(),
    benhsu: Yup.string().required(),
    trieuchung: Yup.string().required(),
    loidan: Yup.string().required(),
    ngaytaikham: Yup.string().required(),
    // para: Yup.string().required(),
    lydokolaythuoc: Yup.string().required(),
})