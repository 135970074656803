import { Modal, Input, Select, Checkbox, message } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { AddUserSchema } from '../../../../schemas/AddUserSchema'
import { branchService } from '../../../../services/branch/branchService'
import moment from 'moment'
import { addUser, updateUser } from '../../../../store/actions/userAction'
import { https } from '../../../../services/apiService'
import 'moment/locale/vi'
moment.locale('vi')

const EditUser = ({ onReload, cancelEdit, isEdit, info }) => {
  // console.log("Info in EditUser:", info); // Kiểm tra dữ liệu được truyền vào từ User.js
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const [staff, setStaff] = useState([])
  const [branch, setBranch] = useState(null)
  const [role, setRole] = useState()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [tempIsLock, setTempIsLock] = useState(info?.isLock || 0)

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  //------ lấy dánh sách nhân viên ------------//
  const fetcchStaffs = async () => {
    try {
      const result = await nhanvienService.getStaffs()
      setStaff(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  //--------- lấy danh  sach vai trò  ------------//
  const fetchRule = async () => {
    try {
      const result = await nhanvienService.getRulerUser()
      setRole(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  const fetchBranch = async (id) => {
    try {
      const result = await branchService.getListBranch(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (value) => {
    const now = moment()
    value.nguoiSua = infoUser?.idnv
    value.ngaySua = now.format('YYYY-MM-DDTHH:mm:ss')
    value.isLock = tempIsLock // Cập nhật lại isLock từ tempIsLock
    try {
      const payload = [{ username: value.taiKhoan, islock: value.isLock }]
      const result = await https.put('NguoiDung/IsLock-User', payload)
      if (result.status === 200) {
        dispatch(updateUser(value, restStore))
        info.isLock = value.isLock // Cập nhật lại trạng thái isLock của info
      } else {
        message.error('Failed to update status')
      }
    } catch (error) {
      message.error('Failed to update status')
      console.error('Update status error:', error)
    }
  }

  const restStore = () => {
    cancelEdit()
    formik.resetForm()
    // onReload()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNguoiDung: info?.idNguoiDung || '',
      taiKhoan: info?.taiKhoan || '',
      // matKhau: null,
      // passwordConfirmation: null,
      idnv: info?.nhanVien.id || '',
      idChiNhanh: info?.idChiNhanh || '',
      // nguoiTao: user?.taiKhoan,
      // ngayTao: '',
      idNhom: info?.nhomNguoiDung.idNhom || '',
      IDCT: id_company, // set trạng thái mặc đình là 1 >> CTy y tế chấn văn
      isLock: info?.isLock,
      ipmac: info?.ipmac || '',
      tenNguoiSua: info?.tenNguoiSua || '',
    },
    initialErrors: {
      taiKhoan: '',
      ipmac: '',
    },
    validate: (values) => {
      const errors = {}
      if (!values.taiKhoan) {
        errors.taiKhoan = 'Tài khoản không được để trống!'
      }
      // if (!values.ipmac) {
      //   errors.ipmac = 'IP MAC không được để trống!'
      // }
      return errors
    },
    onSubmit: (value) => handleSubmit(value),
  })
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetcchStaffs()
    fetchRule()
  }, [])

  // Cập nhật tempIsLock khi info thay đổi
  useEffect(() => {
    setTempIsLock(info?.isLock || 0)
  }, [info])
  // Xử lý khi thay đổi trạng thái Api của checkbox "Hiệu lực"
  const handleLockChange = (e) => {
    const isLock = e.target.checked ? 1 : 0 // Đảo ngược giá trị isLock khi checkbox thay đổi
    setTempIsLock(isLock) // Cập nhật tempIsLock
    formik.setFieldValue('isLock', isLock) // Cập nhật formik.values.isLock
  }

  return (
    <Modal
      open={isEdit}
      onCancel={restStore}
      onOk={formik.handleSubmit}
      okText={'Cập nhật'}
      cancelText="Hủy"
      title="Cập nhật người dùng"
    >
      <div className="mt-8">
        <form className="flex flex-col gap-2">
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span> Tài khoản:
            </label>
            <Input
              name="taiKhoan"
              // readOnly
              // variant="filled"
              status={formik.errors.taiKhoan ? 'error' : ''}
              value={formik.values.taiKhoan}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.taiKhoan && <div className="text-red-500">{formik.errors.taiKhoan}</div>}
          </div>
          <div>
            <label className="font-semibold">Công ty:</label>
            <Select
              showSearch
              value={formik.values.IDCT}
              onChange={(value) => {
                fetchBranch(value)
                formik.setFieldValue('idChiNhanh', null)
                formik.setFieldValue('IDCT', value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">Chi nhánh:</label>
            <Select
              status={formik.errors.idChiNhanh ? 'error' : ''}
              value={formik.values.idChiNhanh}
              showSearch
              onChange={handleSelected('idChiNhanh')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={branch?.map((items) => ({
                label: items.tenChiNhanh,
                value: items.idChiNhanh,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold"> Vai trò:</label>
            <Select
              status={formik.errors.idNhom ? 'error' : ''}
              value={formik.values.idNhom}
              onChange={handleSelected('idNhom')}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={role?.map((items) => ({
                label: items.tenNhom,
                value: items.idNhom,
              }))}
              className="w-full"
            />
          </div>
          <div className="flex items-center gap-4">
            <label className="font-semibold">Hiệu lực:</label>
            <Checkbox
              checked={formik.values.isLock === 1} // Đảo ngược giá trị để checkbox phản ánh đúng trạng thái
              onChange={handleLockChange}
              className="mr-2"
            >
              {tempIsLock === 1 ? 'Đang hoạt động' : 'Đã vô hiệu hóa'}
            </Checkbox>
          </div>
          <div>
            <label className="font-semibold">IP MAC:</label>
            <Input
              name="ipmac"
              className="w-full"
              status={formik.errors.ipmac ? 'error' : ''}
              value={formik.values.ipmac}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* {formik.errors.ipmac && <div className="text-red-500">{formik.errors.ipmac}</div>} */}
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default EditUser
