import CongTy from '../layouts/CongTy/CongTy'
import GoiDichVu from '../layouts/GoiDichVu/GoiDichVu'
import GoiKhamKhachHang from '../layouts/GoiKhamKhachHang/GoiKhamKhachHang'
import KhachHang from '../layouts/KhachHang/KhachHang'
import LienHe from '../layouts/LienHe/LienHe'
import PhanCong from '../layouts/PhanCong/PhanCong'
import TraCuu from '../layouts/TraCuu/TraCuu'

//Tạo các route
export const routesCRM = [
  {
    path: '/CRM/congty',
    Component: CongTy,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/CRM/lienhe',
    Component: LienHe,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/CRM/khachhang',
    Component: KhachHang,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/CRM/goikham',
    Component: GoiDichVu,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/CRM/benhnhan',
    Component: TraCuu,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/CRM/phancong',
    Component: PhanCong,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/CRM/goikhamkhachhang',
    Component: GoiKhamKhachHang,
    isAuth: true,
    redirectPath: '/login',
  },
]
