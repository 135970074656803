import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { columns } from './Columns'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import * as typeAction from '../../../../store/constants/constants'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { exportExcelformatSumCol } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { reportService } from '../../../../services/Report/reportService'
import { branchService } from '../../../../services/branch/branchService'
import 'moment/locale/vi'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const BaoCaoVacXin = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  const fetchReport = async () => {
    try {
      setIsLoading(true)
      const { data } = await reportService.getBaoCaoVacXin(
        (value?.includes('all') ? branch : value)
          ?.map((item) => `idChiNhanhs=${value?.includes('all') ? item?.idChiNhanh : item}`)
          ?.join('&')
          ?.toString(),
        dateForm,
        dateTo,
      )
      setDataReport(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(id)
      setBranch(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const exportToExcel = () => {
    const formatData = dataReport?.map((item, index) => ({
      'STT ': ++index,
      'Chi nhánh': item?.chinhanh,
      'Mã hàng': item?.mahang,
      'Tên hàng': item?.tenhang,
      'Hoạt chất': item?.hoatchat,
      'Nhóm hàng': item?.nhomhang,
      'Đơn vị': item?.dvt,
      'Số lượng': item?.soluong,
      'Đơn giá': formattedNumber(item?.dongia),
      'Thành tiền': formattedNumber(item?.thanhtien),
      'Giảm %': item?.phantramgiam,
      'Giảm giá': formattedNumber(item?.giamgia),
    }))
    const name = 'BÁO CÁO VẮC XIN'
    if (formatData?.length)
      exportExcelformatSumCol(formatData, name, [
        { key: 'Số lượng', fields: ['Số lượng'] },
        { key: 'Đơn giá', fields: ['Đơn giá'] },
        { key: 'Thành tiền', fields: ['Thành tiền'] },
        { key: 'Giảm giá', fields: ['Giảm giá'] },
      ])
  }

  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    fetchReport()
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])
  return (
    <div className="p-2 bg-[#EFEFEF] h-full">
      <div className="p-3 bg-white h-full">
        <div className="flex gap-3 border-dotted border-b-0  p-2 ">
          <div className="w-[17rem] flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-[17rem]">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setValue('all')
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-[17rem] flex gap-2">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>

          <div className="flex gap-3">
            <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              disabled={isLoading || !dataReport?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            scroll={{
              x: 1500,
              y: 715,
            }}
            dataSource={dataReport}
            columns={columns}
            pagination={false} // Disable pagination
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8} className="text-right">
                    <span className="font-semibold">
                      {formatNumberVND(
                        dataReport?.reduce((total, item) => total + (item?.dongia ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={9}>
                    <span className="font-semibold">
                      {formatNumberVND(
                        dataReport?.reduce((total, item) => total + (item?.thanhtien ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={11}>
                    <span className="font-semibold">
                      {formatNumberVND(
                        dataReport?.reduce((total, item) => total + (item?.giamgia ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} className="text-right">
                    <span className="font-semibold">
                      {formatNumberVND(
                        dataReport?.reduce((total, item) => total + (item?.thanhtoan ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default BaoCaoVacXin
