import { Modal, Steps, Button, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";
import { Formik, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewBranch,
  huyenByQuanBranch,
  quanByCountryBranch,
  staffAndCountry,
} from "../../../../store/actions/BranchAction";
const DateFormat = "DD/MM/YYYY";
const ModalAddBranch = ({ isModal, setIsModal, onReload }) => {
  const { listCompany } = useSelector((state) => state.companyReducer);
  const { chuyenKhoa } = useSelector((state) => state.thuocVTReducer);
  const { Tinh, staffsBranch, Quan, Huyen } = useSelector(
    (state) => state.branchReducer
  );
  const [current, setCurrent] = useState(0);
  const toDay = moment();
  const dispatch = useDispatch();
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const ChangeSelected = (name) => (value) => {
    Formik.setFieldValue(name, value);
  };
  const handleDatePicker = (date, dateString) => {
    Formik.setFieldValue(
      "ngayKhaiTruong",
      dateString ? moment(dateString, DateFormat).format() : null
    );
  };
  const cancelModal = () => {
    setIsModal(false);
  };
  const resetForm = () => {
    cancelModal();
    Formik.handleReset();
    setCurrent(0)
    onReload()
  };
  const handleCountry = (value) => {
    dispatch(quanByCountryBranch(value));
    Formik.setFieldValue("idTinh", value);
    Formik.setFieldValue("idQuan", null);
    Formik.setFieldValue("idPhuong", null);
  };
  const handleQuan = (value) => {
    dispatch(huyenByQuanBranch(value));
    Formik.setFieldValue("idQuan", value);
    Formik.setFieldValue("idPhuong", null);
  };
  const handleSubmit = (value) => {
    dispatch(addNewBranch(value, resetForm));
  };
  const Formik = useFormik({
    initialValues: {
      maChiNhanh: null,
      tenChiNhanh: null,
      dienThoai: null,
      diaChi: null,
      idTinh: 46, // 46 data là HCM
      idQuan: null,
      idPhuong: null,
      idNhanVienQuanLy: null,
      idCongTy: 1,
      idChuyenKhoa: 1,
      ngayKhaiTruong: toDay.format(),
      latitude: 0,
      longitude: 0,
      linkmap: null,
      loaichinhanh: "CHINHANH",
      sudung: 1,
    },
    onSubmit: (value) => handleSubmit(value),
  });
  useEffect(() => {
    dispatch(staffAndCountry());
  }, []);
  const steps = [
    {
      title: "Thông tin",
      content: (
        <>
          <div className="mt-5">
            <div>
              <label className="font-semibold"><span className="text-red-500">(*)</span> Mã chi nhánh:</label>
              <Input
                value={Formik.values.maChiNhanh}
                name="maChiNhanh"
                onChange={Formik.handleChange}
              />
            </div>
            <div>
              <label className="font-semibold">Tên chi nhánh:</label>
              <Input
                value={Formik.values.tenChiNhanh}
                name="tenChiNhanh"
                onChange={Formik.handleChange}
              />
            </div>
            <div>
              <label className="font-semibold">Số điện thoại:</label>
              <Input
                value={Formik.values.dienThoai}
                name="dienThoai"
                onChange={Formik.handleChange}
              />
            </div>
            <div>
              <label className="font-semibold">Ngày khai trương:</label>
              <DatePicker
                allowClear={false}
                value={dayjs(Formik.values.ngayKhaiTruong, "YYYY-MM-DD")}
                format={DateFormat}
                onChange={handleDatePicker}
                className="w-full"
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Thuộc quản lý",
      content: (
        <>
          <div className="mt-5">
            <div>
              <label className="font-semibold">Công ty:</label>
              <Select
                onChange={ChangeSelected("idCongTy")}
                defaultValue={1}
                value={Formik.values.idCongTy}
                options={listCompany?.map((items) => ({
                  label: items.tenct,
                  value: items.idct,
                }))}
                className="w-full"
              />
            </div>
            <div>
              <label className="font-semibold">Loại chi nhánh:</label>
              <Select
                className="w-full"
                onChange={ChangeSelected("loaichinhanh")}
                value={Formik.values.loaichinhanh}
                defaultValue={"CHINHANH"}
                options={[
                  { label: "Chi nhánh", value: "CHINHANH" },
                  { label: "Văn phòng", value: "VANPHONG" },
                ]}
              />
            </div>
            <div>
              <label className="font-semibold">Chuyên khoa:</label>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                showSearch
                onChange={ChangeSelected("idChuyenKhoa")}
                value={Formik.values.idChuyenKhoa}
                defaultValue={1}
                options={chuyenKhoa?.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                }))}
                className="w-full"
              />
            </div>
            <div>
              <label className="font-semibold">Nhân viên quản lý:</label>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                className="w-full"
                showSearch
                onChange={ChangeSelected("idNhanVienQuanLy")}
                value={Formik.values.idNhanVienQuanLy}
                options={staffsBranch?.map((items) => ({
                  label: items.tennv,
                  value: items.id,
                }))}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Địa chỉ",
      content: (
        <>
          <div className="mt-5">
            <div>
              <label className="font-semibold">Tỉnh/TP:</label>
              <Select
                onChange={handleCountry}
                value={Formik.values.idTinh}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                className="w-full"
                showSearch
                options={Tinh?.map((items) => ({
                  label: items.tenTinh,
                  value: items.idTinh,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">Quận/Huyện:</label>
              <Select
                value={Formik.values.idQuan}
                onChange={handleQuan}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                className="w-full"
                showSearch
                options={Quan?.map((items) => ({
                  label: items.tenQuan,
                  value: items.idQuan,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">Phường/Xã:</label>
              <Select
                value={Formik.values.idPhuong}
                onChange={ChangeSelected("idPhuong")}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                className="w-full"
                showSearch
                options={Huyen?.map((items) => ({
                  label: items.tenPhuong,
                  value: items.idPhuong,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">Địa chỉ chi tiết:</label>
              <Input
                value={Formik.values.diaChi}
                name="diaChi"
                onChange={Formik.handleChange}
              />
            </div>
            <div>
              <label className="font-semibold">Link map:</label>
              <Input
                value={Formik.values.linkmap}
                name="linkmap"
                onChange={Formik.handleChange}
              />
            </div>
            <div className="flex gap-2">
              <div className="w-1/2">
                <label className="font-semibold">latitude:</label>
                <Input
                  type="Number"
                  value={Formik.values.latitude}
                  onChange={Formik.handleChange}
                  name="latitude"
                />
              </div>
              <div className="w-1/2">
                <label className="font-semibold">longitude:</label>
                <Input
                  type="Number"
                  name="longitude"
                  value={Formik.values.longitude}
                  onChange={Formik.handleChange}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <Modal
      footer={null}
      title={
        <h2 className="text-lg text-blue-500 font-semibold">Thêm chi nhánh</h2>
      }
      open={isModal}
      onCancel={cancelModal}
    >
      <form className="mt-5">
        <Steps current={current} items={steps} />
        <div>{steps[current].content}</div>
        <div className="flex justify-end"
          style={{
            marginTop: 24,
          }}
        >
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Trở lại
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Tiếp tục
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={Formik.handleSubmit}>
              Tạo
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ModalAddBranch;
