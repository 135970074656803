import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { addMenuSchema } from '../../../../schemas/addMenuSchema'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'

function ModalCreateMenu({ isModalOpen, setIsModalOpen, getAllMenu }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [loading, setLoading] = useState(false)
  const [listMenu, setListMenu] = useState([])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maMenu: '',
      tenMenu: '',
      tenVietTat: '',
      ghichu: '',
      links: '',
      idMenuCha: 0,
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addMenuSchema,
  })

  const handleCloseModal = () => {
    setIsModalOpen(false)
    formik.resetForm()
  }

  const handleSubmit = async (values, actions) => {
    setLoading(true)
    try {
      await https.post(`Menu/AddMenu`, { ...values, nguoiTao: infoUser.idnv })
      handleCloseModal()
      getAllMenu()
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm menu thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi thêm menu',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleMenuParentChange = (value) => {
    formik.setFieldValue('idMenuCha', value)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('Menu/GetAll')
        setListMenu([
          ...data.filter(items => items.idMenuCha === 0),
          {
            idMenu: 0,
            tenMenu: 'Mặc định',
          },
        ])
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lấy dữ liệu thất bại',
        })
      }
    })()
  }, [])

  return (
    <Modal
      width={650}
      className="text-center"
      title="Thêm menu"
      okText="Thêm"
      cancelText="Huỷ bỏ"
      open={isModalOpen}
      onOk={formik.handleSubmit}
      onCancel={handleCloseModal}
      maskClosable={false}
      confirmLoading={loading}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-3">
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Tên Menu:
            </label>
            <Input
              status={formik.errors.tenMenu ? 'error' : ''}
              name="tenMenu"
              value={formik.values.tenMenu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Mã Menu:
            </label>
            <Input
              status={formik.errors.maMenu ? 'error' : ''}
              name="maMenu"
              value={formik.values.maMenu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Tên viết tắt:</label>
            <Input
              status={formik.errors.tenVietTat ? 'error' : ''}
              name="tenVietTat"
              value={formik.values.tenVietTat}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Link:</label>
            <Input
              status={formik.errors.links ? 'error' : ''}
              name="links"
              value={formik.values.links}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Ghi chú:</label>
            <Input
              name="ghichu"
              value={formik.values.ghichu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end ">Menu cha:</label>
            <Select
              showSearch
              onChange={handleMenuParentChange}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              defaultValue={0}
              options={listMenu?.map((value) => ({
                label: value.tenMenu,
                value: value.idMenu,
              }))}
              className="w-full truncate"
              placeholder="Chọn menu cha"
              size="small"
              name="idMenuCha"
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

ModalCreateMenu.propTypes = {}

export default ModalCreateMenu
