export const trangThais = [
  {
    stt: 1,
    label: 'Gửi Profile (20%)',
    value: 'Gửi Profile (20%)',
    disabled: false,
  },
  {
    stt: 2,
    label: 'Gửi BBG (50%)',
    value: 'Gửi BBG (50%)',
    disabled: false,
  },
  {
    stt: 3,
    label: 'Thỏa thuận (70%)',
    value: 'Thỏa thuận (70%)',
    disabled: false,
  },
  {
    stt: 4,
    label: 'Kí hợp đồng (100%)',
    value: 'Kí hợp đồng (100%)',
    disabled: false,
  },
]
