import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Space,
  Table,
  Tabs,
  Segmented,
  Menu,
} from 'antd'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addVoucherSchema } from '../../../../schemas/addVoucherSchema'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { recieveService } from '../../../../services/receive/recieveService'
import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { cloneDeep, debounce } from 'lodash'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import * as XLSX from 'xlsx'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { getListDichVuAction } from '../../../../store/actions/detailCardAction'
import { listGroupsDichVuAction } from '../../../../store/actions/dichVuAction'
import TabDoiTuong from './../ModalAddVoucher/TabDoiTuong'
import TabChiNhanh from './../ModalAddVoucher/TabChiNhanh'
import TabKhungGio from './../ModalAddVoucher/TabKhungGio'
import TabTTV from './../ModalAddVoucher/TabTTV'
import TabHangHoa from './../ModalAddVoucher/TabHangHoa'
import TableVoucher from '../ModalEditVoucher/TableVoucher/TableVoucher'
import 'moment/locale/vi'
moment.locale('vi')

function ModalEditVoucher({ showEditModal, setShowEditModal, getAllChuongTrinh }) {
  const dispatch = useDispatch()
  const KB = 'Khám bệnh'
  const XN = 'Xét nghiệm'
  const CDHA = 'Chẩn đoán hình ảnh'
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { ListDichVu, groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { listDichVuTheoNhom } = useSelector((state) => state.detailCardReducer)
  const [valueSegmented, setValueSegmented] = useState(KB)
  const [listXNhoacCDHA, setListXNhoacCDHA] = useState([])
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const { infoUser } = useSelector((state) => state.userReducer)
  const [checkTaoMa, setCheckTaoMa] = useState(false)
  const [defaultListMa, setDefaultListMa] = useState([])
  const [loading, setLoading] = useState(false)
  const [allLoaiGiaTri, setallLoaiGiaTri] = useState([])
  const [listVoucher, setListVoucher] = useState([])
  const [listDVGiamGia, setListDVGiamGia] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filterActive, setFilterActive] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [dataInTableDV, setDataInTableDV] = useState([])
  const [loadingSearching, setLoadingSearching] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const searchInputMaVoucher = useRef(null)
  const [searchTextMaVoucher, setSearchTextMaVoucher] = useState('')
  const [searchedColumnMaVoucher, setSearchedColumnMaVoucher] = useState('')
  const [dataDoiTuong, setDataDoiTuong] = useState([])
  const [dataChiNhanh, setDataChiNhanh] = useState([])
  const [dataKhungGio, setDataKhungGio] = useState([])
  const [dataTTV, setDataTTV] = useState([])
  const [dataHangHoa, setDataHangHoa] = useState([])
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [idNhomDichVu, setIdNhomDichVu] = useState('1')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idct: showEditModal.data.idct ?? null,
      mact: showEditModal.data.mact ?? null,
      tenct: showEditModal.data.tenct ?? null,
      batdau: showEditModal.data.batdau ?? null,
      ketthuc: showEditModal.data.ketthuc ?? null,
      idttdt: showEditModal.data.idttdt ?? null,
      idttcn: showEditModal.data.idttcn ?? null,
      idtttg: showEditModal.data.idtttg ?? null, //thời gian
      idttgt: showEditModal.data.idttgt ?? null, //giới tính
      idcombo: showEditModal.data.idcombo ?? null,
      giatri: showEditModal.data.giatri ?? null,
      soluongma: showEditModal.data.soluongma ?? null,
      loaigiatri: showEditModal.data.loaigiatri ?? null,
      dodaima: showEditModal.data.dodaima ?? null,
      kytudau: showEditModal.data.kytudau ?? null,
      kytucuoi: showEditModal.data.kytucuoi ?? null,
      ghichu: showEditModal.data.ghichu ?? null,
      nguoitao: showEditModal.data.nguoitao ?? null,
      ngaytao: showEditModal.data.ngaytao ?? null,
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addVoucherSchema,
  })

  const debounceSearch = useCallback(
    debounce((keyword) => {
      ;(async () => {
        setLoadingSearching(true)
        try {
          const listKhoa = await https.get('ChuyenKhoa/AllChuyenKhoa')
          const resultSearch = await https.get(`DichVu/Mobile/SearchDichVu?keyword=${keyword}`)
          const formatData = listKhoa.data.map((item) => ({
            key: item.idChuyenKhoa,
            maDichVu: '',
            tenDichVu: `${item.maChuyenKhoa} - ${item.tenChuyenKhoa}`,
            donVi: '',
            donGia: '',
            children: resultSearch.data
              .filter((val) => val.idChuyenKhoa === item.idChuyenKhoa)
              .map((val) => ({
                // key: val.iddv,
                // maDichVu: val.maDichVu,
                // tenDichVu: val.tenDichVu,
                // donVi: val.donVi,
                // donGia: val.donGia,
                key: `${val.iddv}${val.maDichVu}`, //val.iddv,
                iddv: val.iddv,
                maDichVu: val.maDichVu,
                tenDichVu: val.tenDichVu,
                donVi: val.donVi,
                donGia: val.donGia,
              })),
          }))
          const allKeys = formatData.map((item) => item.key)
          setExpandedRowKeys(allKeys)
          //format data
          setDataInTableDV(formatData)
        } catch (error) {
          console.log(error)
        } finally {
          setLoadingSearching(false)
        }
      })()
    }, 500),
    [],
  )

  const fetchAllXetNghiem = async () => {
    try {
      setLoadingSearching(true)
      const { data } = await recieveService.getListXetNghiemTiepNhan(PkDangNhap?.idChiNhanh)
      setListXNhoacCDHA(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }
  const fetchAllCDHA = async () => {
    try {
      setLoadingSearching(true)
      const { data } = await recieveService.getListCDHATiepNhan(ID_COMPANY)
      setListXNhoacCDHA(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  const handleClickNhomDichVu = (e) => {
    setIdNhomDichVu(e.key)
    dispatch(getListDichVuAction(e.key, ID_COMPANY))
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (searchText) {
      confirm()
      setSearchText(searchText)
      debounceSearch(searchText)
      setFilterActive(true)
      return
    }
    ToastCus.fire({
      icon: 'error',
      title: 'Vui lòng nhập tên dịch vụ cần tìm',
    })
  }

  const handleCloseModal = () => {
    setShowEditModal(false)
    formik.resetForm()
  }

  const handleSubmit = async (values, actions) => {
    if (values.idttdt === 1 && dataDoiTuong?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn đối tượng được áp dụng!',
      })
      return
    }
    if (values.idttcn === 1 && dataChiNhanh?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chi nhánh được áp dụng!',
      })
      return
    }
    if (values.idtttg === 1 && dataKhungGio?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn khung giờ được áp dụng!',
      })
      return
    }
    setLoading(true)
    try {
      const dataFormat = listDVGiamGia.map((item) => ({
        iddv: item.iddv,
        idct: showEditModal.data.idct,
        dongia: item.donGia,
        dvt: item.donVi,
        tendv: item.tenDichVu,
        madichvu: item.maDichVu,
        loaivoucherdichvu: item.idloaidv, //1,// 1 - Dịch vụ, 2- Xét nghiệm, 3 - Chẩn đóan hình ảnh
        nguoitao: infoUser?.tenNV,
      }))
      if (values.idttdt === 1 && dataDoiTuong.length > 0) {
        const dataFormatDT = dataDoiTuong?.map((item) => ({
          idct: showEditModal.data.idct,
          iddt: item?.iddt,
          tendoituong: item?.tendoituong,
          nguoitao: infoUser?.tenNV,
        }))
        await https.put(
          `VoucherDoiTuong/UpdateVoucherDoiTuong?idct=${showEditModal.data.idct}`,
          dataFormatDT,
        )
      }
      if (values.idttcn === 1 && dataChiNhanh.length > 0) {
        const dataFormatCN = dataChiNhanh?.map((item) => ({
          idct: showEditModal.data.idct,
          idcn: item?.idcn,
          tenchinhanh: item?.tenChiNhanh,
          nguoitao: infoUser?.tenNV,
        }))
        await https.put(
          `VoucherChiNhanh/UpdateVoucherChiNhanh?idct=${showEditModal.data.idct}`,
          dataFormatCN,
        )
      }
      await https.put(`VoucherDichVu/UpdateVoucherDichVu/${showEditModal.data.idct}`, dataFormat)
      await https.put(
        `VoucherTheThanhVien/UpdateVoucherTheThanhVien/${showEditModal.data.idct}`,
        dataTTV?.map((item) => ({ ...item, tenloaithe: item?.loaithe, dongia: item?.tiensudung })),
      )
      await https.put(
        `VoucherHangHoa/UpdateVoucherHangHoa?idct=${showEditModal.data.idct}`,
        dataHangHoa?.map((item) => ({ ...item, tenthuoc: item?.tenbietduoc })),
      )
      await https.put(
        `VoucherThoiGian/UpdateVoucherThoiGian?idct=${showEditModal.data.idct}`,
        dataKhungGio?.map((item) => ({
          idct: showEditModal.data.idct,
          tugio: item?.tugio,
          dengio: item?.dengio,
          nguoitao: infoUser?.tenNV,
        })),
      )
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa chương trình thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi cập nhật chương trình & voucher',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`Voucher/GetVoucherLoaiGiaTri`)
        setallLoaiGiaTri(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`VoucherHangHoa/GetVoucherHangHoaChuongTrinh`, {
          params: { idchuongtrinh: showEditModal.data.idct },
        })
        setDataHangHoa(data?.map((item) => ({ ...item, tenbietduoc: item?.tenthuoc })))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(
          `VoucherTheThanhVien/GetVoucherTheThanhVien/${showEditModal.data.idct}`,
        )
        setDataTTV(
          data?.map((item) => ({
            ...item,
            loaithe: item?.tenloaithe,
            tiensudung: item?.dongia,
          })),
        )
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(
          `VoucherDichVu/GetVoucherDichVu/${showEditModal.data.idct}`,
        )
        setListDVGiamGia(
          data.map((item) => ({
            key: `${item.iddv}${item.madichvu}`, //val.iddv,
            iddv: item.iddv,
            maDichVu: item.madichvu,
            tenDichVu: item.tendichvu,
            donVi: item.dvt,
            donGia: item.dongia,
            idloaidv: item.loaivoucherdichvu,
            // iddv: item.iddv,
            // key: item.iddv,
            // maDichVu: item.madichvu,
            // tenDichVu: item.tendichvu,
            // donVi: item.dvt,
            // donGia: item.dongia,
          })),
        )
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`VoucherDoiTuong/GetVoucherDoiTuong`, {
          params: { idct: showEditModal.data.idct },
        })
        const formatData = data.map((item, index) => ({
          key: `` + item.iddt + item.tendoituong,
          iddt: item.iddt,
          madt: item.iddt,
          tendoituong: item.tendoituong,
          info: item,
        }))
        setDataDoiTuong(formatData)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`VoucherChiNhanh/GetVoucherChiNhanh`, {
          params: { idct: showEditModal.data.idct },
        })
        const formatData = data.map((item, index) => ({
          key: '' + item?.idcn + item?.tenchinhanh,
          idcn: item?.idcn,
          // idCongTy: idct,
          maChiNhanh: item?.machinhanh,
          tenChiNhanh: item?.tenchinhanh,
          // donVi: val.donVi,
          chuyenKhoa: item?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim(),
          info: item,
        }))
        setDataChiNhanh(formatData)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`VoucherThoiGian/GetVoucherThoiGian`, {
          params: { idct: showEditModal.data.idct },
        })
        setDataKhungGio(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoadingTable(true)
      try {
        const { data } = await https.get(
          `VoucherDanhSach/GetAllListVoucher/${showEditModal.data.idct}`,
        )
        setDefaultListMa(data)
        setListVoucher(data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingTable(false)
      }
    })()
  }, [])

  useEffect(() => {
    getAllChuyenKhoa()
    dispatch(listGroupsDichVuAction())
    dispatch(getListDichVuAction(idNhomDichVu, PkDangNhap?.idCongTy))
  }, [])
  useEffect(() => {
    if (valueSegmented === XN) fetchAllXetNghiem()
    if (valueSegmented === CDHA) fetchAllCDHA()
  }, [valueSegmented])

  const getAllChuyenKhoa = async () => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      //format data
      const formatData = data.map((item) => ({
        key: item.idChuyenKhoa, //`${item.idChuyenKhoa}${item.maChuyenKhoa}`,//''+item.idChuyenKhoa+item.maChuyenKhoa,
        maDichVu: '',
        tenDichVu: `${item.maChuyenKhoa} - ${item.tenChuyenKhoa}`,
        donVi: '',
        donGia: '',
        children: [],
      }))
      setDataInTableDV(formatData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  const handleAddDichVu = (record) => {
    // const idx = listDVGiamGia.findIndex((item) => +item.key === +record.key)
    // if (idx !== -1) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Dịch vụ đã được chọn',
    //   })
    //   return
    // }
    // //add record
    // setListDVGiamGia((prev) => [
    //   ...prev,
    //   {
    //     ...record,
    //     iddv: record.key,
    //   },
    // ])
    let idloaidvtemp =
      (valueSegmented === KB && 1) || (valueSegmented === XN && 2) || (valueSegmented === CDHA && 3)
    const idx = listDVGiamGia.findIndex(
      (item) =>
        (item.key === record.key || item.iddv === record.iddv) && item.idloaidv === idloaidvtemp,
    )
    if (idx !== -1) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ đã được chọn',
      })
      return
    }
    setListDVGiamGia((prev) => [
      ...prev,
      {
        ...record,
        // iddv: record.key,
        idloaidv: idloaidvtemp,
      },
    ])
  }

  const handleLoaiGiaTriChange = (value) => {
    formik.setFieldValue('loaigiatri', value)
  }

  const handleDeleteInTable = (record) => {
    setListDVGiamGia((prev) => prev.filter((item) => item.key !== record.key))
  }

  const exportToExcel = () => {
    // Custom column names
    const headers = [
      'Mã thẻ',
      'Giá trị',
      'Người tạo',
      'Ngày tạo',
      'Người kích hoạt',
      'Ngày kích hoạt',
      'Trạng thái',
    ]
    const formattedData = listVoucher.map((item) => ({
      'Mã thẻ': item.mavoucher || '',
      'Giá trị': item.giatri || '',
      'Người tạo': item.nguoitao || '',
      'Ngày tạo': item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm') : '',
      'Người kích hoạt': item.nguoikichhoat || '',
      'Ngày kích hoạt': item.ngaykichhoat
        ? moment(item.ngaykichhoat).format('DD/MM/YYYY HH:mm')
        : '',
      'Trạng thái':
        item.trangthai === 1 ? ' Mới tạo' : item.trangthai === 2 ? 'Chưa sử dụng' : 'Đã sử dụng',
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, `Danh sách voucher - ${showEditModal.data.tenct}.xlsx`)
  }

  const handleReset = () => {
    setListVoucher(defaultListMa)
  }

  const handleExpand = (expanded, record) => {
    if (searchText) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key])
      getDichVuByIdNhom(record.key)
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key))
    }
  }

  const getDichVuByIdNhom = async (idNhom) => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get(`DichVu/Mobile/GetDichVuByIdChuyenKhoa/${idNhom}`)
      //set data in table
      const newDataInTable = cloneDeep(dataInTableDV)
      const idx = newDataInTable.findIndex((item) => item.key === idNhom)
      newDataInTable[idx].children = data.map((val) => ({
        // key: val.iddv,
        // maDichVu: val.maDichVu,
        // tenDichVu: val.tenDichVu,
        // donVi: val.donVi,
        // donGia: val.donGia,
        key: `${val.iddv}${val.maDichVu}`, //val.iddv,
        iddv: val.iddv,
        maDichVu: val.maDichVu,
        tenDichVu: val.tenDichVu,
        donVi: val.donVi,
        donGia: val.donGia,
      }))
      setDataInTableDV(newDataInTable)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  const handleSearchMaVoucher = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchTextMaVoucher(selectedKeys[0])
    setSearchedColumnMaVoucher(dataIndex)
  }
  const handleResetMaVoucher = (clearFilters) => {
    clearFilters()
    setSearchTextMaVoucher('')
  }

  const getColumnSearchPropsMaVoucher = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInputMaVoucher}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchMaVoucher(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchMaVoucher(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleResetMaVoucher(clearFilters)
              handleSearchMaVoucher('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputMaVoucher.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumnMaVoucher === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchTextMaVoucher]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columnsDichVuChoose = [
    {
      title: <p className="text-center">Mã dịch vụ</p>,
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      align: 'left',
      className: 'text-sm',
      width: 200,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      align: 'left',
      className: 'text-sm',
    },
    // {
    //   title: 'Mã dịch vụ',
    //   dataIndex: 'maDichVu',
    //   key: 'maDichVu',
    //   align: 'center',
    //   className: 'text-sm',
    // },
    // {
    //   title: 'Tên dịch vụ',
    //   dataIndex: 'tenDichVu',
    //   key: 'tenDichVu',
    //   align: 'left',
    //   className: 'text-sm',
    // },
    {
      title: 'ĐVT',
      dataIndex: 'donVi',
      key: 'donVi',
      align: 'center',
      className: 'text-sm',
      width: 55,
    },
    {
      title: <p className="text-center">Đơn giá</p>,
      dataIndex: 'donGia',
      key: 'donGia',
      className: 'text-sm',
      align: 'right',
      render: (text, record) => {
        return record.donGia ? formatNumberVND(record.donGia) : ''
      },
      width: 100,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      className: 'text-sm',
    },
    // {
    //   title: 'Đơn vị tính',
    //   dataIndex: 'donVi',
    //   key: 'donVi',
    //   align: 'center',
    //   className: 'text-sm',
    // },
    // {
    //   title: 'Đơn giá',
    //   dataIndex: 'donGia',
    //   key: 'donGia',
    //   className: 'text-sm',
    //   align: 'right',
    //   render: (text, record) => {
    //     return formatNumberVND(record.donGia)
    //   },
    // },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 40,
    //   className: 'text-sm',
    // },
  ]
  const columnsXNhoacCDHA = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      width: 200,
      ...getColumnSearchPropsMaVoucher('maDichVu'),
      sorter: {
        compare: (a, b) => {
          return (a?.maDichVu ?? '').localeCompare(b?.maDichVu ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      sorter: {
        compare: (a, b) => {
          return (a?.tenDichVu ?? '').localeCompare(b?.tenDichVu ?? '')
        },
      },
      ...getColumnSearchPropsMaVoucher('tenDichVu'),
    },
    {
      title: <p className="text-sm">Nhóm</p>,
      dataIndex: 'tennhom',
      key: 'tennhom',
      width: 140,
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tennhom ?? '').localeCompare(b?.tennhom ?? '')
        },
      },
      className: 'text-xs',
      render: (text, record, index) =>
        (text ?? '')?.toLowerCase()?.replace('xét nghiệm', '')?.trim().toUpperCase(),
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return Number(a?.price ?? 0) - Number(b?.price ?? 0)
        },
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) => (
        <PlusOutlined
          onClick={() => handleAddDichVu(record)}
          className="text-xl text-blue-500 cursor-pointer"
        />
      ),
    },
  ]
  const columnsDichVu = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'madv',
      key: 'madv',
      width: 150,
      ...getColumnSearchPropsMaVoucher('madv'),
      sorter: {
        compare: (a, b) => {
          return (a?.madv ?? '').localeCompare(b?.madv ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendv',
      key: 'tendv',
      ...getColumnSearchPropsMaVoucher('tendv'),
      sorter: {
        compare: (a, b) => {
          return (a?.tendv ?? '').localeCompare(b?.tendv ?? '')
        },
      },
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) => (
        <PlusOutlined
          onClick={() => handleAddDichVu(record)}
          className="text-xl text-blue-500 cursor-pointer"
        />
      ),
    },
  ]
  const columnsVoucher = [
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 25,
    //   align: 'center',
    // },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      align: 'center',
      key: 'mavoucher',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchPropsMaVoucher('mavoucher'),
      width: 120,

      // title: 'Mã thẻ',
      // dataIndex: 'mavoucher',
      // align: 'center',
      // key: 'mavoucher',
      // align: 'center',
      // className: 'text-sm',
      // ...getColumnSearchPropsMaVoucher('mavoucher'),
    },
    {
      title: (
        <p className="text-center">Giá trị {formik?.values?.loaigiatri === 2 ? `(%)` : `(VNĐ)`}</p>
      ),
      dataIndex: 'giatri',
      key: 'giatri',
      align: 'center',
      width: 105,

      // title: 'Giá trị',
      // dataIndex: 'giatri',
      // key: 'giatri',
      // align: 'center',
      // className: 'text-sm',
    },
    {
      title: 'Ngày hiệu lực',
      dataIndex: 'ngayhieuluc',
      key: 'ngayhieuluc',
      align: 'center',
      width: 100,
    },
    {
      title: 'TGHL (Tháng)',
      dataIndex: 'thoigianhieuluc',
      key: 'thoigianhieuluc',
      align: 'center',
      width: 100,
    },
    {
      title: 'Người kích hoạt',
      dataIndex: 'nguoikichhoat',
      key: 'nguoikichhoat',
      width: 220,
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'ngaykichhoat',
      key: 'ngaykichhoat',
      align: 'center',
      width: 140,
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      align: 'center',
      width: 140,
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,

      // title: 'Người tạo',
      // dataIndex: 'nguoitao',
      // key: 'nguoitao',
      // className: 'text-sm',
      // width: 200,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      align: 'center',
      width: 140,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      key: 'trangthai',
      align: 'center',
      width: 105,
      fixed: 'right',
      render: (text) => (text === 'Đã sử dụng' ? <p className="text-green-500">{text}</p> : text),
    },
    // {
    //   title: 'Trạng thái',
    //   dataIndex: 'trangthai',
    //   key: 'trangthai',
    //   className: 'text-sm',
    //   render: (text) => (text === 'Đã sử dụng' ? <p className="text-green-500">{text}</p> : text),
    // },
  ]

  const optTuyChon = [
    {
      key: 0,
      value: 0,
      label: 'Tất cả',
    },
    {
      key: 1,
      value: 1,
      label: 'Tùy chỉnh',
    },
  ]
  return (
    <Modal
      style={{
        top: 20,
      }}
      width={950}
      className="text-center"
      title={`Chi tiết chương trình ${(formik.values.idcombo === 0 && 'voucher') || (formik.values.idcombo === 1 && 'combo') || (formik.values.idcombo === 2 && 'mã đối tác') || (formik.values.idcombo === 3 && 'giảm giá')}`}
      okText="Xác nhận"
      cancelText="Đóng"
      open={showEditModal.show}
      onCancel={handleCloseModal}
      onOk={formik.handleSubmit}
      maskClosable={false}
      confirmLoading={loading}
    >
      <form className="text-start mt-5">
        <div
          className={`grid ${formik.values.idcombo !== 2 ? 'grid-rows-5' : 'grid-rows-2'} gap-1`}
        >
          <div className="grid grid-cols-6">
            <div className="font-semibold w-full text-end pr-1">
              <span className="text-red-500 text-xs">(*) </span>Tên chương trình:
            </div>
            <div className="col-span-3">
              <Input
                // status={formik.errors.tenct ? 'error' : ''}
                // name="tenct"
                // onChange={formik.handleChange}
                readOnly
                variant="filled"
                value={formik.values.tenct}
                size="small"
              />
            </div>
            <div className="font-semibold w-full text-end pr-1">
              <span className="text-red-500 text-xs">(*) </span>Mã chương trình:
            </div>
            <div>
              <Input
                // status={formik.errors.mact ? 'error' : ''}
                // name="mact"
                // value={formik.values.mact}
                // onChange={formik.handleChange}
                // size="small"
                // readOnly
                readOnly
                variant="filled"
                value={formik.values.mact}
                size="small"
              />
            </div>
          </div>
          <div className="grid grid-cols-6">
            <div className="font-semibold w-full text-end pr-1">
              {/* <span className="text-red-500 text-xs">(*) </span> */}
              Ghi chú:
            </div>
            <div className="col-span-3">
              <Input
                // name="ghichu"
                // value={formik.values.ghichu}
                // onChange={formik.handleChange}
                // size="small"
                readOnly
                variant="filled"
                value={formik.values.ghichu}
                size="small"
              />
            </div>
            {formik.values.idcombo !== 2 ? (
              <>
                <div className="font-semibold w-full text-end pr-1">Đối tượng:</div>
                <div>
                  <Input
                    // name="ghichu"
                    // value={formik.values.ghichu}
                    // onChange={formik.handleChange}
                    // size="small"
                    readOnly
                    variant="filled"
                    // {formik.values.loaigiatri === 1 && `VNĐ` || formik.values.loaigiatri === 2 && `%`}
                    value={(formik.values.idttdt === 1 && `Tùy chỉnh`) || `Tất cả`}
                    size="small"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="font-semibold w-full text-end pr-1">Loại chương trình:</div>
                <div>
                  <Input readOnly variant="filled" value={`Mã đối tác`} size="small" />
                </div>
              </>
            )}
          </div>
          {formik.values.idcombo !== 2 && (
            <>
              <div className="grid grid-cols-6">
                <div className="font-semibold w-full text-end pr-1">
                  <span className="text-red-500 text-xs">(*) </span>Bắt đầu:
                </div>
                <div>
                  <Input
                    // name="ghichu"
                    // value={formik.values.ghichu}
                    // onChange={formik.handleChange}
                    // size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.batdau && moment(formik.values.batdau).format(dateFormat)}
                    size="small"
                  />
                </div>
                <div className="font-semibold w-full text-end pr-1">
                  <span className="text-red-500 text-xs">(*) </span>Kết thúc:
                </div>
                <div>
                  <Input
                    // name="ghichu"
                    // value={formik.values.ghichu}
                    // onChange={formik.handleChange}
                    // size="small"
                    readOnly
                    variant="filled"
                    value={
                      formik.values.ketthuc && moment(formik.values.ketthuc).format(dateFormat)
                    }
                    size="small"
                  />
                </div>
                <div className="font-semibold w-full text-end pr-1">Chi nhánh:</div>
                <div>
                  <Input
                    // name="ghichu"
                    // value={formik.values.ghichu}
                    // onChange={formik.handleChange}
                    // size="small"
                    readOnly
                    variant="filled"
                    // value={formik.values.idttcn}
                    value={(formik.values.idttcn === 1 && `Tùy chỉnh`) || `Tất cả`}
                    size="small"
                  />
                </div>
              </div>

              {formik.values.idcombo === 0 && (
                <>
                  <div className="grid grid-cols-6">
                    <div className="font-semibold w-full text-end pr-1">Kí tự đầu:</div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formik.values.kytudau}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">Kí tự cuối:</div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formik.values.kytucuoi}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      {/* <span className="text-red-500 text-xs">(*) </span> */}
                      Loại giá trị:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={
                          (formik.values.loaigiatri === 1 && 'Thành tiền') ||
                          (formik.values.loaigiatri === 2 && 'Phần trăm')
                        }
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-6">
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>
                      Số lượng mã:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formik.values.soluongma}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>
                      Độ dài mã:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formik.values.dodaima}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>Giá trị:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formatNumberVND(formik.values.giatri ?? 0)}
                        size="small"
                      />
                    </div>
                  </div>
                </>
              )}
              {(formik.values.idcombo === 1 || formik.values.idcombo === 3) && (
                <>
                  <div className="grid grid-cols-6">
                    <div className="font-semibold w-full text-end pr-1">
                      {/* <span className="text-red-500 text-xs">(*) </span> */}
                      Loại chương trình:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={
                          formik.values.idcombo === 3
                            ? 'Giảm giá'
                            : formik.values.idcombo === 2
                              ? 'Mã đối tác'
                              : formik.values.idcombo === 1
                                ? 'Combo'
                                : 'Voucher'
                        }
                        //value={formik.values.idcombo === 1 ? 'Combo' : 'Voucher'}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      {/* <span className="text-red-500 text-xs">(*) </span> */}
                      Loại giá trị:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formik.values.loaigiatri === 2 ? 'Phần trăm' : 'Thành tiền'}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>Giá trị:
                    </div>
                    <div>
                      <Input
                        // name="ghichu"
                        // value={formik.values.ghichu}
                        // onChange={formik.handleChange}
                        // size="small"
                        readOnly
                        variant="filled"
                        value={formatNumberVND(formik.values.giatri ?? 0)}
                        size="small"
                      />
                    </div>
                  </div>
                </>
              )}
              {formik.values.idcombo === 0 && (
                <>
                  <div className="grid grid-cols-6">
                    <div className="font-semibold w-full text-end pr-1">Khung giờ:</div>
                    <div>
                      <Input
                        readOnly
                        variant="filled"
                        value={(formik.values.idtttg === 1 && `Tùy chỉnh`) || `Tất cả`}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">Giới tính:</div>
                    <div>
                      <Input
                        readOnly
                        variant="filled"
                        value={
                          (formik.values.idttgt === 1 && `Nam`) ||
                          (formik.values.idttgt === 2 && `Nữ`) ||
                          (formik.values.idttgt === 3 && `Khác`) ||
                          `Tất cả`
                        }
                        size="small"
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </form>
      <Tabs
        className="h-full"
        defaultActiveKey="1"
        items={[
          (formik.values.idcombo === 0 || formik.values.idcombo === 2) && {
            label: 'Voucher',
            key: '1',
            children: (
              <div className="">
                <div className="flex justify-start gap-2 mb-2">
                  {/* <Button onClick={handleReset}>Reset</Button> */}
                  <Button className="text-green-700 font-semibold" onClick={exportToExcel}>
                    Xuất
                  </Button>
                </div>
                <TableVoucher
                  listVoucher={listVoucher}
                  columnsVoucher={columnsVoucher}
                  loadingTable={loadingTable}
                />
              </div>
            ),
          },
          formik.values.idcombo !== 2 && {
            label: 'Dịch vụ',
            key: '2',
            children: (
              <div>
                <div className="overflow-auto h-[265px]">
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                    }}
                  >
                    <Table
                      // loading={loadingTable}
                      scroll={{ y: 180 }}
                      bordered
                      pagination={false}
                      // pagination={{
                      //   pageSize: 5,
                      //   showSizeChanger: false,
                      // }}
                      columns={columnsDichVuChoose}
                      dataSource={
                        listDVGiamGia.map((item) => ({
                          ...item,
                          action: (
                            <ul className="flex gap-2 justify-around">
                              <li className="text-lg text-red-500">
                                <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                              </li>
                            </ul>
                          ),
                        })) || []
                      }
                    />
                  </ConfigProvider>
                </div>
                <div>
                  <Segmented
                    onChange={(value) => setValueSegmented(value)}
                    options={[KB, XN, CDHA]}
                    className="mb-3"
                    block
                  />
                  <div className="flex">
                    {valueSegmented === KB && (
                      <>
                        <div className="w-1/4 max-h-[13.4rem] overflow-auto text-start">
                          <Menu
                            onClick={handleClickNhomDichVu}
                            defaultSelectedKeys={[idNhomDichVu]}
                            mode="inline"
                            items={groupsDichVu?.map((item) => ({
                              label: item?.tenNhom,
                              key: item?.idNhom,
                            }))}
                          />
                        </div>
                      </>
                    )}
                    <div className={`overflow-auto h-[280px] ${valueSegmented === KB && 'w-3/4'}`}>
                      <ConfigProvider
                        theme={{
                          token: {
                            padding: 5,
                          },
                        }}
                      >
                        <>
                          <Table
                            scroll={{ y: 180 }}
                            loading={loadingSearching}
                            bordered
                            pagination={false}
                            columns={valueSegmented === KB ? columnsDichVu : columnsXNhoacCDHA}
                            dataSource={
                              valueSegmented === KB
                                ? listDichVuTheoNhom?.map((item) => ({
                                    key: `${item?.iddv}${item?.maDichVu}`, //``item?.iddv,
                                    madv: item?.maDichVu,
                                    tendv: item?.tenDichVu,
                                    price: formatNumberVND(item?.donGia ?? 0),
                                    dongia: item?.donGia ?? 0,

                                    iddv: item?.iddv,
                                    maDichVu: item?.maDichVu,
                                    tenDichVu: item?.tenDichVu,
                                    donVi: item?.donVi,
                                    donGia: item?.donGia ?? 0,

                                    // itemCD: item,
                                    // action: (
                                    //   <PlusOutlined
                                    //     onClick={() => {
                                    //       console.log(item)
                                    //       //handleClickChonDichVu(item, 1) // Loại thẻ dịch vụ : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                                    //     }}
                                    //     className="text-xl text-blue-500 cursor-pointer"
                                    //   />
                                    // ),
                                  }))
                                : listXNhoacCDHA?.map((item) => ({
                                    key: `${item?.xetNghiem?.idxn ?? item?.idChiDinh}${item?.xetNghiem?.maxn ?? item?.macdha}`,
                                    iddv: item?.xetNghiem?.idxn ?? item?.idChiDinh,
                                    maDichVu: item?.xetNghiem?.maxn ?? item?.macdha,
                                    tenDichVu: item?.xetNghiem?.tenxn ?? item?.tenChiDinh,
                                    donVi: item?.xetNghiem?.dvt ?? item?.dvt, //'',
                                    donGia: item?.xetNghiem?.dongia ?? item?.donGia ?? 0,
                                    tennhom: (item?.tenNhom ?? item?.tennhom ?? '')
                                      ?.toLowerCase()
                                      ?.replace('xét nghiệm', '')
                                      ?.trim()
                                      .toUpperCase(),
                                    price: formatNumberVND(
                                      item?.xetNghiem?.dongia ?? item?.donGia ?? 0,
                                    ),
                                    // itemCD: item,
                                  }))
                            }
                          />
                        </>
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
          {
            label: 'Thẻ thành viên',
            key: '3',
            children: (
              <div className="h-full">
                <TabTTV dataTTV={dataTTV} setDataTTV={setDataTTV} />
              </div>
            ),
          },
          {
            label: 'Hàng hóa',
            key: '4',
            children: (
              <div className="h-full">
                <TabHangHoa dataHangHoa={dataHangHoa} setDataHangHoa={setDataHangHoa} />
              </div>
            ),
          },
          formik.values.idttdt === 1 && {
            label: 'Đối tượng',
            key: '5',
            children: (
              <div className="h-full">
                <TabDoiTuong dataDoiTuong={dataDoiTuong} setDataDoiTuong={setDataDoiTuong} />
              </div>
            ),
          },
          formik.values.idttcn === 1 && {
            label: 'Chi nhánh',
            key: '6',
            children: (
              <div className="h-full">
                <TabChiNhanh dataChiNhanh={dataChiNhanh} setDataChiNhanh={setDataChiNhanh} />
              </div>
            ),
          },
          formik.values.idtttg === 1 && {
            label: 'Khung giờ',
            key: '7',
            children: (
              <div className="h-full">
                <TabKhungGio dataKhungGio={dataKhungGio} setDataKhungGio={setDataKhungGio} />
              </div>
            ),
          },
        ]}
      />
    </Modal>
  )
}

ModalEditVoucher.propTypes = {}

export default ModalEditVoucher
