import { Card, Tag } from 'antd'
import React from 'react'

const Cards = ({ props, handelModal }) => {
  return (
    <>
      <li
        onClick={() => {
          handelModal(props.type)
        }}
        className=" cursor-pointer hover:-translate-y-2 duration-200"
      >
        <Card
          title={
            <span className="flex justify-between ">
              {' '}
              {props.title}{' '}
              <Tag color={props.status ? 'success' : 'processing'}>
                {' '}
                {props.status ? 'Đã chạy' : 'sắp ra mắt'}
              </Tag>{' '}
            </span>
          }
          bordered={false}
        >
          <p className="h-16">{props.description}</p>
        </Card>
      </li>
    </>
  )
}

export default Cards
