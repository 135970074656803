import * as typeAction from '../constants/constants'

export const getBNChoKhamMatAction = (idcnpk) => ({
  type: typeAction.GET_BN_CHO_KHAM_MAT,
  idcnpk,
})
export const getBNChoDoKhucXaAction = (idcn) => ({
  type: typeAction.GET_BN_CHO_DKX,
  idcn,
})
export const getBNDaDoKhucXaAction = (idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_BN_DA_DKX,
  idcn,
  tuNgay,
  denNgay,
})
export const getInfoBNDoKhucXaAction = (idbnttc) => ({
  type: typeAction.GET_INFO_BN_DKX,
  idbnttc,
})
export const postInfoBNDoKhucXaAction = (form, listdv, idcn, tungay, denngay) => ({
  type: typeAction.POST_INFO_BN_DKX,
  form,
  listdv,
  idcn,
  tungay,
  denngay,
})
export const putInfoBNDoKhucXaAction = (form, listdv, idcn, tungay, denngay, resetForm) => ({
  type: typeAction.PUT_INFO_BN_DKX,
  form,
  listdv,
  idcn,
  tungay,
  denngay,
  resetForm,
})
export const getListTrongVaGongKinhAction = (idnhomdv, idcn) => ({
  type: typeAction.GET_LIST_TRONG_VA_GONG_KINH_DKX,
  idnhomdv,
  idcn,
})
export const postInfoBNTrongVaGongKinhAction = (idbnttc, listTK, listGK, isKhamMat) => ({
  type: typeAction.POST_LIST_TRONG_VA_GONG_KINH_DKX,
  idbnttc,
  listTK,
  listGK,
  isKhamMat,
})
