import { Button, DatePicker, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import SyncIcon from '@mui/icons-material/Sync'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { companyService } from '../../../../services/Company/companyService'
import { Option } from 'antd/es/mentions'
import { https } from '../../../../services/apiService'
import { IconButton } from '@mui/material'
import DrawerCaTrong from './DrawerCaTrong'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
import { BorderOutlined, FolderViewOutlined } from '@ant-design/icons'
moment.locale('vi')

dayjs.extend(isoWeek)

const getDaysOfWeek = (startDate) => {
  const daysOfWeek = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
  const days = []

  for (let i = 0; i < 7; i++) {
    const currentDay = new Date(startDate)
    currentDay.setDate(startDate.getDate() + i)
    const formattedDate = `${daysOfWeek[currentDay.getDay()]} - ${currentDay.getDate().toString().padStart(2, '0')}/${(currentDay.getMonth() + 1).toString().padStart(2, '0')}`
    days.push(formattedDate)
  }

  return days
}

const CalendarHeader = ({
  setDays,
  setCurrentWeek,
  onBranchChange,
  selectedPosition,
  handlePositionChange,
  onLoad,
}) => {
  const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('isoWeek').toDate())
  const [currentWeekEnd, setCurrentWeekEnd] = useState(moment().endOf('isoWeek').toDate())
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedChuyenKhoa, setSelectedChuyenKhoa] = useState(null)
  const [branch, setBranch] = useState([])
  const [branchPending, setBranchPending] = useState(null)
  const [branchForPosition, setBranchForPosition] = useState([])
  const [positions, setPositions] = useState([])
  const [visible, setVisible] = useState(false)
  const [allChuyenKhoa, setAllChuyenKhoa] = useState([])

  const { infoUser } = useSelector((state) => state.userReducer)
  const isBacSi = /Bác Sĩ/i.test(infoUser?.tenNhom) // Check if the user is a doctor

  const positionDisplayNames = {
    BacSi: 'Bác Sĩ',
    DieuDuong: 'Điều Dưỡng',
    KyThuatVien: 'Kỹ Thuật Viên',
    DuocSi: 'Dược Sĩ',
    LeTan: 'Lễ Tân',
  }

  const onClose = () => {
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const getAllChuyenKhoa = async () => {
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      setAllChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllChuyenKhoa()
  }, [])

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await https.get('/NhomNguoiDung/GetAllLoaiNhom')
        setPositions(response.data || []) // Ensure positions is an array
      } catch (error) {
        console.error('Error fetching positions:', error)
      }
    }

    fetchPositions()
  }, [])

  useEffect(() => {
    if (selectedChuyenKhoa) {
      const fetchBranchByChuyenKhoa = async () => {
        try {
          setSelectedChuyenKhoa(selectedChuyenKhoa)
          const { data } = await https.get(
            `ChiNhanh/GetChiNhanhIdChuyenKhoa?idChuyenKhoa=${selectedChuyenKhoa}`,
          )
          setBranch(data)

          if (data.length > 0) {
            setBranchPending(data[0].idChiNhanh) // Set default branch to the first element
            // nếu isBacSi thì setBranchForPosition còn không thì setBranchPending
            if (isBacSi) {
              onBranchChange(branchForPosition) // Pass the default
            } else {
              onBranchChange(data[0].idChiNhanh) // Pass the default branch ID to the parent component
            }
          }
        } catch (error) {
          console.error('Error fetching branch:', error)
        }
      }
      fetchBranchByChuyenKhoa()
    }
  }, [selectedChuyenKhoa])

  useEffect(() => {
    setDays(getDaysOfWeek(currentWeekStart))
    setCurrentWeek(currentWeekStart) // Update currentWeek in parent component
  }, [currentWeekStart, setDays, setCurrentWeek])

  useEffect(() => {
    const fetchCompanies = async () => {
      const { data } = await companyService.getAllListCompany()
      setCompanies(data)
      setSelectedCompany(1)
    }

    fetchCompanies()
  }, [])

  const fetchBranch = async (companyId) => {
    const { data } = await nhanvienService.getChiNhanh(companyId, '')
    setBranch(data)
    if (data.length > 0) {
      setBranchPending(data[0].idChiNhanh) // Set default branch to the first element
      // onBranchChange(data[0].idChiNhanh) // Pass the default branch ID to the parent component
    }
  }

  useEffect(() => {
    if (selectedCompany) {
      fetchBranch(selectedCompany)
    }
  }, [selectedCompany])

  // New useEffect to set default position
  useEffect(() => {
    if (infoUser) {
      const tenNhom = infoUser.tenNhom
      if (/Bác Sĩ/i.test(tenNhom)) {
        handlePositionChange('BacSi')
      } else {
        handlePositionChange('')
      }
    }
  }, [positions, infoUser])

  useEffect(() => {
    if (infoUser) {
      const idChiNhanh = infoUser?.idChiNhanh
      if (idChiNhanh) {
        setBranchForPosition(idChiNhanh)
      }
    }
  }, [infoUser])

  useEffect(() => {
    if (infoUser) {
      const idCK = infoUser?.idCK
      if (idCK) {
        setSelectedChuyenKhoa(idCK)
      }
    }
  }, [infoUser])

  const handlePreviousWeek = () => {
    const newStartDate = moment(currentWeekStart).subtract(1, 'weeks').startOf('isoWeek').toDate()
    setCurrentWeekStart(newStartDate)
    setCurrentWeekEnd(moment(newStartDate).endOf('isoWeek').toDate())
  }

  const handleNextWeek = () => {
    const newStartDate = moment(currentWeekStart).add(1, 'weeks').startOf('isoWeek').toDate()
    setCurrentWeekStart(newStartDate)
    setCurrentWeekEnd(moment(newStartDate).endOf('isoWeek').toDate())
  }

  const onChange = (date) => {
    if (date) {
      const newStartDate = date.startOf('isoWeek').toDate()
      setCurrentWeekStart(newStartDate)
      setCurrentWeekEnd(date.endOf('isoWeek').toDate())
    }
  }

  const customWeekStartEndFormat = (value) => {
    const weekNumber = value.isoWeek()
    const startOfWeek = value.startOf('isoWeek').format('DD/MM/YYYY')
    const endOfWeek = value.endOf('isoWeek').format('DD/MM/YYYY')
    return `Tuần ${weekNumber} (${startOfWeek} - ${endOfWeek})`
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-2 w-1/4">
        <Select
          style={{
            width: '200px',
            minWidth: '200px',
          }}
          className="w-36"
          value={selectedPosition || undefined} // Ensure value is undefined when no option is selected
          onChange={handlePositionChange}
          placeholder="Chọn chức vụ"
          disabled={isBacSi}
        >
          {positions.map((position, index) => (
            <Option key={index} value={position}>
              {positionDisplayNames[position] || position}
            </Option>
          ))}
        </Select>

        {selectedPosition === 'BacSi' && (
          <>
            <Select
              style={{
                width: '300px',
                minWidth: '300px',
              }}
              placeholder="Chọn chuyên khoa"
              value={selectedChuyenKhoa}
              onChange={(value) => {
                setSelectedChuyenKhoa(value)
              }}
              showSearch
              options={allChuyenKhoa.map((chuyenKhoa) => ({
                label: chuyenKhoa.tenChuyenKhoa,
                value: chuyenKhoa.idChuyenKhoa,
              }))}
              className="w-72"
              disabled={isBacSi}
            />
            <Select
              style={{
                width: '300px',
                minWidth: '300px',
              }}
              placeholder="Chọn chi nhánh theo chuyên khoa"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              // nếu isBacSi thì setBranchForPosition còn không thì setBranchPending
              value={isBacSi ? branchForPosition : branchPending}
              onChange={(value) => {
                setBranchPending(value)
                onBranchChange(value) // Pass  component
              }}
              showSearch
              options={branch.map((items) => ({
                label: items.tenChiNhanh,
                value: items.idChiNhanh,
              }))}
              disabled={isBacSi}
              className="w-full"
            />
          </>
        )}

        {selectedPosition && selectedPosition !== 'BacSi' && (
          <>
            <Select
              style={{
                width: '300px',
                minWidth: '300px',
              }}
              value={selectedCompany}
              onChange={(value) => {
                setSelectedCompany(value)
                localStorage.setItem('id_company', value)
              }}
              placeholder="Chọn công ty"
              showSearch
              options={companies.map((company) => ({
                label: company.tenct,
                value: company.idct,
              }))}
              className="w-72"
            />
            <Select
              style={{
                width: '300px',
                minWidth: '300px',
              }}
              placeholder="Chọn chi nhánh"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              value={branchPending}
              onChange={(value) => {
                setBranchPending(value)
                onBranchChange(value) // Pass the selected branch ID to the parent component
              }}
              showSearch
              options={branch.map((items) => ({
                label: items.tenChiNhanh,
                value: items.idChiNhanh,
              }))}
              className="w-72"
            />
          </>
        )}

        <IconButton
          onClick={onLoad}
          color="primary"
          aria-label="sync"
          size="small"
          disabled={!selectedPosition}
        >
          <SyncIcon />
        </IconButton>
        <Button
          className="bg-blue-400 text-white"
          onClick={showDrawer}
          disabled={!selectedPosition}
          icon={<FolderViewOutlined />}
        >
          Ca còn trống
        </Button>

        <DrawerCaTrong
          onClose={onClose}
          visible={visible}
          idCN={isBacSi ? branchForPosition : branchPending}
          loaiNhomND={selectedPosition}
          thangDK={dayjs(currentWeekStart).format('MM/YYYY')}
        />
      </div>

      <div className="flex gap-2">
        <Button onClick={handlePreviousWeek}>Tuần trước</Button>
        <DatePicker
          picker="week"
          value={dayjs(currentWeekStart)}
          onChange={onChange}
          format={customWeekStartEndFormat}
        />
        <Button onClick={handleNextWeek}>Tuần sau</Button>
      </div>
    </div>
  )
}

export default CalendarHeader
