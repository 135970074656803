import React, { useState } from 'react'
import { Modal, Input } from 'antd'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'
import { useFormik } from 'formik'
import { addCtyKhamDoanSchema } from '../../../../schemas/addCtyKhamDoanSchema'
import { addCtyKhamDoan } from '../../../../store/actions/khamDoanAction'
import QueueIcon from '@mui/icons-material/Queue'
import { addCtyCRM } from '../../../../store/actions/congtyCRMAction'
import { congtyCRMSchema } from '../../../../schemas/congtyCRMSchema'
const ModalAddCty = ({ showAdd, onClose, onLoad }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const handleCancel = () => {
    formik.handleReset()
    onClose(false)
  }

  const handleAddCty = (value) => {
    dispatch(addCtyCRM(value, onLoad))
    handleCancel()
  }

  const formik = useFormik({
    initialValues: {
      // mact: '',
      masothue: '',
      tenct: '',
      diachi: '',
      dienthoai: '',
      fax: '',
      email: '',
      website: '',
      ghichu: '',
      nguoitao: infoUser?.idnv,
    },
    validationSchema: congtyCRMSchema,
    onSubmit: (value) => handleAddCty(value),
  })
  return (
    <>
      <Modal
        className="text-center"
        title="Thêm công Ty"
        open={showAdd}
        footer={null}
        onCancel={handleCancel}
      >
        <form className="text-start">
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Mã số thuế
            </label>
            <Input
              value={formik.values.masothue}
              onChange={formik.handleChange}
              name="masothue"
              status={formik.errors.masothue ? 'error' : ''}
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Tên công ty
            </label>
            <Input
              value={formik.values.tenct}
              onChange={formik.handleChange}
              name="tenct"
              status={formik.errors.tenct ? 'error' : ''}
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Địa chỉ
            </label>
            <Input
              value={formik.values.diachi}
              onChange={formik.handleChange}
              name="diachi"
              status={formik.errors.diachi ? 'error' : ''}
            />
          </div>
          <div>
            <label className="font-semibold">
              {' '}
              <span className="text-red-500">(*)</span>Điện thoại
            </label>
            <Input
              value={formik.values.dienthoai}
              onChange={formik.handleChange}
              name="dienthoai"
              status={formik.errors.dienthoai ? 'error' : ''}
            />
            {formik.errors.dienthoai && (
              <span className="text-red-500">{formik.errors.dienthoai}</span>
            )}
          </div>
          <div>
            <label className="font-semibold">Fax</label>
            <Input
              value={formik.values.fax}
              onChange={formik.handleChange}
              name="fax"
              // status={formik.errors.fax ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">Email</label>
            <Input
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              // status={formik.errors.email ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">website</label>
            <Input
              value={formik.values.website}
              onChange={formik.handleChange}
              name="website"
              //   status={formik.errors.website ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">Ghi Chú</label>
            <Input
              value={formik.values.ghichu}
              onChange={formik.handleChange}
              name="ghichu"
              //   status={formik.errors.website ? "error" : ""}
            />
          </div>
          <div className="flex flex-col gap-2 mt-2">
            <Button
              type="submit"
              onClick={formik.handleSubmit}
              variant="contained"
              color="success"
              size="small"
            >
              Lưu
            </Button>
            <Button type="button" onClick={handleCancel} variant="outlined" size="small">
              Huỷ bỏ
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAddCty
