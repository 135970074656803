import { Input } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { calculateAge } from '../../../../utils/calculateAge'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = () => {
  const { infoBNDienTim } = useSelector((state) => state.dienTimReducer)
  return (
    <div>
      <h2 className="p-3 border-b text-blue-500 font-semibold">Thông tin bệnh nhân</h2>
      <div className="flex gap-2 flex-col p-2">
        <div className="flex">
          <label className="font-semibold w-24">Tên BN : </label>
          <Input
            value={infoBNDienTim?.benhNhan?.tenBenhNhan}
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="font-semibold w-24">Mã BN : </label>
          <Input
            value={infoBNDienTim?.benhNhan?.maBenhNhan}
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex gap-2">
          <div className="flex">
            <label className="font-semibold w-32">Ngày sinh: </label>
            <Input
              value={
                infoBNDienTim?.benhNhan?.ngaySinh &&
                moment(infoBNDienTim?.benhNhan?.ngaySinh).format('DD/MM/YYYY')
              }
              size="small"
              variant="filled"
              readOnly
            />
          </div>
          <div className="flex">
            <label className="font-semibold w-32">Giới tính: </label>
            <Input
              size="small"
              value={infoBNDienTim?.benhNhan?.gioiTinh}
              variant="filled"
              readOnly
            />
          </div>
        </div>
        <div className="flex">
          <label className="font-semibold w-24">Tuổi : </label>
          <Input
            size="small"
            value={
              infoBNDienTim
                ? infoBNDienTim?.benhNhan?.ngaySinh &&
                  calculateAge(infoBNDienTim?.benhNhan?.ngaySinh)
                : null
            }
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="font-semibold w-24">Địa chỉ : </label>
          <Input
            value={`${infoBNDienTim?.benhNhan.diaChi ?? ''}${infoBNDienTim?.benhNhan.tenPhuongXa ? ', ' + infoBNDienTim?.benhNhan.tenPhuongXa : ''}${infoBNDienTim?.benhNhan.tenQuanHuyen ? ', ' + infoBNDienTim?.benhNhan.tenQuanHuyen : ''}${infoBNDienTim?.benhNhan.tenTinhTP ? ', ' + infoBNDienTim?.benhNhan.tenTinhTP : ''}`}
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="font-semibold w-24">BS chỉ định: </label>
          <Input value={infoBNDienTim?.bskham} size="small" variant="filled" readOnly />
        </div>
      </div>
    </div>
  )
}

export default InfoBN
