import { Button } from '@mui/material'
import { Checkbox, Input, Select } from 'antd'
import React from 'react'
import { formattedNumber } from '../../../../../utils/formattedNumber'

const Pay = ({ totalPrice, formik_Billing }) => {
  const handleSelected = (name) => (value) => {
    formik_Billing.setFieldValue(name, value)
  }
  const handleCheckboxChange = () => {
    formik_Billing.setFieldValue('hoaDonDienTu', formik_Billing.values.hoaDonDienTu === 1 ? 0 : 1)
  }
  return (
    <div className="border rounded ">
      <div className="border-b flex text-lg font-semibold text-gray-500 p-2">
        <h2 className="w-1/2">Thanh toán</h2>
        <h2 className="w-1/2 text-black text-center">
          Tổng trả: <span>{formattedNumber(totalPrice)} VNĐ</span>
        </h2>
      </div>

      <div className=" bg-slate-100 p-2">
        {/* <div className="flex gap-2">
          <Input placeholder="Nhập mã khuyển mãi" />
          <Button className="w-40" size="small" variant="contained">
            Áp dụng
          </Button>
        </div> */}
        <div className="flex gap-2 mt-2">
          <div className="w-[400px]  ">
            <Select
              onChange={handleSelected('idPhuongThuc')}
              defaultValue={formik_Billing.values.idPhuongThuc}
              className="w-full"
              size="small"
              options={[
                { label: 'Thanh toán', value: 1 },
                { label: 'Công nợ', value: 2 },
              ]}
            />
          </div>
          <div>
            <label className="mr-2">Xuất HĐ: </label>
            <Checkbox
              checked={formik_Billing.values.hoaDonDienTu === 1}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
      </div>
      <div className="p-2 text-base flex flex-col gap-2">
        <div className="flex p-2">
          <label className="w-1/2">Tổng tiền:</label>
          <h1 className="w-1/2 text-end">{formattedNumber(totalPrice)} VNĐ</h1>
        </div>
        <div className="flex p-2">
          <label className="w-1/2"> % Giảm:</label>
          <h1 className="w-1/2 text-end">0 VNĐ</h1>
        </div>
        <div className="flex p-2">
          <label className="w-1/2">Tiền giảm:</label>
          <h1 className="w-1/2 text-end">0 VNĐ</h1>
        </div>
        <div className="flex p-2">
          <label className="w-1/2">Giảm giá Voucher:</label>
          <h1 className="w-1/2 text-end">0 VNĐ</h1>
        </div>
      </div>
    </div>
    // <div className="border rounded h-96">
    //   <div className="border-b flex text-lg font-semibold text-gray-400 p-2">
    //     <h2 className="w-1/2">Thanh toán</h2>
    //     <h2 className="w-1/2 text-black text-center">
    //       Tổng trả: <span>{formattedNumber(totalPrice)} VNĐ</span>
    //     </h2>
    //   </div>

    //   <div className=" bg-slate-100 p-2">
    //     {/* <div className="flex gap-2">
    //       <Input placeholder="Nhập mã khuyển mãi" />
    //       <Button className="w-40" size="small" variant="contained">
    //         Áp dụng
    //       </Button>
    //     </div> */}
    //     <div className="flex gap-2 mt-2">
    //       <div className="w-[400px]  ">
    //         <Select
    //           onChange={handleSelected('idPhuongThuc')}
    //           defaultValue={formik_Billing.values.idPhuongThuc}
    //           className="w-full"
    //           size="small"
    //           options={[
    //             { label: "Thanh toán", value: 1 },
    //             { label: "Công nợ", value: 2 },
    //           ]}
    //         />
    //       </div>
    //       <div>
    //         <label className="mr-2">Xuất HĐ: </label>
    //         <Checkbox
    //           checked={formik_Billing.values.hoaDonDienTu === 1}
    //           onChange={handleCheckboxChange}
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="p-2 text-base flex flex-col gap-2">
    //     <div className="flex p-2">
    //       <label className="w-1/2">Tổng tiền:</label>
    //       <h1 className="w-1/2 text-end">{formattedNumber(totalPrice)} VNĐ</h1>
    //     </div>
    //     <div className="flex p-2">
    //       <label className="w-1/2"> % Giảm:</label>
    //       <h1 className="w-1/2 text-end">0 VNĐ</h1>
    //     </div>
    //     <div className="flex p-2">
    //       <label className="w-1/2">Tiền giảm:</label>
    //       <h1 className="w-1/2 text-end">0 VNĐ</h1>
    //     </div>
    //     <div className="flex p-2">
    //       <label className="w-1/2">Giảm giá Voucher:</label>
    //       <h1 className="w-1/2 text-end">0 VNĐ</h1>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Pay
