import * as typeAction from '../constants/constants'

export const getLienHeCRM = (id) => ({
  type: typeAction.GET_ALL_LH_CRM,
  id,
})

export const getLienHeCRMByidNv = (idnv, idct) => ({
  type: typeAction.GET_ALL_LH_CRM_BY_ID_NV,
  idnv,
  idct,
})

export const addLienHeCRM = (form, onload) => ({
  type: typeAction.ADD_LH_CRM,
  form,
  onload,
})

export const editLienHeCRM = (form, onLoad) => ({
  type: typeAction.EDIT_LH_CRM,
  form,
  onLoad,
})

export const getThongTinLienHeCRM = (idttlh) => ({
  type: typeAction.GET_THONGTIN_LH_CRM,
  idttlh,
})

// chi tiet lien he
export const addChiTietLienHeCRM = (form, onLoad) => ({
  type: typeAction.ADD_CHITIET_LH_CRM,
  form,
  onLoad
})

export const getChiTietLienHeCRM = () => ({
  type: typeAction.GET_CHITIET_LH_CRM,
})
