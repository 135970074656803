import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDanhMuc } from '../../../store/actions/suCoAction'

const EditDanhMuc = ({ isEditDanhMuc, setIsEditDanhMuc, idDanhMuc, fetchDanhMucNhan }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsEditDanhMuc(false)
  }
  const handlSubmit = (values) => {
    dispatch(updateDanhMuc(values, handleCancel, fetchDanhMucNhan))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idnhandm: idDanhMuc?.idnhandm,
      tendanhmuc: idDanhMuc?.tendanhmuc,
      nguoisua: infoUser?.idnv,
    },
    onSubmit: (values) => handlSubmit(values),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Cập nhập"
      cancelText="Huỷ "
      open={isEditDanhMuc}
      onCancel={handleCancel}
      title="Chỉnh sửa danh mục nhãn"
    >
      <label htmlFor="" className='font-semibold'>Tên Danh Mục:</label>
      <Input value={formik.values.tendanhmuc} onChange={formik.handleChange} name="tendanhmuc" />
    </Modal>
  )
}

export default EditDanhMuc
