import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { https } from '../../../../../services/apiService'
import { editGiaChiNhanhLoaiTheSchema } from '../../../../../schemas/editGiaChiNhanhLoaiTheSchema'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import {
  ListDichVuAction,
  addNewDichVuAction,
  getAllDichVu,
} from '../../../../../store/actions/dichVuAction'
import jsPDF from 'jspdf'
import { timesRegular } from '../../../../../assets/fonts/base64/times'
import ToastCus from '../../../../../utils/Toast'
const ModalAdd = ({ isModalAdd, setIsModalAdd }) => {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { infoLoaiThe } = useSelector((state) => state.detailCardReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listCNchuaLT, setListCNchuaLT] = useState([])
  const [congTy, setCongTy] = useState(PkDangNhap?.idCongTy)
  const onLoad = () => {
    // dispatch(getAllDichVu())
    // dispatch(ListDichVuAction(searchGroup, company))
  }
  // xử lí form thêm dịch vụ
  const handleSubmit = async (value) => {
    //dispatch(addNewDichVuAction(value, onLoad))
    try {
      await https.post('TheLoai/AddTheLoaiChiNhanh', value)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      formik.resetForm()
      setIsModalAdd(false)
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idlt: infoLoaiThe?.idlt,
      idchinhanh: null,
      dongia: 0,
      ghichu: '',

      // idcnpk: 0,
      // idcn: null,
      // idpk: rowActive?.idPK,
      // nguoitao: infoUser?.idnv,
      // ghiChu: '',
      // sudung: 1,
      // dongia: 0,
      // ngayTao: "2024-08-07T04:22:27.066Z",
      // tenChiNhanh: "",
      // tenPhongKham: "",
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: editGiaChiNhanhLoaiTheSchema,
  })

  const getAllChiNhanhChuaCoLT = async () => {
    try {
      const { data } = await https.get('TheLoai/GetChiNhanhNotTheLoaiChiNhanh', {
        params: { idchuyenkhoa: infoLoaiThe?.idchuyenkhoa, idct: congTy, idlt: infoLoaiThe?.idlt },
      })
      setListCNchuaLT(data)
    } catch (error) {
      console.log(error)
    }
  }
  // useEffect(() => {
  //   dispatch(listAllCompanyAction())
  //   // getAllChiNhanhChuaCoLT()
  // }, [])
  useEffect(() => {
    getAllChiNhanhChuaCoLT()
  }, [congTy])

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  return (
    <>
      <Modal
        style={{
          top: 20,
        }}
        okText="Thêm"
        cancelText="Hủy"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Thêm chi nhánh loại thẻ</h2>}
        onOk={formik.handleSubmit}
        open={isModalAdd}
        onCancel={() => setIsModalAdd(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên loại thẻ
            </label>
            <Input
              readOnly
              variant="filled"
              value={infoLoaiThe?.loaithe}
              style={{ color: 'black' }}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Công ty
            </label>
            <Select
              placeholder="Chọn công ty..."
              className="w-full"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              defaultValue={PkDangNhap?.idCongTy}
              onChange={(value) => {
                setCongTy(value)
                formik.setFieldValue('idcn', null)
              }}
              value={congTy}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Chi nhánh
            </label>
            {/* <Input
              value={formik.values.tenDichVu}
              name="tenDichVu"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên dịch vụ"
              status={formik.errors.tenDichVu && 'error'}
            /> */}
            <Select
              placeholder="Chọn chi nhánh..."
              className="w-full"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => formik.setFieldValue('idchinhanh', value)}
              value={formik.values.idchinhanh}
              status={formik.errors.idchinhanh && 'error'}
              options={listCNchuaLT?.map((items) => ({
                label: items.tenchinhanh,
                value: items.idchinhanh,
                key: items.idchinhanh,
              }))}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Đơn giá
            </label>
            <InputNumber
              onChange={(value) => {
                formik.setFieldValue('dongia', value)
              }}
              status={formik.errors.dongia ? 'error' : ''}
              name="dongia"
              className="w-full"
              value={formik.values.dongia}
              step={100000}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          {/* <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.ghiChu}
              onChangeCapture={formik.handleChange}
              name="ghiChu"
            />
          </div> */}
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd
