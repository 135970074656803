import { Input, Modal, InputNumber, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { dichVuService } from '../../../services/dichVu/dichVuService'
import { postGiaVatTuDVThuThuatAction } from '../../../store/actions/hoaHongAction'
import ToastCus from '../../../utils/Toast'
const AddNew = ({ open, setOpen, listCongTy, listChuyenKhoa, getListGiaVatTuDVThuThuat }) => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [dichVu, setDichVu] = useState(null)
  const [listDichVu, setListDichVu] = useState([])
  const [congTy, setCongTy] = useState(id_company)
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [giaVatTu, setGiaVatTu] = useState('')

  const handleCancel = () => {
    setOpen(false)
  }
  const resetForm = () => {
    getListGiaVatTuDVThuThuat()
    setDichVu(null)
    setCongTy(id_company)
    setGiaVatTu(0)
    setOpen(false)
  }
  const handleSubmit = () => {
    if (isLoading || !congTy || !dichVu || !dichVu?.iddv || giaVatTu === null || giaVatTu < 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thử lại!',
      })
      return
    }
    setOpen(false)
    dispatch(
      postGiaVatTuDVThuThuatAction(
        {
          iddv: dichVu?.iddv,
          tenthuthuat: dichVu?.tenthuthuat,
          giavattu: giaVatTu,
          nguoitao: infoUser?.idnv,
        },
        resetForm,
      ),
    )
  }
  const getListDichVuThuThuat = async (idct) => {
    try {
      setIsLoading(true)
      const { data } = await dichVuService.getListDichVuThuThuatChuaAddGVT(idct) // chỉ lấy dịch vụ là thủ thuật id nhóm = 23
      setListDichVu(data) // chỉ lấy dịch vụ sản
      setDichVu(null)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        getListDichVuThuThuat(congTy)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [open])
  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Thêm"
      cancelText="Huỷ bỏ"
      title={<p className="text-center">Thêm giá vật tư cho thủ thuật</p>}
    >
      <div className="mt-5 flex flex-col gap-2">
        <div>
          <label className="text-gray-500 font-semibold">
            <span className="text-red-500">(*)</span>&nbsp;Công ty:
          </label>
          <Select
            disabled={isLoading}
            placeholder="Chọn công ty..."
            className="w-full"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCongTy?.map((items) => ({
              label: items?.tenct,
              value: items?.idct,
            }))}
            value={congTy}
            onChange={(value) => {
              setCongTy(value)
              getListDichVuThuThuat(value)
            }}
          />
        </div>
        {/* <div>
          <label className="text-gray-500 font-semibold">Chuyên khoa:</label>
          <Select
            disabled={isLoading}
            placeholder="Chọn chuyên khoa..."
            className="w-full"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                label: 'Tất cả chuyên khoa',
                value: 'all',
              },
              ...listChuyenKhoa.map((items) => ({
                label: items?.tenChuyenKhoa,
                value: items?.idChuyenKhoa,
              })),
            ]}
            value={chuyenKhoa}
            onChange={(value) => setChuyenKhoa(value)}
          />
        </div> */}
        <div>
          <label className="text-gray-500 font-semibold">
            <span className="text-red-500">(*)</span>&nbsp;Thủ thuật:
          </label>
          <Select
            status={(!dichVu || !dichVu?.iddv) && giaVatTu && !isLoading && 'error'}
            disabled={isLoading}
            placeholder="Chọn dịch vụ thủ thuật..."
            className="w-full"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listDichVu?.map((items) => ({
              label: items?.tendichvu, //`${items?.tenDichVu} - ${items?.iddv}`,
              value: items?.iddv,
              infoDV: items,
            }))}
            value={dichVu?.iddv}
            onChange={(value, opt) => {
              console.log(opt)
              setDichVu({ iddv: value, tenthuthuat: opt?.label })
              setGiaVatTu((prev) => (!prev || prev === '' ? null : prev))
            }}
          />
        </div>
        <div>
          <label className="text-gray-500 font-semibold">
            <span className="text-red-500">(*)</span>&nbsp;Giá vật tư:
          </label>
          <InputNumber
            status={giaVatTu === null && 'error'}
            className="w-full"
            min={0}
            step={100}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            value={giaVatTu}
            onChange={(value) => setGiaVatTu(value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AddNew
