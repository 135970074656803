import React, { useCallback, useEffect, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import _ from 'lodash'
import { ConfigProvider, Table, Checkbox, Input, Button, Pagination, Modal, Select } from 'antd'
import {
  SearchOutlined,
  SyncOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, resetPassUser } from '../../../store/actions/userAction'
import moment from 'moment'
import CreateUser from './Modal/CreateUser'
import EditUser from './Modal/EditUser'
import { authService } from '../../../services/auth/authService'
import { nguoidungService } from '../../../services/nguoidung/nguoidungService'
import { nhanvienService } from '../../../services/nhanvien/nhanvienService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { branchService } from '../../../services/branch/branchService'
import { useFormik } from 'formik'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

const User = () => {
  const dispatch = useDispatch()
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [isModal, setIsModal] = useState(false)
  const [info, setInfo] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [search, setSearch] = useState(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  // Khai báo state cho trạng thái checkbox ở file cha
  const [tempIsLockParent, setTempIsLockParent] = useState(0) // Khởi tạo tempIsLockParent và set giá trị mặc định là 0
  const { listCompany } = useSelector((state) => state.companyReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState(null)
  const [rightsGroup, setRightsGroup] = useState([])
  const [usersCan, setUsersCan] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('all')
  const [pageIndex, setPageIndex] = useState(1)
  const [listUser, setListUser] = useState()
  const [loading, setLoading] = useState(false)

  const debounceSearchUser = useCallback(
    _.debounce((e) => {
      let kw = e?.target?.value ? e?.target?.value?.trim() : null
      if (kw?.length > 0) {
        setSearch(kw)
        setPageIndex(1)
      } else setSearch(null)
    }, 300),
    [],
  )
  const getListUser = async () => {
    setLoading(true)
    try {
      const result = await nguoidungService.getListUserTheoDieuKien(
        selectedGroup === 'all' ? null : selectedGroup,
        null,
        search?.trim()?.length > 0 ? search : null,
        pageIndex,
      )
      setListUser(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!isModal && !isEdit) {
      setTimeout(() => {
        getListUser()
      }, 100)
    }
    // _.debounce(() => { getListNhanVien() }, 100)
  }, [selectedGroup, search, pageIndex, isModal, isEdit])

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  // lấy danh sách chi nhánh theo công ty
  const fetchBranch = async (id) => {
    try {
      const result = await branchService.getListBranch(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  // lấy dánh sách nhóm quyền
  useEffect(() => {
    const fetchAllUsersCan = async () => {
      try {
        const result = await nhanvienService.getRulerUser()
        setRightsGroup(result.data)
      } catch (error) {
        console.error('Không thể tìm được dữ liệu nhóm quyền', error)
      }
    }
    fetchAllUsersCan()
  }, [])

  const formik = useFormik({
    initialValues: {
      idnv: null,
      idChiNhanh: null,
      idNhom: null,
      IDCT: id_company,
      isLock: 0,
      ipmac: null,
    },
    // validationSchema: AddUserSchema,
  })
  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])

  // lấy danh sách nhân viên theo nhóm quyền
  const fetchUsersByGroup = async (idNhom, pageIndex) => {
    try {
      const result = await nguoidungService.getUserByIdnhom(idNhom, pageIndex)
      const formatData = result?.data?.data?.map((items, index) => ({
        key: index,
        idNguoiDung: items.idnd,
        taiKhoan: items.taikhoan,
        tennv: items.tennhanvien,
        manv: items.manhanvien,
        tenNhom: items.tennhom,
        nguoiTao: items.nguoitao,
        ngayTao: items.ngaytao && moment(items.ngaytao).format('DD/MM/YYYY HH:mm:ss'),
        ngaySua: items.ngaysua && moment(items.ngaysua).format('DD/MM/YYYY HH:mm:ss'),
        isLock: items.hieuluc,
        tenNguoiSua: items.nguoisua,
      }))
      setUsersCan(formatData)
    } catch (error) {
      console.error('Không thể tìm được dữ liệu nhân viên', error)
    }
  }

  const showConfirm = async (record) => {
    confirm({
      title: 'Reset mật khẩu người dùng ',
      icon: <ExclamationCircleFilled />,
      content: `bạn có muốn đặt lại mật khẩu cho ${record.taiKhoan}`,
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        dispatch(
          resetPassUser(record.taiKhoan, infoUser?.idnv, {
            keyword: search,
            page: useSearch.get('page'),
          }),
        )
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const debounce = useCallback(
    _.debounce((keyword) => {
      dispatch(fetchUser(keyword))
    }, 300),
    [],
  )
  const showEdit = () => {
    setIsEdit(true)
  }
  const cancelEdit = () => {
    setIsEdit(false)
  }
  const cancelModalAdd = () => {
    setIsModal(false)
  }
  const showModaAdd = () => {
    setIsModal(true)
  }
  const onSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    SetSearchParams({ page: 1 })
    debounce(value)
  }
  const onReload = () => {
    setSelectedGroup('all')
    setPageIndex(1)
    setSearch(null)
  }
  const handleEdit = async (id) => {
    showEdit()
    const { data } = await authService.getInfoUser(id)
    setInfo(data)
    // Cập nhật tempIsLockParent từ info.isLock
    setTempIsLockParent(data.isLock || 0)
  }
  // Hàm xử lý thay đổi checkbox của file cha
  const handleLockChangeParent = (e) => {
    const isLock = e.target.checked ? 1 : 0 // Đảo ngược giá trị khi checkbox thay đổi
    setTempIsLockParent(isLock) // Cập nhật giá trị tempIsLockParent
  }
  // thay đổi chọn nhóm quyền
  const handleChange = (value) => {
    setSelectedGroup(value)
    setPageIndex(1) // Reset page index về 1 khi nhóm quyền thay đổi
    // fetchUsersByGroup(value, 1)
  }

  useEffect(() => {
    dispatch(fetchUser(search, useSearch.get('page')))
  }, [useSearch.get('page')])

  return (
    <>
      <div className="h-full p-5 bg-[#EFEFEF]">
        <div className="p-2 rounded-xl  bg-white">
          <div className="flex justify-between mb-2">
            <div className="flex gap-2">
              {/* <div>
                <Select
                  showSearch
                  value={formik.values.IDCT}
                  onChange={(value) => {
                    fetchBranch(value)
                    formik.setFieldValue('idChiNhanh', null)
                    formik.setFieldValue('IDCT', value)
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={listCompany?.map(({ idct, tenct }) => ({
                    label: tenct,
                    value: idct,
                  }))}
                  className="w-72"
                />
              </div> */}
              {/* <div>
                <Select
                  placeholder="Chọn chi nhánh"
                  value={formik.values.idChiNhanh}
                  showSearch
                  onChange={handleSelected('idChiNhanh')}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={branch?.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))}
                  className="w-60"
                />
              </div> */}
              <div>
                <Select
                  className="w-60"
                  onChange={handleChange}
                  options={[
                    { label: 'Tất cả', value: 'all' },
                    ...(rightsGroup?.map((item) => ({
                      label: item.tenNhom,
                      value: item.idNhom,
                    })) || []),
                  ]}
                  defaultValue="all" // Mặc định là 'all'
                />
                {selectedGroup !== 'all' && (
                  <ul>
                    {usersCan?.map((item) => (
                      <li key={item.idNhom}>{item.idNhom}</li>
                    ))}
                  </ul>
                )}
              </div>
              <Input
                onChange={debounceSearchUser}
                // onChange={onSearch}
                className="w-60"
                placeholder="Nhập từ khóa tìm kiếm..."
                prefix={<SearchOutlined className="pr-1" />}
              />
              {/* <Select className="w-60" /> */}
            </div>
            <div className="flex gap-2">
              <button
                onClick={showModaAdd}
                className="rounded-lg relative w-32 h-8 cursor-pointer flex items-center overflow-hidden border border-green-500 bg-green-500 group hover:bg-green-500 active:bg-green-500 active:border-green-500"
              >
                <span className="text-white font-semibold ml-7 transform group-hover:translate-x-20 transition-all duration-300">
                  Tạo mới
                </span>
                <span className="absolute right-0 h-full w-10 rounded-lg bg-green-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                  <svg
                    className="svg w-8 text-white"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="12" x2="12" y1="5" y2="19"></line>
                    <line x1="5" x2="19" y1="12" y2="12"></line>
                  </svg>
                </span>
              </button>
              {/* <Button onClick={onReload} type="primary" icon={<SyncOutlined />}>
                Tải lại
              </Button> */}
            </div>
          </div>
          <ConfigProvider theme={{ token: { padding: 4 } }}>
            <Table
              loading={loading}
              scroll={{
                x: 1750,
              }}
              pagination={false}
              bordered
              dataSource={listUser?.data?.map((items, index) => ({
                key: index,
                idNguoiDung: items.idnd,
                taiKhoan: items.taikhoan,
                // matKhau: items.matKhau,
                // idChiNhanh: items.idChiNhanh,
                // idNhom: items.idNhom,
                tenNhom: items.tennhom,
                tennv: items.tennhanvien,
                manv: items.manhanvien,
                nguoiTao: items.nguoitao,
                ngayTao: items.ngaytao && moment(items.ngaytao).format('DD/MM/YYYY HH:mm:ss'),
                // nguoiSua: items.nguoisua,
                ngaySua: items.ngaysua && moment(items.ngaysua).format('DD/MM/YYYY HH:mm:ss'),
                isLock: items.hieuluc,
                ipmac: items.ipmac,
                tenNguoiSua: items.nguoisua,
              }))}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'STT',
                  key: 'STT',
                  align: 'center',
                  width: 50,
                  render: (text, record, index) => {
                    return (pageIndex - 1) * 20 + index + 1
                  },
                  fixed: 'left',
                },
                {
                  title: 'Tài khoản',
                  dataIndex: 'taiKhoan',
                  key: 'taiKhoan',
                  width: 160,
                  fixed: 'left',
                },
                {
                  title: 'Tên nhân viên',
                  dataIndex: 'tennv',
                  key: 'tennv',
                  fixed: 'left',
                },
                {
                  title: 'Mã nhân viên',
                  dataIndex: 'manv',
                  key: 'manv',
                  width: 120,
                },
                {
                  title: 'Quyền người dùng',
                  dataIndex: 'tenNhom',
                  key: 'tenNhom',
                  width: 200,
                },
                {
                  title: 'Người tạo',
                  dataIndex: 'nguoiTao',
                  key: 'nguoiTao',
                  width: 200,
                },
                {
                  title: 'Ngày tạo',
                  dataIndex: 'ngayTao',
                  key: 'ngayTao',
                  width: 140,
                  align: 'center',
                },
                {
                  title: 'Người sửa',
                  dataIndex: 'tenNguoiSua',
                  key: 'tenNguoiSua',
                  width: 200,
                },
                {
                  title: 'Ngày sửa',
                  dataIndex: 'ngaySua',
                  key: 'ngaySua',
                  width: 140,
                  align: 'center',
                },
                {
                  title: 'Hiệu lực',
                  dataIndex: 'isLock',
                  key: 'isLock',
                  width: 70,
                  align: 'center',
                  render: (text, record) => (
                    <Checkbox
                      checked={record.isLock === 1} // Đảo ngược giá trị checked dựa trên record.isLock
                      // onChange={() => {
                      //   handleLockChangeParent(record)
                      // }}
                    />
                  ),
                  fixed: 'right',
                },
                {
                  title: 'Reset',
                  dataIndex: 'reset',
                  key: 'reset',
                  width: 130,
                  align: 'center',
                  render: (text, record, index) => (
                    <Button
                      onClick={() => showConfirm(record, record.tenNguoiSua)}
                      size="small"
                      type="primary"
                      ghost
                    >
                      Reset mật khẩu
                    </Button>
                  ),
                  fixed: 'right',
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  width: 40,
                  align: 'center',
                  fixed: 'right',
                  render: (text, record, index) => (
                    <EditOutlined
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        handleEdit(record.idNguoiDung)
                      }}
                      className=" rounded p-1 bg-green-200 text-green-700 hover:text-white hover:bg-green-500 cursor-pointer"
                    />
                  ),
                },
              ]}
            />
          </ConfigProvider>
          <div className="flex justify-end">
            <Pagination
              className="mt-2"
              pageSize={20}
              showSizeChanger={false}
              current={pageIndex}
              total={20 * listUser?.totalPages}
              onChange={setPageIndex}
            />
          </div>
        </div>
      </div>
      <CreateUser cancelModalAdd={cancelModalAdd} isModal={isModal} />
      <EditUser onReload={onReload} info={info} cancelEdit={cancelEdit} isEdit={isEdit} />
    </>
  )
}

export default User
