import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, ConfigProvider, Row, Statistic } from "antd";
import { useSelector } from "react-redux";
const Static = ({ data }) => {
  // const { cardReceive } = useSelector(state => state.cartReducer)
  const tongThanhTien = data?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0
  const totalCard = data?.length ?? 0
  const TongPhanBo = data?.reduce((total, items) => total + items.giaphanbo, 0) ?? 0
  return (
    <div className=" w-full">
      <ConfigProvider theme={{
        components:{
          Card:{
            paddingLG:10
          }
        }
      }}>
      <Row gutter={24} justify="between">
        <Col span={8}>
          <Card bordered={true}>
            <Statistic
              title={<h2 className="font-semibold text-lg">Tổng nguyên giá</h2>}
              value={tongThanhTien}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<ArrowUpOutlined />}
            //   suffix="(This month)"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={true}>
            <Statistic
              title={<h2 className="font-semibold text-lg">Tổng phân bổ kỳ</h2>}
              value={TongPhanBo}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<ArrowUpOutlined />}
            //   suffix="(This month)"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={true}>
            <Statistic
              title={<h2 className="font-semibold text-lg">Tổng phiếu</h2>}
              value={totalCard}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
      </Row>
      </ConfigProvider>
     
    </div>
  );
};

export default Static;
