import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { branchService } from '../../../services/branch/branchService'
import { reportService } from '../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../utils/formattedNumber'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
function ChamCong() {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataList, setDataList] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  const getListChamCong = async () => {
    try {
      setIsLoading(true)
      const { data } = await reportService.getListChamCong(
        value?.includes('all')
          ? branch
              ?.map((item) => `idCNs=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idCNs=${item}`)
              ?.join('&')
              ?.toString(),
        search?.length ? search?.trim() : null,
        //value?.includes('all') ? branch?.map((item) => item?.idChiNhanh) : value,
        dateForm,
        dateTo,
      )
      setDataList(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getListBranch(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = dataList?.map((item, index) => ({
      'STT ': ++index,
      'Mã NV': item?.maNhanVien,
      'Tên NV': item?.tenNhanVien,
      'Chi nhánh': item?.tenChiNhanh,
      'Ngày ': item?.ngay ? moment(item?.ngay).format('DD/MM/YYYY') : '-',
      'Thứ ': item?.thu,
      'Vào ': item?.checkIn,
      'Ra ': item?.checkOut,
    }))
    const name = 'TÍNH TOÁN CHẤM CÔNG'
    if (formatData?.length) exportExcelformat(formatData, name)
  }
  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    getListChamCong()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  useEffect(() => {
    getListChamCong()
  }, [branch])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã NV',
      dataIndex: 'maNhanVien',
      key: 'maNhanVien',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Tên NV',
      dataIndex: 'tenNhanVien',
      key: 'tenNhanVien',
      fixed: 'left',
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 400,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 100,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Thứ',
      dataIndex: 'thu',
      key: 'thu',
      width: 100,
    },
    // {
    //   title: 'Ca',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    {
      title: 'Vào',
      dataIndex: 'checkIn',
      key: 'checkIn',
      width: 100,
    },
    {
      title: 'Ra',
      dataIndex: 'checkOut',
      key: 'checkOut',
      width: 100,
    },
    // {
    //   title: 'Công',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'Giờ',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'Trễ',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'Về sớm',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'Về trễ',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'TC 1',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'TC 2',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'TC 3',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'TC 4',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   width: 75,
    // },
    // {
    //   title: 'Tổng giờ',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   fixed: 'right',
    //   width: 75,
    // },
    // {
    //   title: 'Đếm công',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   fixed: 'right',
    //   width: 85,
    // },
    // {
    //   title: 'Ký hiệu',
    //   dataIndex: 'tenbenhnhan',
    //   key: 'tenbenhnhan',
    //   fixed: 'right',
    //   width: 75,
    // },
  ]
  return (
    <>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div
          className="h-full p-2 bg-white border rounded-xl"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex gap-2">
            <div className="w-[17rem] flex items-center gap-2">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="w-[17rem]">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  setCompany(value)
                  fetchBranch(value)
                  setValue('all')
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
              />
            </div>
            <div className="w-[17rem] flex gap-2">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
              />
            </div>
            <div className="flex w-64">
              <Input
                value={search}
                placeholder="Nhập Mã NV, Tên NV..."
                onChange={(e) => setSearch(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="flex gap-2">
              <IconButton
                disabled={isLoading}
                onClick={onLoad}
                color="primary"
                aria-label="delete"
                size="small"
              >
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                disabled={isLoading || !dataList?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
                components: {
                  Table: {
                    headerBorderRadius: 0,
                  },
                },
              }}
            >
              <Table
                scroll={{
                  // x: 1750,
                  y: 715,
                }}
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                loading={isLoading}
                bordered
                dataSource={dataList}
                columns={columns}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChamCong
