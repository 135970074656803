import { useEffect, useRef, useState } from 'react'
import { Popover, AutoComplete } from 'antd'

function InputAdd({ nhanVien, addNhanVien, debounceDropDown, item }) {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const popoverRef = useRef(null)
  const handleSelect = (value, option) => {
    addNhanVien(option, item.DanhSachNhanVien, item.IDVUNG, setPopoverVisible)
  }

  const handleOpenChange = (newOpen) => {
    setPopoverVisible(newOpen)
  }

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setPopoverVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Popover
      trigger="click"
      open={popoverVisible}
      onOpenChange={handleOpenChange}
      content={() => {
        return (
          <div ref={popoverRef}>
            <AutoComplete
              placeholder="Nhập tên"
              style={{ width: '100%' }}
              options={nhanVien?.map((item) => ({
                label: item.tennv,
                value: item.tennv,
                id: item.id,
              }))}
              onSearch={debounceDropDown}
              onSelect={handleSelect} // Run function and close popover
            />
          </div>
        )
      }}
      title="Thêm người"
    >
      <button
        className="w-6 h-6 rounded bg-gray-300 dark:text-black"
        onClick={() => setPopoverVisible(true)} // Open the popover on button click
      >
        +
      </button>
    </Popover>
  )
}

export default InputAdd
