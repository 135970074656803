import React from 'react'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import { Divider, Flex, Tag } from 'antd'
const CalendarControls = () => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <Flex gap="4px 0" wrap>
          <div className="border py-1 rounded  w-[5rem] flex flex-col items-center justify-center bg-blue-100 text-blue-500 mr-2">
            Ca Sáng
          </div>
          <div className="border py-1 rounded w-[5rem]  flex flex-col items-center justify-center bg-yellow-100 text-yellow-500 mr-2">
            Ca Chiều
          </div>
          <div className="border py-1 rounded  w-[5rem] flex flex-col items-center justify-center bg-green-100 text-green-500 ">
            Ca Tối
          </div>
        </Flex>
      </div>
      {/* <button className="bg-blue-500 text-white p-2 rounded-md flex items-center">
          <EventRepeatIcon className="mr-2" />
      Tính lại ca và giờ làm việc
      </button> */}
    </div>
  )
}

export default CalendarControls
