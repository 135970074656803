import { Modal, Radio, Select, Empty, notification } from 'antd'
import React, { useCallback, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import { recieveService } from '../../../../services/receive/recieveService'
import { useDispatch } from 'react-redux'
import { gopBenhAn } from '../../../../store/actions/toolAction'
import 'moment/locale/vi'
moment.locale('vi')
const ModalGopBN = ({ openGop, setOpenGop }) => {
  const [api, contextHolder] = notification.useNotification()
  const [InfoBN1, setInfoBN1] = useState([])
  const [InfoBN2, setInfoBN2] = useState([])
  const [BN1, setBN1] = useState(null)
  const [BN2, setBN2] = useState(null)
  const dispatch = useDispatch()
  const [value, setValue] = useState(1)
  const handleCancel = () => {
    setOpenGop(false)
  }
  const openNotificationWithIcon = () => {
    api.warning({
      message: 'Chọn bệnh nhân',
      description: 'Bệnh nhân đã được chọn',
    })
  }
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        searchBN1(keyword)
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const debounceDropDown2 = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        searchBN2(keyword)
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const searchBN1 = async (keyword) => {
    try {
      const { data } = await recieveService.searchBN(keyword, ID_COMPANY)
      setInfoBN1(data)
    } catch (error) {
      console.log(error)
    }
  }
  const searchBN2 = async (keyword) => {
    try {
      const { data } = await recieveService.searchBN(keyword, ID_COMPANY)
      setInfoBN2(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChange = ({ target: { value } }) => {
    setValue(value)
  }
  const getInfoBN1 = async (id) => {
    try {
      if (BN2?.idbn === id) {
        openNotificationWithIcon()
        return
      }
      const { data } = await recieveService.getInfoBN(id)
      setBN1(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getInfoBN2 = async (id) => {
    try {
      if (BN1?.idbn === id) {
        openNotificationWithIcon()
        return
      }
      const { data } = await recieveService.getInfoBN(id)
      setBN2(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleGopBenhAn = () => {
    let bn = BN2
    let bndelete = BN1
    if (value === 2) {
      bndelete = BN2
      bn = BN1
    }
    dispatch(gopBenhAn(reset, bn.idbn, bndelete.idbn))
  }
  const reset = () => {
    setBN1(null)
    setBN2(null)
    setValue(1)
    handleCancel()
  }
  return (
    <>
      {contextHolder}
      <Modal
        onOk={handleGopBenhAn}
        okText="Gộp bệnh án"
        cancelText="Huỷ"
        width={'50%'}
        title={<p className="text-lg">Gộp bệnh án</p>}
        open={openGop}
        onCancel={handleCancel}
      >
        <div className="mt-5">
          <Radio.Group className="w-full" onChange={onChange} value={value}>
            <div className="my-2">
              <Select
                onSearch={debounceDropDown}
                value={null}
                onChange={getInfoBN1}
                filterOption={false}
                notFoundContent={null}
                placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân"
                suffixIcon={<SearchOutlined className=" " />}
                options={InfoBN1?.map((items) => ({
                  label: items.tenbenhnhan,
                  value: items.idbn,
                  MaBN: items.mabenhnhan,
                  ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
                  diaChi: items.diachi,
                  tinh: items.tentinhtp,
                  quan: items.tenquanhuyen,
                  xa: items.tenphuongxa,
                  gioiTinh: items.gioitinh,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-5/6">
                      <p className="font-semibold">
                        {options.data.label} - {options.data.MaBN}
                      </p>
                      <i className="text-gray-500">
                        Địa chỉ: {options.data.diaChi}
                        {options.data.xa && `, ${options.data.xa}`}
                        {options.data.quan && `, ${options.data.quan}`}
                        {options.data.tinh && `, ${options.data.tinh}`}
                      </i>
                    </li>
                    <li className="w-1/6 flex flex-col">
                      <p>Ngày sinh: {options.data.ngaySinh} </p>
                      <i className="text-gray-500">Giới tính: {options.data.gioiTinh}</i>
                    </li>
                  </ul>
                )}
                showSearch
                allowClear
                className="w-full"
              />
              <Radio value={1}>
                <div className="border min-w-[890px] rounded-lg mt-2">
                  {BN1 ? (
                    <div className="p-2">
                      <ul className="flex">
                        <li className="w-5/6">
                          <p className="font-semibold">
                            {BN1?.tenBenhNhan} - {BN1?.maBenhNhan}{' '}
                            {value === 1 && <span className="text-red-500">( Bệnh nhân xoá )</span>}
                          </p>
                          <i className="text-gray-500">
                            Địa chỉ: {BN1?.diaChi}
                            {BN1?.tenPhuongXa && `, ${BN1?.tenPhuongXa}`}
                            {BN1?.tenQuanHuyen && `, ${BN1?.tenQuanHuyen}`}
                            {BN1?.tenTinhTP && `, ${BN1?.tenTinhTP}`}
                          </i>
                        </li>
                        <li className="w-1/6 flex flex-col">
                          <p>Ngày sinh: {moment(BN1?.ngaySinh).format('DD/MM/YYYY')} </p>
                          <i className="text-gray-500">Giới tính: {BN1?.gioiTinh}</i>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <Empty description="Chưa có bệnh nhân" className="w-full" />
                  )}
                </div>
              </Radio>
            </div>
            <div className="my-2">
              <Select
                onSearch={debounceDropDown2}
                value={null}
                onChange={getInfoBN2}
                filterOption={false}
                notFoundContent={null}
                placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân"
                suffixIcon={<SearchOutlined className=" " />}
                options={InfoBN2?.map((items) => ({
                  label: items.tenbenhnhan,
                  value: items.idbn,
                  MaBN: items.mabenhnhan,
                  ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
                  diaChi: items.diachi,
                  tinh: items.tentinhtp,
                  quan: items.tenquanhuyen,
                  xa: items.tenphuongxa,
                  gioiTinh: items.gioitinh,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-5/6">
                      <p className="font-semibold">
                        {options.data.label} - {options.data.MaBN}
                      </p>
                      <i className="text-gray-500">
                        Địa chỉ: {options.data.diaChi}
                        {options.data.xa && `, ${options.data.xa}`}
                        {options.data.quan && `, ${options.data.quan}`}
                        {options.data.tinh && `, ${options.data.tinh}`}
                      </i>
                    </li>
                    <li className="w-1/6 flex flex-col">
                      <p>Ngày sinh: {options.data.ngaySinh} </p>
                      <i className="text-gray-500">Giới tính: {options.data.gioiTinh}</i>
                    </li>
                  </ul>
                )}
                showSearch
                allowClear
                className="w-full"
              />
              <Radio value={2} className="w-full ">
                <div className="border min-w-[890px] rounded-lg mt-2">
                  {BN2 ? (
                    <div className="p-2">
                      <ul className="flex">
                        <li className="w-5/6">
                          <p className="font-semibold">
                            {BN2?.tenBenhNhan} - {BN2?.maBenhNhan}{' '}
                            {value === 2 && <span className="text-red-500">( Bệnh nhân xoá )</span>}
                          </p>
                          <i className="text-gray-500">
                            Địa chỉ: {BN2?.diaChi}
                            {BN2?.tenPhuongXa && `, ${BN2?.tenPhuongXa}`}
                            {BN2?.tenQuanHuyen && `, ${BN2?.tenQuanHuyen}`}
                            {BN2?.tenTinhTP && `, ${BN2?.tenTinhTP}`}
                          </i>
                        </li>
                        <li className="w-1/6 flex flex-col">
                          <p>Ngày sinh: {moment(BN2?.ngaySinh).format('DD/MM/YYYY')} </p>
                          <i className="text-gray-500">Giới tính: {BN2?.gioiTinh}</i>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <Empty description="Chưa có bệnh nhân" className="w-full" />
                  )}
                </div>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </Modal>
    </>
  )
}

export default ModalGopBN
