import { call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { vltlService } from '../../services/vltl/vltlService'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* vltlSaga() {
  //LẤY THÔNG TIN BỆNH NHÂN VLTL
  yield takeLatest(
    typeAction.GET_INFO_BN_TAP_VLTL,
    function* getInfoBNTapVLTLSaga({ type, idbnttc }) {
      try {
        const result = yield call(() => vltlService.getInfoBNTapVLTL(idbnttc))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TAP_VLTL,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_SO_LAN_BN_TAP_VLTL,
          payload: null,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY DANH SÁCH BỆNH NHÂN CHỜ THỰC HIỆN VLTL
  yield takeLatest(typeAction.GET_LIST_BN_CHO_VLTL, function* getListBNChoVLTLSaga({ type, idcn }) {
    try {
      const result = yield call(() => vltlService.getListBNChoVLTL(idcn))
      yield put({
        type: typeAction.DISPATCH_LIST_BN_CHO_VLTL,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //LẤY DANH SÁCH BỆNH NHÂN ĐÃ THỰC HIỆN VLTL
  yield takeLatest(
    typeAction.GET_LIST_BN_DA_VLTL,
    function* getListBNDaVLTLSaga({ type, idcn, tungay, denngay }) {
      try {
        const result = yield call(() => vltlService.getListBNDaVLTL(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_VLTL,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LƯU BỆNH NHÂN TẬP VLTL
  yield takeLatest(
    typeAction.POST_BN_TAP_VLTL,
    function* postBNTapVLTLSaga({ type, form, idcnCho, idcnDa, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => vltlService.postBNTapVLTL(form))
        if (form.lancuoi) yield call(() => vltlService.putBNTapVLTL(form.idbnttc, form.iddv))
        const listChoVLTL = yield call(() => vltlService.getListBNChoVLTL(idcnCho))
        const listDaVLTL = yield call(() => vltlService.getListBNDaVLTL(idcnDa, tungay, denngay))
        const infoBNTapVLTL = yield call(() => vltlService.getInfoBNTapVLTL(form.idbnttc))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TAP_VLTL,
          payload: infoBNTapVLTL.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_VLTL,
          payload: listChoVLTL.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_VLTL,
          payload: listDaVLTL.data,
        })
        // yield put({
        //     type: typeAction.DISPATCH_INFO_BN_TAP_VLTL,
        //     payload: null,
        // })
        yield put({
          type: typeAction.DISPATCH_INFO_SO_LAN_BN_TAP_VLTL,
          payload: null,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu lần tập thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Lưu lần tập thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //LẤY DANH SÁCH SỐ LẦN BỆNH NHÂN TẬP VLTL
  yield takeLatest(
    typeAction.GET_INFO_SO_LAN_BN_TAP_VLTL,
    function* getInfoSoLanTapVLTLSaga({ type, idbnttc, iddv }) {
      try {
        const result = yield call(() => vltlService.getInfoSoLanTapVLTL(idbnttc, iddv))
        yield put({
          type: typeAction.DISPATCH_INFO_SO_LAN_BN_TAP_VLTL,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
}
