import * as typeAction from '../constants/constants'

export const infoBNDienTim = (idbnttc,idbndt) => ({
  type: typeAction.GET_INFO_BN_DIEN_TIM,
  idbnttc,
  idbndt
})
export const saveBnDienTIm = (form, reload) => ({
  type: typeAction.POST_BENH_NHAN_DIEN_TIM,
  form,
  reload,
})
export const infoBnPending = (idbnttc) => ({
  type: typeAction.GET_INFO_BN_PENDING_DIEN_TIM,
  idbnttc
})

