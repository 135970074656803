import { CheckCircleOutlined, ContainerOutlined, SyncOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import PrintIcon from '@mui/icons-material/Print'
import {
  Button as AntButton,
  ConfigProvider,
  DatePicker,
  Input,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import {
  getphieuChuyen,
  listInfoThuocVTChyenKho_Action,
  printerAutoChuyenKho,
} from '../../../../store/actions/chuyenKhoAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const fomatDB = 'YYYY-MM-DD'
const now = moment().format(fomatDB)
const SoPhieu = ({ showModal, cancelModal, setStatusUpdate }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const ID_COMPANY = localStorage.getItem('id_company')
  const dispatch = useDispatch()
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [companys, setCompany] = useState([])
  const [idCT, setIdCT] = useState(Number(ID_COMPANY))
  const [data, setData] = useState([])
  const [searchKho, setSearchKho] = useState('')
  const [khoNhap, setKhoNhap] = useState('')
  const [idBranh, setIdBranch] = useState(Number(BranchLogin))
  const [searchName, setSearchName] = useState('')
  const [searchMa, setSearchMa] = useState('')
  const [searchNguoiXuat, setSearchNguoiXuat] = useState('')
  const [branhNhan, setBranchNhan] = useState('')
  const [branchChuyen, setBranchChuyen] = useState('')
  const [NgayNhan, setNgayNhan] = useState('')
  const [NgayChuyen, setNgayChuyen] = useState('')
  const [nguoiNhan, setNguoiNhan] = useState('')
  const [ListBranch, setListBranch] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [ghiChu, setGhiChu] = useState('')
  const [thucTra, setThucTra] = useState('')
  const [statusFilter, setStatusFilter] = useState(null)
  const hasSelected = selectedRowKeys.length > 0
  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    const formatValue = moment(dateString, dateFormat).format(fomatDB)
    if (name === 'since') {
      setSince(formatValue)
    } else {
      SetToDate(formatValue)
    }
  }
  const handleSelectedBranhNhan = (value, option) => {
    setBranchNhan(value || '')
    if (value) {
      // fetchKho(option.id)
    }
    setSearchKho('')
  }
  const filter = {
    fromDate: since, //
    toDate: toDate,
    idCongTy: idCT,
    idChiNhanh: idBranh,
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // bật trạng thái cho phép update
  }
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    try {
      const { data } = await branchService.getListBranch(idCT)
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  // ---------- lấy danh sách công ty ---------
  const fetchCompany = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setCompany(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchData = () => {
    dispatch(getphieuChuyen(filter, setData, setIsLoading))
  }
  ///---- in phiếu hàng loạt -------
  const autoPrin = (isA5) => {
    if (hasSelected) {
      dispatch(printerAutoChuyenKho(selectedRowKeys, setSelectedRowKeys, isA5))
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const handleTableChange = (pagination, filters, sorter) => {
    const currentStatusFilter = filters.trangThai ? filters.trangThai[0] : null
    setStatusFilter(currentStatusFilter)
  }
  useEffect(() => {
    fetchBranch()
  }, [idCT])
  useEffect(() => {
    fetchData()
    fetchCompany()
  }, [])
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maPhieu',
      key: 'MAPHmaPhieuIEU',
      width: 120,
      sorter: (a, b) => compare(a, b, 'maPhieu'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={(e) => setSearchMa(e.target.value)} value={searchMa} />
            </>
          ),
          width: 120,
          dataIndex: 'maPhieu',
          key: 1,
        },
      ],
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'tenPhieu',
      key: 'tenPhieu',
      width: 200,
      sorter: (a, b) => compare(a, b, 'tenPhieu'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchName(e.target.value)}
                value={searchName}
              />
            </>
          ),
          width: 200,
          dataIndex: 'tenPhieu',
          key: 1,
        },
      ],
    },
    {
      title: 'Người chuyển',
      dataIndex: 'tenNVXuat',
      key: 'tenNVXuat',
      sorter: (a, b) => compare(a, b, 'tenNVXuat'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchNguoiXuat(e.target.value)}
                value={searchNguoiXuat}
              />
            </>
          ),
          dataIndex: 'tenNVXuat',
          key: 1,
          width: 160,
        },
      ],
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'ngayXuat',
      key: 'ngayXuat',
      width: 160,
      sorter: (a, b) => compare(a, b, 'ngayXuat'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <DatePicker
              className="w-full"
              value={NgayChuyen !== '' ? dayjs(NgayChuyen, 'YYYY-MM-DD') : ''}
              size="small"
              format={'DD/MM/YYYY'}
              onChange={(date, DateString) => {
                setNgayChuyen(
                  DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                )
              }}
            />
          ),
          dataIndex: 'ngayXuat',
          key: 2,
          width: 160,
        },
      ],
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      sorter: (a, b) => compare(a, b, 'tenChiNhanhXuat'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <Select
              size="small"
              className="w-full"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onChange={(value) => setBranchChuyen(value)}
              value={branchChuyen}
              options={[
                { label: 'Tất cả', value: '' },
                ...ListBranch?.map((item) => ({
                  label: item.tenChiNhanh,
                  value: item.tenChiNhanh,
                  id: item.idChiNhanh,
                })),
              ]}
            />
          ),
          dataIndex: 'tenChiNhanhXuat',
          key: 1,
          width: 190,
        },
      ],
    },
    {
      title: 'kho Chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      sorter: (a, b) => compare(a, b, 'tenKhoXuat'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchKho(e.target.value)}
                value={searchKho}
              />
            </>
          ),
          dataIndex: 'tenKhoXuat',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Người nhận',
      dataIndex: 'tenNVNhan',
      key: 'tenNVNhan',
      width: 160,
      sorter: (a, b) => compare(a, b, 'tenNVNhan'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setNguoiNhan(e.target.value)}
                value={nguoiNhan}
              />
            </>
          ),
          dataIndex: 'tenNVNhan',
          key: 2,
          width: 160,
        },
      ],
    },
    {
      title: 'Ngày nhận',
      dataIndex: 'ngayNhan',
      key: 'ngayNhan',
      width: 160,
      sorter: (a, b) => compare(a, b, 'ngayNhan'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <DatePicker
              className="w-full"
              value={NgayNhan !== '' ? dayjs(NgayNhan, 'YYYY-MM-DD') : ''}
              size="small"
              format={'DD/MM/YYYY'}
              onChange={(date, DateString) => {
                setNgayNhan(DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
              }}
            />
          ),
          dataIndex: 'ngayNhan',
          key: 2,
          width: 160,
        },
      ],
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'tenChiNhanhNhan',
      key: 'tenChiNhanhNhan',
      sorter: (a, b) => compare(a, b, 'tenChiNhanhNhan'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Select
                size="small"
                className="w-full"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                onChange={handleSelectedBranhNhan}
                value={branhNhan}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListBranch?.map((item) => ({
                    label: item.tenChiNhanh,
                    value: item.tenChiNhanh,
                    id: item.idChiNhanh,
                  })),
                ]}
              />
            </>
          ),
          dataIndex: 'tenChiNhanhNhan',
          key: 2,
          width: 190,
        },
      ],
    },
    {
      title: 'kho nhận',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      sorter: (a, b) => compare(a, b, 'tenKhoNhap'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={(e) => setKhoNhap(e.target.value)} value={khoNhap} />
            </>
          ),
          dataIndex: 'tenKhoNhap',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Thành tiền',
      dataIndex: 'sumThucTra',
      key: 'sumThucTra',
      sorter: (a, b) => compare(a, b, 'sumThucTra'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                type="Number"
                onChange={(e) => setThucTra(e.target.value)}
                value={thucTra}
              />
            </>
          ),
          dataIndex: 'sumThucTra',
          key: 2,
          align: 'right',
          width: 150,
        },
      ],
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      key: 'ghiChu',
      sorter: (a, b) => compare(a, b, 'ghiChu'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={(e) => setGhiChu(e.target.value)} value={ghiChu} />
            </>
          ),
          dataIndex: 'ghiChu',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangThai',
      key: 'trangThai',
      width: 120,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <Tag
          className="font-semibold"
          icon={text === 3 ? <CheckCircleOutlined /> : text === 2 ? <SyncOutlined /> : null}
          color={text === 3 ? '#87d068' : text === 1 ? 'error' : '#108ee9'}
        >
          {record.tenTrangThai}
        </Tag>
      ),
      filters: [
        {
          text: 'Mới chuyển',
          value: 1,
        },
        {
          text: 'Đã rời kho',
          value: 2,
        },
        {
          text: 'Đã nhận',
          value: 3,
        },
      ],
      onFilter: (value, record) => record.trangThai === value,
    },

    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'ACTION',
      width: 80,
      align: 'center',
      fixed: 'right',
    },
  ]

  const filteredData = data?.filter((items) => {
    const findKho = searchKho?.toLowerCase() ?? ''
    const findMa = searchMa?.trim().toLowerCase() ?? ''
    const nameLower = searchName?.trim().toLowerCase() ?? ''
    const nguoiXuatLower = searchNguoiXuat?.trim().toLowerCase() ?? ''
    const branchNhanLower = branhNhan?.trim().toLowerCase() ?? ''
    const branchChuyenLower = branchChuyen?.trim().toLowerCase() ?? ''
    const ngayChuyenLower = NgayChuyen?.trim().toLowerCase() ?? ''
    const ngayNhanLower = NgayNhan?.trim().toLowerCase() ?? ''
    const nguoiNhanLower = nguoiNhan?.trim().toLowerCase() ?? ''
    const khoNhapLower = khoNhap?.trim().toLowerCase() ?? ''
    const thuctraLower = thucTra?.toString().toLowerCase() ?? '' // If thucTra is not a string, convert it first
    const ghiChuLower = ghiChu?.trim().toLowerCase() ?? ''

    return (
      (items?.tenKhoNhap?.toLowerCase().includes(findKho) || !items?.tenKhoNhap) &&
      (items?.maPhieu?.toLowerCase().includes(findMa) || !items?.maPhieu) &&
      (items?.tenPhieu?.toLowerCase().includes(nameLower) || !items?.tenPhieu) &&
      (items?.tenNVXuat?.toLowerCase().includes(nguoiXuatLower) || !items?.tenNVXuat) &&
      (items?.tenChiNhanhXuat?.toLowerCase().includes(branchChuyenLower) ||
        !items?.tenChiNhanhXuat) &&
      (items?.tenChiNhanhNhan?.toLowerCase().includes(branchNhanLower) ||
        !items?.tenChiNhanhNhan) &&
      (items?.ngayXuat?.toLowerCase().includes(ngayChuyenLower) || !items?.ngayXuat) &&
      (items?.ngayNhan?.toLowerCase().includes(ngayNhanLower) || !items?.ngayNhan) &&
      (items?.tenNVNhan?.toLowerCase().includes(nguoiNhanLower) || !items?.tenNVNhan) &&
      (items?.tenKhoNhap?.toLowerCase().includes(khoNhapLower) || !items?.tenKhoNhap) &&
      (items?.sumThucTra?.toString().toLowerCase().includes(thuctraLower) || !items?.sumThucTra) &&
      ((items?.ghiChu || '').toLowerCase().includes(ghiChuLower) || !items?.ghiChu)
    )
  })

  const exportToExcel = () => {
    const filteredExportData = filteredData?.filter((item) => {
      if (statusFilter && item.trangThai !== statusFilter) return false
      return true
    })
    const formattedData = filteredExportData.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': item.ngayXuat ? moment(item.ngayXuat).format('DD/MM/YYYY hh:mm:ss') : '-',
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Ngày Nhận ': item.tenKhoNhap ? moment(item.tenKhoNhap).format('DD/MM/YYYY hh:mm:ss') : '-',
      'Người nhận': item.nhanVienNhan,
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
      'Thành tiền': item.sumThucTra ? formatNumberVND(Math.round(item.sumThucTra)) : 0,
      'Ghi chú': item.ghiChu,
      'Trạng thái': item.tenTrangThai,
    }))
    const name = 'Danh sách phiếu chuyển kho'
    exportExcelformat(formattedData, name)
  }
  // Summing up a specific property in filteredData
  const total = filteredData?.reduce((t, item) => t + (item.someProperty || 0), 0) ?? 0

  const columSummary = () => {
    const NumberColum = 16
    const tonKhoData = {
      12: total, // Assuming column 12 should show the total
    }

    return Array.from({ length: NumberColum }, (_, i) => ({
      key: i,
      value: tonKhoData[i] != null ? formatNumberVND(tonKhoData[i]) : null,
    }))
  }
  const summaryData = columSummary()
  return (
    <div className="h-full">
      <div className="flex gap-2 items-center">
        <div className="w-72 gap-2 items-center flex">
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => {
              setIdCT(value)
              setIdBranch(null)
            }}
            value={idCT}
            options={companys?.map((item) => ({
              label: item.tenct,
              value: item.idct,
            }))}
          />
        </div>
        <div className="w-72 gap-2 items-center flex">
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => {
              setIdBranch(value)
            }}
            value={idBranh}
            options={[
              { label: 'Tất cả', value: null },
              ...ListBranch?.map((item) => ({
                label: item.tenChiNhanh,
                value: item.idChiNhanh,
              })),
            ]}
          />
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleSince('since')}
              format={dateFormat}
            />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
          </svg>
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              // minDate={dayjs(since, dateFormat)}
              onChange={handleSince('toDate')}
              format={dateFormat}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={fetchData}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3">
          <Popconfirm
            title="In phiếu chuyển kho"
            onConfirm={() => autoPrin(false)}
            onCancel={() => autoPrin(true)}
            okText="A4"
            cancelText="A5"
            icon={<PrintIcon color="primary" />}
            cancelButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            okButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            className={`${hasSelected ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            disabled={!hasSelected}
          >
            <PrintIcon
              className={`cursor-pointer rounded-sm ${hasSelected ? 'text-blue-500' : 'text-gray-500'}  hover:bg-slate-200`}
            />
          </Popconfirm>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
              borderRadius: 0,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
              },
            },
          }}
        >
          <Table
            rowSelection={rowSelection}
            loading={isLoading}
            bordered
            pagination={false}
            scroll={{
              x: 1400,
              y: 660,
            }}
            columns={columns}
            onChange={handleTableChange}
            dataSource={filteredData?.map((items, index) => ({
              key: items.idNhapXuat,
              STT: ++index,
              maPhieu: items.maPhieu,
              tenNVXuat: items.tenNVXuat,
              tenPhieu: items.tenPhieu,
              ngayXuat: moment(items.ngayXuat).format('DD/MM/YYYY hh:mm:ss'),
              ngayNhan: items.ngayNhan ? moment(items.ngayNhan).format('DD/MM/YYYY hh:mm:ss') : '-',
              tenChiNhanhXuat: items.tenChiNhanhXuat,
              tenChiNhanhNhan: items.tenChiNhanhNhan,
              tenNVNhan: items.tenNVNhan,
              tenKhoXuat: items.tenKhoXuat,
              tenKhoNhap: items.tenKhoNhap,
              trangThai: items.trangThai,
              tenTrangThai: items.tenTrangThai,
              sumThucTra: Math.round(items.sumThucTra),
              ghiChu: items?.ghiChu,
              ACTION: (
                <div className="flex gap-4 justify-center ">
                  <Tooltip title="Xem chi tiết" color="#108ee9">
                    <ContainerOutlined
                      onClick={() => {
                        handleShowModalDetail(items.idNhapXuat)
                      }}
                      className="text-xl text-[#108ee9]  cursor-pointer"
                    />
                  </Tooltip>
                </div>
              ),
            }))}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {summaryData?.map((item) => (
                    <Table.Summary.Cell align="right" index={item.key}>
                      {' '}
                      <p className="font-semibold">{item.value}</p>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default SoPhieu
