import * as typeAction from '../constants/constants'

export const postKhachHangDTAction = (list) => ({
  type: typeAction.POST_KHACH_HANG_DT,
  list,
})
export const putKhachHangDTAction = (form, resetForm) => ({
  type: typeAction.PUT_KHACH_HANG_DT,
  form,
  resetForm,
})
