import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { https } from '../../../../services/apiService'
import { editGoiKhamKhachHangCRM } from '../../../../store/actions/goikhamkhachhangCRMAction'
import ToastCus from '../../../../utils/Toast'
const dateFormat = 'DD-MM-YYYY'

const ModalEditPhanCong = ({ openEdit, setOpenEdit }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listGoiKhamKH } = useSelector((state) => state.goikhamkhachhangCRMReducer)

  const [allGoiKham, setAllGoiKham] = useState([])
  const [allKhachHang, setAllKhachHang] = useState([])
  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    if (value.idgk.length === 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui chọn gói khám',
      })
      return
    }
    const formatData = value.idgk.map((item) => ({
      idgk: item,
      idkh: value.idkh,
      nguoisua: infoUser.idnv,
    }))
    dispatch(editGoiKhamKhachHangCRM(formatData))
    formik.resetForm()
    setOpenEdit(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idkh: openEdit.data?.idkh,
      nguoisua: infoUser?.idnv,
      idgk: listGoiKhamKH?.map((item) => item.crmGoiKham.idgk),
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('CRMGoiKham/GetAll')
        setAllGoiKham(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('CRMKhachHang/GetAll')
        setAllKhachHang(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Huỷ"
        className="text-center"
        title={'Chi tiết gói khám khách hàng'}
        onOk={formik.handleSubmit}
        open={openEdit.open}
        onCancel={() => setOpenEdit({ open: false, data: null })}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Khách hàng</label>
            <Input value={openEdit.data?.tenkh} readOnly />
          </div>
          <div>
            <label className="font-semibold  text-base">Gói khám</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              mode="multiple"
              value={formik.values.idgk}
              options={allGoiKham?.map((items) => ({
                label: items.tengoikham,
                value: items.idgk,
              }))}
              onChange={handleSelected('idgk')}
              className="w-full"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditPhanCong
