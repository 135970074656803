import * as typeAction from '../constants/constants'

export const getListBNChoAction = (idcn) => ({
  type: typeAction.GET_LIST_BN_CHO_THUTHUAT,
  idcn,
})
export const getListBNDaThucHienAction = (idcn, tungay, denngay) => ({
  type: typeAction.GET_LIST_BN_DATHUCHIEN_THUTHUAT,
  idcn,
  tungay,
  denngay,
})
export const getInfoBNDVThuThuatAction = (idbnttc, idcn) => ({
  type: typeAction.GET_INFO_BN_DV_THUTHUAT,
  idbnttc,
  idcn,
})
export const getListVTTieuHaoThuThuatAction = (idcn) => ({
  type: typeAction.GET_LIST_VT_THUTHUAT,
  idcn,
})
export const getListVTTHThuThuatTheoKhoAction = (idcn, khoChon) => ({
  type: typeAction.GET_LIST_VTTH_THEOKHO_THUTHUAT,
  idcn,
  khoChon,
})
export const postListVTBNThuThuatAction = (list, listdv, idcn, tungay, denngay) => ({
  type: typeAction.POST_LIST_VT_BN_THUTHUAT,
  list,
  listdv,
  idcn,
  tungay,
  denngay,
})
export const putListVTBNThuThuatAction = (list,listdv, idbnttc, idcn, tungay, denngay) => ({
  type: typeAction.PUT_LIST_VT_BN_THUTHUAT,
  list,
  listdv,
  idbnttc,
  idcn,
  tungay,
  denngay,
})
