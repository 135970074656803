import { ArrowDownOutlined, SearchOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Input,
  Select,
  Space,
  Statistic,
  Table,
  TreeSelect,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { https } from '../../../services/apiService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { formatNumber } from 'devextreme/localization'
import 'moment/locale/vi'
moment.locale('vi')

const loaiKham = [
  {
    id: 1,
    value: 'Khám bệnh mới',
    text: 'Khám bệnh mới',
  },
  {
    id: 2,
    value: 'Tái khám',
    text: 'Tái khám',
  },
  {
    id: 4,
    value: 'Khám lần đầu',
    text: 'Khám lần đầu',
  },
]

const { SHOW_PARENT } = TreeSelect
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

const BaoCaoPT = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [company, setCompany] = useState(id_company)
  const [phieuThu, setPhieuThu] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [nguonKH, setNguonKH] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [branch, setBranch] = useState([])

  const fetchBranch = async (id) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${id}`)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchReport = async () => {
    setIsLoading(true)
    try {
      let idChiNhanhs = null
      // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `BenhNhanThongTinChung/ThongKePhieuThuByCondition?idCongTy=${company}${idChiNhanhs}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setPhieuThu(data)
      } else {
        const { data } = await https.get(
          `BenhNhanThongTinChung/ThongKePhieuThuByCondition?idCongTy=${company}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setPhieuThu(data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('NguonKH')
        setNguonKH(
          data.map((item) => ({
            ...item,
            value: item.nguon,
            text: item.nguon,
          })),
        )
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  const CountLanDau = useMemo(() => {
    return (
      phieuThu?.reduce((count, items) => {
        if (items.tenLoaiKham === 'Khám lần đầu') {
          count += 1
        }
        return count
      }, 0) ?? 0
    )
  }, [phieuThu])

  const countBenhmoi = useMemo(() => {
    return (
      phieuThu?.reduce((count, items) => {
        if (items.tenLoaiKham === 'Khám bệnh mới') {
          count += 1
        }
        return count
      }, 0) ?? 0
    )
  }, [phieuThu])

  const taikham = useMemo(() => {
    return (
      phieuThu?.reduce((count, items) => {
        if (items.tenLoaiKham === 'Tái khám') {
          count += 1
        }
        return count
      }, 0) ?? 0
    )
  }, [phieuThu])

  const quaNgay = useMemo(() => {
    return (
      phieuThu?.reduce((count, items) => {
        if (items.tenLoaiKham === 'Khám bệnh quá ngày tái khám') {
          count += 1
        }
        return count
      }, 0) ?? 0
    )
  }, [phieuThu])

  const TotalPriceGiam = useMemo(() => {
    return phieuThu?.reduce((total, item) => total + item.tienGiamGia, 0) ?? 0
  }, [phieuThu])
  const TotalPricePay = useMemo(() => {
    return phieuThu?.reduce((total, item) => total + item.tienThanhToan, 0) ?? 0
  }, [phieuThu])
  const dispatch = useDispatch()

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  //------------ quyền người dùng ----------
  const ruleUser = ['Lễ tân '].includes(infoUser?.tenNhom)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 40,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã PT',
      dataIndex: 'maPhieuThu',
      width: 140,
      // align: "center",
      fixed: 'left',
      key: 'maPhieuThu',
      ...getColumnSearchProps('maPhieuThu'),
    },
    {
      title: 'Ngày thu',
      dataIndex: 'ngayThu',
      width: 140,
      align: 'center',
      key: 'ngayThu',
      fixed: 'left',
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.ngayThu
          ? moment(record.ngayThu).format('DD/MM/YYYY HH:mm:ss')
          : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.ngayThu).unix() - moment(b.ngayThu).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      fixed: 'left',
      ...getColumnSearchProps('tenBenhNhan'),
      onFilter: (value, record) => record.tenBenhNhan.indexOf(value) === 0,
      sorter: (a, b) => a.tenBenhNhan.length - b.tenBenhNhan.length,
      sortDirections: ['descend'],
      width: 250,
    },
    {
      title: 'Mã BN',
      dataIndex: 'maBenhNhan',
      width: 140,
      fixed: 'left',
      align: 'left',
      key: 'maBenhNhan',
      ...getColumnSearchProps('maBenhNhan'),
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaySinh',
      width: 100,
      align: 'center',
      key: 'ngaySinh',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.ngaySinh
          ? moment(record.ngaySinh).format('DD/MM/YYYY HH:mm:ss')
          : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.ngaySinh).unix() - moment(b.ngaySinh).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Loại khám',
      dataIndex: 'tenLoaiKham',
      width: 120,
      align: 'center',
      key: 'tenLoaiKham',
      filters: loaiKham,
      onFilter: (value, record) => record.tenLoaiKham.indexOf(value) === 0,
    },
    {
      title: 'Nguồn khách',
      dataIndex: 'nguon',
      align: 'center',
      width: 120,
      key: 'nguon',
      filters: nguonKH,
      onFilter: (value, record) => record.nguon.indexOf(value) === 0,
    },
    // {
    //   title: 'Hình thức',
    //   // dataIndex: '',
    //   width: 105,
    //   align: 'center',
    //   key: 'hinhThuc',
    //   filters: [
    //     {
    //       text: 'Tiền mặt',
    //       value: 0,
    //     },
    //     {
    //       text: 'Chuyển khoản',
    //       value: 1,
    //     },
    //   ],
    //   onFilter: (value, record) =>
    //     (record.chuyenKhoan > 0 ||
    //     record.theTT > 0 ||
    //     record.moMo > 0 ||
    //     record.vnPay > 0 ||
    //     record.zaloPay > 0 ||
    //     record.pos > 0
    //       ? 1
    //       : 0) === value,
    //   render: (text, record, index) =>
    //     record.chuyenKhoan > 0 ||
    //     record.theTT > 0 ||
    //     record.moMo > 0 ||
    //     record.vnPay > 0 ||
    //     record.zaloPay > 0 ||
    //     record.pos > 0
    //       ? 'Chuyển khoản'
    //       : 'Tiền mặt',
    //   // sorter: {
    //   //   compare: (a, b) => { return (a?.tienMat ?? 0) - (b?.tienMat ?? 0) },
    //   // },
    // },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienMat',
      key: 'tienMat',
      align: 'right',
      width: 120,
      render: (text) => formattedNumber(text),
      sorter: {
        compare: (a, b) => {
          return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
        },
      },
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenKhoan',
      key: 'chuyenKhoan',
      width: 120,
      align: 'right',
      render: (text) => formattedNumber(text),
      sorter: {
        compare: (a, b) => {
          return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
        },
      },
    },
    {
      title: 'Máy Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 120,
      align: 'right',
      render: (text) => formattedNumber(text),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.pos ?? '').localeCompare(b?.pos ?? '')
      //   },
      // },
    },
    {
      title: 'Momo',
      dataIndex: 'moMo',
      key: 'moMo',
      align: 'right',
      width: 120,
      render: (text) => formattedNumber(text),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'VNPay',
      dataIndex: 'vnPay',
      key: 'vnPay',
      align: 'right',
      render: (text) => formattedNumber(text),
      width: 120,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.vnPay ?? '').localeCompare(b?.vnPay ?? '')
      //   },
      // },
    },
    {
      title: 'ZaloPay',
      dataIndex: 'zaloPay',
      key: 'zaloPay',
      align: 'right',
      render: (text) => formattedNumber(text),
      width: 120,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.zaloPay ?? '').localeCompare(b?.zaloPay ?? '')
      //   },
      // },
    },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'theTT',
      key: 'theTT',
      align: 'right',
      render: (text) => formattedNumber(text),
      width: 140,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.theTT ?? '').localeCompare(b?.theTT ?? '')
      //   },
      // },
    },
    {
      title: 'Mã TTV',
      dataIndex: 'maThe',
      key: 'maThe',
      width: 100,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tenThe ?? '').localeCompare(b?.tenThe ?? '')
      //   },
      // },
    },
    {
      title: 'Thẻ thành viên',
      dataIndex: 'tenThe',
      key: 'tenThe',
      width: 250,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tenThe ?? '').localeCompare(b?.tenThe ?? '')
      //   },
      // },
    },

    {
      title: 'Voucher',
      dataIndex: 'maVoucher',
      width: 100,
      key: 'maVoucher',
      sorter: {
        compare: (a, b) => {
          return (a?.maVoucher ?? '').localeCompare(b?.maVoucher ?? '')
        },
      },
    },
    {
      title: 'Voucher đối tác',
      dataIndex: 'maVoucherDoiTac',
      width: 130,
      key: 'maVoucherDoiTac',
      sorter: {
        compare: (a, b) => {
          return (a?.maVoucherDoiTac ?? '').localeCompare(b?.maVoucherDoiTac ?? '')
        },
      },
    },
    {
      title: 'Người thu',
      dataIndex: 'thuNgan',
      width: 200,
      key: 'thuNgan',
      ...getColumnSearchProps('thuNgan'),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTien',
      key: 'tongTien',
      align: 'right',
      width: 100,
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tongTien === Number(value),
      sorter: (a, b) => a.tongTien - b.tongTien,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tienGiamGia',
      key: 'tienGiamGia',
      align: 'right',
      width: 100,
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tienGiamGia === Number(value),
      sorter: (a, b) => a.tienGiamGia - b.tienGiamGia,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Thanh toán',
      dataIndex: 'tienThanhToan',
      key: 'tienThanhToan',
      width: 100,
      fixed: 'right',
      align: 'right',
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tienThanhToan === Number(value),
      sorter: (a, b) => a.tienThanhToan - b.tienThanhToan,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 150,
      fixed: 'right',
    },
  ]

  const exportToExcel = () => {
    const headers = [
      'Mã phiếu thu',
      'Ngày thu',
      'Chi nhánh',
      'Tên bệnh nhân',
      'Mã bệnh nhân',
      'Ngày sinh',
      'Loại khám',
      'Nguồn khách',
      'Tiền mặt',
      'Chuyển khoản',
      'Máy Pos',
      'Momo',
      'VNPay',
      'ZaloPay',
      'Thẻ thanh toán',
      'Thẻ TV',
      'Tên thẻ',
      'Voucher',
      'Voucher đối tác',
      'Người thu',
      'Tiền mặt',
      'Chuyển khoản',
      'Xuất hoá đơn',
      'Ghi chú xuất hoá đơn',
      'Tổng tiền',
      'Tiền giảm',
      'Thanh toán',
    ]

    const formattedData = phieuThu.map((item) => ({
      'Mã phiếu thu': `PT${item.maBenhNhan}`,
      'Ngày thu': moment(item.ngayThu).format('DD/MM/YYYY HH:mm'),
      'Chi nhánh': item.tenChiNhanh,
      'Tên bệnh nhân': item.tenBenhNhan,
      'Mã bệnh nhân': item.maBenhNhan,
      'Ngày sinh': moment(item.ngaySinh).format('DD/MM/YYYY HH:mm'),
      'Loại khám': item.tenLoaiKham,
      'Nguồn khách': item.nguon,
      'Tiền mặt': formatNumber(item.tienMat),
      'Chuyển khoản': formatNumber(item.chuyenKhoan),
      'Máy Pos': formatNumber(item.pos),
      'Momo': formatNumber(item.moMo),
      'VNPay': formatNumber(item.vnPay),
      'ZaloPay': formatNumber(item.zaloPay),
      'Thẻ thanh toán': formatNumber(item.theTT),
      'Thẻ TV': item.maThe,
      'Tên thẻ':item.tenThe,
      'Voucher': item.maVoucher,
      'Voucher đối tác': item.maVoucherDoiTac,
      'Người thu': item.thuNgan,
      'Xuất hoá đơn': item.hoaDonDienTu !== 0 ? 'Có' : 'Không',
      'Ghi chú xuất hoá đơn': item.ghiChuXuatHoaDon,
      'Tổng tiền': formatNumber(item.tongTien),
      'Tiền giảm': formatNumber(item.tienGiamGia),
      'Thanh toán': formatNumber(item.tienThanhToan),
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Phiếu thu.xlsx')
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
    fetchReport()
  }, [])

  return (
    <>
      <>
        <div className="p-2  bg-[#E8E8E8] h-full">
          <div
            className="bg-white rounded-md border p-2 h-full"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <div className="flex justify-between">
              <div className="w-3/5 flex gap-2 justify-between">
                <ConfigProvider>
                  <Card size="small" className="w-1/3 ">
                    <Statistic
                      title="Tổng tiền giảm"
                      value={TotalPriceGiam}
                      valueStyle={{
                        color: 'red',
                      }}
                      prefix={<ArrowDownOutlined />}
                      suffix="VNĐ"
                    />
                  </Card>
                  <Card size="small" className="w-1/3 ">
                    <Statistic
                      title="Tổng doanh thu"
                      value={TotalPricePay}
                      valueStyle={{
                        color: '#3f8600',
                      }}
                      prefix={<WalletOutlined />}
                      suffix="VNĐ"
                    />
                  </Card>
                  <Card size="small" className="w-1/3">
                    <Statistic
                      title="Tổng lượt khách"
                      value={phieuThu ? phieuThu.length : 0}
                      valueStyle={{
                        color: '#3f8600',
                      }}
                      prefix={<UserOutlined />}
                    />
                  </Card>
                </ConfigProvider>
              </div>
              <div className="w-2/5 flex items-center justify-around">
                <Statistic title="Lần đầu" value={CountLanDau} suffix={`/ ${phieuThu?.length}`} />
                <Statistic title="Bệnh mới" value={countBenhmoi} suffix={`/ ${phieuThu?.length}`} />
                <Statistic title="Tái khám" value={taikham} suffix={`/ ${phieuThu?.length}`} />
              </div>
            </div>
            <div className="border mt-5 rounded-md ">
              <div className="flex gap-5 border-dotted border-b-0  p-2 ">
                <Select
                  showSearch
                  value={company}
                  onChange={(value) => {
                    setCompany(value)
                    fetchBranch(value)
                    setValue([])
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={listCompany?.map(({ idct, tenct }) => ({
                    label: tenct,
                    value: idct,
                  }))}
                  className="w-80"
                />
                <div className="w-80">
                  <TreeSelect
                    {...tProps}
                    style={{ flexWrap: 'unset' }}
                    className="w-full h-8 overflow-clip"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <DatePicker
                    allowClear={false}
                    defaultValue={dayjs(now)}
                    maxDate={dayjs(now)}
                    onChange={handleDatePicker('form')}
                    format={dateFormat}
                  />
                  <span className="font-semibold text-lg">-</span>
                  <DatePicker
                    allowClear={false}
                    defaultValue={dayjs(now)}
                    maxDate={dayjs(now)}
                    onChange={handleDatePicker('to')}
                    format={dateFormat}
                  />
                </div>

                <div className="flex gap-3">
                  <IconButton
                    onClick={fetchReport}
                    color="primary"
                    aria-label="delete"
                    size="small"
                  >
                    <SyncIcon />
                  </IconButton>
                </div>
                <div className="flex gap-3 ml-auto">
                  <Button
                    onClick={exportToExcel}
                    type="text"
                    size="middle"
                    className="text-green-700 p-1"
                    icon={<ImportExportIcon />}
                  >
                    Xuất Excel
                  </Button>
                </div>
              </div>
              <div>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                      fontSize: 14,
                    },
                    components: {
                      Table: {
                        headerBorderRadius: 0,
                        rowHoverBg: '#ecf0f1',
                      },
                    },
                  }}
                >
                  <Table
                    locale={{
                      triggerDesc: 'Sắp xếp giảm dần',
                      triggerAsc: 'Sắp xếp tăng dần',
                      cancelSort: 'Hủy sắp xếp',
                    }}
                    pagination={{
                      pageSize: 50,
                      showSizeChanger: false,
                    }}
                    loading={isLoading}
                    bordered
                    dataSource={phieuThu}
                    columns={columns}
                    scroll={{
                      x: 2000,
                      y:'61vh'
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default BaoCaoPT
