import * as XLSX from 'xlsx'
import { formattedNumber } from '../formattedNumber'

export const exportExcelformat = (data, name) => {
  // Tạo worksheet từ dữ liệu đã định dạng
  const worksheet = XLSX.utils.json_to_sheet(data)
  const headers = Object.keys(data[0])

  // Thêm tiêu đề vào worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })
  // Bật chức năng filter cho các cột
  const range = XLSX.utils.decode_range(worksheet['!ref'])
  worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) }
  // Tự động điều chỉnh kích thước cột
  const wscols = headers.map((header) => ({
    wch: Math.max(
      header.length,
      ...data.map((item) => (item[header] != null ? item[header].toString().length : 0)),
    ),
  }))
  worksheet['!cols'] = wscols
  // Tự động điều chỉnh chiều cao hàng
  const rowHeight = { hpx: 20 } // Chiều cao mặc định cho các hàng
  worksheet['!rows'] = new Array(data.length + 1).fill(rowHeight)

  // Tạo workbook và thêm worksheet
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')

  // Xuất file Excel
  XLSX.writeFile(wb, `${name}.xlsx`)
}
export const exportExcelformatSumCol = (data, name, listSumCol) => {
  // Tạo worksheet từ dữ liệu đã định dạng
  const worksheet = XLSX.utils.json_to_sheet(data)
  const headers = Object.keys(data[0])

  // Thêm tiêu đề vào worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })
  // Bật chức năng filter cho các cột
  const range = XLSX.utils.decode_range(worksheet['!ref'])
  worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) }
  // Tự động điều chỉnh kích thước cột
  const wscols = headers.map((header) => ({
    wch: Math.max(
      header.length,
      ...data.map((item) => (item[header] != null ? item[header].toString().length : 0)),
    ),
  }))
  worksheet['!cols'] = wscols

  // Tính tổng cho các cột cần thiết (ví dụ: 'Đơn giá', 'Thanh toán', 'Công mài')
  const totalRow = headers?.map((header) => {
    if (listSumCol?.includes(header)) {
      const sum = formattedNumber(
        data.reduce((sum, item) => sum + (+`${item[header] ?? ''}`.replaceAll(',', '') ?? 0), 0) ??
          0,
      )
      return sum?.includes(',') ? sum : +sum
    }
    return ''
  })
  // const totalRow = headers.map((header) => {
  //   if (header === 'SL ') return list?.reduce((sum, item) => sum + (item?.soluong ?? 0), 0)
  //   else if (header === 'Đơn giá')
  //     return formattedNumber(list?.reduce((sum, item) => sum + (item?.dongia ?? 0), 0) ?? 0)
  //   else if (header === 'Thanh toán')
  //     return formattedNumber(list?.reduce((sum, item) => sum + (item?.thanhtien ?? 0), 0) ?? 0)
  //   else if (header === 'Công mài')
  //     return formattedNumber(list?.reduce((sum, item) => sum + (item?.tiengiacong ?? 0), 0) ?? 0)
  //   return ''
  // })

  // Thêm hàng tổng vào worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [totalRow], { origin: -1 }) // Thêm ở cuối worksheet

  // Tự động điều chỉnh chiều cao hàng
  const rowHeight = { hpx: 20 } // Chiều cao mặc định cho các hàng
  worksheet['!rows'] = new Array(data.length + 1).fill(rowHeight)

  // Tạo workbook và thêm worksheet
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')

  // Xuất file Excel
  XLSX.writeFile(wb, `${name}.xlsx`)
}
