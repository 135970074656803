import moment from 'moment/moment'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../splitWordForReport'
import { generateCheckbox, generateDot, generateDotWithWidth, generateLogo } from '../algorithm'
const getChuyenKhoa = (value) => {
  switch (value) {
    case 1:
      return `NHI KHOA`
    case 2:
      return `SẢN KHOA`
    case 3:
      return `LÃO KHOA`
    case 4:
      return `MẮT`
    case 21:
      return `TIM MẠCH TIỂU ĐƯỜNG`
    default:
      return 'ĐA KHOA'
  }
}

function calculateAge(birthdate) {
  const birthDate = moment(birthdate)
  const today = moment()
  return today.diff(birthDate, 'years')
}

export const generatePhieuKhamBenh = (infoBN, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //page 1
  doc.setLineWidth(0.2)
  //header
  doc.setFontSize(16)
  doc.setFont('times', 'bold')
  doc.text('Phiếu khám bệnh', pageWidth - 80, yPos + 3)

  generateLogo(doc, xPos, PkDangNhap)

  doc.setFontSize(13)

  doc.setFont('times', 'normal')
  const labelText = 'KHOA:'
  const labelWidth = doc.getTextWidth(labelText)
  const chuyenKhoaText = getChuyenKhoa(PkDangNhap.idChuyenKhoa)
  const startX = pageWidth - 80
  // Draw the texts
  doc.text(labelText, startX, 20)
  doc.setFont('times', 'bold')
  doc.text(chuyenKhoaText, startX + labelWidth + 2, 20)
  doc.setFontSize(11)
  doc.text('I. Hành chính:', xPos, 28)
  let textWidthHoTen = doc.getTextDimensions('1. Họ và tên (In hoa): ').w
  doc.text(`${infoBN.benhNhan.tenBenhNhan ?? ''}`, textWidthHoTen + xPos, 35)
  doc.setFont('times', 'regular')
  doc.text('1. Họ và tên', xPos, 35)
  doc.setFont('times', 'italic')
  doc.text(' (In hoa):', doc.getTextDimensions('1. Họ và tên').w + xPos, 35)
  const dotLineHoTen = generateDot(
    doc,
    `1. Họ và tên (In hoa): ${infoBN.benhNhan.tenBenhNhan ?? ''} `,
    xPos,
    35,
  )
  doc.text(dotLineHoTen.positionX, dotLineHoTen.yPos, dotLineHoTen.content)
  yPos = 35
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text(
    `2. Ngày sinh: ${moment(infoBN.benhNhan.ngaySinh ?? '').format('DD/MM/YYYY')}`,
    xPos,
    yPos,
  )
  doc.text('Tuổi: ', xPos + 70, yPos)
  const age = calculateAge(infoBN.benhNhan.ngaySinh)
  if (age < 10) {
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(age + '', xPos + 91, yPos)
  } else {
    //tach so
    let ageString = age + ''
    while (ageString.length < 3) {
      ageString = '0' + ageString
    }

    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(ageString[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(ageString[1], xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(ageString[2], xPos + 91, yPos)
  }

  doc.text('3. Giới tính: ', xPos + 100, yPos)
  doc.text('Nam', xPos + 135, yPos)
  doc.text('Nữ', xPos + 160, yPos)
  if (infoBN.benhNhan.gioiTinh === 'Nam') {
    generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, false)
  } else {
    generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, true)
  }

  yPos += 5
  doc.text(`4. Điện thoại: ${infoBN.benhNhan.dienThoai ?? ''}`, xPos, yPos)
  doc.text(`5. Nghề nghiệp: ${infoBN.benhNhan.tenNgheNghiep ?? ''}`, xPos + 100, yPos)
  yPos += 5

  doc.text(`6. Dân tộc: ${infoBN.benhNhan.tenDanToc ?? ''}`, xPos, yPos)
  if (infoBN.benhNhan.idDanToc < 10) {
    // Set border color

    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(`${infoBN.benhNhan.idDanToc ?? '0'}`, xPos + 86, yPos)
  } else {
    //tach so
    let idDanToc = infoBN.benhNhan.idDanToc + ''
    let dotStrings = []
    for (let i = 0; i < idDanToc.length; i++) {
      dotStrings.push(idDanToc[i])
    }
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(dotStrings[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(dotStrings[1], xPos + 86, yPos)
  }

  doc.text(`7. Quốc tịch: ${infoBN.benhNhan.tenQuocTich ?? ''}`, xPos + 100, yPos)
  if (infoBN.benhNhan.idQuocTich < 10) {
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(`${infoBN.benhNhan.idQuocTich ?? '0'}`, pageWidth - 14, yPos)
  } else {
    //tach so
    let idQuocTich = infoBN.benhNhan.idQuocTich + ''
    while (idQuocTich.length < 3) {
      idQuocTich = '0' + idQuocTich
    }

    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[0], pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[1], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[2], pageWidth - 14, yPos)
  }

  yPos += 5
  doc.text(`8. Địa chỉ: ${infoBN.benhNhan.diaChi ?? ''}`, xPos, yPos)
  doc.text(`Xã phường: ${infoBN.benhNhan.tenPhuongXa ?? ''}`, xPos + 100, yPos)
  if (infoBN.benhNhan.idPhuong < 10) {
    doc.rect(pageWidth - 35, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 34, yPos)
    doc.rect(pageWidth - 30, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 29, yPos)
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBN.benhNhan.idPhuong + '', pageWidth - 14, yPos)
  } else {
    //tach so
    let idPhuongXa = infoBN.benhNhan.idPhuong + ''
    while (idPhuongXa.length < 5) {
      idPhuongXa = '0' + idPhuongXa
    }
    doc.rect(pageWidth - 35, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[0], pageWidth - 34, yPos)
    doc.rect(pageWidth - 30, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[1], pageWidth - 29, yPos)
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[2], pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[3], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[4], pageWidth - 14, yPos)
  }

  yPos += 5
  doc.text(`Huyện(Q, Tx): ${infoBN.benhNhan.tenQuanHuyen ?? ''}`, xPos, yPos)
  if (infoBN.benhNhan.idQuan < 10) {
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(infoBN.benhNhan.idQuan + '', xPos + 91, yPos)
  } else {
    //tach so
    let idQuanHuyen = infoBN.benhNhan.idQuan + ''
    while (idQuanHuyen.length < 3) {
      idQuanHuyen = '0' + idQuanHuyen
    }
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[1], xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[2], xPos + 91, yPos)
  }

  doc.text(`Tỉnh, thành phố: ${infoBN.benhNhan.tenTinhTP ?? ''}`, xPos + 100, yPos)
  if (infoBN.benhNhan.idTinh < 10) {
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBN.benhNhan.idTinh + '', pageWidth - 14, yPos)
  } else {
    //tach so
    let idQuanHuyen = infoBN.benhNhan.idTinh + ''
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[0], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[1], pageWidth - 14, yPos)
  }

  yPos += 5
  doc.text(`9. Đối tượng: `, xPos, yPos)

  doc.text(`BHYT`, xPos + 60, yPos)
  generateCheckbox(doc, xPos + 50, yPos - 3.5, 4, 4, false)

  doc.text(`Thu phí`, xPos + 85, yPos)
  generateCheckbox(doc, xPos + 80, yPos - 3.5, 4, 4, false)

  doc.text(`Miễn`, xPos + 110, yPos)
  generateCheckbox(doc, xPos + 105, yPos - 3.5, 4, 4, false)

  doc.text(`Khác`, xPos + 135, yPos)
  generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, true)

  yPos += 5

  doc.text(`10. Số thẻ BHYT: ........................................... Có giá trị đến ngày ... tháng ... năm ...`, xPos, yPos)
  yPos += 5
  doc.text(`11. Họ và tên người nhà khi cần báo tin: ${infoBN.tennguoinha ?? ''}`, xPos, yPos)
  doc.text(`Điện thoại: ${infoBN.dienthoainguoinha ?? ''}`, pageWidth - 55, yPos)
  yPos += 5
  doc.text(
    `12. Đến khám bệnh lúc ${moment(infoBN.ngaykham).format('HH')} giờ ${moment(infoBN.ngaykham).format('mm')} phút ngày ${moment(
      infoBN.ngaykham,
    ).format(
      'DD',
    )} tháng ${moment(infoBN.ngaykham).format('MM')} năm ${moment(infoBN.ngaykham).format('YYYY')}`,
    xPos,
    yPos,
  )
  yPos += 5
  let chandoan = `13. Chẩn đoán: ${infoBN.chandoan ?? ''}`
  if (chandoan.endsWith('/')) {
    chandoan = chandoan.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(chandoan) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, chandoan, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(chandoan, xPos, yPos)
    const dotLineCC = generateDot(doc, chandoan, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 5
  }
  yPos += 2
  doc.setFont('times', 'bold')
  doc.text('II. Lí do vào viện: ', xPos, yPos)
  doc.setFont('times', 'regular')
  const lydokham = infoBN.lydokham ?? ''
  if (doc.getTextWidth(lydokham) > 60) {
    const wordsArray = splitLongWord(lydokham, 55)
    for (let index = 0; index < wordsArray.length; index++) {
      if (index === 0) {
        doc.text(wordsArray[index], doc.getTextDimensions('II. Lí do vào viện: ').w + 10, yPos)
        yPos += 4
      } else {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 4
      }
    }
  } else {
    doc.text(lydokham, doc.getTextDimensions('II. Lí do vào viện: ').w + 12, yPos)
    yPos += 5
  }

  yPos += 2
  doc.setFont('times', 'bold')
  doc.text('III. Hỏi bệnh', xPos, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  const quatrinhbenh = `1. Quá trình bệnh lý: ${infoBN.benhsu ?? ''}`
  if (doc.getTextWidth(quatrinhbenh) > 180) {
    //185 is width of page
    const wordsArray = splitLongWordFitWidth(doc, quatrinhbenh, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 5
      }
    }
  } else {
    doc.text(quatrinhbenh, xPos, yPos)
    const dotLineCC = generateDot(doc, quatrinhbenh, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 5
    }
  }

  yPos += 2
  doc.text('2. Tiền sử bệnh:', xPos, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  const tiensubanthan = `- Bản thân: ${infoBN.tiensubanthan ?? ''}`
  //beakword
  if (doc.getTextWidth(tiensubanthan) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, tiensubanthan, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(tiensubanthan, xPos, yPos)
    const dotLineCC = generateDot(doc, tiensubanthan, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
  }
  yPos += 2
  const tiensugiadinh = `- Gia đình: ${infoBN.tieusugiadinh ?? ''}`
  //beakword
  if (doc.getTextWidth(tiensugiadinh) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, tiensugiadinh, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(tiensugiadinh, xPos, yPos)
    const dotLineCC = generateDot(doc, tiensugiadinh, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
  }
  yPos += 2
  doc.setFont('times', 'bold')
  doc.text('IV. Khám xét', xPos, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text('1. Toàn thân: ', xPos, yPos)
  doc.rect(pageWidth - 50, yPos - 4, 42, 26)
  doc.setFontSize(10)
  doc.text(`Mạch: ${infoBN.mach ?? ''} lần/phút`, pageWidth - 48, yPos)
  doc.text(`Nhiệt độ: ${infoBN.nhietdo ?? ''} °C`, pageWidth - 48, yPos + 4)
  doc.text(`Huyết áp: ${infoBN.huyetap ?? ''} mmHg`, pageWidth - 48, yPos + 8)
  doc.text(`Nhịp thở: ${infoBN.nhiptho ?? ''} lần/phút`, pageWidth - 48, yPos + 12)
  doc.text(`Cân năng: ${infoBN.cannang ?? ''} kg`, pageWidth - 48, yPos + 16)
  doc.text(`Chiều cao: ${infoBN.chieucao ?? ''} cm`, pageWidth - 48, yPos + 20)
  doc.setFont('times', 'regular')
  doc.setFontSize(12)
  const toanthan = infoBN.toanthan ?? ''
  //beakword
  yPos += 5
  if (doc.getTextWidth(toanthan) > 80) {
    const wordsArray = splitLongWord(toanthan, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos,
      yPos - 5,
      160,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 4) {
      for (let i = 0; i < 4 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 5
      }
    }
  } else {
    doc.text(toanthan, xPos, yPos)
    const dotLineCC = generateDotWithWidth(doc, toanthan, xPos, yPos, 160)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    for (let i = 0; i < 3; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 5
    }
  }

  yPos += 2
  const cacbophan = `2. Các bộ phận: ${infoBN.cacbophan ?? ''}`
  if (doc.getTextWidth(cacbophan) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, cacbophan, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(cacbophan, xPos, yPos)
    const dotLineCC = generateDot(doc, cacbophan, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 5
  }

  yPos += 2
  const kqcls = `3. Tóm tắt kết quả cận lâm sàng: ${infoBN.kqcls ?? ''}`
  if (doc.getTextWidth(kqcls) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, kqcls, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(kqcls, xPos, yPos)
    const dotLineCC = generateDot(doc, kqcls, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 5
  }

  yPos += 2
  let chandoanbandau = `4. Chẩn đoán ban đầu: ${infoBN.chandoanbandau ?? ''}`
  if (chandoanbandau.endsWith('/')) {
    chandoanbandau = chandoanbandau.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(chandoanbandau) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, chandoanbandau, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(chandoanbandau, xPos, yPos)
    const dotLineCC = generateDot(doc, chandoanbandau, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 5
  }

  yPos += 2
  const daxuly = `5. Đã xử lí (thuốc, chăm sóc...): ${infoBN.daxuly ?? ''}`
  if (doc.getTextWidth(daxuly) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, daxuly, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 5
      }
    }
  } else {
    doc.text(daxuly, xPos, yPos)
    const dotLineCC = generateDot(doc, daxuly, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 5
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos + 5, dotLineEmpty.content)
    yPos += 5
  }

  yPos += 2
  const ghiChu = `6. Ghi chú: ${infoBN.ghichu ?? ''}`
  if (doc.getTextWidth(ghiChu) > 180) {
    const wordsArray = splitLongWordFitWidth(doc, ghiChu, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 5)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(ghiChu, xPos, yPos)
    const dotLineCC = generateDot(doc, ghiChu, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 5
  }

  yPos += 2
  doc.setFont('times', 'italic')
  const dateText = `Ngày ${moment(infoBN.ngaykham ?? new Date()).format('DD')} tháng ${moment(infoBN.ngaykham ?? new Date()).format('MM')} năm ${moment(infoBN.ngaykham ?? new Date()).format('YYYY')}`
  const doctorText = infoBN.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 25 

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.setFont('times', 'bold')
  doc.text('Bác sĩ khám bệnh', pageWidth - 63, yPos)
  doc.text(doctorText, doctorTextX, yPos + 20)
  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
