import React, { useCallback, useEffect, useState } from 'react'
import { Button, ConfigProvider, Input, Pagination, Table, Tooltip, Modal, Select } from 'antd'
import {
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from '@ant-design/icons'

import moment from 'moment'
import 'moment/locale/vi'
import { https } from '../../../../services/apiService'
moment.locale('vi')
const { confirm } = Modal
const LoaiVanBan = () => {
  const now = moment()
  const [isloading, setIsLoading] = useState(false)

  const [itemEdit, setItemEdit] = useState(null)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const cancelModal = () => {
    setOpen(false)
    setItemEdit(null)
  }

  //----------- lấy danh sách loại khám -----------
  // const fetchListBieuMau = async () => {
  //   setIsLoading(true)
  //   try {
  //     const { data } = await sieuAmService.getALLBieuMau(
  //       loaiBieuMau === 0 ? null : loaiBieuMau,
  //       search || null,
  //     )
  //     setListBieuMau(data?.map((item, index) => ({ ...item, stt: ++index })))
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  const handleDeleteBM = async (idbm) => {
    try {
      await https.delete(`BieuMau/DeleteBieuMau`, { params: { idbm } })

      // fetchListBieuMau()
    } catch (error) {
      console.log(error)
    }
  }
  const showConfirm = async (record) => {
    confirm({
      title: 'Xóa biểu mẫu',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xóa biểu mẫu ${record?.tenbieumau ?? ''}`,
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        handleDeleteBM(record?.idbm)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      // render: (text, record, index) => ++index,
    },
    {
      title: 'Tên loại văn bản',
      dataIndex: 'tenbieumau',
      key: 'tenbieumau',
      width: 350,
    },

    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 70,
      render: (text, record, index) => (
        <ul className="flex justify-center gap-2">
          <li>
            <Tooltip title="Chỉnh sửa" color="green">
              <EditOutlined
                onClick={() => {
                  setOpen(true)
                  setItemEdit(record)
                }}
                className="text-xl text-green-500  cursor-pointer"
              />
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Xóa" color="red">
              <CloseOutlined
                onClick={() => {
                  showConfirm(record)
                }}
                className="text-xl text-red-500  cursor-pointer"
              />
            </Tooltip>
          </li>
        </ul>
      ),
    },
  ]
  // useEffect(() => {
  //   // fetchListBieuMau()
  // }, [loaiBieuMau, search])
  useEffect(() => {
    // !open && fetchListBieuMau()
  }, [open])
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1">
            <div className="flex w-64">
              <Input
                value={search}
                placeholder="Nhập Tên loại văn bản"
                onChange={(e) => setSearch(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          <Button
            type="primary"
            className="flex items-center"
            onClick={() => {
              setOpen(true)
              setItemEdit(null)
            }}
          >
            <PlusOutlined />
            &nbsp;Tạo loại văn bản
          </Button>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            loading={isloading}
            scroll={{ x: 1000 }}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
            columns={columns}
            // dataSource={listBieuMau}
            bordered
          />
        </ConfigProvider>
        <Modal
          title={itemEdit ? 'Chỉnh sửa loại văn bản' : 'Tạo loại văn bản'}
          visible={open}
          onCancel={cancelModal}
          footer={null}
          destroyOnClose
          width={600}
        >
          <div className="p-3">
            <div className="flex items-center gap-3">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Tên loại văn bản :
                </label>
                <Input
                  value={itemEdit?.tenbieumau}
                  placeholder="Nhập tên loại văn bản"
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex justify-end mt-3">
              <Button type="primary">Lưu</Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default LoaiVanBan
