import * as typeAction from '../constants/constants'

export const getListKhoNhanAction = (id) => ({
  type: typeAction.GET_LIST_Ck_KHO_NHAN,
  payload: id,
})
export const getInfoKhoDefault = () => ({
  type: typeAction.GET_BRANCH_AND_KHO_VT_DEFAULT,
})
export const getKhoVTAction = (idThuoc) => ({
  type: typeAction.GET_CK_KHOVT,
  payload: idThuoc,
})
export const postPhieuCKAction = (form, khoVT) => ({
  type: typeAction.POST_PHIEU_CK,
  payload: form,
  khoVT,
})
export const postPhieuCTYAction = (form, khoVT) => ({
  type: typeAction.CHUYEN_KHO_COMPANY,
  payload: form,
  khoVT,
})
export const filterPT_createCK = (filter, setIsLoading) => ({
  type: typeAction.GET_FILTER_CREATE_CHUYEN_KHO,
  filter,
  setIsLoading,
})
export const filterPT_pendingCK = (filter, setIsLoading) => ({
  type: typeAction.GET_FILTER_PENDING_CHUYEN_KHO,
  filter,
  setIsLoading,
})
export const filterPT_tranferCK = (filter, setIsLoading) => ({
  type: typeAction.GET_FILTER_TRANFER_CHUYEN_KHO,
  filter,
  setIsLoading,
})
export const filterPT_recevieCK = (filter, setIsLoading) => ({
  type: typeAction.GET_FILTER_RECEVIE_CHUYEN_KHO,
  filter,
  setIsLoading,
})
export const getListTonkhoCn_action = (idkhoCn) => ({
  type: typeAction.GET_TON_KHO_CHI_NHANH,
  idkhoCn,
})
export const updateStatusCK_action = (form, idNhapXuat, filter, status) => ({
  type: typeAction.UPDATE_STATUS_CK,
  form,
  idNhapXuat,
  filter,
  status,
})
export const updateStatusCTY_action = (form, idNhapXuat, fetchData, status) => ({
  type: typeAction.UPDATE_CHUYEN_KHO_CTY,
  form,
  idNhapXuat,
  fetchData,
  status,
})
export const listInfoThuocVTChyenKho_Action = (idNhapXuat) => ({
  type: typeAction.GET_INFO_PT_CHUYEN_KHO,
  idNhapXuat,
})
export const updateChuyenKho_Action = (form, idNhapXuat) => ({
  type: typeAction.UPDATE_INFO_PT_CHUYEN_KHO,
  form,
  idNhapXuat,
})
export const deleteChiTietChuyenKho_Action = (id, idNhapXuat) => ({
  type: typeAction.DELETE_KHO_CHI_TIET_CHUEN_KHO,
  id,
  idNhapXuat,
})
export const branchChuyenKhoByCompany = (IDCompany) => ({
  type: typeAction.GET_BRANCH_CHUYEN_KHO_BT_COMPANY,
  IDCompany,
})
export const branchPTByCompany = (IDCompany) => ({
  type: typeAction.GET_BRANCH_PT_CHUYEN_KHO,
  IDCompany,
})
//------- delete -----------//
export const deleteChuyenKho = (id, handleFilter) => ({
  type: typeAction.DELETE_PHIEU_CHUYEN_KHO,
  id,
  handleFilter,
})
//--------upload file chuyen ------//
export const uploadFileCuyenKho = (id, form) => ({
  type: typeAction.UPLOAD_FILE_CHUYEN_KHO,
  id,
  form,
})
//--------Delete file chuyen ------//
export const deleteFileCuyenKho = (id, form) => ({
  type: typeAction.DELETE_FILE_CHUYEN_KHO,
  id,
  form,
})
//--------- in phiếu thu hàng loạt --------
export const printerAutoChuyenKho = (arr, setSelectedRowKeys, isA5) => ({
  type: typeAction.PRINTER_CHUYEN_KHO,
  arr,
  setSelectedRowKeys,
  isA5,
})
//--------- add new hang hao --------
export const addHangChuyen = (idNhapXuat, production, handleCancel) => ({
  type: typeAction.ADD_HANG_HOA_CHUYEN_KHO,
  idNhapXuat,
  production,
  handleCancel,
})
//-------- lấy danh sách phiếu chuyển kho ---------//
export const getphieuChuyen = (filter,setData,setIsLoading) => ({
  type:typeAction.GET_PHIEU_CHUYEN_KHO,
  filter,
  setData,
  setIsLoading
})
//--------- copy hàng hoá mã hàng chuyển kho --------
export const getProductsChuyenKhoByMaHang = (maHang,ListTonKho,setMaHang) => ({
  type: typeAction.COPY_PRODUCTS_CHUYEN_KHO,
  maHang,
  ListTonKho,
  setMaHang
})