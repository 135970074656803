import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { recieveService } from '../../services/receive/recieveService'
import Swal from 'sweetalert2'
import moment from 'moment'
import { cardService } from '../../services/card/cardService'
import { generatePhieuThuTiepNhan } from '../../utils/report/phieuThuTiepNhan'
import { cloneDeep } from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* receiveSaga() {
  // LẤY TẤT CẢ DANH MỤC SELECT TIẾP NHẬN
  yield takeLatest(typeAction.GET_ALL_SELECT_CLINIC, function* ListBranch({ type, payload }) {
    try {
      const idBranchLogin = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
      //const { PkDangNhap } = yield select((state) => state.branchReducer)
      const NguonKH = yield call(() => recieveService.getNguonKH())
      const NgheNghiep = yield call(() => recieveService.getNgheNghiep())
      const HinhThucTT = yield call(() => recieveService.getHinhThucTT())
      const DanToc = yield call(() => recieveService.getDanToc())
      const DoiTuong = yield call(() => recieveService.getDoiTuong())
      const TinhTP = yield call(() => recieveService.getTinhTP())
      const QuocTich = yield call(() => recieveService.getQuocTich())
      const nguoiThan = yield call(() => recieveService.getListNguoiThan())
      yield put({
        type: typeAction.DISPATCH_NGUON_KH,
        payload: NguonKH.data,
      })
      yield put({
        type: typeAction.DISTPATCH_LIST_NGUOI_THAN,
        payload: nguoiThan.data,
      })
      yield put({
        type: typeAction.DISPATCH_NGHE_NGHIEP,
        payload: NgheNghiep.data,
      })
      yield put({
        type: typeAction.DISPATCH_HINH_THUC_TT,
        payload: HinhThucTT.data,
      })
      yield put({
        type: typeAction.DISPATCH_DAN_TOC,
        payload: DanToc.data,
      })
      yield put({
        type: typeAction.DISPATCH_DOI_TUONG,
        payload: DoiTuong.data,
      })
      yield put({
        type: typeAction.DISPATCH_TINH_TP,
        payload: TinhTP.data,
      })
      yield put({
        type: typeAction.DISPATCH_QUOC_TICH,
        payload: QuocTich.data,
      })
      const listCombo = yield call(() => recieveService.getListCombo(idBranchLogin))
      yield put({
        type: typeAction.DISPATCH_LIST_COMBO_TIEPNHAN,
        payload: listCombo.data?.filter((item) => item?.DanhSachDichVu?.length > 0),
      })
      const listCTGiamGia = yield call(() => recieveService.getListCTGiamGia(idBranchLogin))
      yield put({
        type: typeAction.DISPATCH_LIST_CTGIAMGIA_TIEPNHAN,
        payload: listCTGiamGia.data?.filter((item) => item?.DanhSachChiDinh?.length > 0),
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //Phòng khám by chi nhánh
  yield takeLatest(typeAction.GET_PK_BY_CHI_NHANH, function* pkByChiNhanh({ type, id }) {
    try {
      const PhongKham = yield call(() => recieveService.getPhongKham(id))
      yield put({
        type: typeAction.DISPATCH_PHONG_KHAM,
        payload: PhongKham.data, //?.sort((a, b) => a.idpk - b.idpk),
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy dữ liệu Quận
  yield takeLatest(typeAction.GET_QUAN_API, function* listQuan({ type, payload }) {
    try {
      const result = yield call(() => recieveService.getQuanHuyen(payload))
      yield put({
        type: typeAction.DISPATCH_QUAN_HUYEN,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy dữ liệu XÃ HUYỆN
  yield takeLatest(typeAction.GET_XA_API, function* listXaPhuong({ type, payload }) {
    try {
      const result = yield call(() => recieveService.getPhuongXa(payload))
      yield put({
        type: typeAction.DISPATCH_PHUONG_XA,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // groups chỉ định by chi nhánh
  yield takeLatest(typeAction.GET_GROUPS_CHI_DINH, function* groupsChiDinh({ type, id }) {
    try {
      const result = yield call(() => recieveService.getGroupsChiDinh(id))
      yield put({
        type: typeAction.DISPATCH_GROUPS_CHI_DINH,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // groups CHẨN ĐOÁN
  yield takeLatest(typeAction.GET_GROUPS_CĐHA, function* groupsCĐHA({ type }) {
    try {
      const result = yield call(() => recieveService.getGroupsCĐHA())
      yield put({
        type: typeAction.DISPATCH_GROUPS_CHI_DINH,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // GET LIST CHI DINH KHAM BENH BY GROUPS
  yield takeLatest(
    typeAction.GET_CHI_DINH,
    function* getListChiDinhKham({ type, idGroups, idBranch }) {
      try {
        const ID_COMPANY = Number(localStorage.getItem('id_company'))
        const result = yield call(() =>
          recieveService.getListChiDinhByGroups(idGroups, idBranch, ID_COMPANY),
        )
        yield put({
          type: typeAction.DISPATCH_CHI_DINH,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST CHI DINH CĐHA BY GROUPS
  yield takeLatest(
    typeAction.GET_CHI_DINH_CĐHA_BY_GROUPS,
    function* getListChiDinhKham({ type, idGroups }) {
      try {
        const result = yield call(() => recieveService.getLisChiDinhCĐHA(idGroups))
        yield put({
          type: typeAction.DISPATCH_CHI_DINH,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST CHI DINH XÉT NGHIỆM BY GROUPS
  yield takeLatest(
    typeAction.GET_XET_NGHIEM_RECIEVE,
    function* getListXetNghiem({ type, idGroups, idBranch }) {
      try {
        const result = yield call(() => recieveService.getListXetNghiemByGroups(idGroups, idBranch))
        yield put({
          type: typeAction.DISPATCH_CHI_DINH,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST CHỈ ĐỊNH XÉT NHIỆM BY GROUPS
  yield takeLatest(
    typeAction.GET_GROUPS_XET_NGHIEM_BY_BRANCH,
    function* groupsXetNghiem({ type, id }) {
      try {
        const result = yield call(() => recieveService.getGroupXetNghiemByBranch(id))
        yield put({
          type: typeAction.DISPATCH_GROUPS_CHI_DINH,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  // TÌM KIẾM BỆNH NHÂN
  yield takeLatest(typeAction.SEARCH_BN, function* searchBN({ type, keyWord, idct }) {
    try {
      const result = yield call(() => recieveService.searchBN(keyWord, idct))
      yield put({
        type: typeAction.DISPATCH_LIST_BN,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // TẠO BỆNH HỒ SƠ BỆNH NHÂN MỚI
  yield takeLatest(typeAction.CREATE_BENH_NHAN, function* createNewBN({ type, form }) {
    const now = moment()
    try {
      // const arr = [];
      form.NgayTao = now.format()
      // yield arr.push(form);
      // console.log(form);
      const result = yield call(() => recieveService.postNewBN(form))
      yield put({
        type: typeAction.DISPATCH_INFO_BENH_NHAN,
        payload: result.data,
      })
      Toast.fire({
        icon: 'success',
        title: 'Tạo bệnh nhân thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Tạo bệnh nhân thất bại!',
      })
    }
  })
  // ADD BN KHÁM BỆNH
  yield takeLatest(
    typeAction.ADD_BN_KHAM_BENH,
    function* createNewBN({
      type,
      form,
      ArrXN,
      ArrCĐHA,
      ArrKB,
      formPay,
      formPayDetail,
      paymentCard,
      isPrint,
      maVoucher,
      maVoucherDoiTac,
    }) {
      const now = moment()
      const Dateformat = 'YYYY-MM-DDTHH:mm:ss'
      const idBranchLogin = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      const arrChiDinh = []
      const data = yield select((state) => state.receiveReducer)
      const dataChiNhanh = yield select((state) => state.branchReducer)
      const { PkDangNhap } = dataChiNhanh
      const { infoChuongTrinhGiamGiaDoiTac } = data
      let listDvSDThe = []
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        // set ngày khám
        form.ngaykham = now.format(Dateformat)
        formPay.ngayThanhToan = now.format(Dateformat)
        console.log(form);
        
        const result = yield call(() => recieveService.postBNKham(form))
        // điền thông tin ttbn
        formPay.idbnttc = result.data.idbnttc
        formPayDetail.idbnttchung = result.data.idbnttc
        if (formPayDetail.thett > 0) {
          // không có chức năng trừ tiền thẻ thanh toán
          // yield call(() =>
          //   recieveService.postPaymentCard({
          //     idthe: paymentCard.idThe,
          //     idbn: form.idbn,
          //     nguoinhan: form.nguoitao,
          //     tiensudung: formPayDetail.thett,
          //     chinhanhnap: idBranchLogin,
          //   }),
          // )
        }
        if (ArrXN.length !== 0) {
          // nếu có dữ liệu thì thực hiện post dữ liệu
          let newArr = []
          for (let item of ArrXN) {
            newArr.push({
              idbnttc: result.data.idbnttc,
              idxn: item.idChiDinh,
              maxn: item.xetNghiem.maxn,
              tenxn: item.tenChiDinh,
              mamic: item.xetNghiem.mamic,
              madiag: item.xetNghiem.madiag,
              matvd: item.xetNghiem.matvd,
              dvt: item.xetNghiem.dvt,
              trangthaitt: 2,
              dongia: item.donGia,
              ptgiamgia: item.ptGiam,
              tiengiamgia: item.tienGiam,
              tienthanhtoan: item.thanhToan,
              ngaychidinh: now.format(Dateformat),
            })
            if (formPayDetail?.idthe) {
              listDvSDThe?.push({
                idthe: formPayDetail?.idthe,
                idbn: form?.idbn,
                tiensd: item.thanhToan, // tiền thanh toán (sau khi giảm)
                trangthaisd: 1, //
                // idlt: 0,
                iddv: item.idChiDinh,
                idcn: PkDangNhap?.idChiNhanh,
                nguoinhan: userInfo?.tenNV, // tên lễ tân
                nguoitt: form?.tennguoinha ?? form?.tenbennhan, // tên người nhà hoặc tên bệnh nhân
                ghichu: '',
                loaichidinh: 2, //dv = 1, xn = 2, cdha = 3
              })
            }
          }
          const resultXetNghiem = yield call(() => recieveService.postBNXetNghiem(newArr))
          const dataFormat = resultXetNghiem.data.map((item) => ({
            dvt: item.dvt,
            dongia: item.dongia,
            tenChiDinh: item.tenxn,
            tienThanhToan: item.tienthanhtoan,
          }))
          arrChiDinh.push(...dataFormat)
        }
        if (ArrCĐHA.length !== 0) {
          let newArr = []
          for (let item of ArrCĐHA) {
            newArr.push({
              idbnttc: result.data.idbnttc,
              idcdha: item.idChiDinh,
              macdha: item.macdha,
              tencdha: item.tenChiDinh,
              idnhomcdha: item.idnhomcdha,
              dongia: item.donGia,
              ptgiamgia: item.ptGiam,
              tiengiamgia: item.tienGiam,
              tienthanhtoan: item.thanhToan,
              idtrangthaitt: 2,
              ngaychidinh: now.format(Dateformat),
              dvt: item.dvt,
            })
            if (formPayDetail?.idthe) {
              listDvSDThe?.push({
                idthe: formPayDetail?.idthe,
                idbn: form?.idbn,
                tiensd: item.thanhToan, // tiền thanh toán (sau khi giảm)
                trangthaisd: 1, //
                // idlt: 0,
                iddv: item.idChiDinh,
                idcn: PkDangNhap?.idChiNhanh,
                nguoinhan: userInfo?.tenNV, // tên lễ tân
                nguoitt: form?.tennguoinha ?? form?.tenbennhan, // tên người nhà hoặc tên bệnh nhân
                ghichu: '',
                loaichidinh: 3, //dv = 1, xn = 2, cdha = 3
              })
            }
          }
          const resultChanDoanHinhAnh = yield call(() => recieveService.postBNCDHA(newArr))
          const dataFormat = resultChanDoanHinhAnh.data.map((item) => ({
            dvt: item.dvt,
            dongia: item.dongia,
            tenChiDinh: item.tencdha,
            tienThanhToan: item.tienthanhtoan,
          }))
          arrChiDinh.push(...dataFormat)
        }
        if (ArrKB.length !== 0) {
          let newArr = []
          for (let item of ArrKB) {
            newArr.push({
              idbnttc: result.data.idbnttc,
              iddichvu: item.dichVu.iddv,
              madichvu: item.dichVu.maDichVu,
              tendichvu: item.dichVu.tenDichVu,
              donvitinh: item.dichVu.donVi,
              dongia: item.donGia,
              ptgiamgia: item.ptGiam,
              tiengiamgia: item.tienGiam,
              tienthanhtoan: item.thanhToan,
              loaichidinh: item.dichVu.loaiChiDinh,
              tienguimau: item.dichVu.tienGuiMau,
              tienchietkhau: item.dichVu.tienChietKhau,
              ngaysudung: now.format(Dateformat),
              trangthaikb: 1, // tiếp nhận
              trangthaitt: 2, // đã thanh toán
              tiencong: item.dichVu.tienGiaCong,
              idnguoichidinh: userInfo?.idnv,
            })
            if (formPayDetail?.idthe) {
              listDvSDThe?.push({
                idthe: formPayDetail?.idthe,
                idbn: form?.idbn,
                tiensd: item.thanhToan, // tiền thanh toán (sau khi giảm)
                trangthaisd: 1, //
                // idlt: 0,
                iddv: item.dichVu.iddv,
                idcn: PkDangNhap?.idChiNhanh,
                nguoinhan: userInfo?.tenNV, // tên lễ tân
                nguoitt: form?.tennguoinha ?? form?.tenbennhan, // tên người nhà hoặc tên bệnh nhân
                ghichu: '',
                loaichidinh: 1, //dv = 1, xn = 2, cdha = 3
              })
            }
          }
          const resultBNDV = yield call(() => recieveService.postBNDV(newArr))
          const dataFormat = resultBNDV.data.map((item) => ({
            dvt: item.donvitinh,
            dongia: item.dongia,
            tenChiDinh: item.tendichvu,
            tienThanhToan: item.tienthanhtoan,
          }))
          arrChiDinh.push(...dataFormat)
        }
        const resPay = yield call(() => recieveService.postBNPay(formPay))
        formPayDetail.idbnttc = resPay.data.idbnttc
        formPayDetail.idbntt = resPay.data.idbntt
        const resPayDetail = yield call(() => recieveService.postDetailPay(formPayDetail))

        if (listDvSDThe?.length > 0) {
          yield call(() => recieveService.postListDVApplyTTV(listDvSDThe))
        }
        //add benh nhan voucher
        if (maVoucher?.trim().length > 0 && formPay?.listMaVC?.length > 0) {
          const formatData = [...ArrCĐHA, ...ArrXN, ...ArrKB].map((item) => ({
            mavoucher: formPay?.listMaVC[0],
            idbn: form.idbn,
            iddv: item.idChiDinh,
          }))
          formPay.maVoucher = maVoucher.trim()
          yield call(() => recieveService.addBenhNhanVoucher(formatData))
          yield call(() => cardService.putSuDungVoucher(formPay?.listMaVC, form.nguoitao))
        }
        //add voucher doi tac
        if (
          Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher) > 0 &&
          maVoucherDoiTac?.trim()?.length > 0
        ) {
          const formatData = {
            mavoucher: maVoucherDoiTac?.trim(),
            idct: infoChuongTrinhGiamGiaDoiTac.idctDoiTac,
            idcn: PkDangNhap.idChiNhanh,
            nguoitao: userInfo.tenNV,
            giatri: infoChuongTrinhGiamGiaDoiTac.giaVoucher,
          }
          formPay.mavoucherdoitac = maVoucherDoiTac?.trim()
          yield call(() => recieveService.addVoucherDoiTac(formatData))
          yield call(() => cardService.putSuDungVoucher([maVoucherDoiTac?.trim()], form.nguoitao))
        }
        yield put({
          type: typeAction.RESET_SOTER_CHI_CHI_RECEIVE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (isPrint)
          yield call(() =>
            generatePhieuThuTiepNhan(
              result.data,
              arrChiDinh,
              resPay.data,
              resPayDetail.data,
              PkDangNhap,
            ),
          )
        Toast.fire({
          icon: 'success',
          title: 'Thanh toán thành công!',
        })
        //reset neu co discount
        maVoucherDoiTac &&
          (yield put({
            type: typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
            payload: null,
          }))
        maVoucher &&
          (yield put({
            type: typeAction.UPDATE_NEW_PRICE_CHI_DINH,
            payload: [],
          }))
      } catch (err) {
        yield console.log(err)
        yield put({
          type: typeAction.RESET_SOTER_CHI_CHI_RECEIVE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (maVoucher) {
          // yield call(() => recieveService.updateCancelUseVoucher(maVoucher))
          yield put({
            type: typeAction.UPDATE_NEW_PRICE_CHI_DINH,
            payload: [],
          })
        }
        if (maVoucherDoiTac) {
          yield put({
            type: typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
            payload: null,
          })
        }
        Toast.fire({
          icon: 'error',
          title: 'Thanh toán thất bại!',
        })
      }
    },
  )
  // GET INFO BỆNH NHÂN
  yield takeLatest(
    typeAction.GET_INFO_BENH_NHAN,
    function* searchBN({ type, idBN, idChuyenKhoa, formik }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => recieveService.getInfoBN(idBN))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idBN, idChuyenKhoa))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idBN))
        yield put({
          type: typeAction.DISPATCH_INFO_BENH_NHAN,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_MEMBER_CARD_RECEIVE,
          payload: memberCard?.data?.idbn === idBN ? memberCard?.data : null,
        })
        yield put({
          type: typeAction.DISPATCH_PAYMENT_CARD_RECEIVE,
          payload: paymentCard.data[0],
        })
        const uuDaiTK = yield call(() =>
          recieveService.getUuDaiTaiKhamBN(idBN, PkDangNhap?.idChiNhanh),
        )
        yield put({
          type: typeAction.DISPATCH_UU_DAI_TAI_KHAM_TIEP_NHAN,
          payload: uuDaiTK.data.result,
        })
        formik.setFieldValue('chieucao', result.data.chieucao)
        formik.setFieldValue('cannang', result.data.cannang)
        formik.setFieldValue('mach', result.data.mach)
        formik.setFieldValue('huyetap', result.data.huyetap)
        formik.setFieldValue('nhietdo', result.data.nhietdo)
        formik.setFieldValue('nhiptho', result.data.nhiptho)
        formik.setFieldValue('spO2', result.data.spO2)
        formik.setFieldValue('nguoinha', result.data.nguoinha)
        formik.setFieldValue('tennguoinha', result.data.tennguoinha)
        formik.setFieldValue('dienthoainguoinha', result.data.dienthoainguoinha)
        formik.setFieldValue('ghichu', result.data.ghiChuBNTTC)
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Chọn bệnh nhân thất bại, vui lòng thử lại!',
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BENH_NHAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_MEMBER_CARD_RECEIVE,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_PAYMENT_CARD_RECEIVE,
          payload: null,
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield put({
          type: typeAction.RESET_SOTER_CHI_CHI_RECEIVE,
        })
      }
    },
  )

  // Apply CHỈ ĐỊNH GIẢM GIÁ
  yield takeLatest(
    typeAction.GET_LIST_DISCOUNT,
    function* updateListChiDinhGiamGia({ type, listDV }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const data = yield select((state) => state.receiveReducer)

        const { ArrKB, ArrXN, ArrCĐHA } = data
        const cloneArrKB = cloneDeep(ArrKB)
        const cloneArrXN = cloneDeep(ArrXN)
        const cloneArrCĐHA = cloneDeep(ArrCĐHA)
        cloneArrKB.forEach((item) => {
          listDV.forEach((element) => {
            if (item.idChiDinh === element.iddv && element.loaivoucherdichvu === 1) {
              item.tienGiam = element.tiengiam
              item.giaTienSauKhiGiam = element.thanhtien
              item.ptGiam = element.ptgiam
              item.thanhToan = element.thanhtien
            }
          })
        })
        cloneArrXN.forEach((item) => {
          listDV.forEach((element) => {
            if (item.idChiDinh === element.iddv && element.loaivoucherdichvu === 2) {
              item.tienGiam = element.tiengiam
              item.giaTienSauKhiGiam = element.thanhtien
              item.ptGiam = element.ptgiam
              item.thanhToan = element.thanhtien
            }
          })
        })
        cloneArrCĐHA.forEach((item) => {
          listDV.forEach((element) => {
            if (item.idChiDinh === element.iddv && element.loaivoucherdichvu === 3) {
              item.tienGiam = element.tiengiam
              item.giaTienSauKhiGiam = element.thanhtien
              item.ptGiam = element.ptgiam
              item.thanhToan = element.thanhtien
            }
          })
        })
        // cloneArrXN.forEach((item) => {
        //   listDV.forEach((element) => {
        //     if (item.idChiDinh === element.iddv) {
        //       item.tienGiam = element.giatrigiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptGiam = element.phantramgiam
        //       item.thanhToan = element.giatri
        //     }
        //   })
        // })
        // cloneArrCĐHA.forEach((item) => {
        //   listDV.forEach((element) => {
        //     if (item.idChiDinh === element.iddv) {
        //       item.tienGiam = element.giatrigiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptGiam = element.phantramgiam
        //       item.thanhToan = element.giatri
        //     }
        //   })
        // })

        // update lai list chi dinh sau khi chinh sua gia moi nhat
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB,
          payload: cloneArrKB,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN,
          payload: cloneArrXN,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA,
          payload: cloneArrCĐHA,
        })

        yield put({
          type: typeAction.UPDATE_NEW_PRICE_CHI_DINH,
          payload: listDV,
        })
        Toast.fire({
          icon: 'success',
          title: 'Áp dụng voucher thành công',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // Apply VOUCHER cua doi tac
  yield takeLatest(
    typeAction.SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC,
    function* updateGiamGiaByVoucherDoiTac({ type, form }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield put({
          type: typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
          payload: form,
        })
        Toast.fire({
          icon: 'success',
          title: 'Áp dụng voucher thành công',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.GET_INFO_NGUOI_NHA_BN,
    function* getInfoNguoiThan({ type, idbn, nguoiThan, formik }) {
      try {
        const result = yield call(() => recieveService.getInfoNguoiThan(idbn, nguoiThan))
        formik.setFieldValue('tennguoinha', result.data.tennguoinha)
        formik.setFieldValue('dienthoainguoinha', result.data.dienthoainguoinha)
      } catch (err) {
        yield console.log(err)
        formik.setFieldValue('tennguoinha', '')
        formik.setFieldValue('dienthoainguoinha', '')
      }
    },
  )

  // Remove Giá giảm của chỉ định
  yield takeLatest(
    typeAction.REMOVE_LIST_DISCOUNT,
    function* unApplyListChiDinhGiamGia({ type, listDV }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        // const data = yield select((state) => state.receiveReducer)

        // const { ArrKB, ArrXN, ArrCĐHA, discountArr } = data
        // const cloneArrKB = cloneDeep(ArrKB)
        // const cloneArrXN = cloneDeep(ArrXN)
        // const cloneArrCĐHA = cloneDeep(ArrCĐHA)
        // const cloneDiscountArr = cloneDeep(discountArr)

        // cloneArrKB.forEach((item) => {
        //   cloneDiscountArr.forEach((element) => {
        //     if (item.idChiDinh === element.iddv) {
        //       item.tienGiam = element.tiengiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptGiam = element.ptgiam
        //       item.thanhToan = element.thanhtien
        //     }
        //   })
        // })
        // cloneArrXN.forEach((item) => {
        //   cloneDiscountArr.forEach((element) => {
        //     if (item.idChiDinh === element.iddv) {
        //       item.tienGiam = element.tiengiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptGiam = element.ptgiam
        //       item.thanhToan = element.thanhtien
        //     }
        //   })
        // })
        // cloneArrCĐHA.forEach((item) => {
        //   cloneDiscountArr.forEach((element) => {
        //     if (item.idChiDinh === element.iddv) {
        //       item.tienGiam = element.tiengiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptGiam = element.ptgiam
        //       item.thanhToan = element.thanhtien
        //     }
        //   })
        // })

        // update lai list chi dinh sau khi chinh sua gia moi nhat
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB,
          payload: listDV?.filter((item) => item.type === 'KB'), //cloneArrKB,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN,
          payload: listDV?.filter((item) => item.type === 'XN'), //,cloneArrXN,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA,
          payload: listDV?.filter((item) => item.type === 'CDHA'), //,cloneArrCĐHA,
        })

        yield put({
          type: typeAction.UPDATE_NEW_PRICE_CHI_DINH,
          payload: [],
        })
        Toast.fire({
          icon: 'success',
          title: 'Đã hủy áp dụng voucher',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // DANH SÁCH BỆNH NHÂN CHỜ KHÁM
  yield takeLatest(
    typeAction.GET_BN_PEDING,
    function* BNPending({ type, status, idBranch, useSWR }) {
      try {
        const result = yield call(() => recieveService.getListBNStatus(status, idBranch))
        yield put({
          type: typeAction.DISPATCH_BN_PENDING,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // Apply thẻ thành viên khi chọn dịch vụ
  yield takeLatest(typeAction.POST_APPLY_MEMBER_CARD, function* applyMemberCard({ type, form }) {
    try {
      const result = yield call(() => recieveService.postApplyMemberCard(form))
      yield put({
        type: typeAction.DISTPATCH_LIST_KB_RECEIVE_APPLY_MEMBER_CARD, // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) - xài chung cho kb + xn + cdha
        payload: result.data, ///////////{ ...result.data, loaithedichvu: form.loaithedichvu },
      })
    } catch (err) {
      console.log(err)
    }
  })
  //Lưu số lần sử dụng dịch vụ của thẻ thành viên
  yield takeLatest(typeAction.POST_DV_MEMBER_CARD, function* DVApplyTTV({ type, list }) {
    try {
      yield call(() => recieveService.postListDVApplyTTV(list))
    } catch (err) {
      console.log(err)
    }
  })
  // GET LIST CHỈ ĐỊNH XÉT NGHIỆM TIẾP NHẬN
  yield takeLatest(
    typeAction.GET_LIST_CD_XN_TIEPNHAN,
    function* getListXetNghiemTiepNhanSaga({ type, idBranch }) {
      try {
        const result = yield call(() => recieveService.getListXetNghiemTiepNhan(idBranch))
        yield put({
          type: typeAction.DISPATCH_CHI_DINH,
          payload: result.data?.sort((a, b) =>
            (a?.tenChiDinh ?? '').localeCompare(b?.tenChiDinh ?? ''),
          ),
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST CHỈ ĐỊNH CĐHA TIẾP NHẬN
  yield takeLatest(
    typeAction.GET_LIST_CD_CDHA_TIEPNHAN,
    function* getListCDHATiepNhanSaga({ type, idct }) {
      try {
        const result = yield call(() => recieveService.getListCDHATiepNhan(idct))
        yield put({
          type: typeAction.DISPATCH_CHI_DINH,
          payload: result.data?.sort((a, b) =>
            (a?.tenChiDinh ?? '').localeCompare(b?.tenChiDinh ?? ''),
          ),
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST CHỈ ĐỊNH DV APPLY VOUCHER TIẾP NHẬN
  yield takeLatest(
    typeAction.GET_APPLY_VOUCHER_TIEPNHAN,
    function* applyVoucherSaga({ type, ArrKB, temp, form }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        let formatData = null
        yield call(
          () =>
            temp?.split(',')?.map(function* (vc) {
              console.log(vc)
              if (vc?.length > 0) {
                formatData = yield call(() =>
                  formatData?.map((item) => ({
                    ...item,
                    mavoucher: vc, ///////////////
                  })),
                )
                const { data } = yield call(() =>
                  recieveService.postApplyVoucherTiepNhan(formatData),
                )
                // const { data } = yield call(() => recieveService.postApplyVoucherTiepNhan())
                formatData = [...data]
              }
            }),
          //  {
          //   // formatData = ArrKB?.map((item) => ({
          //   //   iddv: item.idChiDinh,
          //   //   dongia: item.donGia,
          //   //   mavoucher: null,///////////////
          //   //   ptgiam: item.ptGiam,
          //   //   tiengiam: item.tienGiam,
          //   //   thanhtien: item.thanhToan,
          //   //   trangthaitt: 1,
          //   //   nguoikichhoat: form.nguoikichhoat,
          //   //   iddt: form.iddt,
          //   //   idcn: form.idcn,
          //   // }))
          // }
        )
        // yield call(() =>
        // )
        // const result = yield call(() => recieveService.getListCDHATiepNhan())
        // yield put({
        //   type: typeAction.DISPATCH_CHI_DINH,
        //   payload: result.data?.sort((a, b) => (a?.tenChiDinh ?? '').localeCompare(b?.tenChiDinh ?? '')),
        // })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
