import { Tabs, ConfigProvider, Table, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { detailBNCashier } from '../../../../store/actions/cashierAction'
const TabPT = ({ resetFormThanhToan, BNChuaThu, setIsDelete }) => {
  const dispatch = useDispatch()
  const { BNPending } = useSelector((state) => state.receiveReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const STATUS_KHAM = 1
  // ------- lấy danh sách bệnh nhân chưa thu tiền --------------//
  // const fecthBNchuaThu = async () => {
  //   try {
  //     const result = await cashierService.getBNChuaThu(ID_BRANCH_LOGIN)
  //     setBNChuaThu(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // -------------- lấy thông tin chi tiết bệnh nhân chưa thu tiền ------------/
  const onClickDetailBN = (record) => {
    setIsDelete({ DV: [], XN: [], CDHA: [] })
    dispatch(detailBNCashier(record.idbn, 368, record.idbnttc, PkDangNhap?.idChuyenKhoa))
    // dispatch({// cập nhật lại tổng tiền giảm khi xem phiếu
    //   type: typeAction.DISPATCH_SET_TONG_GIAM_CASHIER,
    //   payload: 0,
    // })
    // dispatch({// cập nhật lại tổng tiền thanh toán khi xem phiếu
    //   type: typeAction.DISPATCH_SET_TONG_THANH_TOAN_CASHIER,
    //   payload: 0,
    // })
    resetFormThanhToan()
  }
  const items = [
    {
      key: '1',
      label: <p className="ml-1">Phiếu chưa thu</p>,
      children: (
        <>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#e3f0fb',
                },
              },
            }}
          >
            <Table
              bordered
              pagination={false}
              scroll={{ y: 289 }}
              dataSource={
                //BNChuaThu
                BNChuaThu?.map((item, index) => ({
                  stt: ++index,
                  mabenhnhan: item.mabenhnhan,
                  tenbenhnhan: item.tenbenhnhan,
                  idbn: item.idbn,
                  idbnttc: item.idbnttc,
                }))
              }
              onRow={(record) => ({
                onClick: () => onClickDetailBN(record),
              })}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  align: 'center',
                  width: 40,
                  key: 'stt',
                },
                {
                  title: 'Mã BN',
                  dataIndex: 'mabenhnhan',
                  width: 120,
                  key: 'mabenhnhan',
                },
                {
                  title: 'Tên bệnh nhân',
                  dataIndex: 'tenbenhnhan',
                  key: 'tenbenhnhan',
                },
              ]}
            />
          </ConfigProvider>
        </>
      ),
    },
    {
      key: '2',
      label: 'Chờ khám',
      children: (
        <>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#e3f0fb',
                },
              },
            }}
          >
            <Table
              bordered
              pagination={false}
              scroll={{ y: 289 }}
              dataSource={BNPending?.map((item) => ({
                STT: item.sttkham,
                mabn: item.mabenhnhan,
                tenBN: item.tenbenhnhan,
              }))}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'STT',
                  align: 'center',
                  width: 40,
                  key: 'STT',
                },
                {
                  title: 'Mã BN',
                  dataIndex: 'mabn',
                  width: 120,
                  key: 'mabn',
                },
                {
                  title: 'Tên bệnh nhân',
                  dataIndex: 'tenBN',
                  key: 'tenBN',
                },
              ]}
            />
          </ConfigProvider>
        </>
      ),
    },
  ]
  // useEffect(() => {
  //   dispatch(BNPendingByBranch(STATUS_KHAM, ID_BRANCH_LOGIN))
  //   fecthBNchuaThu()
  //   // Thiết lập interval để gọi API mỗi 3 giây
  //   const intervalId = setInterval(() => {
  //     dispatch(BNPendingByBranch(STATUS_KHAM, ID_BRANCH_LOGIN))
  //     fecthBNchuaThu()
  //   }, 3000)
  //   // Cleanup interval khi component unmount
  //   return () => clearInterval(intervalId)
  // }, [])
  return (
    <>
      <Tabs items={items} />
    </>
  )
}

export default TabPT
