import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, ConfigProvider, Input, Modal, Select, Space, Table, Tooltip } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  ListDichVuAction,
  deleteDichVuAction,
  getAllDichVu,
  getInfoDichVuAction,
  listGroupsDichVuAction,
  searchDanhMucDVAction,
} from '../../../store/actions/dichVuAction'
import ToastCus from '../../../utils/Toast'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalAdd from './Modal/ModalAdd'
import ModalChangePriceCN from './Modal/ModalChangePriceCN'
import ModalEdit from './Modal/ModalEdit'
const { confirm } = Modal
const DichVu = () => {
  const dispatch = useDispatch()
  const { ListDichVu, groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [loadingTable, setLoadingTable] = useState(false)
  const searchInput = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEdit, setisModalEdit] = useState(false)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [searchGroup, setSearchGroup] = useState(1)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [rowActive, setRowActive] = useState(null)
  const [listBranch, setListBranch] = useState(null)
  const [loadingCN, setLoadingCN] = useState(false)
  const [showEditPriceCN, setShowEditPriceCN] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancelEdit = () => {
    setisModalEdit(false)
  }
  const handleShowModalEdit = (item) => {
    setisModalEdit(true)
    dispatch(getInfoDichVuAction(item.iddv))
  }
  const onLoad = () => {
    dispatch(ListDichVuAction(searchGroup, company))
  }
  // handle delete danh mục xét nghiệm
  const showDeleteConfirm = (iddv, tendv) => {
    confirm({
      title: 'Bạn có muốn xóa loại dịch vụ ?',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có chắc muốn xóa loại dịch vụ ${tendv}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteDichVuAction(iddv, onLoad))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    dispatch(ListDichVuAction(searchGroup, company))
    dispatch(listGroupsDichVuAction())
    dispatch(listAllCompanyAction())
  }, [])

  const handleCompany = (value) => {
    setRowActive(null)
    setCompany(value)
    setListBranch([])
    // setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    dispatch(ListDichVuAction(searchGroup, value))
  }

  const handleSearch = useCallback(
    debounce((keyword, company, searchGroup) => {
      //search theo tất cả
      dispatch(searchDanhMucDVAction(searchGroup, company, keyword))
    }, 300),
    [],
  )

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'STT',
        width: 40,
        align: 'center',
      },
      {
        title: 'Mã dịch vụ',
        dataIndex: 'maDichVu',
        width: 200,
      },
      {
        title: 'Tên dịch vụ',
        dataIndex: 'tenDichVu',
      },
      {
        title: 'Đơn giá',
        dataIndex: 'donGia',
        width: 100,
        align: 'center',
        render: (text) => formatNumberVND(text),
      },
      {
        title: 'Tiền gửi mẫu',
        dataIndex: 'tienGuiMau',
        width: 100,
        align: 'center',
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'donVi',
        align: 'center',
        width: 150,
      },
      {
        title: 'Ghi chú',
        dataIndex: 'ghiChu',
        align: 'center',
        width: 250,
      },
      {
        title: 'Hành động',
        dataIndex: 'action',
        width: 100,
        fixed: 'right',
      },
    ],
    [],
  )

  const onChange = (value) => {
    setSearchGroup(value)
    // setIdChuyenKhoa(groupsDichVu.find((item) => item.idNhom === value).idChuyenKhoa)s
    setListBranch([])
    dispatch(ListDichVuAction(value, company))
  }

  const handleSearchCN = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleResetCN = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchCN(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchCN(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleResetCN(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleCheckBox = (e, record) => {
    const { checked } = e.target
    const updatedListBranch = listBranch.map((item) =>
      item.idchinhanh === record.idchinhanh ? { ...item, sudung: checked ? 1 : 0 } : item,
    )
    setListBranch(updatedListBranch)
  }


  const getListBranchUse = async (iddv, company, idChuyenKhoa) => {
    setLoadingCN(true)
    try {
      const { data } = await https.get(
        `DichVuChiNhanh/GetChiNhanhByIDDV?iddv=${iddv}&idct=${company}&idck=${idChuyenKhoa}`,
      )
      setListBranch(data)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi lấy thông tin dịch vụ chi nhánh',
      })
      setRowActive(null)
      setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    } finally {
      setLoadingCN(false)
    }
  }

  const handleClickRow = (row, company) => {
    setRowActive(row)
    getListBranchUse(row.iddv, company, row.idChuyenKhoa)
  }

  // const getAllCnByIdct = async (idct, idchuyenkhoa) => {
  //   setLoadingCN(true)
  //   try {
  //     const { data } = await https.get(
  //       `ChiNhanh/GetChiNhanhByCondition?idct=${idct}&idChuyenKhoa=${idchuyenkhoa}`,
  //     )
  //     //format to show list branch
  //     const list = data.map((item) => {
  //       return {
  //         idchinhanh: item.idchinhanh,
  //         tenchinhanh: item.tenchinhanh,
  //         sudung: 0,
  //         dongia: 0,
  //         tienguimau: 0,
  //         tienchietkhau: 0,
  //       }
  //     })
  //     setListBranch(list)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setLoadingCN(false)
  //   }
  // }

  // useEffect(() => {
  //   getAllCnByIdct(company, idChuyenKhoa)
  // }, [company, idChuyenKhoa])

  const handleUpdate = async () => {
    setLoadingCN(true)
    try {
      await https.put(
        'DichVuChiNhanh/UpdateSuDung',
        listBranch.map((item) => ({
          suDung: item.sudung,
          sudung: item.sudung,
          idCN: item.idchinhanh,
          id: rowActive.iddv,
        })),
      )
      ToastCus.fire({
        icon: 'success',
        text: 'Cập nhật thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: 'Cập nhật thất bại!',
      })
    } finally {
      setLoadingCN(false)
    }
  }

  const handleSelect = (e) => {
    if (e.target.checked) {
      setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 1 })))
    } else {
      setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    }
  }

  const columnsCN = [
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...getColumnSearchProps('tenchinhanh'),
    },
    {
      title: (
        <div className="flex justify-center flex-col items-center">
          <p>Sử dụng</p>

          <Checkbox
            checked={listBranch?.every((item) => item.sudung === 1)}
            onChange={handleSelect}
            disabled={!rowActive || loadingCN}
          />
        </div>
      ),
      dataIndex: 'sudung',
      width: 90,
      align: 'center',
      key: 'sudung',
      render: (text, record, index) => (
        <Checkbox
          disabled={!rowActive}
          checked={record.sudung === 1}
          onChange={(e) => {
            handleCheckBox(e, record)
          }}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
    },
  ]

  return (
    <>
      <div className="p-5 bg-[#efefef]">
        <div className="flex w-full items-center gap-5 p-2 bg-white rounded-md">
          <div className="flex w-full gap-3">
            <Select
              showSearch
              className="w-80"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={handleCompany}
              value={company}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
            />
            <Select
              onChange={onChange}
              value={searchGroup}
              defaultValue={-1}
              className="w-80"
              options={[
                ...(groupsDichVu
                  ? groupsDichVu.map((items) => ({
                      label: items.tenNhom,
                      value: items.idNhom,
                    }))
                  : []),
              ]}
            />
            <Input.Search
              onChange={(e) => {
                handleSearch(e.target.value, company, searchGroup)
              }}
              className="w-80"
              placeholder="Nhập từ khoá tìm kiếm"
            />
          </div>
          <div className="flex gap-5 ml-auto">
            <Button onClick={showModal} className="flex items-center" type="primary">
              <PlusOutlined /> Tạo dịch vụ
            </Button>
            <Button className="flex items-center ml-4" type="primary" disabled>
              <PlusOutlined /> Tạo nhóm dịch vụ
            </Button>
          </div>
        </div>
        <div className="flex gap-2 h-full mt-2">
          <div className="w-2/3 h-full bg-white rounded-md">
            <div className="p-2 h-full">
              <div className="mt-5">
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                      fontSize: 14,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                      },
                    },
                  }}
                >
                  <Table
                    loading={loadingTable}
                    pagination={{
                      defaultPageSize: 20,
                    }}
                    bordered
                    scroll={{
                      x: 1500,
                    }}
                    onRow={(row) => {
                      return {
                        onClick: () => {
                          handleClickRow(row, company)
                        }, // click row
                      }
                    }}
                    columns={columns}
                    dataSource={ListDichVu?.map((item, index) => ({
                      STT: ++index,
                      ...item,
                      action: (
                        <ul className="flex justify-around">
                          <li>
                            <Tooltip title="Chỉnh sửa" color="green">
                              <EditOutlined
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleShowModalEdit(item)
                                }}
                                className="text-xl text-green-500  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title="Xoá" color="red">
                              <DeleteOutlined
                                onClick={(e) => {
                                  e.stopPropagation()
                                  showDeleteConfirm(item.iddv, item.tenDichVu)
                                }}
                                className="text-xl text-red-500  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        </ul>
                      ),
                    }))}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full bg-white rounded-md">
            <div className="px-2 pt-2 flex justify-between text-base">
              <div className="flex gap-1 w4/5 ">
                {rowActive && (
                  <>
                    <span>Tên:</span>
                    <p className="font-semibold ">{rowActive?.tenDichVu}</p>
                  </>
                )}
              </div>
              <div className="w-1/5 flex justify-end">
                <Button
                  onClick={handleUpdate}
                  disabled={!rowActive || loadingCN}
                  className="h-7 w-full ml-2"
                  type="primary"
                  size="small"
                >
                  Lưu
                </Button>
              </div>
            </div>

            <div className="p-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                }}
              >
                <Table
                  loading={loadingCN}
                  scroll={{ y: 700 }}
                  // loading={isLoadingBranch}
                  pagination={false}
                  columns={columnsCN}
                  dataSource={listBranch?.map((item) => ({
                    ...item,
                    action: (
                      <ul className="flex justify-around">
                        {rowActive && (
                          <li>
                            <Tooltip title="Chỉnh sửa" color="green">
                              <EditOutlined
                                onClick={() => {
                                  setShowEditPriceCN({ show: true, data: item })
                                }}
                                className="text-xl text-green-500  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        )}
                      </ul>
                    ),
                  }))}
                  bordered
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalAdd
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          searchGroup={searchGroup}
          company={company}
        />
      )}
      {isModalEdit && (
        <ModalEdit
          isModalEdit={isModalEdit}
          handleCancelEdit={handleCancelEdit}
          searchGroup={searchGroup}
          company={company}
        />
      )}
      {showEditPriceCN && (
        <ModalChangePriceCN
          rowActive={rowActive}
          showEditPriceCN={showEditPriceCN}
          setShowEditPriceCN={setShowEditPriceCN}
          company={company}
          getListBranchUse={getListBranchUse}
        />
      )}
    </>
  )
}

export default DichVu
