import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function GongKinh() {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  const fetchReport = async () => {
    try {
      setIsLoading(true)
      const { data } = await reportService.getListGongKinhDaBan(
        dateForm,
        dateTo,
        (value?.includes('all') ? branch : value)
          ?.map((item) => `idChiNhanhs=${value?.includes('all') ? item?.idChiNhanh : item}`)
          ?.join('&')
          ?.toString(),
        search?.length ? search?.trim() : null,

        //value?.includes('all') ? branch?.map((item) => item?.idChiNhanh) : value,
      )
      setDataReport(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data?.filter((item) => item?.idChuyenKhoa === 4))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = dataReport?.map((item, index) => ({
      'STT ': ++index,
      'Chi nhánh': item?.chinhanh,
      'Mã hàng': item?.mahang,
      'Tên gọng': item?.tengong,
      'ĐVT ': item?.dvt,
      'Ngày bán': item?.ngayban ? moment(item?.ngayban).format('DD/MM/YYYY') : '-',
      'Số lượng': item?.soluongban,
    }))
    const name = 'BÁO CÁO GỌNG KÍNH ĐÃ BÁN'
    if (formatData?.length) exportExcelformat(formatData, name)
  }
  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'chinhanh',
      key: 'chinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mahang',
      key: 'mahang',
      width: 140,
    },
    {
      title: 'Tên gọng',
      dataIndex: 'tengong',
      key: 'tengong',
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Ngày bán',
      dataIndex: 'ngayban',
      key: 'ngayban',
      width: 120,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluongban',
      key: 'soluongban',
      align: 'center',
      width: 100,
    },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem] flex items-center gap-2">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value)
              setValue('all')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex w-64">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              // x: 2000,
              y: '70vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={dataReport}
            columns={columns}
            // summary={() => (
            //   <Table.Summary fixed>
            //     <Table.Summary.Row>
            //       <Table.Summary.Cell index={0}></Table.Summary.Cell>
            //       <Table.Summary.Cell index={1}></Table.Summary.Cell>
            //       <Table.Summary.Cell index={2}></Table.Summary.Cell>
            //       <Table.Summary.Cell index={3}></Table.Summary.Cell>
            //       <Table.Summary.Cell index={4}></Table.Summary.Cell>
            //       <Table.Summary.Cell index={5}></Table.Summary.Cell>
            //       <Table.Summary.Cell index={6}></Table.Summary.Cell>
            //       <Table.Summary.Cell className="text-center" index={7}>
            //         <span className="font-semibold">{dataReport?.length}</span>
            //       </Table.Summary.Cell>
            //       <Table.Summary.Cell index={8}></Table.Summary.Cell>
            //       <Table.Summary.Cell className="text-right" index={9}>
            //         <span className="font-semibold">
            //           {formattedNumber(
            //             //đơn giá
            //             dataReport?.reduce((tong, item) => (tong += item?.dongia), 0),
            //           )}
            //         </span>
            //       </Table.Summary.Cell>
            //       <Table.Summary.Cell className="text-right" index={10}>
            //         <span className="font-semibold">
            //           {formattedNumber(
            //             //thành tiền
            //             dataReport?.reduce((tong, item) => (tong += item?.thanhtien), 0),
            //           )}
            //         </span>
            //       </Table.Summary.Cell>
            //       <Table.Summary.Cell className="text-right" index={11}>
            //         <span className="font-semibold">
            //           {formattedNumber(
            //             //thành tiền
            //             dataReport?.reduce((tong, item) => (tong += item?.tiengiacong), 0),
            //           )}
            //         </span>
            //       </Table.Summary.Cell>
            //     </Table.Summary.Row>
            //   </Table.Summary>
            // )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default GongKinh
