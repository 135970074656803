import { InboxOutlined, SnippetsOutlined } from '@ant-design/icons'
import { Button } from '@mui/material'
import { Tabs } from 'antd'
import 'jspdf-autotable'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LapPhieuXuat from './Tabs/LapPhieuXuat'
import ListPhieu from './Tabs/ListPhieu'
import ListHangHoa from './Tabs/ListHangHoa'
import 'moment/locale/vi'
moment.locale('vi')

const XuatSuDung = () => {
  const [keyTabs, setKeyTabs] = useState(1)
  return (
    <>
      {/* {contextHolder} */}
      {/* <canvas id="barcode" className="hidden"></canvas> */}
      <div className="min-[1700px]:p-3 p-2 bg-[#E7E7E7] h-full">
        <div className="bg-white h-full rounded-xl border">
          <Tabs
            onChange={(key) => setKeyTabs(key)}
            type="card"
            className="p-2"
            items={[
              {
                key: 1,
                label: (
                  <span className="font-semibold flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
                      />
                    </svg>
                    Lập phiếu xuất
                  </span>
                ),
                children: (
                  <>
                    <LapPhieuXuat />
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <span className="font-semibold flex gap-2 items-center">
                    <SnippetsOutlined style={{ fontSize: 20 }} /> Số phiếu
                  </span>
                ),
                children: (
                  <>
                    <ListPhieu keyTabs={keyTabs} />
                  </>
                ),
              },
              {
                key: 3,
                label: (
                  <span className="font-semibold flex gap-2 items-center">
                    {' '}
                    <InboxOutlined style={{ fontSize: 20 }} />
                    Hàng hóa
                  </span>
                ),
                children: (
                  <>
                    <ListHangHoa keyTabs={keyTabs} />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default XuatSuDung
