import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Modal, notification, Tag } from 'antd'
import {
  ContainerOutlined,
  DeleteOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { https } from '../../../services/apiService'
import ModalAddDonvitinh from './Modal/ModalAddDonvitinh'
import ModalUpdateDonvitinh from './Modal/ModalUpdateDonvitinh'
import { DonViTinhService } from '../../../services/DonViTinh/DonViTinhService'
import { useDispatch } from 'react-redux' // Added import statement

function DonViTinh(props) {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [selectedMa, setSelectedMa] = useState(null)

  const fetchData = async () => {
    try {
      const response = await https.get('/DonViTinh/getAll')
      setData(response.data)
      setFilteredData(response.data)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter(
      (item) => item.donViTinh && item.donViTinh.toLowerCase().includes(value),
    )
    setFilteredData(filtered)
  }

  const handleReset = () => {
    setSearchText('')
    setFilteredData(data)
  }

  const openUpdateModal = (ma) => {
    setSelectedMa(ma)
    setIsUpdateModalOpen(true)
  }

  const handleDelete = async (ma, donViTinh) => {
    try {
      await DonViTinhService.DeleteDonViTinh(ma)
      // Refresh the data after delete
      fetchData()
      notification.success({
        message: 'Xóa thành công',
        description: `Đơn vị tính ${donViTinh} đã được xóa thành công.`,
      })
    } catch (error) {
      console.error('Failed to delete data:', error)
    }
  }

  const confirmDelete = (ma, donViTinh) => {
    Modal.confirm({
      title: `Bạn chắc chắn muốn xóa đơn vị tính ${donViTinh}?`,
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk: () => handleDelete(ma, donViTinh),
    })
  }

  const handleSwitchChange = async (ma, checked) => {
    try {
      await DonViTinhService.UpdateDonViTinh(ma, { suDung: checked ? 1 : 0 })
      fetchData()
      notification.success({
        message: 'Cập nhật thành công',
        description: `Đơn vị tính với mã ${ma} đã được cập nhật.`,
      })
    } catch (error) {
      console.error('Failed to update data:', error)
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 10,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã đơn vị tính',
      dataIndex: 'ma',
      key: 'ma',
      width: 20,
    },
    {
      title: 'Tên đơn vị tính',
      dataIndex: 'donViTinh',
      key: 'donViTinh',
      width: 20,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'suDung',
      key: 'suDung',
      width: 20,
      render: (text, record) => (
        <Tag color={record.suDung === 0 ? 'red' : 'green'}>
          {record.suDung === 0 ? 'Không sử dụng' : 'Sử dụng'}
        </Tag>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 20,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 10,
      render: (_, record) => (
        <Space size="middle">
          <ContainerOutlined
            style={{ color: 'blue', fontSize: 18 }}
            onClick={() => openUpdateModal(record.ma)}
          />
          <DeleteOutlined
            style={{ color: 'red', fontSize: 18 }}
            onClick={() => confirmDelete(record.ma, record.donViTinh)}
          />
        </Space>
      ),
    },
  ]

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false)
  }

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm">
      <div className="flex flex-col gap-5 bg-white border-dotted border-b-0 p-4">
        <div className="flex justify-between mb-4">
          <Space>
            <Input
              placeholder="Tìm kiếm tên đơn vị tính"
              value={searchText}
              onChange={handleSearch}
              style={{ width: 200 }}
            />
            <Button icon={<ReloadOutlined />} onClick={fetchData} />
          </Space>
          <Button type="primary" onClick={openModal}>
            Thêm đơn vị tính
          </Button>
        </div>
        <Table
          bordered
          // scroll={{
          //   x: '100%',
          //   y: 'calc(100vh - 300px)',
          // }}
          columns={columns}
          dataSource={filteredData}
          pagination={false} // Disable pagination
        />
      </div>
      <ModalAddDonvitinh
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onAddSuccess={fetchData}
      />
      <ModalUpdateDonvitinh
        isOpen={isUpdateModalOpen}
        onRequestClose={closeUpdateModal}
        ma={selectedMa}
        onUpdateSuccess={fetchData}
      />
    </div>
  )
}

DonViTinh.propTypes = {}

export default DonViTinh
