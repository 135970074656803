import moment from 'moment'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { Tag } from 'antd'
import 'moment/locale/vi'
moment.locale('vi')

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 40,
    fixed: 'left',
    render: (text, record, index) => ++index,
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    width: 250,
    fixed: 'left',
    // ...getColumnSearchProps('tenchinhanh'),
  },
  {
    title: 'Ngày',
    dataIndex: 'ngayketqua',
    key: 'ngayketqua',
    width: 140,
    align: 'center',
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },
  {
    title: 'Mã bệnh nhân',
    dataIndex: 'mabenhnhan',
    key: 'mabenhnhan',
    width: 150,
  },
  {
    title: 'Tên bệnh nhân',
    dataIndex: 'tenbenhnhan',
    key: 'tenbenhnhan',
    width: 250,
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'diachi',
    key: 'diachi',
    width: 300,
  },
  {
    title: 'Mã xét nghiệm',
    dataIndex: 'maxn',
    key: 'maxn',
    width: 150,
  },
  {
    title: 'Tên xét nghiệm',
    dataIndex: 'tenxn',
    key: 'tenxn',
    width: 400,
  },
  {
    title: 'Đơn vị',
    dataIndex: 'dvt',
    key: 'dvt',
    align: 'center',
    width: 75,
  },
  {
    title: 'Thanh toán',
    dataIndex: 'tienthanhtoan',
    key: 'tienthanhtoan',
    width: 100,
    align: 'right',
    render: (text) => formattedNumber(text ?? 0),
  },
  {
    title: 'Tiền gửi mẫu',
    dataIndex: 'tienguimau',
    key: 'tienguimau',
    width: 100,
    align: 'right',
    render: (text) => formattedNumber(text ?? 0),
  },
  {
    title: 'Lợi nhuận',
    dataIndex: 'loinhuan',
    key: 'loinhuan',
    width: 100,
    align: 'right',
    fixed: 'right',
    render: (text) => formattedNumber(text ?? 0),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'trangthaiguimau',
    key: 'trangthaiguimau',
    width: 120,
    align: 'center',
    fixed: 'right',
    render: (text) => {
      return (
        <span style={{ color: text?.includes('Đã') ? 'green' : 'red', fontWeight: 'bold' }}>
          {text}
        </span>
      )
    },
  },
]
