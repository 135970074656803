import { DatePicker, Input, Modal, Select, Checkbox } from 'antd'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import dayjs from 'dayjs'
import { recieveService } from '../../../../services/receive/recieveService'
import {
  updateBranch,
  huyenByQuanBranch,
  quanByCountryBranch,
} from '../../../../store/actions/BranchAction'
import 'moment/locale/vi'
moment.locale('vi')
// import { CheckBox } from 'devextreme-react'
const dateFormat = 'DD/MM/YYYY'
const ModalInfoBranch = ({ isEdit, setIsEdit }) => {
  const dispatch = useDispatch()
  //----------- STORE ---------------//
  const { infoBranch, staffsBranch, Tinh, Quan, Huyen } = useSelector(
    (state) => state.branchReducer,
  )
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { chuyenKhoa } = useSelector((state) => state.thuocVTReducer)
  //-------------- STATE -----------------//
  const [open, setOpen] = useState(false)
  // const [Quan, setQuan] = useState([])
  // const [phuong, setPhuong] = useState([])
  //----------- close modal edit ---------//
  const cancelShow = () => {
    setOpen(false)
    setIsEdit(false)
  }
  //--------------- handle submit-------------//
  const handleSubmit = (value) => {
    setOpen(false)
    dispatch(updateBranch(value))
  }
  //---------FORM FORMIK -----------//
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idChiNhanh: infoBranch?.idChiNhanh,
      maChiNhanh: infoBranch?.maChiNhanh,
      tenChiNhanh: infoBranch?.tenChiNhanh,
      dienThoai: infoBranch?.dienThoai,
      idsohotline: infoBranch?.idsohotline,
      sohotline: infoBranch?.sohotline, // ?? '0901.315.315',
      diaChi: infoBranch?.diaChi,
      idTinh: infoBranch?.idTinh,
      tenTinh: infoBranch?.tenTinh,
      idQuan: infoBranch?.idQuan,
      tenQuan: infoBranch?.tenQuan,
      idPhuong: infoBranch?.idPhuong,
      tenPhuong: infoBranch?.tenPhuong,
      idNhanVienQuanLy: infoBranch?.idNhanVienQuanLy,
      idCongTy: infoBranch?.idCongTy,
      idChuyenKhoa: infoBranch?.idChuyenKhoa,
      ghiChu: infoBranch?.ghiChu,
      ngayKhaiTruong: infoBranch?.ngayKhaiTruong,
      latitude: infoBranch?.latitude,
      longitude: infoBranch?.longitude,
      linkmap: infoBranch?.linkmap,
      loaichinhanh: infoBranch?.loaichinhanh,
      sudung: infoBranch?.sudung,
    },
    onSubmit: (values) => handleSubmit(values),
  })
  //-----------  Tìm nhân viên quản lý dựa trên idNhanVienQuanLy --------------//
  const selectedStaff = staffsBranch?.find((staff) => staff.id === infoBranch?.idNhanVienQuanLy)
  //---------------- tìm công ty dựa trên id công ty ------------------//
  const selectedCompany = listCompany?.find((company) => company.idct === infoBranch?.idCongTy)
  //---------------- show update -----------------//
  const showUpdate = () => {
    setOpen(true)
  }
  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  //------------- date picker---------//
  const handleDatePicker = (date, dateString) => {
    formik.setFieldValue('ngayKhaiTruong', moment(dateString, dateFormat).format())
  }
  // //------------- fetch Quận by tinh ----------//
  // const fetchQuan = async (id) => {
  //   try {
  //     const result = await recieveService.getQuanHuyen(id)
  //     setQuan(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // // ------------ fetch Phuong --------------//
  // const fetchPhuong = async (id) => {
  //   try {
  //     const result = await recieveService.getPhuongXa(id)
  //     setPhuong(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // ------------ close update ---------------/

  const closeUpdate = () => {
    setOpen(false)
  }
  //-------------- selected tinh tp --------//
  const selectedTinhTp = (value, label) => {
    dispatch(quanByCountryBranch(value))
    formik.setFieldValue('idTinh', value)
    formik.setFieldValue('tenTinh', label.label)
    formik.setFieldValue('idQuan', null)
    formik.setFieldValue('idPhuong', null)
  }
  //-------------- selected quan huyen -----------//
  const selectedQuanHuyen = (value, label) => {
    dispatch(huyenByQuanBranch(value))
    formik.setFieldValue('idQuan', value)
    formik.setFieldValue('tenQuan', label.label)
    formik.setFieldValue('idPhuong', null)
  }
  //-------------- selected phuong xa -----------//
  const selectedPhuongXa = (value, label) => {
    formik.setFieldValue('idPhuong', value)
    formik.setFieldValue('tenPhuong', label.label)
  }
  const handleChangeSuDung = (checked) => {
    if (open) formik.setFieldValue('sudung', checked ? 1 : 0)
  }
  const handleChangeLatLong = (name, value) => {
    if (open) formik.setFieldValue(name, !value || value === '' ? 0 : value)
  }
  useEffect(() => {
    if (infoBranch) {
      infoBranch.idTinh && dispatch(quanByCountryBranch(infoBranch.idTinh))
      infoBranch.idQuan && dispatch(huyenByQuanBranch(infoBranch.idQuan))
    }
  }, [infoBranch])
  return (
    <Modal
      footer={null}
      width={700}
      open={isEdit}
      title={<h2 className="text-xl">Thông tin chi tiết</h2>}
      onCancel={cancelShow}
    >
      <form className="px-3">
        <div className="border-b py-2">
          <div className="flex justify-between items-center">
            <h2 className="font-semibold text-gray-500">THÔNG TIN </h2>
            {!open ? (
              <Button onClick={showUpdate} variant="text" startIcon={<BorderColorIcon />}>
                Chỉnh sửa
              </Button>
            ) : (
              <div className="flex gap-2">
                <Button onClick={closeUpdate} size="small" variant="outlined">
                  Huỷ
                </Button>
                <Button
                  onClick={formik.handleSubmit}
                  size="small"
                  variant="contained"
                  startIcon={<SaveAsIcon />}
                >
                  Lưu
                </Button>
              </div>
            )}
          </div>
          <div className="">
            <div className="flex gap-2 mb-1">
              <div className="w-1/2">
                <label className="font-semibold">Tên chi nhánh</label>
                <Input
                  name="tenChiNhanh"
                  readOnly={!open}
                  onChange={formik.handleChange}
                  value={open ? formik.values.tenChiNhanh : infoBranch?.tenChiNhanh}
                  variant={!open ? 'filled' : 'outlined'}
                />
              </div>
              <div className="w-1/2">
                <label className="font-semibold">Mã</label>
                <Input
                  name="maChiNhanh"
                  readOnly
                  value={formik.values.maChiNhanh}
                  variant="filled"
                />
              </div>
            </div>
            <div className="flex gap-2 mb-1">
              <div className="w-1/3">
                <label className="font-semibold">SĐT</label>
                <Input
                  name="dienThoai"
                  readOnly={!open}
                  value={open ? formik.values.dienThoai : infoBranch?.dienThoai}
                  onChange={formik.handleChange}
                  variant={!open ? 'filled' : 'outlined'}
                />
              </div>
              <div className="w-1/3">
                <label className="font-semibold">Hotline</label>
                <Input
                  name="hotline"
                  variant={'filled'}
                  readOnly
                  value={open ? formik.values.sohotline : infoBranch?.sohotline}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-1/3">
                <label className="font-semibold">Ngày khai trương</label>
                {open ? (
                  <DatePicker
                    allowClear={false}
                    onChange={handleDatePicker}
                    className="w-full"
                    format={dateFormat}
                    value={formik.values.ngayKhaiTruong && dayjs(formik.values.ngayKhaiTruong)}
                  />
                ) : (
                  <Input
                    readOnly
                    value={
                      infoBranch?.ngayKhaiTruong &&
                      moment(infoBranch?.ngayKhaiTruong).format('DD/MM/YYYY')
                    }
                    variant="filled"
                  />
                )}
              </div>
            </div>
            <div className="flex gap-2 mb-1">
              <div className="w-full">
                <label className="font-semibold">Ghi chú</label>
                <Input
                  readOnly={!open}
                  onChange={formik.handleChange}
                  name="ghiChu"
                  value={open ? formik.values.ghiChu : infoBranch?.ghiChu}
                  variant={!open ? 'filled' : 'outlined'}
                />
              </div>
            </div>
            <div className="w-1/4">
              <label className="font-semibold mr-2">Sử dụng</label>
              <Checkbox
                checked={open ? formik.values.sudung === 1 : infoBranch?.sudung === 1}
                // readOnly={!open}
                onChange={(e) => {
                  handleChangeSuDung(e.target.checked)
                }}
                // name="ghiChu"
                // value={open ? formik.values.ghiChu : infoBranch?.ghiChu}
                // variant={!open ? 'filled' : 'outlined'}
              />
            </div>
          </div>
        </div>
        <div className="border-b py-2 mt-2">
          <h2 className="font-semibold text-gray-500">ĐỊA ĐIỂM</h2>
          <div className="mt-2 mb-1">
            <div className="flex gap-2 mb-1">
              <div className="w-1/3">
                <label className="font-semibold">Tỉnh/Tp</label>
                {open ? (
                  <Select
                    onChange={selectedTinhTp}
                    className="w-full"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Tinh?.map((items) => ({
                      label: items.tenTinh,
                      value: items.idTinh,
                    }))}
                    defaultValue={infoBranch?.tenTinh}
                    value={formik.values.idTinh}
                  />
                ) : (
                  <Input readOnly value={infoBranch?.tenTinh} variant="filled" />
                )}
              </div>
              <div className="w-1/3">
                <label className="font-semibold">Quận/Huyện</label>
                {open ? (
                  <Select
                    onChange={selectedQuanHuyen}
                    className="w-full"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Quan?.map((items) => ({
                      label: items.tenQuan,
                      value: items.idQuan,
                    }))}
                    defaultValue={infoBranch?.tenQuan}
                    value={formik.values.idQuan}
                  />
                ) : (
                  <Input readOnly value={infoBranch?.tenQuan} variant="filled" />
                )}
              </div>
              <div className="w-1/3">
                <label className="font-semibold">Phường/Xã</label>
                {open ? (
                  <Select
                    onChange={selectedPhuongXa}
                    className="w-full"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Huyen?.map((items) => ({
                      label: items.tenPhuong,
                      value: items.idPhuong,
                    }))}
                    defaultValue={infoBranch?.tenPhuong}
                    value={formik.values.idPhuong}
                  />
                ) : (
                  <Input readOnly value={infoBranch?.tenPhuong} variant="filled" />
                )}
              </div>
            </div>
            <div>
              <label className="font-semibold">Địa chỉ</label>
              <Input
                name="diaChi"
                readOnly={!open}
                onChange={formik.handleChange}
                value={open ? formik.values.diaChi : infoBranch?.diaChi}
                variant={!open ? 'filled' : 'outlined'}
              />
            </div>
            <div className="flex gap-2 mt-1 mb-1">
              <div className="w-1/2">
                <label className="font-semibold">Latitude</label>
                <Input
                  readOnly={!open}
                  name="latitude"
                  value={open ? formik.values.latitude : infoBranch?.latitude}
                  onChange={(e) => {
                    handleChangeLatLong('latitude', e.target.value)
                  }}
                  type="Number"
                  variant={!open ? 'filled' : 'outlined'}
                />
              </div>
              <div className="w-1/2">
                <label className="font-semibold">Longitude</label>
                <Input
                  type="Number"
                  readOnly={!open}
                  onChange={(e) => {
                    handleChangeLatLong('longitude', e.target.value)
                  }}
                  name="longitude"
                  value={open ? formik.values.longitude : infoBranch?.longitude}
                  variant={!open ? 'filled' : 'outlined'}
                />
              </div>
            </div>
            <div>
              <label className="font-semibold">Link map</label>
              <Input
                name="linkmap"
                readOnly={!open}
                onChange={formik.handleChange}
                value={open ? formik.values.linkmap : infoBranch?.linkmap}
                variant={!open ? 'filled' : 'outlined'}
              />
            </div>
          </div>
        </div>
        <div className="py-2 mt-2">
          <h2 className="font-semibold text-gray-500">VAI TRÒ</h2>
          <div className="mt-2">
            <div className="flex gap-2 mb-1">
              <div className="w-1/2">
                <label className="font-semibold">Loại</label>
                {open ? (
                  <Select
                    className="w-full"
                    onChange={handleSelected('loaichinhanh')}
                    value={formik.values.loaichinhanh}
                    options={[
                      { label: 'Chi nhánh', value: 'CHINHANH' },
                      { label: 'Văn phòng', value: 'VANPHONG' },
                    ]}
                  />
                ) : (
                  <Input
                    readOnly
                    value={
                      infoBranch?.loaichinhanh === 'VANPHONG'
                        ? 'Văn phòng'
                        : infoBranch?.loaichinhanh === 'CHINHANH'
                          ? 'Chi nhánh'
                          : ''
                    }
                    variant="filled"
                  />
                )}
              </div>
              <div className="w-1/2">
                <label className="font-semibold">Chuyên khoa</label>
                {open ? (
                  <Select
                    onChange={handleSelected('idChuyenKhoa')}
                    className="w-full"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={chuyenKhoa?.map((items) => ({
                      label: items.tenChuyenKhoa,
                      value: items.idChuyenKhoa,
                    }))}
                    value={formik.values.idChuyenKhoa}
                  />
                ) : (
                  <Input readOnly value={infoBranch?.tenChuyenKhoa} variant="filled" />
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="w-1/2">
                <label className="font-semibold">Công ty</label>
                {open ? (
                  <Select
                    onChange={handleSelected('idCongTy')}
                    className="w-full"
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                    value={formik.values.idCongTy}
                  />
                ) : (
                  <Input
                    readOnly
                    value={selectedCompany ? selectedCompany.tenct : ''}
                    variant="filled"
                  />
                )}
              </div>
              <div className="w-1/2">
                <label className="font-semibold">Nhân viên quản lý</label>
                {open ? (
                  <Select
                    onChange={handleSelected('idNhanVienQuanLy')}
                    className="w-full"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={staffsBranch?.map((items) => ({
                      label: items.tennv,
                      value: items.id,
                    }))}
                    value={formik.values.idNhanVienQuanLy}
                  />
                ) : (
                  <Input
                    value={selectedStaff ? selectedStaff.tennv : ''}
                    variant="filled"
                    readOnly
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalInfoBranch
