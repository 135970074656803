import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FilterOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import {
  Badge,
  Button,
  ConfigProvider,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import {
  deleteBNCRMById,
  getListKHCRM,
  infoBNCRMAction,
} from '../../../store/actions/benhnhanCRMAction'
import * as typeAction from '../../../store/constants/constants'
import ModalAdd from './ModalAddBenhNhan/ModalAdd'
import ModalEditBenhNham from './ModalEditBenhNhan/ModalEditBenhNham'
import ModalImport from './ModalInponrt/ModalImport'
import OptionFIlter from './OptionFIlter'

moment.locale('vi')
const { confirm } = Modal

const TraCuu = () => {
  const dispatch = useDispatch()
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState({})
  const [listBN, setListBN] = useState([])

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [loading, setLoading] = useState(false)

  const fetchBN = async (filterKH, keyword, page) => {
    setLoading(true)
    try {
      const { data } = await https.get('CRMBenhNhan/SearchBenhNhanByCondition', {
        params: {
          idKH: filterKH,
          keyword: keyword,
          pageNumber: page,
        },
      })
      setListBN(data.data)
      setTotalPage(data.totalPage)
      setPage(page)
      SetSearchParams({ page })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handlDeleteBenhNhan = (idbn) => {
    dispatch(deleteBNCRMById(idbn))
  }

  const handleAddKhamDoan = () => {
    dispatch({
      type: typeAction.OPEN_ADD_KHAM_DOAN,
    })
  }

  // xử lí dữ liệu xuất excel
  const exportToExcel = () => {
    // const headers = [
    //   'Mã bệnh nhân',
    //   'Tên bệnh nhân',
    //   'Giới tính',
    //   'Ngày sinh',
    //   'Gói khám',
    //   'Tên công ty',
    //   'KQ xét nghiệm',
    //   'KQ khám',
    //   'KQ CĐHA',
    //   'Ghi chú',
    // ]
    // const formattedData = data.map((item) => ({
    //   'Mã bệnh nhân': item.mabn,
    //   'Tên bệnh nhân': item.tenbn,
    //   'Giới tính': item.gioitinh,
    //   'Ngày sinh': moment(item.ngaysinh).format('DD/MM/YYYY'),
    //   'Gói khám': item.tengoikham,
    //   'Tên công ty': item.tenkh,
    //   'KQ xét nghiệm': item.linK_KQXN ?? '',
    //   'KQ khám': item.linK_KQKHAM ?? '',
    //   'KQ CĐHA': item.linK_KQCDHA ?? '',
    //   'Ghi chú': item.ghichu,
    // }))
    // const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    // const wb = XLSX.utils.book_new()
    // XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    // XLSX.writeFile(wb, 'Danh sách bệnh nhân.xlsx')
  }
  // xử lý gửi sms

  useEffect(() => {
    dispatch(getListKHCRM())
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 'STT',
        width: 40,
        align: 'center',
      },
      {
        title: 'Mã BN',
        dataIndex: 'MABN',
        key: 'MABN',
        width: 120,
      },
      {
        title: 'Tên BN',
        dataIndex: 'TENBN',
        key: 'TENBN',
        width: 200,
      },
      {
        title: 'Giới tính',
        dataIndex: 'GIOITINH',
        key: 'GIOITINH',
        width: 90,
        align: 'center',
      },
      {
        title: 'Ngày sinh',
        dataIndex: 'NGAYSINH',
        key: 'NGAYSINH',
        width: 100,
        align: 'center',
      },
      {
        title: 'Gói khám',
        dataIndex: 'GOIKHAM',
        key: 'GOIKHAM',
        width: 100,
        align: 'center',
      },
      {
        title: 'Tên công ty',
        dataIndex: 'TENCTY',
        key: 'TENCTY',
        width: 250,
        // ...getColumnSearchProps("TENCTY"),
      },
      {
        title: 'KQ xét nghiệm',
        dataIndex: 'KQXN',
        key: 'KQXN',
        width: 130,
        align: 'center',
      },
      {
        title: 'KQ khám',
        dataIndex: 'KQK',
        key: 'LQK',
        width: 100,
        align: 'center',
      },
      {
        title: 'KQ CĐHA',
        dataIndex: 'KQCDHA',
        key: 'KQCDHA',
        width: 100,
        align: 'center',
      },
      {
        title: 'SMS',
        dataIndex: 'SMS',
        key: 'SMS',
        width: 120,
        align: 'center',
      },
      {
        title: 'Người cập nhật KQ',
        dataIndex: 'NGUOIKQ',
        key: 'NGUOIKQ',
        width: 150,
        align: 'center',
      },
      {
        title: 'Ngày cập nhật KQ',
        dataIndex: 'NGAYKQ',
        key: 'NGAYKQ',
        width: 150,
        align: 'center',
      },
      {
        title: 'Người gửi SMS',
        dataIndex: 'NGUOIGUISNS',
        key: 'NGUOIGUISNS',
        width: 150,
      },
      {
        title: 'Ngày gửi SMS',
        dataIndex: 'NGAYGUISMS',
        key: 'NGAYGUISMS',
        width: 150,
        align: 'center',
      },
      {
        title: 'Người tạo',
        dataIndex: 'NGUOITAO',
        key: 'NGUOITAO',
        width: 150,
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'NGAYTAO',
        key: 'NGAYTAO',
        width: 150,
        align: 'center',
      },
      {
        title: 'Ghi chú',
        dataIndex: 'GHICHU',
        key: 'GHICHU',
        width: 200,
      },
      {
        title: 'Hành động',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 90,
        fixed: 'right',
      },
    ]
  }, [])

  useEffect(() => {
    fetchBN('', '', 1)
  }, [])

  const handleRowSelectionChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowsPerPage((prevState) => ({
      ...prevState,
      [page]: newSelectedRowKeys, // Store selected rows for the current page
    }))
  }

  const rowSelection = {
    selectedRowKeys: selectedRowsPerPage[page] || [], // Restore selected rows for the current page
    onChange: handleRowSelectionChange,
  }

  const listBNSendSMS = useMemo(() => {
    return Object.values(selectedRowsPerPage).reduce((acc, current) => acc.concat(current), [])
  }, [selectedRowsPerPage])

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn gửi sms hay không ?',
      icon: <ExclamationCircleFilled />,
      content: `số lượng bệnh nhân gửi của bạn là ${listBNSendSMS.length} bệnh nhân ! `,
      okText: 'Gửi SMS',
      cancelText: 'Huỷ bỏ',
      onOk() {
        // dispatch(sendSMSCRMAction(listSMS))
      },
      onCancel() {},
    })
  }

  console.log('render')

  const content = (
    <div className="w-60">
      <Button
        onClick={showConfirm}
        type="primary"
        disabled={listBNSendSMS.length === 0}
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Gửi sms bệnh nhân đã chọn
      </Button>
      <Button onClick={showConfirm} type="primary" style={{ marginBottom: '8px', width: '100%' }}>
        Gửi sms tất cả bệnh nhân
      </Button>
    </div>
  )

  return (
    <>
      <div className="p-2 bg-[#E8E8E8] h-full ">
        <div
          className="bg-white p-2 h-full rounded-md"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex items-center gap-2">
            <div className="mr-4">
              <OptionFIlter page={page} />
            </div>
            <div className="flex items-center gap-1">
              <div>
                <Popover content={content} trigger="click" placement="bottom">
                  <Button type="primary">
                    <SendOutlined /> Gửi SMS
                  </Button>
                </Popover>
              </div>
            </div>
            <div className="flex items-center gap-2 ml-auto">
              {listBNSendSMS.length !== 0 ? (
                <>
                  <p className="hidden xl:block">
                    <span className="font-bold">Đã chọn:</span>{' '}
                    <span className="text-red-500 text-lg">{listBNSendSMS.length}</span>{' '}
                  </p>
                  <Button type="link" onClick={() => setSelectedRowsPerPage({})}>
                    Bỏ chọn tất cả <ClearOutlined />
                  </Button>
                </>
              ) : (
                ''
              )}

              <Button
                onClick={exportToExcel}
                className="text-green-700 font-semibold"
                type="default"
              >
                Excel <ImportExportIcon />
              </Button>
              <Button
                onClick={() => {
                  dispatch({
                    type: typeAction.OPEN_MODAL_IMPORT_KHAM_DOAN,
                  })
                }}
                className="bg-blue-500 hover:bg-blue-400 text-white"
              >
                import
              </Button>
              <Button
                onClick={handleAddKhamDoan}
                className="bg-green-700 hover:bg-green-500 text-white flex items-center"
              >
                Tạo <AddIcon fontSize="small" />
              </Button>
            </div>
          </div>
          <div className="mt-5">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                loading={loading}
                pagination={false}
                bordered
                className="mb-5 table-crmBenhNhan"
                scroll={{
                  y: 680,
                  x: 1500,
                }}
                dataSource={listBN?.map((item, index) => ({
                  ...item,
                  STT: (page - 1) * 50 + (index + 1),
                  key: item.idbn,
                  MABN: item.mabn,
                  TENBN: item.tenbn,
                  GIOITINH: item.gioitinh,
                  SODIENTHOAI: item.sodienthoai,
                  NGAYSINH: item.ngaysinh && moment(item.ngaysinh).format('DD/MM/YYYY'),
                  GOIKHAM: item.tengoikham,
                  KQXN: !item.linK_KQXN ? (
                    <Tag color="volcano">Chưa có </Tag>
                  ) : (
                    <Tag color="green">Hoàn thành</Tag>
                  ),
                  KQK: !item.linK_KQKHAM ? (
                    <Tag color="volcano">Chưa có </Tag>
                  ) : (
                    <Tag color="green">Hoàn thành</Tag>
                  ),
                  KQCDHA: !item.linK_KQCDHA ? (
                    <Tag color="volcano">Chưa có </Tag>
                  ) : (
                    <Tag color="green">Hoàn thành</Tag>
                  ),
                  TENCTY: item.tenkh,
                  NGUOIGUISNS: item.nguoiguisms,
                  NGAYGUISMS:
                    item.ngayguisms && moment(item.ngayguisms).format('DD/MM/YY HH:mm:ss'),
                  NGUOITAO: item.tennguoitao,
                  NGAYTAO: item.ngaytao && moment(item.ngaytao).format('DD/MM/YY HH:mm:ss'),
                  NGAYKQ: item.ngaykq && moment(item.ngaykq).format('DD/MM/YY HH:mm:ss'),
                  NGUOIKQ: item.tennguoikq,
                  GHICHU: item.ghichu,
                  SMS:
                    item.trangthaisms === 1 ? (
                      <Tag>Chưa gửi</Tag>
                    ) : item.trangthaisms === 2 ? (
                      <Tag color="success">Gửi thành công</Tag>
                    ) : (
                      <Tag color="error">Gửi thất bại</Tag>
                    ),
                  action: (
                    <ul className="flex gap-2 justify-around gap-2 ">
                      <li className="text-lg text-red-500">
                        <Tooltip title="Xóa" placement="top" className="cursor-pointer" color="red">
                          <Popconfirm
                            placement="left"
                            title="Xoá bệnh nhân"
                            description="Bạn có chắc xoá bệnh nhân này không ?"
                            onConfirm={() => {
                              handlDeleteBenhNhan(item.idbn)
                            }}
                            okText="Xoá"
                            okType="danger"
                            cancelText="Huỷ"
                            icon={
                              <QuestionCircleOutlined
                                style={{
                                  color: 'red',
                                }}
                              />
                            }
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </Tooltip>
                      </li>
                      <li
                        onClick={() => {
                          dispatch(infoBNCRMAction(item.idbn))
                          dispatch({
                            type: typeAction.OPEN_MODAL_EDIT_BN_KHAM_DOAN,
                          })
                        }}
                        className="text-lg text-green-500"
                      >
                        <Tooltip
                          title="sửa"
                          className="cursor-pointer"
                          placement="top"
                          color="green"
                        >
                          <EditOutlined />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
                columns={columns}
                rowSelection={rowSelection}
              />
            </ConfigProvider>
            <div className="flex justify-end">
              <Pagination
                className="mt-5"
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={50}
                total={50 * totalPage}
                onChange={(page, pagesize) => {
                  console.log(page, pagesize);
                  setPage(page)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalAdd />
      <ModalImport />
      <ModalEditBenhNham />
    </>
  )
}

export default TraCuu
