import React, { useState, useEffect } from 'react'
import { format, addDays, isBefore, parse } from 'date-fns'
import { Avatar, Button, List, message, Tag, Pagination, Spin, Typography, Input } from 'antd'
import Modal from './Modal'
import CopyModal from './CopyModal'
import {
  CheckCircleOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { https } from '../../../../services/apiService'
import logo from '../../../../assets/images/logo/MemLogo_315_Logo.png'
import NotFound from './notfound/notfound'

const positionDisplayNames = {
  BacSi: 'Bác Sĩ',
  DieuDuong: 'Điều Dưỡng',
  KyThuatVien: 'Kỹ Thuật Viên',
  DuocSi: 'Dược Sĩ',
  LeTan: 'Lễ Tân',
}
const { Text } = Typography
const CalendarGrid = ({ currentWeek, days, branchId, selectedPosition, onLoad }) => {
  const [shifts, setShifts] = useState([])
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedShift, setSelectedShift] = useState(null)
  const [selectedEmployees, setSelectedEmployees] = useState({})

  const [targetDays, setTargetDays] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalEmployees, setTotalEmployees] = useState(0)
  const [loading, setLoading] = useState(false) // Add loading state
  const [loading2, setLoading2] = useState(false) // Add loading state

  const [isBranchInfoModalVisible, setBranchInfoModalVisible] = useState(false) // State to control Modal visibility
  const [searchKeyword, setSearchKeyword] = useState('')

  //
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
  const [currentEmployee, setCurrentEmployee] = useState(null)
  const [currentCheckboxState, setCurrentCheckboxState] = useState(false)
  const [checkboxStates, setCheckboxStates] = useState({})
  useEffect(() => {
    setTargetDays(days) // Initialize with all days selected by default
  }, [days])

  useEffect(() => {
    // Fetch shifts for the default position "Lễ Tân"
    fetchShifts(selectedPosition)
  }, [selectedPosition])

  const fetchShifts = async (loaiNhomND) => {
    try {
      const response = await https.get(`/CaLamViec/GetCaLamViecByNhom`, {
        params: {
          loaiNhomND: selectedPosition,
        },
      })
      setShifts(response.data)
    } catch (error) {
      console.error('Error fetching shifts:', error)
    }
  }

  const fetchEmployees = async (idCN, loaiNhomND, fromDate, toDate) => {
    setLoading(true)
    try {
      const response = await https.get(`/CaLamViec/GetAllDanhSachDangKyByIDCN`, {
        params: {
          idCN: idCN,
          loaiNhomND: selectedPosition,
          fromDate: fromDate,
          toDate: toDate,
        },
      })

      console.log('API Response:', response.data) // Debugging log
      const transformedData = transformEmployeeData(response.data)
      console.log('Transformed Data:', transformedData) // Debugging log
      setSelectedEmployees(transformedData)
    } catch (error) {
      console.error('Error fetching employees:', error)
    } finally {
      setLoading(false) // Set loading to false after API call
    }
  }

  const fetchEmployeeListByShift = async (
    idCN,
    ngayDK,
    idCLV,
    pageNumber = 1,
    searchKeyword = '',
  ) => {
    setLoading2(true)
    try {
      const params = {
        keyword: searchKeyword,
        idCN: idCN,
        ngayDK: ngayDK,
        idCLV: idCLV,
      }
      if (pageNumber !== '') {
        params.pageNumber = pageNumber
      }

      const response = await https.get(`/CaLamViec/GetDanhSachNhanVienByIdCLV`, { params })
      const employees = response.data.data.map((employee) => ({
        id: employee.idNV,
        name: employee.tenNV,
        tenCN: employee.tenCN,
        idtt: employee.idtt,
        idCalamViec: idCLV,
        iddkclvct: employee.iddkclvct,
        idCN: employee.idcn, // Include idCN here
        maNV: employee.maNV,
      }))
      setTotalEmployees(response.data.totalCount)
      return employees
    } catch (error) {
      console.error('Error fetching employee list by shift:', error)
      return []
    } finally {
      setLoading2(false) // Set loading to false after API call
    }
  }
  const handleSearch = async () => {
    const formattedDay = format(parse(selectedShift.day, 'dd/MM', new Date()), 'yyyy-MM-dd')
    const employees = await fetchEmployeeListByShift(
      branchId,
      formattedDay,
      selectedShift.idCalamViecCC,
      1,
      searchKeyword,
    )
    setSelectedEmployees((prevState) => ({
      ...prevState,
      [`Shift ${selectedShift.idCalamViec}`]: {
        ...prevState[`Shift ${selectedShift.idCalamViec}`],
        [selectedShift.day]: employees,
      },
    }))
    setCurrentPage(1)
  }

  const transformEmployeeData = (data) => {
    const transformed = {}

    data.caLamViecTheoThang.forEach((dayData) => {
      const day = format(new Date(dayData.ngayDK), 'dd/MM')
      dayData.caLamViecTheoNgay.forEach((shiftData) => {
        const shiftName = `Shift ${shiftData.idCalamViec}`
        if (!transformed[shiftName]) {
          transformed[shiftName] = {}
        }
        transformed[shiftName][day] = shiftData.caLamViecTheoCa.map((employee) => ({
          iddkclvct: employee.iddkclvct,
          name: employee.tenNV,
          // position: employee.chucVu,
          idtt: employee.idtt,
          idCalamViec: shiftData.idCalamViec, // Include idCalamViec here
        }))
      })
    })

    return transformed
  }

  const handlePositionChange = (event) => {
    const selectedValue = event.target.value

    if (selectedValue) {
      fetchShifts(selectedValue)
    } else {
      setShifts([]) // Clear shifts if no position is selected
    }
  }

  const handleButtonClick = async (shift) => {
    setSelectedShift(shift) // Set the shift first, but don't show the modal yet

    // Fetch the employees for the selected shift
    const formattedDay = format(parse(shift.day, 'dd/MM', new Date()), 'yyyy-MM-dd')

    try {
      const employees = await fetchEmployeeListByShift(
        branchId,
        formattedDay,
        shift.idCalamViecCC,
        currentPage,
      )

      // Once the data is fetched, update the state
      setSelectedEmployees((prevState) => ({
        ...prevState,
        [`Shift ${shift.idCalamViec}`]: {
          ...prevState[`Shift ${shift.idCalamViec}`],
          [shift.day]: employees,
        },
      }))

      // After state is updated, show the modal
      setModalVisible(true)
    } catch (error) {
      console.error('Error fetching employee data:', error)
      // Optionally, handle the error (e.g., show a message or keep the modal hidden)
    }
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (selectedShift) {
      const formattedDay = format(parse(selectedShift.day, 'dd/MM', new Date()), 'yyyy-MM-dd')
      const newEmployees = await fetchEmployeeListByShift(
        branchId,
        formattedDay,
        selectedShift.idCalamViecCC,
        page,
        searchKeyword,
      )
      setSelectedEmployees((prevState) => ({
        ...prevState,
        [`Shift ${selectedShift.idCalamViec}`]: {
          ...prevState[`Shift ${selectedShift.idCalamViec}`],
          [selectedShift.day]: [
            ...(prevState[`Shift ${selectedShift.idCalamViec}`]?.[selectedShift.day] || []),
            ...newEmployees,
          ],
        },
      }))
    }
  }

  const handleSkip = () => {
    setModalVisible(false)
    setSelectedShift(null)
    setSearchKeyword('')
    setCheckboxStates({})
    setCurrentPage(1)
  }

  const isPastDate = (day) => {
    const today = new Date()
    const date = parse(day, 'dd/MM', new Date())
    return isBefore(date, today)
  }

  const handleSave = async (newEmployees) => {
    const updatedEmployeeData = newEmployees.map((employee) => ({
      idNV: employee.id,
      tenNV: employee.name,
      iddkclvct: employee.iddkclvct,
      idCN: employee.idCN,
      idtt: employee.idtt,
    }))
    try {
      const response = await https.put(
        '/CaLamViec/UpdateDanhSachNhanVienByIdCLV',
        updatedEmployeeData,
      )
      if (response.status === 200) {
        console.log('Employee data saved successfully:', updatedEmployeeData)
        message.success('Cập nhật thành công')
        // Fetch the updated employees data
        await fetchEmployees(
          branchId,
          selectedPosition,
          format(currentWeek, 'yyyy-MM-dd'),
          format(addDays(currentWeek, 6), 'yyyy-MM-dd'),
        )
        setModalVisible(false) // Close the modal after saving
        // updatedEmployeeData trở về rỗng
        // setSelectedEmployees({})
        setCurrentPage(1)
        setSearchKeyword('')
        setCheckboxStates({})
      } else {
        message.error('Cập nhật thất bại')
      }
    } catch (error) {
      console.error('Error saving employee data:', error)
      message.error('Đã xảy ra lỗi khi cập nhật dữ liệu')
    }
  }

  const handleCheckboxChange = (employeeId, checked) => {
    const updatedEmployees = { ...selectedEmployees }
    Object.keys(updatedEmployees).forEach((shiftName) => {
      Object.keys(updatedEmployees[shiftName]).forEach((day) => {
        updatedEmployees[shiftName][day] = updatedEmployees[shiftName][day].map((employee) => {
          if (employee.id === employeeId) {
            return {
              ...employee,
              idCN: checked ? branchId : employee.idCN === branchId ? null : employee.idCN,
            }
          }
          return employee
        })
      })
    })
    setSelectedEmployees(updatedEmployees)
    setCheckboxStates((prev) => ({ ...prev, [employeeId]: checked }))
  }

  const onLoadfetch = () => {
    if (onLoad) {
      fetchEmployees(
        branchId,
        selectedPosition,
        format(currentWeek, 'yyyy-MM-dd'),
        format(addDays(currentWeek, 6), 'yyyy-MM-dd'),
      )
    }
  }
  useEffect(() => {
    onLoadfetch()
  }, [onLoad])

  return (
    <Spin spinning={loading2}>
      <div className="grid grid-cols-8 gap-2">
        <div className="col-span-1 flex flex-col mt-[2.1rem]">
          {shifts.map((shift, index) => (
            <div
              key={index}
              className="min-h-40 border-2 rounded mb-2 flex flex-grow items-center justify-center"
            >
              <p className="font-bold">{shift.batDau}</p>-
              <p className="font-bold">{shift.ketThuc}</p>
            </div>
          ))}
        </div>
        <div className="col-span-7 grid grid-cols-7 gap-2">
          {selectedPosition ? (
            days.map((day, index) => {
              const formattedDay = day.split(' - ')[1] // Extract the date part
              const pastDate = isPastDate(formattedDay)
              return (
                <div
                  key={index}
                  className={`border p-2 rounded flex flex-col ${pastDate ? 'bg-gray-100' : ''}`}
                >
                  <div className="flex justify-center items-center mb-2">
                    <p className="font-bold">{day}</p>
                  </div>
                  {shifts.map(
                    (shift, shiftIndex) => (
                      console.log('shift.idCalamViecCC', shift.idCalamViecCC),
                      (
                        <div
                          key={shiftIndex}
                          className="flex-grow min-h-40 border-dashed border-2 rounded mb-2 flex flex-col items-center justify-center"
                        >
                          <div className="max-h-20 overflow-y-auto min-w-full">
                            {selectedEmployees[`Shift ${shift.idCalamViecCC}`] &&
                              selectedEmployees[`Shift ${shift.idCalamViecCC}`][formattedDay] && (
                                <div className="mt-2">
                                  {selectedEmployees[`Shift ${shift.idCalamViecCC}`][
                                    formattedDay
                                  ].map((employee) => (
                                    <div
                                      key={employee.id}
                                      className={`mb-2 p-1 rounded-xl flex justify-center items-center ${employee.position === 'LeTan' ? 'bg-[#00AFEF] text-white' : 'bg-[#00AFEF] text-white'}`}
                                    >
                                      <span>{employee.name}</span>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                          {!pastDate && (
                            <button
                              className="border border-dotted rounded w-24 mb-2 mt-2"
                              onClick={() => handleButtonClick({ ...shift, day: formattedDay })}
                            >
                              <PlusOutlined />
                            </button>
                          )}
                        </div>
                      )
                    ),
                  )}
                </div>
              )
            })
          ) : (
            <NotFound />
          )}

          <Modal isVisible={isModalVisible} style={{ width: '80% !important' }}>
            <div className="flex flex-col w-[40rem]">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold mb-4">
                  Ca {selectedShift?.tenCa} - {selectedShift?.day}
                </h2>
                <div className="flex items-center">
                  <span className="mr-2">Chú ý: </span>
                  <Tag color="geekblue"> Đã đăng kí ở chi nhánh</Tag>
                </div>
              </div>
              <div className="flex mb-4">
                <Input
                  placeholder="Tìm kiếm nhân viên theo tên hoặc mã nhân viên"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onPressEnter={handleSearch}
                  style={{ marginRight: 8 }}
                />
                <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
                  Tìm kiếm
                </Button>
              </div>
              <div
                className="overflow-auto"
                style={{ maxHeight: '400px' }}
                onScroll={(e) => {
                  const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
                  if (scrollTop + clientHeight >= scrollHeight) {
                    handlePageChange(currentPage + 1)
                  }
                }}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={
                    selectedEmployees[`Shift ${selectedShift?.idCalamViec}`]?.[
                      selectedShift?.day
                    ] || []
                  }
                  renderItem={(employee) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar style={{ backgroundColor: '#87d068' }}>
                            {employee.name.charAt(0)}
                          </Avatar>
                        }
                        title={
                          <label
                            htmlFor={`employee-${employee.id}`}
                            style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ marginRight: '8px' }}>{employee.name}</span>
                            <span style={{ color: '#999' }}>{employee.maNV}</span>
                          </label>
                        }
                        description={
                          employee.idtt === 4 ? (
                            <>
                              <Tag color="blue" icon={<CheckCircleOutlined />}>
                                Đã chốt
                              </Tag>
                              <Tag color="geekblue">{employee.tenCN}</Tag>
                            </>
                          ) : (
                            <>{employee.tenCN && <Tag color="geekblue">{employee.tenCN}</Tag>}</>
                          )
                        }
                      />
                      <input
                        type="checkbox"
                        id={`employee-${employee.id}`}
                        className="mr-2"
                        checked={checkboxStates[employee.id] ?? employee.idCN === branchId}
                        onChange={(e) => {
                          setCurrentEmployee(employee)
                          setCurrentCheckboxState(e.target.checked)
                          handleCheckboxChange(employee.id, e.target.checked)
                        }}
                        disabled={employee.idtt === 4}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  type="primary"
                  className="mr-2"
                  onClick={() =>
                    handleSave(
                      selectedEmployees[`Shift ${selectedShift?.idCalamViec}`]?.[
                        selectedShift?.day
                      ] || [],
                    )
                  }
                >
                  Lưu
                </Button>
                <Button type="default" onClick={handleSkip}>
                  Quay lại
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Spin>
  )
}

export default CalendarGrid
