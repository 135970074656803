import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { https } from '../../../../services/apiService'
import { addKhachHangCRM } from '../../../../store/actions/khachhangCRMAction'
import ToastCus from '../../../../utils/Toast'
const dateFormat = 'DD-MM-YYYY'

const ModalAdd = ({ open, setOpen, onLoad }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [allCongTy, setAllCongTy] = useState([])

  // // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    if (!value.idct) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn công ty',
      })
      return
    }
    const formatData = {
      ...value,
      // idttlh: allLienHe.find((item) => item.idct === value.idct).idttlh,
    }
    dispatch(addKhachHangCRM(formatData, onLoad))
    formik.resetForm()
    setOpen(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      doanhthu: 0,
      giamgia: 0,
      chietkhau: 0,
      idct: '',
      nguoitao: infoUser.idnv,
      tennguoitao: infoUser.tenNV,
      ghichu: '',
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  // useEffect(() => {
  //   const getAllLienHe = async () => {
  //     const { data } = await https.get('CRMThongTinLienHe/GetAllCRMThongTinLienHe')
  //     setAllLienHe(data)
  //   }
  //   getAllLienHe()
  // }, [])

  // useEffect(() => {
  //   const getAllCongty = async () => {
  //     const { data } = await https.get('CRMCongTy/GetAllCRMCongTy')
  //     setAllCongTy(data)
  //   }
  //   getAllCongty()
  // }, [])



  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo Khách hàng</h2>}
        onOk={formik.handleSubmit}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Công ty</label>
            <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              defaultValue={formik.values.idct}
              options={allCongTy?.map((item) => ({
                label: item.tenct,
                value: item.idct,
              }))}
              className="w-full"
              onChange={ChangeSelected('idct')}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Doanh thu</label>
            <Input
              type="number"
              min={0}
              step={1}
              value={formik.values.doanhthu}
              name="doanhthu"
              onChangeCapture={formik.handleChange}
              status={formik.errors.doanhthu && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Giảm giá</label>
            <Input
              type="number"
              min={0}
              step={1}
              value={formik.values.giamgia}
              name="giamgia"
              onChangeCapture={formik.handleChange}
              status={formik.errors.giamgia && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Chiết khấu</label>
            <Input
              type="number"
              min={0}
              step={1}
              value={formik.values.chietkhau}
              name="chietkhau"
              onChangeCapture={formik.handleChange}
              status={formik.errors.chietkhau && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              value={formik.values.ghichu}
              name="ghichu"
              onChangeCapture={formik.handleChange}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd
