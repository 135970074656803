import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
// import { drugstoreService } from '../../services/drugstore/drugstoreService'
// import { recieveService } from '../../services/receive/recieveService'
// import { BNChoKham, thuocKeToa } from '../actions/childrenClinicAction'
// import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
import moment from 'moment'
import { generatePhieuKhamKhucXa } from '../../utils/report/mat/phieuKhamKhucXa'
// import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
import 'moment/locale/vi'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* doKhucXaSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM MẮT TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_MAT, function* getBNChoKhamMatSaga({ type, idcnpk }) {
    try {
      const result = yield call(() => doKhucXaService.getBNChoKhamMat(idcnpk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ ĐO KHÚC XẠ
  yield takeLatest(typeAction.GET_BN_CHO_DKX, function* getBNChoDoKhucXaSaga({ type, idcn }) {
    try {
      const result = yield call(() => doKhucXaService.getBNChoDoKhucXa(idcn))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_DKX,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ ĐO KHÚC XẠ
  yield takeLatest(
    typeAction.GET_BN_DA_DKX,
    function* getBNDaDoKhucXaSaga({ type, idcn, tuNgay, denNgay }) {
      try {
        const listDaKham = yield call(() => doKhucXaService.getBNDaDoKhucXa(idcn, tuNgay, denNgay))
        yield put({
          type: typeAction.DISPATCH_BN_DA_DKX,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // LẤY THÔNG TIN CHI TIẾT BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(typeAction.GET_INFO_BN_DKX, function* getInfoBNDoKhucXaSaga({ type, idbnttc }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => doKhucXaService.getInfoBNDoKhucXa(idbnttc))
      const resultTKGK = yield call(() => doKhucXaService.getInfoBNTrongVaGongKinh(idbnttc))
      const resultBNDV = yield call(() => doKhucXaService.getListBNDichVu(idbnttc))
      const newDataTK = resultTKGK?.data?.DanhSachTrongKinhBenhNhan.map((item, index) => ({
        ...item,
        // doitacthuchien: null,
        iddttkcp: item?.tengong,
        // tenct: null,
        // tengong: null,
      }))
      const newDataGK = resultTKGK?.data?.DanhSachGongKinhBenhNhan.map((item, index) => ({
        ...item,
        ID: index,
        //ton: item?.tonkho,
        ton: null,
        tenBietDuoc: item?.tenbietduoc,
        duongDung: item?.duongdung,
        hamLuong: item?.hamluong,
        soLuong: item?.soluong,
        // bsKeDon: infoUser?.tenNV,
        //   ngayKeDon: "2024-05-23T16:39:02.286Z",
        ghiChu: item?.ghichu,
        lieuDung: item?.lieudung,
        soLanNgay: item?.solanngay,
        soLo: item?.solo,
        hanDung: item?.handung,
        soHoaDon: item?.sohoadon,
        maSoDangKy: item?.masodangky,
        giaBan: item?.dongia,
        thanhToan: item?.thanhtien,
      }))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_DKX,
        payload: result.data,
      })
      yield put({
        type: typeAction.DISPATCH_LIST_TRONG_KINH_BN_DKX,
        payload: newDataTK ?? [],
      })
      yield put({
        type: typeAction.DISPATCH_LIST_GONG_KINH_BN_DKX,
        payload: newDataGK ?? [],
      })
      yield put({
        type: typeAction.DISPATCH_INFO_TRONG_VA_GONG_KINH_BN_DKX,
        payload: resultTKGK.data,
      })
      yield put({
        type: typeAction.DISPATCH_LIST_BN_DV_DKX,
        payload: resultBNDV.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // LƯU THÔNG TIN ĐO KHÚC XẠ CỦA BỆNH NHÂN
  yield takeLatest(
    typeAction.POST_INFO_BN_DKX,
    function* postInfoBNDoKhucXaSaga({ type, form, listdv, idcn, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        const result = yield call(() => doKhucXaService.postInfoBNDoKhucXa(form))
        form?.nguoikham && (yield call(() => doKhucXaService.putListDVBN(listdv)))
        const listCho = yield call(() => doKhucXaService.getBNChoDoKhucXa(idcn))
        const listDa = yield call(() => doKhucXaService.getBNDaDoKhucXa(idcn, tungay, denngay))
        const resultBN = yield call(() => doKhucXaService.getInfoBN(form.idbnttc))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_DKX,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_DKX,
          payload: listDa.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DKX,
          payload: null,
        })
        yield call(() => generatePhieuKhamKhucXa(resultBN.data, result.data, PkDangNhap))
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // CẬP NHẬT THÔNG TIN ĐO KHÚC XẠ CỦA BỆNH NHÂN
  yield takeLatest(
    typeAction.PUT_INFO_BN_DKX,
    function* putInfoBNDoKhucXaSaga({ type, form, listdv, idcn, tungay, denngay, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        const { action, ...other } = form
        const result = yield call(() => doKhucXaService.putInfoBNDoKhucXa(other))
        if (form?.nguoikham) {
          yield call(() => doKhucXaService.putListDVBN(listdv))
        }
        const listCho = yield call(() => doKhucXaService.getBNChoDoKhucXa(idcn))
        const listDa = yield call(() => doKhucXaService.getBNDaDoKhucXa(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_DKX,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_DKX,
          payload: listDa.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DKX,
          payload: null,
        })
        if (form?.nguoikham) {
          const resultBN = yield call(() => doKhucXaService.getInfoBN(form.idbnttc))
          yield call(() => generatePhieuKhamKhucXa(resultBN.data, result.data, PkDangNhap))
        }
        yield call(() => resetForm())
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY DANH SÁCH TRÒNG KÍNH VÀ TỒN GỌNG KÍNH
  yield takeLatest(
    typeAction.GET_LIST_TRONG_VA_GONG_KINH_DKX,
    function* getListTrongVaGongKinhSaga({ type, idnhomdv, idcn }) {
      try {
        const ID_COMPANY = Number(localStorage.getItem('id_company'))
        //const resultTK = yield call(() => doKhucXaService.getListDVTrongKinh(idnhomdv))
        const resultTK = yield call(() =>
          doKhucXaService.getListDVTrongKinh(idnhomdv, idcn, ID_COMPANY),
        )
        const resultGK = yield call(() => doKhucXaService.getListTonGongKinh(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_TRONG_KINH_DKX,
          payload: resultTK.data,
        })
        const newData = resultGK?.data
          .filter((item) => item.tonKho > 0)
          .map((item, index) => ({ ...item, ID: index }))
        yield put({
          type: typeAction.DISPATCH_LIST_GONG_KINH_DKX,
          payload: newData,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // //---------------- LƯU THÔNG TIN TRÒNG VÀ GỌNG KÍNH BỆNH NHÂN -------------//
  yield takeLatest(
    typeAction.POST_LIST_TRONG_VA_GONG_KINH_DKX,
    function* postInfoBNTrongVaGongKinhSaga({ type, idbnttc, listTK, listGK, isKhamMat }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        if (listTK?.length > 0) yield call(() => doKhucXaService.postInfoBNTrongKinh(listTK))
        if (listGK?.length > 0)
          yield call(() => childrenClinicService.putBNThuocKTVKeGong(idbnttc, listGK)) // có gọng mới cập nhật thêm
        if (!isKhamMat && listGK?.length > 0)
          yield call(() => doKhucXaService.putDoKhucXaKhongKhamMat(2, idbnttc)) // nếu mà không khám mắt thì cập nhật trangthaikb = 2 của bnttc để ra dược lấy toa gọng
        else if (!isKhamMat && listGK?.length <= 0)
          yield call(() => doKhucXaService.putDoKhucXaKhongKhamMat(4, idbnttc)) // không khám mắt + không lấy gọng thì hoàn thành để vào phòng tròng kính
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_TRONG_KINH_BN_DKX,
          payload: [],
        })
        yield put({
          type: typeAction.DISPATCH_LIST_GONG_KINH_BN_DKX,
          payload: [],
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
