import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo, generateThuVietNamese } from '../../algorithm'
import { jsPDFCus } from '../../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../../splitWordForReport'
import { calculateAgeForReport } from '../../calculateAge'

export const generateKhamBenhXuatVacXin = (infoBn, benhNhanTTC, toaThuoc, PkDangNhap) => {
  // tach toa thuoc
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)

  JsBarcode('#barcode', `TT${benhNhanTTC.idbnttc}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `TT${benhNhanTTC.idbnttc}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(10)
  doc.text(text, textX, 22)

  doc.text(`Mã BN: ${benhNhanTTC.benhNhan.maBenhNhan ?? ''}`, pageWidth - 45, 26)
  doc.setFont('times', 'bold')
  doc.setFontSize(15)
  doc.text('PHIẾU CHỈ ĐỊNH', doc.internal.pageSize.width / 2, 30, { align: 'center' })
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.setFontSize(11)
  doc.text(benhNhanTTC.benhNhan.tenBenhNhan.toUpperCase(), textWidthName, 40)
  doc.setFont('times', 'regular')
  doc.setFontSize(10)
  doc.text('Họ và tên: ', xPos, 40)
  let textWidthGioiTinh = doc.getTextDimensions('Giới tính: ').w
  doc.text('Giới tính:', pageWidth - 50, 40)
  doc.setFont('times', 'bold')
  doc.text(benhNhanTTC.benhNhan.gioiTinh, pageWidth - 50 + textWidthGioiTinh, 40)
  doc.setFont('times', 'regular')
  doc.text(`Ngày sinh: ${moment(benhNhanTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`, xPos, 45)
  doc.text(`Tuổi: ${calculateAgeForReport(benhNhanTTC.benhNhan.ngaySinh)}`, pageWidth - 80, 45)

  //dịa chỉ
  const diaChiBenhNhan = `Địa chỉ: ${infoBn.diaChi ?? ''}${infoBn.tenPhuongXa ? ', ' + infoBn.tenPhuongXa : ''}${infoBn.tenQuanHuyen ? ', ' + infoBn.tenQuanHuyen : ''}${infoBn.tenTinhTP ? ', ' + infoBn.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 80) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 80)
    doc.text(wordsArray[0], xPos, 50)
    doc.text(wordsArray[1], xPos, 54)
    yPos = 54
  } else {
    doc.text(diaChiBenhNhan, xPos, 50)
    yPos = 50
  }
  yPos += 5
  doc.text('Cân nặng: ', xPos, yPos)
  if (PkDangNhap.idChuyenKhoa === 1) {
    doc.text('Chiều cao: ', pageWidth / 2 - 20, yPos)
    doc.text('Nhiệt độ: ', pageWidth - 50, yPos)
  } else {
    doc.text('Mạch ', pageWidth / 2 - 20, yPos)
    doc.text('Huyết áp: ', pageWidth - 50, yPos)
  }

  doc.setFont('times', 'bold')
  let canNangWidth = doc.getTextDimensions('Cân nặng: ').w
  let chieuCaoWidth = doc.getTextDimensions('Chiều cao: ').w
  let nhietDoWidth = doc.getTextDimensions('Nhiệt độ: ').w
  let machWidth = doc.getTextDimensions('Mạch: ').w
  let huyetApWidth = doc.getTextDimensions('Huyết áp: ').w

  doc.text(`${benhNhanTTC.cannang ? `${benhNhanTTC.cannang} kg` : ''}`, canNangWidth + xPos, yPos)
  if (PkDangNhap.idChuyenKhoa === 1) {
    doc.text(
      `${benhNhanTTC.chieucao ? `${benhNhanTTC.chieucao} cm` : ''}`,
      chieuCaoWidth + pageWidth / 2 - 20,
      yPos,
    )
    doc.text(
      `${benhNhanTTC.nhietdo ? `${benhNhanTTC.nhietdo} °C` : ''}`,
      nhietDoWidth + pageWidth - 50,
      yPos,
    )
  } else {
    doc.text(
      `${benhNhanTTC.mach ? `${benhNhanTTC.mach} lần/phút` : ''}`,
      machWidth + pageWidth / 2 - 20,
      yPos,
    )
    doc.text(
      `${benhNhanTTC.huyetap ? `${benhNhanTTC.huyetap} mmHg` : ''}`,
      huyetApWidth + pageWidth - 50,
      yPos,
    )
  }
  doc.setFont('times', 'regular')
  //chẩn đoán
  yPos += 5
  const chandoan = `Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`
  if (doc.getTextWidth(chandoan) > 100) {
    const wordsArray = splitLongWordFitWidth(doc, chandoan, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(chandoan, xPos, yPos + 5)
    yPos += 5
  }
  // Add a line
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  yPos += 5
  for (let i = 0; i < toaThuoc.length; i++) {
    // Check if content exceeds the page height
    if (yPos > 200) {
      doc.addPage() // Add a new page
      yPos = 10 // Reset yPos for new page
    }

    //chia 2 cột
    doc.setFont('times', 'bold')
    //check text dài
    doc.text(`${toaThuoc[i]?.soLuong} ${toaThuoc[i]?.dvt}`, pageWidth - 25, yPos)
    if (`${i + 1}. ${toaThuoc[i]?.tenhoatchat || toaThuoc[i]?.tenHoatChat}`.length > 55) {
      const wordsArray = splitLongWord(
        `${i + 1}. ${toaThuoc[i]?.tenhoatchat || toaThuoc[i]?.tenHoatChat}`,
        55,
      )
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 4
      }
    } else {
      doc.text(`${i + 1}. ${toaThuoc[i]?.tenhoatchat || toaThuoc[i]?.tenHoatChat}`, xPos, yPos)
      yPos += 4
    }
    doc.setFont('times', 'italic')
    //check text cách dùng dài
    if (
      `${toaThuoc[i]?.duongDung ?? ''}, lần ${toaThuoc[i]?.lieuDung ?? ''} ${toaThuoc[i]?.donvidung ?? ''} ${toaThuoc[i]?.ghiChu ? '(' + toaThuoc[i]?.ghiChu + ')' : ''}`
        .length > 55
    ) {
      const wordsArray = splitLongWord(
        `${toaThuoc[i]?.duongDung ?? ''}, lần ${toaThuoc[i]?.lieuDung ?? ''} ${toaThuoc[i]?.donvidung ?? ''} ${toaThuoc[i]?.ghiChu ? '(' + toaThuoc[i]?.ghiChu + ')' : ''}`,
        55,
      )
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 4
      }
    } else {
      doc.text(
        `${toaThuoc[i]?.duongDung ?? ''}, lần ${toaThuoc[i]?.lieuDung ?? ''} ${toaThuoc[i]?.donvidung ?? ''} ${toaThuoc[i]?.ghiChu ? '(' + toaThuoc[i]?.ghiChu + ')' : ''}`,
        xPos,
        yPos,
      )
      yPos += 4
    }
    yPos += 1
  }
  yPos = yPos - 5
  if (PkDangNhap.idChuyenKhoa === 1) {
    if (doc.internal.pageSize.height - yPos < 90) {
      doc.addPage()
      yPos = 10
    }
  } else {
    if (doc.internal.pageSize.height - yPos < 80) {
      doc.addPage()
      yPos = 10
    }
  }

  yPos += 5
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  yPos += 5
  doc.setFont('times', 'bold')
  const timeWidth = doc.getTextDimensions('Chủ nhật - 03/03/2024').w
  doc.text(
    `${generateThuVietNamese(benhNhanTTC.ngaytaikham)} - ${moment(benhNhanTTC.ngaytaikham).format('DD/MM/YYYY')}`,
    timeWidth,
    yPos,
  )
  doc.setFont('times', 'regular')

  doc.text('Ngày tái khám: ', xPos, yPos)
  const dateText = `Ngày ${moment(benhNhanTTC.ngaykham).format('DD')} tháng ${moment(benhNhanTTC.ngaykham).format('MM')} năm ${moment(benhNhanTTC.ngaykham).format('YYYY')}`
  const doctorText = benhNhanTTC.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.text('Lời dặn:', xPos, yPos)
  doc.text('Bác sĩ khám bệnh', pageWidth - 46, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  //lời dặn
  yPos += 5
  doc.setFont('times', 'regular')
  if (`- ${benhNhanTTC.loidan ?? ''}`.length > 45) {
    const wordsArray = splitLongWord(`- ${benhNhanTTC.loidan ?? ''}`, 45)
    for (let j = 0; j < wordsArray.length; j++) {
      doc.text(`${wordsArray[j]}`, xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(`- ${benhNhanTTC.loidan ?? ''}`, xPos, yPos)
    yPos += 5
  }

  //tái khám miễn phí
  // if (PkDangNhap.idChuyenKhoa === 1) {
  //   doc.setFont('times', 'bold')
  //   const defaultLoiDanTaiKham =
  //     '- Tái khám miễn phí khi đúng hẹn tối đa 3 lần trong cùng 1 đợt bệnh'
  //   const wordsArrayTaiKham = splitLongWord(defaultLoiDanTaiKham, 45)
  //   for (let j = 0; j < wordsArrayTaiKham.length; j++) {
  //     doc.text(`${wordsArrayTaiKham[j]}`, xPos, yPos)
  //     yPos += 5
  //   }
  // } else {
  //   doc.setFont('times', 'bold')
  //   const defaultLoiDanTaiKham = ' Tái khám 1 lần miễn phí khi đúng hẹn.'
  //   const wordsArrayTaiKham = splitLongWord(defaultLoiDanTaiKham, 45)
  //   for (let j = 0; j < wordsArrayTaiKham.length; j++) {
  //     doc.text(`${wordsArrayTaiKham[j]}`, xPos, yPos)
  //     yPos += 5
  //   }
  // }

  doc.setFont('times', 'italic')
  //lời dặn default

  const defaultLoiDan =
    '- Toa thuốc chỉ có giá trị tại thời điểm bác sĩ chẩn đoán bệnh. Chúng tôi miễn trừ trách nhiệm trong trường hợp toa thuốc khách hàng sử dụng không ngay tại thời điểm chẩn đoán bệnh'
  const wordsArray = splitLongWord(defaultLoiDan, 45)
  for (let j = 0; j < wordsArray.length; j++) {
    doc.text(`${wordsArray[j]}`, xPos, yPos)
    yPos += 5
  }
  doc.line(xPos, yPos - 4, pageWidth - 10, yPos - 4)
  if (PkDangNhap.idChuyenKhoa === 1) {
    doc.setFontSize(8)
    doc.setFont('times', 'bold')
    doc.text('Tái khám ngay khi có các dấu hiệu sau :', xPos, yPos)
    doc.setFont('times', 'regular')
    yPos += 4
    doc.text('* Ngủ li bì hay vật vã', xPos, yPos)
    doc.text('* Co giật', xPos + 30, yPos)
    doc.text('* Nôn ói nhiều', xPos + 45, yPos)
    doc.text('* Vết mỗ sưng nề hoặc chảy máu', xPos + 65, yPos)
    doc.text('* Tiêu máu', xPos + 110, yPos)
    yPos += 4
    doc.text('* Sốt cao khó hạ', xPos, yPos)
    doc.text('* Tím tái', xPos + 30, yPos)
    doc.text('* Bỏ ăn uống', xPos + 45, yPos)
    doc.text('* Thở bất thường', xPos + 65, yPos)
    doc.text('* Bệnh nặng hơn', xPos + 110, yPos)
  }

  if (PkDangNhap.idChuyenKhoa === 2) {
    doc.setFontSize(8)
    doc.setFont('times', 'italic')
    doc.text('(*) Mang theo toa thuốc này khi tái khám.', xPos, yPos)
  }
  // doc.save('phieuToaThuoc.pdf')
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD')}/${moment().format('MM')}/${moment().format('YYYY')}`,
    pageWidth - 45,
    doc.internal.pageSize.height - 5,
  )

  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
