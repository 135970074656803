import { https } from '../apiService'

export const suCoService = {
  getAllNhanSuCo: (idnhom, keyword) =>
    https.get(`KTNhanSuCo/GetAllKTNhanSuCo?idnhom=${idnhom}&keyword=${keyword}`),
  postNhanSuCo: (data) => https.post('KTNhanSuCo/AddKTNhanSuCo', data),
  getAllGroupsNhan: () => https.get('KTNhanSuCo/GetAllNhomNhanSuCo'),
  postDanhMucNhan: (form) => https.post('KTNhanSuCo/AddKTNhanSuCoDanhMuc', form),
  getDanhMucByNhan: (id, keyword) =>
    https.get(`KTNhanSuCo/GetDanhMucNhanSuCo?idnhan=${id}&keyword=${keyword}`),
  getDetailNhan: (id) => https.get(`KTNhanSuCo/GetInfoKTNhanSuCo?idnhan=${id}`),
  putNhanSuCo: (form) => https.put('KTNhanSuCo/UpdateKTNhanSuCo', form),
  deleteNhanSuCo: (id) => https.delete(`KTNhanSuCo/DeleteKTNhanSuCo?idnhan=${id}`),
  getNhanVienByVung: (idcn) => https.get(`KTBaoHong/GetNhanVienChiNhanhBaoHong?idcn=${idcn}`),
  postKTBaoHong: (form) => https.post('KTBaoHong/AddKTBaoHong', form),
  getDanhSachBaoHong: (keyword, idcn) =>
    https.get(`KTBaoHong/GetDanhSachKTBaoHong`, {
      params: {
        keyword,
        idcn,
      },
    }),
  postVungBaoHong: (form) => https.post('KTBaoHong/AddKTVungBaoHong', form),
  getVungBaoHong: (keywword) => https.get(`KTBaoHong/GetVungBaoHong?keyword=${keywword}`),
  postNhanVienBaoHong: (form) => https.post('KTBaoHong/AddKTNhanVienBaoHong', form),
  deleteNhanVienBaoHong: (form) =>
    https.delete('KTBaoHong/DeleteKTNhanVienBaoHong', {
      data: form,
    }),
  getDetailBaoHong: (idbh) => https.get(`KTBaoHong/GetInfoKTBaoHong?idbaohong=${idbh}`),
  getAllHangMuc: () => https.get('KTNhanSuCo/GetAllDanhMucNhanSuCo'),
  getinfoNhanByHangMuc: (id) => https.get(`KTNhanSuCo/GetNhanSuCoByDanhMuc?idnhandm=${id}`),
  deleteFileBaoHong: (id, url) =>
    https.delete(`KTBaoHong/DeleteFileKTBaoHong?idbaohong=${id}&fileKetQua=${url}`),
  getDanhDachKTThucHien: (idbh) => https.get(`KTBaoHong/GetNhanVienKTBaoHong?idbaohong=${idbh}`),
  putKTBaoHong: (from) => https.put('KTBaoHong/UpdateKTBaoHong', from),
  getImageBaoHong: (idbh) => https.get(`KTBaoHong/GetHinhAnhKTBaoHong?idbaohong=${idbh}`),
  getAllBranh: () => https.get('KTNhanSuCo/GetChiNhanhBaoHong'),
  postChiNhanhVung: (form) => https.post('KTBaoHong/AddKTChiNhanhVungBaoHong', form),
  getChiNhanhByVung: (id, keyword) =>
    https.get(`KTBaoHong/GetChiNhanhVungBaoHong?idvung=${id}&keyword=${keyword}`),
  postXuLyBaoHong: (form) => https.post('KTXuLyBaoHong/AddKTXuLyBaoHong', form),
  getInfoXuLyBaoHong: (id) => https.get(`KTXuLyBaoHong/GetKTXuLyBaoHong?idbaohong=${id}`),
  updateLoadFileXuLy: (form) => https.put(`KTXuLyBaoHong/UpdateFileXuLyBaoHong`, form),
  deleteFileXuLy: (idxl, url) =>
    https.delete(`KTXuLyBaoHong/DeleteFileKTXuLyBaoHong?idxl=${idxl}&fileKetQua=${url}`),
  uploadFileBaoHong: (form) => https.put('KTBaoHong/UpdateFileBaoHong', form),
  updateNoiDungXuLy: (idxuly, noidung) =>
    https.put(`KTXuLyBaoHong/UpdateNoiDungKTXuLyBaoHong?idxuly=${idxuly}&noidung=${noidung}`),
  getDanhSachHoangThanh: (keyword,  idcn, tuNgay, denNgay) =>
    https.get(`KTBaoHong/GetDanhSachKTBaoHongHoanThanh`, {
      params: {
        keyword,
        idcn,
        tuNgay,
        denNgay,
      },
    }),
  postNhanVienVung: (form) => https.post('KTBaoHong/AddKTNhanVienVungBaoHong', form),
  deleteBaoHong: (id) => https.delete(`KTBaoHong/DeleteKTBaoHong?idbaohong=${id}`),
  updateStatusBaoHong: (id, status) =>
    https.put(`KTBaoHong/UpdateTrangThaiBaoHong?idbaohong=${id}&trangthai=${status}`),
  deleteNhanVienVung: (id) => https.delete(`KTBaoHong/DeleteKTNhanVienVungBaoHong?idnvvbh=${id}`),
  deleteChiNhanhVung: (id) => https.delete(`KTBaoHong/DeleteKTChiNhanhVungBaoHong?idcnvbh=${id}`),
  updateKhuVuc: (form) => https.put(`KTBaoHong/UpdateKTVungBaoHong`, form),
  deleteVungBaoHong: (id) => https.delete(`KTBaoHong/DeleteKTVungBaoHong?idvung=${id}`),
  deleteDanhMucNhan: (id) => https.delete(`KTNhanSuCo/DeleteKTNhanSuCoDanhMuc?idnhandm=${id}`),
  updateDanhMuc: (from) => https.put('KTNhanSuCo/UpdateKTNhanSuCoDanhMuc', from),
  ChuyenNhanVien: (from) => https.put('KTBaoHong/ChuyenNhanVienBaoHong', from),
  getHistoryStaff: (idbh) => https.get(`KTBaoHong/GetLichSuKTBaoHong?idbaohong=${idbh}`),
  getDanhSachThongKe: (keyword, idcn, tuNgay, denNgay) =>
    https.get(`KTBaoHong/GetDanhSachThongKeKTBaoHong`, {
      params: {
        keyword,
        idcn,
        tuNgay,
        denNgay,
      },
    }),
    getSearchNV: (keyword, idnhom) => https.get(`KTNhanSuCo/GetNhanVienITKT`, {
      params: {
        keyword,
        idnhom,
      },
    }),
}
