import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Input, Popover, Select } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { https } from '../../../services/apiService'

const optionKQ = [
  { label: 'Tất cả', value: '' },
  {
    label: 'Chưa có',
    value: '0',
  },
  {
    label: 'Hoàn thành',
    value: '1',
  },
]

const optionSms = [
  { label: 'Tất cả', value: '' },
  {
    label: 'Chưa gửi',
    value: '0',
  },
  {
    label: 'Gửi thành công',
    value: '1',
  },
  {
    label: 'Gửi thất bại',
    value: '2',
  },
]

function OptionFIlter({page}) {
  const [listKh, setListKh] = useState(null)
  const [filterKH, setFilterKH] = useState('')
  const [filterKQXetNghiem, setFilterKQXetNghiem] = useState('')
  const [filterKQKham, setFilterKQKham] = useState('')
  const [filterKQCdha, setFilterKQCdha] = useState('')
  const [filterSms, setFilterSms] = useState('')
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    ;(async () => {
      const { data } = await https.get('CRMKhachHang/GetAll')
      setListKh(data)
    })()
  }, [])
  const handleSearch = () => {
    setSearchTriggered(true)
    console.log(keyword, filterKH, filterKQXetNghiem, filterKQKham, filterKQCdha, filterSms);
    setVisible(false)
    //call api

  }
  const handleClearFilter = () => {
    setKeyword('')
    setFilterKH('')
    setFilterKQXetNghiem('')
    setFilterKQKham('')
    setFilterKQCdha('')
    setFilterSms('')
    setSearchTriggered(false) // Reset the search flag
  }

  const contentFilter = (
    <div className="w-[500px] flex flex-col gap-2">
      <div className="w-full flex items-center">
        <label className="w-36 font-semibold">Tìm kiếm:</label>
        <Input
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          className="w-full"
          placeholder="Tìm kiếm tên bệnh nhân/mã bn/gói khám"
        />
      </div>

      <div className="w-full flex items-center">
        <label className="w-36 font-semibold">Công ty:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKH(value)}
          allowClear
          options={[
            { label: 'Tất cả', value: '' },
            ...(listKh
              ? listKh.map((items) => ({
                  label: items.tenkh,
                  value: items.idkh,
                }))
              : []),
          ]}
          className="w-full"
          value={filterKH}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-36 font-semibold">KQ xét nghiệm:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKQXetNghiem(value)}
          allowClear
          options={optionKQ.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-full"
          value={filterKQXetNghiem}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-36 font-semibold">KQ khám:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKQKham(value)}
          allowClear
          options={optionKQ.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-full"
          value={filterKQKham}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-36 font-semibold">KQ CĐHA:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKQCdha(value)}
          allowClear
          options={optionKQ.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-full"
          value={filterKQCdha}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-36 font-semibold">Trạng thái SMS:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterSms(value)}
          allowClear
          options={optionSms.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-full"
          value={filterSms}
        />
      </div>
      <div className="w-full flex items-center justify-end">
        <Button onClick={handleClearFilter}>Refresh</Button>
        <Button onClick={handleSearch} className="ml-2" type="primary">
          Tìm kiếm
        </Button>
      </div>
    </div>
  )

  const countFilter = useMemo(() => {
    let count = 0
    if (keyword) count++
    if (filterKH) count++
    if (filterKQXetNghiem) count++
    if (filterKQKham) count++
    if (filterKQCdha) count++
    if (filterSms) count++
    return count
  }, [keyword, filterKH, filterKQXetNghiem, filterKQKham, filterKQCdha, filterSms])

  console.log('render filter')

  useEffect(() => {
    if (searchTriggered) {
      handleSearch()
    }
  }, [searchTriggered])

  const handleVisbleChange = (value) => {
    setVisible(value)
  }
  useEffect(() => {
    if (!visible && !searchTriggered) {
      //reset
      setKeyword('')
      setFilterKH('')
      setFilterKQXetNghiem('')
      setFilterKQKham('')
      setFilterKQCdha('')
      setFilterSms('')
      setSearchTriggered(false)
    }
  }, [visible, searchTriggered])

  console.log(page);

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisbleChange}
      placement="bottomLeft"
      content={contentFilter}
      trigger="click"
    >
      <Badge count={searchTriggered ? countFilter : 0}>
        <Button type="primary">
          <FilterOutlined /> Tìm kiếm
        </Button>
      </Badge>
    </Popover>
  )
}

OptionFIlter.propTypes = {}

export default React.memo(OptionFIlter)
