import moment from 'moment/moment'
import { formatNumberVND } from '../../utils/formatNumberVND'
import { numberToWords } from '../../utils/numberToWords'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'

export const generatePhieuThuLeTan = (data) => {
    const doc = jsPDFCus('p', 'mm', 'a5')
    let yPos = 10
    let xPos = 8
    let ruleWidth = 55 // for text of price is longer
    const pageWidth = doc.internal.pageSize.width
    function generateDot(text, yPos) {
        let textDimensions = doc.getTextDimensions(text)
        const availableSpace = Math.abs(pageWidth - xPos * 2 - textDimensions.w) // Calculate available space for dots
        const dotWidth = doc.getTextDimensions('.').w // Get the width of a single dot
        const dotCount = Math.floor(availableSpace / dotWidth) // Calculate the number of dots that can fit
        const dotStrings = '.'.repeat(dotCount) // Create a string of dots
        return {
            content: dotStrings,
            positionX: xPos + textDimensions.w, // Position dots after the text
            yPos: yPos,
        }
    }

    doc.setFont('times', 'bold')
    doc.setFontSize(10)
    doc.text('Đơn vị: ....................', xPos, 10)
    doc.text('Địa chỉ: ....................', xPos, 18)
    doc.text('Mẫu số 01 - TT', pageWidth - 'Mẫu số 01 - TT'.length * 4, 10)

    doc.setFont('times', 'regular')
    doc.setFontSize(10)
    doc.text(
        pageWidth - '(Ban hành theo Thông tư số 133/2016/TT-'.length * 2,
        15,
        '(Ban hành theo Thông tư số 133/2016/TT-',
    )
    doc.text(
        pageWidth - 'BTC ngày 26/8/2016 của Bộ Tài chính'.length * 2 - 5,
        20,
        'BTC ngày 26/8/2016 của Bộ Tài chính)',
    )

    doc.setFont('times', 'bold')
    doc.setFontSize(12)
    doc.text('PHIẾU THU', doc.internal.pageSize.width / 2, 40, { align: 'center' })
    doc.setFont('times', 'italic')
    doc.setFontSize(10)
    doc.text(
        `Ngày ${moment(data.detailHistory.ngaySuDung).format('DD')} Tháng ${moment(data.detailHistory.ngaySuDung).format('MM')} Năm ${moment(data.detailHistory.ngaySuDung).format('YYYY')}`,
        doc.internal.pageSize.width / 2,
        45,
        { align: 'center' },
    )

    doc.setFont('times', 'regular')
    doc.text(xPos, 55, `Họ và tên người nộp tiền: ${data.BNPaymentCard.tenBenhNhan}`)
    const dotLineHoTenNguoiNop = generateDot(
        `Họ và tên người nộp tiền: ${data.BNPaymentCard.tenBenhNhan}`,
        55,
    )
    doc.text(
        dotLineHoTenNguoiNop.positionX,
        dotLineHoTenNguoiNop.yPos,
        dotLineHoTenNguoiNop.content,
    )

    doc.text(xPos, 65, `Địa chỉ: ${data.BNPaymentCard.diaChi}`)
    const dotDiaChi = generateDot(`Địa chỉ: ${data.BNPaymentCard.diaChi}`, 65)
    doc.text(dotDiaChi.positionX, dotDiaChi.yPos, dotDiaChi.content)

    doc.text(xPos, 75, `Lý do nộp: Nộp tiền vào thẻ thanh toán - ${data.infoPaymentCard.maThe}`)
    const dotLyDo = generateDot(
        `Lý do nộp: Nộp tiền vào thẻ thanh toán - ${data.infoPaymentCard.maThe}`,
        75,
    )
    doc.text(dotLyDo.positionX, dotLyDo.yPos, dotLyDo.content)

    const textOverLong = numberToWords(data.tien)
    if (textOverLong.length > ruleWidth) {
        // Split the long word into substrings
        const wordsArray = splitLongWord(textOverLong, ruleWidth)
        doc.text(
            xPos,
            85,
            `Số tiền: ${formatNumberVND(data.tien)} (Viết bằng chữ): ${wordsArray[0]}`,
        )
        const dotTienNop = generateDot(wordsArray[1], 95)
        doc.text(xPos, 95, `${wordsArray[1]} `)
        doc.text(dotTienNop.positionX, dotTienNop.yPos, dotTienNop.content)
    } else {
        //text in one line
        doc.text(
            xPos,
            85,
            `Số tiền: ${formatNumberVND(data.tien)} (Viết bằng chữ): ${numberToWords(data.tien)}`,
        )
        const dotTienNop = generateDot(
            `Số tiền: ${data.tien} (Viết bằng chữ): ${numberToWords(data.tien)}`,
            85,
        )
        doc.text(dotTienNop.positionX, dotTienNop.yPos, dotTienNop.content)
        doc.text(xPos, 95, generateDot('', 95).content)
    }

    doc.text(xPos, 105, `Kèm theo: ....................................... Chứng từ gốc: `)

    doc.text(
        pageWidth -
            `Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}`
                .length *
                2,
        120,
        `Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}`,
    )
    doc.setFont('times', 'bold')
    doc.text(xPos, 130, 'Giám đốc')
    doc.text(xPos + 24, 130, 'Kế toán trưởng')
    doc.text(xPos + 58, 130, 'Người nộp tiền')
    doc.text(xPos + 88, 130, 'Người lập phiếu')
    doc.text(xPos + 120, 130, 'Thủ quỹ')

    doc.setFont('times', 'regular')
    doc.text(xPos - 1, 135, '(Ký, họ tên,')
    doc.text(xPos, 139, 'đóng dấu)')
    doc.text(xPos + 29, 135, '(Ký, họ tên)')
    doc.text(xPos + 62, 135, '(Ký, họ tên)')
    doc.text(xPos + 92, 135, '(Ký, họ tên)')
    doc.text(xPos + 118, 135, '(Ký, họ tên)')
    // doc.save('Phieu-thu.pdf')
    // auto printer
    const blob = doc.output('blob')
    const url = URL.createObjectURL(blob)
    const printWindow = window.open(url)
    if (printWindow) {
        printWindow.addEventListener(
            'load',
            function () {
                printWindow.print()
            },
            true,
        )
    }
}
