import moment from "moment";
import { formatNumberVND } from "../../../../../utils/formatNumberVND";

export  const columns = [
  {
    title: 'Ngày',
    dataIndex: 'ngay',
    key: 'ngay',
    render: (text) => moment(text).format('DD/MM/YYYY'),
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenChiNhanh',
    key: 'tenChiNhanh',
  },
  {
    title: 'Nhóm dịch vụ',
    dataIndex: 'tenNhomDV',
    key: 'tenNhomDV',
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'dvt',
    key: 'dvt',
  },
  {
    title: 'Số lượng',
    dataIndex: 'soLuong',
    key: 'soLuong',
  },
]
