import React, { useState, useEffect } from 'react'
import { format, addDays, isBefore, parse, startOfMonth, endOfMonth } from 'date-fns'
import {
  Avatar,
  Button,
  List,
  message,
  Tag,
  Modal,
  Input,
  Space,
  Table,
  Pagination,
  Spin,
  Card,
  Divider,
} from 'antd' // Ensure Modal and Pagination are imported from 'antd'
import CopyModal from './CopyModal'
import {
  CheckSquareOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { https } from '../../../../services/apiService'
import logo from '../../../../assets/images/logo/MemLogo_315_Logo.png'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { Checkbox, IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { Duo } from '@mui/icons-material'
import CalendarControls from './CalendarControls'
import { vi } from 'date-fns/locale'
const positionDisplayNames = {
  BacSi: 'Bác Sĩ',
  DieuDuong: 'Điều Dưỡng',
  KyThuatVien: 'Kỹ Thuật Viên',
  DuocSi: 'Dược Sĩ',
  LeTan: 'Lễ Tân',
}

const CalendarGrid = ({
  currentMonth,
  days,
  branchIds,
  companyIds,
  searchKeyword,
  onLoad,
  selectedPosition,
  selectedChuyenKhoa,
}) => {
  const [employees, setEmployees] = useState([])
  const [isModalVisible, setModalVisible] = useState(false)
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
  const [selectedShift, setSelectedShift] = useState({})
  const [selectedEmployees, setSelectedEmployees] = useState({})
  const [copySourceDay, setCopySourceDay] = useState(null)
  const [isCopyModalVisible, setCopyModalVisible] = useState(false)
  const [targetDays, setTargetDays] = useState([])
  const [positions, setPositions] = useState([])

  const [shiftDetails, setShiftDetails] = useState({})
  const [selectedRecord, setSelectedRecord] = useState(null)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false)
  const [shiftToDelete, setShiftToDelete] = useState(null) // Ca làm việc cần xóa
  const [ngayCongChuan, setNgayCongChuan] = useState(null)
  const [confirmStaus, setConfirmStatus] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [employeeData, setEmployeeData] = useState(null)

  // này để truyền update lại idcn và idnv
  const [idcnCuaChiNhanh, setIDCN] = useState(null)

  const [loading, setLoading] = useState(false) // Add loading state

  const { infoUser } = useSelector((state) => state.userReducer)

  const [listStatusCLVForAdmin, setListStatusCLVForAdmin] = useState([]) // Thêm trạng thái để lưu trữ danh sách chi nhánh
  const [modalConfirmForAdminVisible, setModalConfirmForAdminVisible] = useState(false) // Thêm modal xác nhận cho admin
  const [selectedBranches, setSelectedBranches] = useState([])

  const [soNhanVienChuaDangKyCaC, setSoNhanVienChuaDangKyCaC] = useState(0)
  const isBacSi = /Bác Sĩ/i.test(infoUser?.tenNhom)
  useEffect(() => {
    setTargetDays(days) // Initialize with all days selected by default
  }, [])

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await https.get('/NhomNguoiDung/GetAllLoaiNhom')
        setPositions(response.data || []) // Ensure positions is an array
      } catch (error) {
        console.error('Error fetching positions:', error)
      }
    }

    fetchPositions()
  }, [])

  useEffect(() => {
    if (!employeeData) {
      fetchEmployees(
        branchIds,
        selectedPosition,
        format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
        format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
        currentPage,
        searchKeyword,
      )
    } else {
      const transformedData = transformEmployeeData(employeeData)
      const transformedShiftData = transformEmployeeDataByDay(employeeData)
      setSelectedEmployees(transformedShiftData)
      setEmployees(transformedData)
    }
    // }, [currentMonth, days, selectedPosition, branchIds, currentPage, searchKeyword])
  }, [])

  const fetchEmployees = async (
    idCN,
    loaiNhomND,
    startDate,
    endDate,
    pageNumber,
    searchKeyword = '',
  ) => {
    if (!loaiNhomND) {
      console.warn('loaiNhomND is null, skipping API call')
      return
    }

    pageNumber = pageNumber || 1 // Set default value for pageNumber
    setLoading(true) // Set loading to true before API call
    try {
      let idCNs = branchIds.map((id) => `idCNs=${id}`).join('&')
      let keyword = searchKeyword ? `&keyword=${searchKeyword}` : ''

      console.log('idCNs:', idCNs)
      if (pageNumber !== '') {
        pageNumber = `&pageNumber=${pageNumber}`
      }
      if (branchIds.includes('all')) {
        idCNs = `idCT=${companyIds[0]}`
      }
      const response = await https.get(
        `/CaLamViec/GetAllDanhSachDangKyByNhanVien?${idCNs}${keyword}&loaiNhomND=${loaiNhomND}&thangDK=${format(currentMonth, 'MM/yyyy')}${pageNumber}`,
      )

      const transformedData = transformEmployeeData(response.data)
      const transformedShiftData = transformEmployeeDataByDay(response.data)

      setNgayCongChuan(response.data.data[0]?.ngayCongChuan || 0) // Đảm bảo ngày công chuẩn là một số
      setSoNhanVienChuaDangKyCaC(response.data.data[0]?.soNhanVienChuaDKCaC || 0)
      setConfirmStatus(response.data.data[0]?.isConfirm || false)

      setSelectedEmployees(transformedShiftData)
      setEmployees(transformedData)
      setEmployeeData(response.data) // Store the data

      // Cập nhật thông tin phân trang
      setCurrentPage(response.data.currentPage)
      setPageSize(response.data.pageSize)
      setTotalCount(response.data.totalCount)
      setTotalPage(response.data.totalPage)
    } catch (error) {
      console.error('Error fetching employee data:', error)
    } finally {
      setLoading(false) // Set loading to false after API call
    }
  }

  const handleSearchNhanvien = (event) => {
    // setSearchKeyword(event.target.value)
    fetchEmployees(
      branchIds,
      selectedPosition,
      format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
      format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
      '',
      searchKeyword,
    )
  }

  useEffect(() => {
    handleSearchNhanvien()
  }, [])

  useEffect(() => {
    fetchEmployees(
      branchIds,
      selectedPosition,
      format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
      format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
      currentPage,
    )
  }, [])
  const transformEmployeeData = (apiResponse) => {
    const transformedData = []

    apiResponse.data?.forEach((item) => {
      item.nhanVien?.forEach((nhanVien) => {
        console.log('nhanVien:', nhanVien)
        const existingEmployee = transformedData.find((employee) => employee.idnv === nhanVien.idnv)
        //setIDCN
        setIDCN(nhanVien.idcn)

        if (!existingEmployee) {
          transformedData.push({
            idnv: nhanVien.idnv,
            tenNV: nhanVien.tenNV,
            tongCa: nhanVien.tongCa,
            idtt: nhanVien.idtt,
            idcn: nhanVien.idcn, // Assuming idcn is part of nhanVien object
            tongCaC: nhanVien.tongCaC,
          })
        }
      })
    })

    return transformedData
  }

  useEffect(() => {
    if (ngayCongChuan !== null) {
      localStorage.setItem('ngayCongChuan', ngayCongChuan)
    }
  }, [ngayCongChuan])

  const transformEmployeeDataByDay = (data) => {
    const transformed = {}

    data.data?.forEach((item) => {
      item.nhanVien?.forEach((employee) => {
        employee.caLamViecTheoThang?.forEach((dayData) => {
          const day = format(new Date(dayData.ngayDK), 'dd/MM')
          if (!transformed[day]) {
            transformed[day] = {}
          }
          if (!transformed[day][employee.tenNV]) {
            transformed[day][employee.tenNV] = []
          }
          dayData.caLamViecTheoNgay?.forEach((shiftData) => {
            transformed[day][employee.tenNV].push({
              ca: shiftData.idCalamViec, // ID ca làm việc
              tenCa: shiftData.tenCa, // Tên ca làm việc
              idCN: shiftData.idCN, // ID chi nhánh
              tenCN: shiftData.tenCN, // Tên chi nhánh
              idNV: employee.idnv, // ID nhân viên
              iddkclvct: shiftData.iddkclvct, // ID đăng ký ca làm việc chi tiết
              idtt: shiftData.idtt, // ID trạng thái
            })
          })
        })
      })
    })

    return transformed
  }

  const handleSelectAllCaLamViecForAdmin = (e) => {
    if (e.target.checked) {
      const selectableBranches = listStatusCLVForAdmin
        .filter((branch) => !branch.isConfirm)
        .map((branch) => branch.idChiNhanh)
      setSelectedBranches(selectableBranches)
    } else {
      setSelectedBranches([])
    }
  }

  const handleBranchSelectForConfirm = (idChiNhanh) => {
    setSelectedBranches((prevSelected) =>
      prevSelected.includes(idChiNhanh)
        ? prevSelected.filter((id) => id !== idChiNhanh)
        : [...prevSelected, idChiNhanh],
    )
  }
  const isPastDate = (day) => {
    const today = new Date()
    const date = parse(day, 'dd/MM', new Date())
    return isBefore(date, today)
  }

  const handleDeleteShiftClick = (shift) => {
    setShiftToDelete(shift) // Lưu ca làm việc cần xóa vào state
    setIsConfirmDeleteVisible(true) // Hiện modal xác nhận
  }

  const handleConfirmDelete = async () => {
    const updatedEmployeeData = [
      {
        iddkclvct: shiftToDelete.iddkclvct,
        idCN: null, // Set idCN to null to delete the shift
        tenNV: shiftToDelete.tenNV,
        idNV: shiftToDelete.idNV,
        idtt: shiftToDelete.idtt,
      },
    ]

    try {
      const response = await https.put(
        '/CaLamViec/UpdateDanhSachNhanVienByIdCLV',
        updatedEmployeeData,
      )

      if (response.status === 200) {
        message.success('Xóa ca làm việc thành công')
        setIsConfirmDeleteVisible(false) // Ẩn modal
        await fetchEmployees(
          branchIds,
          selectedPosition,
          format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
          format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
          currentPage,
        )
      } else {
        message.error('Xóa ca làm việc thất bại')
      }
    } catch (error) {
      console.error('Error deleting shift:', error)
      message.error('Đã xảy ra lỗi khi xóa ca làm việc')
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Tìm kiếm tên chi nhánh`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm kiếm
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Quay lại
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleShiftClick = async (shift) => {
    const parsedDate = parse(shift.ngayDK, 'dd/MM', new Date())
    if (isNaN(parsedDate)) {
      console.error('Invalid date:', shift.ngayDK)
      message.error('Ngày đăng ký không hợp lệ')
      return
    }

    const formattedNgayDK = format(parsedDate, 'yyyy-MM-dd')

    try {
      const response = await https.get('/CaLamViec/GetCaLamViecByIdNV', {
        params: {
          idCN: idcnCuaChiNhanh,
          ngayDK: formattedNgayDK,
          idCLV: shift.ca,
        },
      })

      const isMatch = response.data.some((item) => item.idcn === shift.idCN)

      // Cập nhật trạng thái `shiftDetails` và `selectedShift`
      setShiftDetails(response.data)

      setSelectedShift({
        ...shift,
        ngayDK: formattedNgayDK,
        tenCN: shift.tenCN,
        idCN: shift.idCN,
        idNV: shift?.idNV,
        iddkclvct: shift.iddkclvct,
        idtt: shift?.idtt,
      })

      // Gọi modal để hiển thị chi nhánh có màu sắc tương ứng
      setModalVisible(true)
    } catch (error) {
      console.error('Error fetching shift details:', error)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRecord(record)
    setConfirmModalVisible(true)
  }

  const handleConfirmChange = async () => {
    const updatedEmployeeData = [
      {
        iddkclvct: selectedShift.iddkclvct,
        idCN: selectedRecord.idcn,
        tenNV: selectedShift.tenNV,
        idNV: selectedShift.idNV,
        idtt: selectedShift.idtt,
      },
    ]

    try {
      const response = await https.put(
        '/CaLamViec/UpdateDanhSachNhanVienByIdCLV',
        updatedEmployeeData,
      )
      if (response.status === 200) {
        message.success('Cập nhật thành công')
        // Refresh the data
        await fetchEmployees(
          branchIds,
          selectedPosition,
          format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
          format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
          currentPage,
          searchKeyword,
        )
        // Close the modals
        setModalVisible(false)
        setConfirmModalVisible(false)
      } else {
        message.error('Cập nhật thất bại')
      }
    } catch (error) {
      console.error('Error updating employee data:', error)
      message.error('Đã xảy ra lỗi khi cập nhật dữ liệu')
    }
  }

  const handleConfirmClick = (employee) => {
    const employeeNgayCong = employee.tongCa / 2

    const contentMessage =
      employeeNgayCong < ngayCongChuan
        ? 'Hiện tại ngày công chưa bằng với ngày công chuẩn. Bạn có muốn xác nhận không?'
        : `Đăng ký lịch làm việc tháng ${format(currentMonth, 'MM/yyyy')} của nhân viên ${employee.tenNV} có ${employeeNgayCong} ngày công. Bạn có muốn xác nhận không?`

    Modal.confirm({
      title: 'Xác nhận',
      content: contentMessage,
      onOk: async () => {
        try {
          const response = await https.put(
            `/CaLamViec/UpdateTrangThaiCaLamViecDKByIdNV?idNV=${employee.idnv}&thangDK=${format(currentMonth, 'MM/yyyy')}&idTrangThai=4&nguoiSua=${infoUser.idnv}`,
          )
          if (response.status === 200) {
            message.success('Xác nhận thay đổi thành công')
            // Refresh the data
            await fetchEmployees(
              branchIds,
              selectedPosition,
              format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
              format(endOfMonth(currentMonth), 'yyyy-MM-dd', currentPage),
            )
          } else {
            message.error('Xác nhận thay đổi thất bại')
          }
        } catch (error) {
          console.error('Error updating employee data:', error)
          message.error('Đã xảy ra lỗi khi cập nhật dữ liệu')
        }
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  //nếu branchIds thay đổi thì fetch lại dữ liệu và trang hiện tại sẽ là trang 1
  useEffect(() => {
    fetchEmployees(
      branchIds,
      selectedPosition,
      format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
      format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
      1,
      searchKeyword,
    )
  }, [])

  const columns = [
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenCN',
      key: 'tenCN',
      ...getColumnSearchProps('tenCN'),
    },
    {
      title: 'Số lượng nhân viên trong ca',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      render: (text, record) => (record.nhanVienList ? record.nhanVienList.length : 0),
    },
  ]

  const getButtonColor = (ngayCong) => {
    return ngayCong > ngayCongChuan ? 'bg-green-300 text-white-500' : 'bg-blue-500 text-white'
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    fetchEmployees(
      branchIds,
      selectedPosition,
      format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
      format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
      page,
      searchKeyword,
    )
  }

  const handleConfirmAllCaLamViecForPosition = () =>
    Modal.confirm({
      title: 'Xác nhận',
      content: `Xác nhận tất cả nhân viên ${positionDisplayNames[selectedPosition]} của tháng ${format(currentMonth, 'MM/yyyy')}`,
      okText: 'Xác nhận',
      cancelText: 'Quay lại',
      onOk: async () => {
        try {
          let idCNs = branchIds.map((id) => `idCNs=${id}`).join('&')
          const response = await https.put(
            `/CaLamViec/UpdateTrangThaiCaLamViecDKByIdCN?${idCNs}&loaiNhomND=${selectedPosition}&thangDK=${format(currentMonth, 'MM/yyyy')}&nguoiSua=${infoUser.idnv}`,
          )
          if (response.status === 200) {
            Modal.success({
              content: 'Xác nhận thành công!',
              onOk: async () => {
                await fetchEmployees(
                  branchIds,
                  selectedPosition,
                  format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
                  format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
                  currentPage,
                  searchKeyword,
                )
              },
            })
          } else {
            Modal.error({
              content: 'Xác nhận thất bại!',
            })
          }
        } catch (error) {
          console.error('Error updating shifts:', error)
          Modal.error({
            content: 'Đã xảy ra lỗi khi xác nhận!',
          })
        }
      },
    })

  const handleConfirmBranchesForAdmin = async () => {
    const idCNs = selectedBranches.map((id) => `idCNs=${id}`).join('&')
    const loaiNhomND = selectedPosition // Giả sử bạn có giá trị này từ props hoặc state
    const thangDK = format(currentMonth, 'MM/yyyy') // Thay thế bằng giá trị thực tế
    const nguoiSua = infoUser.idnv // Thay thế bằng giá trị thực tế

    try {
      const response = await https.put(
        `/CaLamViec/UpdateTrangThaiCaLamViecDKByIdCN?${idCNs}&loaiNhomND=${loaiNhomND}&thangDK=${thangDK}&nguoiSua=${nguoiSua}`,
      )
      if (response.status === 200) {
        Modal.success({
          content: 'Xác nhận thành công!',
          onOk: async () => {
            await fetchEmployees(
              branchIds,
              selectedPosition,
              format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
              format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
              currentPage,
              searchKeyword,
            )
            setModalConfirmForAdminVisible(false)
          },
        })
      } else {
        message.error('Cập nhật thất bại')
      }
    } catch (error) {
      console.error('Error updating shift status:', error)
      message.error('Đã xảy ra lỗi khi cập nhật trạng thái')
    }
  }

  const modalListConfirmAllCaLamViecForAdmin = async () => {
    try {
      let idCNs = branchIds.map((id) => `idCNs=${id}`).join('&')

      if (branchIds.includes('all')) {
        idCNs = selectedChuyenKhoa ? `&idChuyenKhoa=${selectedChuyenKhoa}` : ''
      }
      const response = await https.get(
        `/CaLamViec/GetTrangThaiCaLamViecByIdCN?${idCNs}&loaiNhomND=${selectedPosition}&thangDK=${format(currentMonth, 'MM/yyyy')}`,
      )
      if (response.status === 200) {
        setListStatusCLVForAdmin(response.data)
        setModalConfirmForAdminVisible(true)
      } else {
        message.error('Lỗi khi lấy dữ liệu')
      }
    } catch (error) {
      console.error('Error fetching shift details:', error)
    }
  }

  const onLoad3 = () => {
    if (onLoad) {
      fetchEmployees(
        branchIds,
        selectedPosition,
        format(startOfMonth(currentMonth), 'yyyy-MM-dd'),
        format(endOfMonth(currentMonth), 'yyyy-MM-dd'),
        currentPage,
        searchKeyword,
      )
    }
  }
  useEffect(() => {
    onLoad3()
  }, [onLoad])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="bg-[#e6f4ff] border-[#91caff] border px-4 py-2 rounded-lg text-center w-64">
          <p>
            Ngày công chuẩn: <span className="font-bold text-blue-500">{ngayCongChuan}</span>
          </p>
        </div>
        <div className="flex items-center">
          {isBacSi ? (
            <Button
              className="bg-blue-500 text-white mr-2"
              disabled={!selectedPosition || confirmStaus || soNhanVienChuaDangKyCaC > 0} // Disable button if no position is selected or all shifts are confirmed
              onClick={handleConfirmAllCaLamViecForPosition}
            >
              <CheckSquareOutlined />
              {soNhanVienChuaDangKyCaC > 0
                ? `${soNhanVienChuaDangKyCaC} nhân viên chưa đăng ký ca C`
                : 'Xác nhận tất cả'}
            </Button>
          ) : (
            <Button
              className="bg-blue-500 text-white mr-2"
              disabled={!selectedPosition || confirmStaus}
              onClick={modalListConfirmAllCaLamViecForAdmin}
            >
              <CheckSquareOutlined />
              Xác nhận tất cả
            </Button>
          )}
          <CalendarControls />
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="grid grid-cols-8 gap-2 h-[70vh] overflow-y-auto">
          <div className="col-span-2 flex flex-col mt-[2.5rem] sticky left-0 bg-white z-10">
            {/* Danh sách nhân viên */}
            {/* <div className="min-h-[2.5rem] bg-slate-100 text-white flex items-center justify-center"></div> */}
            {employees.map((employee, empIndex) => (
              <div key={empIndex} className="employee-shift">
                <div className="border-2 min-h-40 p-4 rounded flex-grow flex flex-col items-center justify-center mb-2">
                  <p className="font-bold">{employee.tenNV}</p>
                  <div className="flex flex-col">
                    <p className="ml-2">
                      Ngày công: {employee.tongCa / 2} ({employee.tongCa}ca)
                    </p>
                    {selectedPosition === 'BacSi' ? (
                      <p className="ml-2">Số ca C: {employee.tongCaC}</p>
                    ) : null}
                  </div>
                  {isBacSi ? (
                    <Button
                      onClick={() => handleConfirmClick(employee)}
                      className={`mb-2 mt-2 ${getButtonColor(employee.tongCa / 2)}`}
                      disabled={employee.idtt === 4 || employee.tongCaC < 8} // Vô hiệu hóa nếu idtt = 4 hoặc tongCaC <= 8
                    >
                      {/* nếu idtt == 4 thì hiển thị "Đã duyệt", còn lại hiển thị "Xác nhận" */}
                      {employee.idtt === 4 ? 'Đã duyệt' : 'Xác nhận'}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleConfirmClick(employee)}
                      className={`mb-2 mt-2 ${getButtonColor(employee.tongCa / 2)}`}
                      disabled={employee.idtt === 4} // Vô hiệu hóa nếu idtt = 4 hoặc
                    >
                      {/* nếu idtt == 4 thì hiển thị "Đã duyệt", còn lại hiển thị "Xác nhận" */}
                      {employee.idtt === 4 ? 'Đã duyệt' : 'Xác nhận'}
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* Danh sách ca làm việc */}
          <div className="col-span-6 flex ">
            {selectedPosition ? (
              <div className="flex flex-row">
                {Object.keys(selectedEmployees).map((day, index) => (
                  <div key={index} className="p-2 flex flex-col mr-2 w-[18rem]">
                    <div className="flex justify-center items-center mb-2 border rounded bg-white sticky top-0 z-9 w-[18rem]">
                      <p className="font-bold">
                        {format(addDays(currentMonth, index), 'EEEE', { locale: vi })}
                        {'-'} {day}
                      </p>
                    </div>
                    <div className="flex flex-col ">
                      {Object.keys(selectedEmployees[day] || {}).map((employeeName, empIndex) => {
                        const isGray = selectedEmployees[day][employeeName].some(
                          (shift) => shift.idtt === 4,
                        )
                        const containerClass = isGray ? 'bg-gray-100 text-gray-500' : ''
                        return (
                          <div
                            key={empIndex}
                            className={`flex-grow min-h-40 w-[18rem] border-dashed border-2 rounded mb-2 flex flex-col items-center justify-center ${containerClass}`}
                          >
                            {Array.isArray(selectedEmployees[day][employeeName]) ? (
                              selectedEmployees[day][employeeName].map((shift, shiftIndex) => {
                                const bgColor =
                                  shift.idtt === 4
                                    ? 'bg-gray-100 text-gray-500' // Màu xám nếu idtt = 4
                                    : shiftIndex === 0
                                      ? 'bg-blue-100 text-blue-500'
                                      : shiftIndex === 1
                                        ? 'bg-yellow-100 text-yellow-500'
                                        : shiftIndex === 2
                                          ? 'bg-green-100 text-green-500'
                                          : shiftIndex === 3
                                            ? 'bg-red-100 text-red-500'
                                            : 'bg-gray-100 text-gray-500'
                                return (
                                  <div
                                    key={shiftIndex}
                                    className={`border p-2 rounded mb-2 w-[17rem] y-2 flex flex-col items-center justify-center ${bgColor} ${shift.idtt !== 4 ? 'hover:bg-opacity-75' : ''}`}
                                    title={shift.tenCN} // This will show the full text on hover
                                    onClick={() => {
                                      if (shift.idtt !== 4) {
                                        handleShiftClick({
                                          idCN: shift.idCN,
                                          ngayDK: day,
                                          ca: shift.ca,
                                          tenNV: employeeName,
                                          idNV: shift.idNV,
                                          iddkclvct: shift.iddkclvct,
                                          idtt: shift.idtt,
                                        })
                                      }
                                    }}
                                  >
                                    {shift.tenCN ? (
                                      <p className="flex items-center justify-between text-[14px] text-gray-500 rounded-2xl">
                                        <span className="font-bold">
                                          {shift.tenCN.length > 30
                                            ? shift.tenCN.substring(0, 30) + '...'
                                            : shift.tenCN}
                                        </span>
                                        {shift.idtt !== 4 && (
                                          <CloseCircleOutlined
                                            className="ml-2 text-red-500 cursor-pointer"
                                            onClick={(event) => {
                                              event.stopPropagation() // Ngăn chặn sự kiện onClick của phần tử cha
                                              handleDeleteShiftClick(shift) // Gọi hàm xóa
                                            }}
                                          />
                                        )}
                                      </p>
                                    ) : (
                                      <div className="flex flex-row items-center justify-between py-1">
                                        <PlusOutlined />
                                      </div>
                                    )}
                                  </div>
                                )
                              })
                            ) : (
                              <p>No shifts available</p>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="col-span-7 flex flex-col items-center justify-center ml-[55px]">
                <img src={logo} alt="Logo" className="mb-4" width={300} />
                <p className="text-center text-gray-500 text-3xl">
                  Hãy chọn
                  <span className="font-bold text-blue-500"> chức vụ </span>
                  để xem lịch làm việc của nhân viên
                </p>
              </div>
            )}
          </div>
          {isModalVisible && (
            <Modal
              title="Chọn chi nhánh"
              visible={isModalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <Table
                columns={columns}
                dataSource={shiftDetails}
                pagination={false} // Bỏ phân trang
                rowKey={(record) => record.idcn}
                scroll={{ y: 400 }} // Thêm thuộc tính scroll để cuộn theo chiều dọc
                // So sánh idCN của shift với idcn trong dữ liệu trả về và áp dụng màu đỏ nếu trùng
                rowClassName={(record) =>
                  record.idcn === selectedShift?.idCN ? 'bg-gray-100' : ''
                }
                onRow={(record) => ({
                  onClick: () => {
                    if (record.idcn !== selectedShift?.idCN) {
                      handleRowClick(record)
                    }
                  },
                  style: {
                    cursor: record.idcn === selectedShift?.idCN ? 'not-allowed' : 'pointer',
                    pointerEvents: record.idcn === selectedShift?.idCN ? 'none' : 'auto',
                  },
                })}
              />
            </Modal>
          )}
          {isConfirmModalVisible && (
            <Modal
              title="Xác nhận thay đổi"
              visible={isConfirmModalVisible}
              onCancel={() => setConfirmModalVisible(false)}
              footer={[
                <Button key="back" onClick={() => setConfirmModalVisible(false)}>
                  Quay lại
                </Button>,
                <Button key="submit" type="primary" onClick={handleConfirmChange}>
                  Thay đổi
                </Button>,
              ]}
            >
              <p>Bạn có muốn thay đổi chi nhánh cho nhân viên này không?</p>
            </Modal>
          )}
          <Modal
            title="Xác nhận xóa"
            visible={isConfirmDeleteVisible}
            onCancel={() => setIsConfirmDeleteVisible(false)} // Đóng modal nếu không muốn xóa
            footer={[
              <Button key="back" onClick={() => setIsConfirmDeleteVisible(false)}>
                Hủy
              </Button>,
              <Button key="submit" type="primary" onClick={handleConfirmDelete}>
                Xác nhận
              </Button>,
            ]}
          >
            <p>Bạn có chắc chắn muốn xóa ca làm việc này không?</p>
          </Modal>

          <Modal
            title={
              <>
                <div className="flex items-center">
                  <Checkbox
                    onChange={handleSelectAllCaLamViecForAdmin}
                    checked={
                      listStatusCLVForAdmin.filter((branch) => !branch.isConfirm).length > 0 &&
                      selectedBranches.length ===
                        listStatusCLVForAdmin.filter((branch) => !branch.isConfirm).length
                    }
                  ></Checkbox>

                  <p>Chọn tất cả</p>
                </div>
              </>
            }
            visible={modalConfirmForAdminVisible}
            onCancel={() => setModalConfirmForAdminVisible(false)}
            footer={[
              <Button key="cancel" onClick={() => setModalConfirmForAdminVisible(false)}>
                Hủy
              </Button>,
              <Button key="confirm" type="primary" onClick={handleConfirmBranchesForAdmin}>
                Xác nhận
              </Button>,
            ]}
            bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }} // Đặt chiều cao cố định và thêm overflow
          >
            <List
              dataSource={listStatusCLVForAdmin}
              renderItem={(branch) => (
                <List.Item>
                  <Card style={{ width: '100%' }}>
                    <Checkbox
                      checked={branch.isConfirm || selectedBranches.includes(branch.idChiNhanh)}
                      disabled={branch.isConfirm}
                      onChange={() => handleBranchSelectForConfirm(branch.idChiNhanh)}
                    />
                    <Divider type="vertical" />
                    <span>{branch.tenChiNhanh}</span>
                  </Card>
                </List.Item>
              )}
            />
          </Modal>
        </div>
      </Spin>

      <div className="flex justify-center items-center mt-4">
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          pageSize={pageSize}
          total={totalCount}
          onChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default CalendarGrid
