export const optionKieuToa = {
    chart: {
      width: 380,
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ['Khách không lấy toa', 'Bác sĩ không kê toa'],
    colors: ['#2DB371', '#0396CD'], // Tùy chỉnh màu sắc
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    // theme: {
    //   palette: 'palette2',
    // },
    fill: {
      type: 'gradient',
    },
    legend: {
        position: 'bottom', // Di chuyển label xuống dưới biểu đồ
        formatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
        },
      },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            formatter: function (val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
            },
            position: 'bottom',
          },
        },
      },
    ],
  }

  export const optionToTalLyDo ={
    chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
  }
  export const optionLyDoDuoc =  {
    chart: {
    height: 350,
    events: {
      click: function(chart, w, e) {
        // console.log(chart, w, e)
      }
    }
  },
//   colors: colors,
  plotOptions: {
    bar: {
      columnWidth: '45%',
      distributed: true,
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false
  },
  xaxis: {
    // categories: [
    //   ['John', 'Doe'],
    //   ['Joe', 'Smith'],
    //   ['Jake', 'Williams'],
    //   'Amber',
    //   ['Peter', 'Brown'],
    //   ['Mary', 'Evans'],
    //   ['David', 'Wilson'],
    //   ['Lily', 'Roberts'], 
    // ],
    labels: {
      style: {
        // colors: colors,
        fontSize: '12px'
      }
    }
  }
  };