
import * as typeAction from '../constants/constants'

export const addDonViTinh = (form, resetForm) => ({
    type: typeAction.ADD_DON_VI_TINH,
    form,
    resetForm,
    })

export const updateDonViTinh = (ma, data) => ({
    type: typeAction.UPDATE_DON_VI_TINH,
    payload: { ma, data },
    });

export const getByMaDonViTinh = (ma) => ({
    type: typeAction.GET_BY_MA_DON_VI_TINH,
    ma,
    });

export const deleteDonViTinh = (ma) => ({
    type: typeAction.DELETE_DON_VI_TINH,
    ma,
    });

    export const getAllDonViTinh = () => ({
        type: typeAction.GET_ALL_DON_VI_TINH,
    })
    