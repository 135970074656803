import * as typeAction from '../constants/constants'

export const getAllKhachHangCRM = () => ({
  type: typeAction.GET_ALL_KHACHHANG_CRM,
})

export const addKhachHangCRM = (form, onLoad) => ({
  type: typeAction.ADD_KHACHHANG_CRM,
  form,
  onLoad,
})

export const editKhachHangCRM = (form, onLoad) => ({
  type: typeAction.EDIT_KHACHHANG_CRM,
  form,
  onLoad,
})

export const getKhachHangCRMById = (id) => ({
  type: typeAction.GET_KHACHHANG_CRM_BY_ID,
  id,
})
