import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import { cardService } from '../../services/card/cardService'
import { traCuuService } from '../../services/traCuu/traCuuService'
import * as typeAction from '../constants/constants'
import { banLeService } from '../../services/banLe/banLeService'
import 'moment/locale/vi'
moment.locale('vi')

export function* banLeSaga() {
  yield takeLatest(typeAction.GET_PHIEU_THU_THUOC_BAN_LE, function* PTThuoc({ type, filter }) {
    try {
      const { idcn, tuNgay, denNgay, keyword } = filter
      const result = yield call(() => banLeService.getPhieuThuBanLe(idcn, tuNgay, denNgay, keyword))
      yield put({
        type: typeAction.DISPATCH_SET_LIST_PT_BAN_LE,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
}
