import { Input, Modal, Select, Divider, Statistic } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { QRCode } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Modaldetai = ({ isModalOpen, handleCancel }) => {
  const { infoVT, infoTonKho } = useSelector((state) => state.thuocVTReducer);
  const dispatch = useDispatch();

  const CancelModal = () => {
    handleCancel();
  };
  return (
    <Modal
      width={580}
      className="text-center"
      title={`Thông tin tồn kho - Mã thuốc & VT: ${infoTonKho?.thuocVatTu.maThuoc}`}
      footer={null}
      open={isModalOpen}
      onCancel={CancelModal}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-around">
            <div className="w-1/2 flex justify-center">
              <QRCode
                value={infoTonKho?.thuocVatTu.maThuoc}
                type="svg"
                size={100}
              />
            </div>
            <div className="w-1/2 flex justify-center">
              <div className="w-36">
                <Barcode
                  className="w-full h-min"
                  value={infoTonKho?.thuocVatTu.maThuoc}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Divider style={{ margin: 0 }} orientation="left">
              Thông tin kho
            </Divider>
            <ul className="flex justify-between">
              <li className="font-semibold w-1/2">
                Chi nhánh:{" "}
                <span className="font-normal">{infoTonKho?.tenCN}</span>
              </li>
              <li className="font-semibold w-1/2">
                Kho chi nhánh:{" "}
                <span className="font-normal">{infoTonKho?.tenKhoCn}</span>
              </li>
            </ul>
            <ul className="flex justify-between">
              <li className="font-semibold w-1/3">
                <Statistic
                  title="Tổng nhập"
                  value={infoTonKho?.tongNhap}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </li>
              <li className="font-semibold w-1/3">
                <Statistic
                  title="tổng xuất"
                  value={infoTonKho?.tongXuat}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </li>
              <li className="font-semibold w-1/3">
                <Statistic
                  title=" Tổng tồn"
                  value={infoTonKho?.tonKho}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </li>
            </ul>
            <Divider style={{ margin: 0 }} orientation="left">
              Thông tin thuốc vật tư
            </Divider>
            <div className="flex flex-col gap-2">
              <ul className="flex flex-col gap-2" >
                <li className="font-semibold ">
                  Tên biệt dược:{" "}
                  <span className="font-normal">
                    {infoTonKho?.thuocVatTu.tenBietDuoc}
                  </span>
                </li>
                <li className="font-semibold">
                  Tên hoá chất:{" "}
                  <span className="font-normal">
                    {infoTonKho?.thuocVatTu.tenHoatChat}
                  </span>
                </li>
                <li className="font-semibold">
                  Hạn dùng:{" "}
                  <span className="font-normal">
                    {infoTonKho?.hanDung  && moment(infoTonKho?.hanDung).format('DD/MM/YYYYY')}
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between">
                <li className="font-semibold w-1/2">
                Đơn vị tính:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.dvt}</span>
                </li>
                <li className="font-semibold w-1/2">
                  Quy cách:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.quyCach}</span>
                </li>
              </ul>
              <ul className="flex justify-between">
                <li className="font-semibold w-1/2">
                Nồng độ:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.nongDo}</span>
                </li>
                <li className="font-semibold w-1/2">
                  Hàm lượng:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.hamLuong}</span>
                </li>
              </ul>
              <ul className="flex justify-between">
                <li className="font-semibold w-1/2">
                Đơn vị dùng:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.donViDung}</span>
                </li>
                <li className="font-semibold w-1/2">
                 Đường dùng:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.duongDung}</span>
                </li>
              </ul>
              <ul className="flex justify-between">
                <li className="font-semibold w-1/2">
                Mã số đăng kí:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.maSoDangKy}</span>
                </li>
                <li className="font-semibold w-1/2">
                 Cách dùng:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.cachDung}</span>
                </li>
              </ul>
              <ul className="flex justify-between">
                <li className="font-semibold w-1/2">
                Nước sản xuất:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.nuocSanXuat}</span>
                </li>
                <li className="font-semibold w-1/2">
                Nhà sản xuất:{" "}
                  <span className="font-normal">{infoTonKho?.thuocVatTu.nhaSanXuat}</span>
                </li>
              </ul>
              <ul>
                <li className="font-semibold ">
                  Tên đối tác:{" "}
                  <span className="font-normal">
                    {infoTonKho?.thuocVatTu.tenDoiTac}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div></div>
      </form>
    </Modal>
  );
};

export default Modaldetai;
