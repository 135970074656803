import { Input, Modal } from 'antd'
import React from 'react'

const ModalDeletePhieuThu = ({ showDeletePT, setShowDeletePT }) => {
  const handleOk = () => {}

  return (
    <Modal
      open={showDeletePT}
      onCancel={() => setShowDeletePT(false)}
      onOk={handleOk}
      title="Xóa phiếu thu"
      cancelText="Hủy"
      okText="Xóa"
    >
      <div>
        <label className="font-semibold">Mã BN thanh toán:</label>
        <Input
        // onChange={(e) =>
        //   setFormPay((preState) => ({ ...preState, ghiChuXuatHoaDon: e.target.value }))
        // }
        />
      </div>
    </Modal>
  )
}
export default ModalDeletePhieuThu
