import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { addVaiTroSchema } from '../../../../schemas/addVaiTroSchema'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'

function ModalEditVaiTro({ isModalOpenEdit, setIsModalOpenEdit, getDataRefresh }) {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maNhom: isModalOpenEdit.data?.maNhom,
      tenNhom: isModalOpenEdit.data?.tenNhom,
      tenVietTat: isModalOpenEdit.data?.tenVietTat,
      ghiChu: isModalOpenEdit.data?.ghiChu,
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addVaiTroSchema,
  })

  const handleCloseModal = () => {
    setIsModalOpenEdit(false)
    formik.resetForm()
  }

  const handleSubmit = async (values, actions) => {
    setLoading(true)
    try {
      //api add vai tro
      const resultAddNhomNguoiDung = await https.put(
        `NhomNguoiDung/UpdateNhom/${isModalOpenEdit.data.idNhom}`,
        {
          ...values,
        },
      )
      if (!resultAddNhomNguoiDung.data) {
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa vai trò thất bại',
        })
        throw new Error('Sửa vai trò thất bại')
      }
      //success
      handleCloseModal()
      getDataRefresh()
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa vai trò thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi sửa vai trò',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      width={650}
      className="text-center"
      title="Thông tin vai trò"
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      open={isModalOpenEdit.show}
      onOk={formik.handleSubmit}
      onCancel={handleCloseModal}
      maskClosable={false}
      confirmLoading={loading}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-3 ">
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Tên vai trò:
            </label>
            <Input
              status={formik.errors.tenNhom ? 'error' : ''}
              name="tenNhom"
              value={formik.values.tenNhom}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Mã vai trò:
            </label>
            <Input
              status={formik.errors.maNhom ? 'error' : ''}
              name="maNhom"
              value={formik.values.maNhom}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Tên viết tắt:</label>
            <Input
              name="tenVietTat"
              value={formik.values.tenVietTat}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Ghi chú:</label>
            <Input
              name="ghiChu"
              value={formik.values.ghiChu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

ModalEditVaiTro.propTypes = {}

export default ModalEditVaiTro
