import * as typeAction from '../constants/constants'

export const getAllHangHoa = (idct) => ({
  type: typeAction.GET_ALL_HANG_HOA,
  idct,
})

export const getAllNguoiDuyet = () => ({
  type: typeAction.GET_ALL_NGUOI_DUYET,
})

export const getDatHang = (keyword, fromDate, toDate, pageIndex, idnv) => ({
  type: typeAction.GET_ALL_DAT_HANG,
  keyword,
  fromDate,
  toDate,
  pageIndex,
  idnv,
})

export const getAllDonHangByTrangThai = (
  keyword,
  fromDate,
  toDate,
  pageIndex,
  idTrangThai,
  idct,
  idcn,
  nguoiduyet = '',
) => ({
  type: typeAction.GET_ALL_DON_DAT_HANG_BY_TRANG_THAI,
  keyword,
  fromDate,
  toDate,
  pageIndex,
  idTrangThai,
  idct,
  idcn,
  nguoiduyet,
})

export const getAllDonHangThuMuaDuyet = (keyword, idct, pageIndex) => ({
  type: typeAction.GET_ALL_DON_DAT_HANG_THU_MUA_DUYET,
  keyword,
  idct,
  pageIndex,
})

export const addDonDatHang = (form, onLoad, setLoadingBtn, handleCloseModal) => ({
  type: typeAction.ADD_DON_DAT_HANG,
  form,
  onLoad,
  setLoadingBtn,
  handleCloseModal,
})

export const editDonDatHang = (form, onLoad, setLoadingBtn, handleCloseModal, updateStatus = false) => ({
  type: typeAction.EDIT_DON_DAT_HANG,
  form,
  onLoad,
  setLoadingBtn,
  handleCloseModal,
  updateStatus
})

export const nhanDonHang = (form, setLoadingBtn, handleCancel) => ({
  type: typeAction.NHAN_DON_DAT_HANG,
  form,
  setLoadingBtn,
  handleCancel,
})

export const updateTrangThaDatHang = (id, idTrangThai, idnv = '', onLoad) => ({
  type: typeAction.UPDATE_TRANG_THAI_DON_DAT_HANG,
  id,
  idTrangThai,
  idnv,
  onLoad,
})

export const updateTrangThaiListDatHang = (listDonDatHang, onLoad) => ({
  type: typeAction.UPDATE_TRANG_THAI_LIST_DON_DAT_HANG,
  listDonDatHang,
  onLoad,
})


export const getInfoDonHang = (id) => ({
  type: typeAction.GET_INFO_DON_HANG,
  id,
})

export const getInfoDonHangIncludeMoney = (id) => ({
  type: typeAction.GET_INFO_DON_HANG_INCLUDE_MONEY,
  id,
})

export const deleteDonHang = (id, onLoad) => ({
  type: typeAction.DELETE_DON_HANG,
  id,
  onLoad,
})

export const getAllDonHangByIdCtyIdCn = (keyword, fromDate, toDate, pageIndex, idct, idcn) => ({
  type: typeAction.GET_ALL_DON_DAT_HANG_BY_IDCT_IDCN,
  keyword,
  fromDate,
  toDate,
  pageIndex,
  idct,
  idcn,
})
