import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { editKhachHangCRM } from '../../../../store/actions/khachhangCRMAction'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'

const ModalEdit = ({ openEdit, setOpenEdit, onLoad }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoKH } = useSelector((state) => state.khachhangCRMReducer)
  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    dispatch(editKhachHangCRM(value, onLoad))
    formik.resetForm()
    setOpenEdit(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idkh: infoKH?.idkh,
      doanhthu: infoKH?.doanhthu,
      giamgia: infoKH?.giamgia,
      chietkhau: infoKH?.chietkhau,
      nguoisua: infoUser.idnv,
      tennguoisua: infoUser.tenNV,
      ghichu: infoKH?.ghichu,
    },
    onSubmit: (value) => handleSubmit(value),
  })

  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Thông tin Khách hàng</h2>}
        onOk={formik.handleSubmit}
        open={openEdit}
        onCancel={() => setOpenEdit(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Khách hàng</label>
            <Input readOnly value={infoKH?.tenkh} />
          </div>
          <div>
            <label className="font-semibold  text-base">Nhân viên tạo</label>
            <Input readOnly value={infoKH?.tennguoitao} />
          </div>
          <div>
            <label className="font-semibold  text-base">Ngày tạo</label>
            <Input readOnly value={moment(infoKH?.ngaytao).format(dateFormat)} />
          </div>
          <div>
            <label className="font-semibold  text-base">Doanh thu</label>
            <Input
              type="number"
              min={0}
              step={1}
              value={formik.values.doanhthu}
              name="doanhthu"
              onChangeCapture={formik.handleChange}
              status={formik.errors.doanhthu && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Giảm giá</label>
            <Input
              type="number"
              min={0}
              step={1}
              value={formik.values.giamgia}
              name="giamgia"
              onChangeCapture={formik.handleChange}
              status={formik.errors.giamgia && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Chiết khấu</label>
            <Input
              type="number"
              min={0}
              step={1}
              value={formik.values.chietkhau}
              name="chietkhau"
              onChangeCapture={formik.handleChange}
              status={formik.errors.chietkhau && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              value={formik.values.ghichu}
              name="ghichu"
              onChangeCapture={formik.handleChange}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEdit
