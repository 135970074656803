import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listPartner: null,
}

const partnerReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISTPATCH_LIST_PARTNER:
        draft.listPartner = payload
        break
      default:
        return state
    }
  })
}

export default partnerReducer
