import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { Input, Select, Button, DatePicker } from 'antd'
import { SearchOutlined, FilterOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import TableThuoc from './TableThuoc/TableThuoc'
import TableTheKho from './TableTheKho/TableTheKho'
import {
  branchTonKhoAction,
  fetchGroupsTheKho,
  fetchKhoTheKhoByBranch,
  filterTheKho,
  getListThuocVTByPageIndex_Action,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import moment from 'moment'
import dayjs from 'dayjs'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const formatDate = 'DD/MM/YYYY'
const TheKho = () => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const idBranchDefault = Number(localStorage.getItem('BRANH_LOGIN'))
  const { historyTheKho } = useSelector((state) => state.thuocVTReducer)
  //------------ useHook -------//
  const [company, setCompany] = useState(id_company)
  const [branch, setBranch] = useState(idBranchDefault)
  const [infoThuoc, setInfoThuoc] = useState(null)
  const [kho, setKho] = useState(null)
  const [isloading, setIsLoading] = useState(false)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [search, setSearch] = useState(null)
  const [dateFrom, setDateForm] = useState(today.format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(today.format('YYYY-MM-DD'))
  const [type, setType] = useState(null)
  const [group, setGroup] = useState(null)
  //------------ STORE REDUCER -----------//
  const { branchTonKho, typesVT, groupsTheKho, khoTheKho } = useSelector(
    (state) => state.thuocVTReducer,
  )
  //---------- chọn loại thuốc vật tư -----------//
  const SelectedType = (value) => {
    setType(value)
    dispatch(fetchGroupsTheKho(value))
    setGroup(null)
  }
  //------------- chọn nhón thuốc vật tư --------//
  const selectedGroup = (value) => {
    setGroup(value)
  }
  //------------- search thuốc vật tư ------------//
  const debounceDropDown = useCallback(
    _.debounce((keyword, id_company, types, valueGroups) => {
      dispatch(
        getListThuocVTByPageIndex_Action(
          keyword,
          id_company,
          types,
          valueGroups,
          useSearch.get('page'),
        ),
      )
    }, 300),
    [],
  )
  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    SetSearchParams({ page: 1 })
    debounceDropDown(value, id_company, type, group)
  }
  //---------- click tìm kiếm ----------- //
  const onClickSearch = () => {
    SetSearchParams({ page: 1 })
    dispatch(getListThuocVTByPageIndex_Action(search, id_company, type, group, 1))
  }
  //------------ chọn chi nhánh tìm kiêm -----------//
  const selectedBranch = (value) => {
    setBranch(value)
    setKho(null)
    dispatch(fetchKhoTheKhoByBranch(value))
  }

  //------------ hiện thị kho làn đầu -----------//
  useEffect(() => {
    selectedBranch(idBranchDefault)
  }, [])
  //---------------- chọn kho -----------------//
  const selectedKho = (value) => {
    setKho(value)
  }
  //---------------- datePicker ----------//
  const selectedDatefrom = (date, dateString) => {
    setDateForm(moment(dateString, formatDate).format('YYYY-MM-DD'))
  }
  const selectedDateTo = (date, dateString) => {
    setDateTo(moment(dateString, formatDate).format('YYYY-MM-DD'))
  }
  // -------------- filter danh sách kho ------//
  const onClickFilter = () => {
    const filter = {
      IDCT: id_company,
      branch,
      kho,
      dateFrom,
      dateTo,
      idThuoc: infoThuoc.idThuoc,
    }
    dispatch(filterTheKho(filter, setIsLoading))
  }
  //---------xuất excel -------//
  const exportToExcel = () => {
    const formatData = historyTheKho?.map((item) => ({
      Ngày: item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
      Phiếu: item.maPhieu,
      Loại: item.loai,
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Chi nhánh nhập': item.tenChiNhanhNhap,
      'Kho nhập': item.tenKhoNhap,
      'Số lượng': item.soLuong,
      'Số lô': item.soLo,
      'Số hoá đơn': item.soHoaDon,
      'Hạn dùng': item.hanDung ? moment(item.hanDung).format('DD/MM/YYYY') : '-',
    }))
    const name = 'Thẻ kho'
    exportExcelformat(formatData, name)
  }
  useEffect(() => {
    dispatch(
      getListThuocVTByPageIndex_Action(search, id_company, type, group, useSearch.get('page')),
    )
  }, [useSearch.get('page')])
  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(branchTonKhoAction(id_company))
    dispatch(listTypesThuocVT_Action())
  }, [])

  return (
    <>
      <div className="h-full bg-[#EFEFEF]">
        <div className="p-5 h-full">
          <div className=" h-full  bg-white border rounded-lg flex">
            <div className="h-full  w-1/3 border-r">
              <div className="p-2 h-full">
                <div className="border h-full rounded">
                  <div className="p-2 border-b">
                    {/* <div className="flex flex-col gap-1">
                      <label className="font-semibold ">Công ty :</label>
                      <Select
                        className="w-full"
                        onChange={handleCompany}
                        value={company}
                        options={listCompany?.map((items) => ({
                          label: items.tenct,
                          value: items.idct,
                        }))}
                      />
                    </div> */}
                    <div className="flex gap-1">
                      <div className="w-1/2">
                        <label className="font-semibold h-5 w-full"> Loại:</label>
                        <Select
                          className="w-full"
                          placeholder="Loại hàng hoá"
                          onChange={SelectedType}
                          value={type}
                          options={[
                            { label: 'Tất cả', value: null },
                            ...(typesVT?.map((items) => ({
                              label: items.phanLoai,
                              value: items.idPhanLoai,
                            })) ?? []),
                          ]}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="font-semibold h-5 w-full">Nhóm :</label>
                        <Select
                          className="w-full"
                          onChange={selectedGroup}
                          value={group}
                          options={[
                            { label: 'Tất cả', value: null },
                            ...(groupsTheKho?.map((items) => ({
                              label: items.nhom,
                              value: items.idNhom,
                            })) ?? []),
                          ]}
                        />
                      </div>
                    </div>
                    <div className="flex gap-1 mt-2">
                      <Input onChange={handleSearch} placeholder="Nhập từ khoá tìm kiếm" />
                      <Button type="primary" onClick={onClickSearch} icon={<SearchOutlined />}>
                        Tìm
                      </Button>
                    </div>
                  </div>
                  <div>
                    <TableThuoc
                      setInfoThuoc={setInfoThuoc}
                      SetSearchParams={SetSearchParams}
                      page={useSearch.get('page')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/3 h-full">
              <div className="p-2">
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <p className="font-semibold">Chi nhánh:</p>
                    <Select
                      onChange={selectedBranch}
                      className="w-56"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      showSearch
                      placeholder="Chọn chi nhánh"
                      options={[
                        { label: 'Tất cả', value: null },
                        ...(branchTonKho?.map((items) => ({
                          label: items.tenChiNhanh,
                          value: items.idChiNhanh,
                        })) ?? []),
                      ]}
                      value={branch}
                    />
                    <p className="font-semibold">Kho:</p>
                    <Select
                      className="w-44"
                      value={kho}
                      onChange={selectedKho}
                      options={[
                        { label: 'Tất cả', value: null },
                        ...(khoTheKho?.map((items) => ({
                          label: items.tenKho,
                          value: items.idKhoCN,
                        })) ?? []),
                      ]}
                      placeholder="Chọn Kho"
                    />
                    <div className="flex gap-2 items-center">
                      <DatePicker
                        onChange={selectedDatefrom}
                        value={dayjs(dateFrom)}
                        format={formatDate}
                        allowClear={false}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                      </svg>
                      <DatePicker
                        onChange={selectedDateTo}
                        value={dayjs(dateTo)}
                        format={formatDate}
                        allowClear={false}
                      />
                    </div>
                  </div>
                  <Button
                    onClick={onClickFilter}
                    disabled={!infoThuoc}
                    type="primary"
                    icon={<FilterOutlined />}
                  >
                    Xem
                  </Button>
                  <Button
                    onClick={exportToExcel}
                    type="text"
                    size="middle"
                    className="text-green-700 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                    Excel
                  </Button>
                </div>

                <div className="flex gap-1 items-center my-2">
                  <label className="w-[100px] font-semibold">Hàng hoá: </label>
                  <Input
                    variant="filled"
                    style={{ color: 'black' }}
                    value={infoThuoc?.maThuoc}
                    // disabled
                    readOnly
                    className="w-36"
                  />
                  <Input
                    readOnly
                    variant="filled"
                    style={{ color: 'black' }}
                    value={infoThuoc?.tenBietDuoc}
                  />
                  <Input
                    readOnly
                    variant="filled"
                    style={{ color: 'black' }}
                    value={infoThuoc?.quyCach}
                    className="w-60"
                  />
                </div>
                <div>
                  <TableTheKho isloading={isloading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TheKho
