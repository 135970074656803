import moment from 'moment/moment'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import { generateCheckbox, generateDot, generateDotWithWidth } from '../algorithm'
const getChuyenKhoa = (value) => {
  switch (value) {
    case 1:
      return `NHI KHOA`
    case 2:
      return `SẢN KHOA`
    case 3:
      return `LÃO KHOA`
    case 4:
      return `MẮT`
    case 21:
      return `TIM MẠCH TIỂU ĐƯỜNG`
    default:
      return 'ĐA KHOA'
  }
}

function calculateAge(birthdate) {
  const birthDate = moment(birthdate)
  const today = moment()
  return today.diff(birthDate, 'years')
}

export const benhAnNgoaiTru = (infoBN, icdObj) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //page 1
  doc.setLineWidth(0.2)
  //header
  doc.setFontSize(15)
  doc.setFont('times', 'bold')
  doc.text('BỆNH ÁN NGOẠI TRÚ', doc.internal.pageSize.width / 2, yPos + 3, { align: 'center' })

  doc.setFontSize(12)
  doc.setFont('times', 'regular')
  doc.text('Sở Y tế TP.Hồ Chí Minh', xPos + 10, 10)
  switch (infoBN.benhNhan.chiNhanh.idChuyenKhoa) {
    case 1:
      doc.text('Hệ thống Y tế Nhi đồng 315', xPos + 7, 15)
      break
    case 2:
      doc.text('Hệ thống phòng khám Sản khoa 315', xPos + 2, 15)
      break
    case 3:
      doc.text('Hệ thống Y tế TMTĐ 315', xPos + 2, 15)
      break
    case 4:
      doc.text('Hệ thống phòng khám Mắt 315', xPos + 5, 15)
      break
    case 21:
      doc.text('Hệ thống Y tế TMTĐ 315', xPos + 2, 15)
      break
    default:
      doc.text('Phòng khám Đa khoa Quốc tế', xPos + 5, 15)
      doc.text('IVY HEALTH', xPos + 16, 20)
      break
  }

  doc.setFontSize(10)
  doc.text('MS: BA-16', pageWidth - 40, 10)
  doc.setFontSize(12)

  doc.setFont('times', 'normal')
  const labelText = 'KHOA:'
  const labelWidth = doc.getTextWidth(labelText)
  // Set font for the second text

  // Checked checkboxes

  const chuyenKhoaText = getChuyenKhoa(infoBN.benhNhan.chiNhanh.idChuyenKhoa)
  const chuyenKhoaWidth = doc.getTextWidth(chuyenKhoaText)

  // Calculate the total width
  const totalWidth = labelWidth + chuyenKhoaWidth + 2
  const startX = (doc.internal.pageSize.width - totalWidth) / 2
  // Draw the texts
  doc.text(labelText, startX, 20)
  doc.setFont('times', 'bold')
  doc.text(chuyenKhoaText, startX + labelWidth + 2, 20)

  doc.text('A. THÔNG TIN CHUNG:', xPos, 28)
  let textWidthHoTen = doc.getTextDimensions('1. Họ và tên (In hoa): ').w
  doc.text(`${infoBN.benhNhan.tenBenhNhan ?? ''}`, textWidthHoTen + xPos, 35)
  doc.setFont('times', 'regular')
  doc.text('1. Họ và tên', xPos, 35)
  doc.setFont('times', 'italic')
  doc.text(' (In hoa):', doc.getTextDimensions('1. Họ và tên').w + xPos, 35)
  const dotLineHoTen = generateDot(
    doc,
    `1. Họ và tên (In hoa): ${infoBN.benhNhan.tenBenhNhan ?? ''} `,
    xPos,
    35,
  )
  doc.text(dotLineHoTen.positionX, dotLineHoTen.yPos, dotLineHoTen.content)
  yPos = 35
  yPos += 7
  doc.setFont('times', 'regular')
  doc.text(
    `2. Ngày sinh: ${moment(infoBN.benhNhan.ngaySinh ?? '').format('DD/MM/YYYY')}`,
    xPos,
    yPos,
  )
  doc.text('Tuổi: ', xPos + 70, yPos)
  const age = calculateAge(infoBN.benhNhan.ngaySinh)
  if (age < 10) {
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(age + '', xPos + 91, yPos)
  } else {
    //tach so
    let ageString = age + ''
    while (ageString.length < 3) {
      ageString = '0' + ageString
    }

    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(ageString[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(ageString[1], xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(ageString[2], xPos + 91, yPos)
  }

  doc.text('3. Giới tính: ', xPos + 100, yPos)
  doc.text('Nam', xPos + 135, yPos)
  doc.text('Nữ', xPos + 160, yPos)
  if (infoBN.benhNhan.gioiTinh === 'Nam') {
    generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, false)
  } else {
    generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, true)
  }

  yPos += 7
  doc.text(`4. Điện thoại: ${infoBN.benhNhan.dienThoai ?? ''}`, xPos, yPos)
  doc.text(`5. Nghề nghiệp: ${infoBN.benhNhan.tenNgheNghiep ?? ''}`, xPos + 100, yPos)
  yPos += 7

  doc.text(`6. Dân tộc: ${infoBN.benhNhan.tenDanToc ?? ''}`, xPos, yPos)
  if (infoBN.benhNhan.idDanToc < 10) {
    // Set border color

    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(`${infoBN.benhNhan.idDanToc ?? '0'}`, xPos + 86, yPos)
  } else {
    //tach so
    let idDanToc = infoBN.benhNhan.idDanToc + ''
    let dotStrings = []
    for (let i = 0; i < idDanToc.length; i++) {
      dotStrings.push(idDanToc[i])
    }
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(dotStrings[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(dotStrings[1], xPos + 86, yPos)
  }

  doc.text(`7. Quốc tịch: ${infoBN.benhNhan.tenQuocTich ?? ''}`, xPos + 100, yPos)
  if (infoBN.benhNhan.idQuocTich < 10) {
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(`${infoBN.benhNhan.idQuocTich ?? '0'}`, pageWidth - 14, yPos)
  } else {
    //tach so
    let idQuocTich = infoBN.benhNhan.idQuocTich + ''
    while (idQuocTich.length < 3) {
      idQuocTich = '0' + idQuocTich
    }

    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[0], pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[1], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[2], pageWidth - 14, yPos)
  }

  yPos += 7
  doc.text(`8. Địa chỉ: ${infoBN.benhNhan.diaChi ?? ''}`, xPos, yPos)
  doc.text(`Xã phường: ${infoBN.benhNhan.tenPhuongXa ?? ''}`, xPos + 100, yPos)
  if (infoBN.benhNhan.idPhuong < 10) {
    doc.rect(pageWidth - 35, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 34, yPos)
    doc.rect(pageWidth - 30, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 29, yPos)
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBN.benhNhan.idPhuong + '', pageWidth - 14, yPos)
  } else {
    //tach so
    let idPhuongXa = infoBN.benhNhan.idPhuong + ''
    while (idPhuongXa.length < 5) {
      idPhuongXa = '0' + idPhuongXa
    }
    doc.rect(pageWidth - 35, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[0], pageWidth - 34, yPos)
    doc.rect(pageWidth - 30, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[1], pageWidth - 29, yPos)
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[2], pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[3], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[4], pageWidth - 14, yPos)
  }

  yPos += 7
  doc.text(`Huyện(Q, Tx): ${infoBN.benhNhan.tenQuanHuyen ?? ''}`, xPos, yPos)
  if (infoBN.benhNhan.idQuan < 10) {
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(infoBN.benhNhan.idQuan + '', xPos + 91, yPos)
  } else {
    //tach so
    let idQuanHuyen = infoBN.benhNhan.idQuan + ''
    while (idQuanHuyen.length < 3) {
      idQuanHuyen = '0' + idQuanHuyen
    }
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[1], xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[2], xPos + 91, yPos)
  }

  doc.text(`Tỉnh, thành phố: ${infoBN.benhNhan.tenTinhTP ?? ''}`, xPos + 100, yPos)
  if (infoBN.benhNhan.idTinh < 10) {
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBN.benhNhan.idTinh + '', pageWidth - 14, yPos)
  } else {
    //tach so
    let idQuanHuyen = infoBN.benhNhan.idTinh + ''
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[0], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[1], pageWidth - 14, yPos)
  }

  yPos += 7
  doc.text(`9. Đối tượng: `, xPos, yPos)

  doc.text(`BHYT`, xPos + 60, yPos)
  generateCheckbox(doc, xPos + 50, yPos - 3.5, 4, 4, false)

  doc.text(`Thu phí`, xPos + 85, yPos)
  generateCheckbox(doc, xPos + 80, yPos - 3.5, 4, 4, false)

  doc.text(`Miễn`, xPos + 110, yPos)
  generateCheckbox(doc, xPos + 105, yPos - 3.5, 4, 4, false)

  doc.text(`Khác`, xPos + 135, yPos)
  generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, true)

  yPos += 7

  doc.text(`10. Số thẻ BHYT: `, xPos, yPos)
  const dotLineBHYT = generateDot(doc, `10. Số thẻ BHYT:`, xPos, yPos)
  doc.text(dotLineBHYT.positionX, dotLineBHYT.yPos, dotLineBHYT.content)
  yPos += 7
  doc.text(
    `11. Số Căn cước/Hộ chiếu/Số định danh cá nhân: ${infoBN.benhNhan.cccd ?? ''}`,
    xPos,
    yPos,
  )
  const dotLineCC = generateDot(
    doc,
    `11. Số Căn cước/Hộ chiếu/Số định danh cá nhân: ${infoBN.benhNhan.cccd ?? ''}`,
    xPos,
    yPos,
  )
  doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  yPos += 7
  doc.text(
    `12. Họ và tên thân nhân của BN khi cần báo tin: ${infoBN.tennguoinha ?? ''}`,
    xPos,
    yPos,
  )
  doc.text(`Điện thoại: ${infoBN.dienthoainguoinha ?? ''}`, pageWidth - 55, yPos)
  yPos += 7
  doc.setFont('times', 'bold')
  doc.text('B. THÔNG TIN KHÁM BỆNH', xPos, yPos)
  yPos += 7
  doc.text('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ', xPos, yPos)
  doc.setFont('times', 'regular')
  const lydokham = infoBN.lydokham ?? ''
  if (doc.getTextWidth(lydokham) > 60) {
    const wordsArray = splitLongWord(lydokham, 55)
    // doc.text(
    //   wordsArray[0],
    //   doc.getTextDimensions('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ').w + 10,
    //   yPos,
    // )
    // doc.text(wordsArray[1], xPos, yPos + 4)
    // yPos += 4
    for (let index = 0; index < wordsArray.length; index++) {
      if (index === 0) {
        doc.text(
          wordsArray[index],
          doc.getTextDimensions('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ').w + 10,
          yPos,
        )
        yPos += 4
      } else {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 4
      }
    }
  } else {
    doc.text(lydokham, doc.getTextDimensions('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ').w + 12, yPos)
  }

  yPos += 7
  doc.setFont('times', 'bold')
  doc.text('II. HỎI BỆNH', xPos, yPos)
  yPos += 7
  doc.text('1. Quá trình bệnh lý, diễn biến lâm sàng: ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(
    ' (Đặc điểm khởi phát, triệu chứng lâm sàng, diễn biến bệnh...)',
    doc.getTextDimensions('1. Quá trình bệnh lý, diễn biến lâm sàng: ').w + 10,
    yPos,
  )
  yPos += 6
  doc.setFont('times', 'regular')
  const quatrinhbenh = infoBN.benhsu ?? ''

  if (doc.getTextWidth(quatrinhbenh) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(quatrinhbenh, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 6) {
      for (let i = 0; i < 6 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(quatrinhbenh, xPos, yPos)
    const dotLineCC = generateDot(doc, quatrinhbenh, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 5; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.setFont('times', 'bold')
  doc.text('2. Tiền sử bệnh:', xPos, yPos)
  yPos += 7
  doc.setFont('times', 'regular')
  doc.text('Dị ứng', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)

  if (!infoBN.diung) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 75, yPos)
      doc.text(xPos + 75, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const diung = infoBN.diung
    //beakword
    if (doc.getTextWidth(diung) > 60) {
      const wordsArray = splitLongWord(diung, 60)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 75, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 75, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(diung, xPos + 75, yPos)
      const dotLineCC = generateDot(doc, diung, xPos + 75, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 75, yPos)
      doc.text(xPos + 75, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 5
  doc.text('Bản thân', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)

  if (!infoBN.tiensubanthan) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 75, yPos)
      doc.text(xPos + 75, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const tiensubanthan = infoBN.tiensubanthan
    //beakword
    if (doc.getTextWidth(tiensubanthan) > 60) {
      const wordsArray = splitLongWord(tiensubanthan, 60)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 75, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 75, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(tiensubanthan, xPos + 75, yPos)
      const dotLineCC = generateDot(doc, tiensubanthan, xPos + 75, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 75, yPos)
      doc.text(xPos + 75, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 5
  doc.text('Gia đình', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)

  if (!infoBN.tieusugiadinh) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 75, yPos)
      doc.text(xPos + 75, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const tiensugiadinh = infoBN.tieusugiadinh
    //beakword
    if (doc.getTextWidth(tiensugiadinh) > 60) {
      const wordsArray = splitLongWord(tiensugiadinh, 60)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 75, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 75, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(tiensugiadinh, xPos + 75, yPos)
      const dotLineCC = generateDot(doc, tiensugiadinh, xPos + 75, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 75, yPos)
      doc.text(xPos + 75, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 4
  doc.setFont('times', 'bold')
  doc.text('III. KHÁM BỆNH', xPos, yPos)
  yPos += 7
  doc.text('1. Toàn thân: ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  doc.rect(pageWidth - 50, yPos, 42, 38)
  doc.setFont('times', 'italic')
  doc.setFontSize(11)
  doc.text(`Mạch: ${infoBN.mach ?? ''} lần/phút`, pageWidth - 48, yPos + 5)
  doc.text(`Nhiệt độ: ${infoBN.nhietdo ?? ''} °C`, pageWidth - 48, yPos + 11)
  doc.text(`Huyết áp: ${infoBN.huyetap ?? ''} mmHg`, pageWidth - 48, yPos + 17)
  doc.text(`Nhịp thở: ${infoBN.nhiptho ?? ''} lần/phút`, pageWidth - 48, yPos + 23)
  doc.text(`Cân năng: ${infoBN.cannang ?? ''} kg`, pageWidth - 48, yPos + 29)
  doc.text(`Chiều cao: ${infoBN.chieucao ?? ''} cm`, pageWidth - 48, yPos + 35)
  doc.setFont('times', 'regular')
  doc.setFontSize(12)
  if (!infoBN.toanthan) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    yPos += 6
    for (let i = 0; i < 7; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)

    const toanthan = infoBN.toanthan ?? ''
    //beakword
    yPos += 6
    if (doc.getTextWidth(toanthan) > 80) {
      const wordsArray = splitLongWord(toanthan, 80)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 6
      }
      const dotLineCC = generateDotWithWidth(
        doc,
        wordsArray[wordsArray.length - 1],
        xPos,
        yPos - 6,
        160,
      )
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      if (wordsArray.length < 7) {
        for (let i = 0; i < 7 - wordsArray.length; i++) {
          const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
          doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
          yPos += 6
        }
      }
    } else {
      doc.text(toanthan, xPos, yPos)
      const dotLineCC = generateDotWithWidth(doc, toanthan, xPos, yPos, 160)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      for (let i = 0; i < 6; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  }

  //page 2
  doc.addPage()
  yPos = 10
  doc.setFont('times', 'bold')
  doc.text('2. Các cơ quan: ', xPos, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text('Tuần hoàn', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  if (!infoBN.tuanhoan) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const tuanhoan = infoBN.tuanhoan ?? ''
    //beakword
    if (doc.getTextWidth(tuanhoan) > 40) {
      const wordsArray = splitLongWord(tuanhoan, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(tuanhoan, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, tuanhoan, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 2

  doc.text('Hô hấp:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)
  if (!infoBN.hohap) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const hohap = infoBN.hohap ?? ''
    //beakword
    if (doc.getTextWidth(hohap) > 40) {
      const wordsArray = splitLongWord(hohap, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(hohap, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, hohap, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Tiêu hóa:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)
  if (!infoBN.tieuhoa) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const tieuhoa = infoBN.tieuhoa
    //beakword
    if (doc.getTextWidth(tieuhoa) > 40) {
      const wordsArray = splitLongWord(tieuhoa, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(tieuhoa, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, tieuhoa, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Thận - tiết niệu:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  if (!infoBN.thantietnieu) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const thantietnieu = infoBN.thantietnieu ?? ''
    //beakword
    if (doc.getTextWidth(thantietnieu) > 40) {
      const wordsArray = splitLongWord(thantietnieu, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(thantietnieu, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, thantietnieu, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Thần kinh:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  if (!infoBN.thankinh) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const thankinh = infoBN.thankinh
    //beakword
    if (doc.getTextWidth(thankinh) > 40) {
      const wordsArray = splitLongWord(thankinh, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(thankinh, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, thankinh, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 2

  doc.text('Cơ - xương - khớp:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  if (!infoBN.coxuongkhop) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const coxuongkhop = infoBN.coxuongkhop ?? ''
    //beakword
    if (doc.getTextWidth(coxuongkhop) > 40) {
      const wordsArray = splitLongWord(coxuongkhop, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(coxuongkhop, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, coxuongkhop, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 2

  doc.text('Tai - mũi - họng:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  if (!infoBN.taimuihong) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const taimuihong = infoBN.taimuihong ?? ''
    //beakword
    if (doc.getTextWidth(taimuihong) > 40) {
      const wordsArray = splitLongWord(taimuihong, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(taimuihong, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, taimuihong, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 2

  doc.text('Răng - hàm - mặt:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)
  if (!infoBN.ranghammat) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const ranghammat = infoBN.ranghammat ?? ''
    //beakword
    if (doc.getTextWidth(ranghammat) > 40) {
      const wordsArray = splitLongWord(ranghammat, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(ranghammat, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, ranghammat, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Mắt:', xPos, yPos)
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  if (!infoBN.mat) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)
    const mat = infoBN.mat ?? ''
    //beakword
    if (doc.getTextWidth(mat) > 40) {
      const wordsArray = splitLongWord(mat, 40)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 115, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 115, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(mat, xPos + 115, yPos)
      const dotLineCC = generateDot(doc, mat, xPos + 115, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 115, yPos)
      doc.text(xPos + 115, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  const benhlykhac = `Bệnh lý khác: ${infoBN.benhlykhac ?? ''}`
  if (doc.getTextWidth(benhlykhac) > 150) {
    const wordsArray = splitLongWord(benhlykhac, 90)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineBenhLy = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineBenhLy.positionX, dotLineBenhLy.yPos, dotLineBenhLy.content)
  } else {
    doc.text(benhlykhac, xPos, yPos)
    const dotLineBenhLy = generateDot(doc, benhlykhac, xPos, yPos)
    doc.text(dotLineBenhLy.positionX, dotLineBenhLy.yPos, dotLineBenhLy.content)
    yPos += 6
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 6
  }

  doc.setFont('times', 'bold')
  doc.text('3. Tóm tắt các xét nghiệm, cận lâm sàng', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    '(có giá trị chẩn đoán, theo dõi bệnh):',
    xPos + 5 + doc.getTextDimensions('3. Tóm tắt các xét nghiệm, cận lâm sàng').w,
    yPos,
  )
  yPos += 6
  const kqcls = `${infoBN.kqcls ?? ''}`
  if (doc.getTextWidth(kqcls) > 180) {
    const wordsArray = splitLongWord(kqcls, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 4) {
      for (let i = 0; i < 4 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(kqcls, xPos, yPos)
    const dotLineCC = generateDot(doc, kqcls, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 3; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 2

  doc.setFont('times', 'bold')
  doc.text('4. Chẩn đoán lúc vào viện ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    '(Tên bệnh kèm theo mã ICD):',
    xPos + 5 + doc.getTextDimensions('4. Chẩn đoán lúc vào viện ').w,
    yPos,
  )
  doc.rect(pageWidth - 50, yPos - 4, 25, 6)
  doc.text(
    `${icdObj.chandoanbandau ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.chandoanbandau ?? '')) / 2,
    yPos + 0.6,
  )
  yPos += 7

  let chandoanbandau = `${infoBN.chandoanbandau ?? ''}`
  if (chandoanbandau.endsWith('/')) {
    chandoanbandau = chandoanbandau.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(chandoanbandau) > 180) {
    const wordsArray = splitLongWord(chandoanbandau, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(chandoanbandau, xPos, yPos)
    const dotLineCC = generateDot(doc, chandoanbandau, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 6
  }

  doc.setFont('times', 'bold')
  doc.text('5. Đã xử lý  ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text('(thuốc, chăm sóc):', xPos + 5 + doc.getTextDimensions('5. Đã xử lý').w, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const daxuly = `${infoBN.daxuly ?? ''}`
  if (doc.getTextWidth(daxuly) > 180) {
    const wordsArray = splitLongWord(daxuly, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(daxuly, xPos, yPos)
    const dotLineCC = generateDot(doc, daxuly, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 6
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos + 6, dotLineEmpty.content)
    yPos += 6
  }
  yPos += 2

  doc.setFont('times', 'bold')
  doc.text('6. Chẩn đoán khi ra viện  ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    '(Tên bệnh kèm theo mã ICD):',
    xPos + 5 + doc.getTextDimensions('6. Chẩn đoán khi ra viện ').w,
    yPos,
  )
  doc.rect(pageWidth - 50, yPos - 4, 25, 6)
  doc.text(
    `${icdObj.chandoanravien ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.chandoanravien ?? '')) / 2,
    yPos + 0.6,
  )
  yPos += 7

  let chandoanravien = `${infoBN.chandoanravien ?? ''}`
  if (chandoanravien.endsWith('/')) {
    chandoanravien = chandoanravien.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(chandoanravien) > 180) {
    const wordsArray = splitLongWord(chandoanravien, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(chandoanravien, xPos, yPos)
    const dotLineCC = generateDot(doc, chandoanravien, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 6
  }

  doc.setFont('times', 'bold')
  doc.text('7. Điều trị ngoại trú', xPos, yPos)
  doc.setFont('times', 'italic')
  const dieutringoaitru = infoBN.dieutringoaitru ?? ''
  if (doc.getTextWidth(dieutringoaitru) > 120) {
    const wordsArray = splitLongWord(dieutringoaitru, 90)
    for (let index = 0; index < wordsArray.length; index++) {
      if (index === 0) {
        doc.text(wordsArray[index], doc.getTextDimensions('7. Điều trị ngoại trú').w + 15, yPos)
        yPos += 5
      } else {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 5
      }
    }
  } else {
    doc.text(dieutringoaitru, xPos, yPos)
    yPos += 5
  }
  const formattedDateTime = `Ngày ${moment(infoBN.ngaykham).format('DD')} tháng ${moment(infoBN.ngaykham).format('MM')} năm ${moment(infoBN.ngaykham).format('YYYY')}`

  const dateTextWidth = doc.getTextWidth(formattedDateTime)

  const dateTextX = pageWidth - dateTextWidth - 35

  doc.text(formattedDateTime, dateTextX, yPos)
  yPos += 5
  doc.setFont('times', 'bold')
  doc.text(pageWidth - 37 - 'Đại diện cơ sở KB, CB'.length * 2, yPos, 'Đại diện cơ sở KB, CB')
  doc.text(xPos + 20, yPos, 'Bác sỹ khám bệnh')
  doc.setFont('times', 'italic')
  yPos += 5
  doc.text(pageWidth - 44 - '(Ký, đóng dấu)'.length * 2, yPos, '(Ký, đóng dấu)')
  doc.text(xPos + 20, yPos, '(Ký, ghi rõ họ tên)')
  doc.setFont('times', 'bold')
  doc.text(xPos + 15, yPos + 20, infoBN.bskham ?? '')

  //page 3
  doc.addPage()
  yPos = 10
  doc.setFont('times', 'bold')
  doc.text('C. TỔNG KẾT BỆNH ÁN:', xPos, yPos)
  yPos += 7
  doc.text('1. Tóm tắt quá trình bệnh lý và diễn biến lâm sàng', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(
    doc.getTextDimensions('1. Tóm tắt quá trình bệnh lý và diễn biến lám sàng').w + xPos + 5,
    yPos,
    ' (Đặc điểm khởi phát, triệu chứng lâm sàng và diễn biến ',
  )
  yPos += 5
  doc.text('bệnh...):', xPos, yPos)
  yPos += 6
  doc.setFont('times', 'regular')
  const ttquatrinhbenh = `${infoBN.benhsu ?? ''} ${infoBN.quatrinhbenhly ?? ''} ${infoBN.dienbienlamsang ?? ''}`
  if (doc.getTextWidth(ttquatrinhbenh) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(ttquatrinhbenh, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 6) {
      for (let i = 0; i < 6 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(ttquatrinhbenh, xPos, yPos)
    const dotLineCC = generateDot(doc, ttquatrinhbenh, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 5; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 2

  doc.setFont('times', 'bold')
  doc.text('2. Tóm tắt kết quả xét nghiệm, cận lâm sàng có giá trị chẩn đoán:', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  if (doc.getTextWidth(kqcls) > 180) {
    const wordsArray = splitLongWord(kqcls, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], 0, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 5) {
      for (let i = 0; i < 5 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(kqcls, xPos, yPos)
    const dotLineCC = generateDot(doc, kqcls, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 4; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.setFont('times', 'bold')
  doc.text('3. Chẩn đoán khi ra viện (Tên bệnh kèm theo mã ICD):', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 5
  doc.rect(pageWidth - 50, yPos - 3, 25, 5)
  doc.text(
    `${icdObj.chandoan ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.chandoan ?? '')) / 2,
    yPos + 0.6,
  )

  let chandoan = `Bệnh chính: ${infoBN.chandoan ?? ''}`
  if (chandoan.endsWith('/')) {
    chandoan = chandoan.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(chandoan) > 150) {
    const wordsArray = splitLongWord(chandoan, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(chandoan, xPos, yPos)
    yPos += 7
  }
  doc.rect(pageWidth - 50, yPos - 3, 25, 5)
  doc.text(
    `${icdObj.benhkemtheo ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.benhkemtheo ?? '')) / 2,
    yPos + 0.6,
  )

  let benhkemtheo = `Bệnh kèm theo(nếu có): ${infoBN.benhkemtheo ?? ''}`
  if (benhkemtheo.endsWith('/')) {
    benhkemtheo = benhkemtheo.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(benhkemtheo) > 150) {
    const wordsArray = splitLongWord(benhkemtheo, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(benhkemtheo, xPos, yPos)
    yPos += 7
  }
  doc.rect(pageWidth - 50, yPos - 3, 25, 5)
  doc.text(
    `${icdObj.bienchung ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.bienchung ?? '')) / 2,
    yPos + 0.6,
  )

  let bienchung = `Biến chứng(nếu có): ${infoBN.bienchung ?? ''}`
  if (bienchung.endsWith('/')) {
    bienchung = bienchung.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(bienchung) > 150) {
    const wordsArray = splitLongWord(bienchung, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(bienchung, xPos, yPos)
    yPos += 7
  }

  doc.setFont('times', 'bold')
  doc.text('4. Phương pháp điều trị (tương ứng với chẩn đoán):', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const ppdieutri = infoBN.ppdieutri ?? ''
  if (doc.getTextWidth(ppdieutri) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(ppdieutri, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 4) {
      for (let i = 0; i < 4 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(ppdieutri, xPos, yPos)
    const dotLineCC = generateDot(doc, ppdieutri, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 3; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  doc.setFont('times', 'bold')
  doc.text('5. Tình trạng ra viện:', xPos, yPos)
  yPos += 7
  doc.setFont('times', 'regular')
  doc.text('Khỏi', xPos + 5, yPos)
  generateCheckbox(doc, xPos, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 1 ? true : false)

  doc.text('Đỡ', xPos + 22, yPos)
  generateCheckbox(doc, xPos + 17, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 2 ? true : false)

  doc.text('Không thay đổi', xPos + 38, yPos)
  generateCheckbox(doc, xPos + 33, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 3 ? true : false)

  doc.text('Nặng hơn', xPos + 72, yPos)
  generateCheckbox(doc, xPos + 67, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 4 ? true : false)

  doc.text('Tử vong', xPos + 97, yPos)
  generateCheckbox(doc, xPos + 92, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 5 ? true : false)

  doc.text('Tiên lượng nặng xin về', xPos + 120, yPos)
  generateCheckbox(doc, xPos + 115, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 6 ? true : false)

  doc.text('Chưa xác định', xPos + 170, yPos)
  generateCheckbox(doc, xPos + 165, yPos - 3.5, 4, 4, infoBN.tinhtrangravien === 7 ? true : false)

  doc.setFont('times', 'bold')
  yPos += 7
  doc.text('6. Hướng điều trị và các chế độ tiếp theo:', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const huongdieutri = `${infoBN.huongxuly ?? ''}`
  if (doc.getTextWidth(huongdieutri) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(huongdieutri, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 4) {
      for (let i = 0; i < 4 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(huongdieutri, xPos, yPos)
    const dotLineCC = generateDot(doc, huongdieutri, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 3; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 5
  doc.setFont('times', 'italic')
  const formattedDateTimeEnd = `Ngày ${moment(infoBN.ngaykham).format('DD')} tháng ${moment(infoBN.ngaykham).format('MM')} năm ${moment(infoBN.ngaykham).format('YYYY')}`

  const dateEndTextWidth = doc.getTextWidth(formattedDateTime)

  const dateEndTextX = pageWidth - dateEndTextWidth - 35

  doc.text(formattedDateTimeEnd, dateEndTextX, yPos)
  yPos += 5
  doc.setFont('times', 'bold')
  doc.text(pageWidth - 40 - 'Bác sĩ điều trị'.length * 2, yPos, 'Bác sĩ điều trị')
  doc.setFont('times', 'italic')
  yPos += 5
  doc.text(pageWidth - 73, yPos, '(Ký, ghi rõ họ tên)')
  doc.setFont('times', 'bold')
  const doctorText = infoBN.bskham ?? ''
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateCenterX = dateTextX + dateTextWidth / 2
  const doctorTextX = dateCenterX - doctorTextWidth / 2
  doc.text(doctorText, doctorTextX, yPos + 20)

  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
