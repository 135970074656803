import { produce } from "immer";
import * as typeAction from "../constants/constants";
const initialState = {
    infoLoaiThe: null,
    listTheDichVu: null,
    listDichVuTheoNhom: null,
    listXN: null,
    listCDHA: null,
};
const detailCartReducer = (state = initialState, { type, payload }) => {
    return produce(state, (draft) => {
        switch (type) {
            case typeAction.RESET_STORE:
                return initialState; // reset store khi logOut
            case typeAction.DISPATCH_INFO_LOAITHE:
                draft.infoLoaiThe = payload;
                break;
            case typeAction.DISPATCH_LIST_THE_DICH_VU:
                draft.listTheDichVu = payload;
                break;
            case typeAction.DISPATCH_LIST_DICH_VU_LOAITHE:
                draft.listDichVuTheoNhom = payload;
                break;
            case typeAction.DISPATCH_LIST_XET_NGHIEM_LOAITHE:
                draft.listXN = payload;
                break;
            case typeAction.DISPATCH_LIST_CDHA_LOAITHE:
                draft.listCDHA = payload;
                break;
            default:
                return state;
        }
    });
};

export default detailCartReducer;
