import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useSelector, useDispatch } from 'react-redux'

const headCells = [
  {
    id: 'stt',
    numeric: true,
    disablePadding: true,
    label: 'STT',
    width: '5%',
  },
  {
    id: 'mahang',
    numeric: true,
    disablePadding: true,
    label: 'Mã ',
    width: '20%',
  },
  {
    id: 'tenhang',
    numeric: false,
    disablePadding: false,
    label: 'Tên hàng hoá',
    width: '50%',
  },
  {
    id: 'quycach',
    numeric: false,
    disablePadding: false,
    label: 'Quy cách',
    width: '25%',
  },
]

const TableThuoc = ({ page, SetSearchParams, setInfoThuoc }) => {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const { listThuocVT } = useSelector((state) => state.thuocVTReducer)

  const handleClick = (event, idThuoc, maThuoc, tenBietDuoc, quyCach) => {
    setInfoThuoc({
      idThuoc,
      maThuoc,
      tenBietDuoc,
      quyCach,
    })
  }

  const handleChangePage = (event, newPage) => {
    SetSearchParams({ page: ++newPage })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer sx={{ height: 658 }}>
          <Table stickyHeader aria-labelledby="tableTitle" size={'small'}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    style={{ width: headCell.width }}
                    sx={{ border: '1px solid #EEEEEE', background: '#DCEAF3' }}
                    key={headCell.id}
                    align={headCell.numeric ? 'center' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listThuocVT?.data.map((row, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(event, row.idThuoc, row.maThuoc, row.tenBietDuoc, row.quyCach)
                    }
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="center">{row.stt}</TableCell>
                    <TableCell
                      component="th"
                      sx={{ borderRight: '1px solid #EEEEEE', borderLeft: '1px solid #EEEEEE' }}
                      align="center"
                      scope="row"
                      padding="none"
                    >
                      {row.maThuoc}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: '1px solid #EEEEEE', borderLeft: '1px solid #EEEEEE' }}
                    >
                      {row.tenBietDuoc}
                    </TableCell>
                    <TableCell align="left">{row.quyCach}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPage={listThuocVT?.pageSize}
          page={page - 1}
          count={listThuocVT?.totalCount}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
        />
      </Paper>
    </Box>
  )
}

export default TableThuoc
