import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deletePhieuKho } from '../../../../store/actions/toolAction'

const ModalDeLetePhieuKho = ({ openPhieuKho, SetPhieuKho }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(null)

  const hanldeSubmit = () => {
    dispatch(deletePhieuKho(value,reset))
  }
  const handleCancel = () => {
    SetPhieuKho(false)
  }
  const reset = () => {
    setValue(null)
    handleCancel()
  }
  return (
    <Modal
      onOk={hanldeSubmit}
      okText="Xoá phiếu"
      cancelText="Huỷ bỏ"
      title="Xoá phiếu kho"
      open={openPhieuKho}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: !value,
      }}
    >
      <div className="grid grid-flow-row items-center gap-2 pt-2 pb-2">
        <div>
          <label className="font-semibold text-base">Mã phiếu kho</label>
          <Input
            placeholder="Nhập mã phiếu thu PT..."
            name="maphieuthu"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeLetePhieuKho
