import { Select, Modal, Button, notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import TableTaiKham from './TableTaiKham'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUuDai, getDichVu, postUuDai } from '../../../../store/actions/toolAction'
const ModalTaiKham = ({ openTK, setOpenTK }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState([])
  const [services, setServices] = useState([])
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenTK(false)
  }
  const openNotificationWithIcon = () => {
    api.warning({
      message: 'Thêm dịch vụ',
      description: 'Dịch vụ ưu đãi đã được chọn.',
    })
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(getDichVu(81, keyword, setServices)) // 81 ID công ty CVhealth24
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fetchData = () => {
    dispatch(getAllUuDai(setData))
  }
  const handleAddUuDai = (value, option) => {
    for (let i of data) {
      if (i.iddv === value) {
        // check xem dịch vụ đã có hay chưa
        openNotificationWithIcon()
        return
      }
    }
    const form = {
      iddv: value,
      idchuyenkhoa: option.idchuyenkhoa,
      ptgiamgia: 100,
      nguoitao: infoUser?.idnv,
    }
    dispatch(postUuDai(form, fetchData))
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
    {contextHolder}
    <Modal
      width={'50%'}
      footer={false}
      title={
        <div className="flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>
          <h2 className="font-semibold text-lg">Chương trình tái khám</h2>
        </div>
      }
      onCancel={handleCancel}
      open={openTK}
    >
      <div className="mt-5">
        <div className="border p-2 rounded bg-sky-100">
          <p>-Đến khám bệnh trong 1 năm kể từ ngày khai trương</p>
          <p>-Đến phòng khám lần đầu (tạo mới bệnh nhân)</p>
          <p>-Được tái khám miễn phí 3 lần trong 1 đợt bệnh</p>
          <p>-Bệnh nhân nhận ưu đãi bắt đầu từ ngày tạo dịch vụ</p>
        </div>
        <div className="mt-5">
          <p className="text-red-500">(*) Đang lấy mặc định dịch vụ của công ty CVhealth24 dùng chung</p>
          <div className="flex gap-2">
            <Select
              onSearch={debounceDropDown}
              value={null}
              onChange={handleAddUuDai}
              filterOption={false}
              notFoundContent={null}
              placeholder="Nhập tên hoặc mã dịch vụ"
              suffixIcon={<SearchOutlined className=" " />}
              options={services?.map((items) => ({
                label: items.tenDichVu,
                value: items.iddv,
                maDichVu: items.maDichVu,
                idchuyenkhoa: items.idChuyenKhoa,
              }))}
              optionRender={(options) => (
                <h2>
                  {options.data.maDichVu} - {options.data.label}
                </h2>
              )}
              showSearch
              allowClear
              className="w-full"
            />
            <Button onClick={fetchData} type="primary">
              Tải lại
            </Button>
          </div>

          <TableTaiKham data={data} setData={setData} fetchData={fetchData} />
        </div>
      </div>
    </Modal>
    </>
    
  )
}

export default ModalTaiKham
