import * as typeAction from '../constants/constants'
export const phieuThuCashier = (form, to, idChiNhanh, setIsLoading, branch) => ({
  type: typeAction.GET_DANH_SACH_PHIEU_THU,
  form,
  to,
  idChiNhanh,
  setIsLoading,
  branch,
})
export const detailBNCashier = (idbn, idbntt, idbnttc, idChuyenKhoa) => ({
  type: typeAction.GET_INFO_PT_CASHIER,
  idbn,
  idbntt,
  idbnttc,
  idChuyenKhoa,
})
export const getListDiscountCashier = (listDV) => ({
  type: typeAction.GET_LIST_DISCOUNT_CASHIER,
  listDV,
})
export const removeListDiscountCashier = (listDV) => ({
  type: typeAction.REMOVE_LIST_DISCOUNT_CASHIER,
  listDV,
})
export const setChuongTrinhGiamGiaDoiTacCashier = (form) => ({
  type: typeAction.SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC_CASHIER,
  form,
})
export const postApplyMemberCardCashierAction = (form, cd) => ({
  type: typeAction.POST_CASHIER_APPLY_MEMBER_CARD,
  form,
  cd,
})
export const putPhieuThuAction = (form, idcn, formPrint) => ({
  type: typeAction.PUT_PHIEUTHU_CASHIER,
  form,
  idcn,
  formPrint,
})
export const getListPhieuDaThuCashier = (form, to, idChiNhanh) => ({
  type: typeAction.GET_DANH_SACH_PHIEU_THU_CASHIER,
  form,
  to,
  idChiNhanh,
})
export const updateServiceBN = (form, idbntt, idnv, resetCheckDelete) => ({
  type: typeAction.UPDATE_SERVICE_BN,
  form,
  idbntt,
  idnv,
  resetCheckDelete,
})
export const updatePTBenhNhan = (data, idbntt, resetUpdate, tiengiamdoitac, idnv, idbnttc) => ({
  type: typeAction.UPDATE_SERVICE_PHIEU_THU,
  data,
  idbntt,
  resetUpdate,
  tiengiamdoitac,
  idnv,
  idbnttc,
})
export const updateDetailPayment = (form, setShow, idbntt,benhNhanTTC,tienGiamUpdate) => ({
  type: typeAction.UPDATE_CHI_TIET_THANH_TOAN_PT,
  form,
  setShow,
  idbntt,
  benhNhanTTC,
  tienGiamUpdate
})
export const updateGhiChuHD = (form) => ({
  type: typeAction.UPDATE_BNTT_GHI_CHU_HD,
  form,
})
export const CalculateServiceCustomer_ByMemberCard = (idthe, idbnttc) => ({
  type: typeAction.CALCULATOR_SERVICE_BY_MEMBERCARD,
  idthe,
  idbnttc,
})
// export const putBenhNhanThanhToanAction = (form) => ({
//   type: typeAction.PUT_BNTT_CASHIER,
//   form,
// })
// export const putBenhNhanhChiTietTTAction = (form, idbntt) => ({
//   type: typeAction.PUT_BN_CHITIET_TT_CASHIER,
//   form,
//   idbntt,
// })
// export const putBenhNhanListDVAction = (idbnttc, list) => ({
//   type: typeAction.PUT_LIST_DV_CASHIER,
//   idbnttc,
//   list,
// })
// export const putBenhNhanListXNAction = (idbnttc, list) => ({
//   type: typeAction.PUT_LIST_XN_CASHIER,
//   idbnttc,
//   list,
// })
// export const putBenhNhanListCDHAAction = (idbnttc, list) => ({
//   type: typeAction.PUT_LIST_CDHA_CASHIER,
//   idbnttc,
//   list,
// })
// export const postTruTienTheThanhToanAction = (form) => ({
//   type: typeAction.POST_TTT_CASHIER,
//   form,
// })
