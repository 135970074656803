import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Space,
  Table,
  Tabs,
  Tooltip,
  Segmented,
} from 'antd'
import { https } from '../../../../services/apiService'
import { cloneDeep, debounce } from 'lodash'
import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { recieveService } from '../../../../services/receive/recieveService'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')

function DichVuGiamGia({ dataDichVu, setDataDichVu }) {
  const KB = 'Khám bệnh'
  const XN = 'Xét nghiệm'
  const CDHA = 'Chẩn đoán hình ảnh'
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [valueSegmented, setValueSegmented] = useState(KB)
  const [loadingSearching, setLoadingSearching] = useState(false)
  const [dataInTableDV, setDataInTableDV] = useState([])
  const [searchText, setSearchText] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [filterActive, setFilterActive] = useState(false)
  const [listTTV, setListTTV] = useState([])
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const [listXNhoacCDHA, setListXNhoacCDHA] = useState([])
  const ID_COMPANY = Number(localStorage.getItem('id_company'))

  const fetchAllXetNghiem = async () => {
    try {
      setLoadingSearching(true)
      const { data } = await recieveService.getListXetNghiemTiepNhan(PkDangNhap?.idChiNhanh)
      setListXNhoacCDHA(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }
  const fetchAllCDHA = async () => {
    try {
      setLoadingSearching(true)
      const { data } = await recieveService.getListCDHATiepNhan(ID_COMPANY)
      setListXNhoacCDHA(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columnsDichVuChoose = [
    {
      title: <p className="text-center">Mã dịch vụ</p>,
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      align: 'left',
      className: 'text-sm',
      width: 200,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      align: 'left',
      className: 'text-sm',
    },
    // {
    //   title: 'Đơn vị tính',
    //   dataIndex: 'donVi',
    //   key: 'donVi',
    //   align: 'center',
    //   className: 'text-sm',
    // },
    // {
    //   title: 'Đơn giá',
    //   dataIndex: 'donGia',
    //   key: 'donGia',
    //   className: 'text-sm',
    //   align: 'right',
    //   render: (text, record) => {
    //     return formatNumberVND(record.donGia)
    //   },
    // },
    // {
    //   title: 'Giá thay đổi',
    //   dataIndex: 'giaThayDoi',
    //   key: 'giaThayDoi',
    //   className: 'text-sm',
    //   align: 'right',
    //   render: (text, record, index) => (
    //     <Input
    //       step={1}
    //       min={0}
    //       className="text-right"
    //       type="number"
    //       onChange={onInputChange(index, record)}
    //     />
    //   ),
    // },

    {
      title: 'ĐVT',
      dataIndex: 'donVi',
      key: 'donVi',
      align: 'center',
      className: 'text-sm',
      width: 55,
    },
    {
      title: <p className="text-center">Đơn giá</p>,
      dataIndex: 'donGia',
      key: 'donGia',
      className: 'text-sm',
      align: 'right',
      render: (text, record) => {
        return record.donGia ? formatNumberVND(record.donGia) : ''
      },
      width: 100,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      className: 'text-sm',
    },
  ]

  const columnsXNhoacCDHA = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      width: 200,
      ...getColumnSearchProps('maDichVu'),
      sorter: {
        compare: (a, b) => {
          return (a?.maDichVu ?? '').localeCompare(b?.maDichVu ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      sorter: {
        compare: (a, b) => {
          return (a?.tenDichVu ?? '').localeCompare(b?.tenDichVu ?? '')
        },
      },
      ...getColumnSearchProps('tenDichVu'),
    },
    {
      title: <p className="text-sm">Nhóm</p>,
      dataIndex: 'tennhom',
      key: 'tennhom',
      width: 140,
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tennhom ?? '').localeCompare(b?.tennhom ?? '')
        },
      },
      className: 'text-xs',
      render: (text, record, index) =>
        (text ?? '')?.toLowerCase()?.replace('xét nghiệm', '')?.trim().toUpperCase(),
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return Number(a?.price ?? 0) - Number(b?.price ?? 0)
        },
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) => (
        <PlusOutlined
          onClick={() => handleAddDichVu(record)}
          className="text-xl text-blue-500 cursor-pointer"
        />
      ),
    },
  ]
  const columnsDichVu = [
    {
      title: <p className="text-center">Mã dịch vụ</p>,
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      align: 'left',
      className: 'text-sm',
      width: 200,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      align: 'left',
      className: 'text-sm',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder="Search name"
            value={searchText}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              setSearchText(e.target.value)
            }}
            onPressEnter={() => handleSearchDV(selectedKeys, confirm, 'name')}
            className="mb-3 w-full"
          />
          <div className="flex">
            <Button
              type="danger"
              onClick={() => handleResetDV(clearFilters)}
              className="bg-red-500 text-white w-2/4"
            >
              Hủy
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearchDV(selectedKeys, confirm, 'name')}
              className="bg-blue-500 ml-2 text-white w-2/4"
            >
              Tìm
            </Button>
          </div>
        </div>
      ),
      filterIcon: (filtered) => {
        return (
          <SearchOutlined
            style={{ color: filterActive ? '#1890ff' : '#333333' }}
            className="text-xl"
          />
        )
      },
      render: (text, record) => <p className={`${record.children ? 'font-medium' : ''}`}>{text}</p>,
    },
    {
      title: 'ĐVT',
      dataIndex: 'donVi',
      key: 'donVi',
      align: 'center',
      className: 'text-sm',
      width: 55,
    },
    {
      title: <p className="text-center">Đơn giá</p>,
      dataIndex: 'donGia',
      key: 'donGia',
      className: 'text-sm',
      align: 'right',
      render: (text, record) => {
        return record.donGia ? formatNumberVND(record.donGia) : ''
      },
      width: 100,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return record.children ? null : (
          <PlusOutlined
            onClick={() => handleAddDichVu(record)}
            className="text-xl text-blue-500 cursor-pointer"
          />
        )
      },
      width: 40,
    },
  ]

  const columnsTTV = [
    {
      title: 'Loại thẻ',
      dataIndex: 'loaithe',
      key: 'loaithe',
      align: 'center',
      className: 'text-sm',
      ...getColumnSearchProps('loaithe'),
    },
    {
      title: 'Tiền sử dụng',
      dataIndex: 'tiensudung',
      key: 'tiensudung',
      align: 'center',
      className: 'text-sm',
      render: (text) => formatNumberVND(text),
    },
    {
      title: 'Từ ngày',
      dataIndex: 'tungay',
      key: 'tungay',
      align: 'center',
      className: 'text-sm',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Đến ngày',
      dataIndex: 'denngay',
      key: 'denngay',
      align: 'center',
      className: 'text-sm',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 40,
    },
  ]

  const debounceSearch = useCallback(
    debounce((keyword) => {
      ;(async () => {
        setLoadingSearching(true)
        try {
          const listKhoa = await https.get('ChuyenKhoa/AllChuyenKhoa')
          const resultSearch = await https.get(`DichVu/Mobile/SearchDichVu?keyword=${keyword}`)
          const formatData = listKhoa.data.map((item) => ({
            key: item.idChuyenKhoa,
            maDichVu: '',
            tenDichVu: `${item.maChuyenKhoa} - ${item.tenChuyenKhoa}`,
            donVi: '',
            donGia: '',
            children: resultSearch.data
              .filter((val) => val.idChuyenKhoa === item.idChuyenKhoa)
              .map((val) => ({
                // key: val.iddv,
                // maDichVu: val.maDichVu,
                // tenDichVu: val.tenDichVu,
                // donVi: val.donVi,
                // donGia: val.donGia,
                key: `${val.iddv}${val.maDichVu}`, //val.iddv,
                iddv: val.iddv,
                maDichVu: val.maDichVu,
                tenDichVu: val.tenDichVu,
                donVi: val.donVi,
                donGia: val.donGia,
              })),
          }))
          const allKeys = formatData.map((item) => item.key)
          setExpandedRowKeys(allKeys)
          //format data
          setDataInTableDV(formatData)
        } catch (error) {
          console.log(error)
        } finally {
          setLoadingSearching(false)
        }
      })()
    }, 500),
    [],
  )

  const handleSearchDV = (selectedKeys, confirm, dataIndex) => {
    if (searchText) {
      confirm()
      setSearchText(searchText)
      debounceSearch(searchText)
      setFilterActive(true)
      return
    }
    ToastCus.fire({
      icon: 'error',
      title: 'Vui lòng nhập tên dịch vụ cần tìm',
    })
  }

  const handleResetDV = (clearFilters) => {
    clearFilters()
    setSearchText('')
    // debounceSearch('')
    setExpandedRowKeys([])
    getAllChuyenKhoa()
    setFilterActive(false)
    //reset data in table
  }

  const getAllChuyenKhoa = async () => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      //format data
      const formatData = data.map((item) => ({
        key: item.idChuyenKhoa, //`${item.idChuyenKhoa}${item.maChuyenKhoa}`,//''+item.idChuyenKhoa+item.maChuyenKhoa,
        maDichVu: '',
        tenDichVu: `${item.maChuyenKhoa} - ${item.tenChuyenKhoa}`,
        donVi: '',
        donGia: '',
        children: [],
      }))
      setDataInTableDV(formatData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  const getDichVuByIdNhom = async (idNhom) => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get(`DichVu/Mobile/GetDichVuByIdChuyenKhoa/${idNhom}`)
      //set data in table
      const newDataInTable = cloneDeep(dataInTableDV)
      const idx = newDataInTable.findIndex((item) => item.key === idNhom)
      newDataInTable[idx].children = data.map((val) => ({
        key: `${val.iddv}${val.maDichVu}`, //val.iddv,
        iddv: val.iddv,
        maDichVu: val.maDichVu,
        tenDichVu: val.tenDichVu,
        donVi: val.donVi,
        donGia: val.donGia,
      }))
      setDataInTableDV(newDataInTable)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  const handleExpand = (expanded, record) => {
    if (searchText) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key])
      getDichVuByIdNhom(record.key)
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key))
    }
  }

  const handleAddDichVu = (record) => {
    let idloaidvtemp =
      (valueSegmented === KB && 1) || (valueSegmented === XN && 2) || (valueSegmented === CDHA && 3)
    const idx = dataDichVu.findIndex(
      (item) =>
        (item.key === record.key || item.iddv === record.iddv) && item.idloaidv === idloaidvtemp,
    )

    if (idx !== -1) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ đã được chọn',
      })
      return
    }
    setDataDichVu((prev) => [
      ...prev,
      {
        ...record,
        // iddv: record.key,
        idloaidv: idloaidvtemp,
      },
    ])
    // if (valueSegmented === KB) {
    //   //add record

    // } else {

    // }
  }

  const handleDeleteInTable = (record) => {
    setDataDichVu((prev) => prev.filter((item) => item.key !== record.key))
  }

  useEffect(() => {
    getAllChuyenKhoa()
  }, [])
  useEffect(() => {
    if (valueSegmented === XN) fetchAllXetNghiem()
    if (valueSegmented === CDHA) fetchAllCDHA()
  }, [valueSegmented])
  // useEffect(() => {
  //   ; (async () => {
  //     try {
  //       const { data } = await https.get(`TheLoai/SearchLoaiThe`)
  //       setListTTV(data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [])

  return (
    <div>
      <div className="overflow-auto h-[265px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            // loading={loadingTable}
            scroll={{ y: 180 }}
            bordered
            pagination={false}
            columns={columnsDichVuChoose}
            dataSource={
              dataDichVu.map((item) => ({
                ...item,
                action: (
                  <ul className="flex gap-2 justify-around">
                    <li className="text-lg text-red-500">
                      <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                    </li>
                  </ul>
                ),
              })) || []
            }
          />
        </ConfigProvider>
      </div>
      <div>
        <Segmented
          onChange={(value) => setValueSegmented(value)}
          options={[KB, XN, CDHA]}
          className="mb-3"
          block
        />
        <div className="overflow-auto h-[220px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            {valueSegmented === KB ? (
              <>
                <Table
                  scroll={{ y: 180 }}
                  loading={loadingSearching}
                  bordered
                  pagination={false}
                  columns={columnsDichVu}
                  dataSource={dataInTableDV}
                  expandable={{ onExpand: handleExpand, expandedRowKeys: expandedRowKeys }}
                />
              </>
            ) : (
              <>
                <Table
                  scroll={{ y: 180 }}
                  loading={loadingSearching}
                  bordered
                  pagination={false}
                  columns={columnsXNhoacCDHA}
                  dataSource={listXNhoacCDHA?.map((item) => ({
                    key: `${item?.xetNghiem?.idxn ?? item?.idChiDinh}${item?.xetNghiem?.maxn ?? item?.macdha}`,
                    iddv: item?.xetNghiem?.idxn ?? item?.idChiDinh,
                    maDichVu: item?.xetNghiem?.maxn ?? item?.macdha,
                    tenDichVu: item?.xetNghiem?.tenxn ?? item?.tenChiDinh,
                    donVi: item?.xetNghiem?.dvt ?? item?.dvt, //'',
                    donGia: item?.xetNghiem?.dongia ?? item?.donGia ?? 0,
                    tennhom: (item?.tenNhom ?? item?.tennhom ?? '')
                      ?.toLowerCase()
                      ?.replace('xét nghiệm', '')
                      ?.trim()
                      .toUpperCase(),
                    price: formatNumberVND(item?.xetNghiem?.dongia ?? item?.donGia ?? 0),
                    // itemCD: item,
                  }))}
                />
              </>
            )}
          </ConfigProvider>
        </div>
        {/* <Tabs
          className="h-full "
          defaultActiveKey="1"
          items={[
            {
              label: 'Dịch vụ',
              key: '1',
              children: (
                <></>
              ),
            },
            // {
            //   label: 'Thẻ thành viên',
            //   key: '2',
            //   children: (
            //     <div className="overflow-auto h-[280px]">
            //       <ConfigProvider
            //         theme={{
            //           token: {
            //             padding: 5,
            //           },
            //         }}
            //       >
            //         <Table
            //           scroll={{ y: 180 }}
            //           bordered
            //           pagination={{
            //             pageSize: 5,
            //             showSizeChanger: false,
            //           }}
            //           columns={columnsTTV}
            //           dataSource={listTTV.map((item) => ({
            //             ...item,
            //             action: (
            //               <ul className="flex gap-2 justify-around">
            //                 <li className="text-lg text-blue-500">
            //                   <PlusOutlined onClick={() => console.log(item)} />
            //                 </li>
            //               </ul>
            //             ),
            //           }))}
            //         />
            //       </ConfigProvider>
            //     </div>
            //   ),
            // },
          ]}
        /> */}
      </div>
    </div>
  )
}

DichVuGiamGia.propTypes = {}

export default DichVuGiamGia
