import { https } from '../apiService'

export const trongKinhService = {
    getListBNChoTK: (idcn) => https.get(`TrongKinh/GetBenhNhanChoTrongKinh?idcn=${idcn}`),
    getListBNDaTK: (idcn, tuNgay, denNgay) => https.get(`TrongKinh/GetBenhNhanDaTrongKinh?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
    putListDVBNTrongKinh: (list) => https.put(`BenhNhanDichVu/UpdateTrangThaiBenhNhanDichVuTrongKinh`, list),
    //getListVTThuThuat: (idcn) => https.get(`TonKho/KhoCapCuuAndDieuDuong/${idcn}`),
    // getInfoBNDVThuThuat: (idbnttc) => https.get(`BenhNhanDichVu/DichVuTHuThuatBy/${idbnttc}`),
    // getInfoBNDVThuThuat: (idbnttc) => https.get(`BenhNhanDichVu/GetThongTinBenhNhanDichVuThuThuat?idbnttc=${idbnttc}`),
    // postListVTBNThuThuat: (list) => https.post(`BenhNhanVatTuTieuHao/AddBenhNhanVatTuTieuHao`, list),
    // putListDVBNThuThuat: (list) => https.put(`BenhNhanDichVu/UpdateTrangThaiBenhNhanDichVu`, list),
}