import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listNguoiDuyet: null,
  listDatHang: null,
  listHangHoa: null,
  inforDonHang: null,
}

const vanPhongPhamReducerReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_NGUOI_DUYET:
        draft.listNguoiDuyet = payload
        break
      case typeAction.DISPATCH_LIST_DATHANG:
        draft.listDatHang = payload
        break
      case typeAction.DISPATCH_LIST_HANGHOA:
        draft.listHangHoa = payload
        break
      case typeAction.DISPATCH_INFO_DONHANG:
        draft.inforDonHang = payload
        break
      case typeAction.DISPATCH_CLEAR_INFO_DONHANG:
        draft.inforDonHang = null
        break
      default:
        return state
    }
  })
}

export default vanPhongPhamReducerReducer
