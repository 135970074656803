import { Checkbox, DatePicker, Input, InputNumber, Modal, Radio, Select } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TimePicker } from 'antd'
import { getList_Template_SMS } from '../../../../../store/actions/smsAction'
import { https } from '../../../../../services/apiService'
import { cloneDeep } from 'lodash'
const { TextArea } = Input
const format = 'HH:mm'
const optionThoiDiem = [
  {
    label: 'Một lần trong ngày',
    value: 0,
  },
  {
    label: 'Lặp lại sau 10 phút',
    value: 1,
  },
]
const ModalInfo = ({ isModalEdit, setIsModalEdit, onLoad }) => {
  const { listTemplate, listBN } = useSelector((state) => state.smsReducer)
  const [brandName, setBrandName] = useState(1)
  const [allBrandName, setAllBrandName] = useState([])
  const [templateChoosed, setTemplateChoosed] = useState(null)
  const [optionDau, setOptionDau] = useState('1')

  const dispatch = useDispatch()
  const handleSubmit = (value) => {
    // dispatch(updateDMXetNghiemAction(value, onLoad))
    // formik.resetForm()
    // setIsModalEdit()
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: (value) => handleSubmit(value),
  })

  useEffect(() => {
    dispatch(getList_Template_SMS())
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('SMSTaiKhoan/GetAll')
        setAllBrandName(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handeChangeOptionDau = (e) => {
    setOptionDau(e.target.value)
  }

  const handleChangeTemplate = (value) => {
    setTemplateChoosed(listTemplate.find((item) => item.idtp === value))
  }

  const handleChangeContentTemplate = (e) => {
    const cloneTemplateChoosed = cloneDeep(templateChoosed)
    if (optionDau === '0') {
      // co dau
      cloneTemplateChoosed.templatE_CODAU = e.target.value
    } else {
      cloneTemplateChoosed.templatE_KHONGDAU = e.target.value
    }
    setTemplateChoosed(cloneTemplateChoosed)
  }

  return (
    <>
      <Modal
        okText="Lưu"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-gray-700 text-xl">Thông tin SMS</h2>}
        onOk={formik.handleSubmit}
        open={isModalEdit.show}
        onCancel={() => setIsModalEdit(false)}
        width={700}
      >
        <form className="text-start flex flex-col gap-2">
          <div className="w-full flex gap-2">
            <div className="w-full flex items-center">
              <label className="font-semibold text-base w-1/5">BrandName:</label>
              <Select
                placeholder="Brandname"
                className="w-4/5 mb-2"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={allBrandName?.map(({ brandname, idtaikhoan }) => ({
                  label: brandname,
                  value: idtaikhoan,
                }))}
                value={brandName}
                onChange={(value) => {
                  setBrandName(value)
                  setTemplateChoosed(null)
                }}
              />
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-3/4 flex items-center">
              <label className="font-semibold text-base w-24">Loại tin:</label>
              <Radio.Group
                onChange={handeChangeOptionDau}
                value={optionDau}
                className="w-3/4 text-center"
                options={[
                  {
                    label: 'Tin có dấu',
                    value: '0',
                  },
                  {
                    label: 'Tin không dấu',
                    value: '1',
                  },
                ]}
              />
            </div>
            <div className="w-1/4 flex items-center">
              <label className="font-semibold text-base w-36">Số ngày:</label>
              <Input
                value={formik.values.maxn}
                name="maxn"
                onChangeCapture={formik.handleChange}
                className="w-full"
                type='number'
                min={1}
                max={99999}
                step={1}
              />
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-full flex items-center">
              <label className="font-semibold text-base w-1/5">Tên:</label>
              <Select
                placeholder="Template"
                className="w-4/5"
                onChange={handleChangeTemplate}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={listTemplate
                  ?.filter((item) => item?.idtaikhoan === brandName)
                  ?.map(({ matp, noidungmau, idtp, chuyenkhoa, templatE_KHONGDAU }) => ({
                    label: `${matp} - ${templatE_KHONGDAU}`,
                    value: idtp,
                  }))}
                value={templateChoosed?.idtp}
              />
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-full flex items-center">
              <label className="font-semibold text-base w-1/5">Nội dung:</label>
              <TextArea
                className="w-4/5"
                rows={8}
                value={
                  optionDau === '0'
                    ? templateChoosed?.templatE_CODAU
                    : templateChoosed?.templatE_KHONGDAU
                }
                onChange={handleChangeContentTemplate}
                disabled={!templateChoosed}
              />
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-3/4 flex items-center">
              <label className="font-semibold text-base w-44">Thời điểm:</label>
              <Select
                value={formik.values.idDoiTuong}
                options={optionThoiDiem?.map((items) => ({
                  label: items.label,
                  value: items.value,
                }))}
                // onChange={handleSelected('idDoiTuong')}
                className="w-full text-center"
                defaultValue={0}
              />
            </div>
            <div className="w-1/4 flex items-center">
              <label className="font-semibold text-base w-36">Giờ gửi:</label>
              <TimePicker defaultValue={dayjs(new Date())} format={format} allowClear={false} />
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-full flex items-center">
              <label className="font-semibold text-base w-1/5">Ghi chú:</label>
              <Input
                value={formik.values.maxn}
                name="maxn"
                onChangeCapture={formik.handleChange}
                className="w-4/5"
              />
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-full flex items-center">
              <label className="font-semibold text-base w-1/5">Hiệu lực:</label>
              <Checkbox checked/>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalInfo
