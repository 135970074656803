import moment from 'moment/moment'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../splitWordForReport'
import {
  calculateContentHeightBenhAnMat,
  checkNewPage,
  generateCheckbox,
  generateDot,
  generateDotWithWidth,
} from '../algorithm'
import { matTraiURI } from '../../assets/images/benhanmat/matTrai'
import { matPhaiURI } from '../../assets/images/benhanmat/matPhai'
import { dayMatPhaiURI } from '../../assets/images/benhanmat/dayMatPhai'
import { dayMatTraiURI } from '../../assets/images/benhanmat/dayMatTrai'

function calculateAge(birthdate) {
  const birthDate = moment(birthdate)
  const today = moment()
  return today.diff(birthDate, 'years')
}

export const benhAnMat = (infoBNTTC, infoBNMat, icdObj) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height

  //page 1
  doc.setLineWidth(0.2)
  //header
  doc.setFontSize(15)
  doc.setFont('times', 'bold')
  doc.text('BỆNH ÁN', doc.internal.pageSize.width / 2, yPos + 3, { align: 'center' })

  doc.setFontSize(12)
  doc.setFont('times', 'regular')
  doc.text('Sở Y tế TP.Hồ Chí Minh', xPos + 10, 10)
  doc.text('Phòng khám Mắt 315', xPos + 9, 15)

  doc.setFontSize(10)
  doc.text('MS: BA-17', pageWidth - 40, 10)

  doc.setFontSize(15)
  doc.setFont('times', 'bold')
  doc.text('NGOẠI TRÚ MẮT', pageWidth / 2, 20, { align: 'center' })
  doc.setFontSize(12)
  yPos = 25
  doc.text('A. THÔNG TIN CHUNG:', xPos, yPos)
  let textWidthHoTen = doc.getTextDimensions('1. Họ và tên (In hoa): ').w
  yPos += 6
  doc.text(`${infoBNTTC.benhNhan.tenBenhNhan}`, textWidthHoTen + xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text('1. Họ và tên', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(' (In hoa):', doc.getTextDimensions('1. Họ và tên').w + xPos, yPos)
  const dotLineHoTen = generateDot(
    doc,
    `1. Họ và tên (In hoa): ${infoBNTTC.benhNhan.tenBenhNhan} `,
    xPos,
    yPos,
  )
  doc.text(dotLineHoTen.positionX, dotLineHoTen.yPos, dotLineHoTen.content)
  yPos += 6
  doc.setFont('times', 'regular')
  doc.text(`2. Ngày sinh: ${moment(infoBNTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`, xPos, yPos)
  doc.text('Tuổi: ', xPos + 70, yPos)
  const age = calculateAge(infoBNTTC.benhNhan.ngaySinh)
  if (age < 10) {
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(age.toString(), xPos + 91, yPos)
  } else {
    //tach so
    let ageString = age.toString()
    while (ageString.length < 3) {
      ageString = '0' + ageString
    }

    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(ageString[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(ageString[1], xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(ageString[2], xPos + 91, yPos)
  }

  doc.text('3. Giới tính: ', xPos + 100, yPos)
  doc.text('Nam', xPos + 135, yPos)
  doc.text('Nữ', xPos + 160, yPos)
  if (infoBNTTC.benhNhan.gioiTinh === 'Nam') {
    generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, false)
  } else {
    generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, true)
  }

  yPos += 7
  doc.text(`4. Điện thoại: ${infoBNTTC.benhNhan.dienThoai ?? ''}`, xPos, yPos)
  doc.text(`5. Nghề nghiệp: ${infoBNTTC.benhNhan.tenNgheNghiep ?? ''}`, xPos + 100, yPos)
  yPos += 7

  doc.text(`6. Dân tộc: ${infoBNTTC.benhNhan.tenDanToc ?? ''}`, xPos, yPos)
  if (infoBNTTC.benhNhan.idDanToc < 10) {
    // Set border color

    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(infoBNTTC.benhNhan.idDanToc.toString(), xPos + 86, yPos)
  } else {
    //tach so
    let idDanToc = infoBNTTC.benhNhan.idDanToc + ''
    let dotStrings = []
    for (let i = 0; i < idDanToc.length; i++) {
      dotStrings.push(idDanToc[i])
    }
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(dotStrings[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(dotStrings[1], xPos + 86, yPos)
  }

  doc.text(`7. Quốc tịch: ${infoBNTTC.benhNhan.tenQuocTich ?? ''}`, xPos + 100, yPos)
  if (infoBNTTC.benhNhan.idDanToc < 10) {
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBNTTC.benhNhan.idQuocTich.toString(), pageWidth - 14, yPos)
  } else {
    //tach so
    let idQuocTich = infoBNTTC.benhNhan.idQuocTich.toString()
    while (idQuocTich.length < 3) {
      idQuocTich = '0' + idQuocTich
    }

    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[0], pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[1], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idQuocTich[2], pageWidth - 14, yPos)
  }

  yPos += 7
  doc.text(`8. Địa chỉ: ${infoBNTTC.benhNhan.diaChi ?? ''}`, xPos, yPos)
  doc.text(`Xã phường: ${infoBNTTC.benhNhan.tenPhuongXa ?? ''}`, xPos + 100, yPos)
  if (infoBNTTC.benhNhan.idPhuong < 10) {
    doc.rect(pageWidth - 35, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 34, yPos)
    doc.rect(pageWidth - 30, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 29, yPos)
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBNTTC.benhNhan.idPhuong.toString(), pageWidth - 14, yPos)
  } else {
    //tach so
    let idPhuongXa = infoBNTTC.benhNhan.idPhuong.toString()
    while (idPhuongXa.length < 5) {
      idPhuongXa = '0' + idPhuongXa
    }
    doc.rect(pageWidth - 35, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[0], pageWidth - 34, yPos)
    doc.rect(pageWidth - 30, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[1], pageWidth - 29, yPos)
    doc.rect(pageWidth - 25, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[2], pageWidth - 24, yPos)
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[3], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idPhuongXa[4], pageWidth - 14, yPos)
  }

  yPos += 7
  doc.text(`Huyện(Q, Tx): ${infoBNTTC.benhNhan.tenQuanHuyen ?? ''}`, xPos, yPos)
  if (infoBNTTC.benhNhan.idQuan < 10) {
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text('0', xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(infoBNTTC.benhNhan.idQuan.toString(), xPos + 91, yPos)
  } else {
    //tach so
    let idQuanHuyen = infoBNTTC.benhNhan.idQuan.toString()
    while (idQuanHuyen.length < 3) {
      idQuanHuyen = '0' + idQuanHuyen
    }
    doc.rect(xPos + 80, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[0], xPos + 81, yPos)
    doc.rect(xPos + 85, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[1], xPos + 86, yPos)
    doc.rect(xPos + 90, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[2], xPos + 91, yPos)
  }

  doc.text(`Tỉnh, thành phố: ${infoBNTTC.benhNhan.tenTinhTP ?? ''}`, xPos + 100, yPos)
  if (infoBNTTC.benhNhan.idTinh < 10) {
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text('0', pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(infoBNTTC.benhNhan.idTinh.toString(), pageWidth - 14, yPos)
  } else {
    //tach so
    let idQuanHuyen = infoBNTTC.benhNhan.idTinh.toString()
    doc.rect(pageWidth - 20, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[0], pageWidth - 19, yPos)
    doc.rect(pageWidth - 15, yPos - 3.5, 4, 4)
    doc.text(idQuanHuyen[1], pageWidth - 14, yPos)
  }

  yPos += 7
  doc.text(`9. Đối tượng: `, xPos, yPos)

  doc.text(`BHYT`, xPos + 60, yPos)
  generateCheckbox(doc, xPos + 50, yPos - 3.5, 4, 4, false)

  doc.text(`Thu phí`, xPos + 85, yPos)
  generateCheckbox(doc, xPos + 80, yPos - 3.5, 4, 4, false)

  doc.text(`Miễn`, xPos + 110, yPos)
  generateCheckbox(doc, xPos + 105, yPos - 3.5, 4, 4, false)

  doc.text(`Khác`, xPos + 135, yPos)
  generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, true)

  yPos += 7

  doc.text(`10. Số thẻ BHYT: `, xPos, yPos)
  const dotLineBHYT = generateDot(doc, `10. Số thẻ BHYT:`, xPos, yPos)
  doc.text(dotLineBHYT.positionX, dotLineBHYT.yPos, dotLineBHYT.content)
  yPos += 7
  doc.text(
    `11. Số Căn cước/Hộ chiếu/Số định danh cá nhân: ${infoBNTTC.benhNhan.cccd ?? ''}`,
    xPos,
    yPos,
  )
  const dotLineCC = generateDot(
    doc,
    `11. Số Căn cước/Hộ chiếu/Số định danh cá nhân: ${infoBNTTC.benhNhan.cccd}`,
    xPos,
    yPos,
  )
  doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  yPos += 7
  doc.text(
    `12. Họ và tên thân nhân của BN khi cần báo tin: ${infoBNTTC.tennguoinha ?? ''}`,
    xPos,
    yPos,
  )
  doc.text(`Điện thoại: ${infoBNTTC.dienthoainguoinha ?? ''}`, pageWidth - 55, yPos)
  yPos += 7
  doc.setFont('times', 'bold')
  doc.text('B. THÔNG TIN KHÁM BỆNH', xPos, yPos)
  yPos += 7
  doc.text('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ', xPos, yPos)
  doc.setFont('times', 'regular')
  const lydokham = infoBNTTC.lydokham ?? ''
  if (doc.getTextWidth(lydokham) > 60) {
    const wordsArray = splitLongWord(lydokham, 55)
    doc.text(
      wordsArray[0],
      doc.getTextDimensions('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ').w + 10,
      yPos,
    )
    doc.text(wordsArray[1], xPos, yPos + 4)
    yPos += 4
  } else {
    doc.text(lydokham, doc.getTextDimensions('I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE: ').w + 12, yPos)
  }

  yPos += 7
  doc.setFont('times', 'bold')
  doc.text('II. HỎI BỆNH', xPos, yPos)
  yPos += 7
  doc.text('1. Quá trình bệnh lý và diễn biến lâm sàng: ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(
    ' (Đặc điểm khởi phát, các triệu chứng lâm sàng, diễn biến bệnh...)',
    doc.getTextDimensions('1. Quá trình bệnh lý và diễn biến lâm sàng: ').w + 10,
    yPos,
  )
  yPos += 6
  doc.setFont('times', 'regular')
  const quatrinhbenh = infoBNTTC.benhsu ?? ''
  if (doc.getTextWidth(quatrinhbenh) > 200) {
    //210 is width of page
    const wordsArray = splitLongWordFitWidth(doc, quatrinhbenh, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 6) {
      for (let i = 0; i < 6 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(quatrinhbenh, xPos, yPos)
    const dotLineCC = generateDot(doc, quatrinhbenh, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 5; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 4
  doc.setFont('times', 'bold')
  doc.text('2. Tiền sử có liên quan đến tình trạng bệnh lần này:', xPos, yPos)
  yPos += 7
  doc.setFont('times', 'regular')
  doc.text('Dị ứng', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)

  if (!infoBNTTC.diung) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const diung = infoBNTTC.diung
    //beakword
    if (doc.getTextWidth(diung) > 120) {
      const wordsArray = splitLongWord(diung, 70)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 70, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 70, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(diung, xPos + 70, yPos)
      const dotLineCC = generateDot(doc, diung, xPos + 70, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Tai mắt', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)
  if (!infoBNMat.TIENSUTAIMAT) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const taimat = infoBNMat.TIENSUTAIMAT
    //beakword
    if (doc.getTextWidth(taimat) > 120) {
      const wordsArray = splitLongWord(taimat, 70)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 70, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 70, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(taimat, xPos + 70, yPos)
      const dotLineCC = generateDot(doc, taimat, xPos + 70, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Bản thân', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)

  if (!infoBNTTC.tiensubanthan) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const tiensubanthan = infoBNTTC.tiensubanthan
    //beakword
    if (doc.getTextWidth(tiensubanthan) > 120) {
      const wordsArray = splitLongWord(tiensubanthan, 70)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 70, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 70, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(tiensubanthan, xPos + 70, yPos)
      const dotLineCC = generateDot(doc, tiensubanthan, xPos + 70, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.text('Gia đình', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có,ghi rõ: ', xPos + 50, yPos)

  if (!infoBNTTC.tieusugiadinh) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, false)
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, true)
    const tiensugiadinh = infoBNTTC.tieusugiadinh
    //beakword
    if (doc.getTextWidth(tiensugiadinh) > 120) {
      const wordsArray = splitLongWord(tiensugiadinh, 70)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 70, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos + 70, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    } else {
      doc.text(tiensugiadinh, xPos + 70, yPos)
      const dotLineCC = generateDot(doc, tiensugiadinh, xPos + 70, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos + 70, yPos)
      doc.text(xPos + 70, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  yPos += 2
  doc.setFont('times', 'bold')
  doc.text('III. KHÁM BỆNH', xPos, yPos)
  yPos += 6
  doc.text('1. Bệnh Mắt: ', xPos, yPos)
  yPos += 6
  const centerPage = pageWidth / 2
  const leftPage = (pageWidth / 2 + pageWidth) / 2
  const rightPage = (pageWidth - pageWidth / 2) / 2

  doc.text('BỘ PHẬN', centerPage, yPos, { align: 'center' })
  doc.text('MẮT PHẢI', leftPage, yPos, { align: 'center' })
  doc.text('MẮT TRÁI', rightPage, yPos, { align: 'center' })
  yPos += 7
  doc.text('Thị lực', centerPage, yPos, { align: 'center' })
  yPos += 7
  doc.setFont('times', 'regular')
  doc.text('Không kính', centerPage, yPos, { align: 'center' })
  doc.text(`${infoBNMat.KHONGKINH_MT ?? '.................................'}`, rightPage, yPos, {
    align: 'center',
  })
  doc.text(`${infoBNMat.KHONGKINH_MP ?? '.................................'}`, leftPage, yPos, {
    align: 'center',
  })
  yPos += 7
  doc.text('Có kính', centerPage, yPos, { align: 'center' })
  doc.text(`${infoBNMat.COKINH_MT ?? '.................................'}`, rightPage, yPos, {
    align: 'center',
  })
  doc.text(`${infoBNMat.COKINH_MP ?? '.................................'}`, leftPage, yPos, {
    align: 'center',
  })
  yPos += 7
  doc.setFont('times', 'bold')
  doc.text('Nhãn áp', centerPage, yPos, { align: 'center' })
  doc.setFont('times', 'regular')
  doc.text(`${infoBNMat.NHANAP_MT ?? '.................................'}`, rightPage, yPos, {
    align: 'center',
  })
  doc.text(`${infoBNMat.NHANAP_MP ?? '.................................'}`, leftPage, yPos, {
    align: 'center',
  })

  //page 2
  doc.addPage()
  yPos = 10
  doc.setFont('times', 'bold')
  doc.text('BỘ PHẬN', centerPage, yPos, { align: 'center' })
  doc.text('MẮT PHẢI', leftPage, yPos, { align: 'center' })
  doc.text('MẮT TRÁI', rightPage, yPos, { align: 'center' })
  yPos += 7
  const fields = [
    {
      name: 'Hốc mắt',
      mt: infoBNMat.HOCMAT_MT,
      mp: infoBNMat.HOCMAT_MP,
    },
    {
      name: 'Tình trạng nhãn cầu',
      mt: infoBNMat.TINHTRANGNHANCAU_MT,
      mp: infoBNMat.TINHTRANGNHANCAU_MP,
    },
    {
      name: 'Vận nhãn',
      mt: infoBNMat.VANNHAN_MT,
      mp: infoBNMat.VANNHAN_MP,
    },
    {
      name: 'Lệ đạo',
      mt: infoBNMat.LEDAO_MT,
      mp: infoBNMat.LEDAO_MP,
    },
    {
      name: 'Mi mắt',
      mt: infoBNMat.MIMAT_MT,
      mp: infoBNMat.MIMAT_MP,
    },
    {
      name: 'Kết mạc',
      mt: infoBNMat.KETMAC_MT,
      mp: infoBNMat.KETMAC_MP,
    },
    {
      name: 'Giác mạc',
      mt: infoBNMat.GIACMAC_MT,
      mp: infoBNMat.GIACMAC_MP,
    },
    {
      name: 'Củng mạc',
      mt: infoBNMat.CUNGMAC_MT,
      mp: infoBNMat.CUNGMAC_MP,
    },
    {
      name: 'Tiền phòng',
      mt: infoBNMat.TIENPHONG_MT,
      mp: infoBNMat.TIENPHONG_MP,
    },
    {
      name: 'Mống mắt',
      mt: infoBNMat.MONGMAT_MT,
      mp: infoBNMat.MONGMAT_MP,
    },
    {
      name: 'Đồng tử',
      mt: infoBNMat.DONGTU_MT,
      mp: infoBNMat.DONGTU_MT,
    },
    {
      name: 'Thể thuỷ tinh',
      mt: infoBNMat.THETHUYTINH_MT,
      mp: infoBNMat.THETHUYTINH_MP,
    },
    {
      name: 'Dịch kính',
      mt: infoBNMat.DICHKINH_MT,
      mp: infoBNMat.DICHKINH_MP,
    },
    {
      name: 'Đáy mắt',
      mt: infoBNMat.DAYMAT_MT,
      mp: infoBNMat.DAYMAT_MT,
    },
  ]
  let tempYPos = yPos

  fields.forEach((field) => {
    if (field.name === 'Tình trạng nhãn cầu') {
      const contentHeight = calculateContentHeightBenhAnMat(doc, field)
      yPos = checkNewPage(doc, yPos, contentHeight)
      // Render the title
      doc.setFont('times', 'bold')
      doc.text('Tình trạng', centerPage, yPos, { align: 'center' })
      doc.text('nhãn cầu', centerPage, yPos + 6, { align: 'center' })
      doc.setFont('times', 'regular')

      // Left eye (MT)
      doc.text('Bình thường', xPos + 12, yPos)
      doc.text('Bất thường', xPos + 50, yPos)
      generateCheckbox(
        doc,
        xPos + 7,
        yPos - 3.5,
        4,
        4,
        infoBNMat.TINHTRANGNHANCAU_MT ? false : true,
      )
      generateCheckbox(
        doc,
        xPos + 45,
        yPos - 3.5,
        4,
        4,
        infoBNMat.TINHTRANGNHANCAU_MT ? true : false,
      )

      // Right eye (MP)
      doc.text('Bình thường', centerPage + 25, yPos)
      doc.text('Bất thường', centerPage + 65, yPos)
      generateCheckbox(
        doc,
        centerPage + 20,
        yPos - 3.5,
        4,
        4,
        infoBNMat.TINHTRANGNHANCAU_MP ? false : true,
      )
      generateCheckbox(
        doc,
        centerPage + 60,
        yPos - 3.5,
        4,
        4,
        infoBNMat.TINHTRANGNHANCAU_MP ? true : false,
      )

      // Description for MT
      yPos += 6
      const nhanCauTrai = infoBNMat.TINHTRANGNHANCAU_MT ?? ''
      const motaNhanCauTrai = `Mô tả: ${nhanCauTrai}`
      let tempYPos = yPos

      if (!nhanCauTrai) {
        doc.text(
          'Mô tả: ...........................................................',
          xPos,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaNhanCauTrai) > 40) {
          const wordsArray = splitLongWord(motaNhanCauTrai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, xPos, tempYPos)
            tempYPos += 4
          })
        } else {
          doc.text(motaNhanCauTrai, xPos, tempYPos)
        }
      }

      // Reset tempYPos to the same line
      tempYPos = yPos

      // Description for MP
      const nhanCauPhai = infoBNMat.TINHTRANGNHANCAU_MP ?? ''
      const motaNhanCauPhai = `Mô tả: ${nhanCauPhai}`
      if (!nhanCauPhai) {
        doc.text(
          'Mô tả: ...........................................................',
          centerPage + 13,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaNhanCauPhai) > 40) {
          const wordsArray = splitLongWord(motaNhanCauPhai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, centerPage + 13, tempYPos)
            tempYPos += 4
          })
        } else {
          doc.text(motaNhanCauPhai, centerPage + 13, tempYPos)
        }
      }

      yPos = tempYPos + 10
    } else if (field.name === 'Tiền phòng') {
      const contentHeight = calculateContentHeightBenhAnMat(doc, field)
      yPos = checkNewPage(doc, yPos, contentHeight)
      doc.setFont('times', 'bold')
      doc.text('Hình vẽ mô tả', centerPage, yPos, { align: 'center' })
      doc.addImage(matTraiURI, 'PNG', xPos + 15, yPos, 60, 25)
      doc.addImage(matPhaiURI, 'PNG', centerPage + 20, yPos, 60, 25)
      yPos += 38

      // Render title
      doc.setFont('times', 'bold')
      doc.text(field.name, centerPage, yPos, { align: 'center' })
      doc.setFont('times', 'regular')

      // Left eye (MT)
      doc.text('Bình thường', xPos + 12, yPos)
      doc.text('Bất thường', xPos + 50, yPos)
      generateCheckbox(doc, xPos + 7, yPos - 3.5, 4, 4, infoBNMat.TIENPHONG_MT ? false : true)
      generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, infoBNMat.TIENPHONG_MT ? true : false)

      // Right eye (MP)
      doc.text('Bình thường', centerPage + 25, yPos)
      doc.text('Bất thường', centerPage + 65, yPos)
      generateCheckbox(
        doc,
        centerPage + 20,
        yPos - 3.5,
        4,
        4,
        infoBNMat.TIENPHONG_MP ? false : true,
      )
      generateCheckbox(
        doc,
        centerPage + 60,
        yPos - 3.5,
        4,
        4,
        infoBNMat.TIENPHONG_MP ? true : false,
      )

      // Description for MT
      yPos += 6
      const tienPhongTrai = infoBNMat.TIENPHONG_MT ?? ''
      const motaTienPhongTrai = `Mô tả: ${tienPhongTrai}`
      let tempYPos = yPos

      if (!tienPhongTrai) {
        doc.text(
          'Mô tả: ...........................................................',
          xPos,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(tienPhongTrai) > 40) {
          const wordsArray = splitLongWord(motaTienPhongTrai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, xPos, tempYPos)
            tempYPos += 4
          })
        } else {
          doc.text(motaTienPhongTrai, xPos, tempYPos)
        }
      }

      // Reset tempYPos to the same line
      tempYPos = yPos

      // Description for MP
      const tienPhongPhai = infoBNMat.TIENPHONG_MP ?? ''
      const motaTienPhongPhai = `Mô tả: ${tienPhongPhai}`
      if (!tienPhongPhai) {
        doc.text(
          'Mô tả: ...........................................................',
          centerPage + 13,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(tienPhongPhai) > 40) {
          const wordsArray = splitLongWord(motaTienPhongPhai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, centerPage + 13, tempYPos)
            tempYPos += 4
          })
        } else {
          doc.text(motaTienPhongPhai, centerPage + 13, tempYPos)
        }
      }
      yPos = tempYPos + 10
    } else if (field.name === 'Đồng tử') {
      const contentHeight = calculateContentHeightBenhAnMat(doc, field)
      yPos = checkNewPage(doc, yPos, contentHeight)
      let check = false
      // Render the title
      doc.setFont('times', 'bold')
      doc.text('Đồng tử', centerPage, yPos, { align: 'center' })
      doc.setFont('times', 'italic')
      doc.text('(Phản xạ,', centerPage, yPos + 6, { align: 'center' })
      doc.text('hình dạng,', centerPage, yPos + 12, { align: 'center' })
      doc.text('kích thước,', centerPage, yPos + 18, { align: 'center' })
      doc.text('ảnh đồng tử...)', centerPage, yPos + 24, { align: 'center' })

      doc.setFont('times', 'regular')

      // Left eye (MT)
      doc.text('Bình thường', xPos + 12, yPos)
      doc.text('Bất thường', xPos + 50, yPos)
      generateCheckbox(doc, xPos + 7, yPos - 3.5, 4, 4, infoBNMat.DONGTU_MT ? false : true)
      generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, infoBNMat.DONGTU_MT ? true : false)

      // Right eye (MP)
      doc.text('Bình thường', centerPage + 25, yPos)
      doc.text('Bất thường', centerPage + 65, yPos)
      generateCheckbox(doc, centerPage + 20, yPos - 3.5, 4, 4, infoBNMat.DONGTU_MP ? false : true)
      generateCheckbox(doc, centerPage + 60, yPos - 3.5, 4, 4, infoBNMat.DONGTU_MP ? true : false)

      // Description for MT (left eye)
      yPos += 6
      const DongTuTrai = infoBNMat.DONGTU_MT ?? ''
      const motaDongTuTrai = `Mô tả: ${DongTuTrai}`
      let tempYPos = yPos

      if (!DongTuTrai) {
        doc.text(
          'Mô tả: ...........................................................',
          xPos,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaDongTuTrai) > 40) {
          const wordsArray = splitLongWord(motaDongTuTrai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, xPos, tempYPos)
            tempYPos += 4
          })
          check = true
        } else {
          doc.text(motaDongTuTrai, xPos, tempYPos)
        }
      }

      // Reset tempYPos to the same line
      tempYPos = yPos

      // Description for MP (right eye)
      const DongTuPhai = infoBNMat.DONGTU_MP ?? ''
      const motaDongTuPhai = `Mô tả: ${DongTuPhai}`

      if (!DongTuPhai) {
        doc.text(
          'Mô tả: ...........................................................',
          centerPage + 13,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaDongTuPhai) > 40) {
          const wordsArray = splitLongWord(motaDongTuPhai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, centerPage + 13, tempYPos)
            tempYPos += 4
          })
          check = true
        } else {
          doc.text(motaDongTuPhai, centerPage + 13, tempYPos)
        }
      }
      yPos = check ? tempYPos + 5 : tempYPos + 26
    } else if (field.name === 'Đáy mắt') {
      const contentHeight = calculateContentHeightBenhAnMat(doc, field)
      yPos = checkNewPage(doc, yPos, contentHeight)

      // Render the title
      doc.setFont('times', 'bold')
      doc.text('Đáy mắt', centerPage, yPos, { align: 'center' })
      doc.setFont('times', 'regular')

      // Add images for left and right eyes
      doc.addImage(dayMatTraiURI, 'PNG', xPos + 18, yPos, 32, 26)
      doc.addImage(dayMatPhaiURI, 'PNG', centerPage + 35, yPos, 32, 26)
      yPos += 32 // Move yPos below the images

      // Left eye (MT)
      doc.text('Bình thường', xPos + 12, yPos)
      doc.text('Bất thường', xPos + 50, yPos)
      generateCheckbox(doc, xPos + 7, yPos - 3.5, 4, 4, infoBNMat.DAYMAT_MT ? false : true)
      generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, infoBNMat.DAYMAT_MT ? true : false)

      // Right eye (MP)
      doc.text('Bình thường', centerPage + 25, yPos)
      doc.text('Bất thường', centerPage + 65, yPos)
      generateCheckbox(doc, centerPage + 20, yPos - 3.5, 4, 4, infoBNMat.DAYMAT_MP ? false : true)
      generateCheckbox(doc, centerPage + 60, yPos - 3.5, 4, 4, infoBNMat.DAYMAT_MP ? true : false)

      // Description for left eye (MT)
      yPos += 6
      const dayMatTrai = infoBNMat.DAYMAT_MT ?? ''
      const motaDayMatTrai = `Mô tả: ${dayMatTrai}`
      let tempYPos = yPos

      if (!dayMatTrai) {
        doc.text(
          'Mô tả: ...........................................................',
          xPos,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaDayMatTrai) > 40) {
          const wordsArray = splitLongWord(motaDayMatTrai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, xPos, tempYPos)
            tempYPos += 4
          })
        } else {
          doc.text(motaDayMatTrai, xPos, tempYPos)
        }
      }

      // Reset tempYPos to the same line
      tempYPos = yPos

      // Description for right eye (MP)
      const dayMatPhai = infoBNMat.DAYMAT_MP ?? ''
      const motaDayMatPhai = `Mô tả: ${dayMatPhai}`

      if (!dayMatPhai) {
        doc.text(
          'Mô tả: ...........................................................',
          centerPage + 13,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaDayMatPhai) > 40) {
          const wordsArray = splitLongWord(motaDayMatPhai, 45)
          wordsArray.forEach((line) => {
            doc.text(line, centerPage + 13, tempYPos)
            tempYPos += 4
          })
        } else {
          doc.text(motaDayMatPhai, centerPage + 13, tempYPos)
        }
      }

      yPos = tempYPos + 5
    } else {
      const contentHeight = calculateContentHeightBenhAnMat(doc, field)
      yPos = checkNewPage(doc, yPos, contentHeight)
      let check = false
      // Render the content (same as your previous logic)
      doc.setFont('times', 'bold')
      doc.text(field.name, centerPage, yPos, { align: 'center' })
      doc.setFont('times', 'regular')

      // Left eye (MT)
      doc.text('Bình thường', xPos + 12, yPos)
      doc.text('Bất thường', xPos + 50, yPos)
      generateCheckbox(doc, xPos + 7, yPos - 3.5, 4, 4, field.mt ? false : true)
      generateCheckbox(doc, xPos + 45, yPos - 3.5, 4, 4, field.mt ? true : false)

      // Right eye (MP)
      doc.text('Bình thường', centerPage + 25, yPos)
      doc.text('Bất thường', centerPage + 65, yPos)
      generateCheckbox(doc, centerPage + 20, yPos - 3.5, 4, 4, field.mp ? false : true)
      generateCheckbox(doc, centerPage + 60, yPos - 3.5, 4, 4, field.mp ? true : false)

      // Description for MT
      yPos += 6
      const motaMT = `Mô tả: ${field.mt ?? ''}`
      tempYPos = yPos

      if (!field.mt) {
        doc.text(
          'Mô tả: ...........................................................',
          xPos,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaMT) > 40) {
          const wordsArray = splitLongWord(motaMT, 45)
          wordsArray.forEach((line) => {
            doc.text(line, xPos, tempYPos)
            tempYPos += 4
          })
          check = true
        } else {
          doc.text(motaMT, xPos, tempYPos)
        }
      }

      // Reset tempYPos to the same line
      tempYPos = yPos

      // Description for MP
      const motaMP = `Mô tả: ${field.mp ?? ''}`
      if (!field.mp) {
        doc.text(
          'Mô tả: ...........................................................',
          centerPage + 13,
          tempYPos,
        )
      } else {
        if (doc.getTextWidth(motaMP) > 40) {
          const wordsArray = splitLongWord(motaMP, 45)
          wordsArray.forEach((line) => {
            doc.text(line, centerPage + 13, tempYPos)
            tempYPos += 4
          })
          check = true
        } else {
          doc.text(motaMP, centerPage + 13, tempYPos)
        }
      }
      yPos = check ? tempYPos + 10 : tempYPos + 5
    }
  })

  doc.addPage()
  yPos = 10
  doc.setFont('times', 'bold')
  doc.text('2. Toàn thân: ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text('Bình thường', xPos + 40, yPos)
  doc.text('Bất thường, ghi rõ: ', xPos + 80, yPos)

  doc.rect(pageWidth - 50, yPos, 42, 38)
  doc.setFont('times', 'italic')
  doc.setFontSize(11)
  doc.text(`Mạch: ${infoBNTTC.mach ?? ''} lần/phút`, pageWidth - 48, yPos + 5)
  doc.text(`Nhiệt độ: ${infoBNTTC.nhietdo ?? ''} °C`, pageWidth - 48, yPos + 11)
  doc.text(`Huyết áp: ${infoBNTTC.huyetap ?? ''} mmHg`, pageWidth - 48, yPos + 17)
  doc.text(`Nhịp thở: ${infoBNTTC.nhiptho ?? ''} lần/phút`, pageWidth - 48, yPos + 23)
  doc.text(`Cân năng: ${infoBNTTC.cannang ?? ''} kg`, pageWidth - 48, yPos + 29)
  doc.text(`Chiều cao: ${infoBNTTC.chieucao ?? ''} cm`, pageWidth - 48, yPos + 35)
  doc.setFont('times', 'regular')
  doc.setFontSize(11)
  if (!infoBNTTC.toanthan) {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, false)
    yPos += 6
    for (let i = 0; i < 7; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 35, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 75, yPos - 3.5, 4, 4, true)

    const toanthan = infoBNTTC.toanthan
    //beakword
    yPos += 6
    if (doc.getTextWidth(toanthan) > 80) {
      const wordsArray = splitLongWord(toanthan, 80)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 6
      }
      const dotLineCC = generateDotWithWidth(
        doc,
        wordsArray[wordsArray.length - 1],
        xPos,
        yPos - 6,
        160,
      )
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      if (wordsArray.length < 7) {
        for (let i = 0; i < 7 - wordsArray.length; i++) {
          const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
          doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
          yPos += 6
        }
      }
    } else {
      doc.text(toanthan, xPos, yPos)
      const dotLineCC = generateDotWithWidth(doc, toanthan, xPos, yPos, 160)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      for (let i = 0; i < 6; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 160)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  }

  doc.setFont('times', 'bold')
  doc.text('3. Các xét nghiệm cần làm: ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text('Không', xPos + 60, yPos)
  doc.text('Có, ghi rõ:', xPos + 90, yPos)
  const cacXN = infoBNMat.CACXETNGHIEMCANLAM ?? ''
  if (!cacXN) {
    generateCheckbox(doc, xPos + 55, yPos - 3.5, 4, 4, true)
    generateCheckbox(doc, xPos + 85, yPos - 3.5, 4, 4, false)
    yPos += 6
    for (let i = 0; i < 3; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  } else {
    generateCheckbox(doc, xPos + 55, yPos - 3.5, 4, 4, false)
    generateCheckbox(doc, xPos + 85, yPos - 3.5, 4, 4, true)
    yPos += 6
    //beakword
    if (doc.getTextWidth(cacXN) > 210) {
      const wordsArray = splitLongWordFitWidth(doc, cacXN, pageWidth - 25)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 6
      }
      const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      if (wordsArray.length < 3) {
        for (let i = 0; i < 3 - wordsArray.length; i++) {
          const dotLineEmpty = generateDot(doc, '', xPos, yPos)
          doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
          yPos += 6
        }
      }
    } else {
      doc.text(cacXN, xPos, yPos)
      const dotLineCC = generateDot(doc, cacXN, xPos, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      for (let i = 0; i < 3; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  }

  doc.setFont('times', 'bold')
  doc.text('4. Tóm tắt bệnh án: ', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const tomtatbenhan = infoBNMat.TOMTATBENHAN ?? ''

  if (doc.getTextWidth(tomtatbenhan) > 210) {
    const wordsArray = splitLongWordFitWidth(doc, tomtatbenhan, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 7) {
      for (let i = 0; i < 5 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(tomtatbenhan, xPos, yPos)
    const dotLineCC = generateDot(doc, tomtatbenhan, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 5; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  doc.setFont('times', 'bold')
  doc.text('IV. CHẨN ĐOÁN (Tên bệnh và mã ICD kèm theo):', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  doc.rect(pageWidth - 50, yPos - 3, 25, 5)
  doc.text(
    `${icdObj.chandoan ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.chandoan ?? '')) / 2,
    yPos + 0.6,
  )

  let chandoan = `Bệnh chính (MP, MT): ${infoBNTTC.chandoan ?? ''}`
  if (chandoan.endsWith('/')) {
    chandoan = chandoan.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(chandoan) > 110) {
    const wordsArray = splitLongWord(chandoan, 75)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(chandoan, xPos, yPos)
    yPos += 7
  }
  doc.rect(pageWidth - 50, yPos - 3, 25, 5)
  doc.text(
    `${icdObj.benhkemtheo ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.benhkemtheo ?? '')) / 2,
    yPos + 0.6,
  )

  let benhkemtheo = `Bệnh kèm theo MP, MT (nếu có): ${infoBNTTC.benhkemtheo ?? ''}`
  if (benhkemtheo.endsWith('/')) {
    benhkemtheo = benhkemtheo.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(benhkemtheo) > 110) {
    const wordsArray = splitLongWord(benhkemtheo, 75)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(benhkemtheo, xPos, yPos)
    yPos += 7
  }
  doc.rect(pageWidth - 50, yPos - 3, 25, 5)
  doc.text(
    `${icdObj.bienchung ?? ''}`,
    pageWidth - 50 + (25 - doc.getTextWidth(icdObj.bienchung ?? '')) / 2,
    yPos + 0.6,
  )

  let bienchung = `Biến chứng MP, MT (nếu có): ${infoBNTTC.bienchung ?? ''}`
  if (bienchung.endsWith('/')) {
    bienchung = bienchung.slice(0, -1) // Remove the last character
  }
  if (doc.getTextWidth(bienchung) > 110) {
    const wordsArray = splitLongWord(bienchung, 75)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(bienchung, xPos, yPos)
    yPos += 7
  }

  doc.setFont('times', 'bold')
  doc.text('V. TIÊN LƯỢNG', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const tienluonggan = `Tiên lượng gần: ${infoBNMat.TIENLUONGGAN ?? ''}`
  if (doc.getTextWidth(tienluonggan) > 210) {
    const wordsArray = splitLongWordFitWidth(doc, tienluonggan, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(tienluonggan, xPos, yPos)
    const dotLineCC = generateDot(doc, tienluonggan, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  const tienluongxa = `Tiên lượng xa: ${infoBNMat.TIENLUONGXA ?? ''}`
  if (doc.getTextWidth(tienluongxa) > 210) {
    const wordsArray = splitLongWordFitWidth(doc, tienluongxa, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(tienluongxa, xPos, yPos)
    const dotLineCC = generateDot(doc, tienluongxa, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  doc.setFont('times', 'bold')
  doc.text('VI. KẾ HOẠCH ĐIỀU TRỊ', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const huongdieutri = `Hướng điều trị tiếp theo: ${infoBNTTC.huongxuly ?? ''}`
  if (doc.getTextWidth(huongdieutri) > 210) {
    //210 is width of page
    const wordsArray = splitLongWordFitWidth(doc, huongdieutri, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(huongdieutri, xPos, yPos)
    const dotLineCC = generateDot(doc, huongdieutri, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  doc.text('Tư vấn, giáo dục sức khỏe cho người bệnh và thân nhân của người bệnh: ', xPos, yPos)
  doc.text('Không', xPos + 135, yPos)
  doc.text('Có, ghi rõ', xPos + 160, yPos)
  generateCheckbox(doc, xPos + 130, yPos - 3.5, 4, 4, infoBNMat.TUVANGIAODUCSUCKHOE ? false : true)
  generateCheckbox(doc, xPos + 155, yPos - 3.5, 4, 4, infoBNMat.TUVANGIAODUCSUCKHOE ? true : false)
  yPos += 6
  const tuvan = infoBNMat.TUVANGIAODUCSUCKHOE ?? ''
  if (doc.getTextWidth(tuvan) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(tuvan, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(tuvan, xPos, yPos)
    const dotLineCC = generateDot(doc, tuvan, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  yPos += 6
  const formattedDateTime = `Ngày ${moment(infoBNTTC.ngaykham).format('DD')} tháng ${moment(infoBNTTC.ngaykham).format('MM')} năm ${moment(infoBNTTC.ngaykham).format('YYYY')}`

  const dateTextWidth = doc.getTextWidth(formattedDateTime)

  const dateTextX = pageWidth - dateTextWidth - 35

  doc.setFont('times', 'italic')
  doc.text(formattedDateTime, dateTextX, yPos)
  doc.text(formattedDateTime, xPos + 12, yPos)

  yPos += 6
  doc.setFont('times', 'bold')
  doc.text(pageWidth - 37 - 'Đại diện cơ sở KB, CB'.length * 2, yPos, 'Đại diện cơ sở KB, CB')
  doc.text(xPos + 22, yPos, 'Bác sỹ điều trị')
  doc.setFont('times', 'italic')
  yPos += 6
  doc.text(pageWidth - 44 - '(Ký, đóng dấu)'.length * 2, yPos, '(Ký, đóng dấu)')
  doc.text(xPos + 20, yPos, '(Ký, ghi rõ họ tên)')
  doc.setFont('times', 'bold')
  doc.text(xPos + 15, yPos + 20, infoBNTTC.bskham ?? '')

  //page 4
  doc.addPage()
  yPos = 10
  doc.setFont('times', 'bold')
  doc.text('C. TỔNG KẾT BỆNH ÁN:', xPos, yPos)
  yPos += 7
  doc.text('1. Chẩn đoán khi ra viện', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 7
  const chuandoanmt = `MT: ${infoBNMat.CHANDOANRAVIEN_MT ?? ''}`
  if (doc.getTextWidth(chuandoanmt) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(chuandoanmt, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(chuandoanmt, xPos, yPos)
    const dotLineCC = generateDot(doc, chuandoanmt, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }

  const chuandoanmp = `MP: ${infoBNMat.CHANDOANRAVIEN_MP ?? ''}`
  if (doc.getTextWidth(chuandoanmp) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(chuandoanmp, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(chuandoanmp, xPos, yPos)
    const dotLineCC = generateDot(doc, chuandoanmp, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  doc.setFont('times', 'bold')
  doc.text('Phương pháp điều trị:', xPos, yPos)
  yPos += 7
  doc.setFont('times', 'regular')
  doc.text('Nội khoa:', xPos, yPos)
  doc.text('Không', xPos + 25, yPos)
  doc.text('Có, ghi rõ: ', xPos + 60, yPos)
  if (!infoBNMat.NOIKHOA) {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, infoBNMat.NOIKHOA ? false : true)
    generateCheckbox(doc, xPos + 55, yPos - 3.5, 4, 4, infoBNMat.NOIKHOA ? true : false)
    doc.text(`${infoBNMat.NOIKHOA ?? ''}`, xPos + 80, yPos)
    const dotLineCC = generateDot(doc, infoBNMat.NOIKHOA ?? '', xPos + 80, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    const dotLineEmpty = generateDot(doc, '', xPos, yPos)
    doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
    yPos += 6
  } else {
    generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, infoBNMat.NOIKHOA ? false : true)
    generateCheckbox(doc, xPos + 55, yPos - 3.5, 4, 4, infoBNMat.NOIKHOA ? true : false)
    const noikhoaText = infoBNMat.NOIKHOA ?? ''
    //beakword
    if (doc.getTextWidth(noikhoaText) > 60) {
      const wordsArray = splitLongWord(noikhoaText, 60)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos + 80, yPos)
        yPos += 6
      }
    } else {
      doc.text(noikhoaText, xPos + 80, yPos)
      const dotLineCC = generateDot(doc, noikhoaText, xPos + 80, yPos)
      doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
      yPos += 6
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  doc.text('Phẫu thuật', xPos + 25, yPos)
  doc.text('Thủ thuật thực hiện trên phòng phẫu thuật', xPos + 60, yPos)
  generateCheckbox(doc, xPos + 20, yPos - 3.5, 4, 4, infoBNMat.PHAUTHUAT ? true : false)
  generateCheckbox(doc, xPos + 55, yPos - 3.5, 4, 4, infoBNMat.THUTHUATTHUCHIEN ? true : false)
  yPos += 6

  const thuthuatthuchienText = infoBNMat.THUTHUATTHUCHIEN ?? ''
  if (doc.getTextWidth(thuthuatthuchienText) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(thuthuatthuchienText, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(thuthuatthuchienText, xPos, yPos)
    const dotLineCC = generateDot(doc, thuthuatthuchienText, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  doc.setFont('times', 'bold')
  doc.text('2. Tình trạng ra viện:', xPos, yPos)
  yPos += 6
  doc.text('Toàn trạng', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const toantrang = infoBNMat.TINHTRANGRAVIEN_TOANTRANG ?? ''
  if (doc.getTextWidth(toantrang) > 210) {
    //210 is width of page
    const wordsArray = splitLongWordFitWidth(doc, toantrang, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(toantrang, xPos, yPos)
    const dotLineCC = generateDot(doc, toantrang, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  doc.setFont('times', 'bold')
  doc.text('Mắt phải', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const matphai = infoBNMat.TINHTRANGRAVIEN_MP ?? ''
  if (doc.getTextWidth(matphai) > 210) {
    //210 is width of page
    const wordsArray = splitLongWord(doc, matphai, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(matphai, xPos, yPos)
    const dotLineCC = generateDot(doc, matphai, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }

  doc.setFont('times', 'bold')
  doc.text('Mắt trái', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 6
  const mattrai = infoBNMat.TINHTRANGRAVIEN_MT ?? ''
  if (doc.getTextWidth(mattrai) > 210) {
    //210 is width of page
    const wordsArray = splitLongWordFitWidth(doc, mattrai, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
  } else {
    doc.text(mattrai, xPos, yPos)
    const dotLineCC = generateDot(doc, mattrai, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
  }
  doc.text('Thị lực ra viện:', xPos + 5, yPos)
  doc.text(
    `MP: ${infoBNMat.THILUCRAVIEN_MP ?? '...........'}   MT: ${infoBNMat.THILUCRAVIEN_MT ?? '...........'}`,
    xPos + 40,
    yPos,
  )

  doc.text('Nhãn áp ra viện:', xPos + 100, yPos)
  doc.text(`MP: ${infoBNMat.NHANAPRAVIEN_MP ?? '...........'} mmHg`, xPos + 130, yPos)
  yPos += 6
  doc.text('Thị lực chỉnh kính:', xPos + 5, yPos)
  doc.text(
    `MP: ${infoBNMat.THILUCCHINHKINH_MP ?? '...........'}   MT: ${infoBNMat.THILUCCHINHKINH_MT ?? '...........'}`,
    xPos + 40,
    yPos,
  )
  doc.text(`MT: ${infoBNMat.NHANAPRAVIEN_MT ?? '...........'} mmHg`, xPos + 130, yPos)
  yPos += 6
  doc.setFont('times', 'bold')
  doc.text('3. Hướng điều trị và các chế độ tiếp theo:', xPos, yPos)
  yPos += 6
  doc.setFont('times', 'regular')
  const huongdieutriTT = `${infoBNTTC.huongxuly ?? ''}`
  if (doc.getTextWidth(huongdieutriTT) > 210) {
    //210 is width of page
    const wordsArray = splitLongWordFitWidth(doc, huongdieutriTT, pageWidth - 25)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 4) {
      for (let i = 0; i < 4 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(huongdieutriTT, xPos, yPos)
    const dotLineCC = generateDot(doc, huongdieutriTT, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 3; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }

  const formattedDateTimeEnd = `Ngày ${moment(infoBNTTC.ngaykham).format('DD')} tháng ${moment(infoBNTTC.ngaykham).format('MM')} năm ${moment(infoBNTTC.ngaykham).format('YYYY')}`

  const dateEndTextWidth = doc.getTextWidth(formattedDateTime)

  const dateEndTextX = pageWidth - dateEndTextWidth - 35

  doc.text(formattedDateTimeEnd, dateEndTextX, yPos)
  yPos += 6
  doc.setFont('times', 'bold')
  doc.text(pageWidth - 40 - 'Bác sĩ điều trị'.length * 2, yPos, 'Bác sĩ điều trị')
  doc.setFont('times', 'italic')
  yPos += 6
  doc.text(pageWidth - 73, yPos, '(Ký, ghi rõ họ tên)')
  doc.setFont('times', 'bold')
  const doctorText = infoBNTTC.bskham ?? ''
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateCenterX = dateTextX + dateTextWidth / 2
  const doctorTextX = dateCenterX - doctorTextWidth / 2
  doc.text(doctorText, doctorTextX, yPos + 20)

  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
