import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, idleTime = 60000) => { // 60000 milliseconds = 1 minute
  const timerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(onIdle, idleTime);
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    const eventHandler = () => resetTimer();

    events.forEach(event => window.addEventListener(event, eventHandler));
    resetTimer();

    return () => {
      events.forEach(event => window.removeEventListener(event, eventHandler));
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return null;
};

export default useIdleTimer;