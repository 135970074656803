import React, { useCallback } from "react";
import { Input, Select, DatePicker, Radio } from "antd";
import { SearchOutlined } from '@ant-design/icons'
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { searchBN_Action } from "../../../../../store/actions/receiveAction";
import { fetchInfoBN } from "../../../../../store/actions/cardAction";
import ToastCus from '../../../../../utils/Toast'
const InfoBN = ({ isBNtang }) => {
  const dispatch = useDispatch();
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { listBN } = useSelector((state) => state.receiveReducer);
  const { infoBN, infoBNthu2 } = useSelector((state) => state.cartReducer);
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword, ID_COMPANY));
      }
    }, 300),
    []
  ); // sử dụng debounce để tối tiểu thánh server perfoman
  
  const handleInfoBN = (value) => {
    if (infoBN?.idbn === value || infoBNthu2?.idbn === value) {
      ToastCus.fire({
        icon: 'error',
        text: 'Bệnh nhân này đã được chọn!',
      })
      return
    }
    dispatch(fetchInfoBN(value, isBNtang))

  };
  return (
    <form className="text-start">
      <div className="flex flex-col gap-2">
        <div className="flex gap-1">
          <label className="w-[14%] text-end">Tìm BN:</label>
          {/* <Select
            size="small"
            onSearch={debounceDropDown}
            value={null}
            onChange={handleInfoBN}
            filterOption={false}
            notFoundContent={null}
            placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân"
            options={listBN?.map((items) => ({
              label: items.tenBenhNhan,
              value: items.idbn,
              MaBN: items.maBenhNhan,
              ngaySinh: items.ngaySinh
                ? moment(items.ngaySinh).format("DD/MM/YYYY")
                : "",
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-1/6">{options.data.MaBN}</li>
                <li className="w-4/6 border-x px-5">{options.data.label}</li>
                <li className="w-1/6 text-center">{options.data.ngaySinh}</li>
              </ul>
            )}
            showSearch
            allowClear
            className="w-full"
          /> */}
          <Select
            size="small"
            onSearch={debounceDropDown}
            value={null}
            onChange={handleInfoBN}
            filterOption={false}
            notFoundContent={null}
            placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân..."
            suffixIcon={<SearchOutlined className=" " />}
            options={listBN?.map((items) => ({
              label: items.tenbenhnhan,
              value: items.idbn,
              MaBN: items.mabenhnhan,
              ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
              diaChi: items.diachi,
              tinh: items.tentinhtp,
              quan: items.tenquanhuyen,
              xa: items.tenphuongxa,
              gioiTinh: items.gioitinh
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-4/6">
                  <p className="font-semibold">
                    {options.data.label} - {options.data.MaBN}
                  </p>
                  <i className="text-gray-500">
                    Địa chỉ: {options.data.diaChi}
                    {/* {options.data.xa && `, ${options.data.xa}`}
                    {options.data.quan && `, ${options.data.quan}`}
                    {options.data.tinh && `, ${options.data.tinh}`} */}
                  </i>
                </li>
                <li className="w-2/6 flex flex-col text-end pr-1">
                  <p>Ngày sinh: {options.data.ngaySinh} </p>
                  <i className="text-gray-500">Giới tính: {options.data.gioiTinh}</i>
                </li>
              </ul>
            )}
            showSearch
            allowClear
            className="w-full"
          />
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">
              <span className="text-sx text-red-500">(*)</span>
              Họ và tên:
            </label>
            <Input
              style={{ color: "black" }}
              disabled
              value={isBNtang ? infoBNthu2?.tenBenhNhan : infoBN?.tenBenhNhan}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Mã BN:</label>
            <Input
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.maBenhNhan : infoBN?.maBenhNhan}
              disabled
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Đối tượng:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.tenDoiTuong : infoBN?.tenDoiTuong}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1 ">
            <label className="w-1/3">Nghề nghiệp:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.tenNgheNghiep : infoBN?.tenNgheNghiep}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Ngày sinh:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.ngaySinh && moment(infoBNthu2?.ngaySinh).format("DD/MM/YYYY") : infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format("DD/MM/YYYY")}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3">Dân tộc:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.tenDanToc : infoBN?.tenDanToc}
              size="small"
            />
          </div>
        </div>
        {/* <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">CCCD:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.cccd : infoBN?.cccd}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3">Ngày cấp:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.ngayCap && moment(infoBNthu2?.ngayCap).format("DD/MM/YYYY") : infoBN?.ngayCap && moment(infoBN?.ngayCap).format("DD/MM/YYYY")}
              size="small"
            />
          </div>
        </div> */}
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Điện thoại:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.dienThoai : infoBN?.dienThoai}
              size="small"
            />
          </div>
          <div className="flex gap-1 w-1/2">
            <label className="w-1/3 ">Giới tính:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.gioiTinh : infoBN?.gioiTinh}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-1">
          <label className="w-[117px] text-end">Địa chỉ:</label>
          <Input
            disabled
            style={{ color: "black" }}
            value={isBNtang ? infoBNthu2?.diaChi : infoBN?.diaChi}
            size="small"
          />
        </div>
      </div>
    </form>
  );
};

export default InfoBN;
