import * as typeAction from '../constants/constants'

export const getAllPhanCongLienHe = () => ({
  type: typeAction.GET_ALL_PHANCONG_LIENHE_CRM,
})

export const getDetailPhanCongLienHe = (idpclh) => ({
  type: typeAction.GET_DETAIL_PHANCONG_LIENHE_CRM,
  idpclh,
})

export const getAllPhanCongLienHeByIdnv = (idnv) => ({
  type: typeAction.GET_PHANCONG_LIENHE_IDNV_CRM,
  idnv,
})

export const getAllTrangThai = () => ({
  type: typeAction.GET_TRANGTHAI_PHANCONG_LIENHE_CRM,
})

export const addListPhanCongLienHe = (form, onLoad, setLoading) => ({
  type: typeAction.ADD_LIST_PHANCONG_LIENHE_CRM,
  form,
  onLoad,
  setLoading,
})

export const editPhanCongLienHe = (form, onLoad) => ({
  type: typeAction.EDIT_PHANCONG_LIENHE_CRM,
  form,
  onLoad,
})
