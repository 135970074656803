import { Input, Select, Tag } from 'antd'
import React from 'react'

export default function ChitietVanBan() {
  return (
    <>
      <div className="p-2 border mx-2 mt-2 rounded-md">
        <div className="grid grid-cols-8 grid-rows-5 gap-1">
          <div className="col-span-2">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-24 font-semibold">Loại VB: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-3">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-24 font-semibold">Tên VB: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-5">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-24 font-semibold">Số VB: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-7">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-24 font-semibold">Trichyeu: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 row-start-2">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Người tạo: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-3 row-start-2">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Ngày tạo: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-5 row-start-2">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Người ký: </label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-7 row-start-2">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Ngày ký :</label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-4 row-start-3">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Ngày phát hành :</label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-4 col-start-5 row-start-3">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Đơn vị phát :</label>
              <div className="col-span-2">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-8 row-start-4">
            <div className="grid grid-cols-6 grid-rows-1 gap-4">
              <label className="w-36 font-semibold">Nội dung:</label>
              <div className="col-span-5">
                <Input
                  variant="filled"
                  readOnly
                  className="w-full" // Đảm bảo input chiếm toàn bộ chiều rộng của cột
                />
              </div>
            </div>
          </div>
          <div className="col-span-6 row-start-5">
            <div className="grid grid-cols-6 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Ghi chú :</label>
              <div className="col-span-5">
                <Input variant="filled" readOnly />
              </div>
            </div>
          </div>
          <div className="col-span-2 col-start-7 row-start-5">
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              <label className="w-36 font-semibold"> Trạng thái :</label>
              <div className="col-span-2">
                <Tag color="green" className="text-white font-semibold">
                  Đã xử lý
                </Tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
