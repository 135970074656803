import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listBN: null,
  infoBN: null,
}

const traCuuReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_TRACUU_LIST_BN:
        draft.listBN = payload
        break
      case typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN:
        draft.infoBN = payload
        break
      default:
        return state
    }
  })
}

export default traCuuReducer
