import * as typeAction from '../constants/constants'
export const getListDichVuTheAction = (idlt) => ({
  type: typeAction.GET_LIST_THE_DICH_VU,
  idlt,
})
export const addListDichVuTheAction = (listDichVuThe, idlt) => ({
  type: typeAction.POST_THE_DICH_VU,
  listDichVuThe,
  idlt,
})
export const getListDichVuAction = (idnhom, idct) => ({
  type: typeAction.GET_LIST_DICH_VU_LOAITHE,
  idnhom,
  idct,
})
export const getListXetNghiemAction = (idct) => ({
  type: typeAction.GET_LIST_XET_NGHIEM_LOAITHE,
  idct,
})
export const getListCDHAAction = (idct) => ({
  type: typeAction.GET_LIST_CDHA_LOAITHE,
  idct,
})
export const putInfoLoaiTheAction = (idlt, form) => ({
  type: typeAction.PUT_INFO_LOAITHE,
  idlt,
  form,
})
