export function numberToWords(num) {
    const units = ["", "nghìn", "triệu", "tỷ"];
    const digits = [
      "không",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
  
    if (num === 0) return "không đồng";
  
    let result = "";
    let unitIndex = 0;
  
    while (num > 0) {
      let part = num % 1000;
      if (part > 0) {
        let partResult = "";
        let hundreds = Math.floor(part / 100);
        let tens = Math.floor((part % 100) / 10);
        let ones = part % 10;
  
        if (hundreds > 0) {
          partResult += digits[hundreds] + " trăm ";
          if (tens === 0 && ones > 0) partResult += "linh ";
        }
  
        if (tens > 0) {
          if (tens === 1) {
            partResult += "mười ";
          } else {
            partResult += digits[tens] + " mươi ";
          }
          if (ones === 5 && tens !== 1) {
            partResult += "lăm ";
          } else if (ones > 0) {
            partResult += digits[ones] + " ";
          }
        } else if (ones > 0) {
          partResult += digits[ones] + " ";
        }
  
        if (unitIndex > 0) {
          result = partResult.trim() + " " + units[unitIndex] + ", " + result;
        } else {
          result = partResult.trim() + " " + units[unitIndex] + " " + result;
        }
      }
  
      num = Math.floor(num / 1000);
      unitIndex++;
    }
  
    result = result.trim().replace(/,\s*$/, "") + " đồng";
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  
  
  
  
  