import * as typeAction from '../constants/constants'
export const demoModalCAction = (value) => ({
  type: 'DEMO_MODAL',
  payload: value,
})

export const listBranchAction = (id) => ({
  type: typeAction.GET_LIST_BRANCH_API,
  id,
})
export const defaultBranchAction = (payload) => ({
  type: typeAction.GET_DEFAULT_BRANCH,
  payload,
})
export const listCaLamViec = () => ({
  type: typeAction.GET_LIST_CALAMVIEC,
})
export const getBranchByChuyenKhoa = (idct, idchuyenKhoa, setData) => ({
  type: typeAction.GET_BRANCH_BY_CHUYEN_KHOA_AND_COMPANY,
  idct,
  idchuyenKhoa,
  setData,
})
// action lấy thông tin cảu chi nhánh đăng nhập
export const infoBranchLogin = (idChiNhanh) => ({
  type: typeAction.GET_INFO_BRANCH_LOGIN,
  idChiNhanh,
})
export const listBranchDetail_Action = (keyword, IDCompany, setIsLoading) => ({
  type: typeAction.GET_LIST_DETAIL_BRANCH,
  keyword,
  IDCompany,
  setIsLoading,
})
// Lây thông tin chi tiết chi nhánh
export const DetailBranch = (idChiNhanh) => ({
  type: typeAction.GET_INFO_BRANCH,
  idChiNhanh,
})
// lấy thông tin tạo chi nhánh
export const staffAndCountry = () => ({
  type: typeAction.GET_COUNTRY_AND_STAFFS_BRANCH,
})
// lấy danh sách quận theo tỉnh
export const quanByCountryBranch = (IDCOUNTRY) => ({
  type: typeAction.GET_QUAN_BY_TINH_BRANCH,
  IDCOUNTRY,
})
// lấy danh sách quận theo tỉnh
export const huyenByQuanBranch = (IDQUAN) => ({
  type: typeAction.GET_HUYEN_BY_QUAN_BRANCH,
  IDQUAN,
})
// lấy danh sách quận theo tỉnh
export const addNewBranch = (form, resetForm) => ({
  type: typeAction.ADD_NEW_BRANCH,
  form,
  resetForm,
})
// lấy danh sách quận theo tỉnh
export const deleteBranchByID = (id, onReload) => ({
  type: typeAction.DELETE_BRANCH,
  id,
  onReload,
})
//-------------- update chi nhánh -----------//
export const updateBranch = (form) => ({
  type: typeAction.UPDATE_BRANCH,
  form,
})
export const getBranchNotVP = (id, setData) => ({
  type: typeAction.GET_BRANCH_NOT_VP,
  id,
  setData,
})
export const getThongKeDongMoCa = () => ({
  type: typeAction.THONG_KE_DONG_MO_CA,
})
