import * as typeAction from '../.../../constants/constants'

export const getAllNhanSuCo = (idnhom, keyword) => ({
  type: typeAction.GET_NHAN_SU_CO,
  idnhom,
  keyword,
})
export const createNhanSuCo = (form, onReset) => ({
  type: typeAction.ADD_NEW_NHAN,
  form,
  onReset,
})
export const createDanhMucNhan = (form, onReset) => ({
  type: typeAction.POST_DANH_MUC_NHAN_SU_CO,
  form,
  onReset,
})
export const getAllNhanDanhMuc = (setNhan) => ({
  type: typeAction.GET_NHAN_DANH_MUC,
  setNhan,
})
export const getDanhMucByNhan = (id, keyword) => ({
  type: typeAction.GET_DANH_MUC_BY_NHAN,
  id,
  keyword,
})
export const getInfoNhanSuCo = (id) => ({
  type: typeAction.GET_INFO_NHAN_SU_CO,
  id,
})
export const updateNhanSuCo = (form) => ({
  type: typeAction.UPDATE_NHAN_SU_CO,
  form,
})
export const deleteNhanSuCo = (id, handleCancel) => ({
  type: typeAction.DELETE_NHAN_SU_CO,
  id,
  handleCancel,
})
export const getNhanVienSuCoByVung = () => ({
  type: typeAction.GET_NHAN_VIEN_SU_CO_BY_VUNG,
})
export const getDanhSachBaoHong = (keyword, idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_DANH_SACH_BAO_HONG,
  keyword,
  idcn,
})
export const createVungBaoHong = (form, onReset) => ({
  type: typeAction.ADD_VUNG_BAO_HONG,
  form,
  onReset,
})
export const getVungBaoHong = (keyword) => ({
  type: typeAction.GET_VUNG_BAO_HONG,
  keyword,
})
export const createBaoHong = (form, nhanVien, nguoitao, fetchData, onReset) => ({
  type: typeAction.CREATE_BAO_HONG,
  form,
  nhanVien,
  nguoitao,
  fetchData,
  onReset,
})
export const getAllNhanVienBaoHong = (keyword, idnhom) => ({
  type: typeAction.GET_DANH_SACH_NHAN_VIEN_SU_CO,
  keyword,
  idnhom
})
export const addNhanVienBaoHong = (form, fetchData) => ({
  type: typeAction.POST_NHAN_VIEN_BAO_HONG,
  form,
  fetchData,
})
export const deleteNhanVienBaoHong = (form, reset) => ({
  type: typeAction.DELETE_NHAN_VIEN_BAO_HONG,
  form,
  reset,
})
export const getDetailBaoHong = (idbh) => ({
  type: typeAction.GET_INFO_BAO_HONG,
  idbh,
})
export const getAllHangMuc = () => ({
  type: typeAction.GET_ALL_HANG_MUC,
})
export const getInfoNhanByDanhMuc = (id, setInfoNhan, formik) => ({
  type: typeAction.GET_INFO_NHAN_BY_HANG_MUC,
  id,
  setInfoNhan,
  formik,
})
export const deleteFileBaoHong = (id, url) => ({
  type: typeAction.DELETE_FILE_BAO_HONG,
  id,
  url,
})
export const getKTThucHienBaoHong = (id) => ({
  type: typeAction.GET_KT_THUC_HIEN_BAO_HONG,
  id,
})
export const deleteNhanVienDetail = (form, reset) => ({
  type: typeAction.DELETE_NHAN_VIEN_THUC_HIEN_DETAIL,
  form,
  reset,
})
export const updateBaoHong = (form) => ({
  type: typeAction.UPDATE_BAO_HONG,
  form,
})
export const getImgBaoHong = (idbh) => ({
  type: typeAction.GET_IMGAE_BAO_HONG,
  idbh,
})
export const addChiNhanhVung = (form, fetchChiNhanhByVung, onReset) => ({
  type: typeAction.POST_CHI_NHANH_VUNG,
  form,
  fetchChiNhanhByVung,
  onReset,
})
export const getChiNhanhByVung = (idVung, keyword) => ({
  type: typeAction.GET_CHI_NHANH_BY_VUNG,
  idVung,
  keyword,
})
export const getInfoXuLy = (id) => ({
  type: typeAction.GET_INFO_XU_LY_BAO_HONG,
  id,
})
export const updateXuLyBaoHong = (formBaoHong, idxuly, noidung) => ({
  type: typeAction.UPDATE_XU_LY_SU_CO,
  formBaoHong,
  idxuly,
  noidung,
})
export const uploadFileXuLy = (form, idbh) => ({
  type: typeAction.UPLOAD_FILE_XU_LY,
  form,
  idbh,
})
export const deleteFileXuLy = (idxl, url, idbh) => ({
  type: typeAction.DELETE_FILE_XULY,
  idxl,
  url,
  idbh,
})
export const upLoadFileBaoHong = (form, idbh) => ({
  type: typeAction.UPLOAD_FILE_BAO_HONG,
  form,
  idbh,
})
export const getDanhSachHoangThanh = (keyword, idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_DANH_SACH_BAO_HONG_HOANG_THANH,
  keyword,
  idcn,
  tuNgay,
  denNgay,
})
export const getDanhSachThongKe = (keyword, idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_DANH_SACH_THONG_KE_BAO_HONG,
  keyword,
  idcn,
  tuNgay,
  denNgay,
})
export const addNhanVienVung = (form, fetchVung, setPopoverVisible) => ({
  type: typeAction.POST_NHAN_VIEN_VUNG,
  form,
  fetchVung,
  setPopoverVisible
})
export const deleteBaoHong = (id, handleCancel, fetchData) => ({
  type: typeAction.DELETE_BAO_HONG,
  id,
  handleCancel,
  fetchData,
})
export const updateStatus = (id, status) => ({
  type: typeAction.UPDATE_SU_CO_BAO_HONG,
  id,
  status,
})
export const deleteNhanVienVung = (id, fetchVung) => ({
  type: typeAction.DELETE_NHAN_VIEN_VUNG,
  id,
  fetchVung,
})
export const deleteChiNhanhVung = (id, fetchChiNhanhByVung) => ({
  type: typeAction.DELETE_CHI_NHANH_VUNG_BAO_HONG,
  id,
  fetchChiNhanhByVung,
})
export const updateKhuVuc = (form, onReset) => ({
  type: typeAction.UPDATE_KHU_VUC,
  form,
  onReset,
})

export const deleteVungBaoHong = (id, fetchVung) => ({
  type: typeAction.DELETE_VUNG_BAO_HONG,
  id,
  fetchVung,
})
export const deleteDanhMucNhan = (id, fetchDanhMucNhan) => ({
  type: typeAction.DELETE_DANH_MUC_NHAN,
  id,
  fetchDanhMucNhan,
})
export const updateDanhMuc = (form, handleCancel, fetchDanhMucNhan) => ({
  type: typeAction.UPDATE_DANH_MUC_NHAN,
  form,
  handleCancel,
  fetchDanhMucNhan,
})
export const chuyenNhanVien = (form, reset) => ({
  type: typeAction.CHUYEN_NHAN_VIEN_THUC_HIEN_BAO_HONG,
  form,
  reset,
})
export const historyStaff = (idbh) => ({
  type: typeAction.GET_HISTORY_STAFF_SU_CO,
  idbh,
})
