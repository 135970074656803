import React, { useCallback, useEffect, useState } from "react";
import { Modal, notification } from "antd";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../../schemas/changePasswordSchema";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, logoutUser } from "../../../store/actions/userAction";
import Results from "./Result/Results";
import { useNavigate } from "react-router-dom";
const TIME_LOGOUT = 5;
const Password = ({ isOpenModalPassWord, cancelModalPassword }) => {
  const { infoUser } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [logout, setLogoOut] = useState(false);
  const [countdown, setCountdown] = useState(TIME_LOGOUT);
  const openNotificationWithIcon = () => {
    api.error({
      message: "Thay đổi mật khẩu",
      description: "Mật khẩu cũ không đúng!",
      duration: 5,
    });
  };

  const openNotificationWithIconNew = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  const formik = useFormik({
    initialValues: {
      username: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    // validationSchema: changePasswordSchema,
    onSubmit: (value, action) => handleSubmit(value, action),
  });
  const handleSubmit = (value) => {
    if (value.newPassword !== value.confirmPassword) {
      openNotificationWithIconNew('Thay đổi mật khẩu', 'Mật khẩu mới và mật khẩu nhập lại không giống nhau!')
      return
    }
    value.username = infoUser?.taiKhoan;
    const { confirmPassword, ...other } = value;
    dispatch(
      changePassword(other, openNotificationWithIcon, handleLogOut)
    );
  };
  const handleLogOut = () => {
    // setCountdown(TIME_LOGOUT); // Reset countdown về 5 giây.
    setLogoOut(true);
  };
  useEffect(() => {
    let intervalId;
    if (logout && countdown > 0) {
      // console.log(countdown);
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000); // Giảm 1 sau mỗi giây (1000 ms).
    } else if (countdown === 0) {

      dispatch(logoutUser(navigate,infoUser?.dangNhap.idDangNhap))
    }

    return () => clearInterval(intervalId); // Dọn dẹp interval khi component bị hủy.
  }, [logout, countdown]);
  return (
    <Modal
      footer={null}
      className="text-center"
      title="Đổi mật khẩu"
      open={isOpenModalPassWord}
      onCancel={!logout ? cancelModalPassword : null}
    >
      {contextHolder}
      {logout ? (
        <Results countdown={countdown} />
      ) : (
        <section>
          <div className="text-start bg-white relative items-center w-full  mx-auto  max-w-7xl">
            <div className="w-full max-w-md mx-auto md:max-w-sm md:px-0 md:w-96 sm:px-4">
              <form onSubmitCapture={formik.handleSubmit}>
                <input type="hidden" name="_redirect" />
                <div className="mt-4 space-y-6">
                  <div className="col-span-full">
                    <label className="block mb-3 text-sm font-medium text-gray-600">
                      {" "}
                      Mật khẩu cũ{" "}
                    </label>
                    <input
                      onChangeCapture={formik.handleChange}
                      name="oldPassword"
                      type="password"
                      placeholder="******"
                      className="block w-full px-6 py-3 text-black bg-white border border-gray-200 rounded-full appearance-none placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                    {formik.errors.oldPassword &&
                      formik.touched.oldPassword && (
                        <span className="text-red-500  text-base">
                          {" "}
                          * {formik.errors.oldPassword}
                        </span>
                      )}
                  </div>
                  <div className="col-span-full">
                    <label className="block mb-3 text-sm font-medium text-gray-600">
                      {" "}
                      Mật khẩu mới{" "}
                    </label>
                    <input
                      onChangeCapture={formik.handleChange}
                      name="newPassword"
                      type="password"
                      placeholder="******"
                      className="block w-full px-6 py-3 text-black bg-white border border-gray-200 rounded-full appearance-none placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                    {formik.errors.newPassword &&
                      formik.touched.newPassword && (
                        <span className="text-red-500  text-base">
                          {" "}
                          * {formik.errors.newPassword}
                        </span>
                      )}
                  </div>
                  <div className="col-span-full">
                    <label className="block mb-3 text-sm font-medium text-gray-600">
                      {" "}
                      Nhập lại mật khẩu{" "}
                    </label>
                    <input
                      onChangeCapture={formik.handleChange}
                      name="confirmPassword"
                      type="password"
                      placeholder="******"
                      className="block w-full px-6 py-3 text-black bg-white border border-gray-200 rounded-full appearance-none placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                    {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword && (
                        <span className="text-red-500  text-base">
                          {" "}
                          * {formik.errors.confirmPassword}
                        </span>
                      )}
                  </div>

                  <div className="col-span-full">
                    <button
                      type="submit"
                      className="items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-blue-500 border-2 border-blue-500 rounded-full line-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
                    >
                      {" "}
                      Lưu thay đổi{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      )}
    </Modal>
  );
};

export default Password;
