import * as typeAction from '../constants/constants'
export const fetchListTypesCard_Action = (idChuyenKhoa, keyword) => ({
  type: typeAction.GET_ALL_TYPE_CARD,
  idChuyenKhoa,
  keyword,
})
export const fetchInfoBN = (idbn, isBNtang) => ({
  type: typeAction.GET_INFO_BN_CARD,
  idbn,
  isBNtang,
})
export const fetchInfoBNTangNhiCuaTheSan = (idbn, isBNtang) => ({
  type: typeAction.GET_INFO_BN_CARD_TANGSAN,
  idbn,
  isBNtang,
})
export const fetchInfoTypeCard = (IDLOAI, idcn, idTinh, idbn) => ({
  type: typeAction.GET_INO_TTV_THU_NGAN,
  IDLOAI,
  idcn,
  idTinh,
  idbn,
})
export const AddCardBN = (
  form,
  typeCard,
  idbn,
  idChiNhanh,
  formTT,
  setIsModalOpen,
  formPrint,
  formLoad,
  formBNthu2,
) => ({
  type: typeAction.ADD_NEW_CARD_BN,
  form,
  typeCard,
  idbn,
  idChiNhanh,
  formTT,
  setIsModalOpen,
  formPrint,
  formLoad,
  formBNthu2,
})
export const cardReceiveAction = (idChiNhanh, tuNgay, denNgay, idlt, idnhom, keyword) => ({
  type: typeAction.GET_ALL_CARD_RECEIVE,
  idChiNhanh, //arr
  tuNgay,
  denNgay,
  idlt,
  idnhom,
  keyword,
})
export const infoCardBN = (idbn, idbnthu2, idthe, idcn) => ({
  type: typeAction.INFO_CARD_BN,
  idbn,
  idbnthu2,
  idthe,
  idcn,
})
export const activeCardPay = (idthe, openNotificationWithIcon) => ({
  type: typeAction.ACTIVE_CARD,
  idthe,
  openNotificationWithIcon,
})
export const searchPaymentCard = (keyword) => ({
  type: typeAction.GET_ALL_PAYMEMNT_CARD,
  keyword,
})
export const inFoPaymentCard = (idthe, idbn) => ({
  type: typeAction.GET_INFO_PAYMENR_CARD,
  idthe,
  idbn,
})
export const rechargePaymentCard = (form, closeModal) => ({
  type: typeAction.POST_RECHARGE_PAYMENT_CARD,
  form,
  closeModal,
})
export const historyPaymentCards = (idthe, idbn, tuNgay, denNgay, setIsLoading) => ({
  type: typeAction.GET_HISTORY_PAYMENT_CARD,
  idthe,
  idbn,
  tuNgay,
  denNgay,
  setIsLoading,
})
export const addNewPaymentCard = (idlt, idBN, idChiNhanh, form, handleResetForm) => ({
  type: typeAction.POST_PAYMENT_CARD,
  idlt,
  idBN,
  idChiNhanh,
  form,
  handleResetForm,
})

export const getListDiscountTTV = (listDV) => ({
  type: typeAction.GET_LIST_DISCOUNT_TTV,
  listDV,
})
export const removeListDiscountTTV = (donGiaTTV, dataTypeCard) => ({
  type: typeAction.REMOVE_LIST_DISCOUNT_TTV,
  donGiaTTV,
  dataTypeCard,
})

export const resetFormTTV = () => ({
  type: typeAction.RESET_FORM_TAO_TTV,
})
export const getHistoryMemberCardAction = (idthe, idbn, tungay, denngay) => ({
  type: typeAction.GET_HISTORY_MEMBER_CARD,
  idthe,
  idbn,
  tungay,
  denngay,
})
export const putPauseMemberCardAction = (idthe, ngayBaoLuu, idnv, formLoad) => ({
  type: typeAction.PUT_PAUSE_MEMBER_CARD,
  idthe,
  ngayBaoLuu,
  idnv,
  formLoad,
})
export const putDisableMemberCardAction = (idthe, idnv, formLoad) => ({
  type: typeAction.PUT_DISABLE_MEMBER_CARD,
  idthe,
  idnv,
  formLoad,
})
export const putRueseMemberCardAction = (idthe, ngaybaoluu, idnv, formLoad) => ({
  type: typeAction.PUT_RUESE_MEMBER_CARD,
  idthe,
  ngaybaoluu,
  idnv,
  formLoad,
})
export const getSoLanKhamAction = (idthe, idbn) => ({
  type: typeAction.GET_SO_LAN_KHAM_MEMBER_CARD,
  idthe,
  idbn,
})

export const putCreateMemberCardTTTAction = (idthe, form, formPrint, formLoad, formBNthu2) => ({
  type: typeAction.PUT_CREATE_MEMBER_CARD_TTT,
  idthe,
  form,
  formPrint,
  formLoad,
  formBNthu2,
})

export const getListTypeCardByCNAction = (idcn) => ({
  type: typeAction.GET_LIST_TYPES_CARD_BY_CN,
  idcn,
})
export const putUpgradeMemberCardAction = (form, formPrint, formLoad, formBNthu2) => ({
  type: typeAction.PUT_UPGRADE_MEMBER_CARD,
  form,
  formPrint,
  formLoad,
  formBNthu2,
})
export const putExtendMemberCardAction = (form, formPrint, formLoad) => ({
  type: typeAction.PUT_EXTEND_MEMBER_CARD,
  form,
  formPrint,
  formLoad,
})
export const getInfoPhieuThuAction = (idthept, idbn, idbnthu2, idthe) => ({
  type: typeAction.GET_INFO_PHIEUTHU_TTV,
  idthept,
  idbn,
  idbnthu2,
  idthe,
})
export const getCacDanhMucSearchTTVAction = () => ({
  type: typeAction.GET_ALL_DANHMUC_SEARCH_TTV,
})
export const postTheNhiTangKhiCoTheSan = (form, formBNthu2, infoTTVSan, handleClose) => ({
  type: typeAction.POST_TTV_NHI_TANGSAN,
  form,
  formBNthu2,
  infoTTVSan,
  handleClose,
})
export const postLoaiTheAction = (form, resetForm, formLoad) => ({
  type: typeAction.POST_LOAI_THE,
  form,
  resetForm,
  formLoad,
})
