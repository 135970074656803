import { call, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { dangKyKhamOnlineService } from '../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import ToastCus from '../../utils/Toast'

export function* doiTacKHSaga() {
  // ADD ĐỐI TÁC KHÁCH HÀNG
  yield takeLatest(
    typeAction.POST_DOI_TAC_KH,
    function* postDoiTacKHSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dangKyKhamOnlineService.postDoiTacKH([form]))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // UPDATE ĐỐI TÁC KHÁCH HÀNG
  yield takeLatest(typeAction.PUT_DOI_TAC_KH, function* putDoiTacKHSaga({ type, form, resetForm }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => dangKyKhamOnlineService.putDoiTacKH(form))
      yield call(() => resetForm())
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
