import React, { useEffect, useState, useRef, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  ConfigProvider,
  Pagination,
  Table,
  Button,
  Space,
  Input,
  Select,
  Tooltip,
  Checkbox,
  message,
} from 'antd'
import {
  SearchOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../services/apiService'
import { nhanvienService } from '../../../services/nhanvien/nhanvienService'
import moment from 'moment'
import AddStaff from './modal/AddStaff'
import EditStaff from './modal/EditStaff'
import SeeEraStaff from './modal/modal2/SeeEraStaff'
import * as XLSX from 'xlsx'
import _ from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')
const { Search } = Input
const Staff = () => {
  const dispatch = useDispatch()
  const { stafs, PkDangNhap } = useSelector((state) => state.branchReducer)
  const [listNV, setListNV] = useState([])
  // State tìm kiếm, phân trang
  const [searchKeyword, setSearchKeyWord] = useState(null) // lưu trữ từ khóa tìm kiếm.
  const [currentPage, setCurrentPage] = useState(1) // lưu trữ trang all DSNV.
  const [pageSize, setPageSize] = useState(20) // Số lượng hiển thị trên mỗi trang.
  const [nextPage, setNextPage] = useState(1) // lưu trữ DSNV by CN
  // ============
  const [companies, setCompanies] = useState([]) // danh sách côngty
  const [branch, setBranch] = useState([]) // chi nhánh thuộc côngty
  const [idCT, setIdCT] = useState(PkDangNhap?.idCongTy ?? null) // ID của công ty được chọn.
  const [idCN, setIdCN] = useState(null) // ID chi nhánh
  const [positionData, setPositionData] = useState()
  // State modal
  const [isModalOpen, setIsOpen] = useState(false) // modal thêm
  const [isEdit, setItEdit] = useState(false) // modal sửa
  const [isViewOpen, setViewOpen] = useState(false) // modal xem
  const [isErase, setIsErase] = useState(false) // modal xoá
  const [editingRecord, setTingRecord] = useState(null) // lưu thông tin thêm, sửa
  const [viewingRecord, setViewingRecord] = useState(null) // lưu thông tin xem
  const [deleteRecordId, setDeleteId] = useState(null) // xoá idnv
  const [loading, setLoading] = useState(false)
  const { Option } = Select

  const debounceSearchNV = useCallback(
    _.debounce((e) => {
      let kw = e?.target?.value ? e?.target?.value?.trim() : null
      if (kw?.length > 0) {
        setSearchKeyWord(kw)
        setCurrentPage(1)
      } else setSearchKeyWord(null)
    }, 300),
    [],
  )
  const getListNhanVien = async () => {
    try {
      setLoading(true)
      const result = await nhanvienService.getSearchNhanVien(
        searchKeyword?.trim(),
        idCN,
        idCT,
        currentPage,
      )
      setListNV(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListNhanVien()
    // _.debounce(() => { getListNhanVien() }, 100)
  }, [searchKeyword, idCN, idCT, currentPage])

  // Lấy dữ liệu từ API
  const fetchData = async (url, params) => {
    try {
      setLoading(true)
      const result = await https.get(url, { params })
      setLoading(false)
      return result.data
    } catch (err) {
      setLoading(false)
      console.error(err)
      message.error('Không thể lấy dữ liệu từ API')
      return null
    }
  }
  const fetchCompanies = async () => {
    try {
      const data = await fetchData('CongTy')
      if (data) {
        const mappedCompanies = data.map((company) => ({
          id: company.idct,
          name: company.tenct,
        }))
        setCompanies(mappedCompanies)
        setIdCT(1)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // Hàm lấy danh sách công ty
  useEffect(() => {
    fetchCompanies()
  }, [])
  // Danh sách chi nhánh theo công ty
  async function Branch(idCT) {
    try {
      const result = await https.get(`ChiNhanh`, {
        params: { idCT },
      })
      setBranch(result.data)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (idCT) {
      Branch(idCT)
    }
  }, [idCT])
  // Nhân viên theo chi nhanh
  const fetchUserByIDCN = async (idcn, page) => {
    try {
      const result = await nhanvienService.getNhanVienByIDCN(idcn, page)
      dispatch({ type: 'DISPATCH_STAFFS', payload: result.data })
    } catch (error) {
      console.error('Không có NhanVien thuộc IDCN:', error) // Xử lý lỗi ở đây nếu cần
      message.error('Không có NhanVien thuộc IDCN')
    }
  }
  useEffect(() => {
    // Truyền id của chi nhánh vào hàm nvByIDCN khi gọi
    // if (idCN) {
    //   fetchUserByIDCN(idCN, nextPage)
    // }
    // Tránh việc truyền mảng rỗng nếu bạn cần sử dụng idcn từ một nguồn nào đó
  }, [idCN, nextPage])
  // Xử lý khi chọn công ty
  const handleCompany = (value) => {
    setIdCT(value)
    setIdCN(null) // Reset chi nhánh khi thay đổi cty
  }
  // Xử lý khi chọn chi nhánh
  const handleBranch = (value) => {
    if (value === 'all') {
      setIdCN(null)
      // fetchListAndSearch(currentPage, searchKeyword, null)
    } else {
      setIdCN(value)
      // fetchListAndSearch(currentPage, searchKeyword, value)
    }
    setCurrentPage(1)
    setNextPage(1)
  }

  // Đổi trang
  const onChangePage = (page) => {
    setCurrentPage(page)
    // setNextPage(page)
    // fetchListAndSearch(page, searchKeyword, idCN)
  }
  // Load lại page
  const onReload = () => {
    setCurrentPage(1)
    setSearchKeyWord(null)
    setIdCN(PkDangNhap?.idChiNhanh ?? null)
    setIdCT(PkDangNhap?.idCongTy ?? null)
    // Đặt lại ID chi nhánh đã chọn
    // Tải lại toàn bộ danh sách nhân viên
    // fetchListAndSearch(1, '', null)
  }
  // Hàm lấy chức danh
  const fetchPosition = async () => {
    try {
      const result = await nhanvienService.getTitle()
      setPositionData(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPosition()
  }, [])
  // Button thêm nhân viên, đóng-mở form sau khi thêm
  const handleAddStaff = () => {
    setIsOpen(true)
  }
  const cancelAddModal = () => {
    setIsOpen(false)
  }
  // Button sửa nhân viên, đóng-mở form sau khi sửa
  const handleEditRecord = (record) => {
    setTingRecord(record)
    setItEdit(true)
  }
  const cancelEditModal = () => {
    setItEdit(false)
  }
  // Button xem tt nhân viên, đóng-mở form
  const handleViewRecord = (record) => {
    setViewingRecord(record)
    setViewOpen(true)
  }
  const cancelSeeModal = () => {
    setViewOpen(false)
    setViewingRecord(null)
  }
  // Hàm mở modal xóa và thiết lập deleteRecordId, đóng-mở form sau khi xóa
  const handleEraseRecord = (idnv) => {
    setDeleteId(idnv) // Thiết lập deleteRecordId với idnv cần xóa
    setIsErase(true)
  }
  const cancelEraseModal = () => {
    setIsErase(false)
    setDeleteId(null)
  }
  // gọi lại DSIDNV
  const onSuccess = () => {
    // fetchListAndSearch(currentPage, searchKeyword, idCN)
  }

  const exportToExcel = async () => {
    try {
      const headers = [
        'Số thứ tự',
        'Mã nhân viên',
        'Tên tắc chức danh',
        'Tên nhân viên',
        'Giới tính',
        'Ngày sinh',
        // 'Số điện thoại',
        'Bằng cấp',
        'Chức danh',
        'Địa chỉ',
        'Phường(xã)',
        'Quận(huyện)',
        'Tỉnh(TP)',
        'Tên chi nhánh',
        'Tên công ty',
        'Người tạo',
        'Ngày tạo',
        'Người sửa',
        'Ngày sửa',
        'Đã nghỉ việc',
        'Tên máy chấm công',
        'Ghi chú',
      ]
      const dataToExport = listNV?.data?.map((item, index) => ({
        'Số thứ tự': index + 1 || '',
        'Mã nhân viên': item.manv || '',
        'Tên tắc chức danh': item?.chucdanhviettat || '',
        'Tên nhân viên': item.tennv || '',
        'Ngày sinh': item.ngaysinh ? moment(item.ngaysinh).format('DD/MM/YYYY') : '',
        'Giới tính': item.gioitinh || '',
        // 'Số điện thoại': item.sodienthoai || '',
        'Bằng cấp': item.bangcap || '',
        'Chức danh': item.tenchucdanh || '',
        'Địa chỉ': item.diachi || '',
        'Phường(xã)': item.tenphuong || '',
        'Quận(huyện)': item.tenquan || '',
        'Tỉnh(TP)': item.tentinh || '',
        'Tên chi nhánh': item.tenchinhanh || '',
        'Tên công ty': item.tencongty || '',
        'Người tạo': item.nguoitao || '',
        'Ngày tạo': item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm') : '',
        'Người sửa': item.nguoisua || '',
        'Ngày sửa': item.ngaysua ? moment(item.ngaysua).format('DD/MM/YYYY HH:mm') : '',
        'Đã nghỉ việc': item?.thoiviec === 1 ? 'Đã nghỉ việc' : '',
        'Tên máy chấm công': item.tenmaychamcong || '',
        'Ghi chú': item.ghichu || '',
      }))
      const worksheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Staff List')
      XLSX.writeFile(wb, `Danh Sách Nhân Viên - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`)
    } catch (error) {
      console.error('Lỗi khi xuất dữ liệu:', error)
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      align: 'center',
      width: 45,
      render: (text, record, index) => {
        return (currentPage - 1) * pageSize + index + 1
      },
      fixed: 'left',
    },
    {
      title: 'Mã NV',
      dataIndex: 'manv',
      key: 'manv',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Tên tắt chức danh',
      dataIndex: 'chucdanhviettat',
      key: 'chucdanhviettat',
      width: 140,
      fixed: 'left',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'tennv',
      key: 'tennv',
      width: 250,
      fixed: 'left',
    },
    {
      title: 'Giới tính',
      dataIndex: 'gioitinh',
      key: 'gioitinh',
      align: 'center',
      width: 70,
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
      align: 'center',
      width: 90,
      // Kiểm tra nếu giá trị ngày sinh hợp lệ
      render: (ngaysinh) => (ngaysinh ? moment(ngaysinh).format('DD/MM/YYYY') : null),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      render: (text, record) => {
        const { tentinh, tenquan, tenphuong } = record
        const addressParts = [text, tenphuong, tenquan, tentinh].filter((part) => part)
        const fullAddress = addressParts.join(', ')
        return <span>{fullAddress}</span>
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 200,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      align: 'center',
      width: 140,
      render: (ngaytao) => (ngaytao ? moment(ngaytao).format('DD/MM/YYYY HH:mm:ss') : null),
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 200,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      align: 'center',
      width: 140,
      render: (ngaysua) => (ngaysua ? moment(ngaysua).format('DD/MM/YYYY HH:mm:ss') : null),
    },
    {
      title: 'Chức danh',
      dataIndex: 'idchucdanh',
      key: 'idchucdanh',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (id) => positionData?.find((item) => item.idcd === id)?.tenchucdanh || id,
    },
    {
      title: 'Đã nghỉ việc',
      dataIndex: 'thoiviec',
      key: 'thoiviec',
      align: 'center',
      fixed: 'right',
      width: 90,
      render: (thoiviec) => <Checkbox checked={thoiviec === 1} />,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <div className="flex justify-around items-center">
          <Tooltip
            title="Xem thông tin nhân viên"
            color="#1890ff"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <EyeOutlined
              style={{ color: '#1890ff', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleViewRecord(record)}
            />
          </Tooltip>
          <Tooltip
            title="Sửa thông tin nhân viên"
            color="#52c41a"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <EditOutlined
              style={{ color: '#52c41a', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleEditRecord(record)}
            />
          </Tooltip>
          <Tooltip
            title="Xoá thông tin nhân viên"
            color="#ff4d4f"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <DeleteOutlined
              style={{ color: '#ff4d4f', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleEraseRecord(record.id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="bg-white rounded-xl p-2">
          <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
            <div className="flex flex-col md:flex-row gap-2" style={{ flex: 2 }}>
              <Select
                style={{ width: '100%', maxWidth: '260px' }}
                name="tenct"
                placeholder="Chọn công ty"
                onChange={handleCompany}
                value={idCT}
              >
                {companies.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%', maxWidth: '250px' }}
                name="tenchinhanh"
                placeholder="Chọn chi nhánh"
                showSearch
                onChange={handleBranch}
                value={idCN || 'all'}
                options={[
                  { label: 'Tất cả', value: 'all' },
                  ...(branch?.map((branch) => ({
                    label: branch.tenChiNhanh,
                    value: branch.idChiNhanh,
                  })) || []),
                ]}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Input
                placeholder="Tìm kiếm nhân viên..."
                prefix={<SearchOutlined className="pr-1" />}
                className="w-[250px]"
                // value={searchKeyword}
                onChange={debounceSearchNV}
                // onSearch={debounceSearchNV}
                //style={{ width: '100%', maxWidth: '250px' }}
              />
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <Button
                onClick={exportToExcel}
                type="default"
                className="rounded-lg border border-gray-300 hover:border-gray-400"
                icon={<GetAppIcon />}
              >
                <span>Xuất Excel</span>
              </Button>
              <Button
                type="primary"
                className="rounded-lg bg-blue-500 hover:bg-blue-600"
                icon={<PlusCircleOutlined />}
                onClick={handleAddStaff}
              >
                <span>Thêm nhân viên</span>
              </Button>
              {/* <Button type="primary" icon={<SyncOutlined />} onClick={onReload}>
                Tải lại
              </Button> */}
            </div>
          </div>

          <ConfigProvider
            renderEmpty={() => <div>Không tìm thấy nhân viên</div>}
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              rowKey="id"
              scroll={{
                x: 2000,
              }}
              pagination={false}
              columns={columns}
              loading={loading}
              dataSource={listNV?.data?.map((items, index) => ({
                key: index,
                id: items?.id,
                manv: items?.manv,
                tennv: items?.tennv,
                diachi: items?.diachi,
                dienthoai: items?.dienthoai,
                idtinh: items?.idtinh,
                idquan: items?.idquan,
                idphuong: items?.idphuong,
                idchinhanh: items?.idchinhanh,
                nguoitao: items?.nguoitao,
                ngaytao: items.ngaytao,
                idchucdanh: items?.idchucdanh,
                ghichu: items?.ghichu,
                idbangcap: items?.idbangcap,
                tenmaychamcong: items?.tenmaychamcong,
                ngaysinh: items?.ngaysinh,
                gioitinh: items?.gioitinh,
                thoiviec: items?.thoiviec,
                nguoisua: items?.nguoisua,
                ngaysua: items?.ngaysua,
                idct: items?.idct,
                tencongty: items?.tencongty,
                tenchinhanh: items?.tenchinhanh,
                tentinh: items?.tentinh,
                tenquan: items?.tenquan,
                tenphuong: items?.tenphuong,
                tenchucdanh: items?.tenchucdanh,
                bangcap: items?.bangcap,
                chucdanhviettat: items?.chucdanhviettat,
              }))}
            />
            <div className="rounded-md mt-2 flex justify-end items-center">
              <Pagination
                onChange={onChangePage}
                showSizeChanger={false}
                current={currentPage}
                pageSize={20}
                total={(listNV?.totalPages ?? 1) * 20}
                // showTotal: {
                //   total: (total, range) => `${total}`,
                //   position: ['bottomRight']
                //   }
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
      {isModalOpen && (
        <AddStaff
          stafs={stafs}
          visible={isModalOpen}
          cancelAddModal={cancelAddModal}
          setModalVisibility={setIsOpen}
          setPage={setCurrentPage}
          pageSize={pageSize}
          modifyTing={setTingRecord} // lưu thông tin thêm vào.
          onAddSuccess={onSuccess}
          getListNhanVien={getListNhanVien}
        />
      )}
      {isEdit && (
        <EditStaff
          editMode={isEdit}
          cancelEditModal={cancelEditModal}
          updateEditState={setItEdit}
          record={editingRecord} // Lưu thông tin chỉnh sửa.
          onEditSuccess={onSuccess}
          getListNhanVien={getListNhanVien}
        />
      )}
      {isViewOpen && (
        <SeeEraStaff
          togglePanel={isViewOpen}
          cancelSeeModal={cancelSeeModal}
          view={viewingRecord} // lưu trữ thôg tin được xem.
          professional={positionData} // Truyền positionData như một prop
          branch={branch}
          getListNhanVien={getListNhanVien}
        />
      )}
      {isErase && (
        <SeeEraStaff
          isErase={isErase}
          cancelEraseModal={cancelEraseModal}
          setIsErase={setIsErase}
          deleteIdnv={deleteRecordId} // Truyền deleteRecordId
          setDeleteIdnv={setDeleteId} // Truyền setDeleteId
          onEraseSuccess={onSuccess}
          getListNhanVien={getListNhanVien}
        />
      )}
    </>
  )
}

export default Staff
