import {
  Button,
  ConfigProvider,
  Select,
  Skeleton,
  Table,
  Input,
  Divider,
  notification,
  Tabs,
  DatePicker,
  TimePicker,
  Radio,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const FormLSTC = () => {
  const dispatch = useDispatch()
  const today = moment()
  const formatDateAPI = 'YYYY-MM-DD'
  const dateFormat = 'DD/MM/YYYY'
  const timeFormat = 'HH:mm'
  const [dateForm, setDateForm] = useState(today.format(formatDateAPI))
  const [dateTo, setDateTo] = useState(today.format(formatDateAPI))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBNTiemChung, DetailToaThuocBN, infoTheoDoiSauTiem } = useSelector(
    (state) => state.tiemChungReducer,
  )

  return (
    <>
      <div className="w-full mt-5">
        <Divider
          orientation="left"
          style={{
            margin: '10px 0',
          }}
        >
          <span className="">Thông tin kết quả</span>
        </Divider>
        <>
          <div className="mb-2">
            {/* <Divider orientation="left">Vật tư tiêu hao</Divider> */}
            <div className="p-2">
              <div className="flex ">
                <div className="flex gap-1 w-1/4">
                  <label className="w-1/2 text-end font-semibold">
                    <span className="text-red-500">(*)&nbsp;</span>Ngày tiêm:
                  </label>
                  <Input
                    variant="filled"
                    readOnly
                    className="w-2/3 text-center"
                    size="small"
                    value={
                      infoTheoDoiSauTiem?.ngaytiem &&
                      moment(infoTheoDoiSauTiem?.ngaytiem).format(dateFormat)
                    }
                  />
                  {/* <DatePicker
                                        // status={formik.values.ngaytiem?.length <= 0 ? 'error' : ''}
                                        // placeholder='Chọn ngày tiêm...'
                                        // allowClear={false}
                                        // format={dateFormat}
                                        // maxDate={dayjs(now)}
                                        // value={formik.values.ngaytiem && dayjs(formik.values.ngaytiem)}
                                        // onChange={handleDateChange('ngaytiem')}
                                        className="w-2/3"
                                        size="small"
                                    /> */}
                </div>
                <div className="flex gap-1 w-1/4">
                  <label className="w-1/2 text-end font-semibold">
                    <span className="text-red-500">(*)&nbsp;</span>Ngày k.tra:
                  </label>
                  <Input
                    variant="filled"
                    readOnly
                    className="w-2/3 text-center"
                    size="small"
                    value={
                      infoTheoDoiSauTiem?.ngaykiemtra &&
                      moment(infoTheoDoiSauTiem?.ngaykiemtra).format(dateFormat)
                    }
                  />
                  {/* <DatePicker
                                        // status={formik.values.ngaykiemtra?.length <= 0 ? 'error' : ''}
                                        // placeholder='Chọn ngày kiểm tra...'
                                        // allowClear={false}
                                        // format={dateFormat}
                                        // minDate={formik.values.ngaytiem && dayjs(formik.values.ngaytiem)}
                                        // maxDate={dayjs(now)}
                                        // onChange={handleDateChange('ngaykiemtra')}
                                        // value={formik.values.ngaykiemtra && dayjs(formik.values.ngaykiemtra)}
                                        className="w-2/3"
                                        size="small"
                                    /> */}
                </div>
                <div className="flex gap-1 w-1/4 ">
                  <label className="w-1/2 text-end font-semibold">Mạch:</label>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.machbenhnhan}
                    variant="filled"
                    readOnly
                    name="machbenhnhan"
                    className="w-2/3 text-center"
                    addonAfter="Lần/p"
                    size="small"
                    value={infoTheoDoiSauTiem?.machbenhnhan}
                  />
                </div>
                <div className="flex gap-1 w-1/4 ">
                  <label className="w-1/2 text-end font-semibold">Nhiệt độ:</label>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.nhietdo}
                    variant="filled"
                    readOnly
                    name="nhietdo"
                    className="w-2/3 text-center"
                    addonAfter={<>&#8451;</>}
                    size="small"
                    value={infoTheoDoiSauTiem?.nhietdo}
                  />
                </div>
              </div>
              <div className="flex mt-1">
                <div className="flex gap-1 w-1/4 ">
                  <label className="w-1/2 text-end font-semibold">
                    <span className="text-red-500">(*)&nbsp;</span>Giờ tiêm:
                  </label>
                  <Input
                    variant="filled"
                    readOnly
                    className="w-2/3 text-center"
                    size="small"
                    value={
                      infoTheoDoiSauTiem?.giotiem &&
                      moment(infoTheoDoiSauTiem?.giotiem).format(timeFormat)
                    }
                  />
                  {/* <TimePicker
                                        // status={formik.values.giotiem?.length <= 0 ? 'error' : ''}
                                        // placeholder='Chọn giờ tiêm...'
                                        // allowClear={false}
                                        // needConfirm={false}
                                        // format={timeFormat}
                                        // onChange={handleTimeChange('giotiem')}
                                        // value={formik.values.giotiem && dayjs(formik.values.giotiem, dateFormatAPI + 'T' + timeFormat + ':ss')}
                                        className="w-2/3"
                                        size="small"
                                    /> */}
                </div>
                <div className="flex gap-1 w-1/4 ">
                  <label className="w-1/2 text-end font-semibold">
                    <span className="text-red-500">(*)&nbsp;</span>Giờ k.tra:
                  </label>
                  <Input
                    variant="filled"
                    readOnly
                    className="w-2/3 text-center"
                    size="small"
                    value={
                      infoTheoDoiSauTiem?.giokiemtra &&
                      moment(infoTheoDoiSauTiem?.giokiemtra).format(timeFormat)
                    }
                  />
                  {/* <TimePicker
                                        // status={formik.values.giokiemtra?.length <= 0 ? 'error' : ''}
                                        // placeholder='Chọn giờ kiểm tra...'
                                        // allowClear={false}
                                        // needConfirm={false}
                                        // format={timeFormat}
                                        // onChange={handleTimeChange('giokiemtra')}
                                        // value={formik.values.giokiemtra && dayjs(formik.values.giokiemtra, dateFormatAPI + 'T' + timeFormat + ':ss')}
                                        className="w-2/3"
                                        size="small"
                                    /> */}
                </div>
                <div className="flex gap-1 w-1/4 ">
                  <label className="w-1/2 text-end font-semibold">Huyết áp:</label>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.huyetap}
                    // name="huyetap"
                    variant="filled"
                    readOnly
                    className="w-2/3 text-center"
                    addonAfter="mmHg"
                    size="small"
                    value={infoTheoDoiSauTiem?.huyetap}
                  />
                </div>
                <div className="flex gap-1 w-1/4 ">
                  <label className="w-1/2 text-end font-semibold">Kiểm tra:</label>
                  <Input
                    // disabled
                    // style={{ color: "black" }}
                    // // type="Number"
                    // // onChange={formik.handleChange}
                    // // name="nhietdo"
                    // // readOnly
                    // // variant="filled"
                    // value={formik.values.kiemtra}
                    variant="filled"
                    readOnly
                    className="w-2/3"
                    size="small"
                    value={infoTheoDoiSauTiem?.kiemtra}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Toàn trạng:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.toantrang}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.toantrang === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.toantrang === 1 && (!formik.values.toantrangghichu || formik.values.toantrangghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.toantrang === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.toantrangghichu}
                    // name="toantrangghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.toantrangghichu}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Tri giác:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.trigiac}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.trigiac === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.trigiac}
                                        name="trigiac"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.trigiac === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.trigiac === 1 && (!formik.values.trigiacghichu || formik.values.trigiacghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.trigiac === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.trigiacghichu}
                    // name="trigiacghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.trigiacghichu}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Mạch:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.mach}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` + (infoTheoDoiSauTiem?.mach === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.mach}
                                        name="mach"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.mach === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.mach === 1 && (!formik.values.machghichu || formik.values.machghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.mach === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.machghichu}
                    // name="machghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.machghichu}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Nhịp thở:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.nhiptho}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.nhiptho === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.nhiptho}
                                        name="nhiptho"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.nhiptho === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.nhiptho === 1 && (!formik.values.nhipthoghichu || formik.values.nhipthoghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.nhiptho === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.nhipthoghichu}
                    // name="nhipthoghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.nhipthoghichu}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Da niêm:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.daniem}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.daniem === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.daniem}
                                        name="daniem"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.daniem === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.daniem === 1 && (!formik.values.daniemghichu || formik.values.daniemghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.daniem === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.daniemghichu}
                    // name="daniemghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.daniemghichu}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Vị trí tiêm:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.vitritiem}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.vitritiem === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.vitritiem}
                                        name="vitritiem"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.vitritiem === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.vitritiem === 1 && (!formik.values.vitritiemghichu || formik.values.vitritiemghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.vitritiem === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.vitritiemghichu}
                    // name="vitritiemghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.vitritiemghichu}
                  />
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Dấu hiệu khác:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.dauhieukhac}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.dauhieukhac === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.dauhieukhac}
                                        name="dauhieukhac"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.dauhieukhac === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.dauhieukhac === 1 && (!formik.values.dauhieukhacghichu || formik.values.dauhieukhacghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.dauhieukhac === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.dauhieukhacghichu}
                    // name="dauhieukhacghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.dauhieukhacghichu}
                  />
                </div>
              </div>
              <div className="mt-1">
                <div className="flex">
                  <label className="text-end font-semibold w-[11.1%]">Xử trí:</label>
                  <div className="w-full pl-1">
                    <Input
                      // onChange={formik.handleChange}
                      // value={formik.values.xutri}
                      // name="xutri"
                      variant="filled"
                      readOnly
                      size="small"
                      className="w-full"
                      value={infoTheoDoiSauTiem?.xutri}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-1 grid grid-cols-5">
                <div className="flex col-span-2">
                  <label className="w-1/4 text-end font-semibold">Kết quả:</label>
                  <Radio.Group
                    // onChange={formik.handleChange}
                    value={infoTheoDoiSauTiem?.ketqua}
                    // name="toantrang"
                  >
                    <Radio value={0} className="pl-5">
                      Bình thường
                    </Radio>
                    <Radio
                      value={1}
                      className={
                        `ml-5 ` +
                        (infoTheoDoiSauTiem?.ketqua === 1 && ` text-red-500 font-semibold`)
                      }
                    >
                      Bất thường
                    </Radio>
                  </Radio.Group>
                  {/* <Radio.Group
                                        onChange={formik.handleChange}
                                        value={formik.values.ketqua}
                                        name="ketqua"
                                    >
                                        <Radio value={0} className='pl-5'>Bình thường</Radio>
                                        <Radio value={1} className={`ml-5 ` + (formik.values.ketqua === 1 && ` text-red-500 font-semibold`)}>Bất thường</Radio>
                                    </Radio.Group> */}
                </div>
                <div className="col-span-3">
                  <Input
                    // status={formik.values.ketqua === 1 && (!formik.values.ketquaghichu || formik.values.ketquaghichu?.trim().length <= 0) ? 'error' : ''}
                    // disabled={formik.values.ketqua === 0}
                    // onChange={formik.handleChange}
                    // value={formik.values.ketquaghichu}
                    // name="ketquaghichu"
                    variant="filled"
                    readOnly
                    size="small"
                    value={infoTheoDoiSauTiem?.ketquaghichu}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            {/* <h2 className="font-semibold  text-lg m-2 text-gray-500">Vắc xin</h2> */}
            <ConfigProvider
              theme={{
                token: {
                  padding: 3,
                  fontSize: 13,
                },
              }}
            >
              <Table
                pagination={false}
                dataSource={DetailToaThuocBN?.DanhSachThuoc ?? []}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    width: 40,
                    align: 'center',
                    render: (text, record, index) => ++index,
                    fixed: 'left',
                  },
                  {
                    title: 'Tên vắc xin',
                    dataIndex: 'tenbietduoc',
                    key: 'tenbietduoc',
                    fixed: 'left',
                  },
                  //   {
                  //     title: 'Tên nhà sản xuất',
                  //     dataIndex: 'nhasanxuat',
                  //     key: 'nhasanxuat',
                  //     width: 175,
                  //   },
                  //   {
                  //     title: <p className="text-center">Số đăng ký</p>,
                  //     dataIndex: 'masodangky',
                  //     key: 'masodangky',
                  //     width: 125,
                  //   },
                  //   {
                  //     title: 'Số lô',
                  //     dataIndex: 'solo',
                  //     key: 'solo',
                  //     width: 65,
                  //     align: 'center',
                  //   },
                  //   {
                  //     title: 'Hạn dùng',
                  //     dataIndex: 'handung',
                  //     key: 'handung',
                  //     width: 85,
                  //     align: 'center',
                  //     render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
                  //   },
                  {
                    title: 'Đơn vị',
                    dataIndex: 'dvt',
                    key: 'dvt',
                    align: 'center',
                    width: 65,
                  },
                  {
                    title: 'Mũi',
                    dataIndex: 'muitiem',
                    key: 'muitiem',
                    align: 'center',
                    width: 40,
                  },
                  {
                    title: 'Liều dùng (ml)',
                    dataIndex: 'lieudung',
                    key: 'lieudung',
                    width: 100,
                    align: 'center',
                  },
                  {
                    title: 'Đường dùng',
                    dataIndex: 'duongdung',
                    key: 'duongdung',
                    width: 90,
                    align: 'center',
                  },
                  {
                    title: 'SL',
                    dataIndex: 'soluong',
                    key: 'soluong',
                    width: 40,
                    align: 'center',
                  },
                  {
                    title: 'Ngày hẹn tiêm',
                    dataIndex: 'ngayhentiem',
                    key: 'ngayhentiem',
                    width: 95,
                    align: 'center',
                    render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
                  },
                  {
                    title: 'Ghi chú',
                    dataIndex: 'ghichu',
                    key: 'ghichu',
                    align: 'center',
                    width: 150,
                    render: (text, record, index) => (
                      <Input value={text} size="small" readOnly={true} variant="filled" />
                    ),
                  },
                ]}
                bordered
                scroll={{ y: 175 }}
              />
            </ConfigProvider>
          </div>
        </>
      </div>
    </>
  )
}

export default FormLSTC
