import moment from 'moment'

export const CalculateDuSanh = (ngaytaikham, duSanh) => {
  // Lấy ngày bắt đầu (ngày tái khám hoặc ngày hiện tại)
  const startDate = ngaytaikham || moment().format('YYYY-MM-DD')
  // Tạo các đối tượng moment từ formatDate và startDate
  const endMoment = moment(duSanh, 'YYYY-MM-DD')
  const startMoment = moment(startDate, 'YYYY-MM-DD')
  // Tính sự chênh lệch giữa hai ngày
  const duration = endMoment.diff(startMoment, 'days')
  // Chuyển đổi sự chênh lệch thành tuần và ngày
  const weeks = Math.floor(duration / 7)
  const days = duration % 7 // Số ngày lẻ sau số tuần
  // Tuổi thai = 40 tuần trừ đi số tuần còn lại cho đến ngày dự sinh
  const gestationalWeeks = 40 - weeks
  const gestationalDays = 7 - days // Ngày tuổi thai trong tuần
  // Nếu daysUntilDue là 0, giữ nguyên số ngày
  const adjustedGestationalDays = gestationalDays === 7 ? 0 : gestationalDays
  const adjustedGestationalWeeks = gestationalWeeks - (gestationalDays === 7 ? 0 : 1)
  const ngaySanh = `${weeks} tuần, ${days} ngày`
  const tuoiThai = `${adjustedGestationalWeeks} tuần, ${adjustedGestationalDays} ngày`

  return {
    ngaySanh,
    tuoiThai,
  }
}
