import { InputNumber } from 'antd'
import React from 'react'

const InputCustom = ({props, value, name }) => {
  return (
    <InputNumber
      readOnly={!props.show}
      variant={props.show ? 'outlined' : 'borderless'}
      className="w-24"
      size='small'
      value={value}
      name={name}
      onChange={props.onChangeDetailPayment(name)}
      formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      min={0}
    />
  )
}

export default InputCustom
