import * as Yup from "yup";

export const congtyCRMSchema = Yup.object().shape({
    masothue: Yup.string().required(),
    tenct: Yup.string().required(),
    diachi: Yup.string().required(),
    dienthoai: Yup.string().required(),
    // fax: Yup.string().required(),
    // email: Yup.string().required(),
    // mact: Yup.string().required(),
    // website: Yup.string().required(),
});