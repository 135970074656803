import {
  CloseOutlined,
  GoldOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button } from '@mui/material'
import {
  Input,
  Select,
  Tabs,
  notification,
  Table,
  ConfigProvider,
  InputNumber,
  Checkbox,
  DatePicker,
} from 'antd'
import { useFormik } from 'formik'
import 'jspdf-autotable'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  branchNhapKhoByCompany,
  fetchInfoThuocVT,
  getBranchNhapKho,
  getHangHoaByMaHang,
  getHangVPPKhoTong,
  getInfoDoitac,
  getlistDoitac,
  searchThuocVT,
} from '../../../../store/actions/NhapKhoAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { postPhieuXuatTraNCCAction } from '../../../../store/actions/xuatTraNCCAction'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import { partnerServices } from '../../../../services/partner/partnerServices'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import * as typeAction from '../../../../store/constants/constants'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')

function LapPhieuXuat() {
  const [totalPrice, setTotalPrice] = useState(0)
  // ------dùng để focus vào items mới -------------//
  const [selectedItem, setSelectedItem] = useState(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  const [congTy, setCongTy] = useState(id_company)
  const [listCongTy, setListCongTy] = useState([])
  const [branch, setBranch] = useState(id_branch)
  const [listBranch, setListBranch] = useState([])
  const [khoXuat, setKhoXuat] = useState(null) //id kho cn
  const [listKho, setListKho] = useState([])
  const now = useMemo(() => moment(), [])
  const dispatch = useDispatch()
  const [maHang, setMaHang] = useState(null)
  const [selectedFile, setSelectedFile] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const [company, setCompany] = useState(id_company)
  // lấy thông tin người dùng >> tạm thời
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [listDT, setListDT] = useState([])
  const [infoDT, setInfoDT] = useState(null)
  const [isLoadingDT, setIsLoadingDT] = useState(false)

  const [listTonVTHH, setListTonVTHH] = useState([])
  const [listXuatVTHH, setListXuatVTHH] = useState([])
  const [isLoadingTon, setIsLoadingTon] = useState(false)
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const selectRef = useRef(null)

  //---------------- lấy danh thông tin bệnh nhân -----------
  const getInfoDoiTacById = async (id) => {
    try {
      setIsLoadingDT(true)
      const { data } = await NhapKhoService.getInfoDoiTac(id)
      setInfoDT(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingDT(false)
    }
  }
  const searchDoiTac = async (kw) => {
    try {
      const { data } = await partnerServices.searchPartnerByKeyword(kw)
      setListDT(data)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  const debounceDropDownDT = useCallback(
    _.debounce((keyword) => {
      searchDoiTac(keyword)
    }, 300),
    [],
  )

  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  // xoá hình ảnh
  const initialValues = useMemo(
    () => ({
      phuongThuc: 2,
      hinhThuc: 2,
      soHoaDon: null,
      ngayHoaDon: null,
      checkHoaDon: 0,
      ghiChu: null,
    }),
    [now, id_company],
  )
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: KhoNhapSchema,
    onSubmit: (value, action) => handleSubmit(value, action),
  })
  const closeImg = () => {
    setSelectedFile([])
    formik.setFieldValue('HoaDonFile', null)
  }

  const resetForm = () => {
    formik.resetForm()
    setKhoXuat(null)
    setInfoDT(null)
    setListXuatVTHH([])
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
  }
  // xử lí button submit
  const handleSubmit = (values, action) => {
    if (!values?.soHoaDon || !values?.ngayHoaDon || !khoXuat || !branch || !khoXuat) {
      formik.setFieldError('soHoaDon', !values?.soHoaDon ? 'error' : null)
      formik.setFieldError('ngayHoaDon', !values?.ngayHoaDon ? 'error' : null)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    } else if (!infoDT) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn đối tác!',
      })
      return
    } else if (
      !listXuatVTHH?.length ||
      listXuatVTHH?.find(
        (item) =>
          !item?.soluongchan || item?.soluongchan < 0 || item?.soluongchan > item?.tonkhochan,
      )
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Không có vật tư hàng hóa cần xuất!',
      })
      return
    }
    const formData = new FormData()
    formData.append('IDDOITAC', infoDT?.idDoiTac)
    formData.append('MADOITAC', infoDT?.maDoiTac ?? '')
    formData.append('DIACHI', infoDT?.diaChi ?? '')
    formData.append('DIENTHOAI', infoDT?.dienThoai ?? '')
    formData.append('MASOTHUE', infoDT?.maSoThue ?? '')
    formData.append('GHICHU', values?.ghiChu ?? '')
    formData.append('HOADON', values?.soHoaDon)
    formData.append('NGAYHOADON', values?.ngayHoaDon)
    formData.append('HOADONDIENTU', values?.checkHoaDon)
    formData.append('NGUOITRA', infoUser?.idnv)
    formData.append('IDPHUONGTHUC', values?.phuongThuc)
    formData.append('IDHINHTHUC', values?.hinhThuc)
    formData.append('IDKHOXUAT', khoXuat)
    formData.append(
      'THANHTIEN',
      listXuatVTHH?.reduce((tong, item) => (tong += item?.thanhtien), 0),
    )

    if (selectedFile?.length !== 0) {
      const dataFiles = selectedFile?.map((item) => item.file)
      dataFiles?.forEach((file, index) => {
        formData.append('files', file)
      })
    }
    // const formDataObj = Object.fromEntries(formData.entries())
    // console.log(formDataObj)
    dispatch(postPhieuXuatTraNCCAction(formData, listXuatVTHH, infoDT, resetForm))
    //////////////////////
  }

  // search lấy thông tin thuốc vật tư
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  )
  const handleAddVTHH = (value, opt) => {
    if (listXuatVTHH?.find((item) => item?.key === opt?.key)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư hàng hóa này đã được chọn!',
      })
      return
    }
    setListXuatVTHH((prev) => [
      ...prev,
      {
        key: opt?.key,
        stt: (prev?.length ?? 0) + 1,
        idthuoc: opt?.infoVTHH?.idThuoc,
        tenbietduoc: opt?.infoVTHH?.thuocVatTu?.tenBietDuoc,
        tenhoatchat: opt?.infoVTHH?.thuocVatTu?.tenHoatChat,
        mathuoc: opt?.infoVTHH?.thuocVatTu?.maThuoc,

        soluongchan: 1,
        dvtchan: opt?.infoVTHH?.thuocVatTu?.donViChan,
        dongiachan: opt?.infoVTHH?.thuocVatTu?.quyCachDongGoi * opt?.infoVTHH?.thuocVatTu?.giaMua,

        quycach: opt?.infoVTHH?.thuocVatTu?.quyCach,
        quycachdonggoi: opt?.infoVTHH?.thuocVatTu?.quyCachDongGoi,
        soluongle: opt?.infoVTHH?.thuocVatTu?.quyCachDongGoi,
        dvtle: opt?.infoVTHH?.thuocVatTu?.dvt,
        dongiale: opt?.infoVTHH?.thuocVatTu?.giaMua,

        thanhtien: opt?.infoVTHH?.thuocVatTu?.quyCachDongGoi * opt?.infoVTHH?.thuocVatTu?.giaMua,

        solo: opt?.infoVTHH?.soLo,
        handung: opt?.infoVTHH?.hanDung,
        sohoadon: opt?.infoVTHH?.soHoaDon,
        masodangky: opt?.infoVTHH?.thuocVatTu?.maSoDangKy,
        ghichu: null,

        tonkhochan: opt?.infoVTHH?.tonkhochan,
      },
    ])
  }
  const getListVTHHbyIdKhoCN = async (idkhocn) => {
    try {
      setIsLoadingTon(true)
      const { data } = await https.get(`TonKho/${idkhocn ?? 0}`)
      setListTonVTHH(
        data?.result
          ?.map((item) => ({
            ...item,
            tonkhochan: Math.floor(item?.tonKho / (item?.thuocVatTu?.quyCachDongGoi ?? 1)),
          }))
          ?.filter((item) => item?.tonkhochan > 0),
      )
      setListXuatVTHH([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTon(false)
    }
  }
  const getLisCongTy = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setListCongTy(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getListChiNhanh = async (idct) => {
    try {
      const { data } = await branchService.getListBranch(idct)
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getListKhoChiNhanh = async (idcn) => {
    try {
      setIsLoadingTon(true)
      const { data } = await chuyenKhoService.getlistKhoNhan(idcn)
      setListKho(data)
      setKhoXuat(data?.length ? data[0].idKhoCN : null)
      getListVTHHbyIdKhoCN(data?.length ? data[0].idKhoCN : null)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTon(false)
    }
  }

  //   useEffect(() => {
  //     dispatch(getlistDoitac())
  //     dispatch(branchNhapKhoByCompany(id_company))
  //     dispatch(listAllCompanyAction())
  //   }, [])
  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const validKhoIds = [104, 65, 4] // chỉ lấy ID kho điều dưỡng
  //       const { data } = await https.get(`KhoChiNhanh/${id_branch}`)
  //       const filteredKhoList = data.filter((item) => validKhoIds.includes(item.idKho))
  //       setListKho(filteredKhoList)
  //       // lấy tự động kho điều dưỡng
  //       setKhoXuat(filteredKhoList[0].idKhoCN)
  //       getListVTHHbyIdKhoCN(filteredKhoList[0].idKhoCN)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [])

  useEffect(() => {
    ;(async () => {
      try {
        getLisCongTy()
        getListChiNhanh(id_company)
        getListKhoChiNhanh(id_branch)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  // const formatDataNull = (text) => {
  //   return `${text ?? ''}`?.replaceAll('null', '')
  // }
  return (
    <div>
      <form>
        <div className="grid grid-cols-2 items-start gap-2 pr-5">
          <div className="grid grid-flow-row items-center gap-1">
            {infoDT ? (
              <>
                <div className="grid grid-cols-6 items-center gap-1">
                  <label className="text-end font-semibold">
                    <span className="text-red-500 text-xs">(*)</span>Đối tác:
                  </label>
                  <Input
                    className="col-span-5"
                    size="small"
                    readOnly
                    variant="filled"
                    value={infoDT?.tenDoiTac}
                    suffix={
                      <>
                        <CloseOutlined
                          onClick={() => {
                            setInfoDT(null)
                          }}
                          className=" text-red-500 cursor-pointer text-lg"
                        />
                      </>
                    }
                  />
                </div>
                <div className="grid grid-cols-2 items-center gap-1">
                  <div className="grid grid-cols-3 items-center gap-1">
                    <label className="text-end font-semibold">Tên viết tắt:</label>
                    <Input
                      className="col-span-2"
                      size="small"
                      readOnly
                      variant="filled"
                      value={infoDT?.tenVietTat}
                    />
                  </div>
                  <div className="grid grid-cols-3 items-center gap-1">
                    <label className="text-end font-semibold">Mã đối tác:</label>
                    <Input
                      className="col-span-2"
                      size="small"
                      readOnly
                      variant="filled"
                      value={infoDT?.maDoiTac}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center gap-1">
                  <div className="grid grid-cols-3 items-center gap-1">
                    <label className="text-end font-semibold">Số điện thoại:</label>
                    <Input
                      className="col-span-2"
                      size="small"
                      readOnly
                      variant="filled"
                      value={infoDT?.dienThoai}
                    />
                  </div>
                  <div className="grid grid-cols-3 items-center gap-1">
                    <label className="text-end font-semibold">Mã số thuế:</label>
                    <Input
                      className="col-span-2"
                      size="small"
                      readOnly
                      variant="filled"
                      value={infoDT?.maSoThue}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 items-center gap-1">
                  <label className="text-end font-semibold">Địa chỉ:</label>
                  <Input
                    className="col-span-5"
                    size="small"
                    readOnly
                    variant="filled"
                    value={infoDT?.diaChi}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-6 items-center gap-1">
                  <label className="text-end font-semibold">
                    <span className="text-red-500 text-xs">(*)</span>Chọn đối tác:
                  </label>
                  <Select
                    disabled={isLoadingDT}
                    onSearch={debounceDropDownDT}
                    value={isLoadingDT ? 'Đang lấy dữ liệu đối tác...' : null}
                    onChange={getInfoDoiTacById}
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Tìm kiếm Tên đối tác, Tên viết tắt, Mã đối tác, SĐT, Mã số thuế..."
                    suffixIcon={<SearchOutlined className=" " />}
                    options={listDT?.map((item) => ({
                      label: item?.tenDoiTac,
                      value: item?.idDoiTac,
                      maDoiTac: item?.maDoiTac,
                      tenVietTat: item?.tenVietTat,
                      dienThoai: item?.dienThoai,
                      maSoThue: item?.maSoThue,
                      diaChi: item?.diaChi,
                    }))}
                    optionRender={(opt) => (
                      <ul>
                        <li>
                          <div className="flex justify-between w-full">
                            <p className="font-semibold">
                              {opt.data?.label} - {opt.data?.maDoiTac}
                            </p>
                            <p> {opt.data?.maSoThue} </p>
                          </div>
                          <i className="text-gray-500 text-wrap">Địa chỉ: {opt.data?.diaChi}</i>
                        </li>
                      </ul>
                    )}
                    showSearch
                    allowClear
                    className="w-full col-span-5 text-black"
                    size="small"
                  />
                </div>
              </>
            )}
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">Ghi chú:</label>
              <Input
                className="col-span-5"
                size="small"
                value={formik.values.ghiChu}
                onChange={formik.handleChange}
                name="ghiChu"
              />
            </div>
          </div>
          <div className="grid grid-flow-row items-center gap-1">
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Người xuất:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoUser?.tenNV}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Ngày xuất:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={moment().format('DD/MM/YYYY HH:mm:ss')}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Phương thức:</label>
                <Select
                  className="w-full col-span-2"
                  size="small"
                  options={[
                    {
                      key: 1,
                      value: 1,
                      label: 'Thanh toán',
                    },
                    {
                      key: 2,
                      value: 2,
                      label: 'Công nợ',
                    },
                  ]}
                  value={formik.values.phuongThuc}
                  onChange={(value) => formik.setFieldValue('phuongThuc', value)}
                  name="phuongThuc"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Hình thức:</label>
                <Select
                  className="w-full col-span-2"
                  size="small"
                  options={[
                    {
                      key: 1,
                      value: 1,
                      label: 'Tiền mặt',
                    },
                    {
                      key: 2,
                      value: 2,
                      label: 'Chuyển khoản',
                    },
                  ]}
                  value={formik.values.hinhThuc}
                  onChange={(value) => formik.setFieldValue('hinhThuc', value)}
                  name="hinhThuc"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Số hóa đơn:
                </label>
                <Input
                  className="col-span-2"
                  size="small"
                  status={formik.errors.soHoaDon}
                  value={formik.values.soHoaDon}
                  onChange={formik.handleChange}
                  name="soHoaDon"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Ngày hóa đơn:
                </label>
                <DatePicker
                  status={formik.errors.ngayHoaDon}
                  format="DD/MM/YYYY"
                  maxDate={dayjs(now)}
                  onChange={(date) =>
                    date
                      ? formik.setFieldValue('ngayHoaDon', dayjs(date).format('YYYY-MM-DD'))
                      : null
                  }
                  value={formik.values.ngayHoaDon ? dayjs(formik.values.ngayHoaDon) : null}
                  className="col-span-2"
                  size="small"
                  placeholder=" "
                />
                {/* <DatePicker className="col-span-2" size="small" placeholder=" " /> */}
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Công ty xuất:
                </label>
                <Select
                  status={!congTy && 'error'}
                  value={congTy}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={listCongTy.map((items) => ({
                    label: items?.tenct,
                    value: items?.idct,
                  }))}
                  className="col-span-2 w-full"
                  size="small"
                  onChange={(value) => {
                    setCongTy(value)
                    getListChiNhanh(value)
                    setBranch(null)
                    setKhoXuat(null)
                  }}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Chi nhánh xuất:
                </label>
                <Select
                  status={!branch && 'error'}
                  value={branch}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={listBranch.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))}
                  className="col-span-2 w-full"
                  size="small"
                  onChange={(value) => {
                    setBranch(value)
                    getListKhoChiNhanh(value)
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Có hóa đơn:</label>
                <Checkbox
                  checked={formik.values.checkHoaDon}
                  onChange={(e) => formik.setFieldValue('checkHoaDon', e.target.checked ? 1 : 0)}
                  name="checkHoaDon"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Kho xuất:
                </label>
                <Select
                  status={!khoXuat && 'error'}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setKhoXuat(value)
                    getListVTHHbyIdKhoCN(value)
                  }}
                  className="w-full col-span-2"
                  size="small"
                  value={khoXuat}
                  options={listKho?.map(({ idKhoCN, tenKho }) => ({
                    label: tenKho,
                    value: idKhoCN,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Tabs
            items={[
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    {' '}
                    <GoldOutlined style={{ fontSize: 18 }} /> Hàng hóa
                  </div>
                ),
                children: (
                  <>
                    <div className="flex items-center mb-2 gap-2">
                      <Select
                        disabled={isLoadingTon}
                        className="w-full"
                        placeholder="Nhập tên vật tư hàng hoá..."
                        showSearch
                        onChange={handleAddVTHH}
                        filterOption={(value, option) => {
                          const tenBietDuoc = option?.infoVTHH?.thuocVatTu?.tenBietDuoc ?? ''
                          const maThuoc = option?.infoVTHH?.thuocVatTu?.maThuoc ?? ''
                          const searchText = value?.toLowerCase()
                          return (
                            tenBietDuoc?.toLowerCase()?.includes(searchText) ||
                            maThuoc?.toLowerCase()?.includes(searchText)
                          )
                        }}
                        dropdownRender={(menu) => (
                          <div>
                            <div
                              className="flex bg-gray-100"
                              style={{ padding: '8px', fontWeight: 'bold' }}
                            >
                              <span className="w-[5%] text-center ">Mã hàng</span>
                              <span className="w-[60%] text-center">Tên thuốc</span>
                              <span className="w-[10%] text-center">Số lô</span>
                              <span className="w-[5%] text-center">Hạn dùng</span>
                              <span className="w-[10%] text-center">Số hoá đơn</span>
                              <span className="w-[5%] text-center">Đơn vị </span>
                              <span className="w-[5%] text-center">Tồn</span>
                            </div>
                            <div style={{ marginBottom: '8px' }}>{menu}</div>
                          </div>
                        )}
                        optionLabelProp="label"
                        value={null}
                        options={
                          !congTy || !branch || !khoXuat
                            ? []
                            : listTonVTHH?.map((d, index) => ({
                                infoVTHH: d,
                                key: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                value: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                label: (
                                  <ul className="flex no-underline">
                                    <li className="flex w-[5%] border-r-2">
                                      <p className=" w-full truncate text-center">
                                        {d?.thuocVatTu?.maThuoc}
                                      </p>
                                    </li>
                                    <li className="flex w-[60%] border-r-2">
                                      <p>{d?.thuocVatTu?.tenBietDuoc}</p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.soLo}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 items-end justify-center">
                                      <p className="w-full truncate text-center">
                                        {d?.hanDung && moment(d?.hanDung).format('DD/MM/YYYY')}
                                      </p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.soHoaDon}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 pl-2 items-end justify-center">
                                      <p className=" w-full truncate text-center">
                                        {d?.thuocVatTu?.dvt}
                                      </p>
                                    </li>
                                    {/* <li className="flex w-[10%] border-r-2 pl-2">
                                              <p>{d?.maSoDangKy}</p>
                                            </li> */}
                                    <li className="flex w-[5%] items-end justify-center">
                                      <p className=" w-full truncate text-center">
                                        {d?.tonkhochan}
                                      </p>
                                    </li>
                                  </ul>
                                ),
                              }))
                        }
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 4,
                          fontSize: 12,
                        },
                      }}
                    >
                      <Table
                        bordered
                        pagination={false}
                        scroll={{
                          y: 300,
                        }}
                        dataSource={listXuatVTHH}
                        columns={[
                          {
                            key: 1,
                            title: 'Thông tin hàng',
                            children: [
                              {
                                key: 1.1,
                                title: 'STT',
                                dataIndex: 'stt',
                                width: 40,
                                fixed: true,
                                align: 'center',
                              },
                              {
                                key: 1.2,
                                title: 'Tên hàng',
                                dataIndex: 'tenbietduoc',
                                fixed: true,
                              },
                              {
                                key: 1.3,
                                title: 'Mã hàng',
                                dataIndex: 'mathuoc',
                                width: 90,
                                fixed: true,
                              },
                            ],
                          },
                          {
                            key: 2,
                            title: 'Đơn vị chẵn',
                            children: [
                              {
                                key: 2.1,
                                title: 'SL',
                                dataIndex: 'soluongchan',
                                width: 75,
                                align: 'center',
                                editable: true,
                                render: (text, record, index) => (
                                  <>
                                    <InputNumber
                                      size="small"
                                      className="w-full"
                                      min={1}
                                      step={1}
                                      max={record?.tonkhochan}
                                      value={text}
                                      onChange={(value) => {
                                        const valueNew =
                                          value > record?.tonkhochan ? record?.tonkhochan : value
                                        setListXuatVTHH((prev) =>
                                          prev?.map((item) =>
                                            item?.key === record?.key
                                              ? {
                                                  ...item,
                                                  soluongchan: valueNew,
                                                  soluongle: valueNew * record?.quycachdonggoi,
                                                  thanhtien: valueNew * record?.dongiachan,
                                                }
                                              : item,
                                          ),
                                        )
                                      }}
                                    />
                                  </>
                                ),
                              },
                              {
                                key: 2.2,
                                title: 'Đơn vị',
                                dataIndex: 'dvtchan',
                                align: 'center',
                                width: 60,
                              },
                              {
                                key: 2.3,
                                title: 'Đơn giá',
                                dataIndex: 'dongiachan',
                                align: 'right',
                                width: 90,
                                render: (text, record, index) => formattedNumber(text ?? 0),
                              },
                            ],
                          },
                          {
                            key: 3,
                            title: 'Đơn vị lẻ',
                            children: [
                              {
                                key: 3.1,
                                title: 'Quy cách',
                                dataIndex: 'quycachdonggoi',
                                width: 75,
                                align: 'center',
                              },
                              {
                                key: 3.2,
                                title: 'SL',
                                dataIndex: 'soluongle',
                                width: 75,
                                align: 'center',
                              },
                              {
                                key: 3.3,
                                title: 'Đơn vị',
                                dataIndex: 'dvtle',
                                align: 'center',
                                width: 60,
                              },
                              {
                                key: 3.4,
                                title: 'Đơn giá',
                                dataIndex: 'dongiale',
                                align: 'right',
                                width: 90,
                                render: (text, record, index) => formattedNumber(text ?? 0),
                              },
                            ],
                          },
                          {
                            key: 4,
                            title: <p className="text-center">Thành tiền</p>,
                            children: [
                              {
                                key: 4.1,
                                title: <p className="text-center">Thành tiền</p>,
                                dataIndex: 'thanhtien',
                                width: 90,
                                align: 'right',
                                render: (text, record, index) => (
                                  <>
                                    <InputNumber
                                      size="small"
                                      className="w-full"
                                      value={text}
                                      onChange={(value) => {
                                        setListXuatVTHH((prev) =>
                                          prev?.map((item) =>
                                            item?.key === record?.key
                                              ? {
                                                  ...item,
                                                  thanhtien: value,
                                                  dongiachan: value / record?.soluongchan,
                                                  dongiale:
                                                    value /
                                                    record?.soluongchan /
                                                    record?.quycachdonggoi,
                                                }
                                              : item,
                                          ),
                                        )
                                      }}
                                      step={100}
                                      formatter={(value) => {
                                        // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                                        return `${Number(value)}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                      }}
                                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                      min={0}
                                      max={999999999}
                                    />
                                  </>
                                ),
                              },
                            ],
                          },
                          {
                            key: 5,
                            title: 'Chi tiết',
                            children: [
                              {
                                key: 5.1,
                                title: 'Số lô',
                                dataIndex: 'solo',
                                width: 110,
                                align: 'center',
                              },
                              {
                                key: 5.2,
                                title: 'Hạn dùng',
                                dataIndex: 'handung',
                                align: 'center',
                                width: 75,
                                render: (text, record, index) =>
                                  text ? moment(text).format('DD/MM/YYYY') : '-',
                              },
                              {
                                key: 5.3,
                                title: 'Số hóa đơn',
                                dataIndex: 'sohoadon',
                                align: 'center',
                                width: 110,
                              },
                              {
                                key: 5.4,
                                title: 'Mã Số DK',
                                dataIndex: 'masodangky',
                                align: 'center',
                                width: 120,
                              },
                              {
                                key: 5.5,
                                title: 'Ghi chú',
                                dataIndex: 'ghichu',
                                align: 'center',
                                width: 150,
                                render: (text, record, index) => (
                                  <>
                                    <Input
                                      size="small"
                                      className="w-full"
                                      value={text}
                                      onChange={(e) => {
                                        setListXuatVTHH((prev) =>
                                          prev?.map((item) =>
                                            item?.key === record?.key
                                              ? { ...item, ghichu: e.target.value }
                                              : item,
                                          ),
                                        )
                                      }}
                                    />
                                  </>
                                ),
                              },
                            ],
                          },
                          {
                            key: 6,
                            title: '',
                            dataIndex: 'ACTION',
                            width: 40,
                            align: 'center',
                            fixed: 'right',
                            render: (text, record, index) => (
                              <>
                                <CloseOutlined
                                  className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                                  onClick={() =>
                                    setListXuatVTHH((prev) =>
                                      prev?.filter((item) => item?.key !== record?.key),
                                    )
                                  }
                                />
                              </>
                            ),
                          },
                        ]}
                        footer={() => (
                          <div className="w-full  flex justify-end text-sm">
                            <ul className="flex flex-col gap-2 mt-2 mr-5">
                              <li className="flex w-60 justify-between">
                                <label>Tổng tiền:</label>
                                <p>
                                  {formattedNumber(
                                    listXuatVTHH?.reduce(
                                      (tong, item) => (tong += item?.thanhtien),
                                      0,
                                    ),
                                  )}
                                  <span> VNĐ</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        )}
                      />
                    </ConfigProvider>
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <div className="flex items-center  gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                    <div className="flex gap-1 items-start">
                      Đính kèm
                      {selectedFile?.length > 0 && (
                        <span className="font-semibold text-white bg-red-500 text-[10px] pl-[0.35rem] pr-[0.35rem] border border-red-500 rounded-full">
                          {selectedFile?.length}
                        </span>
                      )}
                    </div>
                  </div>
                ),

                children: (
                  // <Attach
                  //   setSelectedFile={setSelectedFile}
                  //   selectedFile={selectedFile}
                  //   formik={formik}
                  // />
                  <>
                    <ul className="flex flex-wrap gap-2 mb-2">
                      {selectedFile?.map((items, index) => (
                        <li
                          key={`upHinh${index}`}
                          className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                        >
                          <DeleteOutlined
                            onClick={() => deleteFileById(items.id)}
                            className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                            style={{ fontSize: 18 }}
                          />
                          <a href={items?.URL} target="_blank">
                            <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                              <div className="relative "></div>
                              <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                {items.name}
                              </p>
                            </div>
                            <img src={items.base64} className="w-full" alt={items.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        border: '2px dashed #cccccc',
                        borderRadius: '4px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      className="border"
                      ref={dropzoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={handleDivClick}
                    >
                      <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                      <label style={{ cursor: 'pointer' }}>
                        <p className="font-semibold text-lg text-gray-500">
                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                        </p>
                      </label>
                      <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mt-5 flex gap-5 justify-end">
          {/* <Button onClick={onclikReset} variant="outlined" color="info" size="small">
                          Làm mới ( F5 )
                        </Button> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="button"
            onClick={formik.handleSubmit}
            startIcon={<SaveOutlined />}
          >
            Lưu
          </Button>
          {/* <Button
                          variant="contained"
                          color="success"
                          size="small"
                          type="button"
                          onClick={handleSaveAndPrinter}
                        >
                          Lưu & in ( F4 )
                        </Button> */}
        </div>
      </form>
    </div>
  )
}

export default LapPhieuXuat
