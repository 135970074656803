import { Input, InputNumber, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { detailCardService } from '../../../../../services/card/detailCardService'
import { editGiaChiNhanhLoaiTheSchema } from '../../../../../schemas/editGiaChiNhanhLoaiTheSchema'
// import { https } from '../../../../services/apiService'
import ToastCus from '../../../../../utils/Toast'
const ModalEditPriceCN = ({ showModalEditPriceCN, setShowModalEditPriceCN, infoCNLT, congTy }) => {
  const { infoLoaiThe } = useSelector((state) => state.detailCardReducer)
  // const [infoGiaCNLT, setInfoGiaCNLT] = useState(infoCNLT)
  // xử lí form thêm dịch vụ
  const handleSubmit = async (values) => {
    try {
      await detailCardService.putInfoGiaChiNhanhLoaiThe(values)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật giá thẻ chi nhánh thành công!',
      })
      formik.resetForm()
      setShowModalEditPriceCN(false)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật giá thẻ chi nhánh thất bại!',
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idltcn: infoCNLT?.idltcn,
      idlt: infoCNLT?.idlt,
      idchinhanh: infoCNLT?.idchinhanh,
      dongia: infoCNLT?.dongia ?? 0,
      ghichu: '',
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: editGiaChiNhanhLoaiTheSchema,
  })
  // useEffect(() => {
  //   if () {
  //   }
  // }, [])
  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Hủy"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Chi tiết giá thẻ chi nhánh</h2>}
        onOk={formik.handleSubmit}
        open={showModalEditPriceCN}
        onCancel={() => setShowModalEditPriceCN(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên loại thẻ{' '}
            </label>
            <Input variant="filled" readOnly value={infoLoaiThe?.loaithe} />
          </div>
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên chi nhánh
            </label>
            <Input variant="filled" readOnly value={infoCNLT?.tenchinhanh} />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Đơn giá
            </label>
            <InputNumber
              onChange={(value) => {
                formik.setFieldValue('dongia', value)
              }}
              status={formik.errors.dongia ? 'error' : ''}
              name="dongia"
              className="w-full"
              value={formik.values.dongia}
              step={100000}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditPriceCN
