import { combineReducers } from 'redux'
import branchReducer from './reducers/branchReducer'
import modalReducer from './reducers/modalReducer'
import userReducer from './reducers/userReducer'
import receiveReducer from './reducers/receiveReducer'
import NhapKhoReducer from './reducers/NhapKhoReduer'
import chuyenKhoReducer from './reducers/chuyenKhoReducer'
import khamDoanReducer from './reducers/khamDoanReducer'
import thuocVTReducer from './reducers/thuocVTReducer'
import xetNghiemReducer from './reducers/xetNghiemReducer'
import companyReducer from './reducers/companyReducer'
import CĐHAReducer from './reducers/CĐHAReducer'
import cartReducer from './reducers/cartReducer'
import partnerReducer from './reducers/partnerReducer'
import childrenClinicReducer from './reducers/childrenClinicReducer'
import maternityClinicReducer from './reducers/maternityClinicReducer'
import tmtdClinicReducer from './reducers/tmtdClinicReducer'
import phcnClinicReducer from './reducers/phcnClinicReducer'
import cashierReducer from './reducers/cashierReducer'
import drugstoreReducer from './reducers/drugstoreReducer'
import dichVuReducer from './reducers/dichVuReducer'
import detailCardReducer from './reducers/detailCardReducer'
import customerReducer from './reducers/customerReducer'
import traCuuReducer from './reducers/traCuuReducer'
import banLeReducer from './reducers/banLeReducer'
import thuThuatReducer from './reducers/thuThuatReducer'
import sieuAmReducer from './reducers/sieuAmReducer'
import vltlReducer from './reducers/vltlReducer'
import monitorReducer from './reducers/monitorReducer'
import dienTimReducer from './reducers/dienTimReducer'
import soKhamBenhReducer from './reducers/soKhamBenhReducer'
import doKhucXaReducer from './reducers/doKhucXaReducer'
import trongKinhReducer from './reducers/trongKinhReducer'
import khamSangLocReducer from './reducers/khamSangLocReducer'
import xuatVacXinReducer from './reducers/xuatVacXinReducer'
import tiemChungReducer from './reducers/tiemChungReducer'
import congtyCRMReducer from './reducers/congtyCRMReducer'
import lienheCRMReducer from './reducers/lienheCRMReducer'
import phanconglienheReducer from './reducers/phanconglienheReducer'
import khachhangCRMReducer from './reducers/khachhangCRMReducer'
import goikhamCRMReducer from './reducers/goikhamCRMReducer'
import goikhamkhachhangCRMReducer from './reducers/goikhamkhachhangCRMReducer'
import benhnhanCRMReducer from './reducers/benhnhanCRMReducer'
import khamSucKhoeReducer from './reducers/khamSucKhoeReducer'
import vanPhongPhamReducer from './reducers/vanPhongPhamReducer'
import suCoReducer from './reducers/suCoReducer'
import smsReducer from './reducers/smsReducer'
import traCuuBnMatReducer from './reducers/traCuuBnMatReducer'

export const rootReducer = combineReducers({
  branchReducer,
  modalReducer,
  userReducer,
  NhapKhoReducer,
  receiveReducer,
  chuyenKhoReducer,
  khamDoanReducer,
  thuocVTReducer,
  xetNghiemReducer,
  companyReducer,
  CĐHAReducer,
  cartReducer,
  partnerReducer,
  childrenClinicReducer,
  maternityClinicReducer,
  tmtdClinicReducer,
  phcnClinicReducer,
  cashierReducer,
  drugstoreReducer,
  dichVuReducer,
  detailCardReducer,
  customerReducer,
  traCuuReducer,
  banLeReducer,
  thuThuatReducer,
  sieuAmReducer,
  vltlReducer,
  monitorReducer,
  dienTimReducer,
  soKhamBenhReducer,
  doKhucXaReducer,
  trongKinhReducer,
  khamSangLocReducer,
  xuatVacXinReducer,
  tiemChungReducer,
  congtyCRMReducer,
  lienheCRMReducer,
  phanconglienheReducer,
  khachhangCRMReducer,
  goikhamCRMReducer,
  goikhamkhachhangCRMReducer,
  benhnhanCRMReducer,
  khamSucKhoeReducer,
  vanPhongPhamReducer,
  suCoReducer,
  smsReducer,
  traCuuBnMatReducer
})
