import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { columns } from './Columns'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import * as typeAction from '../../../../store/constants/constants'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { reportService } from '../../../../services/Report/reportService'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import Static from './Static/Static'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)

const TheThanhVien = () => {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      let idChiNhanhs = null
      // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value.map((id) => `&idcn=${id}`).join('&')
        const { data } = await reportService.getTTVPhieu(company, idChiNhanhs, date.form, date.to)
        setData(data)
      } else {
        // Xử lý trường hợp "all"
        const { data } = await reportService.getTTVPhieu(company, '', date.form, date.to)
        setData(data)
      }
      closeLoading()
    } catch (error) {
      console.log(error)
      closeLoading()
    } finally {
      closeLoading()
    }
  }

  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue([])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = data.map((item) => ({
      'Ngày chứng từ': moment(item.ngaythanhtoan).format('DD/MM/YYYY'),
      'Số chứng từ': item.machungtu,
      'Mã công cụ': item.mathe,
      'Mã bệnh nhân': item.mabenhnhan,
      'Tên công cụ': item.tenbenhnhan,
      'Ngày tăng': moment(item.ngayhieuluc).format('DD/MM/YYYY'),
      'Ngày tính phân bổ': moment(item.ngayhieuluc).format('DD/MM/YYYY'),
      'Ngày kết thúc pb': moment(item.ngayketthuc).format('DD/MM/YYYY'),
      'Số kỳ phân bổ': item.sothang,
      ĐVT: 'Tháng',
      'Mã bộ phận': item.machinhanh,
      'Tên bộ phận': item.tenchinhanh,
      'Nguyên giá': item.tienthanhtoan,
      'Gt phân bổ kỳ': item.giaphanbo,
    }))
    const name = 'Báo cáo thẻ thành viên'
    exportExcelformat(formatData, name)
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchReport()
  }, [])
  // validate
  return (
      <div>
        <Static data={data} />
        <div className="flex gap-3 border-dotted border-b-0  p-2 ">
          <Select
            showSearch
            value={company}
            onChange={onChangeCompany}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className={`w-80`}
          />
          <div className={`w-80 `}>
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              value={dayjs(date.form)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              value={dayjs(date.to)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>

          <div className="flex gap-3">
            <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
          }}
        >
          <Table
            loading={isLoadingTable}
            bordered
            scroll={{
              x: 1500,
              y: '65vh',
            }}
            dataSource={data}
            columns={columns}
            pagination={false} // Disable pagination
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {/* Tổng thanh toán */}
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13} align="right">
                    {' '}
                    <p className="font-semibold">
                      {formattedNumber(data.reduce((total, item) => total + item.tienthanhtoan, 0))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14} align="right">
                    {' '}
                    <p className="font-semibold">
                      {formattedNumber(data.reduce((total, item) => total + item.giaphanbo, 0))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
  )
}

export default TheThanhVien
