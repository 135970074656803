import { Select, Input, Checkbox, DatePicker } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddCardBN,
  fetchInfoTypeCard,
  fetchListTypesCard_Action,
  getListDiscountTTV,
  removeListDiscountTTV,
} from '../../../../../store/actions/cardAction'
import { useFormik } from 'formik'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import moment from 'moment'
import dayjs from 'dayjs'
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import ToastCus from '../../../../../utils/Toast'
import { https } from '../../../../../services/apiService'
import { cardService } from '../../../../../services/card/cardService'
import { nhanvienService } from '../../../../../services/nhanvien/nhanvienService'
import * as typeAction from '../../../../../store/constants/constants'
import _ from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')
const { TextArea } = Input
const CardExtend = ({ isExtendTTV, handleOk, handleCancel, formTTV, setFormTTV }) => {
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const dispatch = useDispatch()
  const idBranch = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
  const { infoBN, detailCard, tongThanhToan, tongGiamGia } = useSelector(
    (state) => state.cartReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [listTypesCard, setListTypesCard] = useState([])
  const [listNhanVien, setListNhanVien] = useState(null)

  //-------- lấy danh sách nhân viên -------//
  const fetchListNhanVien = async (keyword) => {
    try {
      const result = await nhanvienService.getSearch(keyword)
      setListNhanVien(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        fetchListNhanVien(keyword)
      }
    }, 300),
    [],
  )
  const handleChecked = (e) => {
    const { checked } = e.target
    setFormTTV({
      ...formTTV,
      hoadondientu: checked ? 1 : 0,
      ghichuxuathoadon: checked ? formTTV?.ghichuxuathoadon : null,
    })
  }
  const handleTypeCard = async (value) => {
    try {
      const result = await cardService.getInfoTypeCardByCN(value, PkDangNhap?.idChiNhanh)
      await setFormTTV({
        ...formTTV,
        idnhom: result?.data?.idnhom,
        idchuyenkhoa: result?.data?.idchuyenkhoa,
        tiensudung: result?.data?.tiensudung,
        idlt: result?.data?.idlt,
      })
      await dispatch({
        type: typeAction.DISPATCH_TONG_GIAM_TTV,
        payload: 0,
      })
      await dispatch({
        type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
        payload: result?.data?.tiensudung,
      })
    } catch (error) {
      console.log(error)
    }
  }

  //-------- lấy danh sách loại thẻ theo cấp của thẻ cũ -------//
  const fetchListTypesCard = async (capthe) => {
    try {
      const result = await cardService.getListTypeCardExtendByCapThe(
        capthe,
        PkDangNhap?.idChuyenKhoa,
      )
      setListTypesCard(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchListTypesCard(detailCard?.caploaithe)
  }, [])
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-5 text-end">
        <div className="flex w-2/5 gap-1 items-center pr-1">
          <label className="w-1/3">
            {' '}
            <span className="text-sx text-red-500">(*)</span>Mã thẻ:
          </label>
          <Input
            disabled
            style={{ color: 'black' }}
            name="mathe"
            value={formTTV?.mathe}
            onChange={(e) => {
              setFormTTV({ ...formTTV, mathe: e.target.value })
            }}
            // type="Number"
            className="w-2/3 mr-6"
            size="small"
          />
        </div>
        <div className="flex w-3/5 gap-1  items-center">
          <label className="w-1/3"> Loại thẻ:</label>
          <Select
            status={!formTTV?.idlt ? 'error' : ''}
            onChange={handleTypeCard}
            options={listTypesCard?.map((items) => ({
              label: items?.loaithe,
              value: items?.idlt,
            }))}
            value={formTTV?.idlt}
            className="w-full text-start"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Thành viên:</label>
          <Input
            type="Number"
            min={1}
            step={1}
            max={3}
            disabled
            style={{ color: 'Black' }}
            value={detailCard ? formTTV?.sothanhvien : null}
            onChange={(e) => {
              setFormTTV({ ...formTTV, sothanhvien: e.target.value })
            }}
            name="sothanhvien"
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Phương thức:</label>
          <Input
            value={detailCard ? 'Thanh toán' : null}
            style={{ color: 'Black' }}
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-2/5 gap-1 items-center">
          <label className="w-3/5">Đơn giá:</label>
          <Input
            style={{ color: 'black' }}
            value={formTTV?.tiensudung && formattedNumber(formTTV?.tiensudung)}
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Số tháng:</label>
          <Input
            style={{ color: 'black' }}
            value={formTTV?.sothang}
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Ngày hiệu lực:</label>
          <Input
            style={{ color: 'black' }}
            value={formTTV?.tungay && moment(formTTV?.tungay).format(dateFormat)}
            disabled
            className="w-3/5 rounded"
            size="small"
          />
        </div>
        <div className="flex w-2/5 gap-1 items-center">
          <label className="w-3/5"> Ngày hết hạn:</label>
          <Input
            style={{ color: 'black' }}
            value={formTTV?.denngay && moment(formTTV?.denngay).format(dateFormat)}
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
      </div>
      {/* <div className="flex gap-5 text-end">
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Voucher:</label>
          <Input
            value={formTTV?.mavoucher}
            onChange={(e) => setFormTTV({ ...formTTV, mavoucher: e.target.value })}
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-1/3 gap-1 items-center">
          <ul className="flex justify-around">
            {formTTV?.mavoucher && !discountArr && detailCard && infoBN && formTTV?.tiensudung ? (
              <li
                onClick={handleApplyVoucher}
                className="bg-green-200 ml-4 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
              >
                <CheckOutlined />
              </li>
            ) : null}
            {discountArr ? (
              <li
                onClick={handleUnApplyVoucher}
                className="bg-red-200 ml-4 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
              >
                <CloseOutlined />
              </li>
            ) : null}
          </ul>
        </div>
        <div className="flex w-2/5 gap-1 items-center"></div>
      </div> */}
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center mr-3">
          <label className="w-1/4">Người tạo:</label>
          <Input
            value={detailCard ? formTTV.nguoitao : null}
            //defaultValue={detailCard ? formTTV.nguoitao : null}
            style={{ color: 'Black' }}
            disabled
            className="w-3/4"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/4">Người bán:</label>
          <Select
            status={detailCard && formTTV?.nguoiban?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            onSearch={debounceDropDown}
            value={detailCard ? formTTV.nguoiban : null}
            onChange={(value, opt) => {
              setFormTTV({ ...formTTV, idnguoiban: value, nguoiban: opt?.label ?? null })
            }}
            filterOption={false}
            notFoundContent={null}
            placeholder={!detailCard ? '' : 'Tìm tên nhân viên...'}
            suffixIcon={<SearchOutlined className=" " />}
            options={listNhanVien?.map((items) => ({
              label: items.tennv,
              value: items.id,
              manv: items.manv,
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-3/4">{options.data.label}</li>
                <li className="w-1/4 border-x text-center">{options.data.manv}</li>
              </ul>
            )}
            showSearch
            allowClear
            className="w-3/4 text-left"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center mr-3">
          <label className="w-1/4">Người nộp tiền:</label>
          <Input
            status={detailCard && formTTV?.nguoimua?.trim().length <= 0 ? 'error' : ''}
            value={formTTV?.nguoimua}
            name="nguoimua"
            onChange={(e) => {
              setFormTTV({ ...formTTV, nguoimua: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/4">SĐT:</label>
          <Input
            status={detailCard && formTTV?.sodienthoai?.trim().length <= 0 ? 'error' : ''}
            value={formTTV?.sodienthoai}
            name="sodienthoai"
            onChange={(e) => {
              setFormTTV({ ...formTTV, sodienthoai: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center mr-3">
          <label className="w-1/4">Địa chỉ:</label>
          <Input
            status={detailCard && formTTV?.diachi?.trim().length <= 0 ? 'error' : ''}
            value={formTTV?.diachi}
            name="diachi"
            onChange={(e) => {
              setFormTTV({ ...formTTV, diachi: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/4">Lý do:</label>
          <Input
            status={detailCard && formTTV?.lydo?.trim().length <= 0 ? 'error' : ''}
            value={formTTV?.lydo}
            name="lydo"
            onChange={(e) => {
              setFormTTV({ ...formTTV, lydo: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end w-1/2">
        <div className="flex items-start w-full">
          <label className="mr-1">Xuất HĐ điện tử:</label>
          <Checkbox
            disabled={!detailCard}
            className="mr-2"
            checked={formTTV?.hoadondientu === 1}
            onChange={handleChecked}
          />
          {formTTV?.hoadondientu === 1 && (
            <TextArea
              status={formTTV?.ghichuxuathoadon?.trim().length <= 0 ? 'error' : ''}
              value={formTTV?.ghichuxuathoadon}
              onChange={(e) => setFormTTV({ ...formTTV, ghichuxuathoadon: e.target.value })}
              autoSize={{ minRows: 2, maxRows: 5 }}
              className="w-[65.5%]"
            />
          )}
        </div>
      </div>
      <div
        className={
          isExtendTTV
            ? !formTTV?.tiensudung
              ? 'hidden'
              : ''
            : !detailCard?.tiensudung
              ? 'hidden'
              : ''
        }
      >
        <div className="flex flex-col gap-2 justify-end mt-2">
          {/* {infoChuongTrinhGiamGiaDoiTac && (
          <div className="flex items-center ml-auto mr-4">
            <span className="text-red-500 text-xl font-semibold mr-10">
              Voucher đối tác:
            </span>
            <span className="text-red-500 text-xl font-semibold">
              -{' '}
              {formattedNumber(tongGiamGia)
              }{' '}
              VNĐ
            </span>
          </div>
        )} */}
          <div className="flex items-center ml-auto mr-4 text-red-500 text-xl font-semibold justify-between w-2/6">
            <div className="mr-4">Tiền giảm:</div>
            <div className="">-{formattedNumber(tongGiamGia)} VNĐ</div>
          </div>
          <div className="flex items-center ml-auto mr-4 text-green-700 text-xl font-semibold justify-between w-2/6">
            <span className="mr-4">Thanh toán:</span>
            <span className="">{formattedNumber(tongThanhToan ?? 0)} VNĐ</span>
          </div>
        </div>
      </div>

      {/* <div className="flex gap-2 justify-end mt-5">
        <Button onClick={handleCancel}>Huỷ</Button>
        <Button type="primary" onClick={formik.handleSubmit}>
          Tạo thẻ
        </Button>
      </div> */}
    </div>
  )
}

export default CardExtend
