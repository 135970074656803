import { https } from '../apiService'

export const cardService = {
  getListCardMemberShips: () => https.get('The/GetTheThanhVienTheoChuyenKhoa/1?idChiNhanh=22'),
  listTypeCard: (idChuyenKhoa, keyword) =>
    https.get(`TheLoai/SearchLoaiThe`, { params: { idChuyenKhoa, loaithe: keyword } }),
  getInfoTypeCard: (IDLOAI) => https.get(`TheLoai/GetLoaiTheByID/${IDLOAI}`),
  getInfoTypeCardByCN: (idlt, idcn) =>
    https.get(`TheLoai/GetLoaiTheByCN?idlt=${idlt}&idcn=${idcn}`),
  postCardBN: (form) => https.post(`The/AddTheThanhVien`, form),
  postCardBNthu2: (form) => https.post(`The/AddTheNhieuThanhVien`, form),
  //getAllCardReceive: (idChiNhanh, tuNgay, denNgay, idlt, idnhom, keyword) => https.get('The/GetAllTheThanhVien', { params: { idChiNhanh, tuNgay, denNgay, idlt, idnhom, keyword }, }),
  getAllCardReceive: (idChiNhanh, tuNgay, denNgay, idlt, idnhom, keyword) =>
    https.get(`The/SearchThe?${idChiNhanh}`, {
      params: { tuNgay, denNgay, idlt, idnhom, keyword },
    }),
  infoCard: (idthe) => https.get(`The/GetTheByID/${idthe}`),
  activeCardPay: (idthe) => https.put(`The/KichHoatThe/${idthe}`),
  paymentCards: (keyword) => https.get(`The/GetAllTheThanhToan`, { params: { keyword } }),
  postPaymentCard: (form) => https.post('TheBenhNhan/NapTienTheThanhToan', form),
  getInfoPaymentCard: (idthe) => https.get(`The/GetTheThanhToanByID/${idthe}`),
  getInfoPaymentCardByIdBN: (idbn) => https.get(`The/Mobile/GetTheThanhToanByIDBN/${idbn}`),
  getHistoryPaymentCard: (idthe, idbn, tuNgay, denNgay) =>
    https.get('TheBenhNhan/GetLichSuThe', { params: { idthe, idbn, tuNgay, denNgay } }),
  createPaymentCard: (idTheLoai, idBenhNhan, idChiNhanh, form) =>
    https.post(
      `The/AddTheThanhToan?idTheLoai=${idTheLoai}&idBenhNhan=${idBenhNhan}&idChiNhanh=${idChiNhanh}`,
      form,
    ),
  updateCancelUseVoucher: (maVoucher) =>
    https.put(`VoucherDanhSach/UpdateCancelVoucher`, { params: { maVoucher } }),
  addBenhNhanVoucher: (data) => https.post('VoucherBenhNhan/AddVoucherBenhNhan', data),
  // https.post(`The/AddTheThanhToan?idTheLoai=${idTheLoai}&idBenhNhan=${idBenhNhan}&idChiNhanh=${idChiNhanh}`, form),
  // getInfoMemberCard: (idbn) => https.get(`The/GetTheThanhVienByIDBN/${idbn}`),
  getInfoMemberCard: (idbn, idchuyenkhoa) =>
    https.get(`The/GetTheThanhVienByIDBN/${idbn}?idchuyenkhoa=${idchuyenkhoa}`),
  getHistoryMemberCard: (idthe, tungay, denngay) =>
    https.get(`The/GetLichSuTheThanhVien`, { params: { idthe, tungay, denngay } }),
  putPauseMemberCard: (idthe, ngayBaoLuu, nguoithaotac) =>
    https.put(
      `The/VoHieuTheThanhVien?idthe=${idthe}&ngayBaoLuu=${ngayBaoLuu}&nguoithaotac=${nguoithaotac}`,
    ),
  putDisableMemberCard: (idthe, nguoithaotac) =>
    https.put(`The/HuyTheThanhVien?idthe=${idthe}&nguoithaotac=${nguoithaotac}`),
  putRueseMemberCard: (idthe, ngaybaoluu, nguoithaotac) =>
    https.put(
      `The/TaiSuDungTheThanhVien?idthe=${idthe}&ngayTamDung=${ngaybaoluu}&nguoithaotac=${nguoithaotac}`,
    ),
  getSoLanKham: (idthe, idbn) => https.get(`The/GetSoLanKham`, { params: { idthe, idbn } }),
  listGroupCard: () => https.get(`The/GetAllNhomThe`),
  putCreateMemberCardTTT: (form) => https.put(`The/UpdateTheThanhVien`, form),
  getListTypeCardByCN: (idcn) => https.get(`TheLoai/GetAllLoaiTheByCN?idcn=${idcn}`),
  getListTypeCardUpgradeByCapThe: (capthe, idchuyenkhoa) =>
    https.get(`TheLoai/GetAllLoaiTheNangCap?capthe=${capthe}&idchuyenkhoa=${idchuyenkhoa}`),
  putUpgradeMemberCard: (form) => https.put(`The/NangCapTheThanhVien`, form),
  getListTypeCardExtendByCapThe: (capthe, idchuyenkhoa) =>
    https.get(`TheLoai/GetAllLoaiTheGiaHan?capthe=${capthe}&idchuyenkhoa=${idchuyenkhoa}`),
  putExtendMemberCard: (form) => https.put(`The/GiaHanTheThanhVien`, form),
  updateChiTietTT: (from) => https.put(`BenhNhanChiTietTT/UpdateBenhNhanChiTietTTBinhThuong`, from),
  // getListPhieuThu: (idcn, idct, tuNgay, denNgay, keyword) =>
  //   https.get(`TheThanhVien_PhieuThu/GetTheThanhVienPhieuThu`, {
  //     params: { idcn, idct, tuNgay, denNgay, keyword },
  //   }),////////////////////////////
  getListPhieuThu: (idcn, idct, tuNgay, denNgay, keyword) =>
    https.get(
      `TheThanhVien_PhieuThu/GetTheThanhVienPhieuThu?${idcn}&idct=${idct}&tuNgay=${tuNgay}&denNgay=${denNgay}${keyword?.length ? `&keyword=${keyword}` : ''}`,
    ),
  getInfoPhieuThu: (idthept) =>
    https.get(`TheThanhVien_PhieuThu/GetInfoTheThanhVienPhieuThu?idthept=${idthept}`),
  putSuDungVoucher: (listVC, nguoikichhoat) =>
    https.put(`VoucherDanhSach/UpdateTrangThaiVoucher?nguoikichhoat=${nguoikichhoat}`, listVC),
  updateBNTT: (form) => https.put('BenhNhanThanhToan', form),
  getAllCardTTVByBenhNhan: (idbn) => https.get(`The/Mobile/GetAllTheThanhVienByIDBN?idbn=${idbn}`),
  getLichSuMuaTTV: (idthe) => https.get(`The/GetTheThanhVienLichSu`, { params: { idthe } }),
  putMaTheTTV: (idthe, mathe) => https.put(`The/UpdateMaThe?idthe=${idthe}&mathe=${mathe}`),
  getListLoaiTheTangByCk: (idchuyenkhoa) =>
    https.get(`TheLoai/GetTheTang`, { params: { idchuyenkhoa } }),
  postLoaiThe: (form) => https.post(`TheLoai/AddTheLoai`, form),
  postApplyVoucherTTV: (list) => https.post('VoucherDanhSach/ApplyVoucherTheThanhVien', list),
  getLichSuThaoTacTTV: (idthe) =>
    https.get(`The/GetTheThanhVienLichSuThaoTac`, { params: { idthe } }),
  getListTheHuy: (tuNgay, denNgay, idChiNhanhs, keyword) =>
    https.get(
      `TheThanhVien_PhieuThu/GetDanhSachTheHuy?tuNgay=${tuNgay}&denNgay=${denNgay}&${idChiNhanhs}${keyword?.length ? `&keyword=${keyword}` : ''}`,
    ),
}
