import { AuditOutlined, FormOutlined, HomeOutlined } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { Breadcrumb, ConfigProvider, DatePicker, Select, Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LayoutApp from '../../../HOCs/LayoutApp'
import { listBranchAction } from '../../../store/actions/BranchAction'
import { fetchPhieuThuBanLe } from '../../../store/actions/banLeAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import DrawerThuocBanLe from './DrawerThuocBanLe/DrawerThuocBanLe'

import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

const PhieuBanLe = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listBranch } = useSelector((state) => state.branchReducer)
  const { listPT } = useSelector((state) => state.banLeReducer)
  const { PkDangNhap } = useSelector((state) => state.userReducer)
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [chiTiet, setChiTiet] = useState(null)
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const filter = {
    idcn: idChiNhanh,
    tuNgay: dateForm,
    denNgay: dateTo,
    keyword: keyword,
  }
  const onReload = () => {
    setChiTiet(null)
    dispatch(fetchPhieuThuBanLe(filter))
  }

  useEffect(() => {
    dispatch(listBranchAction(id_company))
    dispatch(fetchPhieuThuBanLe(filter))
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 45,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      // render: (text, record, index) => ++index,
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maphieuthu',
      width: 115,
      // align: 'center',
      key: 'maphieuthu',
      fixed: 'left',
      align: 'center',
      sorter: {
        compare: (a, b) => {
          return (a?.maphieuthu ?? '').localeCompare(b?.maphieuthu ?? '')
        },
      },
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setChiTiet(record)
            setOpen(true)
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Ngày bán',
      dataIndex: 'ngayban',
      width: 140,
      align: 'center',
      key: 'ngayban',
      fixed: 'left',
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngayban ?? '').localeCompare(b?.ngayban ?? '')
        },
      },
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
        },
      },
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      width: 120,
      // align: 'center',
      key: 'mabenhnhan',
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
        },
      },
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      width: 100,
      align: 'center',
      key: 'ngaysinh',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
        },
      },
    },
    // {
    //   title: 'Bác sĩ kê toa',
    //   dataIndex: 'bskedon',
    //   key: 'bskedon',
    //   width: 220,
    //   sorter: {
    //     compare: (a, b) => { return (a?.bskedon ?? '').localeCompare(b?.bskedon ?? '') },
    //   },
    // },
    {
      title: 'Dược sĩ bán',
      dataIndex: 'duocsiban',
      width: 220,
      key: 'duocsiban',
      sorter: {
        compare: (a, b) => {
          return (a?.duocsiban ?? '').localeCompare(b?.duocsiban ?? '')
        },
      },
    },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 100,
      sorter: {
        compare: (a, b) => {
          return (a.tienmat ?? 0) - (b.tienmat ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
      //   },
      // },
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenkhoan',
      key: 'chuyenkhoan',
      width: 120,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.chuyenkhoan ?? 0) - (b.chuyenkhoan ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
      //   },
      // },
    },
    {
      title: 'Máy Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 100,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.pos ?? 0) - (b.pos ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.pos ?? '').localeCompare(b?.pos ?? '')
      //   },
      // },
    },
    {
      title: 'Momo',
      dataIndex: 'momo',
      key: 'momo',
      align: 'right',
      width: 100,
      sorter: {
        compare: (a, b) => {
          return (a.momo ?? 0) - (b.momo ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'VNPay',
      dataIndex: 'vnpay',
      key: 'vnpay',
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.vnpay ?? 0) - (b.vnpay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      width: 100,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.vnPay ?? '').localeCompare(b?.vnPay ?? '')
      //   },
      // },
    },
    {
      title: 'ZaloPay',
      dataIndex: 'zalopay',
      key: 'zalopay ',
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.zalopay ?? 0) - (b.zalopay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      width: 100,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.zaloPay ?? '').localeCompare(b?.zaloPay ?? '')
      //   },
      // },
    },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'thett',
      key: 'thett',
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.thett ?? 0) - (b.thett ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      width: 125,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.theTT ?? '').localeCompare(b?.theTT ?? '')
      //   },
      // },
    },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      key: 'mavoucher',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mavoucher ?? '').localeCompare(b?.mavoucher ?? '')
        },
      },
      width: 105,
    },
    {
      title: <p className="text-center">Tổng tiền</p>,
      dataIndex: 'tongtien',
      key: 'tongtien',
      align: 'right',
      width: 100,
      sorter: {
        compare: (a, b) => {
          return (a.tongtien ?? 0) - (b.tongtien ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
      //onFilter: (value, record) => record.tongTien === Number(value),
      // sorter: (a, b) => a.tongTien - b.tongTien,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: <p className="text-center">Tiền giảm</p>,
      dataIndex: 'tiengiamgia',
      key: 'tiengiamgia',
      width: 100,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.tiengiamgia ?? 0) - (b.tiengiamgia ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      width: 100,
      align: 'right',
      fixed: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.tienthanhtoan ?? 0) - (b.tienthanhtoan ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul
          className="flex justify-center"
          onClick={() => {
            setChiTiet(record)
            setOpen(true)
          }}
        >
          <li className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded">
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
        </ul>
      ),
    },
  ]

  return (
    <>
        <div className="flex gap-5 justify-between border-dotted border-b-0  p-2 ">
          <div className="flex gap-2">
            <div className="flex items-center gap-1">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="w-60 flex gap-1 items-center">
              <Select
                onChange={(value) => setIdChiNhanh(value)}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                defaultValue={idChiNhanh}
                options={listBranch?.map(({ idChiNhanh, tenChiNhanh }) => ({
                  label: tenChiNhanh,
                  value: idChiNhanh,
                }))}
                className="w-full"
              />
            </div>
            <div className="w-60 flex gap-1 items-center">
              <Input
                placeholder="Nhập tên bệnh nhân..."
                prefix={<SearchOutlined className="pr-1" />}
                onChange={(e) => {
                  setKeyword(e.target.value)
                }}
                value={keyword}
                className="w-full"
              />
            </div>
            <div className="flex gap-2 items-center">
              <IconButton onClick={onReload} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 14,
              },
              components: {
                Table: {
                  headerBorderRadius: 0,
                  rowHoverBg: '#ecf0f1',
                },
              },
            }}
          >
            <Table
              scroll={{
                x: 2150,
              }}
              pagination={{
                pageSize: 20,
                showSizeChanger: false,
                // current: page,
              }}
              loading={isLoading}
              bordered
              dataSource={listPT}
              columns={columns}
              summary={(pageData) => {
                let sumtienmat = 0
                let sumchuyenkhoan = 0
                let summaypos = 0
                let summomo = 0
                let sumvnpay = 0
                let sumzalopay = 0
                let sumthethanhtoan = 0
                let sumtiengiam = 0
                let sumthanhtoan = 0
                let sumtongtien = 0
                pageData.forEach(
                  ({
                    tienmat,
                    chuyenkhoan,
                    pos,
                    momo,
                    zalopay,
                    vnpay,
                    thett,
                    tongtien,
                    tiengiamgia,
                    tienthanhtoan,
                  }) => {
                    sumtienmat += tienmat
                    sumchuyenkhoan += chuyenkhoan
                    summaypos += pos
                    summomo += momo
                    sumvnpay += vnpay
                    sumzalopay += zalopay
                    sumthethanhtoan += thett
                    sumtongtien += tongtien
                    sumtiengiam += tiengiamgia
                    sumthanhtoan += tienthanhtoan
                  },
                )
                // console.log;
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <p className="font-semibold text-right">{formattedNumber(sumtienmat)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <p className="font-semibold text-right">
                          {formattedNumber(sumchuyenkhoan)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <p className="font-semibold text-right">{formattedNumber(summaypos)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10}>
                        <p className="font-semibold text-right">{formattedNumber(summomo)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11}>
                        <p className="font-semibold text-right">{formattedNumber(sumvnpay)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12}>
                        <p className="font-semibold text-right">{formattedNumber(sumzalopay)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13}>
                        <p className="font-semibold text-right">
                          {formattedNumber(sumthethanhtoan)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}></Table.Summary.Cell>
                      <Table.Summary.Cell index={15}>
                        <p className="font-semibold text-right">{formattedNumber(sumtongtien)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}>
                        <p className="font-semibold text-right">{formattedNumber(sumtiengiam)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={17}>
                        <p className="font-semibold text-right">{formattedNumber(sumthanhtoan)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }}
            />
          </ConfigProvider>
        </div>
      <DrawerThuocBanLe open={open} setOpen={setOpen} chiTiet={chiTiet} />
    </>
  )
}

export default PhieuBanLe
