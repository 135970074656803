import * as typeAction from '../constants/constants'

export const createBN_Action = (form) => ({
  type: typeAction.CREATE_BENH_NHAN,
  form,
})
export const searchBN_Action = (keyWord, idct) => ({
  type: typeAction.SEARCH_TRACUU_BN,
  keyWord,
  idct
})
export const getInfoBN_Action = (idBN, idChuyenKhoa) => ({
  type: typeAction.GET_INFO_TRACUU_BENH_NHAN,
  idBN,
  idChuyenKhoa,
})

export const getInfoBN_TT_Action = (idBN, idChuyenKhoa) => ({
  type: typeAction.GET_INFO_TRACUU_BENH_NHAN_TT,
  idBN,
  idChuyenKhoa,
})

export const getAll_BN = () => ({
  type: typeAction.GET_ALL_BN,
})

export const updateInfoBN = (form, onLoad, idChuyenKhoa, setLoading) => ({
  type: typeAction.UPDATE_BN_TRACUU,
  form,
  onLoad,
  idChuyenKhoa,
  setLoading
})

export const getList_BN_BY_CHINHANH_CTY = (idct,  idChinhanh, keyword, pageIndex, setIsLoading) => ({
  type: typeAction.GET_BN_CHINHANH_CTY,
  idct,
  idChinhanh,
  keyword,
  pageIndex,
  setIsLoading
})

export const getList_BN_BY_CHINHANH_CTY_Mat = (idct, keyword, pageIndex, setIsLoading) => ({
  type: typeAction.GET_BN_CHINHANH_CTY_MAT,
  idct,
  keyword,
  pageIndex,
  setIsLoading
})
