import { call, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { xetNghiemService } from '../../services/xetNghiem/xetNghiemService'
import * as typeAction from '../constants/constants'
import { companyService } from '../../services/Company/companyService'
export function* companySaga() {
  yield takeLatest(typeAction.GET_LIST_ALL_COMPANY, function* listCompany({ type }) {
    try {
      const result = yield call(() => companyService.getAllListCompany())
      yield put({
        type: typeAction.DISPATCH_LIST_ALL_COMPANY,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
}
