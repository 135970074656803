import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import * as typeAction from '../store/constants/constants'

const RouteComponent = ({ isAuth, Component, redirectPath, isCheckIn }) => {
  const token = Cookies.get('access-token')
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  // kiểm tra ca làm việc
  useEffect(() => {
    if (
      isAuth &&
      infoUser?.dangNhap?.thoiGianDangNhap === null &&
      infoUser?.tenNhom !== 'Ban Giám đốc'
    ) {
      dispatch({ type: typeAction.OPEN_MODAL_CHECK_IN })
    }
  }, [isAuth, infoUser, dispatch])
  if (isAuth) {
    // kiểm tra user đã đăng nhập hay chưa.
    return token ? <Component /> : <Navigate to={redirectPath} />
  }

  return <Component />
}

export default RouteComponent
