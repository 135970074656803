import { call, put, takeLatest } from 'redux-saga/effects'
import { dichVuService } from '../../services/dichVu/dichVuService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
export function* dichVuSaga() {
  //GET ALL LIST DICH VU
  yield takeLatest(typeAction.GET_ALL_LIST_DICH_VU, function* listDichVu({ type }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getAllListDichVu())
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // GET LIST DICH VU THEO NHOM DV
  yield takeLatest(typeAction.GET_LIST_DICH_VU, function* listDichVu({ type, idNhomDv, idct }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getListDichVu(idNhomDv, idct))
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // GET LIST GROUPS DICH VU
  yield takeLatest(typeAction.GET_LIST_GROUPS_DICH_VU, function* listGroupsDichVu({ type }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getListGroupsDichVu())
      yield put({
        type: typeAction.DISPATCH_GROUPS_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  
  //CHI TIET DICH VU
  yield takeLatest(typeAction.GET_INFO_DICH_VU, function* infoXetNgiem({ type, iddv }) {
    try {
      const result = yield call(() => dichVuService.fetchInfoDV(iddv))
      yield put({
        type: typeAction.DISPATCH_INFO_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })

  // SEARCH DV BY KEYWORD
  yield takeLatest(typeAction.SEARCH_DV_BY_KEYWORD, function* listDVByKeyword({ type, idnhomdv, idct, keyword }) {
    try {
      const result = yield call(() => dichVuService.searchByKeyword(idnhomdv, idct, keyword))
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })

  // ADD NEW DICH VU
  yield takeLatest(typeAction.POST_NEW_DICH_VU, function* addNewDichVu({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => dichVuService.addDichVu(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm dịch vụ thành công',
      })
      onLoad()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm dịch vụ thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  // DELETE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.DELETE_DICH_VU,
    function* deleteDVXetNghiem({ type, iddv, onLoad }) {
      try {
        yield call(() => dichVuService.deleteDichVu(iddv))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Xóa thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Xóa thất bại!',
        })
      }
    },
  )
  // UPDATE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.UPDATE_DICH_VU,
    function* updateDVXetNghiem({ type, form, onLoad }) {
      try {
        yield call(() => dichVuService.editDichVu(form))
        yield onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Cập nhập thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Cập nhập thất bại!',
        })
      }
    },
  )
}
