import { https } from '../apiService'
export const xuatSuDungService = {
  postPhieuXuatSuDung: (formData) => https.post(`XuatSuDung/AddXuatSD`, formData),
  postListVTHH: (list) => https.post(`XuatSuDungChiTiet/AddXuatSDChiTietList`, list),
  getListPhieuXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(
      `XuatSuDung/GetAll?${keyword?.length ? `keyword=${keyword}&` : ''}idct=${idct}&${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getInfoPhieuXuat: (IDXUATSD) => https.get(`XuatSuDung/GetByIDXUATSD`, { params: { IDXUATSD } }),
  getListVTHHCuaPhieuXuat: (IDXUATSD) =>
    https.get(`XuatSuDungChiTiet/GetChiTietPhieu`, { params: { IDXUATSD } }),
  getListVTHHDaXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(`XuatSuDung/GetHangHoaXuatSuDung`, {
      params: { keyword, idct, idcn, tuNgay, denNgay },
    }),
}
