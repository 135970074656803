import * as typeAction from '../constants/constants'

export const postDoiTacKHAction = (form, resetForm) => ({
  type: typeAction.POST_DOI_TAC_KH,
  form,
  resetForm,
})
export const putDoiTacKHAction = (form, resetForm) => ({
  type: typeAction.PUT_DOI_TAC_KH,
  form,
  resetForm,
})
