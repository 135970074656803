import { Input, Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({}) => {
  const today = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { InfoBNTrongVaGongKinh } = useSelector((state) => state.doKhucXaReducer)
  const infoTKDone = InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.find(
    (item) => item?.trangthaikb === 4 && item?.trangthaitt === 2,
  )
  //   const handleSelected = (value) => {
  //     formik.setFieldValue('donviguimau', value)
  //   }
  return (
    <div className="p-2 border rounded-md">
      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Tên BN: </label>
              <Input value={InfoBNTrongVaGongKinh?.TENBENHNHAN} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Mã BN: </label>
              <Input value={InfoBNTrongVaGongKinh?.MABENHNHAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Địa chỉ: </label>
              <Input
                value={
                  '' +
                  (InfoBNTrongVaGongKinh?.DIACHI?.length > 0 ? InfoBNTrongVaGongKinh?.DIACHI : '') +
                  (InfoBNTrongVaGongKinh?.TENPHUONG?.length > 0
                    ? ', ' + InfoBNTrongVaGongKinh?.TENPHUONG
                    : '') +
                  (InfoBNTrongVaGongKinh?.TENQUAN?.length > 0
                    ? ', ' + InfoBNTrongVaGongKinh?.TENQUAN
                    : '') +
                  (InfoBNTrongVaGongKinh?.TENTINH?.length > 0
                    ? ', ' + InfoBNTrongVaGongKinh?.TENTINH
                    : '')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">SĐT: </label>
              <Input value={InfoBNTrongVaGongKinh?.DIENTHOAI} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Thực hiện: </label>
              <Input
                value={
                  infoTKDone && infoTKDone?.nguoithuchien
                    ? infoTKDone?.nguoithuchien
                    : infoUser?.tenNV

                  // InfoBNTrongVaGongKinh
                  //   ? InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.length > 0
                  //     ? InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan[0]?.nguoithuchien ??
                  //       infoUser?.tenNV
                  //     : ''
                  //   : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày: </label>
              <Input
                value={
                  infoTKDone && infoTKDone?.ngaythuchien
                    ? moment(infoTKDone?.ngaythuchien).format('DD/MM/YYYY')
                    : today.format('DD/MM/YYYY')
                  // InfoBNTrongVaGongKinh
                  //   ? InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.length > 0
                  //     ? InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan[0]?.ngaythuchien
                  //       ? moment(
                  //           InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan[0]?.ngaythuchien,
                  //         ).format('DD/MM/YYYY')
                  //       : today.format('DD/MM/YYYY')
                  //     : ''
                  //   : ''
                }
                variant="filled"
                readOnly
              />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Chẩn đoán: </label>
              <Input value={InfoBNTrongVaGongKinh?.CHANDOAN} variant="filled" readOnly />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Ghi chú: </label>
              <Input value={InfoBNTrongVaGongKinh?.GHICHU} variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 flex flex-col">
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Ngày sinh: </label>
              <Input
                value={
                  InfoBNTrongVaGongKinh?.NGAYSINH &&
                  moment(InfoBNTrongVaGongKinh?.NGAYSINH).format('DD/MM/YYYY')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Giới tính: </label>
              <Input value={InfoBNTrongVaGongKinh?.GIOITINH} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Đối tượng: </label>
              <Input value={InfoBNTrongVaGongKinh?.DOITUONG} variant="filled" readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Mã TCQG: </label>
              <Input value={InfoBNTrongVaGongKinh?.MATCQG} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lý do: </label>

              <Input variant="filled" value={InfoBNTrongVaGongKinh?.LIDO} readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lời dặn: </label>
              <Input value={InfoBNTrongVaGongKinh?.LOIDAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Bác sĩ CĐ: </label>
            <Input variant="filled" value={InfoBNTrongVaGongKinh?.BSCHANDOAN} readOnly />
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Triệu chứng: </label>
            <Input variant="filled" value={InfoBNTrongVaGongKinh?.TRIEUCHUNG} readOnly />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBN
