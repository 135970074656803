import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Input,
  Table,
  ConfigProvider,
  Button,
  DatePicker,
  Modal,
  Drawer,
  Select,
  TreeSelect,
} from 'antd'
import { FileTextOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons'
import { xuatSuDungService } from '../../../../services/xuatSuDung/xuatSuDungService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
function ListPhieu({ keyTabs }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listPhieuXuat, setListPhieuXuat] = useState([])
  const [infoPhieuXuat, setInfoPhieuXuat] = useState(null)
  const [listVTHHCuaPhieuXuat, setListVTHHCuaPhieuXuat] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [value, setValue] = useState([ID_BRANCH])
  const [listKhoCn, setListKhoCn] = useState([])
  const [idKhoCn, setIdKhoCn] = useState(0)

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: listChiNhanh
        // ?.filter((item) => !idCongTy || item?.idct === idCongTy)
        ?.map((item, index) => ({
          title: item?.tenChiNhanh,
          value: item?.idChiNhanh,
          key: `${item?.idChiNhanh}`,
        })),

      // ?.filter((item) => item?.loaiChiNhanh === 'CHINHANH')
      // ?.filter((item) => (company ? item?.idct === company : item)) // dư
      // ?.filter((item) => (filterCK ? item?.idChuyenKhoa === filterCK : item))
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getListPhieuXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await xuatSuDungService.getListPhieuXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        value?.includes('all')
          ? listChiNhanh
              ?.map((item) => `idcn=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idcn=${item}`)
              ?.join('&')
              ?.toString(),
        dateForm,
        dateTo,
      )
      setListPhieuXuat(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getInfoPhieuXuatById = async (idPhieuXuat) => {
    try {
      setIsLoading(true)
      const { data: info } = await xuatSuDungService.getInfoPhieuXuat(idPhieuXuat)
      const { data: list } = await xuatSuDungService.getListVTHHCuaPhieuXuat(idPhieuXuat)
      setInfoPhieuXuat(info)
      setListVTHHCuaPhieuXuat(list)
      // setListPhieuXuat(data)
    } catch (error) {
      console.log(error)
      setInfoPhieuXuat(null)
      setListVTHHCuaPhieuXuat([])
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  const getListKhoCnByIdCn = async (idcn) => {
    try {
      setIsLoading(true)
      const { data: arrKhoCn } = await chuyenKhoService.getlistKhoNhan(idcn)
      setListKhoCn(arrKhoCn)
    } catch (error) {
      console.log(error)
      setListKhoCn([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
        // getListKhoCnByIdCn(idChiNhanh)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (keyTabs === 2) {
      getListPhieuXuat()
    }
  }, [keyTabs])
  return (
    <div>
      <Drawer
        placement="right"
        width={1000}
        open={isModal}
        onClose={() => setIsModal(false)}
        title={'Chi tiết phiếu xuất sử dụng'}
      >
        <div className="grid grid-flow-row items-center gap-2">
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Tên phiếu:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tenphieu}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Mã phiếu:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.maphieu}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Người xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tennguoixuat}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Ngày xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={
                  infoPhieuXuat?.ngayxuat
                    ? moment(infoPhieuXuat?.ngayxuat).format('DD/MM/YYYY HH:mm:ss')
                    : '-'
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Chi nhánh xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tenchinhanh}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Kho xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tenkhoxuat}
              />
            </div>
          </div>
          {/* <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Kho xuất:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.tenkhoxuat}
            />
          </div> */}
          <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Bộ phận sử dụng:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.tenbophansudung}
            />
          </div>
          <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Nội dung:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.noidung}
            />
          </div>
          <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Ghi chú:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.ghichu}
            />
          </div>
          <div>
            <ul className="flex flex-wrap gap-2">
              {infoPhieuXuat?.filehoadon?.split(';')?.map((url, index) => {
                return (
                  url?.length && (
                    <li
                      key={`hinh${index}:${url}`}
                      className="w-[8.1rem] h-[8.1rem] overflow-hidden border rounded-md relative"
                    >
                      {/* <DeleteOutlined
                    // onClick={() => deleteFileById(items.id)}
                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                    style={{ fontSize: 18 }}
                  /> */}
                      <a href={url} target="_blank">
                        <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                          <div className="relative "></div>
                          {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                            {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                          </p> */}
                        </div>
                        <img src={url} className="w-full" alt={url} />
                      </a>
                    </li>
                  )
                )
              })}
            </ul>
          </div>
          <div className="w-[59.7rem]">
            <ConfigProvider theme={{ token: { padding: 2, fontSize: 12 } }}>
              <Table
                scroll={{ x: 1150, y: 350 }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={listVTHHCuaPhieuXuat}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    width: 40,
                    align: 'center',
                    fixed: 'left',
                  },
                  {
                    title: 'Mã hàng',
                    dataIndex: 'mathuoc',
                    key: 'mathuoc',
                    width: 100,
                    fixed: 'left',
                  },
                  {
                    title: 'Tên hàng',
                    dataIndex: 'tenbietduoc',
                    key: 'tenbietduoc',
                    fixed: 'left',
                  },
                  {
                    title: 'Số lô',
                    dataIndex: 'solo',
                    key: 'solo',
                    width: 100,
                  },
                  {
                    title: 'Hạn Dùng',
                    dataIndex: 'handung',
                    key: 'handung',
                    align: 'center',
                    width: 100,
                    render: (text, record, index) => (text ? moment(text).format(dateFormat) : '-'),
                  },
                  {
                    title: 'Số hóa đơn',
                    dataIndex: 'sohoadon',
                    key: 'sohoadon',
                    width: 100,
                  },
                  {
                    title: 'ĐVT',
                    dataIndex: 'dvt',
                    key: 'dvt',
                    width: 50,
                    align: 'center',
                  },
                  // {
                  //   title: 'Mã số đăng ký',
                  //   dataIndex: 'masodangky',
                  //   key: 'masodangky',
                  //   width: 120,
                  // },
                  {
                    title: 'Số lượng',
                    dataIndex: 'soluong',
                    key: 'soluong',
                    width: 60,
                    align: 'center',
                  },
                  {
                    title: 'Ghi chú',
                    dataIndex: 'ghichu',
                    key: 'ghichu',
                    width: 250,
                  },
                  // {
                  //   title: '',
                  //   dataIndex: 'action',
                  //   key: 'action',
                  //   width: 40,
                  //   align: 'center',
                  //   fixed: 'right',
                  //   render: (text, record, index) => (
                  //     <>
                  //       {/* <CloseOutlined
                  //     className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                  //     onClick={() =>
                  //       setListXuatVTHH((prev) =>
                  //         prev?.filter((item) => item?.key !== record?.key),
                  //       )
                  //     }
                  //   /> */}
                  //     </>
                  //   ),
                  // },
                ]}
              />
            </ConfigProvider>
          </div>
        </div>
      </Drawer>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setValue(['all'])
                // setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-[17rem] h-8 overflow-clip"
              size="medium"
            />
            {/* <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả các chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
                setIdKhoCn(0)
                getListKhoCnByIdCn(value)
              }}
            /> */}
          </>
        )}
        {/* <Select
          disabled={isLoading}
          className="w-[17rem]"
          options={[
            {
              key: `khocn0`,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKhoCn?.map((item) => ({
              key: `khocn${item?.idKhoCN}`,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        <Input
          placeholder="Tìm mã phiếu, tên phiếu..."
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListPhieuXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ y: 720 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listPhieuXuat}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 45,
              align: 'center',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày xuất',
              dataIndex: 'ngayxuat',
              key: 'ngayxuat',
              width: 140,
              align: 'center',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            },
            {
              title: 'Mã phiếu',
              dataIndex: 'maphieu',
              key: 'maphieu',
              width: 140,
            },
            {
              title: 'Tên phiếu',
              dataIndex: 'tenphieu',
              key: 'tenphieu',
            },
            {
              title: 'Chi nhánh xuất',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 250,
            },
            {
              title: 'Kho xuất',
              dataIndex: 'tenkhoxuat',
              key: 'tenkhoxuat',
              width: 250,
            },
            {
              title: 'Người xuất',
              dataIndex: 'tennguoixuat',
              key: 'tennguoixuat',
              width: 220,
            },
            {
              title: 'Bộ phận sử dụng',
              dataIndex: 'tenbophansudung',
              key: 'tenbophansudung',
              width: 130,
            },
            // {
            //   title: 'Người sửa',
            //   dataIndex: 'tennguoisua',
            //   key: 'tennguoisua',
            //   width: 220,
            // },
            // {
            //   title: 'Ngày sửa',
            //   dataIndex: 'ngaysua',
            //   key: 'ngaysua',
            //   width: 140,
            //   align: 'center',
            //   render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            // },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 40,
              align: 'center',
              render: (text, record, index) => (
                <>
                  <FileTextOutlined
                    className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                    onClick={() => {
                      setIsModal(true)
                      getInfoPhieuXuatById(record?.idxuatsd)
                    }}
                  />
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListPhieu
