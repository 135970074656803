import React, { useRef, useState } from 'react'
import { Button, notification, List, Image, Checkbox } from 'antd'
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
const Images = ({ image, setImage }) => {
  const fileInputRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()
  const { infoBNDienTim } = useSelector((state) => state.dienTimReducer)

  const openNotificationWithIcon = () => {
    api.error({
      message: 'Chọn file siêu âm ',
      description: 'Chỉ chấp nhận file hình ảnh!',
    })
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files)
    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    const validFiles = files.filter((file) => allowedFileTypes.includes(file.type))
    if (validFiles.length < files.length) {
      openNotificationWithIcon()
    }
    const validFilesWithIdAndUrl = validFiles.map((file) => ({
      file,
      id: uuidv4(), // Tạo ID duy nhất với uuid
      url: URL.createObjectURL(file), // Tạo URL cho file
      checked: false,
    }))
    fileInputRef.current.value = ''
    setImage([...image, ...validFilesWithIdAndUrl])
  }
  const handleRemoveFile = (item) => {
    setImage(image.filter((f) => f.id !== item.id))
  }

  return (
    <div className=" h-full">
      {contextHolder}
      <div className="flex flex-wrap gap-2">
        {image?.map((item) => (
          <div key={item.id} className="border rounded-md w-1/6 h-40 overflow-hidden  ">
            <div className="h-4/5 overflow-hidden border-b">
              <Image src={item.url} />
            </div>
            {!infoBNDienTim?.infoDienTim && (
              <div className="h-1/5 flex justify-around items-center">
                <DeleteOutlined
                  onClick={() => handleRemoveFile(item)}
                  style={{ fontSize: 18 }}
                  className="text-red-500 p-1 cursor-pointer hover:bg-red-500 hover:text-white bg-red-100 rounded-md"
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-2">
        <input
          accept="image/*"
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {!infoBNDienTim?.infoDienTim && (
          <Button onClick={handleButtonClick} type="primary" ghost icon={<PaperClipOutlined />}>
            Thêm ảnh
          </Button>
        )}
      </div>
    </div>
  )
}

export default Images
