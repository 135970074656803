import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import { soKhamBenhService } from '../../services/soKhamBenh/soKhamBenhService'
import * as typeAction from '../constants/constants'
import 'moment/locale/vi'
moment.locale('vi')

export function* soKhamBenhSaga() {
  // TÌM KIẾM BỆNH NHÂN
  yield takeLatest(typeAction.SEARCH_TRACUU_BN_SKB, function* searchBN({ type, keyWord }) {
    try {
      const result = yield call(() => soKhamBenhService.searchBN(keyWord))
      yield put({
        type: typeAction.DISPATCH_TRACUU_LIST_BN_SKB,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  //GET BY ID CHINHANH ID CTY
  yield takeLatest(
    typeAction.GET_BN_CHINHANH_CTY_SKB,
    function* getBNByIdChiNhanhIdCty({ type, idct, idChinhanh }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => soKhamBenhService.getByIdChinhanhIdct(idct, idChinhanh))
        yield put({
          type: typeAction.DISPATCH_TRACUU_LIST_BN_SKB,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
