import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  infoKSK: null,
  pending: null,
  ListBNDangKham: null,
  ListBNDaKham: null,
  coKeToa: null,
}

const khamSucKhoeReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_BN_CHO_KSK:
        draft.pending = payload
        break
      case typeAction.DISPATCH_LIST_BN_DANG_KSK:
        draft.ListBNDangKham = payload
        break
      case typeAction.DISPATCH_LIST_BN_DA_KSK:
        draft.ListBNDaKham = payload
        break
      case typeAction.DISPATCH_INFO_BN_KSK:
        draft.infoKSK = payload
        break
      case typeAction.DISPATCH_CO_KE_TOA_KSK:
        draft.coKeToa = payload
        break
      default:
        return state
    }
  })
}

export default khamSucKhoeReducer
