import { https } from '../apiService'

export const dienTimService = {
  getBNPendingByBranch: (idcn) => https.get(`BenhNhanDienTim/BenhNhanChoDienTim?idcn=${idcn}`),
  getBNDoneBtBranch: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanDienTim/BenhNhanDaThucHienDienTim?idcn=${idcn}&fromdate=${tuNgay}&toDate=${denNgay}`),
  getInfoBn: (id, idcdha) =>
    https.get(`BenhNhanSieuAm/GetThongTinBenhNhanSieuAm?idbnttc=${id}&idbncdha=${idcdha}`),
  postBnDienTim: (form) => https.post(`BenhNhanDienTim/ThemBenhNhanDienTim`, form),
  getInfoBnPending: (idbnttc) => https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`),
  getInfoBnDienTim: (idbndt) => https.get(`BenhNhanDienTim/GetBenhNhanDienTimByIDBNMT?IDBNMT=${idbndt}`),
}
