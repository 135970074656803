import {
  Button,
  ConfigProvider,
  DatePicker,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Space,
  Tabs,
  Divider,
  Radio,
  Popover,
} from 'antd'
import dayjs from 'dayjs'
import { SearchOutlined, UpOutlined, DownOutlined, PrinterOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import ToaThuoc from '../../../../ChildrenClinic/General/ToaThuoc/ToaThuoc'
import { childrenClinicService } from '../../../../../../services/childrenClinic/childrenClinicService'
import {
  deleteBNThuocBSKhongKeToaAction,
  updateBNThuocBSKeToaLaiAction,
} from '../../../../../../store/actions/childrenClinicAction'

import { maternityClinicService } from '../../../../../../services/maternityClinic/maternityClinicService'
import { khamSucKhoe } from '../../../../../../services/khamSucKhoe/khamSucKhoeService'
import { sieuAmService } from '../../../../../../services/SieuAm/sieuAmService'
import style from '../../../../SieuAm/Template/style.module.css'
// import {
//   deleteBNThuocBSKhongKeToaSanAction,
//   updateBNThuocBSKeToaLaiSanAction,
// } from '../../../../../../store/actions/maternityClinicAction'
import Swal from 'sweetalert2'
import { generatePhieuToaThuoc } from '../../../../../../utils/report/phieuToaThuoc'
import 'moment/locale/vi'
import { generatePhieuKhamBenh } from '../../../../../../utils/report/phieuKhamBenh'
moment.locale('vi')
const { TextArea } = Input
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
const FormCD = ({
  date,
  count,
  checkBox,
  setCheckBox,
  formik,
  setDate,
  formikKSK,
  setCount,
  isPrintToaThuocKhiLuu,
  setIsPrintToaThuocKhiLuu,
}) => {
  const dispatch = useDispatch()
  const { infoKSK, coKeToa } = useSelector((state) => state.khamSucKhoeReducer)
  const { loidan, DetailBN, ThuocBN, infoBN, DetailToaThuocBN } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [ChanDoan, setChanDoan] = useState([])
  const [listLyDoKhongKeToa, setListLyDoKhongKeToa] = useState([])
  // const [loaiKham, setLoaiKham] = useState(formikPhuSan.values.idloaisan)
  const [tenLoaiKham, setTenLoaiKham] = useState('Sản lớn')
  const [listBieuMau, setListBieuMau] = useState([])
  const [isFormKham, setIsFormKham] = useState(true)
  const [bieuMauChon, setBieuMauChon] = useState(null)
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day')
  }
  const [isExpanded, setIsExpanded] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (newState) => {
    setEditorState(newState)
  }
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }
  const handleSlected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeDatePicker = (date, dateString) => {
    setDate(moment(dateString, 'DD/MM/YYYY').format())
    formik.setFieldValue('ngaytaikham', moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      fechChanDoan(keyword)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchListLyDoKhongKeToa = async () => {
    try {
      const result = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoKhongKeToa(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const onClickChanDoan = (value, options, key, keyIDC) => {
    const newValue = options.mabenh + '-' + options.icD10TV + '/'
    formik.setFieldValue(key, newValue)
    formik.setFieldValue(keyIDC, value)
    //reset lai chan doan
    fechChanDoan('')
  }

  const onClick = (e) => {
    if (
      DetailBN?.trangthaikhambenh === 2 &&
      (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
      DetailToaThuocBN?.DANHANTOA === 1
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (coKeToa === 1 && (DetailBN?.trangthaikhambenh === 2 || DetailBN?.trangthaikhambenh === 1)) {
      const { checked } = e.target
      // formik.setFieldValue('lydokolaythuoc', null)
      // setCheckBox(checked)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // setCount(0)
      // formik.setFieldError('ngaytaikham', null)

      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      formik.setFieldError('ngaytaikham', null)
      setDate(dayjs(new Date()))
      setCount(0)
      setCheckBox(checked)
    }
  }
  const handleLuuVaIn = async (isPrint) => {
    setIsPrintToaThuocKhiLuu(isPrint)
    // if (ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
    if (
      isPrint &&
      ((checkBox === false && ThuocBN?.length <= 0) || (checkBox && !formik.values.lydokolaythuoc))
    ) {
      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldError('lydokolaythuoc', true)
      formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      formik.setFieldError('ngaytaikham', null)
      setDate(dayjs(new Date()))
      setCount(0)
      setCheckBox(true)
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })

      // formik.setFieldValue('lydokolaythuoc', null)
      // setCheckBox(true)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // setCount(0)
      return
    }
    if (
      checkBox === false &&
      ThuocBN?.find((item) => item?.soLuong <= 0 || item?.ton < 0 || !item?.soLuong || !item?.ton)
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng thuốc trong toa!',
      })
      return
    }
    // if (formikKSK.errors.bieumau || formikKSK.values.bieumau?.includes(`,\"text\":\"\",`)) {
    //   formikKSK.setFieldValue('bieumau', null)
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Vui lòng kiểm tra lại biểu mẫu!',
    //   })
    //   return
    // }
    // if (!formik.values.para || formik.values.para?.trim()?.length === 0) {
    //   formik.setFieldValue('para', null)
    //   formik.setErrors({ para: 'require' })
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Vui lòng nhập chỉ số Para!',
    //   })
    //   return
    // }
    if (DetailBN?.trangthaikhambenh === 1) {
      if (_.isEmpty(formik.errors)) {
        //&& _.isEmpty(formikKSK.errors)

        //nếu mà không có lỗi mới lưu
        const contentState = editorState.getCurrentContent()
        const row = convertToRaw(contentState)
        const data = JSON.stringify(row)
        formikKSK.setFieldValue('bieumau', data)

        formik.handleSubmit()
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
      }
      return
    }
    if (DetailBN?.trangthaikhambenh === 2) {
      if (
        (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
        DetailToaThuocBN?.DANHANTOA === 1
      ) {
        Toast.fire({
          icon: 'error',
          title: 'Dược sĩ đang thao tác!',
        })
        return
      }
      if (checkBox && !formik.values.lydokolaythuoc) {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
        return
      }
      // kê lại toa khi dược chưa lưu
      if (checkBox || ThuocBN?.length < 1) {
        // lưu khi tích chọn không kê toa hoặc toa thuốc rỗng
        dispatch(deleteBNThuocBSKhongKeToaAction(DetailBN?.idbnttc, formik.values.lydokolaythuoc))
        setCheckBox(false)
      } else {
        // lưu khi không chỉnh sửa hoặc chỉnh số lượng thuốc trong toa hoặc cho thêm thuốc vào toa
        dispatch(
          updateBNThuocBSKeToaLaiAction(
            DetailBN?.idbnttc,
            ThuocBN?.map((item) => ({
              ...item,
              lieuDung: item?.lieuDung ?? ``,
              soLanNgay: item?.soLanNgay ?? ``,
            })),
            infoBN,
            DetailBN,
            PkDangNhap,
            isPrint,
          ),
        )
      }
      return
    }
  }
  const handleChangeLucThai = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const handleChangeLyDoKhongLayThuoc = (value) => {
    if (coKeToa === 1) formik.setFieldValue('lydokolaythuoc', value)
  }
  //----------- lấy danh sách loại khám -----------
  const fetchListBieuMau = async () => {
    try {
      const { data } = await sieuAmService.getALLBieuMau(2) // 2 là loại biểu mẫu khám sức khỏe
      setListBieuMau(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handlePrint = () => {
    const infoBn = {
      diaChi: DetailBN.benhNhan.diaChi,
      tenPhuongXa: DetailBN.benhNhan.tenPhuongXa,
      tenQuanHuyen: DetailBN.benhNhan.tenQuanHuyen,
      tenTinhTP: DetailBN.benhNhan.tenTinhTP,
    }
    const formatToaThuoc = DetailToaThuocBN.DanhSachThuoc.map((item) => ({
      ...item,
      soLuong: item.soluong,
      tenhoatchat: item.tenhoatchat,
      duongDung: item.duongdung,
      soLanNgay: item.solanngay,
      lieuDung: item.lieudung,
      ghiChu: item.ghichu,
    }))
    generatePhieuToaThuoc(infoBn, DetailBN, formatToaThuoc, PkDangNhap)
  }
  //--------- INFO LÂY BIỂU ------------//
  const onClickBieuMau = async (value) => {
    try {
      const { data } = await sieuAmService.getInfoBieuMau(value)
      const convertJson = JSON.parse(data.mota)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
      setBieuMauChon(value)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fechChanDoan('')
    fetchListLyDoKhongKeToa()
    fetchListBieuMau()
  }, [])
  useEffect(() => {
    if (ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
  }, [ThuocBN?.length])
  useEffect(() => {
    // if (DetailBN && listBieuMau?.length > 0 && !infoKSK?.bieumau) {
    //   // setBieuMauChon(listBieuMau[0]?.idbm ?? null)
    //   onClickBieuMau(listBieuMau[0]?.idbm)
    // }
    setBieuMauChon(null)
    if (infoKSK?.bieumau) {
      const convertJson = JSON.parse(infoKSK?.bieumau)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [DetailBN])
  const handleSoNgayTK = (e) => {
    if (e.target.value >= 0 && e.target.value < 10000) {
      setCount(e.target.value)
      formik.setFieldValue(
        'ngaytaikham',
        moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'),
      )
      setDate(moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'))
    } else {
      setCount(0)
      formik.setFieldValue('ngaytaikham', moment()?.add(0, 'days')?.format('YYYY-MM-DD'))
      setDate(moment()?.add(0, 'days')?.format('YYYY-MM-DD'))
    }
  }

  const content = (
    <div className="w-32">
      <Button
        onClick={handlePrint}
        type="primary"
        disabled={!infoBN || !DetailBN || checkBox}
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Toa thuốc
      </Button>
      <Button
        type="primary"
        onClick={() => {
          generatePhieuKhamBenh(DetailBN, PkDangNhap)
        }}
        style={{ width: '100%' }}
      >
        Phiếu khám bệnh
      </Button>
    </div>
  )

  return (
    <div>
      <form>
        <div className="flex gap-2 justify-end">
          {/* <div className="flex w-56 items-center justify-start mr-5">
            <label className="font-semibold w-1/3 text-end mr-2">Loại khám:</label>
            <Select
              size="small"
              className="w-2/3"
              onChange={(value, option) => {
                formikPhuSan.setFieldValue('idloaisan', value)
                setTenLoaiKham(option.label)
              }}
              options={listLoaiKham?.map((item) => ({
                value: item.idbnsanloai,
                label: item.tensanloai,
              }))}
              value={formikPhuSan.values.idloaisan}
            />
          </div> */}
          <div className="flex w-40 items-center">
            <label className="font-semibold w-1/2">Số ngày TK:</label>
            {/* <Input size="small" variant="filled" readOnly className="w-1/2" value={count ?? 0} /> */}
            <Input
              disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
              style={{ color: 'black' }}
              type="number"
              min={0}
              step={1}
              size="small"
              variant="filled"
              // readOnly
              className="w-1/2"
              value={count ?? 0}
              onChange={handleSoNgayTK}
              // onChange={(e) => {
              //   if (e.target.value >= 0) {
              //     setCount(e.target.value)
              //     formik.setFieldValue(
              //       'ngaytaikham',
              //       moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'),
              //     )
              //   }
              // }}
            />
          </div>
          <div className="flex w-56 items-center">
            <label className="font-semibold w-1/2 text-end mr-2">Hẹn tái khám:</label>
            <DatePicker
              status={
                checkBox
                  ? ''
                  : formik.errors.ngaytaikham && formik.values.ngaytaikham === null
                    ? 'error'
                    : ''
              }
              allowClear={false}
              onChange={onChangeDatePicker}
              value={formik.values.ngaytaikham === null ? '' : dayjs(formik.values.ngaytaikham)}
              size="small"
              className="w-1/2"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
              placeholder=" "
              disabled={(DetailBN && DetailBN?.trangthaikhambenh !== 1) || checkBox}
              style={{ color: 'black' }}
            />
          </div>
          <Checkbox onChange={onClick} checked={checkBox} className="flex items-center">
            Không kê toa
          </Checkbox>
          {/* <Button onClick={handlePrintPhieuKham} type="primary" ghost>
            In phiếu khám
          </Button> */}
          {DetailBN && DetailBN?.trangthaikhambenh !== 1 && (
            <Popover content={content} trigger="click" placement="bottom">
              <Button type="primary" disabled={!infoBN || !DetailBN}>
                <PrinterOutlined /> In
              </Button>
            </Popover>
          )}
          <Button
            disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
            onClick={() => handleLuuVaIn(false)}
            type="primary"
          >
            {DetailBN?.trangthaikhambenh === 1 ? 'Cập nhật' : 'Lưu'}
          </Button>
          <Button
            disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
            onClick={() => handleLuuVaIn(true)}
            type="primary"
          >
            Lưu & in
          </Button>
        </div>
        <div className="h-[693px]  overflow-x-hidden">
          <Tabs
            tabPosition={'left'}
            type="card"
            items={[
              {
                label:
                  formik.errors.lydokolaythuoc ||
                  formik.errors.lydokham ||
                  formik.errors.benhsu ||
                  formik.errors.trieuchung ||
                  formik.errors.loidan ? (
                    <>
                      Chung
                      <span className="text-red-500"> (*)</span>
                    </>
                  ) : (
                    <>Chung</>
                  ),
                key: 1,
                children: (
                  <>
                    {checkBox ? (
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-44 text-end">
                          <span className="text-red-500">(*)</span>&nbsp;Lý do không kê toa:
                        </label>
                        <Select
                          // disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                          style={{ color: 'black' }}
                          //showSearch
                          //filterOption={false}
                          className="w-full"
                          size="small"
                          status={formik.errors.lydokolaythuoc ? 'error' : ''}
                          onChange={handleChangeLyDoKhongLayThuoc}
                          value={formik.values.lydokolaythuoc}
                          // onSearch={debounceDropDown}
                          // suffixIcon={<SearchOutlined className=" " />}
                          // notFoundContent={null}
                          options={listLyDoKhongKeToa?.map((item) => ({
                            key: item?.idld,
                            value: item?.idld,
                            label: item?.lydo,
                          }))}
                          // options={{
                          //   key: item.idld,
                          //   value: item.idld,
                          //   label: item.lydo,},
                          //   ...(listLyDoKhongKeToa?listLyDoKhongKeToa?.map((item) => ({
                          //     key: item.idld,
                          //     value: item.idld,
                          //     label: item.lydo,
                          //   })):[])}
                          // optionRender={(options) => (
                          //   <ul className="flex">
                          //     <li className="w-1/6">{options.data.mabenh}</li>
                          //     <li className="w-5/6 border-l px-5">{options.data.label}</li>
                          //   </ul>
                          // )}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Lý do khám:
                      </label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        status={formik.errors.lydokham ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.lydokham}
                        name="lydokham"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Bệnh sử:
                      </label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        status={formik.errors.benhsu ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.benhsu}
                        name="benhsu"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Triệu chứng:
                      </label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        status={formik.errors.trieuchung ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.trieuchung}
                        name="trieuchung"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">ICD-10 chẩn đoán:</label>
                      <Select
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        showSearch
                        filterOption={false}
                        className="w-full"
                        size="small"
                        onChange={(value, options) =>
                          onClickChanDoan(value, options, 'chandoan', 'idchandoan')
                        }
                        onSearch={debounceDropDown}
                        suffixIcon={<SearchOutlined className=" " />}
                        notFoundContent={null}
                        value={formik.values.idchandoan}
                        options={ChanDoan?.map((items) => ({
                          label: items.mabenh + ' - ' + items.icD10TV,
                          value: items.idicD10,
                          mabenh: items.mabenh,
                          ...items,
                        }))}
                        optionRender={(options) => (
                          <ul className="flex">
                            <li className="w-1/6">{options.data.mabenh}</li>
                            <li className="w-5/6 border-l px-5">{options.data.label}</li>
                          </ul>
                        )}
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Chẩn đoán:</label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.chandoan}
                        name="chandoan"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">ICD-10 CĐ bệnh phụ:</label>
                      <Select
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        showSearch
                        filterOption={false}
                        className="w-full"
                        size="small"
                        onChange={(value, options) =>
                          onClickChanDoan(value, options, 'benhkemtheo', 'idicD10BENHKEMTHEO')
                        }
                        onSearch={debounceDropDown}
                        suffixIcon={<SearchOutlined className=" " />}
                        notFoundContent={null}
                        value={formik.values.idicD10BENHKEMTHEO}
                        options={ChanDoan?.map((items) => ({
                          label: items.mabenh + ' - ' + items.icD10TV,
                          value: items.idicD10,
                          mabenh: items.mabenh,
                          ...items,
                        }))}
                        optionRender={(options) => (
                          <ul className="flex">
                            <li className="w-1/6">{options.data.mabenh}</li>
                            <li className="w-5/6 border-l px-5">{options.data.label}</li>
                          </ul>
                        )}
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Chẩn đoán bệnh phụ:</label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.benhkemtheo}
                        name="benhkemtheo"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Gợi ý lời dặn:</label>
                      <Select
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={loidan?.map((items) => ({
                          label: items.loiDan,
                          value: items.loiDan,
                        }))}
                        placeholder="Chọn gọi ý lời dặn"
                        showSearch
                        status={formik.errors.idloidan ? 'error' : ''}
                        onChange={handleSlected('loidan')}
                        value={null}
                        name="loidan"
                        size="small"
                        className="w-full truncate"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Lời dặn:
                      </label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        status={formik.errors.loidan ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.loidan}
                        name="loidan"
                        size="small"
                      />
                      {/* <Select
                      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={loidan?.map((items) => ({
                        label: items.loiDan,
                        value: items.idld,
                      }))}
                      showSearch
                      status={formik.errors.idloidan ? 'error' : ''}
                      onChange={handleSlected('idloidan')}
                      value={formik.values.idloidan}
                      name="loidan"
                      size="small"
                      className="w-full"
                    /> */}
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Tiền sử bản thân:</label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.tiensubanthan}
                        name="tiensubanthan"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">ICD-10 CĐ ban đầu:</label>
                      <Select
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        showSearch
                        filterOption={false}
                        className="w-full"
                        size="small"
                        onChange={(value, options) =>
                          onClickChanDoan(value, options, 'chandoanbandau', 'idicD10BANDAU')
                        }
                        onSearch={debounceDropDown}
                        suffixIcon={<SearchOutlined className=" " />}
                        notFoundContent={null}
                        value={formik.values.idicD10BANDAU}
                        options={ChanDoan?.map((items) => ({
                          label: items.mabenh + ' - ' + items.icD10TV,
                          value: items.idicD10,
                          mabenh: items.mabenh,
                          ...items,
                        }))}
                        optionRender={(options) => (
                          <ul className="flex">
                            <li className="w-1/6">{options.data.mabenh}</li>
                            <li className="w-5/6 border-l px-5">{options.data.label}</li>
                          </ul>
                        )}
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Chẩn đoán ban đầu:</label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.chandoanbandau}
                        name="chandoanbandau"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Dị ứng:</label>
                      <Input
                        disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.diung}
                        name="diung"
                        size="small"
                      />
                    </div>
                    {/* ########################################################################## */}
                    {isExpanded && (
                      <>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Quá trình bệnh lý:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.quatrinhbenhly}
                            name="quatrinhbenhly"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Tiền sử gia đình:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.tieusugiadinh}
                            name="tieusugiadinh"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Toàn thân:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.toanthan}
                            name="toanthan"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Các bộ phận:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.cacbophan}
                            name="cacbophan"
                            size="small"
                          />
                        </div>

                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Đã xử lý:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.daxuly}
                            name="daxuly"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">ICD-10 CĐ ra viện:</label>
                          <Select
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            showSearch
                            filterOption={false}
                            className="w-full"
                            size="small"
                            onChange={(value, options) =>
                              onClickChanDoan(value, options, 'chandoanravien', 'idicD10RAVIEN')
                            }
                            onSearch={debounceDropDown}
                            suffixIcon={<SearchOutlined className=" " />}
                            notFoundContent={null}
                            value={formik.values.idicD10RAVIEN}
                            options={ChanDoan?.map((items) => ({
                              label: items.mabenh + ' - ' + items.icD10TV,
                              value: items.idicD10,
                              mabenh: items.mabenh,
                              ...items,
                            }))}
                            optionRender={(options) => (
                              <ul className="flex">
                                <li className="w-1/6">{options.data.mabenh}</li>
                                <li className="w-5/6 border-l px-5">{options.data.label}</li>
                              </ul>
                            )}
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Chẩn đoán ra viện:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.chandoanravien}
                            name="chandoanravien"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Điều trị ngoại trú:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.dieutringoaitru}
                            name="dieutringoaitru"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Diễn biến lâm sàng:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.dienbienlamsang}
                            name="dienbienlamsang"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">
                            Phương pháp điều trị:
                          </label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.ppdieutri}
                            name="ppdieutri"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">
                            Tình trạng lúc ra viện:
                          </label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.ttlucravien}
                            name="ttlucravien"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-44 text-end">Hướng xử lý:</label>
                          <Input
                            disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                            style={{ color: 'black' }}
                            onChange={formik.handleChange}
                            value={formik.values.huongxuly}
                            name="huongxuly"
                            size="small"
                          />
                        </div>
                      </>
                    )}
                    <div className="flex justify-center mt-1">
                      <button
                        type="button"
                        onClick={toggleExpand}
                        className="cursor-pointer flex justify-between text-blue-500 px-1 py-1 rounded-md  tracking-wider    duration-500  w-[100px]"
                      >
                        {isExpanded ? (
                          <>
                            Thu gọn
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 animate-bounce"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 15.75 7.5-7.5 7.5 7.5"
                              />
                            </svg>
                          </>
                        ) : (
                          <>
                            Mở rộng
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 animate-bounce"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </>
                        )}
                      </button>
                    </div>
                  </>
                ),
              },
              {
                label: (
                  <>
                    <p
                      className={`w-[3.7rem] text-start  ${!formik.errors.para ? '' : '  text-red-500 font-semibold'}`}
                    >
                      Biểu mẫu
                      <span className={`text-red-500  ${!formik.errors.para ? ' hidden ' : ''}`}>
                        (*)
                      </span>
                    </p>
                  </>
                ),
                key: 2,
                children: (
                  <>
                    <div className="flex items-center w-full justify-end  mt-2 mb-2">
                      <label className="font-semibold pr-1">Biểu mẫu:</label>
                      <Select
                        size="small"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={onClickBieuMau}
                        value={bieuMauChon}
                        className="w-72"
                        options={listBieuMau?.map((item) => ({
                          label: item.tenbieumau,
                          value: item.idbm,
                        }))}
                      />
                      {/* {infoUser?.tenNhom === 'Quản trị hệ thống' && (
                        <PlusCircleOutlined
                          onClick={() => setOpen(true)}
                          className="cursor-pointer text-blue-500"
                          style={{ fontSize: 20 }}
                        />
                      )} */}
                    </div>
                    <div className="border min-h-[22.5rem] overflow-hidden">
                      <Editor
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'fontFamily',
                            'textAlign',
                            'colorPicker',
                          ],
                          inline: {
                            options: ['bold', 'italic'],
                          },
                          blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          },
                          fontFamily: {
                            options: ['Times New Roman'],
                          },
                        }}
                        editorClassName={style.editorClassNameKSK}
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
          {!checkBox && coKeToa === 1 && (
            <div className="mb-2">
              <Divider plain style={{ margin: 5 }}>
                <p className="font-semibold">Kê toa thuốc</p>
              </Divider>
              <ToaThuoc />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
export default FormCD
