import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listGoiKhamKH: null,
  infoGoiKhamKh: null,
}

const goikhamkhachhangCRMReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM:
        draft.listGoiKhamKH = payload
        break
      case typeAction.DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM:
        draft.infoGoiKhamKh = payload
        break
      default:
        return state
    }
  })
}

export default goikhamkhachhangCRMReducer
