import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, DatePicker, Divider, Input, Modal, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { editDonDatHang } from '../../../../store/actions/vanPhongPhamAction'
import { DISPATCH_CLEAR_INFO_DONHANG } from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
const dateFormat = 'DD/MM/YYYY'
const dateFormatAPI = 'YYYY-MM-DD'

function ModalEdit({ openEdit, setOpenEdit, onLoad }) {
  const { inforDonHang } = useSelector((state) => state.vanPhongPhamReducer)

  const [dataTable, setDataTable] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [loadingBtn, setLoadingBtn] = useState(false)

  const dispatch = useDispatch()
  const selectRef = useRef(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      iddathang: inforDonHang?.IDDATHANG,
      chinhanhdat: inforDonHang?.CHINHANHDAT,
      chinhanhnhan: inforDonHang?.CHINHANHNHAN,
      tendonhang: inforDonHang?.TENDONHANG,
      ghichudathang: inforDonHang?.GHICHU,
      nguoidat: inforDonHang?.NGUOIDAT,
      ngaydat: inforDonHang?.NGAYDAT,
      trangThaiDatHang: inforDonHang?.TRANGTHAIDATHANG,

    },
    onSubmit: (value) => handleSubmit(value),
  })

  const handleSubmit = (value) => {
    const data = {
      iddathang: inforDonHang?.IDDATHANG,
      trangThaiDatHang: inforDonHang?.TRANGTHAIDATHANG,
      nguoidat: inforDonHang?.IDNGUOIDAT,
      ngaydat: inforDonHang?.NGAYDAT,
      ghichudathang: inforDonHang?.GHICHU,
      tendonhang: inforDonHang?.TENDONHANG,
      idchinhanhdat: inforDonHang?.IDCHINHANHDAT,
      idchinhanhnhan: inforDonHang?.IDCHINHANHNHAN,
      thoigiandathang: inforDonHang?.THOIGIANDATHANG,
      nguoisua: infoUser?.idnv,
      chitietdathangs: [
        ...dataTable
          .filter((val) => val.soluongdat > 0)
          .map((item) => ({
            ...item,
            iddathang: value.iddathang,
            idhang: item.idthuoc,
            soluongduyet: item.soluongduyet,
          })),
      ],
    }
    //edit so luong duyet
    dispatch(editDonDatHang(data, onLoad, setLoadingBtn, handleCancel, true))
  }

  const handleDeleteInTable = (record) => {
    setDataTable((prev) => prev.filter((item) => item.idHang !== record.idHang))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size="small"
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleChangeInput = (e, record, key) => {
    const findItemIdx = dataTable.findIndex((item) => item.idthuoc === record.idthuoc)
    if (findItemIdx !== -1) {
      const cloneListHang = cloneDeep(dataTable)
      if (e.target.value < 0) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng nhập số hợp lệ',
        })
        cloneListHang[findItemIdx][key] = 0
        setDataTable(cloneListHang)
        return
      }

      if (e.target.value > cloneListHang[findItemIdx]['soluongdat']) {
        ToastCus.fire({
          icon: 'error',
          title: 'Số lượng duyệt phải nhỏ hơn hoặc bằng số lượng đặt',
        })
        return
      }
      cloneListHang[findItemIdx][key] = e.target.value
      setDataTable(cloneListHang)
    }
  }

  const columnsHangHoa = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã hàng hóa',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      ...getColumnSearchProps('mathuoc'),
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      ...getColumnSearchProps('tenbietduoc'),
    },
    {
      title: 'SL đặt',
      dataIndex: 'soluongdat',
      key: 'soluongdat',
      align: 'center',
      width: 100,
    },
    {
      title: 'SL duyệt',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Input
          size="small"
          min={0}
          step={1}
          type="number"
          key={record.idthuoc}
          value={record.soluongduyet}
          className="w-full text-center"
          onChange={(e) => handleChangeInput(e, record, 'soluongduyet')}
        />
      ),
    },
    {
      title: 'SL tồn',
      dataIndex: 'tonkho',
      key: 'tonkho',
      align: 'center',
      width: 100,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 200,
    },
  ]

  useEffect(() => {
    if (inforDonHang) {
      setDataTable(
        inforDonHang.DanhSachHangHoa.map((item) => ({
          ...item,
          idthuoc: item.idhang,
          soluongduyet: item.soluongduyet ?? 0,
        })),
      )
    }
  }, [inforDonHang?.IDDATHANG])

  const handleCancel = () => {
    formik.resetForm()
    setOpenEdit(false)
    dispatch({ type: DISPATCH_CLEAR_INFO_DONHANG })
  }

  return (
    <Modal
      okText="Xác nhận"
      cancelText="Huỷ"
      className="text-center"
      title={'Chi tiết đặt hàng'}
      onOk={formik.handleSubmit}
      open={openEdit}
      onCancel={handleCancel}
      width={1200}
      style={{ top: 20 }}
      confirmLoading={loadingBtn}
    >
      <form className="text-start flex flex-col gap-2">
        <div className="flex gap-4">
          <div className="w-1/2 flex gap-4">
            <div className="w-2/3">
              <label className="font-semibold  text-base">Người đặt</label>
              <Input
                size="small"
                value={formik.values.nguoidat}
                className="w-full cursor-not-allowed bg-gray-100"
                name="nguoidat"
                readOnly
              />
            </div>
            <div className="w-1/3">
              <label className="font-semibold  text-base">Thời gian</label>
              <DatePicker
                size="small"
                name="ngaydat"
                format={dateFormat}
                value={dayjs(formik.values.ngaydat)}
                className="w-full"
                disabled
              />
            </div>
          </div>
          <div className="w-1/2 flex gap-4">
            <div className="w-1/2">
              <label className="font-semibold  text-base">Chi nhánh đặt</label>
              <Input
                size="small"
                value={formik.values.chinhanhdat}
                className="w-full cursor-not-allowed bg-gray-100"
                name="chinhanhdat"
                readOnly
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">Bộ phận duyệt</label>
              <Input
                size="small"
                value={formik.values.chinhanhnhan}
                name="chinhanhnhan"
                className="w-full cursor-not-allowed bg-gray-100"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <label className="font-semibold  text-base">Tên đơn hàng</label>
            <Input
              size="small"
              value={formik.values.tendonhang}
              name="tendonhang"
              className="w-full cursor-not-allowed bg-gray-100"
              readOnly
            />
          </div>
          <div className="w-1/2 flex flex-col">
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              size="small"
              value={formik.values.ghichudathang}
              name="ghichudathang"
              className="w-full cursor-not-allowed bg-gray-100"
              readOnly
            />
          </div>
        </div>
      </form>
      <div className="mt-2 text-left">
        <p>
          Số lượng lượt khám bệnh (tháng trước): <span className="font-semibold">{inforDonHang?.LUOTKHAMCN}</span>
        </p>
        <p>
          Số lượng nhân viên (tháng trước): <span className="font-semibold">{inforDonHang?.TONGNHANVIEN}</span>
        </p>
        <p>
          {/* Số lượng VPP sử dung (tháng trước): <span className="font-semibold">200</span> */}
        </p>
      </div>
      <Divider />
      <div className="overflow-auto h-[500px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            scroll={{ y: 400 }}
            bordered
            pagination={false}
            columns={columnsHangHoa}
            dataSource={
              dataTable.map((item) => ({
                ...item,
                action: (
                  <ul className="flex gap-2 justify-around">
                    <li className="text-lg text-red-500">
                      <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                    </li>
                  </ul>
                ),
              })) || []
            }
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

ModalEdit.propTypes = {}

export default ModalEdit
