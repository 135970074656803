export const buildFilterCondition = (condition, value, comparator) => {
  if (!value) {
    return true
  }
  switch (condition) {
    case '>':
      return comparator > Number(value)
    case '<':
      return comparator < Number(value)
    case '=':
      return comparator === Number(value)
    default:
      return true // Mặc định không áp dụng điều kiện
  }
}