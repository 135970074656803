import * as typeAction from '../constants/constants'
export const postVersion = (form,onReset) => ({
    type:typeAction.ADD_NEW_VERSION,
    form,
    onReset
})
export const DeleteVersion = (id,onLoad) => ({
    type:typeAction.DELETE_VERSION_BY_ID,
    id,
    onLoad
})
export const updateVersion = (form,reload) => ({
    type:typeAction.UPDATE_VERSION,
    form,
    reload
})