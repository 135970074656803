import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../store/constants/constants'
import logo from '../../../assets/images/logo/logo_nhiDong315.png'
import { versionService } from '../../../services/version/versionService'
import moment from 'moment'

import 'moment/locale/vi'
moment.locale('vi')
const Version = () => {
  const { version } = useSelector((state) => state.modalReducer)
  const [versions, setVersion] = useState(null)
  const dispatch = useDispatch()
  const cancelShows = () => {
    dispatch({
      type: typeAction.CLOSE_MODAL_VERSION,
    })
  }
  const fetchNewVersion = async () => {
    const { data } = await versionService.getNewVersion()
    setVersion(data)
  }
  useEffect(() => {
    fetchNewVersion()
  }, [])
  return (
    <Modal footer={null} onCancel={cancelShows} open={version}>
      <div className="flex flex-col justify-center items-center">
        {/* <img className='w-32 h-32' src={logo} alt="#" /> */}
        <h2 className="text-[#00AFEF] font-semibold text-xl">315 MEDICAL</h2>
        <h2> Phiên bản: {versions?.phienban}</h2>
        <h2> Ngày update: {moment(versions?.ngaynangcap).format('DD/MM/YYYY HH:mm:ss')}</h2>
      </div>
    </Modal>
  )
}

export default Version
