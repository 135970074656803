import { Button } from '@mui/material'
import { DatePicker, Input, Select, Tabs, notification } from 'antd'
import {
  SnippetsOutlined,
  InboxOutlined,
  GoldOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import 'jspdf-autotable'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KhoNhapSchema } from '../../../schemas/KhoNhapSchema'
import {
  addPhieuNhapKho,
  branchNhapKhoByCompany,
  fetchInfoThuocVT,
  getBranchNhapKho,
  getHangHoaByMaHang,
  getHangVPPKhoTong,
  getInfoDoitac,
  getlistDoitac,
  searchThuocVT,
} from '../../../store/actions/NhapKhoAction'
import * as typeAction from '../../../store/constants/constants'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import Attach from './Attach/Attach'
import ListKho from './ListKho/ListKho'
import TableChiTiet from './TableChiTiet/TableChiTiet'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import ChiTietHangHoa from './ChiTietHangHoa/ChiTietHangHoa'
import NhapKhoVPP from './VPP/NhapKhoVPP'
import HangVPP from './VPP/HangVPP'

import 'moment/locale/vi'
moment.locale('vi')

const Nhapkho = () => {
  const [totalPrice, setTotalPrice] = useState(0)
  // ------dùng để focus vào items mới -------------//
  const [selectedItem, setSelectedItem] = useState(null)
  const [branch, setBranch] = useState(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const now = moment()
  const [date, setDate] = useState(now.format('YYYY-MM-DDTHH:mm:ss')) // set time lại thời gian thực
  const dispatch = useDispatch()
  const [maHang, setMaHang] = useState(null)
  const [selectedFile, setSelectedFile] = useState([])
  const { listKhoNhap, listDoiTac, infoDoiTac, thuocVT, infoThuocVT, branchNhapByCT } = useSelector(
    (state) => state.NhapKhoReducer,
  )
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [api, contextHolder] = notification.useNotification()
  const [company, setCompany] = useState(id_company)
  // lấy thông tin người dùng >> tạm thời
  const { infoUser } = useSelector((state) => state.userReducer)
  // xoá hình ảnh
  const initialValues = useMemo(
    () => ({
      TenPhieu: '',
      IdKhoNhap: '',
      GhiChu: '',
      NgayNhan: '',
      TrangThai: 5,
      IdDoiTac: '',
      SoHoaDon: '',
      NgayHoaDon: '',
      LinkHoaDon: '',
      HoaDonFiles: null,
      IDCT: id_company,
      IdHinhThuc: 2,
      IdPhuongThuc: 2,
      clickedButton: '',
    }),
    [now, id_company],
  )
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: KhoNhapSchema,
    onSubmit: (value, action) => handleSubmit(value, action),
  })
  const closeImg = () => {
    setSelectedFile([])
    formik.setFieldValue('HoaDonFile', null)
  }
  const selectRef = useRef(null)
  // xử lí button submit
  const handleSubmit = (values, action) => {
    const today = moment()
    if (infoThuocVT.length === 0) {
      // check hàng có trong thuốc vó trong kho hay chưa
      openNotificationWithIcon(
        'warning',
        'Lưu phiếu nhập kho',
        'Vui lòng chọn sản phẩm để lưu phiếu!',
      )
      return
    }
    // CHECK SỐ LÔ VÀ VÀ HẠN DÙNG CÁC HÀNG HOÁ
    for (let items of infoThuocVT) {
      if (items.khoChiTiet.soLo === '' || !items.khoChiTiet.hanDung) {
        openNotificationWithIcon(
          'warning',
          'Lưu phiếu nhập kho',
          `Vui lòng nhập số lô hoặc hạn dùng cho SP: ${items.TENBIETDUOC} !`,
        )
        return
      } else if (items.khoChiTiet.soLuong === 0 && items.khoChiTiet.countLe === 0) {
        openNotificationWithIcon(
          'warning',
          'Lưu phiếu nhập kho',
          `Số lượng không thể bằng 0, SP: ${items.TENBIETDUOC} `,
        )
        return
      }
    }
    if (selectedFile.length !== 0) {
      const data = selectedFile.map((item) => item.file)
      values.HoaDonFiles = data
    }
    const formData = new FormData()
    values.NgayNhan = today.format('YYYY-MM-DDTHH:mm:ss') // LẤY XÁC NGÀY NHẬP HÀNG
    values.NhanVienNhan = infoUser?.idnv
    for (let key in values) {
      // console.log(key)
      if (key === 'HoaDonFiles') {
        if (values[key] && values[key].length > 0) {
          values[key].forEach((file, index) => {
            formData.append(key, file)
          })
        }
      } else {
        formData.append(key, values[key])
      }
    }
    // Log the contents of formData
    // for (let pair of formData.entries()) {
    //   console.log(pair)
    // }
    const xuatPhieuNhapKho = values.clickedButton === 'save' ? false : true
    dispatch(addPhieuNhapKho(formData, infoThuocVT, xuatPhieuNhapKho))
    action.resetForm()
    dispatch({
      type: typeAction.DISPATCH_RESET_INFO_DOITAC,
    })
    dispatch({
      type: typeAction.RESET_INFO_THUOVT,
    })
    closeImg()
    // formik.setFieldValue('NgayNhan', now.format('YYYY-MM-DDTHH:mm:ss')) // set lại thời gian nhận
  }
  const onChangeSoHD = (e) => {
    const { value } = e.target
    const tenVietTat = infoDoiTac?.tenVietTat || ''
    formik.setFieldValue('SoHoaDon', value)
    const NeValue = `${tenVietTat ? tenVietTat + '-' : ''}` + value
    formik.setFieldValue('TenPhieu', NeValue)
  }
  // modal hiện thong báo
  const openNotificationWithIcon = (type, message, desc) => {
    api[type]({
      message,
      description: desc,
    })
  }
  // search lấy thông tin thuốc vật tư
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearch = (keyword) => {
    const value = keyword || ''
    debounceDropDown(keyword, company)
  }
  const onchangeDateHoaDon = useCallback(
    (date, dateString) => {
      const dateHoaDon = dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
      formik.setFieldValue('NgayHoaDon', dateHoaDon)
    },
    [formik],
  )
  // xử lý thông tin chọn
  const handleChangeSelect = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value)
    },
    [formik],
  )
  const checkStoreThuocVT = (value) => {
    for (const obj of infoThuocVT) {
      if (obj.IDTHUOC === value) {
        return false // trả về false nếu trùng
      }
    }
    return true
  }
  // xử lý phong các HÌnh thức và phương thức
  const handleSelect = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  // xử lí chọn kho chi tiết
  const handleChoose = async (value) => {
    dispatch(fetchInfoThuocVT(value, setSelectedItem))
    // console.log(selectedItem)
    // const validate = await checkStoreThuocVT(value)
    // validate
    //   ? dispatch(fetchInfoThuocVT(value))
    //   : openNotificationWithIcon('error', 'Chọn thuốc vật tư', 'Sản phẩm đã có ')
  }
  const handleChooseMemoized = useCallback(handleChoose, [handleChoose])
  const handleChangeDoiTac = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value)
      dispatch(getInfoDoitac(value))
    },
    [formik],
  )
  // lấy chi nhánh theo công ty
  const handleCompany = useCallback(
    (value) => {
      dispatch(branchNhapKhoByCompany(value))
      setCompany(value)
      formik.setFieldValue('IDCT', value)
      setBranch(null)
      dispatch({
        type: typeAction.DISPATCH_LIST_KHONHAP,
        payload: null,
      })
    },
    [formik],
  )
  // lấy kho nhập
  const hanldeKhoNhapByBranch = (idChiNhanh) => {
    setBranch(idChiNhanh)
    dispatch(getBranchNhapKho(idChiNhanh))
  }

  // // xử lý on change

  const handleDatePickerNgayNhap = useCallback(
    (date, DateString) => {
      // Lấy giờ hiện tại
      const currentTime = moment().format('HH:mm:ss')
      // Ghép ngày đã chọn với giờ hiện tại
      const combinedDateTime = date.format('DD/MM/YYYY') + ' ' + currentTime
      formik.setFieldValue(
        'NgayNhan',
        moment(combinedDateTime, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'),
      )
    },
    [formik],
  )
  useEffect(() => {
    dispatch(getlistDoitac())
    dispatch(branchNhapKhoByCompany(id_company))
    dispatch(listAllCompanyAction())
  }, [])
  const setPricre = (sum) => {
    setTotalPrice(sum)
  }
  // tính tổng tiền
  useEffect(() => {
    if (infoThuocVT.length !== 0) {
      let sum = 0
      for (let item of infoThuocVT) {
        sum += item.khoChiTiet.thanhTien
      }
      setPricre(sum)
    } else {
      setTotalPrice(0)
    }
  }, [infoThuocVT])
  /// reste form
  const onclikReset = () => {
    closeImg()
    dispatch({
      type: typeAction.RESET_INFO_THUOVT,
    })
    formik.handleReset()
  }
  // lưu
  const handleSave = () => {
    formik.setFieldValue('clickedButton', 'save')
    formik.handleSubmit()
  }
  // luuw và in
  const handleSaveAndPrinter = () => {
    formik.setFieldValue('clickedButton', 'save & print')
    formik.handleSubmit()
  }
  // ------------- lấy mã hàng --------//
  const onClickCopyHang = () => {
    // Lấy 3 chữ cái đầu của maHang
    const prefix = maHang.substring(0, 3)
    if (prefix !== 'VPP') {
      dispatch(getHangHoaByMaHang(maHang, setMaHang, setSelectedItem, formik))
      return
    }
    dispatch(getHangVPPKhoTong(maHang, setMaHang, formik.values.IdDoiTac, setSelectedItem, formik))
  }
  // select key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'k') {
        event.preventDefault()
        if (selectRef.current) {
          selectRef.current.focus()
        }
      } else if (event.key === 'F5') {
        event.preventDefault()
        onclikReset()
      } else if (event.key === 'F9') {
        event.preventDefault()
        handleSave()
      } else if (event.key === 'F4') {
        event.preventDefault()
        handleSaveAndPrinter()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  useEffect(() => {
    if (infoDoiTac?.tenVietTat) {
      const { SoHoaDon, TenPhieu } = formik.values
      const { tenVietTat } = infoDoiTac
      const value = tenVietTat + `${tenVietTat ? '-' : ''}` + SoHoaDon
      formik.setFieldValue('TenPhieu', value)
    } else {
      const { SoHoaDon, TenPhieu } = formik.values
      formik.setFieldValue('TenPhieu', TenPhieu)
    }
  }, [infoDoiTac])
  const isValidMaHang =
    (maHang && maHang?.startsWith('PN') && maHang?.length === 14) || maHang?.startsWith('VPP')
  return (
    <>
      {contextHolder}
      <canvas id="barcode" className="hidden"></canvas>
      <div className="min-[1700px]:p-3 p-2 bg-[#E7E7E7] h-full">
        <div className="bg-white h-full rounded-xl border">
          <Tabs
            type="card"
            className="p-2"
            items={[
              {
                key: 1,
                label: (
                  <span className="font-semibold flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
                      />
                    </svg>
                    Nhập kho
                  </span>
                ),
                children: (
                  <>
                    <form>
                      <div>
                        <div className="flex gap-2">
                          <div className="flex flex-col w-2/4 gap-2">
                            <div className="flex  min-[1470px]:flex gap-2">
                              <div className=" flex w-1/2">
                                <label className="w-[40%] min-[1700px]:w-[30%]  font-semibold text-end mr-1">
                                  Người nhập:
                                </label>
                                <Input
                                  readOnly
                                  variant="filled"
                                  value={infoUser?.tenNV}
                                  // name="nhanVienNhan"
                                  size="small"
                                />
                              </div>
                              <div className="flex w-1/2">
                                <label className="min-[1700px]:w-1/4 w-[40%] font-semibold ">
                                  Nơi nhập:
                                </label>
                                <Select
                                  tabIndex={1}
                                  value={branch}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  onChange={hanldeKhoNhapByBranch}
                                  options={branchNhapByCT?.map((items) => ({
                                    label: items.tenChiNhanh,
                                    value: items.idChiNhanh,
                                  }))}
                                  className="w-full"
                                  size="small"
                                />
                              </div>
                            </div>
                            <div className="flex ">
                              <label className="min-[1700px]:w-[13%] w-[16%] font-semibold text-end mr-1">
                                Công ty:
                              </label>
                              <Select
                                defaultValue={id_company}
                                showSearch
                                value={formik.values.IDCT}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '')
                                    .toLowerCase()
                                    .localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                onChange={handleCompany}
                                options={listCompany?.map(({ idct, tenct }) => ({
                                  label: tenct,
                                  value: idct,
                                }))}
                                className="w-full"
                                size="small"
                              />
                            </div>
                            <div className="flex ">
                              <label className="min-[1700px]:w-[13%] w-[16%] font-semibold text-end mr-1">
                                <span className="text-red-500 text-xs">(*)</span>
                                Đối tác:
                              </label>
                              <div className="flex gap-2 w-full">
                                <Input
                                  value={infoDoiTac?.tenVietTat}
                                  size="small"
                                  placeholder="Tên viết tắt"
                                  readOnly
                                  className="w-36"
                                />
                                <Select
                                  tabIndex={2}
                                  name="IdDoiTac"
                                  status={formik.errors.IdDoiTac ? 'error' : ''}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '')
                                      .toLowerCase()
                                      .localeCompare((optionB?.label ?? '').toLowerCase())
                                  }
                                  value={formik.values.IdDoiTac}
                                  onChange={(value) => handleChangeDoiTac('IdDoiTac', value)}
                                  options={listDoiTac?.map(({ idDoiTac, tenDoiTac }) => ({
                                    label: tenDoiTac,
                                    value: idDoiTac,
                                  }))}
                                  className="w-full"
                                  size="small"
                                />
                              </div>
                            </div>
                            <div className="flex ">
                              <label className="min-[1700px]:w-[13%] w-[16%] font-semibold text-end mr-1">
                                Địa chỉ:
                              </label>
                              <Input
                                variant="filled"
                                readOnly
                                value={infoDoiTac?.diaChi}
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-1/4 gap-2">
                            <div className="flex gap-1">
                              <label className="min-[1700px]:w-1/3 w-[40%] text-end font-semibold">
                                <span className="text-red-500 text-xs">(*)</span>
                                Kho nhập:
                              </label>
                              <Select
                                tabIndex={3}
                                name="IdKhoNhap"
                                status={formik.errors.IdKhoNhap ? 'error' : ''}
                                onChange={(value) => handleChangeSelect('IdKhoNhap', value)}
                                className="w-full"
                                size="small"
                                value={formik.values.IdKhoNhap}
                                options={listKhoNhap?.map(({ idKhoCN, tenKho }) => ({
                                  label: tenKho,
                                  value: idKhoCN,
                                }))}
                              />
                            </div>
                            <div className="flex gap-1">
                              <label className="min-[1700px]:w-1/3 w-[40%] text-end font-semibold">
                                Mã đối tác:
                              </label>
                              <Input
                                readOnly
                                variant="filled"
                                value={infoDoiTac?.maDoiTac}
                                className="w-full"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1">
                              <label className="min-[1700px]:w-1/3 w-[40%] font-semibold text-end">
                                SĐT:
                              </label>
                              <Input
                                variant="filled"
                                readOnly
                                value={infoDoiTac?.dienThoai}
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1">
                              <label className="min-[1700px]:w-1/3 w-[40%]  font-semibold text-center">
                                Phương thức:
                              </label>
                              <Select
                                status={formik.errors.IdPhuongThuc ? 'error' : ''}
                                name="idPhuongThuc"
                                onChange={handleSelect('IdPhuongThuc')}
                                value={formik.values.IdPhuongThuc}
                                options={[
                                  {
                                    label: 'Thanh toán',
                                    value: 1,
                                  },
                                  {
                                    label: 'Công nợ',
                                    value: 2,
                                  },
                                ]}
                                className="w-full"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-1/4 gap-2">
                            <div className="flex gap-2">
                              <label className="w-1/3 text-end font-semibold">Ngày nhập:</label>
                              {/* <DatePicker
                                allowClear={false}
                                size="small"
                                className="w-full"
                                value={dayjs(formik.values.NgayNhan)}
                                format={'DD/MM/YYYY HH:mm:ss'}
                                onChange={handleDatePickerNgayNhap}
                                disabledDate={(current) => current && current > now}
                              /> */}
                              <Input
                                readOnly
                                variant="filled"
                                value={moment().format('DD-MM-YYYY HH:mm:ss')}
                                size="small"
                              />
                            </div>
                            <div className="flex gap-2">
                              <label className="w-1/3 text-end font-semibold">Số thuế:</label>
                              <Input
                                variant="filled"
                                readOnly
                                value={infoDoiTac?.maSoThue}
                                size="small"
                              />
                            </div>
                            <div className="flex gap-2">
                              <label className="w-1/3 text-end font-semibold">Email:</label>
                              <Input
                                variant="filled"
                                readOnly
                                value={infoDoiTac?.email}
                                size="small"
                              />
                            </div>
                            <div className="flex gap-2">
                              <label className="w-1/3 text-end font-semibold">Hình thức:</label>
                              <Select
                                name="IdHinhThuc"
                                status={formik.errors.IdHinhThuc ? 'error' : ''}
                                onChange={handleSelect('IdHinhThuc')}
                                value={formik.values.IdHinhThuc}
                                options={[
                                  {
                                    label: 'Tiền mặt',
                                    value: 1,
                                  },
                                  {
                                    label: 'Chuyển khoản',
                                    value: 2,
                                  },
                                ]}
                                className="w-full"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-3 mt-2">
                          <div className="flex gap-3 flex-col w-1/2 ">
                            <div className="flex">
                              <label className="min-[1700px]:w-[13%] w-[16%]  font-semibold text-end mr-1">
                                Tên phiếu:
                              </label>
                              <Input
                                value={formik.values.TenPhieu}
                                onChange={formik.handleChange}
                                name="TenPhieu"
                                size="small"
                                className="w-full"
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 w-1/2">
                            <div className="flex w-1/2">
                              <label className="min-[1700px]:w-[31%] w-[40%] font-semibold text-end mr-1">
                                <span className="text-red-500 text-sx">(*)</span>
                                Số HĐ:
                              </label>
                              <Input
                                tabIndex={4}
                                status={formik.errors.SoHoaDon ? 'error' : ''}
                                name="SoHoaDon"
                                onChange={onChangeSoHD}
                                value={formik.values.SoHoaDon}
                                size="small"
                              />
                            </div>
                            <div className="flex w-1/2">
                              <label className="font-semibold w-[33%] mr-1 text-end">
                                {/* <span className="text-red-500 text-xs">
                                  (*)
                                </span> */}
                                Ngày HĐ :
                              </label>
                              <DatePicker
                                // tabIndex={6}
                                placeholder=" "
                                name="NgayHoaDon"
                                value={
                                  formik.values.NgayHoaDon !== '' &&
                                  formik.values.NgayHoaDon !== null
                                    ? dayjs(formik.values.NgayHoaDon, 'YYYY-MM-DD')
                                    : ''
                                }
                                format="DD/MM/YYYY"
                                onChange={onchangeDateHoaDon}
                                className="w-full"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-2 mt-2 items-center">
                          <div className="flex items-center w-full">
                            <label className=" min-[1700px]:w-[6.1%] w-[7.1%] mr-1 text-end font-semibold ">
                              Ghi chú:
                            </label>
                            <Input
                              size="small"
                              name="GhiChu"
                              value={formik.values.GhiChu}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <Tabs
                          items={[
                            {
                              key: 1,
                              label: (
                                <div className="flex items-center gap-2">
                                  <GoldOutlined style={{ fontSize: 18 }} /> Hàng hóa
                                </div>
                              ),
                              children: (
                                <>
                                  <div className="flex items-center mb-2 gap-2">
                                    <Select
                                      ref={selectRef}
                                      className="w-full"
                                      // size="small"
                                      showSearch
                                      allowClear
                                      onChange={handleChooseMemoized}
                                      placeholder="Nhập tên vật tư hàng hoá ( Ctrl + K ) "
                                      value={null}
                                      defaultActiveFirstOption={false}
                                      prefix={null}
                                      filterOption={false}
                                      onSearch={handleSearch}
                                      notFoundContent={null}
                                      options={(thuocVT || []).map((d, index) => ({
                                        key: index,
                                        value: d.idThuoc,
                                        label: (
                                          <u className="flex no-underline">
                                            <li className="flex w-[50%]">
                                              <p className="pr-2">{d.maThuoc}</p>
                                              <p className="border-x-2 px-2 w-full ">
                                                {d.tenBietDuoc}
                                              </p>
                                            </li>
                                            <li className=" w-[35%] px-1 border-r text-start">
                                              {d.tenDoiTac}
                                            </li>
                                            <li className=" w-[15%] text-end">
                                              {formatNumberVND(d.giaMua * d.quyCachDongGoi)} VNĐ
                                            </li>
                                          </u>
                                        ),
                                      }))}
                                    />
                                    <div className="flex gap-2">
                                      <Input
                                        placeholder="VD: PN0A . . ."
                                        value={maHang}
                                        onChange={(e) => setMaHang(e.target.value)}
                                      />
                                      <Button
                                        onClick={onClickCopyHang}
                                        disabled={!isValidMaHang}
                                        className="w-40"
                                        size="small"
                                        variant="contained"
                                      >
                                        Lấy hàng
                                      </Button>
                                    </div>
                                  </div>
                                  <TableChiTiet
                                    selectedItem={selectedItem}
                                    infoThuocVT={infoThuocVT}
                                  />
                                </>
                              ),
                            },
                            {
                              key: 2,
                              label: (
                                <div className="flex items-center  gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                                    />
                                  </svg>
                                  Đính kèm
                                </div>
                              ),

                              children: (
                                <Attach
                                  setSelectedFile={setSelectedFile}
                                  selectedFile={selectedFile}
                                  formik={formik}
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                      <div className="mt-5 flex gap-5 justify-end">
                        <Button onClick={onclikReset} variant="outlined" color="info" size="small">
                          Làm mới ( F5 )
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="button"
                          onClick={handleSave}
                        >
                          Lưu ( F9 )
                        </Button>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          type="button"
                          onClick={handleSaveAndPrinter}
                        >
                          Lưu & in ( F4 )
                        </Button>
                      </div>
                    </form>
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <span className="font-semibold flex gap-2 items-center">
                    <SnippetsOutlined style={{ fontSize: 20 }} /> Phiếu nhập
                  </span>
                ),
                children: <ListKho />,
              },
              {
                key: 3,
                label: (
                  <span className="font-semibold flex gap-2 items-center">
                    <InboxOutlined style={{ fontSize: 20 }} />
                    Chi tiết hàng
                  </span>
                ),
                children: <ChiTietHangHoa />,
              },
              {
                key: 4,
                label: (
                  <span className="font-semibold flex gap-2 items-center">
                    <AppstoreAddOutlined style={{ fontSize: 20 }} />
                    Nhập kho VPP
                  </span>
                ),
                children: infoUser?.idNhom === 28 ? <NhapKhoVPP /> : <>Đang phát triển...</>,
              },
              {
                key: 5,
                label: (
                  <span className="font-semibold flex gap-2 items-center">
                    <AppstoreOutlined style={{ fontSize: 20 }} />
                    Hàng VPP đã nhập
                  </span>
                ),
                children: infoUser?.idNhom === 28 ? <HangVPP /> : <>Đang phát triển...</>,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default Nhapkho
