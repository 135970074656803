import React, { useState, useEffect } from 'react'
import { Modal, Divider, Button, InputNumber } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { logoutUser, openShiffCaLamViec } from '../../../store/actions/userAction'
import { useNavigate } from 'react-router-dom'
import { getThongKeDongMoCa } from '../../../store/actions/BranchAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import 'moment/locale/vi'
moment.locale('vi')
const formatDate = 'YYYY-MM-DDTHH:mm:ss'

const ModalCheckIn = () => {
  const now = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const BRANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const { PkDangNhap, ThongKeDoanhThuChiNhanh } = useSelector((state) => state.branchReducer)
  const { modalCheckIn } = useSelector((state) => state.modalReducer)
  const [ip, setIP] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // research ip
  const searchIP = async () => {
    try {
      const result = await axios.get('https://api.ipify.org?format=json')
      setIP(result.data.ip)
    } catch (error) {
      console.log(error)
    }
  }
  const startCaA = moment().set({ hour: 6, minute: 0 })
  const endCaA = moment().set({ hour: 12, minute: 0 })
  const startCaB = moment().set({ hour: 12, minute: 0 })
  const endCaB = moment().set({ hour: 17, minute: 0 })

  useEffect(() => {
    dispatch(getThongKeDongMoCa())
    searchIP()
  }, [])
  return (
    <Modal
      className="text-center"
      title={<h2 className="font-semibold">MỞ CA LÀM VIỆC</h2>}
      // centered
      footer={null}
      open={modalCheckIn}
      width={700}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          idDangNhap: infoUser?.dangNhap.idDangNhap,
          thoiGianDangNhap: now.format(formatDate),
          ip: ip,
          // idCaLamViec: 1,
          idChiNhanh: BRANH_LOGIN,
        }}
        onSubmit={(value, actions) => {
          dispatch(openShiffCaLamViec(value))
        }}
      >
        {(props) => (
          <form className="text-start" onSubmit={props.handleSubmit}>
            <div>
              <div className="text-base flex gap-5 ">
                <div className="w-1/2">
                  <h2 className="font-medium text-gray-500">Chi nhánh</h2>
                  <span> {PkDangNhap?.tenChiNhanh}</span>
                </div>
                <div>
                  <h2 className="font-medium text-gray-500">Nhân viên</h2>
                  <span>
                    {' '}
                    {infoUser?.tenNV} - {infoUser?.maNV}
                  </span>
                </div>
              </div>
              <div className="text-base flex gap-10 mt-3">
                <div className="w-1/2">
                  <h2 className="font-medium text-gray-500">Thời gian làm việc</h2>
                  <span>{now.format('DD/MM/YYYY HH:mm:ss')}</span>
                </div>
                <div className="w-1/2">
                  <h2 className="font-medium text-gray-500">Ca làm việc</h2>
                  <span>
                    {now.isBetween(startCaA, endCaA)
                      ? 'Ca A'
                      : now.isBetween(startCaB, endCaB)
                        ? 'Ca B'
                        : 'Ca c'}
                  </span>
                </div>
              </div>
              <Divider className="my-3" />
              <div className="flex gap-3 flex-col">
                {infoUser?.idNhom !== 4 ? (
                  <div>
                    <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                      <span>
                        Tiền dịch vụ: {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienDichVu)}đ
                      </span>
                    </p>
                    <ul className="ml-5 flex gap-20">
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca A{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuDichVu}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca B{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuDichVu}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca C{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuDichVu}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                    </ul>
                  </div>
                ) : (
                  ''
                )}
                {infoUser?.idNhom !== 4 ? (
                  <div>
                    <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                      {' '}
                      <span>
                        Tiền thẻ thành viên: {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienThe)}
                        đ
                      </span>
                    </p>
                    <ul className="ml-5 flex gap-20">
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca A{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuThe}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca B{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuThe}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca C{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuThe}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                    </ul>
                  </div>
                ) : (
                  ''
                )}
                {infoUser?.idNhom !== 4 ? (
                  <div className="text-base font-semibold flex justify-end gap-2">
                    Tổng lễ tân:{' '}
                    <span className="text-green-700 w-24 text-end">
                      {formattedNumber(
                        ThongKeDoanhThuChiNhanh?.tongTienDichVu +
                          ThongKeDoanhThuChiNhanh?.tongTienThe,
                      )}{' '}
                      đ
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {infoUser?.idNhom !== 4 ? <Divider className="my-1" /> : ''}
                <div>
                  <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                    {' '}
                    <span>
                      Tiền bán lẻ: {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienThuocBanLe)}đ
                    </span>
                  </p>
                  <ul className="ml-5 flex gap-20">
                    <li className="flex justify-between w-1/3 gap-2">
                      {' '}
                      Tiền Ca A{' '}
                      <InputNumber
                        readOnly
                        size="small"
                        variant="filled"
                        value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuThuocBanLe}
                        formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      />
                    </li>
                    <li className="flex justify-between w-1/3 gap-2">
                      {' '}
                      Tiền Ca B{' '}
                      <InputNumber
                        readOnly
                        size="small"
                        variant="filled"
                        value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuThuocBanLe}
                        formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      />
                    </li>
                    <li className="flex justify-between w-1/3 gap-2">
                      {' '}
                      Tiền Ca C{' '}
                      <InputNumber
                        readOnly
                        size="small"
                        variant="filled"
                        value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuThuocBanLe}
                        formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      />
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                    {' '}
                    <span>
                      Tiền toa thuốc: {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienThuocKeToa)}
                      đ
                    </span>
                  </p>
                  <ul className="ml-5 flex gap-20">
                    <li className="flex justify-between w-1/3 gap-2">
                      {' '}
                      Tiền Ca A{' '}
                      <InputNumber
                        readOnly
                        size="small"
                        variant="filled"
                        value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuThuocKeToa}
                        formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      />
                    </li>
                    <li className="flex justify-between w-1/3 gap-2">
                      {' '}
                      Tiền Ca B{' '}
                      <InputNumber
                        readOnly
                        size="small"
                        variant="filled"
                        value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuThuocKeToa}
                        formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      />
                    </li>
                    <li className="flex justify-between w-1/3 gap-2">
                      {' '}
                      Tiền Ca C{' '}
                      <InputNumber
                        readOnly
                        size="small"
                        variant="filled"
                        value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuThuocKeToa}
                        formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      />
                    </li>
                  </ul>
                </div>
                <div className="text-base font-semibold flex justify-end gap-2">
                  Tổng Dược:{' '}
                  <span className="text-green-700 w-24 text-end">
                    {formattedNumber(
                      ThongKeDoanhThuChiNhanh?.tongTienThuocBanLe +
                        ThongKeDoanhThuChiNhanh?.tongTienThuocKeToa,
                    )}{' '}
                    đ
                  </span>
                </div>
                <Divider className="my-1" />
              </div>
            </div>
            {infoUser?.idNhom !== 4 ? (
              <div className="text-base font-semibold flex justify-end gap-2">
                Tổng Doanh thu:{' '}
                <span className="text-green-700 w-24 text-end">
                  {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienNgay)} đ
                </span>
              </div>
            ) : (
              ''
            )}
            {infoUser?.idNhom !== 4 ? <Divider className="my-1" /> : ''}
            <Button type="primary" onClick={props.handleSubmit} className="w-full mt-5">
              Mở ca
            </Button>
            <Button
              type="primary"
              ghost
              className="w-full mt-2"
              onClick={() => {
                dispatch(logoutUser(navigate, infoUser?.dangNhap.idDangNhap))
              }}
              variant="outlined"
            >
              Đăng xuất
            </Button>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalCheckIn
