import React, { useEffect, useState } from 'react'
import { Input, Modal, Tabs, Radio, Empty, DatePicker, Select, notification } from 'antd'
import Button from '@mui/material/Button'
import { Viewer, Worker } from '@react-pdf-viewer/core'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import * as typeAction from '../../../../store/constants/constants'
import { UserOutlined, FileDoneOutlined, ExperimentOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import moment from 'moment'
import { editingBNKhamDoanSchema } from '../../../../schemas/editingBNKhamDoanSchema'
import { UpdateBNKhamDoanAction } from '../../../../store/actions/khamDoanAction'
import axios from 'axios'
import 'moment/locale/vi'
moment.locale('vi')

const ModalEditBenhNham = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const dispatch = useDispatch()
  const [api, contextHolder] = notification.useNotification()
  const { modalEditBNKhamDoan } = useSelector((state) => state.modalReducer)
  const { infoBNKhamDoan, listCTy } = useSelector((state) => state.khamDoanReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const fileType = ['application/pdf']
  const [filePDFkham, setFilePDFkham] = useState(null)
  const [filePDFxn, setFilePDFxn] = useState(null)
  // xử lí file khám bệnh
  const handleChangeFileKham = (e) => {
    let File = e.target.files[0]
    if (File) {
      if (File && fileType.includes(File.type)) {
        const fileSizeInMB = File.size / (1024 * 1024) // Chuyển đổi kích thước tệp sang MB
        if (fileSizeInMB > 5) {
          // file không được vược quá 5mb
          openNotificationWithIcon('warning')
          return
        }
        let render = new FileReader()
        let formData = new FormData()
        formData.append('file', File)
        render.readAsDataURL(File)
        render.onload = (e) => {
          const blob = new Blob([File], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFkham(url)
          formik.setFieldValue('KQKhamFile', File)
          // console.log(formData);
          setKq()
        }
      }
    } else {
      formik.setFieldValue('KQKhamFile', null)
    }
  }
  // cảnh cáo dung lượng upload file
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Upload File',
      description: 'File Không được vược quá 1MB!',
      placement: 'top',
    })
  }
  const handleRadio = (name) => (e) => {
    const value = e.target.value
    formik.setFieldValue(name, value)
  }
  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const cancelPDFKham = (name) => {
    formik.setFieldValue(name, null)
    if (name === 'KQXNFile') {
      setFilePDFxn(null)
    } else {
      setFilePDFkham(null)
    }
  }
  const handleDate = (name) => (date, dateString) => {
    const newDate = moment(dateString, 'DD/MM/YYYY').format()
    formik.setFieldValue(name, newDate)
  }
  const handleSubmit = (value) => {
    // console.log(value);
    let formData = new FormData()
    for (let key in value) {
      if (value[key] !== null) {
        formData.append(key, value[key])
      }
    }
    for (const pair of formData.entries()) {
      // console.log(pair[0] + ": " + pair[1]);
    }
    dispatch(UpdateBNKhamDoanAction(infoBNKhamDoan?.idbn, formData))
    handleCancel()
  }
  const setKq = () => {
    const now = moment()
    formik.setFieldValue('NGUOIKQ', infoUser.tenNV)
    formik.setFieldValue('NGAYKQ', now.format('YYYY-MM-DDTHH:mm:ss'))
  }
  // xử lí file khám xét nghiệm
  const handleChangeFileXetNghiem = (e) => {
    let File = e.target.files[0]
    if (File) {
      if (File && fileType.includes(File.type)) {
        const fileSizeInMB = File.size / (1024 * 1024) // Chuyển đổi kích thước tệp sang MB
        if (fileSizeInMB > 5) {
          // file không được vược quá 1mb
          openNotificationWithIcon('warning')
          return
        }
        let render = new FileReader()
        render.readAsDataURL(File)
        render.onload = (e) => {
          const blob = new Blob([File], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFxn(url)
          formik.setFieldValue('KQXNFile', File)
          setKq()
          // setPdfXetNghiem(e.target.result);
        }
      }
    } else {
      formik.setFieldValue('KQXNFile', e.target.result)
    }
  }
  // FORRMIK
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDBN: infoBNKhamDoan?.idbn,
      MABN: infoBNKhamDoan?.mabn,
      TENBN: infoBNKhamDoan?.tenbn,
      GIOITINH: infoBNKhamDoan?.gioitinh,
      NGAYSINH: infoBNKhamDoan?.ngaysinh,
      SODIENTHOAI: infoBNKhamDoan?.sodienthoai,
      GHICHU: infoBNKhamDoan?.ghichu,
      IDCT: infoBNKhamDoan?.idct,
      MACT: infoBNKhamDoan?.mact,
      KQXNFile: infoBNKhamDoan?.kqxn,
      KQKhamFile: infoBNKhamDoan?.kqkham,
      NGAYKQ: infoBNKhamDoan?.ngaykq,
      NGUOIKQ: infoBNKhamDoan?.nguoikq,
      TENTRANGTHAISMS: infoBNKhamDoan?.trangthaisms,
      TRANGTHAIKHAM: infoBNKhamDoan?.trangthaikham,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: editingBNKhamDoanSchema,
  })
  const handleCancel = () => {
    setFilePDFkham(null)
    setFilePDFxn(null)
    dispatch({
      type: typeAction.CLOSE_MODAL_EDIT_BN_KHAM_DOAN,
    })
  }
  useEffect(() => {
    async function fetchFileKQK() {
      if (infoBNKhamDoan?.linK_KQKHAM) {
        try {
          const result = await axios.get(infoBNKhamDoan?.linK_KQKHAM, {
            responseType: 'arraybuffer',
          })
          const blob = new Blob([result.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFkham(url)
        } catch (err) {
          console.log(err)
        }
      }
    }
    async function fetchFileKQXN() {
      if (infoBNKhamDoan?.liNK_KQXN) {
        try {
          const result = await axios.get(infoBNKhamDoan?.liNK_KQXN, {
            responseType: 'arraybuffer',
          })
          const blob = new Blob([result.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFxn(url)
        } catch (err) {
          console.log(err)
        }
      }
    }
    fetchFileKQK()
    fetchFileKQXN()
    // setFilePDFkham(infoBNKhamDoan?.linK_KQKHAM);
    // setFilePDFxn(infoBNKhamDoan?.liNK_KQXN);
  }, [infoBNKhamDoan])
  return (
    <>
      {contextHolder}
      <Modal
        width={800}
        className="text-center"
        footer={null}
        title="Sửa thông tin bệnh nhân"
        open={modalEditBNKhamDoan}
        onCancel={handleCancel}
      >
        <form className="text-start">
          <p>
            <span className="font-semibold">Tên BN:</span> {infoBNKhamDoan?.tenbn}
          </p>
          <p>
            <span className="font-semibold">Mã BN:</span> {infoBNKhamDoan?.mabn}
          </p>
          <div className=" shadow-gray-500">
            <Tabs
              type="card"
              defaultActiveKey="1"
              items={[
                {
                  key: 1,
                  label: 'Thông tin',
                  icon: <UserOutlined />,
                  children: (
                    <>
                      <div>
                        <div className="flex gap-5">
                          <div className="w-1/2">
                            <label className="font-semibold">
                              <span className="text-red-500">*</span>
                              Tên BN:
                            </label>
                            <Input
                              status={formik.errors.TENBN ? 'error' : ''}
                              name="TENBN"
                              onChange={formik.handleChange}
                              value={formik.values.TENBN}
                            />
                          </div>
                          <div className="w-1/2 flex flex-col">
                            <label className="font-semibold">Ngày sinh:</label>
                            <DatePicker
                              allowClear={false}
                              onChange={handleDate('NGAYSINH')}
                              value={dayjs(formik.values.NGAYSINH, 'YYYY-MM-DD')}
                              format={'DD/MM/YYYY'}
                            />
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="w-1/2">
                            <label className="font-semibold">
                              {/* <span className="text-red-500">*</span> */}
                              Số điện thoại:
                            </label>
                            <Input
                              // status={formik.errors.sodienthoai ? "error" : ""}
                              onChange={formik.handleChange}
                              name="SODIENTHOAI"
                              value={formik.values.SODIENTHOAI}
                            />
                          </div>
                          <div className="w-1/2">
                            <label className="font-semibold">Tên công ty:</label>
                            <Select
                              className="w-full"
                              onChange={handleSelected('MACT')}
                              value={formik.values.MACT}
                              options={listCTy?.map(({ mact, tenct }) => ({
                                label: tenct,
                                value: mact,
                              }))}
                            />
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="w-1/2">
                            <label className="font-semibold">Ghi chú:</label>
                            <Input
                              onChange={formik.handleChange}
                              name="GHICHU"
                              value={formik.values.GHICHU}
                            />
                          </div>
                          <div class="flex items-center  mt-6 w-1/2">
                            <span class="mr-3 text-gray-700 font-medium">Giới tinh:</span>
                            <Radio.Group
                              onChange={handleRadio('GIOITINH')}
                              value={formik.values.GIOITINH}
                              name="GIOITINH"
                              options={[
                                {
                                  label: 'Nam',
                                  value: 'Nam',
                                },
                                {
                                  label: 'Nữ',
                                  value: 'Nữ',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  key: 2,
                  label: 'KQ khám',
                  icon: <FileDoneOutlined />,
                  children: (
                    <div className="h-[450px]">
                      <div className="flex gap-5">
                        <Button type="button" variant="contained" size="small">
                          <label className="cursor-pointer " for="file-upload-kham">
                            Chọn file
                          </label>
                          <input
                            id="file-upload-kham"
                            type="file"
                            accept="application/pdf"
                            onChange={handleChangeFileKham}
                          />
                        </Button>
                        {/* {filePDFkham ? (
                          <Button
                            size="small"
                            color="error"
                            onClick={() => {
                              cancelPDFKham("KQKhamFile");
                            }}
                            variant="outlined"
                          >
                            Xoá file
                          </Button>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div>
                        {filePDFkham ? (
                          <div
                            className="mt-2"
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              height: '400px',
                            }}
                          >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div style={{ height: '400px' }}>
                                <Viewer
                                  fileUrl={filePDFkham}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                            {/* <embed
                              className="w-full h-full"
                              src={filePDFkham}
                              type={fileType}
                            /> */}
                          </div>
                        ) : (
                          <Empty description={<span>Chưa có file kết quả khám</span>} />
                        )}
                        <div className="pdf-viewer"></div>
                      </div>
                    </div>
                  ),
                },
                {
                  key: 3,
                  label: 'KQ xét nghiệm',
                  icon: <ExperimentOutlined />,
                  children: (
                    <div className="h-[450px]">
                      <div className="flex gap-5">
                        <Button type="button" variant="contained" size="small">
                          <label className="cursor-pointer " for="file-upload-xet-nghiem">
                            Chọn file
                          </label>
                          <input
                            id="file-upload-xet-nghiem"
                            type="file"
                            accept="application/pdf"
                            onChange={handleChangeFileXetNghiem}
                          />
                        </Button>
                        {/* {filePDFxn ? (
                          <Button
                            size="small"
                            color="error"
                            onClick={() => {
                              cancelPDFKham("KQXNFile");
                            }}
                            variant="outlined"
                          >
                            Xoá file
                          </Button>
                        ) : (
                          ""
                        )} */}
                      </div>

                      <div>
                        {filePDFxn ? (
                          <div
                            className="mt-2"
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              height: '400px',
                            }}
                          >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div style={{ height: '400px' }}>
                                <Viewer
                                  fileUrl={filePDFxn}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                            {/* <embed
                              className="w-full h-full"
                              src={filePDFxn}
                              type={fileType}
                            /> */}
                          </div>
                        ) : (
                          <Empty description={<span>Chưa có file kết xét nghiệm</span>} />
                        )}
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="flex justify-end gap-5 pt-2">
            <Button onClick={formik.handleSubmit} variant="contained" size="small">
              Cập nhập
            </Button>
            <Button onClick={handleCancel} variant="outlined">
              Huỷ bỏ
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditBenhNham
