import * as typeAction from '../constants/constants'
export const putTrangThaiDangKyKhamOnlAction = (
  idbndkk,
  trangThaiDK,
  nguoiSua,
  getListDangKyKham,
) => ({
  type: typeAction.PUT_TRANGTHAI_DKK_ONL,
  idbndkk,
  trangThaiDK,
  nguoiSua,
  getListDangKyKham,
})
