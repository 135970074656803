import { SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Input, Space, Table } from 'antd'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

const TableTheKho = ({ isloading }) => {
  const { historyTheKho } = useSelector((state) => state.thuocVTReducer)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const count = historyTheKho?.reduce((total, item) => total + item.soLuong, 0) ?? 0

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'date',
      width: 100,
      render: (text) => moment(text).format('DD/MM/YYYY'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.ngay ? moment(record.ngay).format('DD/MM/YYYY') : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.ngay).unix() - moment(b.ngay).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      width: 120,
      ...getColumnSearchProps('maPhieu'),
    },
    {
      title: 'Loại',
      dataIndex: 'loai',
      key: 'loai',
      width: 150,
      ...getColumnSearchProps('loai'),
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      // width: 220,
      ...getColumnSearchProps('tenChiNhanhXuat'),
    },
    {
      title: 'kho chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      width: 150,
      ...getColumnSearchProps('tenKhoXuat'),
    },
    {
      title: 'Chi nhánh nhập',
      dataIndex: 'tenChiNhanhNhap',
      key: 'tenChiNhanhNhap',
      ...getColumnSearchProps('tenChiNhanhNhap'),
      // width: 220,
    },
    {
      title: 'kho nhập',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      width: 150,
      ...getColumnSearchProps('tenKhoNhap'),
    },
    {
      title: 'Số lượng',
      dataIndex: 'soLuong',
      key: 'soLuong',
      width: 100,
      align: 'center',
      sorter: {
        compare: (a, b) => {
          return a.soLuong - b.soLuong
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Số lô',
      dataIndex: 'soLo',
      key: 'soLo',
      width: 80,
      align: 'center',
      ...getColumnSearchProps('soLo'),
    },
    {
      title: 'Số hoá đơn',
      dataIndex: 'soHoaDon',
      key: 'soHoaDon',
      width: 120,
      align: 'center',
      ...getColumnSearchProps('soHoaDon'),
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'hanDung',
      key: 'hanDung',
      width: 100,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.hanDung
          ? moment(record.hanDung).format('DD/MM/YYYY')
          : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.hanDung).unix() - moment(b.hanDung).unix(),
      sortDirections: ['descend', 'ascend'],
    },
  ]

  return (
    <ConfigProvider theme={{ token: { padding: 5, borderRadius: 0 } }}>
      <Table
        locale={{
          triggerDesc: 'Sắp xếp giảm dần',
          triggerAsc: 'Sắp xếp tăng dần',
          cancelSort: 'Hủy sắp xếp',
        }}
        loading={isloading}
        bordered
        scroll={{ x: 2000 }}
        pagination={{
          pageSize: 20,
        }}
        columns={columns}
        dataSource={historyTheKho}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="center" className="text-red-500">
                  {count}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11} align="right"></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </ConfigProvider>
  )
}

export default TableTheKho
