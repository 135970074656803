import React, { useRef, useEffect, useState } from 'react'
import LineTotal from './LineTotal/LineTotal'
import TotalHeThong from './TotalHeThong/TotalHeThong'
import ColumBranch from './ColumBranch/ColumBranch'
import TTV from './TTV/TTV'
import { DatePicker, Select } from 'antd'
import Index from './Indexs/Index'
import dayjs from 'dayjs'
import moment from 'moment'
import { companyService } from '../../../services/Company/companyService'
import { branchService } from '../../../services/branch/branchService'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const Dashboard = () => {
  const id_company = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const [IDComapany, setIDCompany] = useState(id_company)
  const [IDBranch, setIDBranch] = useState(ID_BRANCH)
  const [company, setCompany] = useState([])
  const [branch, setBranch] = useState([])
  //--------- get all company --------//
  const fetchCompany = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setCompany(data)
    } catch (err) {
      console.log(err)
    }
  }
  //---------- get all branch by company
  const fetchBranchByIDCompany = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setBranch(data)
    } catch (err) {
      console.log(err)
    }
  }
  //-----change company--------//
  const SelectedCompany = (value) => {
    setIDCompany(value)
    setIDBranch(-1)
    fetchBranchByIDCompany(value)
  }
  useEffect(() => {
    fetchCompany()
    fetchBranchByIDCompany(IDComapany)
  }, [])
  return (
    <div className="p-3 bg-[#EFEFEF]">
      <div className="flex justify-between mb-3">
        <h2 className="font-semibold text-2xl">Dashboard</h2>
        <div className="flex gap-2">
          <Select
            filterOption={(input, options) => {
              const searchLower = input.trim().toLowerCase()
              return options.label.toLowerCase().includes(searchLower)
            }}
            showSearch
            className="w-60"
            value={IDComapany}
            onChange={SelectedCompany}
            options={company?.map((item) => ({
              key: item.idct,
              label: item.tenct,
              value: item.idct,
            }))}
          />
          <Select
            filterOption={(input, options) => {
              const searchLower = input.trim().toLowerCase()
              return options.label.toLowerCase().includes(searchLower)
            }}
            showSearch
            className="w-60"
            onChange={(value) => setIDBranch(value)}
            value={IDBranch}
            options={[
              { label: 'Tất cả', value: -1 },
              ...branch?.map((item) => ({ label: item.tenChiNhanh, value: item.idChiNhanh })),
            ]}
          />
          <div className="flex items-center">
            <DatePicker value={dayjs(today)} format="DD/MM/YYYY" allowClear={false} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
            </svg>
            <DatePicker value={dayjs(today)} format="DD/MM/YYYY" allowClear={false} />
          </div>
        </div>
      </div>
      <Index />
      <div className="flex gap-5">
        <div className="w-2/3 p-5 bg-white rounded-2xl">
          <h2 className="font-semibold">Tổng ca Khám: 1000 </h2>
          <LineTotal />
        </div>
        <div className="w-1/3 p-5  bg-white rounded-2xl">
          <TotalHeThong />
        </div>
      </div>
      <div className="flex gap-5 mt-5">
        <div className="w-1/2 p-5 bg-white rounded-2xl">
          <h2 className="font-semibold">Tổng doanh thu theo chi nhánh </h2>
          <ColumBranch />
        </div>
        <div className="w-1/2 p-5 bg-white rounded-2xl">
          <TTV />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
