import { Tabs } from 'antd'
import React from 'react'
import Danhsach from './Danhsach'
import ThongKe from './ThongKe'

const KhongMuaThuoc = () => {
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="p-2 rounded-lg border bg-white h-full">
        <Tabs
          items={[
            {
              key: '1',
              label: 'Danh sách',
              children:<Danhsach/> ,
            },
            {
                key: '2',
                label: 'Thống kê',
                children: <ThongKe/>,
              },
          ]}
        />
      </div>
    </div>
  )
}

export default KhongMuaThuoc
