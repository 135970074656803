import { https } from '../apiService'

export const phanconglienheService = {
  getAllPhanCongLienHeByIdnv: (idnv) => https.get(`CRMPhanCongLienHe/GetByIDNV?IDNV=${idnv}`, idnv),
  getAllPhanCongLienHe: () => https.get('CRMPhanCongLienHe/GetAll'),
  getPhanCongLienHeById: (idpclh) =>
    https.get(`CRMPhanCongLienHe/GetPhanCongByIDPCLH?idct=${idpclh}`, idpclh),
  getListTrangThaiPhanCongLienHe: () => https.get('TrangThaiPhanCong/GetAll'),
  addListPhanCongLienHe: (form) => https.post('CRMPhanCongLienHe/ThemListPhanCong', form),
  editListPhanCongLienHe: (form) => https.put('CRMPhanCongLienHe/UpdatePhanCongLienHe', form),
}
