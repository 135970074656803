import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listPT: []
}
const banLeReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_SET_LIST_PT_BAN_LE:
        draft.listPT = payload
        break
      default:
        return state
    }
  })
}

export default banLeReducer
