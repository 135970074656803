import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  KhoNhan: null,
  KhoVT: [],
  detailPT: null,
  PTCreate: null,
  PTPending: null,
  PTTransfer: null,
  PTReceive: null,
  TonKho: [],
  detailThuocVT: null,
  branchByCompany: [],
  branchPT: [],
}

const chuyenKhoReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_Ck_KHO_NHAN:
        draft.KhoNhan = payload
        break
      case typeAction.DISPATCH_LIST_PENDING_CHUYEN_KHO:
        draft.PTPending = payload
        break
      case typeAction.DISPATCH_BRANCH_CHUYEN_KHO_BY_COMPANY:
        draft.branchByCompany = payload
        break
      case typeAction.DISPATCH_BRANCH_PT_CHUYEN_KHO:
        draft.branchPT = payload
        break
      case typeAction.DISPATCH_LIST_TRANSFER_CHUYEN_KHO:
        draft.PTTransfer = payload
        break
      case typeAction.DISPATCH_LIST_RECEIVE_CHUYEN_KHO:
        draft.PTReceive = payload
        break
      case typeAction.DISPATCH_CK_KHOVT:
        draft.KhoVT.push(payload)
        break
      case typeAction.CHANGE_SL_THUOC_CK:
        const indexSL = draft.KhoVT.findIndex(
          (items) => items.ID === payload.ID, // tìm vị trí thuốc thay đổi
        )
        draft.KhoVT[indexSL].soLuongLe = payload.value
        break
      case typeAction.DISPATCH_SL_CHAN_CHUYEN_KHO:
        const indexSLChan = draft.KhoVT.findIndex(
          (items) => items.ID === payload.ID, // tìm vị trí thuốc thay đổi
        )
        draft.KhoVT[indexSLChan].soLuong = payload.value
        break
      case typeAction.DISPATCH_NOTE_CHUYEN_KHO:
        const indexNote = draft.KhoVT.findIndex(
          (items) => items.ID === payload.ID, // tìm vị trí thuốc thay đổi
        )
        draft.KhoVT[indexNote].ghiChu = payload.value
        break
      case typeAction.CLOSE_THUOC_CK_BY_ID:
        const indexDelete = draft.KhoVT.findIndex((items) => items.ID === payload)
        draft.KhoVT.splice(indexDelete, 1)
        break
      case typeAction.RESET_KHOVT_CK:
        draft.KhoVT = []
        draft.TonKho = []
        break
      case typeAction.DISPATCH_LIST_CREATE_CHUYEN_KHO:
        draft.PTCreate = payload
        break
      case typeAction.DISPATCH_TON_KHO_CHI_NHANH:
        draft.TonKho = payload
        break
      case typeAction.PUSH_THUOC_VT_CHUYEN_KHO:
        const indexPush = draft.TonKho.findIndex((items) => items.ID === payload)
        const newData = {
          ...draft.TonKho[indexPush],
          soLuongLe: 0,
          soLuong: 0,
          ghiChu: '',
        }
        draft.KhoVT.push(newData)
        break
      case typeAction.PUSH_PRODUCTS_COPY_CHUYEN_KHO:
        draft.KhoVT = [...draft.KhoVT,payload]
        break
      case typeAction.DISPATCH_INFO_PT_CHUYEN_KHO:
        draft.detailPT = payload
        break
      case typeAction.DISPATCH_INFO_THUOC_VAT_CK:
        draft.detailThuocVT = payload
        break
      default:
        return state
    }
  })
}

export default chuyenKhoReducer
