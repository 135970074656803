import { ConfigProvider, Table, Checkbox, Input } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as typeAction from '../../../../../store/constants/constants'
import 'moment/locale/vi'
moment.locale('vi')

const CustomerSms = ({ loadingBN }) => {
  const dispatch = useDispatch()
  const { listTemplate, listBN } = useSelector((state) => state.smsReducer)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [rowActive, setRowActive] = useState(null)
  const [searchMaBN, setSearchMaBN] = useState('')
  const [searchTenBN, setSearchTenBN] = useState('')
  const [searchSDT, setSearchSDT] = useState('')
  const [searchGioiTinh, setSearchGioiTinh] = useState('')
  const [searchNgaySinh, setSearchNgaySinh] = useState('')
  const [searchDiaChi, setSearchDiaChi] = useState('')
  const [searchChiNhanh, setSearchChiNhanh] = useState('')

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const handleChonKhachGuiSmsAll = (value) => {
    dispatch({
      type: typeAction.DISPATCH_LIST_BN_SMS,
      payload: listBN?.map((item) => ({
        ...item,
        guisms: value,
      })),
    })
  }
  const handleChonKhachGuiSms = (idbn, value) => {
    dispatch({
      type: typeAction.DISPATCH_LIST_BN_SMS,
      payload: listBN?.map((item) => ({
        ...item,
        guisms: item?.idbn === idbn ? value : item?.guisms,
      })),
    })
  }

  return (
    <div className="w-full p-2">
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            // borderRadius: 0,
          },
        }}
      >
        <Table
          loading={loadingBN}
          // rowSelection={rowSelection}
          onRow={(record) => {
            return {
              onClick: () => {
                setRowActive(record)
              }, // click row
            }
          }}
          scroll={{ x: 1750 }}
          pagination={{
            pageSize: 20,
            showSizeChanger: false,
          }}
          dataSource={listBN?.filter(
            (item) =>
              (!searchMaBN ||
                (item?.mabenhnhan ?? '')
                  ?.toLocaleUpperCase()
                  ?.includes((searchMaBN ?? '')?.toLocaleUpperCase())) &&
              (!searchTenBN ||
                (item?.tenbenhnhan ?? '')
                  ?.toLocaleUpperCase()
                  ?.includes((searchTenBN ?? '')?.toLocaleUpperCase())) &&
              (!searchGioiTinh ||
                (item?.gioitinh ?? '')
                  ?.toLocaleUpperCase()
                  ?.includes((searchGioiTinh ?? '')?.toLocaleUpperCase())) &&
              (!searchNgaySinh ||
                moment(item?.ngaysinh)
                  ?.format('DD/MM/YYYY')
                  ?.includes((searchNgaySinh ?? '')?.toLocaleUpperCase())) &&
              (!searchDiaChi ||
                (item?.diachidaydu ?? '')
                  ?.toLocaleUpperCase()
                  ?.includes((searchDiaChi ?? '')?.toLocaleUpperCase())) &&
              (!searchSDT ||
                (item?.dienthoai ?? '')
                  ?.toLocaleUpperCase()
                  ?.includes((searchSDT ?? '')?.toLocaleUpperCase())) &&
              (!searchChiNhanh ||
                (item?.tenchinhanh ?? '')
                  ?.toLocaleUpperCase()
                  ?.includes((searchChiNhanh ?? '')?.toLocaleUpperCase())),
          )}
          bordered
          columns={[
            {
              title: 'STT',
              width: 50,
              align: 'center',
              dataIndex: 'stt',
              key: 'stt',
              fixed: 'left',
            },
            {
              title: 'Mã khách hàng',
              width: 140,
              dataIndex: 'mabenhnhan',
              key: 'mabenhnhan',
              fixed: 'left',
              showSorterTooltip: false,
              sorter: {
                compare: (a, b) => {
                  return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchMaBN(e.target.value)}
                          value={searchMaBN}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  fixed: 'left',
                  dataIndex: 'mabenhnhan',
                  key: 'search' + 'mabenhnhan',
                  width: 140,
                },
              ],
            },
            {
              title: 'Tên khách hàng',
              width: 250,
              dataIndex: 'tenbenhnhan',
              key: 'tenbenhnhan',
              fixed: 'left',
              showSorterTooltip: false,
              sorter: {
                compare: (a, b) => {
                  return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchTenBN(e.target.value)}
                          value={searchTenBN}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  fixed: 'left',
                  dataIndex: 'tenbenhnhan',
                  key: 'search' + 'tenbenhnhan',
                  width: 250,
                },
              ],
            },
            {
              title: 'Giới tính',
              width: 90,
              dataIndex: 'gioitinh',
              key: 'gioitinh',
              align: 'center',
              showSorterTooltip: false,
              sorter: {
                compare: (a, b) => {
                  return (a?.gioitinh ?? '').localeCompare(b?.gioitinh ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchGioiTinh(e.target.value)}
                          value={searchGioiTinh}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  dataIndex: 'gioitinh',
                  key: 'search' + 'gioitinh',
                  align: 'center',
                  width: 90,
                },
              ],
            },
            {
              title: 'Ngày sinh',
              width: 100,
              align: 'center',
              dataIndex: 'ngaysinh',
              key: 'ngaysinh',
              showSorterTooltip: false,
              sorter: {
                compare: (a, b) => {
                  return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchNgaySinh(e.target.value)}
                          value={searchNgaySinh}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  dataIndex: 'ngaysinh',
                  key: 'search' + 'ngaysinh',
                  align: 'center',
                  width: 100,
                  render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
                },
              ],
            },
            {
              title: 'SĐT',
              width: 120,
              dataIndex: 'dienthoai',
              key: 'dienthoai',
              showSorterTooltip: false,

              sorter: {
                compare: (a, b) => {
                  return (a?.dienthoai ?? '').localeCompare(b?.dienthoai ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchSDT(e.target.value)}
                          value={searchSDT}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  dataIndex: 'dienthoai',
                  key: 'search' + 'dienthoai',
                  width: 100,
                  align: 'center',
                  render: (text, record) => (record.idbn === rowActive?.idbn ? <p>{text}</p> : ''),
                },
              ],
            },
            {
              title: 'Địa chỉ',
              dataIndex: 'diachidaydu',
              key: 'diachidaydu',
              showSorterTooltip: false,

              sorter: {
                compare: (a, b) => {
                  return (a?.diachidaydu ?? '').localeCompare(b?.diachidaydu ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchDiaChi(e.target.value)}
                          value={searchDiaChi}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  dataIndex: 'diachidaydu',
                  key: 'search' + 'diachidaydu',
                },
              ],
            },
            {
              title: 'Chi nhánh',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 250,
              fixed: 'right',
              // render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
              showSorterTooltip: false,
              sorter: {
                compare: (a, b) => {
                  return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
                },
              },
              children: [
                {
                  title: (
                    <>
                      <ConfigProvider
                        theme={{
                          token: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={(e) => setSearchChiNhanh(e.target.value)}
                          value={searchChiNhanh}
                        />
                      </ConfigProvider>
                    </>
                  ),
                  fixed: 'right',
                  dataIndex: 'tenchinhanh',
                  key: 'search' + 'tenchinhanh',
                  width: 250,
                },
              ],
            },
            {
              title: (
                <div className="flex justify-center items-center">
                  <Checkbox
                    checked={listBN?.every((item) => item?.guisms === 1)}
                    indeterminate={
                      listBN?.some((item) => item?.guisms === 1) &&
                      listBN?.some((item) => item?.guisms === 0)
                    }
                    onChange={(e) => handleChonKhachGuiSmsAll(e.target.checked ? 1 : 0)}
                  />
                </div>
              ),
              dataIndex: 'action',
              key: 'action',
              width: 40,
              fixed: 'right',
              render: (text, record, index) => (
                <div className="flex justify-center items-center">
                  <Checkbox
                    checked={record?.guisms}
                    onChange={(e) => handleChonKhachGuiSms(record?.idbn, e.target.checked ? 1 : 0)}
                  />
                </div>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default CustomerSms
