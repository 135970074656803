import * as Yup from "yup";
const phoneNumber = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/
export const benhnhanCRMSchema = Yup.object().shape({
    tenbn: Yup.string().required(),
    gioitinh: Yup.string().required(),
    ngaysinh: Yup.string().required(),
    idkh: Yup.number().required(),
    idkhgk: Yup.number().required(),
    // sodienthoai: Yup.string().required(),
    // website: Yup.string().required(),
});