import React from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'antd'
const { TextArea } = Input
const FormSanNhoPhuKhoa = ({ formikPhuSan }) => {
  const { DetailBN,DetailToaThuocBN } = useSelector((state) => state.childrenClinicReducer)
  const checkUpdate =
  DetailBN && DetailBN?.trangthaikhambenh !== 1 && DetailToaThuocBN?.DANHANTOA !== 0
  return (
    <>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Âm hộ: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.amho}
          name="amho"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Âm đạo: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.amdao}
          name="amdao"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">CTC: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.ctc}
          name="ctc"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Tử cung: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.tucung}
          name="tucung"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Phần phụ T: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.phanphut}
          name="phanphut"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Phần phụ P: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.phanphup}
          name="phanphup"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Cùng đồ: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.cungdo}
          name="cungdo"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">TSM: </label>
        <Input
          disabled={checkUpdate}
          style={{ color: 'black' }}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.tsm}
          name="tsm"
          size="small"
        />
      </div>
      <div className="flex gap-1 mt-1 mb-5">
        <label className="font-semibold w-44 text-end">GN. khác: </label>
        <TextArea
          disabled={checkUpdate}
          style={{ color: 'black' }}
          rows={3}
          onChange={formikPhuSan.handleChange}
          value={formikPhuSan.values.gnkhac}
          name="gnkhac"
          size="small"
        />
      </div>
    </>
  )
}
export default FormSanNhoPhuKhoa
