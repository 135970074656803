import * as typeAction from '../constants/constants'

export const getListBNChoTCAction = (idcn) => ({
  type: typeAction.GET_LIST_BN_CHO_TIEMCHUNG,
  idcn,
})
export const getListBNDaTCAction = (idcn, tungay, denngay) => ({
  type: typeAction.GET_LIST_BN_DA_TIEMCHUNG,
  idcn,
  tungay,
  denngay,
})
export const getInfoBNTiemChungAction = (idbnttc, idcn) => ({
  type: typeAction.GET_INFO_BN_TIEMCHUNG,
  idbnttc,
  idcn,
})
export const postInfoBNTheoDoiSauTiemAction = (
  form,
  idcn,
  tungay,
  denngay,
  formPrint,
  resetForm,
) => ({
  type: typeAction.POST_INFO_BN_THEO_DOI_SAU_TIEM,
  form,
  idcn,
  tungay,
  denngay,
  formPrint,
  resetForm,
})
// export const getListVTTieuHaoThuThuatAction = (idcn) => ({
//     type: typeAction.GET_LIST_VT_THUTHUAT,
//     idcn,
// })
