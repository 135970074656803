import * as typeAction from '../constants/constants'

export const getListBNChoVLTLAction = (idcn) => ({
    type: typeAction.GET_LIST_BN_CHO_VLTL,
    idcn,
})
export const getListBNDaVLTLAction = (idcn, tungay, denngay) => ({
    type: typeAction.GET_LIST_BN_DA_VLTL,
    idcn,
    tungay,
    denngay,
})
export const getInfoBNTapVLTLAction = (idbnttc) => ({
    type: typeAction.GET_INFO_BN_TAP_VLTL,
    idbnttc,
})
export const postBNTapVLTLAction = (form, idcnCho, idcnDa, tungay, denngay) => ({
    type: typeAction.POST_BN_TAP_VLTL,
    form,
    idcnCho,
    idcnDa,
    tungay,
    denngay,
})
export const getInfoSoLanTapVLTLAction = (idbnttc, iddv) => ({
    type: typeAction.GET_INFO_SO_LAN_BN_TAP_VLTL,
    idbnttc,
    iddv,
})
