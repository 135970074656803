import * as typeAction from '../constants/constants'

export const BNChoKhamPHCNAction = (idcn) => ({
    type: typeAction.GET_BN_CHO_KHAM_PHCN,
    idcn,
})
export const BNDangVaDaKhamPHCNAction = (idcn, tuNgay, denNgay) => ({
    type: typeAction.GET_BN_DANG_VA_DA_KHAM_PHCN,
    idcn,
    tuNgay,
    denNgay
})