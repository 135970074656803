import * as typeAction from '../.../../constants/constants'

export const getListThuocVT_Action = () => ({
  type: typeAction.GET_ALL_THUOC_VT,
})
export const addThuocVT_Action = (form) => ({
  type: typeAction.POST_THUOC_VT,
  form,
})
export const deleteThuocVT_Action = (id) => ({
  type: typeAction.DELETE_THUOC_VT,
  id,
  // page,
})
export const infoThuocVT_Action = (id) => ({
  type: typeAction.GET_INFO_KHO_VT,
  id,
})
export const getListCountry_Action = () => ({
  type: typeAction.GET_INFO_COUNTRY,
})
export const getListThuocVTByPageIndex_Action = (keyword, idCT, idPhanLoai, idNhom, pageIndex) => ({
  type: typeAction.GET_ALL_THUOC_VT,
  keyword,
  idCT,
  idPhanLoai,
  idNhom,
  pageIndex,
})
export const listTonKho_Action = (
  idct,
  idChiNhanh,
  idkhocn,
  toDate,
  pageNumber,
  keyword,
  setLoading,
) => ({
  type: typeAction.GET_LIST_TON_KHO_VT,
  idct,
  idChiNhanh,
  idkhocn,
  toDate,
  pageNumber,
  keyword,
  setLoading,
})
export const TonKhoTongHop = (
  IDCongTy,
  IDChiNhanh,
  IDKhoCN,
  // toDate,
  keyword,
  setLoading,
) => ({
  type: typeAction.GET_TON_KHO_TONG_HOP,
  IDCongTy,
  IDChiNhanh,
  IDKhoCN,
  // toDate,
  keyword,
  setLoading,
})
export const listTonKho_ByKhoCN_Action = (idKhoCN, setLoading) => ({
  type: typeAction.GET_LIST_TON_KHO_CN_VT,
  idKhoCN,
  setLoading,
})

//##### LẤY DANH SÁCH LOẠI THUỐC VẬT TƯ ###########
export const listTypesThuocVT_Action = () => ({
  type: typeAction.GET_LIST_TYPES_THUOC_VT,
})
//########### LẤY DANH SÁCH TẤT CẢ NHÓM THUỐC VẬT TƯ#######
export const fetchListAllGroupsVT_Action = (idType) => ({
  type: typeAction.GET_ALL_LIST_GROUPS_THUOC_VT,
  idType,
})
//########### UPDATE THUỐC VẬT TƯ#######
export const updateVT_Action = (form) => ({
  type: typeAction.UPDATE_THUOC_VAT_TU,
  form,
})
//######### LẤY DANH SÁCH NHÓM THUỐC VẬT TƯ THEO Loại ##########3

export const ListGroupsVTByTypes = (idTypes) => ({
  type: typeAction.GET_LIST_GROUPS_THUOC_VT_BY_TYPE,
  idTypes,
})
//############## LÂY DANH SÁCH CHI NHÁNH BÁN THEO THUỐC VẬT TU ########
export const listBranchByThuocVT = (idThuoc, setIsloadingBranch) => ({
  type: typeAction.GET_LIST_BRACNH_BUY,
  idThuoc,
  setIsloadingBranch,
})
//############# LẤY DANH SÁCH  NHÓM THUỐC VẬT TƯ BÁN CHI NHÁNH #########
export const listGroupsVTBranchBuy_action = (idTypes) => ({
  type: typeAction.GET_GROUPS_VT_BRANCH_BUY,
  idTypes,
})
//########### UPDATE CHI NHÁNH BÁN THEO THUỐC VT =############
export const updateBranchBuyTV_Action = (form, idThuoc, setIsloadingBranch) => ({
  type: typeAction.UPDATE_STATUS_BRANCH_BUY,
  form,
  idThuoc,
  setIsloadingBranch,
})
//############### GET ALL DANH SACH CHUYEN KHOA ############
export const listAllChuyeKhoa_Action = () => ({
  type: typeAction.GET_ALL_CHUYEN_KHOA,
})
//############### GET ALL DANH SACH LOẠI THUỐC VẬT TƯ ############
export const allTypesVT_action = () => ({
  type: typeAction.GET_ALL_TYPES_THUOC_VT,
})
//################## LẤY DANH SÁCH TỒN KHO THEO ID ###############
export const branchTonKhoAction = (IDCompany) => ({
  type: typeAction.GET_BRANCH_TON_KHO,
  IDCompany,
})
//############# LẤY DANH SÁCH THUỐC VẬT TƯ THEO KEYWORD ############
export const searchThuocVT = (keyword, pageIndex) => ({
  type: typeAction.GET_THUOC_VT_BY_KEYWORD,
  keyword,
  pageIndex,
})
//############# LẤY DANH SÁCH THUỐC VẬT TƯ THEO KEYWORD ############
export const historyMSDKThuocVT = (idThuoc) => ({
  type: typeAction.GET_HISTORY_MSDK,
  idThuoc,
})
// ------------- Lấy danh sách nhóm thuốc vật tư theo thẻ kho ----------
export const fetchGroupsTheKho = (idType) => ({
  type: typeAction.GET_GROUPS_THE_KHO,
  idType,
})
//------------------- lây danh sách kho của thẻ kho theo chi nhánh -----------
export const fetchKhoTheKhoByBranch = (idChinhanh) => ({
  type: typeAction.GET_KHO_THE_KHO,
  idChinhanh,
})
//------------------------- LẤY DANH SÁCH ĐƯỜNG DÙNG ---------------------//
export const fetchDuongDung = () => ({
  type: typeAction.GET_DUONG_DUNG_THUOC,
})
// ---------------------- LẤY DANH SÁCH HISTORY THE KHO ----------------- //
export const filterTheKho = (filter, setIsLoading) => ({
  type: typeAction.FILTER_HISTORY_THE_KHO,
  filter,
  setIsLoading,
})
//----------- IMPORT THUỐC VẬT TƯ------------//
export const importThuocVT = (
  form,

  setIsLoading,
) => ({
  type: typeAction.IMPORT_THU_MUA_HANG_LOAT,
  form,
  setIsLoading,
})
//----------CHECK MA HANG--------//
export const checkImportHanghoa = (data, idct, openNotificationWithIcon) => ({
  type: typeAction.CHECK_IMPORT_DANH_SACH_VAT_TU,
  data,
  idct,
  openNotificationWithIcon,
})
