import moment from 'moment'
import { logoIvyToDataURI } from '../../assets/images/logoIvyBase64'
import { generateCheckbox, generateDot, generateDotWithWidth } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

export const generateTT32 = (PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  // const doc = jsPDFCus('l', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height

  //header
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.addImage(logoIvyToDataURI, 'PNG', xPos, 0, 60, 30)
  doc.text(`${PkDangNhap.dienThoai ?? ''}`, xPos + 70, yPos + 5)
  yPos += 10
  doc.setFont('times', 'regular')
  doc.setFontSize(13)
  doc.text(`${PkDangNhap.diaChi ?? ''}`, xPos + 70, yPos)

  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  yPos += 10
  doc.text('KHÁM SỨC KHỎE ĐỊNH KỲ', pageWidth / 2, yPos, { align: 'center' })
  yPos += 10
  doc.setFontSize(12)
  doc.text('I. TIỀN SỬ BỆNH, TẬT ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(
    '(Bác sỹ khám sức khỏe hỏi và ghi chép)',
    xPos + 5 + doc.getTextDimensions('I. TIỀN SỬ BỆNH, TẬT ').w,
    yPos,
  )
  yPos += 6
  doc.setFont('times', 'regular')
  const tiensubenh = `${'Đặc điểm khởi phát, triệu chứng lâm sàng, diễn biến bệnh Đặc điểm khởi phát, triệu chứng lâm sàng, diễn biến bệnh Đặc điểm khởi phát, triệu chứng lâm sàng, diễn biến bệnh Đặc điểm khởi phát, triệu chứng lâm sàng, diễn biến bệnh' ?? ''}`
  if (doc.getTextWidth(tiensubenh) > 185) {
    //185 is width of page
    const wordsArray = splitLongWord(tiensubenh, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(tiensubenh, xPos, yPos)
    const dotLineCC = generateDot(doc, tiensubenh, xPos, yPos)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDot(doc, '', xPos, yPos)
      doc.text(xPos, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  doc.setFont('times', 'bold')
  doc.text('Tiền sử sản phụ khoa ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text('(Đối với nữ):', xPos + 2 + doc.getTextDimensions('Tiền sử sản phụ khoa ').w, yPos)
  yPos += 6
  doc.setFont('times', 'regular')
  doc.text(`- Bắt đầu thấy kinh nguyệt năm bao nhiêu tuổi: ${'12' ?? '........'}`, xPos, yPos)
  yPos += 6
  doc.text('- Tính chất kinh nguyệt:', xPos, yPos)
  doc.text('Đều', xPos + 50, yPos)
  generateCheckbox(doc, xPos + 58, yPos - 3.5, 4, 4, true)
  doc.text('Không đều', xPos + 80, yPos)
  generateCheckbox(doc, xPos + 100, yPos - 3.5, 4, 4, true)
  yPos += 6
  doc.text(`Chu kỳ kinh: ${'10000' ?? '........'} ngày`, xPos, yPos)
  doc.text(`Lượng kinh: ${'10000' ?? '........'} ngày`, xPos + 62, yPos)
  doc.text('Đau bụng kinh: ', xPos + 120, yPos)
  doc.text('Có', xPos + 150, yPos)
  generateCheckbox(doc, xPos + 156, yPos - 3.5, 4, 4, true)
  doc.text('Không', xPos + 170, yPos)
  generateCheckbox(doc, xPos + 183, yPos - 3.5, 4, 4, true)
  yPos += 6
  doc.text('- Đã lập gia đình:', xPos, yPos)
  doc.text('Có', xPos + 50, yPos)
  generateCheckbox(doc, xPos + 56, yPos - 3.5, 4, 4, true)
  doc.text('Chưa', xPos + 80, yPos)
  generateCheckbox(doc, xPos + 90, yPos - 3.5, 4, 4, true)
  doc.text(`- PARA: ${'0000' ?? '........'}`, xPos + 120, yPos)
  yPos += 6
  doc.text(`- Số lần mổ sản, phụ khoa: ${'12' ?? '........'}`, xPos, yPos)
  doc.text('Có', xPos + 65, yPos)
  generateCheckbox(doc, xPos + 71, yPos - 3.5, 4, 4, true)
  doc.setFont('times', 'italic')
  doc.text('Ghi rõ:', xPos + 82, yPos)
  const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 90)
  doc.text(xPos + 95, dotLineEmpty.yPos, dotLineEmpty.content)
  doc.setFont('times', 'regular')
  doc.text('Chưa', xPos + 175, yPos)
  generateCheckbox(doc, xPos + 185, yPos - 3.5, 4, 4, true)
  yPos += 6
  doc.text('Có đang áp dụng BPTT không? ', xPos, yPos)
  doc.text('Có', xPos + 65, yPos)
  generateCheckbox(doc, xPos + 71, yPos - 3.5, 4, 4, true)
  doc.setFont('times', 'italic')
  doc.text('Ghi rõ:', xPos + 82, yPos)
  const dotLineEmptyBPTT = generateDotWithWidth(doc, '', xPos, yPos, 90)
  doc.text(xPos + 95, dotLineEmptyBPTT.yPos, dotLineEmptyBPTT.content)
  doc.setFont('times', 'regular')
  doc.text('Không', xPos + 172, yPos)
  generateCheckbox(doc, xPos + 185, yPos - 3.5, 4, 4, true)
  yPos += 6
  doc.setFont('times', 'bold')
  doc.text('II. KHÁM THỂ LỰC', xPos, yPos)
  yPos += 6
  doc.setFont('times', 'regular')
  doc.text(`Chiều cao: ${'100' ?? '........'} cm ; `, xPos, yPos)
  doc.text(`Cân nặng: ${'100' ?? '........'} kg ; `, xPos + 65, yPos)
  doc.text(`Chỉ số BMI: ${'100' ?? '........'}`, xPos + 120, yPos)
  yPos += 6
  doc.text(`Mạch: ${'100' ?? '........'} lần/phút ; `, xPos, yPos)
  doc.text(`Huyết áp: ${'100 / 100' ?? '......../.......'} mmHg`, xPos + 100, yPos)
  yPos += 6
  const phanloaitheluc = `Phân loại thể lực: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`
  if (doc.getTextWidth(phanloaitheluc) > 180) {
    const wordsArray = splitLongWord(phanloaitheluc, 100)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLinePhanLoaiTheLuc = generateDot(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos,
      yPos - 6,
    )
    doc.text(
      dotLinePhanLoaiTheLuc.positionX,
      dotLinePhanLoaiTheLuc.yPos,
      dotLinePhanLoaiTheLuc.content,
    )
  } else {
    doc.text(phanloaitheluc, xPos, yPos)
    const dotLinePhanLoaiTheLuc = generateDot(doc, phanloaitheluc, xPos, yPos)
    doc.text(
      dotLinePhanLoaiTheLuc.positionX,
      dotLinePhanLoaiTheLuc.yPos,
      dotLinePhanLoaiTheLuc.content,
    )
    yPos += 6
  }
  doc.setFont('times', 'bold')
  doc.text('III. KHÁM LÂM SÀNG', xPos, yPos)
  yPos += 4

  //line x
  doc.line(xPos, yPos, pageWidth - 8, yPos)
  doc.text('Nội dung khám', xPos + 60, yPos + 8)
  doc.text('Họ tên, chữ ký', xPos + 158, yPos + 5)
  doc.text('của Bác sĩ', xPos + 162, yPos + 10)
  doc.line(xPos, yPos + 15, pageWidth - 8, yPos + 15)
  doc.text('1. Nội khoa', xPos + 4, yPos + 20)
  doc.line(xPos, yPos + 22, pageWidth - 8, yPos + 22)
  doc.setFont('times', 'regular')
  doc.text('a)', xPos + 3, yPos + 31)
  doc.setFontSize(11)
  doc.setFont('times', 'italic')
  doc.text(`Tuần hoàn: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 28)
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 36)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 38, pageWidth - 8, yPos + 38)
  doc.text('b)', xPos + 3, yPos + 46)
  doc.setFontSize(11)
  doc.setFont('times', 'italic')
  doc.text(`Hô hấp: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 44)
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 52)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 54, pageWidth - 8, yPos + 54)
  doc.text('c)', xPos + 3, yPos + 62)
  doc.setFont('times', 'italic')
  doc.text(`Tiêu hóa: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 60)
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 68)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 70, pageWidth - 8, yPos + 70)
  doc.text('d)', xPos + 3, yPos + 78)
  doc.setFont('times', 'italic')
  doc.text(
    `Thận - tiết niệu: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`,
    xPos + 12,
    yPos + 76,
  )
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 84)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 86, pageWidth - 8, yPos + 86)
  doc.text('đ)', xPos + 3, yPos + 94)
  doc.setFont('times', 'italic')
  doc.text(`Nội tiết: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 92)
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 100)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 102, pageWidth - 8, yPos + 102)
  doc.text('e)', xPos + 3, yPos + 110)
  doc.setFont('times', 'italic')
  doc.text(
    `Cơ - xương - khớp: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`,
    xPos + 12,
    yPos + 108,
  )
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 116)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 118, pageWidth - 8, yPos + 118)
  doc.text('g)', xPos + 3, yPos + 126)
  doc.setFont('times', 'italic')
  doc.text(`Thần kinh: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 124)
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 132)
  doc.setFontSize(12)

  doc.line(xPos, yPos + 134, pageWidth - 8, yPos + 134)
  doc.text('h)', xPos + 3, yPos + 142)
  doc.setFont('times', 'italic')
  doc.text(`Tâm thần: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 140)
  doc.setFont('times', 'regular')
  doc.text(`Phân loại: ${'1231231231231231 Phân loại thể i thể lực' ?? ''}`, xPos + 12, yPos + 148)
  doc.setFontSize(12)
  doc.line(xPos, yPos + 150, pageWidth - 8, yPos + 150)
  //line y
  doc.line(xPos, yPos, xPos, yPos + 150)
  doc.line(xPos + 150, yPos, xPos + 150, yPos + 15)
  doc.line(xPos + 150, yPos + 22, xPos + 150, yPos + 150)
  doc.line(xPos + 10, yPos + 22, xPos + 10, yPos + 150)
  doc.line(xPos + 10, yPos + 30, xPos + 150, yPos + 30)
  doc.line(xPos + 10, yPos + 46, xPos + 150, yPos + 46)
  doc.line(xPos + 10, yPos + 62, xPos + 150, yPos + 62)
  doc.line(xPos + 10, yPos + 78, xPos + 150, yPos + 78)

  doc.line(xPos + 10, yPos + 94, xPos + 194, yPos + 94)
  doc.line(xPos + 10, yPos + 110, xPos + 194, yPos + 110)
  doc.line(xPos + 10, yPos + 126, xPos + 194, yPos + 126)
  doc.line(xPos + 10, yPos + 142, xPos + 194, yPos + 142)
  doc.line(pageWidth - 8, yPos, pageWidth - 8, yPos + 150)

  //add page 2
  doc.addPage()
  yPos = 10
  //line x

  doc.line(xPos, yPos, pageWidth - 8, yPos)
  doc.setFont('times', 'bold')
  doc.text('2.', xPos + 3, yPos + 32)
  doc.text('Ngoại khoa, Da liễu:', xPos + 12, yPos + 5)
  doc.setFont('times', 'italic')
  doc.text('- Ngoại khoa:', xPos + 12, yPos + 10)
  doc.setFont('times', 'regular')
  let tempYpos = yPos + 10
  //ngoai khoa
  const textNgoaiKhoa = 'Phân loại thể i thể lực Phân'
  if (doc.getTextWidth(textNgoaiKhoa) > 80) {
    const wordsArray = splitLongWord(textNgoaiKhoa, 65)
    doc.text(wordsArray[0], xPos + 12 + doc.getTextDimensions('- Ngoại khoa:').w, tempYpos)
    tempYpos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 12, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(textNgoaiKhoa, xPos + 12 + doc.getTextDimensions('- Ngoại khoa:').w, tempYpos)
    const dotLineCC = generateDotWithWidth(
      doc,
      textNgoaiKhoa,
      xPos + 12 + doc.getTextDimensions('- Ngoại khoa:').w,
      tempYpos,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }

  const phanloaiNgoaiKhoa = 'Phân loại: thể i thể lực Phân'
  if (doc.getTextWidth(phanloaiNgoaiKhoa) > 80) {
    const wordsArray = splitLongWord(phanloaiNgoaiKhoa, 65)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 12, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 2) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(phanloaiNgoaiKhoa, xPos + 12, tempYpos)
    const dotLineCC = generateDotWithWidth(doc, phanloaiNgoaiKhoa, xPos + 12, tempYpos, 165)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    tempYpos += 6
  }

  //da lieu
  doc.setFont('times', 'italic')
  doc.text('- Da liễu:', xPos + 12, tempYpos)
  doc.setFont('times', 'regular')
  const textDalieu = 'Phân loại thể i thể lực Phân'
  if (doc.getTextWidth(textDalieu) > 80) {
    const wordsArray = splitLongWord(textDalieu, 65)
    doc.text(wordsArray[0], xPos + 12 + doc.getTextDimensions('- Da liễu:').w, tempYpos)
    tempYpos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 12, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(textDalieu, xPos + 12 + doc.getTextDimensions('- Da liễu:').w, tempYpos)
    const dotLineCC = generateDotWithWidth(
      doc,
      textDalieu,
      xPos + 12 + doc.getTextDimensions('- Da liễu:').w,
      tempYpos,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }

  const phanloaiDaLieu = 'Phân loại: thể i thể lực Phân'
  if (doc.getTextWidth(phanloaiDaLieu) > 80) {
    const wordsArray = splitLongWord(phanloaiDaLieu, 65)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 12, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 2) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(phanloaiDaLieu, xPos + 12, tempYpos)
    const dotLineCC = generateDotWithWidth(doc, phanloaiDaLieu, xPos + 12, tempYpos, 165)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    tempYpos += 6
  }
  doc.line(xPos, yPos + 70, pageWidth - 8, yPos + 70)

  doc.text('3.', xPos + 3, yPos + 92)
  doc.setFont('times', 'bold')
  tempYpos += 6
  doc.text('Sản phụ khoa (Dành cho nữ)', xPos + 12, tempYpos)
  tempYpos += 6
  doc.setFont('times', 'regular')
  const sanphukhoa =
    'Phân loại: thể i thể lực Phân Phân loại: thể i thể lực Phân Phân loại: thể i thể lực Phân Phân loại: thể i thể lực Phân'
  if (doc.getTextWidth(sanphukhoa) > 80) {
    const wordsArray = splitLongWord(sanphukhoa, 65)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 12, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(sanphukhoa, xPos + 12, tempYpos)
    const dotLineCC = generateDotWithWidth(doc, sanphukhoa, xPos + 12, tempYpos, 165)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }

  const phanloaiSanPhuKhoa = 'Phân loại: thể i thể lực Phân'
  if (doc.getTextWidth(phanloaiSanPhuKhoa) > 80) {
    const wordsArray = splitLongWord(phanloaiSanPhuKhoa, 65)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 12, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 2) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(phanloaiSanPhuKhoa, xPos + 12, tempYpos)
    const dotLineCC = generateDotWithWidth(doc, phanloaiSanPhuKhoa, xPos + 12, tempYpos, 165)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 12, tempYpos, 165)
    doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
    tempYpos += 6
  }
  doc.line(xPos, yPos + 110, pageWidth - 8, yPos + 110)
  doc.setFont('times', 'bold')
  tempYpos += 3
  doc.text('4. Mắt', xPos + 5, tempYpos)
  doc.line(xPos, yPos + 118, pageWidth - 8, yPos + 118)
  tempYpos += 12
  doc.setFont('times', 'italic')
  doc.text('Kết quả khám thị lực:', xPos + 2, tempYpos)
  doc.setFont('times', 'regular')
  doc.text(
    `Không kính: Mắt phải ${12 ?? '...'}/10, Mắt trái ${12 ?? '...'}/10`,
    xPos + 40,
    tempYpos,
  )
  tempYpos += 7
  doc.text(`Có kính: Mắt phải ${12 ?? '...'}/10, Mắt trái ${12 ?? '...'}/10`, xPos + 40, tempYpos)

  doc.line(xPos, yPos + 144, xPos + 150, yPos + 144)
  tempYpos += 15
  doc.setFont('times', 'italic')
  doc.text('Các bệnh về mắt (nếu có):', xPos + 2, tempYpos)
  doc.setFont('times', 'regular')
  doc.text('12312312312312312312', xPos + 48, tempYpos)
  doc.line(xPos, yPos + 152, xPos + 150, yPos + 152)
  tempYpos += 8
  doc.text(`Phân loại: ${'qưewqeqweeeeeqweqweqweqweqweqweqweqwe' ?? ''}`, xPos + 2, tempYpos)

  doc.line(xPos, yPos + 160, pageWidth - 8, yPos + 160)
  doc.setFont('times', 'bold')
  tempYpos += 8
  doc.text('5. Tai - Mũi - Họng', xPos + 5, tempYpos)
  tempYpos += 10
  doc.line(xPos, yPos + 168, pageWidth - 8, yPos + 168)
  doc.setFont('times', 'italic')
  doc.text('Kết quả khám thính lực:', xPos + 2, tempYpos)
  doc.setFont('times', 'regular')
  doc.text(
    `Tai trái:   Nói thường ${12 ?? '...'} m; Nói thầm ${12 ?? '...'} m`,
    xPos + 44,
    tempYpos,
  )
  tempYpos += 7
  doc.text(`Tai phải: Nói thường ${12 ?? '...'} m; Nói thầm ${12 ?? '...'} m`, xPos + 44, tempYpos)
  doc.line(xPos, yPos + 190, xPos + 150, yPos + 190)
  tempYpos += 15
  doc.setFont('times', 'italic')
  doc.text('Các bệnh tai mũi họng(nếu có): ', xPos + 2, tempYpos)
  doc.setFont('times', 'regular')
  const taimuihong = 'Kết quả khám thính lực'
  if (doc.getTextWidth(taimuihong) > 40) {
    const wordsArray = splitLongWord(taimuihong, 50)
    doc.text(
      wordsArray[0],
      xPos + 2 + doc.getTextDimensions('Các bệnh tai mũi họng(nếu có): ').w,
      tempYpos,
    )
    tempYpos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 2, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(
      taimuihong,
      xPos + 2 + doc.getTextDimensions('Các bệnh tai mũi họng(nếu có): ').w,
      tempYpos,
    )
    const dotLineCC = generateDotWithWidth(
      doc,
      taimuihong,
      xPos + 2 + doc.getTextDimensions('Các bệnh tai mũi họng(nếu có): ').w,
      tempYpos,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }

  doc.line(xPos, yPos + 212, xPos + 150, yPos + 212)
  tempYpos = 227
  doc.text(`Phân loại: 123123123123123123123123123`, xPos + 2, tempYpos)
  doc.line(xPos, yPos + 220, pageWidth - 8, yPos + 220)
  tempYpos += 8
  doc.setFont('times', 'bold')
  doc.text('6. Răng - Hàm - Mặt', xPos + 5, tempYpos)
  doc.line(xPos, yPos + 228, pageWidth - 8, yPos + 228)
  tempYpos += 10
  doc.setFont('times', 'italic')
  doc.text('Kết quả khám:', xPos + 2, tempYpos)
  doc.setFont('times', 'regular')
  doc.text(`Hàm trên: 1231231231231231231231`, xPos + 32, tempYpos)
  tempYpos += 7
  doc.text(`Hàm dưới: 1231231231231231231231`, xPos + 32, tempYpos)

  doc.line(xPos, yPos + 248, xPos + 150, yPos + 248)
  tempYpos = 264
  doc.setFont('times', 'italic')
  doc.text('Các bệnh răng hàm mặt (nếu có):', xPos + 2, tempYpos)
  doc.setFont('times', 'regular')
  const ranghammat = 'Kết quả khám Kết quả khám Kết quả khám Kết quả khám Kết quả khám'
  if (doc.getTextWidth(ranghammat) > 40) {
    const wordsArray = splitLongWord(ranghammat, 50)
    doc.text(
      wordsArray[0],
      xPos + 2 + doc.getTextDimensions('Các bệnh răng hàm mặt (nếu có): ').w,
      tempYpos,
    )
    tempYpos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 2, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(
      ranghammat,
      xPos + 2 + doc.getTextDimensions('Các bệnh răng hàm mặt (nếu có): ').w,
      tempYpos,
    )
    const dotLineCC = generateDotWithWidth(
      doc,
      ranghammat,
      xPos + 2 + doc.getTextDimensions('Các bệnh răng hàm mặt (nếu có): ').w,
      tempYpos,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }
  doc.line(xPos, yPos + 268, xPos + 150, yPos + 268)
  tempYpos = 284
  doc.text(`Phân loại: 123123123123123123123123123`, xPos + 2, tempYpos)
  doc.line(xPos, yPos, pageWidth - 8, yPos)
  doc.line(xPos, pageHeight - 10, pageWidth - 8, pageHeight - 10)

  //line y
  doc.line(xPos, yPos, xPos, pageHeight - 10)
  doc.line(xPos + 150, yPos, xPos + 150, yPos + 110)
  doc.line(xPos + 150, yPos + 118, xPos + 150, yPos + 160)
  doc.line(xPos + 150, yPos + 168, xPos + 150, yPos + 220)
  doc.line(xPos + 150, yPos + 228, xPos + 150, pageHeight - 10)

  doc.line(xPos + 10, yPos, xPos + 10, yPos + 110)

  doc.line(pageWidth - 8, yPos, pageWidth - 8, pageHeight - 10)

  //page 3
  doc.addPage()
  yPos = 10
  tempYpos = yPos
  doc.setFont('times', 'bold')
  doc.text('IV. KHÁM CẬN LÂM SÀNG', xPos, yPos)
  yPos += 4
  //line x
  doc.line(xPos, yPos, pageWidth - 8, yPos)
  doc.setFont('times', 'regular')
  tempYpos = 20
  doc.text(
    'Xét nghiệm huyết học/sinh hóa/X.quang và các xét nghiệm khác khi có chỉ định của',
    xPos + 2,
    tempYpos,
  )
  tempYpos += 6
  doc.text('bác sỹ:', xPos + 2, tempYpos)
  tempYpos += 6
  doc.text('a) Kết quả:', xPos + 2, tempYpos)
  tempYpos += 6
  const ketquaCLS = 'Xét nghiệm huyết học/sinh hóa/X.quang và các xé'
  if (doc.getTextWidth(ketquaCLS) > 90) {
    const wordsArray = splitLongWord(ketquaCLS, 75)
    doc.text(wordsArray[0], xPos + 2, tempYpos)
    tempYpos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 2, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 12) {
      for (let i = 0; i < 12 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(ketquaCLS, xPos + 2, tempYpos)
    const dotLineCC = generateDotWithWidth(doc, ketquaCLS, xPos + 2, tempYpos, 165)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 11; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }

  doc.text('b) Đánh giá:', xPos + 2, tempYpos)
  const danhgia = 'Xét nghiệm huyết học/sinh học/X.quang và các xết nghiệm khác khi có chỉ định của'
  if (doc.getTextWidth(danhgia) > 65) {
    const wordsArray = splitLongWord(danhgia, 65)
    doc.text(wordsArray[0], xPos + 2 + doc.getTextDimensions('b) Đánh giá ').w, tempYpos)
    tempYpos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 2, tempYpos)
      tempYpos += 6
    }
    const dotLineCC = generateDotWithWidth(
      doc,
      wordsArray[wordsArray.length - 1],
      xPos + 12,
      tempYpos - 6,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        tempYpos += 6
      }
    }
  } else {
    doc.text(danhgia, xPos + 2 + doc.getTextDimensions('b) Đánh giá ').w, tempYpos)
    const dotLineCC = generateDotWithWidth(
      doc,
      danhgia,
      xPos + 2 + doc.getTextDimensions('b) Đánh giá ').w,
      tempYpos,
      165,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    tempYpos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos + 2, tempYpos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      tempYpos += 6
    }
  }

  doc.line(xPos, yPos + 110, pageWidth - 8, yPos + 110)
  //line y
  doc.line(xPos, yPos, xPos, yPos + 110)
  doc.line(xPos + 150, yPos, xPos + 150, yPos + 110)
  doc.line(pageWidth - 8, yPos, pageWidth - 8, yPos + 110)

  yPos = tempYpos + 6
  doc.setFont('times', 'bold')
  doc.text('V. KẾT LUẬN', xPos, yPos)
  yPos += 6
  doc.text('1. Phân Loại sức khỏe(*):', xPos, yPos)
  doc.setFont('times', 'regular')
  const phanloaisuckhoe =
    'Xét nghiệm huyết học/sinh học/X.quang và các xết nghiệm khác khi có chỉ định của và các xết nghiệm khác khi có chỉ định của và các xết nghiệm khác khi có chỉ định của'
  if (doc.getTextWidth(phanloaisuckhoe) > 140) {
    const wordsArray = splitLongWord(phanloaisuckhoe, 85)
    doc.text(wordsArray[0], xPos + 2 + doc.getTextDimensions('1. Phân Loại sức khỏe(*): ').w, yPos)
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 3) {
      for (let i = 0; i < 3 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(
      phanloaisuckhoe,
      xPos + 2 + doc.getTextDimensions('1. Phân Loại sức khỏe(*): ').w,
      yPos,
    )
    const dotLineCC = generateDot(
      doc,
      phanloaisuckhoe,
      xPos + doc.getTextDimensions('1. Phân Loại sức khỏe(*): ').w,
      yPos,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 2; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 6
  doc.setFont('times', 'bold')
  doc.text('2. Các bệnh, tật (nếu có)(**):', xPos, yPos)
  doc.setFont('times', 'regular')
  const cacbenhtat =
    'Xét nghiệm huyết học/sinh học/X.quang và các xết nghiệm khác khi có chỉ định của và các xết nghiệm khác khi có chỉ định của và các xết nghiệm khác khi có chỉ định của'
  if (doc.getTextWidth(cacbenhtat) > 130) {
    const wordsArray = splitLongWord(cacbenhtat, 85)
    doc.text(
      wordsArray[0],
      xPos + 2 + doc.getTextDimensions('2. Các bệnh, tật (nếu có)(**): ').w,
      yPos,
    )
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
    const dotLineCC = generateDot(doc, wordsArray[wordsArray.length - 1], xPos, yPos - 6)
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    if (wordsArray.length < 5) {
      for (let i = 0; i < 5 - wordsArray.length; i++) {
        const dotLineEmpty = generateDot(doc, '', xPos, yPos)
        doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
        yPos += 6
      }
    }
  } else {
    doc.text(
      cacbenhtat,
      xPos + 2 + doc.getTextDimensions('2. Các bệnh, tật (nếu có)(**): ').w,
      yPos,
    )
    const dotLineCC = generateDot(
      doc,
      cacbenhtat,
      xPos + doc.getTextDimensions('2. Các bệnh, tật (nếu có)(**): ').w,
      yPos,
    )
    doc.text(dotLineCC.positionX, dotLineCC.yPos, dotLineCC.content)
    yPos += 6
    for (let i = 0; i < 4; i++) {
      const dotLineEmpty = generateDotWithWidth(doc, '', xPos, yPos, 165)
      doc.text(dotLineEmpty.positionX, dotLineEmpty.yPos, dotLineEmpty.content)
      yPos += 6
    }
  }
  yPos += 6
  const dateText = `TP.HCM,ngày ${moment().format('DD')} tháng ${moment().format('MM')} năm ${moment().format('YYYY')}`
  doc.text(dateText, pageWidth - 84, yPos)
  yPos += 6
  doc.setFont('times', 'bold')
  doc.text('NGƯỜI KẾT LUẬN', pageWidth - 70, yPos)
  yPos += 6
  doc.setFont('times', 'regular')
  doc.text('(Ký và ghi rõ họ tên)', pageWidth - 70, yPos)

  doc.setFontSize(10)
  doc.text('*:  Phân loại sức khỏe theo quy định của Bộ Y tế', xPos, pageHeight - 15)
  doc.text(
    '**:  Ghi rõ các bệnh, tật, phương án điều trị, phục hồi chức năng hoặc giới thiệu khám chuyên khoa để khám bệnh, chữa bệnh.',
    xPos,
    pageHeight - 10,
  )

  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
