import { ConfigProvider, Tabs } from 'antd'
import React from 'react'
import KhoaPhong from './KhoaPhong/KhoaPhong'
import ServicesGroups from './ServicesGroups/ServicesGroups'
import DetailService from './DetailService/DetailService'
const DichVuReport = () => {
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="bg-white p-2 rounded-lg border h-full">
        <ConfigProvider theme={{
          components:{
            Tabs:{
              itemColor:'#888888'
            }
          }
        }}>
          <Tabs
            items={[
              {
                key: 1,
                label: <p className="font-semibold ">Khoa phòng</p>,
                children: <KhoaPhong/>,
              },
              {
                key: 2,
                label: <p className="font-semibold ">Nhóm dịch vụ</p>,
                children: <ServicesGroups/>,
              },
              {
                key: 3,
                label: <p className="font-semibold ">Chi tiết dịch vụ</p>,
                children: <DetailService/>,
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default DichVuReport
