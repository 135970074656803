import { ContainerOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Input, Select, Space, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { https } from '../../../services/apiService'
import {
  getAllPhanCongLienHe,
  getAllPhanCongLienHeByIdnv,
  getAllTrangThai,
  getDetailPhanCongLienHe,
} from '../../../store/actions/phanconglienheAction'
import ModalAddPhanCong from './Modal/ModalAdd'
import ModalEditPhanCong from './Modal/ModalEdit'
import { debounce } from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'

function PhanCong(props) {
  const { listPhanCong } = useSelector((state) => state.phanconglienheReducer)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [openChiTiet, setOpenChiTiet] = useState(false)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [nhanVienSales, setNhanVienSales] = useState([])
  const [nhanVienSearch, setNhanVienSearch] = useState('')

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const handleReload = () => {}

  const exportToExcel = () => {}

  const handleSearchInput = () => {}

  const handleShowChitiet = (item) => {
    dispatch(getDetailPhanCongLienHe(item.idpclh))
    setOpenChiTiet(true)
  }

  const onLoad = () => {
    dispatch(getAllPhanCongLienHe())
  }

  useEffect(() => {
    onLoad()
    dispatch(getAllTrangThai())
  }, [])

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Nhân viên gọi',
      dataIndex: 'tennhanvien',
      key: 'tennhanvien',
      width: 200,
      ...getColumnSearchProps('tennhanvien'),
    },
    {
      title: 'Công ty',
      dataIndex: 'tencongty',
      width: 250,
      key: 'tencongty',
      ...getColumnSearchProps('tencongty'),
    },
    {
      title: 'Người liên hệ',
      dataIndex: 'nguoilienhe',
      key: 'nguoilienhe',
      width: 200,
      ...getColumnSearchProps('nguoilienhe'),
    },
    {
      title: 'Người phân công',
      dataIndex: 'tennguoiphancong',
      key: 'tennguoiphancong',
      width: 200,
      ...getColumnSearchProps('tennguoiphancong'),
    },
    {
      title: 'Ngày phân công',
      dataIndex: 'ngayphancong',
      key: 'ngayphancong',
      width: 150,
      render: (text, record, index) => moment(text).format('DD-MM-YYYY'),
    },
    // {
    //   title: 'Nội dung',
    //   dataIndex: 'noidung',
    //   key: 'noidung',
    //   width: 250,
    // },
    // {
    //   title: 'Trạng thái',
    //   dataIndex: 'trangthai',
    //   key: 'trangthai',
    //   width: 150,
    // },
    // {
    //   title: 'Ghi chú',
    //   dataIndex: 'ghichu',
    //   key: 'ghichu',
    //   width: 150,
    // },
    {
      title: '',
      dataIndex: 'action',
      width: 50,
      align: 'center',
      key: 'action',
      fixed: 'right',
    },
  ]

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('NhanVien/GetNhanVienSale')
        console.log(data)
        setNhanVienSales(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handleSearchDebounce = useCallback(
    debounce((value) => {
      if (!value) {
        dispatch(getAllPhanCongLienHe())
        return
      }
      dispatch(getAllPhanCongLienHeByIdnv(value))
    }, 300),
    [],
  )

  const handleChangeNhanVienSearch = (value) => {
    setNhanVienSearch(value)
    handleSearchDebounce(value)
  }

  return (
    <>
      <div className="p-2 bg-[#E8E8E8] h-full">
        <div
          className="bg-white p-2 rounded-md h-full"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="mb-2 flex ">
            <div className="flex gap-1 items-center">
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                value={nhanVienSearch}
                showSearch
                onChange={handleChangeNhanVienSearch}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...nhanVienSales?.map((item) => ({
                    label: item.tennv,
                    value: item.id,
                  })),
                ]}
                className="w-80"
              />
              {/* <ul className="flex gap-1">
                <li className="flex gap-1 justify-center items-center">
                  <DatePicker
                    format={dateFormat}
                    allowClear={false}
                    maxDate={dayjs(now)}
                    defaultValue={dayjs(dateFrom)}
                    onChange={handleDatePicker('from')}
                    className="w-full"
                  />
                </li>
                <li li className="flex gap-1 justify-center items-center">
                  <p className="font-semibold">-</p>
                  <DatePicker
                    onChange={handleDatePicker('to')}
                    maxDate={dayjs(now)}
                    format={dateFormat}
                    allowClear={false}
                    defaultValue={dayjs(dateTo)}
                    className="w-full"
                  />
                </li>
              </ul>
              <div>
                <IconButton sx={{ marginRight: 2 }} onClick={handleReload} color="primary">
                  <CachedIcon />
                </IconButton>
              </div> */}
            </div>
            <div className="font-semibold ">
              {/* <Button
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
                // loading={loadingExport}
                // disabled={loadingExport}
              >
                Xuất Excel
              </Button> */}
            </div>
            <div className="ml-auto w-80">
              {/* <Input
                // value={search}
                placeholder="Tìm kiếm"
                onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                className="w-full"
              /> */}
            </div>
            <div className="px-2">
              <Button type="primary" onClick={() => setOpen(true)}>
                Thêm phân công
              </Button>
            </div>
          </div>
          <div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                bordered
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                columns={columns}
                dataSource={listPhanCong?.map((item) => ({
                  ...item,
                  tencongty: item.crmThongTinLienHe.tencongty,
                  nguoilienhe: item.crmThongTinLienHe.nguoilienhe,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg">
                        <Tooltip title="Chi tiết" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => handleShowChitiet(item)}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
        </div>
        {open && <ModalAddPhanCong setOpen={setOpen} open={open} onLoad={onLoad} />}
        {openChiTiet && (
          <ModalEditPhanCong
            setOpenChiTiet={setOpenChiTiet}
            openChiTiet={openChiTiet}
            onLoad={onLoad}
          />
        )}
      </div>
    </>
  )
}

PhanCong.propTypes = {}

export default PhanCong
