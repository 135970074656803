import * as typeAction from '../constants/constants'

export const updatePayment = (idLoai, maPhieu, date, setMaPhieu) => ({
  type: typeAction.UPDATE_PAYMENT_BN,
  idLoai,
  maPhieu,
  date,
  setMaPhieu,
})
export const updateTTVTool = (form, reset) => ({
  type: typeAction.UPDATE_TTV_TOOL,
  form,
  reset,
})
export const updateTaiKhamTool = (form, reset) => ({
  type: typeAction.UPDATE_NGAY_TAI_KHAM_TOOL,
  form,
  reset,
})
export const getAllUuDai = (setData) => ({
  type: typeAction.GET_ALL_UU_DAI_TAI_KHAM,
  setData,
})
export const getDichVu = (idct, keyword, setData) => ({
  type: typeAction.GET_DICH_VU_BY_CONG_TY,
  idct,
  keyword,
  setData,
})
export const postUuDai = (form, fetchData) => ({
  type: typeAction.ADD_NEW_UU_DAI_TAI_KHAM,
  form,
  fetchData,
})
export const deleteUuDai = (ID, fetchData, openNotificationWithIcon) => ({
  type: typeAction.DELETE_UU_DAI_TAI_KHAM,
  ID,
  fetchData,
  openNotificationWithIcon,
})

export const gopBenhAn = (reset, idbn, idbndelete) => ({
  type: typeAction.TOO_GOP_BENH_AN,
  reset,
  idbn,
  idbndelete,
})
export const putCaLamViecCuaPhieuThuAction = (form, reset) => ({
  type: typeAction.PUT_CALAMVIEC_PHIEUTHU_TOOL,
  form,
  reset,
})
export const putLoaiKhamCuaPhieuThuAction = (form, reset) => ({
  type: typeAction.PUT_LOAIKHAM_PHIEUTHU_TOOL,
  form,
  reset,
})
export const putNguoiBanTTVAction = (form, reset) => ({
  type: typeAction.PUT_NGUOIBAN_TTV_TOOL,
  form,
  reset,
})
export const deletePhieuKho = (maPhieu, reset) => ({
  type: typeAction.DELETE_PHIEU_KHO_TOOL,
  maPhieu,
  reset,
})
