import React from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'

const KhoHang = () => {
  return (
    <>
      <div className='p-5 bg-[#EFEFEF] h-full'>
        <div className=''>

        </div>
      </div>
    </>
  )
}

export default KhoHang
