import * as typeAction from '../constants/constants'

export const getBNChoKSKAction = (idcn) => ({
  type: typeAction.GET_LIST_BN_CHO_KSK,
  idcn,
})
export const getBNDangVaDaKSKAction = (idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_LIST_BN_DANG_VA_DA_KSK,
  idcn,
  tuNgay,
  denNgay,
})
export const getInfoKSKAction = (idbnttc) => ({
  type: typeAction.GET_INFO_BN_KSK,
  idbnttc,
})
export const postInfoKSKAction = (form) => ({
  type: typeAction.POST_INFO_BN_KSK,
  form,
})
