import React, { useRef, useState } from 'react'
import { Button, notification, List, Image, Checkbox } from 'antd'
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { isWithin24Hours } from '../../../../utils/algorithm'
import { infoBNSieuAm as getInfoBNSieuAm } from '../../../../store/actions/sieuAmAction'

const ImageTiemChung = ({ listImage, setListImage, activeItemId, getInfoBNTTC }) => {
  const { infoBN } = useSelector((state) => state.traCuuReducer)
  const fileInputRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()

  const dispatch = useDispatch()

  const openNotificationWithIcon = () => {
    api.error({
      message: 'Chọn file hình ảnh tiêm chủng ',
      description: 'Chỉ chấp nhận file hình ảnh!',
    })
  }

  const reload = (activeItemId) => {
    getInfoBNTTC(activeItemId)
  }

  const updateNewImage = async (listImage) => {
    const formData = new FormData()
    const data = listImage.map((item) => item.file)
    data.forEach((file) => {
      formData.append('files', file)
    })
    try {
      await https.put(`BenhNhanTTC/UploadLinkHinhAnhTiemChung?idbnttc=${activeItemId}`, formData)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật hình ảnh thành công',
      })
      await reload(activeItemId)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.reponse?.data?.message || 'Cập nhật hình ảnh thất bại',
      })
    }
  }

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files)
    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    const validFiles = files.filter((file) => allowedFileTypes.includes(file.type))
    if (validFiles.length < files.length) {
      openNotificationWithIcon()
    }
    const validFilesWithIdAndUrl = validFiles.map((file) => ({
      file,
      id: uuidv4(), // Tạo ID duy nhất với uuid
      url: URL.createObjectURL(file), // Tạo URL cho file
      checked: false,
    }))
    fileInputRef.current.value = ''
    updateNewImage(validFilesWithIdAndUrl)
  }
  const handleRemoveFile = async (item) => {
    try {
      await https.delete(
        `BenhNhanTTC/DeleteLinkHinhAnhTiemChung?idbnttc=${activeItemId}&linkHinhAnh=${item}`,
      )
      await reload(activeItemId)
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa hình ảnh thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.reponse?.data?.message || 'Xóa hình ảnh thất bại',
      })
    }
  }
  const handleUpdateImage = () => {
    fileInputRef.current.click()
  }
  return (
    <div className=" h-full">
      {contextHolder}
      <div className="flex flex-wrap gap-2">
        {listImage?.map((item, idx) => (
          <div key={idx} className="border rounded-md w-1/6 min-h-28 overflow-hidden  ">
            <div className="overflow-hidden border-b">
              <Image src={item} />
            </div>
            <div className=" flex justify-around items-center">
              <div>
                <DeleteOutlined
                  onClick={() => handleRemoveFile(item)}
                  style={{ fontSize: 18 }}
                  className="text-red-500 p-1 cursor-pointer hover:bg-red-500 hover:text-white bg-red-100 rounded-md"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2">
        <input
          accept="image/*"
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleUpdateImage} type="primary" ghost icon={<PaperClipOutlined />}>
          Thêm ảnh
        </Button>
      </div>
    </div>
  )
}

export default ImageTiemChung
