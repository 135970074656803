import JsBarcode from 'jsbarcode'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { logoToDataURI } from '../../assets/images/logoNhiBase64'
import { formatNumberVND } from '../../utils/formatNumberVND'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import { generateLogo } from '../algorithm'
import 'moment/locale/vi'
moment.locale('vi')

export const generatePhieuNhapKho = (phieuNhap, khoChiTiet, PkDangNhap) => {
  // const doc = jsPDFCus('l', 'mm', 'a5')
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const ruleWidth = 95
  const pageWidth = doc.internal.pageSize.width

  //header
  // switch (PkDangNhap.idChuyenKhoa) {
  //   case 1:
  //     doc.addImage(logoToDataURI, 'PNG', xPos, 0, 30, 38)
  //     doc.setFont('times', 'bold')
  //     doc.setFontSize(12)
  //     doc.text('HỆ THỐNG Y TẾ NHI ĐỒNG 315', 38, 11)
  //     doc.setFont('times', 'regular')
  //     doc.setFontSize(10)
  //     doc.text(phieuNhap.diaChiNhan ?? '', 38, 16)
  //     doc.text(
  //       `Điện thoại: ${phieuNhap.soDienThoaiNhan ?? '0901.315.315'} - Hotline: 0901.315.315`,
  //       38,
  //       20,
  //     )
  //     doc.text('Website: https://www.nhidong315.com', 38, 25)
  //     break
  //   case 2:
  //     doc.addImage(logoSanToDataURI, 'PNG', xPos - 3, 2, 35, 30)
  //     doc.setFont('times', 'bold')
  //     doc.setFontSize(12)
  //     doc.text('HỆ THỐNG Y TẾ PHỤ SẢN 315', 38, 11)
  //     doc.setFont('times', 'regular')
  //     doc.setFontSize(10)
  //     doc.text(phieuNhap.diaChiNhan ?? '', 38, 16)

  //     doc.text(
  //       `Điện thoại: ${phieuNhap.soDienThoaiNhan ?? '0901.315.315'} - Hotline: 0901.315.315`,
  //       38,
  //       20,
  //     )
  //     doc.text('Website: https://phusan315.com', 38, 25)
  //     break
  //   case 3:
  //     doc.addImage(logoLaoToDataURI, 'PNG', xPos - 2, 0, 18, 26)
  //     doc.setFont('times', 'bold')
  //     doc.setFontSize(12)
  //     doc.text('HỆ THỐNG Y TẾ TIM MẠCH - TIỂU ĐƯỜNG 315', 22, 10)
  //     doc.setFont('times', 'regular')
  //     doc.setFontSize(10)
  //     doc.text(phieuNhap.diaChiNhan ?? '', 22, 15)

  //     doc.text(
  //       `Điện thoại: ${phieuNhap.soDienThoaiNhan ?? '0901.315.315'} - Hotline: 0901.315.315`,
  //       22,
  //       20,
  //     )
  //     doc.text('Website: www.timmachtieuduong315.com', 22, 24)
  //     break

  //   default:
  //     break
  // }

  generateLogo(doc, xPos, PkDangNhap)
  //barcode
  JsBarcode('#barcode', phieuNhap.maPhieu, {
    displayValue: false,
  })

  const canvas = document.getElementById('barcode')
  // Convert canvas to an image
  const barcodeDataURL = canvas.toDataURL()
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFontSize(11)
  doc.text(phieuNhap.maPhieu, pageWidth - 46, 22)
  doc.text(
    `Ngày: ${moment().format('DD')}/${moment().format('MM')}/${moment().format('YYYY')}`,
    pageWidth - 46,
    26,
  )

  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('PHIẾU NHẬP KHO', doc.internal.pageSize.width / 2, 35, { align: 'center' })
  doc.setFontSize(11)
  doc.setFont('times', 'regular')
  doc.text(`Số hóa đơn: ${phieuNhap.soHoaDon ?? ''}`, pageWidth - 60, 35)
  doc.text(
    `Ngày hóa đơn: ${phieuNhap.ngayHoaDon ? moment(phieuNhap.ngayHoaDon).format('DD/MM/YYYY') : ''}`,
    pageWidth - 60,
    40,
  )

  let textDimensions = doc.getTextDimensions('Nhà cung cấp: ')

  if (phieuNhap.doiTac?.tenDoiTac?.length > 70) {
    doc.text('Nhà cung cấp: ', xPos, 45)
    const wordsArray = splitLongWord(phieuNhap.doiTac?.tenDoiTac, 70)
    doc.setFont('times', 'bold')
    doc.setFontSize(12)
    doc.text(wordsArray[0], xPos + textDimensions.w, 50)
    doc.text(wordsArray[1], xPos, 55)
    yPos = 55
  } else {
    doc.text('Nhà cung cấp: ', xPos, 50)
    doc.setFont('times', 'bold')
    doc.setFontSize(12)
    doc.text(phieuNhap.doiTac.tenDoiTac, xPos + textDimensions.w, 50)
    yPos = 50
  }

  doc.setFont('times', 'regular')
  doc.setFontSize(11)
  doc.text(`Mã số thuế: ${phieuNhap.doiTac.maSoThue ?? ''}`, xPos, yPos + 5)
  yPos += 5
  if (phieuNhap.doiTac?.diaChi?.length > ruleWidth) {
    const wordsArray = splitLongWord(phieuNhap.doiTac?.diaChi, ruleWidth)
    doc.text(`Địa chỉ: ${wordsArray[0]}`, xPos, yPos + 5)
    doc.text(wordsArray[1], xPos, yPos + 10)
    yPos += 10
  } else {
    doc.text(`Địa chỉ: ${phieuNhap.doiTac?.diaChi ?? ''}`, xPos, yPos + 5)
    yPos += 5
  }
  doc.text(
    `Số tài khoản: ${phieuNhap.doiTac.taiKhoanNganHang ?? ''} ${phieuNhap.doiTac.tenTaiKhoan ?? ''} ${phieuNhap.doiTac.nganHang ?? ''}`,
    xPos,
    yPos + 5,
  )
  yPos += 5
  doc.text(`Số điện thoại: ${phieuNhap.doiTac.dienThoai ?? ''}`, xPos, yPos + 5)
  yPos += 5
  doc.text(`Ghi chú: ${phieuNhap.ghiChu ?? ''}`, xPos, yPos + 5)
  yPos += 10
  //table dymamic data
  const headers = [
    'STT',
    'Tên Hàng',
    'Số lô',
    'Hạn dùng',
    'ĐVT',
    'SL ',
    'Đơn giá',
    'Thành Tiền',
    'Ghi chú',
  ]

  const cloneDetail = cloneDeep(khoChiTiet)
  const sortedData = cloneDetail.sort((a, b) => {
    const nameA = a.thuocVatTu.tenBietDuoc.toUpperCase() // ignore upper and lowercase
    const nameB = b.thuocVatTu.tenBietDuoc.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0 // names must be equal
  })

  const data = sortedData.map((item, index) => {
    const soLuongChan = Math.floor(item.soLuongLe / item.thuocVatTu?.quyCachDongGoi)
    const soLuongLe = item.soLuongLe - soLuongChan * item.thuocVatTu?.quyCachDongGoi
    let displaySoLuong

    if (soLuongChan > 0 && soLuongLe > 0) {
      displaySoLuong = `${soLuongChan} ${item.thuocVatTu?.donViChan ?? ''} ${soLuongLe} ${item.thuocVatTu?.dvt ?? ''}`
    } else if (soLuongChan > 0) {
      displaySoLuong = `${soLuongChan} ${item.thuocVatTu?.donViChan ?? ''}`
    } else {
      displaySoLuong = `${soLuongLe} ${item.thuocVatTu?.dvt ?? ''}`
    }
    return [
      index + 1,
      item.thuocVatTu.tenBietDuoc,
      item.soLo,
      item.hanDung ? moment(item.hanDung).format('DD/MM/YYYY') : '-',
      item.thuocVatTu.donViChan,
      displaySoLuong,
      formatNumberVND(item.donGiaMua * item.soLuongDongGoi),
      formatNumberVND(item.donGiaMua * item.soLuongLe),
      item.ghiChu ?? '',
    ]
  })
  //portrait
  const columnWidths = [10, 40, 20, 20, 15, 15, 25, 25, 20]

  //landscape
  // const columnWidths = [15, 70, 30, 25, 25 , 20, 30, 30, 35]

  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4], halign: 'center' },
      5: { cellWidth: columnWidths[5] },
      6: { cellWidth: columnWidths[6], halign: 'right' },
      7: { cellWidth: columnWidths[7], halign: 'right' },
      8: { cellWidth: columnWidths[8], halign: 'left' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#000',
      valign: 'middle',
      halign: 'center',
      fontStyle: 'regular',
      fontSize: 10,
    },
    headStyles: {
      fillColor: '#fff',
      textColor: '#000',
      fontStyle: 'bold',
      fontSize: 10,
    },
    alternateRowStyles: {
      fillColor: '#fff',
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  const congTienHang = khoChiTiet.reduce(
    (total, item) => total + item.donGiaMua * item.soLuongLe,
    0,
  )
  const data2Column = [
    ['', ''],
    ['Cộng tiền hàng: ', formatNumberVND(congTienHang)],
  ]
  // Cộng tiền hàng
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    //portrait
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: 145, halign: 'right' },
      1: { cellWidth: 45, halign: 'right', fontStyle: 'bold' },
    },
    //landscape
    // columnStyles: {
    //   0: { cellWidth: 215, halign: 'right' },
    //   1: { cellWidth: 65, halign: 'right', fontStyle: 'bold' },
    // },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 10,
      // Set line color to black for headers and rows
    },

    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',

      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })
  //vat5
  const vat5 = khoChiTiet.reduce((total, item) => total + item.vat5, 0)
  //vat8
  const vat8 = khoChiTiet.reduce((total, item) => total + item.vat8, 0)
  //vat10
  const vat10 = khoChiTiet.reduce((total, item) => total + item.vat10, 0)

  //VAT
  const data3Column = [
    ['', '', ''],
    [' %GTGT: ', '5 %', formatNumberVND(Math.round(vat5))],
    [' %GTGT: ', '8 %', formatNumberVND(Math.round(vat8))],
    [' %GTGT: ', '10 %', formatNumberVND(Math.round(vat10))],
  ]
  doc.autoTable({
    head: [data3Column[0]],
    body: data3Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    //portrait
    columnStyles: {
      0: { cellWidth: 125, halign: 'right' },
      1: { cellWidth: 20, halign: 'right' },
      2: { cellWidth: 45, halign: 'right', fontStyle: 'bold' },
    },
    //landscape
    // columnStyles: {
    //   // Set custom column widths
    //   0: { cellWidth: 200, halign: 'right' },
    //   1: { cellWidth: 15, halign: 'right' },
    //   2: { cellWidth: 65, halign: 'right', fontStyle: 'bold' },
    // },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'regular', // Set font weight to bold for headers
      fontSize: 10,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',

      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })
  const thanhToan = khoChiTiet.reduce((total, item) => total + item.thucTra, 0)
  //Thanh toán
  const dataThanhToanColumn = [
    ['', ''],
    ['Thanh toán: ', formatNumberVND(Math.round(thanhToan))],
  ]
  doc.autoTable({
    head: [dataThanhToanColumn[0]],
    body: dataThanhToanColumn.slice(1),
    startY: doc.autoTable.previous.finalY,
    //portrait
    columnStyles: {
      0: { cellWidth: 145, halign: 'right' },
      1: { cellWidth: 45, halign: 'right', fontStyle: 'bold' },
    },
    //landscape
    // columnStyles: {
    //   0: { cellWidth: 215, halign: 'right' },
    //   1: { cellWidth: 65, halign: 'right', fontStyle: 'bold' },
    // },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'regular', // Set font weight to bold for headers
      fontSize: 10,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',

      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })

  //ky ten
  doc.setFontSize(10)
  const partWidth = pageWidth / 4
  doc.text('Người giao', xPos + 20, doc.autoTable.previous.finalY + 10)
  doc.text('Người nhận', partWidth * 2 - 30, doc.autoTable.previous.finalY + 10)
  doc.text('Thủ kho', partWidth * 3 - 30, doc.autoTable.previous.finalY + 10)
  doc.text('Người lập phiếu', pageWidth - 50, doc.autoTable.previous.finalY + 10)

  // doc.save('phieu-nhap-kho.pdf')

  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
