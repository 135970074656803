import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table, ConfigProvider, Button, DatePicker, Modal, Drawer, Select } from 'antd'
import { FileTextOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import { xuatSuDungService } from '../../../../services/xuatSuDung/xuatSuDungService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import moment from 'moment'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import * as XLSX from 'xlsx'

moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
function ListHangHoa({ keyTabs }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [isLoading, setIsLoading] = useState(false)
  const [idKhoCn, setIdKhoCn] = useState(0)
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listKho, setListKho] = useState([])
  const [listVTHH, setListVTHH] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [idChiNhanh, setIdChiNhanh] = useState(ID_BRANCH)
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getListVTHHDaXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await xuatSuDungService.getListVTHHDaXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        idChiNhanh === 0 ? null : idChiNhanh,
        dateForm,
        dateTo,
      )
      setListVTHH(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (keyTabs === 3) {
      getListVTHHDaXuat()
    }
  }, [keyTabs])

  const exportToExcel = () => {
    // Custom column names
    const headers = [
      'STT',
      'Ngày xuất',
      'Mã phiếu',
      'Tên phiếu',
      'Chi nhánh xuất',
      'Kho xuất',
      'Bộ phận sử dụng',
      'Mã hàng',
      'Tên vật tư hàng hoá',
      'Số lô',
      'Hạn dùng',
      'Số hoá đơn',
      'ĐVT',
      'Số lượng',
      'Ghi chú',
    ]
    const formattedData = listVTHH.map((item, idx) => ({
      STT: idx + 1,
      'Ngày xuất': moment(item.ngayxuat).format('DD/MM/YYYY hh:mm:ss'),
      'Mã phiếu': item.maphieu,
      'Tên phiếu': item.tenphieu,
      'Chi nhánh xuất': item.tenchinhanh,
      'Kho xuất': item.tenkhoxuat,
      'Bộ phận sử dụng': item.tenbophansudung,
      'Mã hàng': item.mathuoc,
      'Tên vật tư hàng hoá': item.tenbietduoc,
      'Số lô': item.solo,
      'Hạn dùng': item.handung ? moment(item.handung).format(dateFormat) : '',
      'Số hoá đơn': item.sohoadon,
      ĐVT: item.dvt,
      'Số lượng': item.soluong,
      'Ghi chú': item.ghichuchitiet,
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, `Hàng hoá xuất sử dụng.xlsx`)
  }

  return (
    <div>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {/* <Select
          className="w-60"
          options={[
            {
              key: 0,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKho?.map((item) => ({
              key: item?.idKhoCN,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả các chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
              }}
            />
          </>
        )}
        <Input
          placeholder="Nhập từ khóa tìm kiếm..."
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListVTHHDaXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
        <Button
          onClick={exportToExcel}
          type="text"
          size="middle"
          className="text-green-700 p-1 ml-auto"
          icon={<ImportExportIcon />}
        >
          Xuất Excel
        </Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ x: 2650, y: 720 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listVTHH}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 45,
              align: 'center',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày xuất',
              dataIndex: 'ngayxuat',
              key: 'ngayxuat',
              width: 140,
              align: 'center',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            },
            {
              title: 'Mã phiếu',
              dataIndex: 'maphieu',
              key: 'maphieu',
              width: 140,
            },
            {
              title: 'Tên phiếu',
              dataIndex: 'tenphieu',
              key: 'tenphieu',
              width: 400,
            },
            {
              title: 'Chi nhánh xuất',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 250,
            },
            {
              title: 'Kho xuất',
              dataIndex: 'tenkhoxuat',
              key: 'tenkhoxuat',
              width: 250,
            },
            {
              title: 'Bộ phận sử dụng',
              dataIndex: 'tenbophansudung',
              key: 'tenbophansudung',
              width: 130,
            },
            {
              title: 'Mã hàng',
              dataIndex: 'mathuoc',
              key: 'mathuoc',
              width: 140,
            },
            {
              title: 'Tên vật tư hàng hóa',
              dataIndex: 'tenbietduoc',
              key: 'tenbietduoc',
            },
            {
              title: 'Số lô',
              dataIndex: 'solo',
              key: 'solo',
              width: 120,
            },
            {
              title: 'Hạn Dùng',
              dataIndex: 'handung',
              key: 'handung',
              align: 'center',
              width: 100,
              render: (text, record, index) => (text ? moment(text).format(dateFormat) : '-'),
            },
            {
              title: 'Số hóa đơn',
              dataIndex: 'sohoadon',
              key: 'sohoadon',
              width: 120,
            },
            {
              title: 'ĐVT',
              dataIndex: 'dvt',
              key: 'dvt',
              width: 60,
              align: 'center',
            },
            // {
            //   title: 'Mã số đăng ký',
            //   dataIndex: 'masodangky',
            //   key: 'masodangky',
            //   width: 120,
            // },
            {
              title: 'Số lượng',
              dataIndex: 'soluong',
              key: 'soluong',
              width: 75,
              align: 'center',
            },
            {
              title: 'Ghi chú',
              dataIndex: 'ghichuchitiet',
              key: 'ghichuchitiet',
              width: 250,
            },
            // {
            //   title: '',
            //   dataIndex: 'action',
            //   key: 'action',
            //   width: 45,
            //   align: 'center',
            // },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListHangHoa
