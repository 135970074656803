import { ConfigProvider, Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { infoBNSieuAm } from '../../../../store/actions/sieuAmAction'
import 'moment/locale/vi'
moment.locale('vi')

const History = () => {
  const { history } = useSelector((state) => state.sieuAmReducer)
  const dispatch = useDispatch()
  //--------- thông tin bệnh nhân chờ siêu âm  -----------//
  const onClickBn = (record) => {
    dispatch(infoBNSieuAm(record.idbnttc, record.idbncdha))
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
        },
      }}
    >
      <Table
        onRow={(record) => ({
          onClick: () => onClickBn(record),
        })}
        bordered
        dataSource={history}
        columns={[
          {
            title: 'STT',
            dataIndex: 'STT',
            key: 'STT',
            width: 40,
            render: (text, record, index) => ++index,
          },
          {
            title: 'Ngày nội soi',
            dataIndex: 'ngaysieuam',
            key: 'ngaysieuam',
            width: 150,
            render: (text, record, index) =>
              text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
          },
          {
            title: 'Chỉ định',
            dataIndex: 'chidinh',
            key: 'chidinh',
          },
          {
            title: 'Bác sĩ nội soi',
            dataIndex: 'bssieuam',
            key: 'bssieuam',
          },
        ]}
      />
    </ConfigProvider>
  )
}

export default History
