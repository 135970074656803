import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  infoBN: null,
  DetailBN: null,
  DetailBNKhamSan: null,
  pending: null,
  loidan: null,
  chanDoan: null,
  ThuocVT: [],
  ThuocBN: [],
  historyKham: [],
  ListBNDangKham: null,
  ListBNDaKham: null,
  DetailToaThuocBN: null,
}

const maternityClinicReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_BN_CHO_KHAM_SAN:
        draft.pending = payload
        break
      case typeAction.DISPATCH_HISTORY_KHAM_PK_SAN:
        draft.historyKham = payload
        break
      case typeAction.DISPATCH_INFO_BN_KHAM_SAN:
        draft.infoBN = payload
        break
      case typeAction.DISPATCH_DETAIL_BN_KHAM_SAN:
        draft.DetailBN = payload
        break
      case typeAction.DISPATCH_DETAIL_LOAIKHAM_BN_KHAM_SAN:
        draft.DetailBNKhamSan = payload
        break
      case typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_SAN:
        draft.DetailToaThuocBN = payload
        break
      case typeAction.DISPATCH_LOI_DAN_SAN:
        draft.loidan = payload
        break
      case typeAction.DISPATCH_CHAN_DOAN_SAN:
        draft.chanDoan = payload
        break
      case typeAction.DISPATCH_THUOC_KE_TOA_DOCTOR_SAN:
        draft.ThuocVT = payload
        break
      case typeAction.DISPATCH_THUOC_BN_DOCTOR_SAN:
        draft.ThuocBN.push(payload)
        break
      case typeAction.DISPATCH_SL_THUOC_BN_SAN:
        draft.ThuocBN[payload.index].soLuong = payload.value
        break
      case typeAction.DISPATCH_LIEU_DUNG_SAN:
        draft.ThuocBN[payload.index].lieuDung = payload.value
        break
      case typeAction.DISPATCH_LAN_DUNG_SAN:
        draft.ThuocBN[payload.index].soLanNgay = payload.value
        break
      case typeAction.DISPATCH_GHI_CHU_TOA_THUOC_BN_SAN:
        draft.ThuocBN[payload.index].ghiChu = payload.value
        break
      case typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN:
        draft.ThuocBN = payload
        break
      case typeAction.DELETE_THUOC_BN_SAN:
        const index = draft.ThuocBN.findIndex((items) => items.ID === payload)
        draft.ThuocBN.splice(index, 1)
        break
      case typeAction.DISPATCH_BN_DANG_KHAM_SAN:
        draft.ListBNDangKham = payload
        break
      case typeAction.DISPATCH_BN_DA_KHAM_SAN:
        draft.ListBNDaKham = payload
        break
      case 'UPDATE_DETAIL_BN':
        return {
          ...state,
          DetailBN: {
            ...state.DetailBN,
            ...payload,
          },
        }
      default:
        return state
    }
  })
}

export default maternityClinicReducer
