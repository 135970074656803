import { https } from '../apiService'

export const cashierService = {
  getPhieuThu: (form, to, idChiNhanhs) =>
    https.get(
      `/BenhNhanThongTinChung/GetDanhSachPhieuDaThu?${idChiNhanhs}&tuNgay=${form}&denNgay=${to}`,
    ),
  getDicVuByBNTTC: (idbnttc) => https.get(`BenhNhanDichVu/GetBenhNhanDichVu/${idbnttc}`),
  getCDHAByBNTTC: (idbnttc) => https.get(`BenhNhanCDHA/GetBenhNhanCDHA/${idbnttc}`),
  getXNByBNTTC: (idbnttc) => https.get(`BenhNhanXetNghiem/GetBenhNhanXetNghiem/${idbnttc}`),
  getInfoPayment: (idbntt) => https.get(`BenhNhanThanhToan/GetBenhNhanThanhToanByID/${idbntt}`),
  getBNChuaThu: (idChiNhanh) =>
    https.get(`BenhNhanThongTinChung/GetDanhSachPhieuChuaThu?chiNhanh=${idChiNhanh}`),
  putBenhNhanThanhToan: (form) => https.put(`BenhNhanThanhToan`, form),
  putBenhNhanhChiTietTT: (form, idbntt) =>
    https.put(`BenhNhanChiTietTT/UpdateBenhNhanChiTietTT/${idbntt}`, form),
  putBenhNhanListDV: (list) => https.put(`BenhNhanDichVu/UpdateBenhNhanDichVuList`, list),
  putBenhNhanListDVMat: (list) => https.put(`BenhNhanDichVu/UpdateBenhNhanDichVuMatList`, list),
  putBenhNhanListXN: (list) => https.put(`BenhNhanXetNghiem/UpdateBenhNhanXetNghiemList`, list),
  putBenhNhanListCDHA: (list) => https.put(`BenhNhanCDHA/UpdateBenhNhanCDHAList`, list),
  postTruTienTheThanhToan: (form) => https.post(`TheBenhNhan/TruTienTheThanhToan`, form),
  putBNCTTTCheckDelete: (idbntt, nguoisua) =>
    https.put(`BenhNhanThanhToan/UpdateCheckdelete?idbntt=${idbntt}&nguoisua=${nguoisua}`),
  getListDangKyKham: (fromDate, toDate, keyword, idChiNhanh, idDoiTac, trangThaiDK) =>
    https.get(`BenhNhanDangKyKham/GetAllLichDangKyKham?fromDate=${fromDate}&toDate=${toDate}${keyword?.length ? `&keyword=${keyword}` : ''}&${idChiNhanh}${idDoiTac ? `&idDoiTac=${idDoiTac}` : ''}${trangThaiDK ? `&trangThaiDK=${trangThaiDK}` : ''}
`),
  updateDV: (data) => https.put('BenhNhanDichVu/UpdateBenhNhanDichVu', data),
  updateXN: (data) => https.put('BenhNhanXetNghiem/UpdateBenhNhanXetNghiemBinhThuong', data),
  updateCDHA: (data) => https.put('BenhNhanCDHA/UpdateBenhNhanCDHABinhThuong', data),
  putNgayHieuLucListVoucher: (list) => https.put('VoucherDanhSach/UpdateNgayHieuLucVoucher', list),
  putServiceByTTV: (idthe, idbnttc) =>
    https.put(`The/UpdateGiamGiaChiDinhDaLuu?idthe=${idthe}&idbnttc=${idbnttc}`),
}
