import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import {
  Tooltip,
  Modal,
  Spin,
  Button,
  Tag,
  Table,
  ConfigProvider,
  Input,
  Space,
  DatePicker,
  Select,
} from 'antd'
import {
  DeleteOutlined,
  FileDoneOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
  SyncOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import {
  deletePhieuNhapKhoAction,
  getInfoPTNhapByIdAction,
  updateXacNhan,
} from '../../../../store/actions/NhapKhoAction'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

const TableList = ({
  handleCancel,
  searchName,
  setSearchName,
  searchMa,
  filteredData,
  showModal,
  filter,
  setSearchMa,
  searchHD,
  setSearchHD,
  setSearchNguoiNhap,
  searchNguoiNhap,
  searchDoiTac,
  setSearchDoiTac,
  setSearchDateHD,
  searchDateHD,
  setSearchKho,
  SearchKho,
  searchBranch,
  setSearchBranch,
  setSearchDateNhap,
  searchDateNhap,
  listHoaDon,
}) => {
  const { isLoading, BranchPT } = useSelector((state) => state.NhapKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  // reverse array
  const dispatch = useDispatch()
  const [reverseData, setReverseData] = useState([])
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm()
  //   setSearchText(selectedKeys[0])
  //   setSearchedColumn(dataIndex)
  // }

  const handleSearch = (name) => (e) => {
    if (e && e.target) {
      const { value } = e.target
      switch (name) {
        case 'maPhieu':
          setSearchMa(value || '')
          break
        case 'tenPhieu':
          setSearchName(value || '')
          break
        case 'soHoaDon':
          setSearchHD(value || '')
          break
        case 'tenNVNhan':
          setSearchNguoiNhap(value || '')
          break
        case 'tenDoiTac':
          setSearchDoiTac(value || '')
          break
        case 'tenKhoNhap':
          setSearchKho(value || '')
          break
        default:
          break
      }
    } else {
      console.error('Event target is undefined')
    }
  }
  const handleDatePickerHD = (date, dateString) => {
    setSearchDateHD(dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
  }
  const handleDatePickerNhap = (date, dateString) => {
    setSearchDateNhap(dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
  }
  const handleSelected = (value) => {
    setSearchBranch(value || '')
  }

  // show delete phiếu thu
  const showDeleteConfirm = (maPhieu, idPhieu) => {
    confirm({
      title: 'Bạn có chắc muốn xoá phiếu nhập kho ?',
      icon: <ExclamationCircleFilled />,
      content: `Phiếu nhập kho muốn xoá là: ${maPhieu}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deletePhieuNhapKhoAction(idPhieu, filter))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }

  const showConfirm = (idNhapXuat, maPhieu) => {
    confirm({
      title: 'Bạn có muốn xác nhận phiếu nhập kho này không ?',
      icon: <ExclamationCircleFilled />,
      content: `Mã phiếu : ${maPhieu}`,
      onOk() {
        dispatch(updateXacNhan(idNhapXuat, filter))
      },
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  useEffect(() => {
    if (filteredData) {
      const sortedData = _.orderBy(filteredData, 'ngayNhan', 'desc')
      console.log()
      setReverseData(sortedData)
    }
  }, [filteredData])
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
          borderRadius: 0,
        },
        components: {
          Table: {
            rowHoverBg: '#ecf0f1',
          },
        },
      }}
    >
      <Table
        loading={isLoading}
        scroll={{ x: 2000, y: 670 }}
        pagination={false}
        bordered
        dataSource={reverseData}
        columns={[
          {
            title: 'STT',
            dataIndex: 'STT',
            key: 'STT',
            width: 40,
            render: (text, record, index) => ++index,
            align: 'center',
          },
          {
            title: 'Mã phiếu',
            dataIndex: 'maPhieu',
            key: 'maPhieu',
            width: 150,
            sorter: (a, b) => compare(a, b, 'maPhieu'),
            showSorterTooltip: false,
            children: [
              {
                title: (
                  <>
                    <Input size="small" value={searchMa} onChange={handleSearch('maPhieu')} />
                  </>
                ),
                dataIndex: 'maPhieu',
                width: 150,
                render: (text, record) => (
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => {
                      dispatch(getInfoPTNhapByIdAction(record.idNhapXuat))
                      showModal()
                    }}
                  >
                    {text}
                  </span>
                ),
              },
            ],
          },
          {
            title: 'Tên phiếu',
            dataIndex: 'tenPhieu',
            key: 'tenPhieu',
            sorter: (a, b) => compare(a, b, 'tenPhieu'),
            showSorterTooltip: false,
            width: 300,
            children: [
              {
                title: (
                  <>
                    <Input size="small" value={searchName} onChange={handleSearch('tenPhieu')} />
                  </>
                ),
                dataIndex: 'tenPhieu',
                width: 300,
              },
            ],
          },
          {
            title: 'Người nhập',
            dataIndex: 'tenNVNhan',
            key: 'tenNVNhan',
            width: 200,
            sorter: (a, b) => compare(a, b, 'tenNVNhan'),
            showSorterTooltip: false,
            children: [
              {
                title: (
                  <>
                    <Input
                      size="small"
                      value={searchNguoiNhap}
                      onChange={handleSearch('tenNVNhan')}
                    />
                  </>
                ),
                dataIndex: 'tenNVNhan',
                width: 200,
              },
            ],
          },
          {
            title: 'Ngày nhập',
            dataIndex: 'ngayNhan',
            key: 'ngayNhan',
            sorter: (a, b) => compare(a, b, 'ngayNhan'),
            showSorterTooltip: false,
            render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            width: 150,
            children: [
              {
                title: (
                  <>
                    <DatePicker
                      format={'DD/MM/YYYY'}
                      size="small"
                      value={searchDateNhap !== '' ? dayjs(searchDateNhap, 'YYYY-MM-DD') : ''}
                      onChange={handleDatePickerNhap}
                    />
                  </>
                ),
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
                dataIndex: 'ngayNhan',
                width: 150,
              },
            ],
          },
          {
            title: 'Chi nhánh',
            dataIndex: 'tenChiNhanhNhan',
            key: 'tenChiNhanhNhan',
            sorter: (a, b) => compare(a, b, 'tenChiNhanhNhan'),
            showSorterTooltip: false,
            width: 250,
            children: [
              {
                title: (
                  <>
                    <Select
                      size="small"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      className="w-full"
                      options={[
                        { label: 'Tất cả', value: '' },
                        ...(BranchPT ?? []).map((item) => ({
                          label: item.tenChiNhanh,
                          value: item.tenChiNhanh,
                        })),
                      ]}
                      value={searchBranch}
                      onChange={handleSelected}
                    />
                  </>
                ),
                dataIndex: 'tenChiNhanhNhan',
                width: 250,
              },
            ],
          },
          {
            title: 'Kho nhập',
            dataIndex: 'tenKhoNhap',
            key: 'tenKhoNhap',
            width: 150,
            sorter: (a, b) => compare(a, b, 'tenKhoNhap'),
            showSorterTooltip: false,
            children: [
              {
                title: (
                  <>
                    <Input size="small" value={SearchKho} onChange={handleSearch('tenKhoNhap')} />
                  </>
                ),
                dataIndex: 'tenKhoNhap',
                width: 150,
              },
            ],
          },
          {
            title: 'Số hoá đơn',
            dataIndex: 'soHoaDon',
            key: 'soHoaDon',
            width: 250,
            children: [
              {
                title: (
                  <>
                    <Select
                      mode="multiple"
                      size="small"
                      value={searchHD}
                      onChange={(value) => setSearchHD(value)}
                      options={listHoaDon.map((hoaDon) => ({
                        label: hoaDon.soHoaDon,
                        value: hoaDon.soHoaDon,
                      }))}
                      style={{
                        width: '100%',
                        height: '24px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </>
                ),
                dataIndex: 'soHoaDon',
                width: 250,
                className: 'h-6',
              },
            ],
          },
          {
            title: 'Ngày hoá đơn',
            dataIndex: 'ngayHoaDon',
            key: 'ngayHoaDon',
            sorter: (a, b) => compare(a, b, 'ngayHoaDon'),
            showSorterTooltip: false,
            width: 130,
            align: 'center',
            children: [
              {
                title: (
                  <>
                    <DatePicker
                      format={'DD/MM/YYYY'}
                      size="small"
                      value={searchDateHD !== '' ? dayjs(searchDateHD, 'YYYY-MM-DD') : ''}
                      onChange={handleDatePickerHD}
                    />
                  </>
                ),
                render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
                dataIndex: 'ngayHoaDon',
                width: 130,
              },
            ],
          },
          {
            title: 'Nhà cung cấp',
            dataIndex: 'tenDoiTac',
            key: 'tenDoiTac',
            width: 400,
            sorter: (a, b) => compare(a, b, 'tenDoiTac'),
            showSorterTooltip: false,
            children: [
              {
                title: (
                  <>
                    <Input size="small" value={searchDoiTac} onChange={handleSearch('tenDoiTac')} />
                  </>
                ),
                dataIndex: 'tenDoiTac',
                width: 400,
              },
            ],
          },
          {
            title: 'Xác nhận kho',
            dataIndex: 'trangThai',
            key: 'trangThai',
            fixed: 'right',
            align: 'center',
            width: 120,
            filters: [
              {
                id: 1,
                value: 3,
                text: 'Đã nhập',
              },
              {
                id: 2,
                value: 5,
                text: 'Nhập kho',
              },
            ],
            onFilter: (value, record) => record.trangThai === value,
            render: (text, record, index) =>
              text === 5 ? (
                <Tag
                  disabled
                  className="cursor-pointer"
                  bordered={infoUser?.idnv === record?.nhanVienNhan || infoUser?.idnv === 1}
                  color={
                    infoUser?.idnv === record?.nhanVienNhan || infoUser?.idnv === 1
                      ? 'processing'
                      : 'default'
                  }
                  onClick={() => {
                    if (infoUser?.idnv === record?.nhanVienNhan || infoUser?.idnv === 1) {
                      showConfirm(record.idNhapXuat, record.maPhieu)
                    }
                  }}
                >
                  Nhập kho
                </Tag>
              ) : (
                <Tag bordered={false} color="success" icon={<CheckCircleOutlined />}>
                  Đã nhập
                </Tag>
              ),
          },
          {
            title: 'Hành động',
            dataIndex: 'trangThai',
            key: 'trangThai',
            fixed: 'right',
            width: 85,

            render: (text, record, index) => (
              <>
                <div className="flex justify-center gap-5">
                  <Tooltip
                    title={
                      text !== 5 ||
                      !(infoUser?.idnv === record?.nhanVienNhan || infoUser?.idnv === 1)
                        ? ''
                        : 'Xoá'
                    }
                    className="cursor-pointer"
                    color="red"
                  >
                    <DeleteOutlined
                      disabled={true}
                      onClick={() => {
                        if (
                          text === 5 &&
                          (infoUser?.idnv === record?.nhanVienNhan || infoUser?.idnv === 1)
                        ) {
                          showDeleteConfirm(record.maPhieu, record.idNhapXuat)
                        }
                      }}
                      className={`${text !== 5 || !(infoUser?.idnv === record?.nhanVienNhan || infoUser?.idnv === 1) ? 'text-gray-400' : ' text-red-500 '} text-xl`}
                    />
                  </Tooltip>
                  <Tooltip color="blue" className="cursor-pointer" title="Xem">
                    <EyeOutlined
                      onClick={() => {
                        dispatch(getInfoPTNhapByIdAction(record.idNhapXuat))
                        showModal()
                      }}
                      className="text-xl text-blue-500"
                    />
                  </Tooltip>
                </div>
              </>
            ),
          },
        ]}
      />
    </ConfigProvider>
  )
}

export default TableList
