import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { generateLogo } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

export const generatePhieuKetQuaXetNghiem = (BNTTC, ketqua, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width
  generateLogo(doc, xPos, PkDangNhap)
  //barcode
  JsBarcode('#barcode', `XN${BNTTC.benhNhan.maBenhNhan}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `XN${BNTTC.benhNhan.maBenhNhan}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(11)
  doc.text(text, textX, 22)

  doc.setFont('times', 'bold')
  doc.setFontSize(14)
  doc.text('KẾT QUẢ XÉT NGHIỆM', doc.internal.pageSize.width / 2, 30, {
    align: 'center',
  })
  doc.setFontSize(12)
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(BNTTC.benhNhan.tenBenhNhan, xPos + textWidthName, 40)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên: ', xPos, 40)
  doc.setFont('times', 'regular')
  doc.text(`Phái: ${BNTTC.benhNhan.gioiTinh}`, pageWidth - 90, 40)
  doc.text(`Ngày sinh: ${moment(BNTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`, pageWidth - 60, 40)
  const diaChiBenhNhan = `Địa chỉ: ${BNTTC.benhNhan.diaChi ?? ''}${BNTTC.benhNhan.tenPhuongXa ? ', ' + BNTTC.benhNhan.tenPhuongXa : ''}${BNTTC.benhNhan.tenQuanHuyen ? ', ' + BNTTC.benhNhan.tenQuanHuyen : ''}${BNTTC.benhNhan.tenTinhTP ? ', ' + BNTTC.benhNhan.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 80) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 80)
    doc.text(wordsArray[0], xPos, 45)
    doc.text(wordsArray[1], xPos, 49)
    yPos = 49
  } else {
    doc.text(diaChiBenhNhan, xPos, 45)
    yPos = 45
  }
  yPos += 5
  doc.text(`Bác sĩ chỉ định: ${BNTTC.bskham ?? ''}`, xPos, yPos)
  doc.text(
    `Giờ lấy mẫu: ${ketqua[0].ngaychidinh ? moment(ketqua[0].ngaychidinh).format('DD/MM/YYYY HH:mm') : ''}`,
    pageWidth - 70,
    yPos,
  )
  yPos += 5
  doc.text(
    `Giờ trả kết quả: ${ketqua[0].ngayketqua ? moment(ketqua[0].ngayketqua).format('DD/MM/YYYY') : ''}`,
    pageWidth - 70,
    yPos,
  )
  if (`Chẩn đoán: ${BNTTC.chandoan ?? ''}`.length > 65) {
    const wordsArray = splitLongWord(`Chẩn đoán: ${BNTTC.chandoan ?? ''}`, 65)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 5
  } else {
    doc.text(`Chẩn đoán: ${BNTTC.chandoan ?? ''}`, xPos, yPos)
  }
  yPos += 5

  //table
  const headers = ['XÉT NGHIỆM', 'KẾT QUẢ', 'ĐƠN VỊ', 'CHỈ SỐ BÌNH THƯỜNG']
  const data = ketqua.map((item, index) => [
    item.tenxn,
    item.ketqua,
    item.dvt,
    item.chisobinhthuong,
    item.soLuong,
  ])
  const columnWidths = [60, 50, 30, 50]
  doc.autoTable({
    head: [headers],
    body: data,
    startY: 60,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2], halign: 'center' },
      3: { cellWidth: columnWidths[3] },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      halign: 'left',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 12,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      halign: 'center',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  //ky ten
  doc.text(
    pageWidth -
      10 -
      `..., ngày ${moment(ketqua[0].ngayketqua).format('DD')} tháng ${moment(ketqua[0].ngayketqua).format('MM')} năm ${moment(ketqua[0].ngayketqua).format('YYYY')}`
        .length *
        2,
    doc.autoTable.previous.finalY + 5,
    `..., ngày ${moment(ketqua[0].ngayketqua).format('DD')} tháng ${moment(ketqua[0].ngayketqua).format('MM')} năm ${moment(ketqua[0].ngayketqua).format('YYYY')}`,
  )

  doc.text(`Kỹ thuật viên thực hiện`, xPos + 30, doc.autoTable.previous.finalY + 10)
  doc.text(`Trưởng phòng xét nghiệm`, pageWidth - 68, doc.autoTable.previous.finalY + 10)

  doc.setFontSize(9)
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD')}/${moment().format('MM')}/${moment().format('YYYY')}`,
    pageWidth - 45,
    doc.internal.pageSize.height - 5,
  )
  doc.setFont('times', 'italic')
  doc.text('* Kết quả chỉ có giá trị trên mẫu xét nghiệm', xPos, doc.internal.pageSize.height - 5)

  // doc.save('phieu-xuat-kho.pdf')

  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
