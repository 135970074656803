import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  infoBNDienTim: null,
  history: [],
}

const dienTimReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_INFO_BN_DIEN_TIM:
        draft.infoBNDienTim = payload
        break
      case typeAction.RESET_BENN_NHAN_DIEN_TIM:
        draft.infoBNDienTim = null
        break
      case typeAction.DISPATCH_HISTORY_BN_DIEN_TIM:
        draft.history = payload
        break
      default:
        return state
    }
  })
}

export default dienTimReducer
