import dayjs from 'dayjs'
import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateCheckbox } from '../algorithm'
import { calculateAgeForReport } from '../calculateAge'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../splitWordForReport'

export const generatePhieuKiemSauTiem = ({ infoBN, infoTDST, listVX, PkDangNhap }) => {
  // console.log(infoBN)
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 10
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //idbnttc
  if (infoBN.benhNhan.maTCQG) {
    JsBarcode('#barcode', `${infoBN.benhNhan.maTCQG}`, {
      displayValue: false,
    })
    const canvas = document.getElementById('barcode')
    const barcodeDataURL = canvas.toDataURL()
    doc.addImage(barcodeDataURL, 'PNG', pageWidth - 60, 5, 40, 6)
  }
  doc.setFont('times', 'bold')
  doc.setFontSize(7)
  doc.text('HỆ THỐNG PHÒNG KHÁM NHI ĐỒNG 315', xPos + 5, yPos)
  yPos += 4
  doc.text('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', pageWidth - 65, yPos)
  doc.setFont('times', 'regular')
  doc.text(`ĐC: ${PkDangNhap.diaChi}`, xPos, yPos)
  yPos += 4
  doc.text('ĐT: 0901 315 315', xPos + 18, yPos)
  doc.setFont('times', 'bold')
  doc.text('Độc lập - Tự do - Hạnh phúc', pageWidth - 55, yPos)
  yPos += 5
  doc.setFontSize(10)
  doc.text('BẢNG KIỂM TIÊM CHỦNG VÀ SAU TIÊM', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  yPos += 4
  doc.text(
    'ĐỐI VỚI ĐỐI TƯỢNG ≥ 1 THÁNG TUỔI TẠI CÁC CƠ SỞ TIÊM CHỦNG NGOÀI',
    doc.internal.pageSize.width / 2,
    yPos,
    {
      align: 'center',
    },
  )
  yPos += 4
  doc.text('BỆNH VIỆN', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  doc.setFontSize(10)
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text(`Họ tên trẻ: ${infoBN?.benhNhan?.tenBenhNhan ?? ''}`, xPos, yPos)
  doc.text('Nam', pageWidth - 40, yPos)
  if (infoBN?.gioiTinh?.includes('Nam') || infoBN?.benhNhan?.gioiTinh?.includes('Nam')) {
    generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, false)
  }
  doc.text('Nữ', pageWidth - 25, yPos)
  if (infoBN?.gioiTinh?.includes('Nữ') || infoBN?.benhNhan?.gioiTinh?.includes('Nữ')) {
    generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, false)
  }
  yPos += 4
  doc.text(
    `Tuổi: ${infoBN?.benhNhan?.ngaySinh && calculateAgeForReport(infoBN?.benhNhan?.ngaySinh)}, sinh ngày ${infoBN?.benhNhan?.ngaySinh && dayjs(infoBN?.benhNhan?.ngaySinh).format('DD')} tháng ${infoBN?.benhNhan?.ngaySinh && dayjs(infoBN?.benhNhan?.ngaySinh).format('MM')} năm ${infoBN?.benhNhan?.ngaySinh && dayjs(infoBN?.benhNhan?.ngaySinh).format('YYYY')}`,
    xPos,
    yPos,
  )
  yPos += 4
  //   const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
  const diaChiBN = `Địa chỉ: ${infoBN?.benhNhan?.diaChi + ', ' ?? ''}${infoBN?.benhNhan?.tenPhuongXa + ', ' ?? ''}${infoBN?.benhNhan?.tenQuanHuyen + ', ' ?? ''}${infoBN?.benhNhan?.tenTinhTP + ', ' ?? ''}`
  if (doc.getTextWidth(diaChiBN) > 100) {
    const wordsArray = splitLongWordFitWidth(doc, diaChiBN, pageWidth - 15)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 4
    }
  } else {
    doc.text(diaChiBN, xPos, yPos)
    yPos += 4
  }
  doc.text(`Họ tên bố/mẹ: ${infoBN?.tennguoinha ?? ''}`, xPos, yPos)
  doc.text(`Điện thoại: ${infoBN?.dienthoainguoinha ?? ''}`, pageWidth - 60, yPos)
  yPos += 4
  doc.text(`Cân nặng: ${infoBN?.cannang ?? '...'} kg`, xPos, yPos)
  doc.text(`Nhiệt độ: ${infoBN?.nhietdo ?? '...'} °C`, xPos + 35, yPos)
  yPos += 4
  doc.setFont('times', 'bold')
  doc.text('I. Khám sàng lọc', xPos, yPos)
  yPos += 4
  doc.setFont('times', 'regular')
  doc.text('Loại vắc xin tiêm chủng lần này:', xPos, yPos)
  yPos += 2
  const headers = [
    'Tên vắc xin',
    'Tên NSX',
    'Số lô',
    'Số ĐKLH/số GPNK',
    'Hạn dùng',
    'Vị trí tiêm',
    'Liều thứ mấy',
    'Đường tiêm',
  ]
  const data = listVX
  const columnWidths = [25, 20, 15, 15, 20, 15, 8, 15]
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4] },
      5: { cellWidth: columnWidths[5] },
      6: { cellWidth: columnWidths[6] },
      7: { cellWidth: columnWidths[7] },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#000',
      valign: 'middle',
      halign: 'left',
      fontStyle: 'regular',
      fontSize: 7,
      cellPadding: 0.5,
    },
    headStyles: {
      fillColor: '#fff',
      textColor: '#000',
      fontStyle: 'bold',
      halign: 'center',
      fontSize: 7,
      cellPadding: 0.5,
    },
    alternateRowStyles: {
      fillColor: '#fff',
    },
    showHead: 'firstPage',
    margin: xPos,
  })
  yPos = doc.autoTable.previous.finalY
  yPos += 4
  doc.text(
    `Thời gian hoàn tất tiêm: ${infoTDST?.giotiem ? 'Hồi ' + dayjs(infoTDST?.giotiem).format('HH giờ mm') + ' phút' : ''}${infoTDST?.ngaytiem ? ', ngày ' + dayjs(infoTDST?.ngaytiem).format('DD') + ' tháng ' + dayjs(infoTDST?.ngaytiem).format('MM') + ' năm ' + dayjs(infoTDST?.ngaytiem).format('YYYY') : ''}`,
    xPos,
    yPos,
  )
  yPos += 4
  doc.setFont('times', 'bold')
  doc.text('II. Theo dõi sau tiêm: ', xPos, yPos)
  yPos += 4
  doc.setFont('times', 'regular')
  doc.text(
    `Thời gian kiểm tra: ${infoTDST?.giokiemtra ? 'Hồi ' + dayjs(infoTDST?.giokiemtra).format('HH giờ mm') + ' phút' : ''}${infoTDST?.ngaykiemtra ? ', ngày ' + dayjs(infoTDST?.ngaykiemtra).format('DD') + ' tháng ' + dayjs(infoTDST?.ngaykiemtra).format('MM') + ' năm ' + dayjs(infoTDST?.ngaykiemtra).format('YYYY') : ''}`,
    xPos,
    yPos,
  )
  //  doc.text(`Thời gian kiểm tra: ${infoTDST?.giokiemtra ? dayjs(infoTDST?.giokiemtra).format('HH:mm') : ''} - ${infoTDST?.ngaykiemtra ? dayjs(infoTDST?.ngaykiemtra).format('DD/MM/YYYY') : ''}`, xPos, yPos)
  doc.text(`Nhiệt độ: ${infoTDST?.nhietdo ?? '...'} °C`, pageWidth - 40, yPos)
  doc.setFontSize(9)
  yPos += 2
  //add new page for table Theo dõi sau tiêm
  doc.line(xPos, yPos, pageWidth - 5, yPos)
  doc.setFont('times', 'bold')
  doc.text('Dấu hiệu', xPos + 2, yPos + 6)
  doc.text('Bình thường', xPos + 20.5, yPos + 6)
  doc.text('Bất thường ', pageWidth - 65, yPos + 6)
  doc.setFont('times', 'italic')
  doc.text('(mô tả nếu có)', pageWidth - 46, yPos + 6)

  doc.setFont('times', 'regular')
  doc.line(xPos, yPos + 8, pageWidth - 5, yPos + 8)
  doc.text('Toàn trạng', xPos, yPos + 14)
  generateCheckbox(doc, xPos + 26, yPos + 10.5, 4, 4, infoTDST?.toantrang === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 10.5, 4, 4, infoTDST?.toantrang === 1 ? true : false)

  const toanTrang = `${infoTDST?.toantrangghichu ?? ''}`
  if (doc.getTextWidth(toanTrang) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 10.5
    const wordsArray = splitLongWordFitWidth(doc, toanTrang, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.toantrangghichu && infoTDST?.toantrang === 1
        ? infoTDST?.toantrangghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 14,
    )
  }

  doc.line(xPos, yPos + 16, pageWidth - 5, yPos + 16)
  doc.text('Tri giác', xPos, yPos + 22)
  generateCheckbox(doc, xPos + 26, yPos + 18.5, 4, 4, infoTDST?.trigiac === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 18.5, 4, 4, infoTDST?.trigiac === 1 ? true : false)

  const trigiacghichu = `${infoTDST?.trigiacghichu ?? ''}`
  if (doc.getTextWidth(trigiacghichu) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 18.5
    const wordsArray = splitLongWordFitWidth(doc, trigiacghichu, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.trigiacghichu && infoTDST?.trigiac === 1
        ? infoTDST?.trigiacghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 22,
    )
  }

  doc.line(xPos, yPos + 24, pageWidth - 5, yPos + 24)
  doc.text('Mạch', xPos, yPos + 30)
  generateCheckbox(doc, xPos + 26, yPos + 26.5, 4, 4, infoTDST?.mach === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 26.5, 4, 4, infoTDST?.mach === 1 ? true : false)

  const mach = `${infoTDST?.machghichu ?? ''}`
  if (doc.getTextWidth(mach) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 26.5
    const wordsArray = splitLongWordFitWidth(doc, mach, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.machghichu && infoTDST?.mach === 1
        ? infoTDST?.machghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 30,
    )
  }

  doc.line(xPos, yPos + 32, pageWidth - 5, yPos + 32)
  doc.text('Nhịp thở', xPos, yPos + 38)
  generateCheckbox(doc, xPos + 26, yPos + 34.5, 4, 4, infoTDST?.nhiptho === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 34.5, 4, 4, infoTDST?.nhiptho === 1 ? true : false)

  const nhipTho = `${infoTDST?.nhipthoghichu ?? ''}`
  if (doc.getTextWidth(nhipTho) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 34.5
    const wordsArray = splitLongWordFitWidth(doc, nhipTho, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.nhipthoghichu && infoTDST?.nhiptho === 1
        ? infoTDST?.nhipthoghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 38,
    )
  }

  doc.line(xPos, yPos + 40, pageWidth - 5, yPos + 40)
  doc.text('Da niêm', xPos, yPos + 46)
  generateCheckbox(doc, xPos + 26, yPos + 42.5, 4, 4, infoTDST?.daniem === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 42.5, 4, 4, infoTDST?.daniem === 1 ? true : false)
  const daNiem = `${infoTDST?.daniemghichu ?? ''}`
  if (doc.getTextWidth(daNiem) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 42.5
    const wordsArray = splitLongWordFitWidth(doc, daNiem, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.daniemghichu && infoTDST?.daniem === 1
        ? infoTDST?.daniemghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 46,
    )
  }

  doc.line(xPos, yPos + 48, pageWidth - 5, yPos + 48)
  doc.text('Vị trí tiêm', xPos, yPos + 54)
  generateCheckbox(doc, xPos + 26, yPos + 50.5, 4, 4, infoTDST?.daniem === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 50.5, 4, 4, infoTDST?.daniem === 1 ? true : false)
  const vitritiemghichu = `${infoTDST?.vitritiemghichu ?? ''}`
  if (doc.getTextWidth(vitritiemghichu) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 50.5
    const wordsArray = splitLongWordFitWidth(doc, vitritiemghichu, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.vitritiemghichu && infoTDST?.vitritiem === 1
        ? infoTDST?.vitritiemghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 54,
    )
  }

  doc.line(xPos, yPos + 56, pageWidth - 5, yPos + 56)
  doc.text('Dấu hiệu khác', xPos, yPos + 62)
  generateCheckbox(doc, xPos + 26, yPos + 58.5, 4, 4, infoTDST?.dauhieukhac === 0 ? true : false)
  generateCheckbox(doc, xPos + 42, yPos + 58.5, 4, 4, infoTDST?.dauhieukhac === 1 ? true : false)
  const dauhieukhac = `${infoTDST?.dauhieukhacghichu ?? ''}`
  if (doc.getTextWidth(dauhieukhac) > 80) {
    doc.setFontSize(7.5)
    let tempYpos = yPos + 58.5
    const wordsArray = splitLongWordFitWidth(doc, dauhieukhac, 80)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos + 48, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(9)
  } else {
    doc.text(
      infoTDST?.dauhieukhacghichu && infoTDST?.dauhieukhac === 1
        ? infoTDST?.dauhieukhacghichu
        : '........................................................................................',
      xPos + 48,
      yPos + 62,
    )
  }

  doc.line(xPos, yPos + 64, pageWidth - 5, yPos + 64)

  doc.line(xPos, yPos, xPos, yPos + 64)
  doc.line(xPos + 19, yPos, xPos + 19, yPos + 64)
  doc.line(xPos + 38, yPos, xPos + 38, yPos + 64)
  doc.line(pageWidth - 5, yPos, pageWidth - 5, yPos + 64)

  yPos = yPos + 64
  yPos += 4
  console.log(yPos);
  if(yPos > 190) {
    doc.addPage()
    yPos = 10
  }
  const xuTriSauTiem = `Xử trí sau tiêm (nếu có): ${infoTDST?.xutri ?? ''}`
  if (doc.getTextWidth(xuTriSauTiem) > 100) {
    const wordsArray = splitLongWordFitWidth(doc, xuTriSauTiem, pageWidth - 10)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 3.5
    }
  } else {
    doc.text(xuTriSauTiem, xPos, yPos)
    yPos += 3.5
  }
  doc.setFont('times', 'italic')
  doc.setFontSize(7)
  const hoi = `Hồi ${moment().format('HH')} giờ ${moment().format('mm')} phút,ngày ${moment().format('DD')} tháng ${moment().format('MM')} năm ${moment().format('YYYY')}`
  doc.text(hoi, xPos - 6, yPos)
  doc.text(hoi, xPos + 42, yPos)
  doc.text(hoi, pageWidth - 48, yPos)
  yPos += 4
  doc.setFont('times', 'regular')
  doc.setFontSize(8)
  doc.text('Người đồng ý tiêm chủng', xPos - 2, yPos)
  doc.text('Bác sĩ kiểm tra sau tiêm', xPos + 48, yPos)
  doc.text('Điều dưỡng thực hiện tiêm', pageWidth - 44, yPos)
  yPos += 4
  doc.text('(Ký, ghi rõ họ và tên)', xPos, yPos)
  doc.text('(Ký, ghi rõ họ và tên)', xPos + 50, yPos)
  doc.text('(Ký, ghi rõ họ và tên)', pageWidth - 42, yPos)

  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
