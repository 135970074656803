import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { columns } from './Columns'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../../store/actions/BranchAction'
import * as typeAction from '../../../../../store/constants/constants'
import { reportService } from '../../../../../services/Report/reportService'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const KhoaPhong = () => {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getServiceKhoPhong(
          company,
          date.form,
          date.to,
          idChiNhanhs,
        )
        setData(data)
      } else {
        const { data } = await reportService.getServiceKhoPhong(company, date.form, date.to, '')
        setData(data)
      }
      closeLoading()
    } catch (error) {
      console.log(error)
      closeLoading()
    } finally {
      closeLoading()
    }
  }
  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue([])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = data?.map((item) => ({
      Ngày: moment(item.ngay).format('DD/MM/YYYY'),
      'Ngày khai trương': moment(item.ngayKhaiTruong).format('DD/MM/YYYY'),
      'Chi nhánh': item.tenChiNhanh,
      'Khám nhi': formatNumberVND(item.khamNhi),
      'Tiêm chủng': formatNumberVND(item.tiemChung),
      'Sản khoa': formatNumberVND(item.sanKhoa),
      'Phụ khoa': formatNumberVND(item.phuKhoa),
      'Đa khoa': formatNumberVND(item.daKhoa),
      'Lão khoa': formatNumberVND(item.laoKhoa),
      'Phục hồi chức năng': formatNumberVND(item.phcn),
      'Nhãn khoa': formatNumberVND(item.nhanKhoa),
      'Khúc xạ': formatNumberVND(item.khucXa),
      'Khí dung': formatNumberVND(item.khiDung),
      'Thủ thuật': formatNumberVND(item.thuThuat),
      'Xét nghiệm': formatNumberVND(item.xetNghiem),
      'Siêu âm': formatNumberVND(item.sieuAm),
      'Soi CTC': formatNumberVND(item.soiCTC),
      'Điện tim': formatNumberVND(item.dienTim),
      monitor: formatNumberVND(item.monitor),
      'Nội soi TH': formatNumberVND(item.noiSoiTH),
      'Nội soi TMH': formatNumberVND(item.noiSoiTMH),
      'X-Quang': formatNumberVND(item.xQuang),
      khác: formatNumberVND(item.khac),
      'Tổng tiền': formatNumberVND(item.tongTien),
    }))
    const name = 'Báo cáo nhóm dịch vụ khoa phòng'
    exportExcelformat(formatData, name)
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchReport()
  }, [])

  // validate
  const valieDateSelect = infoUser?.idNhom === 3
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        <Select
          showSearch
          value={company}
          onChange={onChangeCompany}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listCompany?.map(({ idct, tenct }) => ({
            label: tenct,
            value: idct,
          }))}
          className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}
        />
        <div className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}>
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className={`w-full h-8 overflow-clip `}
          />
        </div>
        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton onClick={fetchReport} color="primary" aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
        }}
      >
        <Table
          loading={isLoadingTable}
          bordered
          pagination={false}
          scroll={{
            x: 2000,
            y: 580,
          }}
          dataSource={data}
          columns={columns}
          summary={(pageData) => {
            const khamNhi = pageData.reduce((sum, record) => sum + (record.khamNhi || 0), 0)
            const tiemChung = pageData.reduce((sum, record) => sum + (record.tiemChung || 0), 0)
            const sanKhoa = pageData.reduce((sum, record) => sum + (record.sanKhoa || 0), 0)
            const phuKhoa = pageData.reduce((sum, record) => sum + (record.phuKhoa || 0), 0)
            const daKhoa = pageData.reduce((sum, record) => sum + (record.daKhoa || 0), 0)
            const laoKhoa = pageData.reduce((sum, record) => sum + (record.laoKhoa || 0), 0)
            const phcn = pageData.reduce((sum, record) => sum + (record.phcn || 0), 0)
            const nhanKhoa = pageData.reduce((sum, record) => sum + (record.nhanKhoa || 0), 0)
            const khucXa = pageData.reduce((sum, record) => sum + (record.khucXa || 0), 0)
            const khiDung = pageData.reduce((sum, record) => sum + (record.khiDung || 0), 0)
            const thuThuat = pageData.reduce((sum, record) => sum + (record.thuThuat || 0), 0)
            const xetNghiem = pageData.reduce((sum, record) => sum + (record.xetNghiem || 0), 0)
            const sieuAm = pageData.reduce((sum, record) => sum + (record.sieuAm || 0), 0)
            const soiCTC = pageData.reduce((sum, record) => sum + (record.soiCTC || 0), 0)
            const dienTim = pageData.reduce((sum, record) => sum + (record.dienTim || 0), 0)
            const monitor = pageData.reduce((sum, record) => sum + (record.monitor || 0), 0)
            const noiSoiTH = pageData.reduce((sum, record) => sum + (record.noiSoiTH || 0), 0)
            const noiSoiTMH = pageData.reduce((sum, record) => sum + (record.noiSoiTMH || 0), 0)
            const xQuang = pageData.reduce((sum, record) => sum + (record.xQuang || 0), 0)
            const khac = pageData.reduce((sum, record) => sum + (record.khac || 0), 0)
            const tongTien = pageData.reduce((sum, record) => sum + (record.tongTien || 0), 0)

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {Array.from({ length: 24 }, (_, index) => (
                    <Table.Summary.Cell key={index} index={index} align="right">
                      {index === 3 && (
                        <Typography.Text type="danger">{formatNumberVND(khamNhi)}</Typography.Text>
                      )}
                      {index === 4 && (
                        <Typography.Text type="danger">
                          {formatNumberVND(tiemChung)}
                        </Typography.Text>
                      )}
                      {index === 5 && (
                        <Typography.Text type="danger">{formatNumberVND(sanKhoa)}</Typography.Text>
                      )}
                      {index === 6 && (
                        <Typography.Text type="danger">{formatNumberVND(phuKhoa)}</Typography.Text>
                      )}
                      {index === 7 && (
                        <Typography.Text type="danger">{formatNumberVND(daKhoa)}</Typography.Text>
                      )}
                      {index === 8 && (
                        <Typography.Text type="danger">{formatNumberVND(laoKhoa)}</Typography.Text>
                      )}{' '}
                      {index === 9 && (
                        <Typography.Text type="danger">{formatNumberVND(phcn)}</Typography.Text>
                      )}
                      {index === 10 && (
                        <Typography.Text type="danger">{formatNumberVND(nhanKhoa)}</Typography.Text>
                      )}
                      {index === 11 && (
                        <Typography.Text type="danger">{formatNumberVND(khucXa)}</Typography.Text>
                      )}
                      {index === 12 && (
                        <Typography.Text type="danger">{formatNumberVND(khiDung)}</Typography.Text>
                      )}
                      {index === 13 && (
                        <Typography.Text type="danger">{formatNumberVND(thuThuat)}</Typography.Text>
                      )}
                      {index === 14 && (
                        <Typography.Text type="danger">
                          {formatNumberVND(xetNghiem)}
                        </Typography.Text>
                      )}
                      {index === 15 && (
                        <Typography.Text type="danger">{formatNumberVND(sieuAm)}</Typography.Text>
                      )}
                      {index === 16 && (
                        <Typography.Text type="danger">{formatNumberVND(soiCTC)}</Typography.Text>
                      )}
                      {index === 17 && (
                        <Typography.Text type="danger">{formatNumberVND(dienTim)}</Typography.Text>
                      )}
                      {index === 18 && (
                        <Typography.Text type="danger">{formatNumberVND(monitor)}</Typography.Text>
                      )}
                      {index === 19 && (
                        <Typography.Text type="danger">{formatNumberVND(noiSoiTH)}</Typography.Text>
                      )}
                      {index === 20 && (
                        <Typography.Text type="danger">
                          {formatNumberVND(noiSoiTMH)}
                        </Typography.Text>
                      )}
                      {index === 21 && (
                        <Typography.Text type="danger">{formatNumberVND(xQuang)}</Typography.Text>
                      )}
                      {index === 22 && (
                        <Typography.Text type="danger">{formatNumberVND(khac)}</Typography.Text>
                      )}
                      {index === 23 && (
                        <Typography.Text type="danger">{formatNumberVND(tongTien)}</Typography.Text>
                      )}
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      </ConfigProvider>
    </>
  )
}

export default KhoaPhong
