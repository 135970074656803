import {  Tabs } from 'antd'
import React, { useState } from 'react'
import Todo from './Todo/Todo'
import Completed from './Completed/Completed'
import DetailBaoHong from './DetailBaoHong'
import Thongke from './Thongke/Thongke'
const SuCo = () => {
  const [isOpen,setIsOpen] = useState(false)
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="p-2 bg-white h-full rounded-lg border">
      <Tabs
          items={[
            {
              label: 'Sự cố',
              key: 1,
              children: <Todo setIsOpen={setIsOpen}/>,
            },
            {
              label: 'Hoàn thành',
              key: 2,
              children: <Completed setIsOpen={setIsOpen}/>,
            },
            {
              label: 'Danh sách',
              key: 3,
              children: <Thongke setIsOpen={setIsOpen}/>,
            },
          ]}
        />
      </div>
      <DetailBaoHong isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}

export default SuCo
