import React, { useEffect, useState } from 'react'
import { Tabs, Button, Segmented, Checkbox, Input, DatePicker } from 'antd'
import FormCD from './FormCD/FormCD'
import FormCLS from '../../../ChildrenClinic/General/Tab/FormCLS/FormCLS'
import FormKQXN from '../../../ChildrenClinic/General/Tab/FormKQXN/FormKQXN'
import FormKQCDHA from '../../../MaternityClinic/General/Tab/FormKQCDHA/FormKQCDHA'

// import FormCLS from './FormCLS/FormCLS'
// import FormKQXN from './FormKQXN/FormKQXN'
// import FormKQCDHA from './FormKQCDHA/FormKQCDHA'

import { useFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { updateBnDetail } from '../../../../../store/actions/childrenClinicAction'
import { postInfoKSKAction } from '../../../../../store/actions/khamSucKhoeAction'
import { khamSucKhoeSchema } from '../../../../../schemas/khamSucKhoeSchema'
import { ChildrenClinicSchema } from '../../../../../schemas/ChilrenClinicSchema'
import HistoryTongQuat from '../../../ChildrenClinic/General/Tab/HistoryTongQuat/HistoryTongQuat'
import * as typeAction from '../../../../../store/constants/constants'
import 'moment/locale/vi'
moment.locale('vi')
const tab_CD = 'Chẩn đoán'
const tab_CLS = 'Chỉ định cận lâm sàng'
const tab_KQXN = 'Kết quả xét nghiệm'
const tab_KQCDHA = 'Kết quả CĐHA'
const tab_LSTQ = 'Lịch sử tổng quát'

const TabDiagnostic = ({ defaultValuePK }) => {
  const now = moment()
  const SO_NGAY_TAI_KHAM = 0
  const initialStartDate = now.add(SO_NGAY_TAI_KHAM, 'days').format('YYYY-MM-DD')
  const { coKeToa } = useSelector((state) => state.khamSucKhoeReducer)
  const { DetailBN, ThuocBN, DetailToaThuocBN, infoBN, historyKham, NgayThuoc } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const [date, setDate] = useState(initialStartDate)
  const [count, setCount] = useState(SO_NGAY_TAI_KHAM)
  const [checkBox, setCheckBox] = useState(false)
  const [segmentedValue, setSegmentedValue] = useState(tab_CD)
  const [isPrintToaThuocKhiLuu, setIsPrintToaThuocKhiLuu] = useState(false)
  const dispatch = useDispatch()

  const formikKSK = useFormik({
    enableReinitialize: true,
    initialValues: {
      //idbnksl: 0,
      idbnttc: DetailBN?.idbnttc,
      bieumau: null,
      ghichu: '',
      nguoitao: infoUser?.idnv,
      //ngaytao: "2024-08-08T07:03:21.166Z"
    },
    // onSubmit: (values) => formik.setFieldValue('formPhuSan', values),
    validationSchema: khamSucKhoeSchema,
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      ngaytaikham: DetailBN?.ngaytaikham ?? (coKeToa === 1 ? null : moment().format('YYYY-MM-DD')),
      trangthaikhambenh: 2,
      bskham: DetailBN?.bskham,
      lydokham: DetailBN?.lydokham,
      benhsu: DetailBN?.benhsu,
      trieuchung: DetailBN?.trieuchung,
      idchandoan: DetailBN?.idchandoan,
      chandoan: DetailBN?.chandoan,
      idloidan: DetailBN?.idloidan,
      loidan: DetailBN?.loidan,
      tiensubanthan: DetailBN?.tiensubanthan,
      diung: DetailBN?.diung,
      quatrinhbenhly: DetailBN?.quatrinhbenhly,
      tieusugiadinh: DetailBN?.tieusugiadinh,
      toanthan: DetailBN?.toanthan,
      cacbophan: DetailBN?.cacbophan,
      chandoanbandau: DetailBN?.chandoanbandau,
      idicD10BANDAU: DetailBN?.idicD10BANDAU,
      daxuly: DetailBN?.daxuly,
      chandoanravien: DetailBN?.chandoanravien,
      idicD10RAVIEN: DetailBN?.idicD10RAVIEN,
      dieutringoaitru: DetailBN?.dieutringoaitru,
      dienbienlamsang: DetailBN?.dienbienlamsang,
      benhkemtheo: DetailBN?.benhkemtheo,
      idicD10BENHKEMTHEO: DetailBN?.idicD10BENHKEMTHEO,
      ppdieutri: DetailBN?.ppdieutri,
      ttlucravien: DetailBN?.ttlucravien,
      huongxuly: DetailBN?.huongxuly,
      lydokolaythuoc: DetailBN?.lydokolaythuoc ?? (coKeToa !== 1 && 11),
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: ChildrenClinicSchema,
  })
  const handleSegmentedChange = (value) => {
    setSegmentedValue(value)
  }

  const handleSubmit = (value) => {
    try {
      value.idbskham = infoUser?.idnv
      value.bskham = infoUser?.tenNV
      value.ngaytaikham = moment(date).format('YYYY-MM-DD')
      const newArr = []
      if (!checkBox && ThuocBN?.length > 0) {
        // lấy thuốc
        for (let item of ThuocBN) {
          // const { ton, ...other } = item
          // other.ngayKeDon = now.format('YYYY-MM-DDTHH:mm:ss')
          // other.idbnttc = DetailBN?.idbnttc
          // newArr.push(other)

          newArr.push({
            idbnttc: DetailBN?.idbnttc,
            idthuoc: item?.idthuoc,
            tenBietDuoc: item?.tenBietDuoc,
            tenHoatChat: item?.tenhoatchat,
            duongDung: item?.duongDung,
            dvt: item?.dvt,
            hamLuong: item?.hamLuong,
            ghiChu: item?.ghiChu,
            lieuDung: `${item?.lieuDung ?? 0}`,
            soLanNgay: `${item?.soLanNgay ?? 0}`,
            bsKeDon: infoUser?.tenNV,
            donViDung: item?.donViDung,
            donGia: item?.giaBan,
            isGongKinh: item?.isGongKinh,
            soLuong: item?.soLuong,
            idkhocn: item?.idkhocn,
          })
        }
      } else value.trangthaikhambenh = 4 // không lấy thuốc
      dispatch(updateBnDetail(infoBN, value, defaultValuePK, newArr, isPrintToaThuocKhiLuu))
      dispatch(postInfoKSKAction(formikKSK.values))
      formik.resetForm()
      formikKSK.resetForm()
    } catch (error) {
      console.log(error)
    }
  }
  // useEffect(() => {
  //   const today = moment().startOf('day')
  //   if (DetailBN?.ngaytaikham) {
  //     const startDate = moment(DetailBN.ngaykham).startOf('day')
  //     const endDate = moment(DetailBN.ngaytaikham).startOf('day')
  //     const diffDays = endDate.diff(startDate, 'days')
  //     setCount(diffDays)
  //   } else {
  //     const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
  //     const diffDays = selectedDate.diff(today, 'days')
  //     setCount(diffDays)
  //   }
  //   if (formik.values.ngaytaikham === null) setCount(0)
  // }, [date, DetailBN])
  useEffect(() => {
    const today = moment().startOf('day')
    if (formik.values.ngaytaikham) {
      const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
      const diffDays = selectedDate.diff(today, 'days')
      setCount(diffDays)
    } else {
      setCount(0)
    }
  }, [date])
  useEffect(() => {
    const today = moment().startOf('day')
    if (DetailBN?.ngaytaikham) {
      const startDate = moment(DetailBN.ngaykham).startOf('day')
      const endDate = moment(DetailBN.ngaytaikham).startOf('day')
      const diffDays = endDate.diff(startDate, 'days')
      setCount(diffDays)
    } else {
      setCount(0)
    }
  }, [DetailBN?.ngaytaikham])
  //   const selectedDate = moment(date).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  // }, [date])
  useEffect(() => {
    if (NgayThuoc !== count) {
      dispatch({
        type: typeAction.NGAY_THUOC_NHI,
        payload: count,
      })
    }
  }, [count])
  useEffect(() => {
    setIsPrintToaThuocKhiLuu(false)
    setCheckBox(
      (infoBN && !DetailToaThuocBN?.DanhSachThuoc?.length && DetailBN?.lydokolaythuoc) ||
        coKeToa !== 1
        ? true
        : false,
    )
    // if (coKeToa === 0) {
    //   setCount(0)
    //   formik.setFieldValue('ngaytaikham', moment().format('YYYY-MM-DD'))
    //   formik.setFieldValue('lydokolaythuoc', 11)
    //   // formik.setFieldError('ngaytaikham', '')
    //   formik.setFieldError(null)
    // }
  }, [DetailBN])
  return (
    <div className="mt-3 px-2">
      <div className="flex justify-between">
        <Segmented
          options={[tab_CD, tab_LSTQ, tab_CLS, tab_KQXN, tab_KQCDHA]}
          value={segmentedValue}
          onChange={handleSegmentedChange}
        />
      </div>
      <div>
        {segmentedValue === tab_CD ? (
          <FormCD
            checkBox={checkBox}
            setCheckBox={setCheckBox}
            formik={formik}
            setDate={setDate}
            date={date}
            count={count}
            formikKSK={formikKSK}
            setCount={setCount}
            isPrintToaThuocKhiLuu={isPrintToaThuocKhiLuu}
            setIsPrintToaThuocKhiLuu={setIsPrintToaThuocKhiLuu}
          />
        ) : segmentedValue === tab_LSTQ ? (
          <HistoryTongQuat historyKham={historyKham} />
        ) : segmentedValue === tab_KQXN ? (
          <FormKQXN />
        ) : segmentedValue === tab_CLS ? (
          <FormCLS formik={formik} />
        ) : (
          <FormKQCDHA />
        )}

        {/* // : segmentedValue === tab_CLS ? (
        //   <FormCLS formik={formik} />
        // ) : segmentedValue === tab_KQXN ? (
        //   <FormKQXN />
        // ) : (
        //   <FormKQCDHA />
        // )}{' '} */}
      </div>
    </div>
  )
}

export default TabDiagnostic
