import {
  Button,
  ConfigProvider,
  DatePicker,
  Checkbox,
  Input,
  Select,
  Table,
  Divider,
  Collapse,
} from 'antd'
import dayjs from 'dayjs'
import { SearchOutlined } from '@ant-design/icons'
import _, { set } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { generatePhieuKhamKhucXa } from '../../../../../../utils/report/mat/phieuKhamKhucXa'
import { https } from '../../../../../../services/apiService'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const { TextArea } = Input
const FormDKX = ({ formik, bsmat }) => {
  const dispatch = useDispatch()
  const { infoBN, DetailBN } = useSelector((state) => state.childrenClinicReducer)
  const { InfoBNDoKhucXa } = useSelector((state) => state.doKhucXaReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const handleCapNhat = () => {
    formik?.setFieldValue('action', 'capnhat')
    formik?.handleSubmit()
  }
  const handleLuuVaIn = () => {
    formik?.setFieldValue('action', 'luuvain')
    formik?.handleSubmit()
  }
  const handlePrint = async () => {
    const formatDataDoKhucXa = {
      nguoikham: InfoBNDoKhucXa?.NGUOIKHAM,
      thiluckhongkinhxA_MP: InfoBNDoKhucXa?.THILUCKHONGKINHXA_MP,
      thiluckhongkinhxA_MT: InfoBNDoKhucXa?.THILUCKHONGKINHXA_MT,
      thiluckhongkinhxA_2M: InfoBNDoKhucXa?.THILUCKHONGKINHXA_2M,
      thiluckhongkinhgaN_MP: InfoBNDoKhucXa?.THILUCKHONGKINHGAN_MP,
      thiluckhongkinhgaN_MT: InfoBNDoKhucXa?.THILUCKHONGKINHGAN_MT,
      thiluckhongkinhgaN_2M: InfoBNDoKhucXa?.THILUCKHONGKINHGAN_2M,
      thiluckinhloxA_MP: InfoBNDoKhucXa?.THILUCKINHLOXA_MP,
      thiluckinhloxA_MT: InfoBNDoKhucXa?.THILUCKINHLOXA_MT,
      thiluckinhloxA_2M: InfoBNDoKhucXa?.THILUCKINHLOXA_2M,
      thiluckinhcuxA_MP: InfoBNDoKhucXa?.THILUCKINHCUXA_MP,
      thiluckinhcuxA_MT: InfoBNDoKhucXa?.THILUCKINHCUXA_MT,
      thiluckinhcuxA_2M: InfoBNDoKhucXa?.THILUCKINHCUXA_2M,
      thiluckinhcugaN_MP: InfoBNDoKhucXa?.THILUCKINHCUGAN_MP,
      thiluckinhcugaN_MT: InfoBNDoKhucXa?.THILUCKINHCUGAN_MT,
      thiluckinhcugaN_2M: InfoBNDoKhucXa?.THILUCKINHCUGAN_2M,
      nhanaP_MP: InfoBNDoKhucXa?.NHANAP_MP,
      nhanaP_MT: InfoBNDoKhucXa?.NHANAP_MT,
      kinhcU_MP_CAU: InfoBNDoKhucXa?.KINHCU_MP_CAU,
      kinhcU_MT_CAU: InfoBNDoKhucXa?.KINHCU_MT_CAU,
      kinhcU_MP_LOAN: InfoBNDoKhucXa?.KINHCU_MP_LOAN,
      kinhcU_MT_LOAN: InfoBNDoKhucXa?.KINHCU_MT_LOAN,
      kinhcU_MP_TRUC: InfoBNDoKhucXa?.KINHCU_MP_TRUC,
      kinhcU_MT_TRUC: InfoBNDoKhucXa?.KINHCU_MT_TRUC,
      kinhcU_MP_ADD: InfoBNDoKhucXa?.KINHCU_MP_ADD,
      kinhcU_MT_ADD: InfoBNDoKhucXa?.KINHCU_MT_ADD,
      kinhcU_PD: InfoBNDoKhucXa?.KINHCU_PD,
      khachquankhonglietdT_MP: InfoBNDoKhucXa?.KHACHQUANKHONGLIETDT_MP,
      khachquankhonglietdT_MT: InfoBNDoKhucXa?.KHACHQUANKHONGLIETDT_MT,
      khachquanlietdT_MP: InfoBNDoKhucXa?.KHACHQUANLIETDT_MP,
      khachquanlietdT_MT: InfoBNDoKhucXa?.KHACHQUANLIETDT_MT,
      chuquaN_MP: InfoBNDoKhucXa?.CHUQUAN_MP,
      chuquaN_MT: InfoBNDoKhucXa?.CHUQUAN_MT,
      kinhdieuchinH_MP_CAU: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_CAU,
      kinhdieuchinH_MT_CAU: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_CAU,
      kinhdieuchinH_MP_LOAN: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_LOAN,
      kinhdieuchinH_MT_LOAN: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_LOAN,
      kinhdieuchinH_MP_TRUC: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_TRUC,
      kinhdieuchinH_MT_TRUC: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_TRUC,
      kinhdieuchinH_MP_TL: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_TL,
      kinhdieuchinH_MT_TL: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_TL,
      kinhdieuchinH_MP_ADD: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_ADD,
      kinhdieuchinH_MT_ADD: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_ADD,
      kinhdieuchinH_PD: InfoBNDoKhucXa?.KINHDIEUCHINH_PD,
      kinhtiepxuC_MP_CONGSUAT: InfoBNDoKhucXa?.KINHTIEPXUC_MP_CONGSUAT,
      kinhtiepxuC_MT_CONGSUAT: InfoBNDoKhucXa?.KINHTIEPXUC_MT_CONGSUAT,
      kinhtiepxuC_MP_BC: InfoBNDoKhucXa?.KINHTIEPXUC_MP_BC,
      kinhtiepxuC_MT_BC: InfoBNDoKhucXa?.KINHTIEPXUC_MT_BC,
      kinhtiepxuC_MP_DIA: InfoBNDoKhucXa?.KINHTIEPXUC_MP_DIA,
      kinhtiepxuC_MT_DIA: InfoBNDoKhucXa?.KINHTIEPXUC_MT_DIA,
      kinhtiepxuC_MP_HIEUBRAND: InfoBNDoKhucXa?.KINHTIEPXUC_MP_HIEUBRAND,
      kinhtiepxuC_MT_HIEUBRAND: InfoBNDoKhucXa?.KINHTIEPXUC_MT_HIEUBRAND,
      khamtienchaN_MP_SACGIAC: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_SACGIAC,
      khamtienchaN_MP_DONGTU: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_DONGTU,
      khamtienchaN_MP_VANNHAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_VANNHAN,
      khamtienchaN_MP_THITRUONG: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_THITRUONG,
      khamtienchaN_MP_TESTCHEMAT_XA: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_TESTCHEMAT_XA,
      khamtienchaN_MP_TESTCHEMAT_GAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_TESTCHEMAT_GAN,
      khamtienchaN_MP_BODIEUTIETAA: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_BODIEUTIETAA,
      khamtienchaN_MT_SACGIAC: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_SACGIAC,
      khamtienchaN_MT_DONGTU: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_DONGTU,
      khamtienchaN_MT_VANNHAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_VANNHAN,
      khamtienchaN_MT_THITRUONG: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_THITRUONG,
      khamtienchaN_MT_TESTCHEMAT_XA: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_TESTCHEMAT_XA,
      khamtienchaN_MT_TESTCHEMAT_GAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_TESTCHEMAT_GAN,
      khamtienchaN_MT_BODIEUTIETAA: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_BODIEUTIETAA,
      khamtienchaN_2M_SACGIAC: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_SACGIAC,
      khamtienchaN_2M_DONGTU: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_DONGTU,
      khamtienchaN_2M_VANNHAN: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_VANNHAN,
      khamtienchaN_2M_THITRUONG: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_THITRUONG,
      khamtienchaN_2M_TESTCHEMAT_XA: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_TESTCHEMAT_XA,
      khamtienchaN_2M_TESTCHEMAT_GAN: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_TESTCHEMAT_GAN,
      khamtienchaN_2M_BODIEUTIETAA: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_BODIEUTIETAA,
      khamtienchaN_CANDIEMQUYTUNPC: InfoBNDoKhucXa?.KHAMTIENCHAN_CANDIEMQUYTUNPC,
      khamtienchaN_THIGIACLAPTHESTEREO: InfoBNDoKhucXa?.KHAMTIENCHAN_THIGIACLAPTHESTEREO,
    }
    const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${InfoBNDoKhucXa?.IDBNTTC}`)
    generatePhieuKhamKhucXa(data, formatDataDoKhucXa, PkDangNhap)
  }
  return (
    <div>
      <form>
        <div className="flex gap-2 justify-end">
          <Button
            disabled={!infoBN || !InfoBNDoKhucXa?.NGUOIKHAM}
            onClick={handlePrint}
            type="primary"
          >
            In
          </Button>
          {!bsmat && (
            <>
              <Button
                disabled={!infoBN || DetailBN?.trangthaikhambenh !== 1 || InfoBNDoKhucXa?.NGUOIKHAM}
                onClick={handleCapNhat}
                type="primary"
              >
                Cập nhật
              </Button>
              <Button
                disabled={!infoBN || DetailBN?.trangthaikhambenh !== 1 || InfoBNDoKhucXa?.NGUOIKHAM}
                onClick={handleLuuVaIn}
                type="primary"
              >
                Lưu & in
              </Button>
            </>
          )}
          {/* {checkBox && (
            <>
            </>
          )} */}
          {/* {(infoBN || InfoBNDoKhucXa?.NGUOIKHAM) && (
            
          )} */}
        </div>
        <div className="overflow-x-hidden pb-10">
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'THỊ LỰC (VISUAL ACUITY)',
                children: (
                  <>
                    <div className="grid grid-cols-12">
                      <div className="col-span-3 grid grid-rows-6 pr-1 border-r-[1px]">
                        <div className=""></div>
                        <div className="flex justify-between">
                          <span>
                            Không kính <span className="text-[12px] italic">(Without RX)</span>
                          </span>
                          <span>
                            Xa <span className="text-[12px] italic">(Dist)</span>
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span></span>
                          <span>
                            Gần <span className="text-[12px] italic">(Near)</span>
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>
                            Kính lỗ <span className="text-[12px] italic">(PH)</span>
                          </span>
                          <span>
                            Xa <span className="text-[12px] italic">(Dist)</span>
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>
                            Kính cũ <span className="text-[12px] italic">(CC)</span>
                          </span>
                          <span>
                            Xa <span className="text-[12px] italic">(Dist)</span>
                          </span>
                        </div>
                        <div className="text-end">
                          Gần <span className="text-[12px] italic">(Near)</span>
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-rows-6 pl-1 pr-1 border-r-[1px]">
                        <div className="text-center">MP (OD)</div>
                        <div className="mb-1">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckhongkinhxA_MP}
                            name="thiluckhongkinhxA_MP"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckhongkinhgaN_MP}
                            name="thiluckhongkinhgaN_MP"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhloxA_MP}
                            name="thiluckinhloxA_MP"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhcuxA_MP}
                            name="thiluckinhcuxA_MP"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhcugaN_MP}
                            name="thiluckinhcugaN_MP"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-rows-6 pl-1 pr-1  border-r-[1px]">
                        <div className="text-center">MT (OS)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckhongkinhxA_MT}
                            name="thiluckhongkinhxA_MT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckhongkinhgaN_MT}
                            name="thiluckhongkinhgaN_MT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhloxA_MT}
                            name="thiluckinhloxA_MT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhcuxA_MT}
                            name="thiluckinhcuxA_MT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhcugaN_MT}
                            name="thiluckinhcugaN_MT"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-rows-6 pl-1">
                        <div className="text-center">2M (OU)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckhongkinhxA_2M}
                            name="thiluckhongkinhxA_2M"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckhongkinhgaN_2M}
                            name="thiluckhongkinhgaN_2M"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhloxA_2M}
                            name="thiluckinhloxA_2M"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhcuxA_2M}
                            name="thiluckinhcuxA_2M"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.thiluckinhcugaN_2M}
                            name="thiluckinhcugaN_2M"
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'NHÃN ÁP (TONOMETRY)',
                children: (
                  <>
                    <div className="grid grid-cols-2 gap-1">
                      <div className="flex pr-16">
                        <span className="w-36 text-end pr-1">MP (OD)</span>
                        <Input
                          onChange={formik?.handleChange}
                          value={formik?.values?.nhanaP_MP}
                          name="nhanaP_MP"
                          size="small"
                        />
                      </div>
                      <div className="flex pr-16">
                        <span className="w-36 text-end pr-1">MT (OS)</span>
                        <Input
                          onChange={formik?.handleChange}
                          value={formik?.values?.nhanaP_MT}
                          name="nhanaP_MT"
                          size="small"
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'ĐO KHÚC XẠ (REFRACTION)',
                children: (
                  <>
                    <div className="grid grid-cols-12 gap-1">
                      <div className="col-span-4 grid grid-rows-4 pr-1 border-r-[1px]">
                        <div className=""></div>
                        <div className="">
                          Khách quan không liệt ĐT{' '}
                          <span className="text-[12px] italic">(Objective w/o Cydoplegic)</span>
                        </div>
                        <div className="">
                          Khách quan liệt ĐT{' '}
                          <span className="text-[12px] italic">(Objective w Cydoplegic)</span>
                        </div>
                        <div className="">
                          Chủ quan <span className="text-[12px] italic">(Subjective)</span>
                        </div>
                      </div>
                      <div className="col-span-4 grid grid-rows-4 pr-1 border-r-[1px]">
                        <div className="text-center">MP (OD)</div>
                        <div className="mb-1">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khachquankhonglietdT_MP}
                            name="khachquankhonglietdT_MP"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khachquanlietdT_MP}
                            name="khachquanlietdT_MP"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.chuquaN_MP}
                            name="chuquaN_MP"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-4 grid grid-rows-4">
                        <div className="text-center">MT (OS)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khachquankhonglietdT_MT}
                            name="khachquankhonglietdT_MT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khachquanlietdT_MT}
                            name="khachquanlietdT_MT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.chuquaN_MT}
                            name="chuquaN_MT"
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'KÍNH CŨ (CURRENT GLASSES)',
                children: (
                  <>
                    <div className="grid grid-cols-11 gap-1">
                      <div className="grid grid-rows-3 border-r-[1px]">
                        <div className=""></div>
                        <div className="">MP (OD)</div>
                        <div className="">MT (OS)</div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">Cầu (Sph)</div>
                        <div className="mb-1">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MP_CAU}
                            name="kinhcU_MP_CAU"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MT_CAU}
                            name="kinhcU_MT_CAU"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">Loạn (Cyl)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MP_LOAN}
                            name="kinhcU_MP_LOAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MT_LOAN}
                            name="kinhcU_MT_LOAN"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">Trục (Axis)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MP_TRUC}
                            name="kinhcU_MP_TRUC"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MT_TRUC}
                            name="kinhcU_MT_TRUC"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">ADD</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MP_ADD}
                            name="kinhcU_MP_ADD"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_MT_ADD}
                            name="kinhcU_MT_ADD"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3">
                        <div className="text-center">PD</div>
                        <div className="row-span-2">
                          <TextArea
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhcU_PD}
                            name="kinhcU_PD"
                            size="small"
                            rows={2}
                            className="pb-1"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'KÍNH ĐIỀU CHỈNH (SPECTACLE PRESCRIPTION)',
                children: (
                  <>
                    <div className="grid grid-cols-8 gap-1">
                      <div className="grid grid-rows-3 border-r-[1px]">
                        <div className=""></div>
                        <div className="">MP (OD)</div>
                        <div className="">MT (OS)</div>
                      </div>
                      <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">Cầu (Sph)</div>
                        <div className="mb-1">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MP_CAU}
                            name="kinhdieuchinH_MP_CAU"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MT_CAU}
                            name="kinhdieuchinH_MT_CAU"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">Loạn (Cyl)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MP_LOAN}
                            name="kinhdieuchinH_MP_LOAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MT_LOAN}
                            name="kinhdieuchinH_MT_LOAN"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">Trục (Axis)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MP_TRUC}
                            name="kinhdieuchinH_MP_TRUC"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MT_TRUC}
                            name="kinhdieuchinH_MT_TRUC"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">TL (VA)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MP_TL}
                            name="kinhdieuchinH_MP_TL"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MT_TL}
                            name="kinhdieuchinH_MT_TL"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">ADD</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MP_ADD}
                            name="kinhdieuchinH_MP_ADD"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_MT_ADD}
                            name="kinhdieuchinH_MT_ADD"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3">
                        <div className="text-center">PD</div>
                        <div className="row-span-2">
                          <TextArea
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhdieuchinH_PD}
                            name="kinhdieuchinH_PD"
                            size="small"
                            rows={2}
                            className="pb-1"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'KÍNH TIẾP XÚC (CONTACT LENS PRESCRIPTION)',
                children: (
                  <>
                    <div className="grid grid-cols-9 gap-1">
                      <div className="grid grid-rows-3 border-r-[1px]">
                        <div className=""></div>
                        <div className="">MP (OD)</div>
                        <div className="">MT (OS)</div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">
                          Công suất <span className="text-[12px] italic">(Power)</span>
                        </div>
                        <div className="mb-1">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MP_CONGSUAT}
                            name="kinhtiepxuC_MP_CONGSUAT"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MT_CONGSUAT}
                            name="kinhtiepxuC_MT_CONGSUAT"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">BC</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MP_BC}
                            name="kinhtiepxuC_MP_BC"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MT_BC}
                            name="kinhtiepxuC_MT_BC"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                        <div className="text-center">DIA</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MP_DIA}
                            name="kinhtiepxuC_MP_DIA"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MT_DIA}
                            name="kinhtiepxuC_MT_DIA"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-rows-3">
                        <div className="text-center">
                          Hiệu <span className="text-[12px] italic">(Brand)</span>
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MP_HIEUBRAND}
                            name="kinhtiepxuC_MP_HIEUBRAND"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.kinhtiepxuC_MT_HIEUBRAND}
                            name="kinhtiepxuC_MT_HIEUBRAND"
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="mt-2"
            size="small"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: 'KHÁM TIỀN CHẤN (PRE TEST)',
                children: (
                  <>
                    <div className="grid grid-cols-12 gap-1">
                      <div className="col-span-3 grid grid-rows-8 pr-1 border-r-[1px]">
                        <div className=""></div>
                        <div className="">
                          Sắc giác <span className="text-[12px] italic">(Color)</span>
                        </div>
                        <div className="">
                          Đồng tử <span className="text-[12px] italic">(Pupil)</span>
                        </div>
                        <div className="">
                          Vận nhãn <span className="text-[12px] italic">(EOM)</span>
                        </div>
                        <div className="">
                          Thị trường <span className="text-[12px] italic">(Visual field)</span>
                        </div>
                        <div className="flex justify-between">
                          <span>
                            Test che mắt <span className="text-[12px] italic">(Cover test)</span>
                          </span>
                          <span>
                            Xa <span className="text-[12px] italic">(Dist)</span>
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span></span>
                          <span>
                            Gần <span className="text-[12px] italic">(Near)</span>
                          </span>
                        </div>
                        <div className="">
                          Biên độ điều tiết <span className="text-[12px] italic">(AA)</span>
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-rows-8 pr-1 border-r-[1px]">
                        <div className="text-center">MP (OD)</div>
                        <div className="mb-1">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_SACGIAC}
                            name="khamtienchaN_MP_SACGIAC"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_DONGTU}
                            name="khamtienchaN_MP_DONGTU"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_VANNHAN}
                            name="khamtienchaN_MP_VANNHAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_THITRUONG}
                            name="khamtienchaN_MP_THITRUONG"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_TESTCHEMAT_XA}
                            name="khamtienchaN_MP_TESTCHEMAT_XA"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_TESTCHEMAT_GAN}
                            name="khamtienchaN_MP_TESTCHEMAT_GAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MP_BODIEUTIETAA}
                            name="khamtienchaN_MP_BODIEUTIETAA"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-rows-8 pr-1 border-r-[1px]">
                        <div className="text-center">MT (OS)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_SACGIAC}
                            name="khamtienchaN_MT_SACGIAC"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_DONGTU}
                            name="khamtienchaN_MT_DONGTU"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_VANNHAN}
                            name="khamtienchaN_MT_VANNHAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_THITRUONG}
                            name="khamtienchaN_MT_THITRUONG"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_TESTCHEMAT_XA}
                            name="khamtienchaN_MT_TESTCHEMAT_XA"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_TESTCHEMAT_GAN}
                            name="khamtienchaN_MT_TESTCHEMAT_GAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_MT_BODIEUTIETAA}
                            name="khamtienchaN_MT_BODIEUTIETAA"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-rows-8">
                        <div className="text-center">2M (OU)</div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_SACGIAC}
                            name="khamtienchaN_2M_SACGIAC"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_DONGTU}
                            name="khamtienchaN_2M_DONGTU"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_VANNHAN}
                            name="khamtienchaN_2M_VANNHAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_THITRUONG}
                            name="khamtienchaN_2M_THITRUONG"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_TESTCHEMAT_XA}
                            name="khamtienchaN_2M_TESTCHEMAT_XA"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_TESTCHEMAT_GAN}
                            name="khamtienchaN_2M_TESTCHEMAT_GAN"
                            size="small"
                          />
                        </div>
                        <div className="">
                          <Input
                            onChange={formik?.handleChange}
                            value={formik?.values?.khamtienchaN_2M_BODIEUTIETAA}
                            name="khamtienchaN_2M_BODIEUTIETAA"
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-1">
                      <div className="col-span-3 border-r-[1px]">
                        Cận điểm quy tụ <span className="text-[12px] italic">(NPC)</span>
                      </div>
                      <div className="col-span-9">
                        <Input
                          onChange={formik?.handleChange}
                          value={formik?.values?.khamtienchaN_CANDIEMQUYTUNPC}
                          name="khamtienchaN_CANDIEMQUYTUNPC"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-1">
                      <div className="col-span-3 border-r-[1px]">
                        Thị giác lập thể <span className="text-[12px] italic">(Stereo)</span>
                      </div>
                      <div className="col-span-9 mt-1">
                        <Input
                          onChange={formik?.handleChange}
                          value={formik?.values?.khamtienchaN_THIGIACLAPTHESTEREO}
                          name="khamtienchaN_THIGIACLAPTHESTEREO"
                          size="small"
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
      </form>
    </div>
  )
}
export default FormDKX
