import React from 'react'
import { Button, Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../../store/actions/userAction';
import { useNavigate } from 'react-router-dom';
const Results = ({countdown}) => {
  const {infoUser} = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout =() => {
    dispatch(logoutUser(navigate,infoUser?.dangNhap.idDangNhap))
  }
  return (
    <Result
    status="success"
    title="Thay đổi mật khẩu thành công!"
    subTitle={<p>Hệ thống sẽ tự đăng xuất sau {<span className='text-red-500 font-semibold'>{countdown}s</span>} nữa</p>}
    extra={[
      <Button onClick={handleLogout} type="primary" key="console">
       Đăng xuất
      </Button>,
    ]}
  />
  )
}

export default Results