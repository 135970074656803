import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { generateCheckbox, generateLogo } from '../../algorithm'
import { jsPDFCus } from '../../jsPdfAddFont'
import { splitLongWord } from '../../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

export const generateMonitorTimThai = (BNTTC, bnMonitor, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //header
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', `MTD${BNTTC.benhNhan.maBenhNhan}`, {
    displayValue: false,
  })

  const canvas = document.getElementById('barcode')
  // Convert canvas to an image
  const barcodeDataURL = canvas.toDataURL()
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFontSize(11)
  doc.text(`MTD${BNTTC.benhNhan.maBenhNhan}`, pageWidth - 46, 22)
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('KẾT QUẢ MONITOR TIM THAI-CƠN GÒ TỬ CUNG', doc.internal.pageSize.width / 2, 35, {
    align: 'center',
  })
  doc.setFontSize(12)
  doc.setFont('times', 'bold')
  doc.text('I. Thông tin bệnh nhân', xPos, 45)
  doc.setFont('times', 'regular')
  doc.text(`Họ tên: ${BNTTC.benhNhan.tenBenhNhan}`, xPos, 50)
  doc.text(
    `Năm sinh: ${moment(BNTTC.benhNhan.ngaySinh).format('YYYY')}      Tuổi: ${BNTTC.benhNhan.tuoi ?? ''}`,
    pageWidth - 100,
    50,
  )
  doc.text(`Nghề nghiệp: ${BNTTC.benhNhan.tenNgheNghiep}`, pageWidth - 90, 55)

  const diaChiBenhNhan = `Địa chỉ: ${BNTTC.benhNhan.diaChi ?? ''}${BNTTC.benhNhan.tenPhuongXa ? ', ' + BNTTC.benhNhan.tenPhuongXa : ''}${BNTTC.benhNhan.tenQuanHuyen ? ', ' + BNTTC.benhNhan.tenQuanHuyen : ''}${BNTTC.benhNhan.tenTinhTP ? ', ' + BNTTC.benhNhan.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 54) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 54)
    doc.text(wordsArray[0], xPos, 55)
    doc.text(wordsArray[1], xPos, 59)
    yPos = 59
  } else {
    doc.text(diaChiBenhNhan, xPos, 55)
    yPos = 50
    yPos += 5
  }
  yPos += 5
  doc.text(`Cân nặng: ${BNTTC.cannang ?? '...'} kg`, xPos, yPos)
  doc.text(`Chiều cao: ${BNTTC.chieucao ?? '...'} cm`, pageWidth / 2, yPos)
  yPos += 5
  const chanDoanSoBo = `Chẩn đoán: ${BNTTC.chandoan ?? ''}`
  if (chanDoanSoBo.length > 80) {
    const wordsArray = splitLongWord(chanDoanSoBo, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 5
  } else {
    doc.text(chanDoanSoBo, xPos, yPos)
  }
  yPos += 5
  doc.text('Chỉ định: Đo tim thai', xPos, yPos)
  yPos += 8

  doc.setFont('times', 'bold')
  doc.text('II. Kết quả monitor tim thai - cơn gò tử cung', xPos, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  // table
  doc.rect(xPos, yPos, pageWidth - 16, 88)
  doc.line(xPos, yPos + 8, pageWidth - 8, yPos + 8)
  doc.text('Nhịp tim thai cơ bản:', xPos + 2, yPos + 6)
  doc.text(`${bnMonitor.nhiptimthaicoban ?? ''} Nhịp/phút`, xPos + 62, yPos + 6)
  doc.line(xPos, yPos + 16, pageWidth - 8, yPos + 16)

  doc.text('Dao động nội tại: ', xPos + 2, yPos + 14)
  doc.text(`${bnMonitor.daodongnoitai ?? ''} Nhịp/phút`, xPos + 62, yPos + 14)
  doc.line(xPos + 60, yPos + 24, pageWidth - 8, yPos + 24)
  doc.line(xPos, yPos + 32, pageWidth - 8, yPos + 32)

  doc.text('Nhịp tăng:', xPos + 2, yPos + 28)
  doc.text('Không', xPos + 68, yPos + 22)
  doc.text('Có', xPos + 68, yPos + 30)
  if (bnMonitor.nhiptang === 'Không') {
    generateCheckbox(doc, xPos + 62, yPos + 18.5, 4, 4, true)
    generateCheckbox(doc, xPos + 62, yPos + 26.5, 4, 4, false)
  } else {
    generateCheckbox(doc, xPos + 62, yPos + 18.5, 4, 4, false)
    generateCheckbox(doc, xPos + 62, yPos + 26.5, 4, 4, true)
  }

  doc.text('Nhịp giảm:', xPos + 2, yPos + 52)
  doc.line(xPos + 60, yPos + 40, pageWidth - 8, yPos + 40)
  doc.text('Không', xPos + 68, yPos + 38)
  generateCheckbox(doc, xPos + 62, yPos + 34.5, 4, 4, bnMonitor.nhipgiam === 'Không' ? true : false)
  doc.line(xPos + 60, yPos + 48, pageWidth - 8, yPos + 48)
  doc.text('Có, dạng nhịp giảm sớm', xPos + 68, yPos + 46)
  generateCheckbox(
    doc,
    xPos + 62,
    yPos + 42.5,
    4,
    4,
    bnMonitor.nhipgiam === 'Có, dạng nhịp giảm sớm' ? true : false,
  )
  doc.line(xPos + 60, yPos + 56, pageWidth - 8, yPos + 56)
  doc.text('Có dạng bất định', xPos + 68, yPos + 54)
  generateCheckbox(
    doc,
    xPos + 62,
    yPos + 50.5,
    4,
    4,
    bnMonitor.nhipgiam === 'Có dạng bất định' ? true : false,
  )
  doc.line(xPos + 60, yPos + 64, pageWidth - 8, yPos + 64)
  doc.text('Có, dạng nhịp giảm muộn', xPos + 68, yPos + 62)
  generateCheckbox(
    doc,
    xPos + 62,
    yPos + 58.5,
    4,
    4,
    bnMonitor.nhipgiam === 'Có, dạng nhịp giảm muộn' ? true : false,
  )
  doc.line(xPos, yPos + 72, pageWidth - 8, yPos + 72)
  doc.text('Có, dạng nhịp giảm kéo dài > 2 phút', xPos + 68, yPos + 70)
  generateCheckbox(
    doc,
    xPos + 62,
    yPos + 66.5,
    4,
    4,
    bnMonitor.nhipgiam === 'Có, dạng nhịp giảm kèo dài &gt; 2 phút' ? true : false,
  )
  doc.line(xPos + 60, yPos + 80, pageWidth - 8, yPos + 80)
  doc.text('Cơn gò tử cung:', xPos + 2, yPos + 82)
  doc.text('Không', xPos + 68, yPos + 78)
  doc.text('Có', xPos + 68, yPos + 86)
  if (bnMonitor.cogotucung === 'Không') {
    generateCheckbox(doc, xPos + 62, yPos + 74.5, 4, 4, true)
    generateCheckbox(doc, xPos + 62, yPos + 82.5, 4, 4, false)
  } else {
    generateCheckbox(doc, xPos + 62, yPos + 74.5, 4, 4, false)
    generateCheckbox(doc, xPos + 62, yPos + 82.5, 4, 4, true)
  }
  doc.line(xPos + 60, yPos, xPos + 60, yPos + 88)

  yPos += 88
  yPos += 5
  doc.setFont('times', 'bold')
  doc.text('III. Kết luận', xPos, yPos)
  yPos += 8
  doc.setFont('times', 'regular')
  doc.text('Nhóm 1: Biểu đồ tim thai không dấu đe dọa sức khỏe thai', xPos + 10, yPos)
  if (
    bnMonitor.ketluan === 'Nhóm 1: Biểu đồ tim thai không dấu đe dọa sức khỏe thai' ||
    bnMonitor.idkl === 1
  ) {
    generateCheckbox(doc, xPos + 4, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, xPos + 4, yPos - 3.5, 4, 4, false)
  }

  yPos += 8
  doc.text('Nhóm 2: Biểu đồ tim thai nghi ngờ, cần theo dõi sát', xPos + 10, yPos)
  if (
    bnMonitor.ketluan === 'Nhóm 2: Biểu đồ tim thai nghi ngờ, cần theo dõi sát' ||
    bnMonitor.idkl === 2
  ) {
    generateCheckbox(doc, xPos + 4, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, xPos + 4, yPos - 3.5, 4, 4, false)
  }

  yPos += 8
  doc.setFontSize(12)
  doc.text('Nhóm 3: Sức khỏe thai không tốt, cần can thiệp tích cực', xPos + 10, yPos)
  if (
    bnMonitor.ketluan === 'Nhóm 3: Sức khỏe thai không tốt, cần can thiệp tích cực' ||
    bnMonitor.idkl === 3
  ) {
    generateCheckbox(doc, xPos + 4, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, xPos + 4, yPos - 3.5, 4, 4, false)
  }

  yPos += 10
  doc.setFontSize(12)

  const dateText = `TP.HCM, Ngày ${moment(bnMonitor.ngaythuchien).format('DD')} tháng ${moment(bnMonitor.ngaythuchien).format('MM')} năm ${moment(bnMonitor.ngaythuchien).format('YYYY')}`
  const doctorText = bnMonitor.bsthuchien ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2
  doc.text(dateText, dateTextX, yPos)

  yPos += 5
  doc.text(pageWidth - 62, yPos, 'Người Thực Hiện')
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  doc.text('(*) Đem theo phiếu này khi tái khám.', xPos, pageHeight - 10)
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
