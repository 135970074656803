import { Input, Modal, Select,Popconfirm } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createNhanSuCoShema } from '../../../schemas/createNhanSuCoShema'
import { deleteNhanSuCo, updateNhanSuCo } from '../../../store/actions/suCoAction'

function EditNhan({ isEditNhan, setIsEditNhan }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { groupNhan, infoNhan } = useSelector((state) => state.suCoReducer)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsEditNhan(false)
  }
  const handleSubmit = (value) => {
    dispatch(updateNhanSuCo(value))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('nhom', value)
  }
  const confirm = () => {
    dispatch(deleteNhanSuCo(infoNhan?.idnhan,handleCancel))
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idnhan: infoNhan?.idnhan,
      tennhan: infoNhan?.tennhan,
      mota: infoNhan?.mota,
      nhom: infoNhan?.idnhom,
      // nhom: null,
      thoigianxuly: infoNhan?.thoigianxuly,
      ghichu: infoNhan?.ghichu,
      nguoisua: infoUser?.idnv,
    },
    validationSchema: createNhanSuCoShema,
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Cập nhập"
      cancelText="Huỷ bỏ"
      open={isEditNhan}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">CHI TIẾT NHÃN</p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium flex justify-between">
            <p>
              Tên nhãn <span className="text-red-500">(*)</span>
            </p>
            <Popconfirm
              title="Xoá nhãn"
              description="Bạn có chắc muốn xoá nhãn ?"
              onConfirm={confirm}
              okText="Xoá"
              cancelText="bỏ"
              placement="bottomRight"
            >
              <span className="flex items-center text-gray-400 font-normal bg-slate-100 p-1 rounded-md hover:text-red-500 cursor-pointer duration-150">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </span>
            </Popconfirm>
          </label>
          <Input
            name="tennhan"
            onChange={formik.handleChange}
            status={formik.errors.tennhan && formik.touched.tennhan ? 'error' : null}
            value={formik.values.tennhan}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            {' '}
            Thời gian xử lý <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="thoigianxuly"
            onChange={formik.handleChange}
            status={formik.errors.thoigianxuly && formik.touched.tennhan ? 'error' : null}
            value={formik.values.thoigianxuly}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Nhóm người dùng <span className="text-red-500">(*)</span>
          </label>
          <Select
            placeholder="chọn nhóm người dùng"
            onChange={onChangeSelected}
            value={formik.values.nhom}
            options={groupNhan?.map((item) => ({
              label: item.tennhom,
              value: item.idnhom,
            }))}
            className="w-full"
            name="nhom"
            status={formik.errors.nhom && formik.touched.nhom ? 'error' : null}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Mô tả</label>
          <Input name="mota" onChange={formik.handleChange} value={formik.values.mota} />
          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Ghi chú</label>
            <Input name="ghichu" onChange={formik.handleChange} value={formik.values.ghichu} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditNhan
