import { call, put, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import { khamSucKhoeService } from '../../services/khamSucKhoe/khamSucKhoeService'
import * as typeAction from '../constants/constants'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* khamSucKhoeSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_LIST_BN_CHO_KSK, function* getBNChoKSKSaga({ type, idcn }) {
    try {
      const { data } = yield call(() => khamSucKhoeService.getBNChoKham(idcn))
      yield put({
        type: typeAction.DISPATCH_LIST_BN_CHO_KSK,
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_LIST_BN_DANG_VA_DA_KSK,
    function* getBNDangVaDaKSKSaga({ type, idcn, tuNgay, denNgay }) {
      try {
        const listDangKham = yield call(() =>
          khamSucKhoeService.getBNDangKham(idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() => khamSucKhoeService.getBNDaKham(idcn, tuNgay, denNgay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DANG_KSK,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_KSK,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // Lấy thông tin khám sức khỏe
  yield takeLatest(typeAction.GET_INFO_BN_KSK, function* getInfoKSKSaga({ type, idbnttc }) {
    try {
      const { data } = yield call(() => khamSucKhoeService.getInfoKSK(idbnttc))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_KSK,
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // Lưu thông tin khám sức khỏe
  yield takeLatest(typeAction.POST_INFO_BN_KSK, function* postInfoKSKSaga({ type, form }) {
    try {
      const { data } = yield call(() => khamSucKhoeService.postInfoKSK(form))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_KSK,
        payload: null,
      })
      yield put({
        type: typeAction.DISPATCH_CO_KE_TOA_KSK,
        payload: null,
      })
    } catch (error) {
      console.log(error)
    }
  })
}
