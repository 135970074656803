import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, ConfigProvider, Input, Popconfirm, Table, Tooltip } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import ModalCreateMenu from './ModalAddMenu/ModalAddMenu'
import ModalEditMenu from './ModalEditMenu/ModalEditMenu'

const columns = [
  {
    title: 'STT',
    dataIndex: 'STT',
    key: 'STT',
    width: 40,
    align: 'center',
  },
  {
    title: 'Tên menu',
    dataIndex: 'tenMenu',
    key: 'tenMenu',
    align: 'center',
  },
  {
    title: 'Mã menu',
    dataIndex: 'maMenu',
    key: 'maMenu',
    align: 'center',
  },
  {
    title: 'Link',
    dataIndex: 'links',
    key: 'links',
  },
  {
    title: 'Tên viết tắt',
    dataIndex: 'tenVietTat',
    key: 'tenVietTat',
  },
  {
    title: 'Hành động',
    dataIndex: 'action',
    key: 'action',
    width: 100,
  },
]
function MenuVaiTro(props) {
  const [data, setData] = useState([])
  const [dataShow, setDataShow] = useState([])
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState({
    show: false,
    data: null,
  })
  const getAllMenu = async () => {
    try {
      const { data } = await https.get('Menu/GetAll')
      setData(data)
      setDataShow(data)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại',
      })
    }
  }

  useEffect(() => {
    getAllMenu()
  }, [])

  const debounceSearch = useCallback(
    debounce((keyword, data) => {
      const result = data.filter((item) =>
        item.tenMenu.toLowerCase().includes(keyword.toLowerCase()),
      )
      setDataShow(result)
    }, 500),
    [],
  )

  const handleSearchInput = (e) => {
    const { value } = e.target
    debounceSearch(value, data) // Pass data as a parameter
    setSearch(value)
  }

  const handleClearSearch = () => {
    setSearch('')
    //get lai data
    setDataShow(data)
  }

  const handleDeleteById = async (id) => {
    try {
      await https.delete(`Menu/DeleteMenu/${id}`)
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa menu thành công',
      })
      getAllMenu()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa menu thất bại',
      })
    }
  }

  return (
    <div className="gap-2 h-full p-4 bg-[#efefef]">
      <div className="h-full bg-white rounded-xl">
        <div className="p-2 min-h-16 flex justify-between items-center">
          <ul className="flex gap-2 items-center">
            <li className="w-60">
              <Input
                value={search}
                placeholder="Tìm kiếm"
                onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                suffix={search?.length > 0 && <CloseOutlined onClick={handleClearSearch} />}
              />
            </li>
          </ul>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
            Tạo menu
          </Button>
        </div>
        <div className="mt-2 p-2">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              bordered
              pagination={{
                pageSize: 20,
                showSizeChanger: false,
              }}
              rowClassName=" cursor-pointer"
              columns={columns}
              dataSource={dataShow?.map((item, index) => ({
                ...item,
                STT: ++index,
                action: (
                  <ul className="flex justify-around">
                    <li>
                      <Tooltip title="Sửa thông tin" color="#52C41A">
                        <EditOutlined
                          onClick={(e) => {
                            setIsModalOpenEdit({ show: true, data: item })
                          }}
                          className="text-xl text-[#52C41A]  cursor-pointer"
                        />
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Xoá" color="red">
                        <Popconfirm
                          title="Xác nhận xóa menu"
                          okText="Xóa"
                          cancelText="Hủy"
                          onConfirm={() => handleDeleteById(item.idMenu)}
                          icon={<DeleteOutlined style={{ color: 'red' }} />}
                        >
                          <DeleteOutlined
                            className="text-xl text-red-500  cursor-pointer"
                            onClick={(e) => e.stopPropagation()} // Stop event propagation
                          />
                        </Popconfirm>
                      </Tooltip>
                    </li>
                  </ul>
                ),
              }))}
            />
          </ConfigProvider>
        </div>
      </div>
      {isModalOpen && (
        <ModalCreateMenu
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          getAllMenu={getAllMenu}
        />
      )}
      {isModalOpenEdit.show && (
        <ModalEditMenu
          isModalOpenEdit={isModalOpenEdit}
          setIsModalOpenEdit={setIsModalOpenEdit}
          getAllMenu={getAllMenu}
        />
      )}
    </div>
  )
}

MenuVaiTro.propTypes = {}

export default MenuVaiTro
