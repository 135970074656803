import moment from 'moment'
import React from 'react'
import 'moment/locale/vi'
moment.locale('vi')

const Index = () => {
  const today = moment()
  return (
    <div className="flex gap-5">
      <div className="mb-5 w-1/3 ">
        <div className="bg-white p-5 flex rounded-2xl border">
          <div className="bg-blue-500 p-5 rounded-xl w-20 flex items-center justify-center mr-5">
            <span className="  text-white ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
            </span>
          </div>
          <div className="flex justify-between w-full">
            <div>
              <h2 className="text-gray-400">Lượt khách</h2>
              <p className="font-semibold text-2xl">0</p>
            </div>
            <h2 className="text-gray-400 ">{today.format('DD/MM/YYYY')}</h2>
          </div>
        </div>
      </div>
      <div className="mb-5 w-1/3 ">
        <div className="bg-white p-5 flex rounded-2xl border">
          <div className="bg-sky-400 p-5 rounded-xl w-20 flex items-center justify-center mr-5">
            <span className="  text-white ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                />
              </svg>
            </span>
          </div>
          <div className="flex justify-between w-full">
            <div>
              <h2 className="text-gray-400">Thẻ</h2>
              <p className="font-semibold text-2xl">0</p>
            </div>
            <h2 className="text-gray-400 ">{today.format('DD/MM/YYYY')}</h2>
          </div>
        </div>
      </div>
      <div className="mb-5 w-1/3 ">
        <div className="bg-white p-5 flex rounded-2xl border">
          <div className="bg-green-500 p-5 rounded-xl w-20 flex items-center justify-center mr-5">
            <span className="  text-white ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                />
              </svg>
            </span>
          </div>
          <div className="flex justify-between w-full">
            <div>
              <h2 className="text-gray-400">Doanh thu</h2>
              <p className="font-semibold text-2xl">0</p>
            </div>
            <h2 className="text-gray-400 ">{today.format('DD/MM/YYYY')}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
