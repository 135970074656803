import React, { useEffect, useState } from 'react'
import { Tabs, Button, Segmented, Checkbox, Input, DatePicker } from 'antd'
import FormKSL from './FormKSL/FormKSL'
import FormLSTC from './FormLSTC/FormLSTC'
import FormCLS from '../../../ChildrenClinic/General/Tab/FormCLS/FormCLS'
import FormKQXN from '../../../ChildrenClinic/General/Tab/FormKQXN/FormKQXN'
import { useFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
// import { updateBnDetail } from '../../../../../store/actions/childrenClinicAction'
import { KSLClinicSchema } from '../../../../../schemas/ChilrenClinicSchema'
import {
  postInfoBNKhamSangLocAction,
  updateBnDetailKSL,
} from '../../../../../store/actions/khamSangLocAction'
import * as typeAction from '../../../../../store/constants/constants'
import 'moment/locale/vi'
moment.locale('vi')
const tab_CD = 'Khám sàng lọc'
const tab_CLS = 'Chỉ định cận lâm sàng'
const tab_KQXN = 'Kết quả xét nghiệm'
const tab_LSTC = 'Theo dõi sau tiêm'

const TabDiagnostic = ({ defaultValuePK, formBenhNhan }) => {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const formatDateAPI = 'YYYY-MM-DD'
  const SO_NGAY_TAI_KHAM = 0
  const initialStartDate = now.add(SO_NGAY_TAI_KHAM, 'days').format('YYYY-MM-DD')
  const { DetailBN, ThuocBN, DetailToaThuocBN, infoBN, NgayThuoc } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { infoBNTiemChung, infoTheoDoiSauTiem } = useSelector((state) => state.tiemChungReducer)
  const { InfoBNKhamSangLoc } = useSelector((state) => state.khamSangLocReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [date, setDate] = useState(initialStartDate)
  const [count, setCount] = useState(SO_NGAY_TAI_KHAM)
  const [checkBox, setCheckBox] = useState(false)
  const [segmentedValue, setSegmentedValue] = useState(tab_CD)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      ngaytaikham: DetailBN?.ngaytaikham,
      trangthaikhambenh: 2,
      bskham: DetailBN?.bskham,
      lydokham: DetailBN?.lydokham,
      benhsu: DetailBN?.benhsu,
      trieuchung: DetailBN?.trieuchung,
      idchandoan: DetailBN?.idchandoan,
      chandoan:
        DetailBN?.chandoan ??
        `${DetailBN?.trangthaikhambenh === 1 ? 'Z23-Cần tiêm chủng phòng các bệnh nhiễm khuẩn đơn thuần/' : ''}`,
      idloidan: DetailBN?.idloidan,
      loidan: DetailBN?.loidan,
      tiensubanthan: DetailBN?.tiensubanthan,
      diung: DetailBN?.diung,
      quatrinhbenhly: DetailBN?.quatrinhbenhly,
      tieusugiadinh: DetailBN?.tieusugiadinh,
      toanthan: DetailBN?.toanthan,
      cacbophan: DetailBN?.cacbophan,
      chandoanbandau: DetailBN?.chandoanbandau,
      daxuly: DetailBN?.daxuly,
      chandoanravien: DetailBN?.chandoanravien,
      dieutringoaitru: DetailBN?.dieutringoaitru,
      dienbienlamsang: DetailBN?.dienbienlamsang,
      benhkemtheo: DetailBN?.benhkemtheo,
      ppdieutri: DetailBN?.ppdieutri,
      ttlucravien: DetailBN?.ttlucravien,
      huongxuly: DetailBN?.huongxuly,
      lydokolaythuoc: DetailBN?.lydokolaythuoc,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: KSLClinicSchema,
  })
  const formikKSL = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      phanvedoiii: InfoBNKhamSangLoc?.phanvedoiii ?? 0,
      phanvedoii: InfoBNKhamSangLoc?.phanvedoii ?? 0,
      benhcaptinhmantinh: InfoBNKhamSangLoc?.benhcaptinhmantinh ?? 0,
      sothathannhiet: InfoBNKhamSangLoc?.sothathannhiet ?? 0,
      suygiammiendich: InfoBNKhamSangLoc?.suygiammiendich ?? 0,
      corticoid: InfoBNKhamSangLoc?.corticoid ?? 0,
      phanungtang: InfoBNKhamSangLoc?.phanungtang ?? 0,
      timbatthuong: InfoBNKhamSangLoc?.timbatthuong ?? 0,
      nhipthophoibatthuong: InfoBNKhamSangLoc?.nhipthophoibatthuong ?? 0,
      trigiacbatthuong: InfoBNKhamSangLoc?.trigiacbatthuong ?? 0,
      cannang: InfoBNKhamSangLoc?.cannang ?? 0,
      khac: InfoBNKhamSangLoc?.khac ?? 0,
      ghichu: InfoBNKhamSangLoc?.ghichu,
      tiemchung: InfoBNKhamSangLoc?.tiemchung ?? 1,
      chongchidinhtiemchung: InfoBNKhamSangLoc?.chongchidinhtiemchung ?? 0,
      tamhoantiemchung: InfoBNKhamSangLoc?.tamhoantiemchung ?? 0,
      benhvien: InfoBNKhamSangLoc?.benhvien ?? 0,
      lydo: InfoBNKhamSangLoc?.lydo,
      bskham: infoUser?.tenNV,
      benhly: InfoBNKhamSangLoc?.benhly ?? 0,
      diung: InfoBNKhamSangLoc?.diung ?? 0,
      phanungnang: InfoBNKhamSangLoc?.phanungnang ?? 0,
      thankinh: InfoBNKhamSangLoc?.thankinh ?? 0,
      ungthu: InfoBNKhamSangLoc?.ungthu ?? 0,
      corsiton: InfoBNKhamSangLoc?.corsiton ?? 0,
      truyenmau: InfoBNKhamSangLoc?.truyenmau ?? 0,
      mangthai: InfoBNKhamSangLoc?.mangthai ?? 0,
      tiemchunG4TUAN: InfoBNKhamSangLoc?.tiemchunG4TUAN ?? 0,
      idchuyenkhoa: InfoBNKhamSangLoc?.idchuyenkhoa ?? PkDangNhap?.idchuyenkhoa,
    },
    // onSubmit: (values) => handleSubmit(values),
    // validationSchema: ChildrenClinicSchema,
  })

  const handleSegmentedChange = (value) => {
    setSegmentedValue(value)
  }

  const handleSubmit = async (value) => {
    value.idbskham = infoUser?.idnv
    value.bskham = infoUser?.tenNV
    value.ngaytaikham = null // tiêm chủng không có hẹn ngày tái khám
    const listTenVacxin = ThuocBN?.map((item) => item.tenhoatchat)
    const newArr = [],
      formPrint = {
        infoBN: infoBN,
        infoBNTTC: { ...DetailBN, value },
        infoKSL: formikKSL.values,
        tenVacXin: ThuocBN?.length > 0 ? listTenVacxin.join(', ') : null,
        PkDangNhap: PkDangNhap,
      }
    if (!checkBox && ThuocBN?.length > 0) {
      // lấy thuốc
      for (let item of ThuocBN) {
        const { ton, ...other } = item
        other.ngayKeDon = now.format('YYYY-MM-DDTHH:mm:ss')
        other.idbnttc = DetailBN?.idbnttc
        newArr.push(other)
      }
    } else value.trangthaikhambenh = 4 // không lấy thuốc
    // return console.log(formikKSL.values)
    dispatch(
      updateBnDetailKSL(
        infoBN,
        formBenhNhan.values,
        value,
        defaultValuePK,
        newArr,
        formikKSL.values,
        PkDangNhap?.idChiNhanh,
        moment().format(formatDateAPI),
        moment().format(formatDateAPI),
        formPrint,
      ),
    )
    // await dispatch(postInfoBNKhamSangLocAction())
    formikKSL.resetForm()
  }
  // useEffect(() => {
  //   const today = moment().startOf('day')
  //   if (DetailBN?.ngaytaikham) {
  //     const startDate = moment(DetailBN.ngaykham).startOf('day')
  //     const endDate = moment(DetailBN.ngaytaikham).startOf('day')
  //     const diffDays = endDate.diff(startDate, 'days')
  //     setCount(diffDays)
  //   } else {
  //     const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
  //     const diffDays = selectedDate.diff(today, 'days')
  //     setCount(diffDays)
  //   }
  //   if (formik.values.ngaytaikham === null) setCount(0)
  // }, [date, DetailBN?.ngaytaikham])

  useEffect(() => {
    const today = moment().startOf('day')
    if (formik.values.ngaytaikham) {
      const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
      const diffDays = selectedDate.diff(today, 'days')
      setCount(diffDays)
    } else {
      setCount(0)
    }
  }, [date])
  //   const selectedDate = moment(date).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  // }, [date])
  useEffect(() => {
    if (NgayThuoc !== count) {
      dispatch({
        type: typeAction.NGAY_THUOC_NHI,
        payload: count,
      })
    }
  }, [count])

  useEffect(() => {
    if (DetailBN?.ngaytaikham) {
      const startDate = moment(DetailBN.ngaykham).startOf('day')
      const endDate = moment(DetailBN.ngaytaikham).startOf('day')
      const diffDays = endDate.diff(startDate, 'days')
      setCount(diffDays)
    } else {
      setCount(0)
    }
  }, [DetailBN?.ngaytaikham])
  //   const selectedDate = moment(date).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  // }, [date])

  useEffect(() => {
    setCheckBox(
      infoBN && !DetailToaThuocBN?.DanhSachThuoc?.length && DetailBN?.lydokolaythuoc ? true : false,
    )
  }, [DetailBN])
  useEffect(() => {
    if (infoTheoDoiSauTiem?.idbn === DetailBN?.idbn && infoTheoDoiSauTiem?.idbntdst)
      setSegmentedValue(tab_LSTC)
    else setSegmentedValue(tab_CD)
  }, [infoTheoDoiSauTiem?.idbntdst])
  return (
    <div className="mt-3 px-2">
      <div className="flex justify-between">
        <Segmented //[tab_CD, tab_CLS, tab_KQXN]
          options={
            infoTheoDoiSauTiem?.idbn === DetailBN?.idbn && infoTheoDoiSauTiem?.idbntdst
              ? [tab_CD, tab_LSTC]
              : [tab_CD]
          }
          value={segmentedValue}
          onChange={handleSegmentedChange}
        />
      </div>
      <div>
        {
          segmentedValue === tab_CD ? (
            <FormKSL
              formBenhNhan={formBenhNhan}
              checkBox={checkBox}
              setCheckBox={setCheckBox}
              formik={formik}
              setDate={setDate}
              date={date}
              count={count}
              setCount={setCount}
              formikKSL={formikKSL}
            />
          ) : segmentedValue === tab_LSTC ? (
            <>
              <FormLSTC />
            </>
          ) : (
            <></>
          )
          // : segmentedValue === tab_CLS
          //   ? (<FormCLS formik={formik} />)
          //   : (<FormKQXN />)
        }
      </div>
    </div>
  )
}

export default TabDiagnostic
