import React, { useEffect, useState } from 'react'
import { Input, Checkbox, ConfigProvider, DatePicker, Modal, Tooltip } from 'antd'
import { EditOutlined, CloseOutlined, SaveOutlined, QuestionCircleFilled } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { infoCardBN } from '../../../../../store/actions/cardAction'
import { cardService } from '../../../../../services/card/cardService'
import { Button } from '@mui/material'
import { Check } from '@mui/icons-material'
import ToastCus from '../../../../../utils/Toast'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const CardEdit = ({
  hieuLucCheck,
  setHieuLucCheck,
  ngayBaoLuu,
  setNgayBaoLuu,
  isEditTTV,
  isTangTTV,
}) => {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const { detailCard, infoBN, infoBNthu2 } = useSelector((state) => state.cartReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isEdit, setIsEdit] = useState(false)
  const [maThe, setMaThe] = useState(detailCard?.mathe ?? null)
  const [ngayHetHanNew, setNgayHetHanNew] = useState(null)
  const [loading, setLoading] = useState(false)

  // const [ngayHetHanNew, setNgayHetHanNew] = useState((detailCard?.denngay &&
  //   moment(detailCard?.denngay).format("DD/MM/YYYY")))
  // const [hieuLucCheck, setHieuLucCheck] = useState(false)
  // const handleEdit = (idthe) => {
  //   dispatch(activeCardPay(idthe, openNotificationWithIcon));
  //   handleReload();
  // };
  //------------ quyền người dùng ----------
  const isLeTan = ['Lễ tân '].includes(infoUser?.tenNhom)
  const loadNgayHetHanNew = () => {
    const ngayBL = moment(moment(detailCard?.ngaybaoluu).format(dateFormat), dateFormat) // format ngày bảo lưu
    const ngayHH = moment(moment(detailCard?.denngay).format(dateFormat), dateFormat) // format ngày hết hạn
    // const soNamConLai = ngayHH.diff(ngayBL, 'years')
    // const soThangConLai = ngayHH.diff(ngayBL, 'months')
    const soNgayConLai = ngayHH.diff(ngayBL, 'days')
    const ngayHHnew = moment().add(soNgayConLai, 'days')
    setNgayHetHanNew(moment(ngayHHnew).format('DD/MM/YYYY'))
  }
  const handleDatePicker = (date, dateString) => {
    setNgayBaoLuu(moment(dateString, dateFormat).format(dateFormatAPI))
    const ngayBL = moment(dateString, dateFormat) // format ngày bảo lưu
    const ngayHH = moment(moment(detailCard?.denngay).format(dateFormat), dateFormat) // format ngày hết hạn
    // const soNamConLai = ngayHH.diff(ngayBL, 'years')
    // const soThangConLai = ngayHH.diff(ngayBL, 'months')
    const soNgayConLai = ngayHH.diff(ngayBL, 'days')
    const ngayHHnew = moment().add(soNgayConLai, 'days')
    setNgayHetHanNew(moment(ngayHHnew).format('DD/MM/YYYY'))
  }
  const putMaTheTTV = async () => {
    try {
      setLoading(true)
      await cardService.putMaTheTTV(detailCard?.idthe, maThe)
      await dispatch(
        infoCardBN(infoBN?.idbn, infoBNthu2?.idbn, detailCard?.idthe, PkDangNhap?.idChiNhanh),
      )
      ToastCus.fire({
        icon: 'success',
        text: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: 'Thao tác thất bại!',
      })
    } finally {
      setIsEdit(false)
      setLoading(false)
    }
  }
  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn chỉnh sửa mã thẻ thành viên ?',
      icon: <QuestionCircleFilled />,
      content: (
        <>
          <p>Bạn xác nhận bảo chỉnh sửa mã thẻ thành viên:</p>
          <p className="font-semibold">
            {detailCard?.mathe}
            {` -> `}
            {maThe}
          </p>
        </>
      ),
      okText: 'Lưu',
      okType: 'primary',
      cancelText: 'Hủy',
      onOk() {
        putMaTheTTV()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  useEffect(() => {
    setHieuLucCheck(detailCard?.hieuluc === 1)
  }, [detailCard?.hieuluc])
  useEffect(() => {
    if (isTangTTV) setIsEdit(false)
  }, [isTangTTV])
  return (
    <form className="text-start mb-10">
      <div className="flex flex-col gap-2">
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Mã thẻ:</label>
            <Input
              onChange={(e) => setMaThe(e.target.value?.trim())}
              status={isEdit && !maThe && 'error'}
              disabled={!isEdit}
              style={{ color: 'black' }}
              value={isEdit ? maThe : detailCard?.mathe}
              size="small"
              suffix={
                detailCard?.idthe &&
                !isTangTTV &&
                !loading && (
                  <>
                    {isEdit ? (
                      <>
                        <div className="flex justify-end items-center gap-1">
                          <Tooltip title="Lưu chỉnh sửa mã thẻ" color="blue">
                            <SaveOutlined
                              onClick={() => {
                                if (maThe) showConfirm()
                              }}
                              className=" text-blue-500 cursor-pointer text-lg"
                            />
                          </Tooltip>
                          <Tooltip title="Hủy chỉnh sửa mã thẻ" color="red">
                            <CloseOutlined
                              onClick={() => {
                                setIsEdit(false)
                              }}
                              className=" text-red-500 cursor-pointer text-lg"
                            />
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <>
                        <Tooltip title="Chỉnh sửa mã thẻ" color="green">
                          <EditOutlined
                            onClick={() => {
                              setIsEdit(true)
                              setMaThe(detailCard?.mathe)
                            }}
                            className=" text-green-500 cursor-pointer text-lg"
                          />
                        </Tooltip>
                      </>
                    )}
                  </>
                )
              }
            />
          </div>
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Tên thẻ:</label>
            <Input
              style={{ color: 'black' }}
              value={
                detailCard?.hieuluc === -1 && detailCard?.sudung === 0
                  ? detailCard?.tenthe + ' - Đã hủy'
                  : detailCard?.hieuluc === 0 && detailCard?.sudung === 0
                    ? detailCard?.tenthe + ' - Đã hết hạn'
                    : detailCard?.hieuluc === 0 &&
                        detailCard?.sudung === 1 &&
                        detailCard?.idnhom !== 5
                      ? detailCard?.tenthe + ' - Đang bảo lưu'
                      : detailCard?.tenthe
              }
              disabled={!isEditTTV}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Đơn giá:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={detailCard?.tiensudung && formattedNumber(detailCard?.tiensudung)}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-2">
            <div className="w-1/2 flex gap-1">
              <label className="w-1/2 ">Ngày hiệu lực:</label>
              <Input
                className="w-1/2 rounded"
                disabled={!isEditTTV}
                style={{ color: 'black' }}
                value={detailCard?.tungay && moment(detailCard?.tungay).format('DD/MM/YYYY')}
                size="small"
              />
            </div>
            <div className="w-1/2 flex gap-1">
              <label className="w-1/2 text-center">Ngày hết hạn:</label>
              <Input
                className="w-1/2"
                disabled
                style={{ color: 'black' }}
                value={
                  hieuLucCheck && detailCard?.hieuluc === 0
                    ? ngayHetHanNew
                    : detailCard?.denngay && moment(detailCard?.denngay).format('DD/MM/YYYY')
                }
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Người tạo:</label>
            <Input disabled style={{ color: 'black' }} value={detailCard?.nguoitao} size="small" />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3">Ngày tạo:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={
                detailCard?.ngaydangky &&
                moment(detailCard?.ngaydangky).format('DD/MM/YYYY HH:mm:ss')
              }
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          {/* <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/4">Thẻ thanh toán:</label>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultColor: "white",
                    defaultBg: "#2d6e48",
                    defaultHoverBg: "#449764",
                    defaultHoverColor: "white",
                    defaultBorderColor: "none",
                    defaultHoverBorderColor: "none",
                  },
                },
              }}
            >
              {detailCard?.thethanhtoan !== 0 ? (
                <p className="text-[#449764] font-semibold text-base">
                  <CheckCircleFilled /> Đã kích hoạt
                </p>
              ) : (
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    handleEdit(detailCard?.idthe);
                  }}
                  startIcon={<Check />}
                >
                  Kích hoạt thẻ
                </Button>
              )}
            </ConfigProvider>
          </div> */}
          <div className="flex w-1/2 items-center">
            <label className="w-1/4 pr-1">Hiệu lực:</label>
            <Checkbox
              checked={hieuLucCheck}
              onChange={() => {
                // if (isLeTan) return
                if (
                  !hieuLucCheck &&
                  detailCard?.idlt !== 43 && //không phải thẻ nhi tặng
                  detailCard?.idnhom !== 5 &&
                  detailCard?.hieuluc === 0 &&
                  detailCard?.sudung === 1
                ) {
                  setHieuLucCheck(true)
                  loadNgayHetHanNew()
                } else if (
                  hieuLucCheck &&
                  detailCard?.idlt !== 43 && //không phải thẻ nhi tặng
                  detailCard?.hieuluc === 0 &&
                  detailCard?.sudung === 1
                ) {
                  setHieuLucCheck(false)
                }
              }}
            />
            {hieuLucCheck && detailCard?.hieuluc === 0 && detailCard?.sudung === 1 ? (
              <span className="flex justify-start">
                <label className="pl-16 pr-1">Ngày bảo lưu:</label>
                <DatePicker
                  placeholder="Chọn ngày bảo lưu..."
                  allowClear={false}
                  disabled
                  defaultValue={dayjs(detailCard?.ngaybaoluu)}
                  //maxDate={dayjs(now)}
                  // minDate={detailCard?.tungay && dayjs(detailCard?.tungay)}
                  // maxDate={detailCard?.denngay && dayjs(detailCard?.denngay)}
                  format={dateFormat}
                  // onChange={(date, dateString) => {
                  //   handleDatePicker(date, dateString)
                  // }}
                  size="small"
                />
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default CardEdit
