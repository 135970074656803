import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import LayoutApp from '../../../../HOCs/LayoutApp'
import moment from 'moment'
import { Button, Checkbox, ConfigProvider, DatePicker, Input, Pagination, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { IconButton, Tooltip } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { useSearchParams } from 'react-router-dom'
import { ContainerOutlined, SearchOutlined } from '@ant-design/icons'
import ModalInfo from './Modal/ModalInfo'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')

const data = [
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
  {
    key: 'SP_SYS_SENDSMS09',
    ma: 'SP_SYS_SENDSMS09',
    ten: 'Gửi tự động nhắc tất cả THẺ THÀNH VIÊN hết hạn đợt 3',
    noidung:
      'Be @ten the cua be se het han trong ngay mai. Vui long gia han ngay hom nay de duoc giam 200.000 so voi gia goc. Hotline: 0901315315',
    brandname: 'NHIDONG315',
    songay: '1',
    thoidiem: 'LAP_LAI_SAU_10_PHUT',
    giogui: '10:00',
    nguoisua: 'it-ttatrung',
    giosua: '26/12/2023',
    hieuluc: true,
  },
]

function SmsTuDong(props) {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isModalEdit, setIsModalEdit] = useState({
    show: false,
    data: null,
  })

  const onReload = () => {}

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 60,
      key: 'stt',
      align: 'center',
    },
    {
      title: 'Tên',
      dataIndex: 'ten',
      key: 'ten',
      width: 300,
      ...getColumnSearchProps('ten'),
    },
    {
      title: 'Nội dung',
      dataIndex: 'noidung',
      width: 650,
      // align: 'center',
      key: 'noidung',
      ...getColumnSearchProps('ten'),
    },
    {
      title: 'BrandName',
      dataIndex: 'brandname',
      width: 150,
      // align: 'center',
      key: 'brandname',
      ...getColumnSearchProps('ten'),
    },
    {
      title: 'Số ngày',
      dataIndex: 'songay',
      width: 80,
      align: 'center',
      key: 'songay',
      ...getColumnSearchProps('ten'),
    },
    {
      title: 'Thời điểm',
      dataIndex: 'thoidiem',
      // align: 'center',
      key: 'thoidiem',
      width: 200,
      ...getColumnSearchProps('ten'),
    },
    {
      title: 'Giờ gửi',
      dataIndex: 'giogui',
      width: 80,
      align: 'center',
      key: 'giogui',
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      width: 150,
      // align: 'center',
      key: 'nguoisua',
      ...getColumnSearchProps('ten'),
    },
    {
      title: 'Giờ sửa',
      dataIndex: 'giosua',
      width: 80,
      align: 'center',
      key: 'giosua',
    },
    {
      title: 'Hiệu lực',
      dataIndex: 'hieuluc',
      width: 80,
      align: 'center',
      key: 'hieuluc',
      render: (text, record) => <Checkbox checked={record.hieuluc} />,
      fixed: 'right',
    },
    {
      title: '',
      dataIndex: 'action',
      width: 50,
      // align: 'center',
      key: 'action',
      fixed: 'right',
    },
  ]

  return (
    <>
      <div className="h-full w-full bg-[#EBEBEB] p-2 border ">
        <div
          className="border mt-2 rounded-md bg-white"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex gap-5 justify-between border-dotted border-b-0 p-4">
            <div className="flex justify-between w-full">
              <div className="ml-auto">
                <Button
                  //   onClick={() => exportToExcel(search, chiNhanh, company, dateFrom, dateTo)}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                  loading={loadingExport}
                  disabled={loadingExport}
                >
                  Xuất Excel
                </Button>
              </div>
            </div>
          </div>
          <div className="p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
                components: {
                  Table: {
                    cellFontSize: 12,
                  },
                },
              }}
            >
              <Table
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                scroll={{ x: 2500, y: 670 }}
                loading={isLoading}
                bordered
                dataSource={data.map((item, idx) => ({
                  ...item,
                  stt: idx + 1,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg">
                        <Tooltip title="Chi tiết" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => setIsModalEdit({ show: true, data: { ...item } })}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
                columns={columns}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      {isModalEdit && <ModalInfo isModalEdit={isModalEdit} setIsModalEdit={setIsModalEdit} />}
    </>
  )
}

SmsTuDong.propTypes = {}

export default SmsTuDong
