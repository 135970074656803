import React, { useEffect, useState, useRef, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import CachedIcon from '@mui/icons-material/Cached'
import _, { set } from 'lodash'
import IconButton from '@mui/material/IconButton'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {
  ConfigProvider,
  Input,
  Select,
  Table,
  Tooltip,
  Button,
  Pagination,
  Modal,
} from 'antd'
import {
  ContainerOutlined,
  SearchOutlined,
  QuestionCircleFilled,
  CloseOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListGroupsVTByTypes,
  deleteThuocVT_Action,
  fetchDuongDung,
  getListCountry_Action,
  getListThuocVTByPageIndex_Action,
  infoThuocVT_Action,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalAdd from './Modal/ModalAdd'
import ModalDetail from './Modal/ModalDetail'
import { getlistDoitac } from '../../../store/actions/NhapKhoAction'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { https } from '../../../services/apiService'

const ThuaMua = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id_company = Number(localStorage.getItem('id_company'))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [types, setTypes] = useState(null)
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [isModalDetail, setIsModalDetail] = useState(false)
  const [search, setSearch] = useState(null)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const { listThuocVT, typesVT, groupsVT } = useSelector((state) => state.thuocVTReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [loadingExport, setLoadingExport] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }

  const CancelModalDetail = () => {
    setIsModalDetail(false)
  }
  const showModalDetail = () => {
    setIsModalDetail(true)
  }
  const handleInfoKhoVT = (id) => {
    dispatch(infoThuocVT_Action(id))
    showModalDetail()
  }
  const { confirm } = Modal
  const showDeleteInfoKhoVT = (idThuoc, maThuoc, tenBietDuoc) => {
    confirm({
      title: 'Bạn có muốn xóa Thuốc-Vật Tư này ?',
      icon: <QuestionCircleFilled />,
      content: (
        <p>
          Bạn xác nhận xóa:
          <br />
          {maThuoc} - {tenBietDuoc}
        </p>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteById(idThuoc)
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword, company, type, groups) => {
      dispatch(getListThuocVTByPageIndex_Action(keyword, company, type, groups, null))
    }, 300),
    [],
  )
  const handleCompany = (value) => {
    setCompany(value)
    SetSearchParams({ page: 1 })
    dispatch(
      getListThuocVTByPageIndex_Action(search, value, types, valueGroups, useSearch.get('page')),
    )
  }
  const handleSearchInput = (e) => {
    const { value } = e.target
    debounceDropDown(value, company, types, valueGroups)
    setSearch(value)
  }
  const exportToExcel = async () => {
    setLoadingExport(true)
    try {
      const response = await https.get(`ThuocVatTu/downloadAll?idCT=${company}`, {
        responseType: 'blob', // Important
      })

      // Create a Blob from the response
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      // Create a download link and click it programmatically
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Thuốc vật tư.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Optionally set state or do other operations after download
    } catch (error) {
      console.error('Error downloading file:', error)
    } finally {
      setLoadingExport(false)
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false) // close modal detail groups VT
  }
  const handleDeleteById = async (id) => {
    // * Truyền vào idThuoc vật tư muốn xóa
    // *  Lấy lại danh sách thuốc vật tư theo page
    await dispatch(deleteThuocVT_Action(id))
    await getListThuocVT()
  }
  const getListThuocVT = () => {
    SetSearchParams({ page: 1 })
    dispatch(
      getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
    )
  }

  const handleGroupsThuocVT = (value) => {
    SetValueGroups(value)
  }
  const handleTypesThuocVT = (value) => {
    // get list nhóm thuốc vật tư sau khi chọn loại
    setTypes(value)
    SetValueGroups(null)
    dispatch(ListGroupsVTByTypes(value))
  }
  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(getlistDoitac())
    dispatch(getListCountry_Action())
    dispatch(listAllCompanyAction())
    dispatch(fetchDuongDung())
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 50,
      align: 'center',
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      width: 90,
    },
    {
      title: 'Mã Barcode',
      dataIndex: 'barcode',
      width: 90,
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      width: 300,
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'tenHoatChat',
      width: 400,
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      width: 90,
    },

    {
      title: 'Quy cách',
      dataIndex: 'quyCach',
      width: 120,
    },
    {
      title: 'Đóng gói',
      dataIndex: 'quyCachDongGoi',
      width: 80,
      align: 'center',
    },

    {
      title: 'Số đăng kí',
      dataIndex: 'maSoDangKy',
      width: 120,
    },
    {
      title: 'Giá mua',
      dataIndex: 'giaMua',
      width: 80,
      align: 'right',
    },
    {
      title: 'Giá bán',
      dataIndex: 'giaBan',
      width: 80,
      align: 'right',
    },
    {
      title: 'Giá GPP',
      dataIndex: 'giagpp',
      width: 100,
      align: 'right',
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 100,
      fixed: 'right',
    },
  ]
  useEffect(() => {
    dispatch(
      getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
    )
  }, [useSearch.get('page')])
  return (
    <>
      <div className="p-5 bg-[#E7E7E7] h-full">
        <div className="p-2 bg-white rounded-xl border">
          <div className="block xl:flex 2xl:gap-5 gap-1 items-center justify-between">
            <div className="flex 2xl:gap-5 gap-1 items-center">
              <div className="w-60 gap-1 items-center ">
                <Select
                  showSearch
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
              </div>
              <div className="w-60 gap-1 items-center flex">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  value={types}
                  onChange={handleTypesThuocVT}
                  placeholder="Loại Thuốc Vật tư"
                  className="w-full"
                  options={typesVT?.map((items) => ({
                    label: items.phanLoai,
                    value: items.idPhanLoai,
                  }))}
                />
              </div>
              <div className="font-semibold 2xl:w-80 w-60 flex gap-1 items-center">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleGroupsThuocVT}
                  value={valueGroups}
                  options={groupsVT?.map((items) => ({
                    label: items.nhom,
                    value: items.idNhom,
                  }))}
                  placeholder="Nhóm Thuốc Vật tư"
                  className="w-full truncate"
                />
              </div>
              <div>
                <Input
                  value={search}
                  placeholder="Tìm kiếm"
                  onChange={handleSearchInput}
                  prefix={<SearchOutlined />}
                />
              </div>
              <IconButton
                onClick={getListThuocVT}
                color="primary"
                aria-label="add to shopping cart"
              >
                <CachedIcon />
              </IconButton>
            </div>

            <div className="flex items-center 2xl:gap-5 gap-1">
              <div>
                <Button
                  onClick={() => navigate('/thumuakho/thuocVT/import')}
                  variant="outlined"
                  type="primary"
                  ghost
                  icon={<FileUploadIcon />}
                >
                  Import DS
                </Button>
              </div>
              <div className="font-semibold ">
                <Button
                  onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                  loading={loadingExport}
                  disabled={loadingExport}
                >
                  Xuất Excel
                </Button>
              </div>
              <Tooltip title="Thêm" color="green">
                <button
                  onClick={showModal}
                  class="group cursor-pointer outline-none hover:rotate-90 duration-300"
                >
                  <svg
                    class="stroke-teal-500 fill-none group-hover:fill-teal-800 group-active:stroke-teal-200 group-active:fill-teal-600 group-active:duration-0 duration-300"
                    viewBox="0 0 24 24"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeWidth="1.5"
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    ></path>
                    <path strokeWidth="1.5" d="M8 12H16"></path>
                    <path strokeWidth="1.5" d="M12 16V8"></path>
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
              }}
            >
              <Table
                pagination={false}
                bordered
                scroll={{
                  x: 1500,
                  y: 690,
                }}
                columns={columns}
                dataSource={listThuocVT?.data.map(
                  (
                    {
                      stt,
                      idThuoc,
                      maThuoc,
                      tenBietDuoc,
                      tenHoatChat,
                      dvt,
                      quyCach,
                      quyCachDongGoi,
                      giaBan,
                      giaMua,
                      maSoDangKy,
                      duongDung,
                      giagpp,
                      barcode
                    },
                    index,
                  ) => ({
                    stt,
                    maThuoc,
                    barcode,
                    tenBietDuoc,
                    tenHoatChat,
                    dvt,
                    quyCach,
                    quyCachDongGoi,
                    giagpp: formatNumberVND(giagpp),
                    giaBan: formatNumberVND(giaBan),
                    giaMua: formatNumberVND(giaMua),
                    maSoDangKy,
                    duongDung,
                    action: (
                      <ul className="flex justify-around">
                        <li>
                          <Tooltip title="Xem chi tiết" color="#108ee9">
                            <ContainerOutlined
                              onClick={() => {
                                handleInfoKhoVT(idThuoc)
                              }}
                              className="text-xl text-[#108ee9]  cursor-pointer"
                            />
                          </Tooltip>
                        </li>
                        {/* <li>
                          <Tooltip title="Xóa" color="rgb(239 68 68)">
                            <CloseOutlined
                              onClick={() => {
                                showDeleteInfoKhoVT(idThuoc, maThuoc, tenBietDuoc)
                              }}
                              className="text-xl text-red-500  cursor-pointer"
                            />
                          </Tooltip>
                        </li>  */}
                      </ul>
                    ),
                  }),
                )}
              />
            </ConfigProvider>
            <div className="flex justify-end">
              <Pagination
                className="mt-5 "
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={20}
                total={20 * listThuocVT?.totalPage}
                onChange={(page, pagesize) => {
                  SetSearchParams({ page })
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalDetail isModalDetail={isModalDetail} CancelModalDetail={CancelModalDetail} />
      <ModalAdd isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  )
}

export default ThuaMua
