import * as Yup from "yup";

export const KhoNhapSchema = Yup.object().shape({
  IdKhoNhap: Yup.string().required(),
  IdDoiTac: Yup.string().required(),
  SoHoaDon: Yup.string().required(),
  // NgayHoaDon: Yup.string().required(),
  IdPhuongThuc: Yup.string().required(),
  IdHinhThuc: Yup.string().required(),
  // TenPhieu: Yup.string().required(),
  // NoiDung: Yup.string().required(),
});
