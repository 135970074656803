import { DatePicker, Input, Modal } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postVersion } from '../../../../store/actions/versionAction'
import { addVersionSchema } from '../../../../schemas/addVersionSchema'
import 'moment/locale/vi'
moment.locale('vi')
const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const today = moment().format(formatDB)
const dateFormat = 'DD/MM/YYYY'
const AddVersion = ({ open, cancelModal, fetchVersion }) => {
  const { infoUser } = useSelector((state) => state.userReducer)

  const dispatch = useDispatch()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phienban: '',
      tenphienban: '',
      tinhnangcapnhat: '',
      ngaynangcap: today,
      nguoicapnhat: infoUser?.tenNV,
      ngaycapnhat: today,
    },
    validationSchema: addVersionSchema,
    onSubmit: (values) => handleSubmit(values),
  })

  const handleSubmit = (values) => {
    dispatch(postVersion(values, onReset))
  }
  const onReset = () => {
    cancelModal()
    fetchVersion()
    formik.resetForm()
  }
  const handleDatePicker = (date, dateString) => {
    const value = moment(dateString, dateFormat).format(formatDB)
    formik.setFieldValue('ngaynangcap', value)
  }
  return (
    <Modal
      open={open}
      cancelText="Hủy bỏ"
      okText="Lưu"
      onOk={formik.handleSubmit}
      onCancel={() => {
        formik.handleReset()
        cancelModal()
      }}
      title="Thêm phiên bản mới"
    >
      <form className="flex flex-col gap-2">
        <div>
          <label className="font-semibold">
            {' '}
            <span className="text-red-500">*</span>Tiêu đề:
          </label>
          <Input
            status={formik.errors.tenphienban && formik.touched.tenphienban ? 'error' : ''}
            value={formik.values.tenphienban}
            name="tenphienban"
            onChange={formik.handleChange}
          />
        </div>
        <div>
          <label className="font-semibold">
            {' '}
            <span className="text-red-500">*</span>Phiên bản:
          </label>
          <Input
            status={formik.errors.phienban && formik.touched.phienban ? 'error' : ''}
            value={formik.values.phienban}
            name="phienban"
            onChange={formik.handleChange}
          />
        </div>
        <div>
          <label className="font-semibold">Ngày nâng cấp:</label>
          <DatePicker
            allowClear={false}
            value={dayjs(formik.values.ngaynangcap)}
            onChange={handleDatePicker}
            format={dateFormat}
            className="w-full"
          />
        </div>
        <div>
          <label className="font-semibold">
            {' '}
            <span className="text-red-500">*</span>Nội dung:
          </label>
          <Input.TextArea
            status={formik.errors.tinhnangcapnhat && formik.touched.tinhnangcapnhat ? 'error' : ''}
            value={formik.values.tinhnangcapnhat}
            name="tinhnangcapnhat"
            onChange={formik.handleChange}
            maxLength={500}
            style={{ height: 120, resize: 'none' }}
          />
        </div>
      </form>
    </Modal>
  )
}

export default AddVersion
