import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { phcnClinicService } from '../../services/phcnClinic/phcnClinicService'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* phcnClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_CHO_KHAM_PHCN,
    function* BNPendingPHCN({ type, idcn }) {
      try {
        const result = yield call(() => phcnClinicService.getBNChoKhamPHCN(idcn))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_KHAM_PHCN,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    })
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DANG_VA_DA_KHAM_PHCN,
    function* BNDangVaDaKhamPHCN({ type, idcn, tuNgay, denNgay }) {
      try {
        const listDangKham = yield call(() => phcnClinicService.getBNDangKhamPHCN(idcn, tuNgay, denNgay),)
        const listDaKham = yield call(() => phcnClinicService.getBNDaKhamPHCN(idcn, tuNgay, denNgay),)
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_PHCN,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_PHCN,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

