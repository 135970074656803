import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { addGoiKhamKhachHangCRM } from '../../../../store/actions/goikhamkhachhangCRMAction'
import ToastCus from '../../../../utils/Toast'

const dateFormat = 'DD-MM-YYYY'
const { TextArea } = Input
const ModalAddGoiKhamKH = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [allGoiKham, setAllGoiKham] = useState([])
  const [allKhachHang, setAllKhachHang] = useState([])
  const [multiGoiKham, setMultiGoiKham] = useState([])
  const [GKkhachHang, setGKkhachHang] = useState([])
  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    if (!value.idkh) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn khách hàng',
      })
      return
    }
    if (multiGoiKham.length === 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui chọn gói khám',
      })
      return
    }
    const dataSubmit = multiGoiKham.map((item) => {
      return { ...value, idgk: item }
    })
    dispatch(addGoiKhamKhachHangCRM(dataSubmit))
    formik.resetForm()
    setOpen(false)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idkh: '',
      nguoitao: infoUser.idnv,
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const getGoiKhamKH = async (value) => {
    try {
      const { data } = await https.get(`CRMKhachHangGoiKham/GetByIDKH?IDKH=${value}`)
      setGKkhachHang(data.result.map((item) => item.idgk))
    } catch (error) {
      console.log(error)
    }
  }
  const handleSelected = (name) => (value) => {
    getGoiKhamKH(value)
    formik.setFieldValue(name, value)
  }

  const getAllGoiKham = async () => {
    try {
      const { data } = await https.get('CRMGoiKham/GetAll')
      const dataOptions = data.map((item) => {
        if (GKkhachHang.includes(item.idgk)) {
          item.disabled = true
        } else {
          item.disabled = false
        }
        return item
      })
      dataOptions.sort((a, b) => a.disabled - b.disabled)
      setAllGoiKham(dataOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllGoiKham()
  }, [GKkhachHang])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('CRMKhachHang/GetAll')

        setAllKhachHang(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handleChangeMultiSelect = (value) => {
    setMultiGoiKham(value)
  }

  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={'Đăng kí gói khám'}
        onOk={formik.handleSubmit}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Khách hàng</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={formik.values.idkh}
              options={allKhachHang?.map((items) => ({
                label: items.tenkh,
                value: items.idkh,
              }))}
              onChange={handleSelected('idkh')}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Gói khám</label>
            <Select
              mode="multiple"
              options={allGoiKham?.map((items) => ({
                label: items.tengoikham,
                value: items.idgk,
                disabled: items.disabled,
              }))}
              onChange={handleChangeMultiSelect}
              className="w-full"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAddGoiKhamKH
