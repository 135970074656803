import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { DatePicker, Select, ConfigProvider, Table, TreeSelect, Button } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import * as XLSX from 'xlsx'
import IconButton from '@mui/material/IconButton'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { reportService } from '../../../../../services/Report/reportService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { branchService } from '../../../../../services/branch/branchService'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { CheckOutlined } from '@ant-design/icons'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const loaiPhieu = [
  {
    id: 1,
    value: 'Thuốc kê toa',
    text: 'Thuốc kê toa',
  },
  {
    id: 2,
    value: 'Thuốc bán lẻ',
    text: 'Thuốc bán lẻ',
  },
]
const Medicine = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState([BranchLogin])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const dispatch = useDispatch()

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = () => {
    fetchReport()
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Lợi nhuận chi tiết.xlsx')
  }

  const fetchBranch = async (id) => {
    const result = await branchService.getListBranch(id)
    setBranch(result.data)
  }

  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchReport = async () => {
    setIsLoading(true)
    let idChiNhanhs = null
    if (value?.length > 0 && value[0] !== 'all') {
      idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
      const { data } = await reportService.getDetailLoiNhuan(
        company,
        idChuyenKhoa,
        idChiNhanhs,
        dateForm,
        dateTo,
      )
      setData(data)
    } else {
      const { data } = await reportService.getDetailLoiNhuan(
        company,
        idChuyenKhoa,
        '',
        dateForm,
        dateTo,
      )
      setData(data)
    }
    setIsLoading(false)
  }

  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }

  const tongNhap = data.reduce((total, items) => total + items.tienGiaNhap, 0)
  const tongVon = data.reduce((total, items) => total + items.tienVonNhapHang, 0)
  const Giaban = data.reduce((total, items) => total + items.tienGiaBan, 0)
  const loinhuan = data.reduce((total, items) => total + items.loiNhuan, 0)
  const thanhtoan = data.reduce((total, items) => total + items.thanhToan, 0)

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa(null)
    fetchReport()
    fetchChuyenKhoa()
  }, [])

  const statis = [
    {
      title: 'TỔNG GIÁ NHẬP',
      number: formattedNumber(tongNhap),
    },
    {
      title: 'TỔNG TIỀN VỐN',
      number: formattedNumber(tongVon),
    },
    {
      title: 'TỔNG GIÁ BÁN',
      number: formattedNumber(Giaban),
    },
    {
      title: 'THANH TOÁN',
      number: formattedNumber(thanhtoan),
    },
    {
      title: 'TỔNG LỢI NHUẬN',
      number: formattedNumber(loinhuan),
    },
  ]

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  return (
    <div>
      <div>
        <ul className="flex border rounded-md">
          {statis.map((items) => (
            <li className="flex flex-col gap-2 w-1/5 border-r p-2" key={items.title}>
              <div className="flex justify-between">
                <h2 className="font-semibold text-gray-500 xl:text-base text-xs">{items.title}</h2>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="size-5 text-green-700 border-2 border-green-500 rounded-full"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangeChuyenKhoa}
              className="w-full"
              value={idChuyenKhoa}
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: '55vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngayBan',
                key: 'ngayBan',
                width: 100,
                align: 'center',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                width: 250,
              },
              {
                title: 'Loại phiếu',
                dataIndex: 'loaiPhieu',
                key: 'loaiPhieu',
                filters: loaiPhieu,
                width: 100,
                onFilter: (value, record) => record.loaiPhieu.indexOf(value) === 0,
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                  <div style={{ padding: 8 }}>
                    <Select
                      showSearch
                      value={selectedKeys[0]}
                      onChange={(value) => setSelectedKeys(value ? [value] : [])}
                      style={{ width: 188, marginBottom: 8, display: 'block' }}
                      options={loaiPhieu}
                    />
                    <Button
                      type="primary"
                      onClick={() => confirm()}
                      icon={<CheckOutlined />}
                      size="small"
                      style={{ width: 90, backgroundColor: 'green', borderColor: 'green' }}
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => clearFilters()}
                      size="small"
                      style={{ width: 90, marginLeft: 8 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: 'Mã hàng',
                dataIndex: 'maHang',
                key: 'maHang',
                width: 120,
              },
              {
                title: 'Tên hàng',
                dataIndex: 'tenhang',
                key: 'tenhang',
                width: 220,
              },
              {
                title: 'Đơn vị',
                dataIndex: 'dvt',
                key: 'dvt',
                align: 'center',
                width: 80,
              },
              {
                title: 'SL',
                dataIndex: 'soLuong',
                key: 'soLuong',
                width: 90,
                align: 'center',
              },
              {
                title: 'Nhập hàng',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                children: [
                  {
                    title: 'Giá nhập',
                    dataIndex: 'tienGiaNhap',
                    key: 'tienGiaNhap',
                    width: 110,
                    align: 'right',
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                  {
                    title: 'Tiền vốn',
                    dataIndex: 'tienVonNhapHang',
                    key: 'tienVonNhapHang',
                    width: 110,
                    align: 'right',
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                ],
              },
              {
                title: 'Xuất hàng',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                children: [
                  {
                    title: 'Giá bán',
                    dataIndex: 'tienGiaBan',
                    key: 'tienGiaBan',
                    width: 110,
                    align: 'right',
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                  {
                    title: 'Thành tiền',
                    dataIndex: 'thanhTien',
                    key: 'thanhTien',
                    width: 110,
                    align: 'right',
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                  {
                    title: 'Tiền giảm',
                    dataIndex: 'tienGiam',
                    key: 'tienGiam',
                    width: 110,
                    align: 'right',
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                  {
                    title: 'Thanh toán',
                    dataIndex: 'thanhToan',
                    key: 'thanhToan',
                    width: 110,
                    align: 'right',
                  },
                ],
              },
              {
                title: 'Lợi nhuận',
                dataIndex: 'loiNhuan',
                key: 'loiNhuan',
                width: 110,
                align: 'right',
              },
            ]}
            summary={(pageData) => {
              let totalSL = 0
              let totalTienGiaNhap = 0
              let totalTienVonNhapHang = 0
              let totalTienGiaBan = 0
              let totalThanhTien = 0
              let totalTienGiam = 0
              let totalThanhToan = 0
              let totalLoiNhuan = 0

              pageData.forEach(
                ({
                  soLuong,
                  tienGiaNhap,
                  tienVonNhapHang,
                  tienGiaBan,
                  thanhTien,
                  tienGiam,
                  thanhToan,
                  loiNhuan,
                }) => {
                  totalSL += soLuong
                  totalTienGiaNhap += tienGiaNhap
                  totalTienVonNhapHang += tienVonNhapHang
                  totalTienGiaBan += tienGiaBan
                  totalThanhTien += thanhTien
                  totalTienGiam += tienGiam
                  totalThanhToan += thanhToan
                  totalLoiNhuan += loiNhuan
                },
              )

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                    <Table.Summary.Cell index={0} colSpan={7} align="center">
                      Tổng cộng
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      {totalSL}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                      {formattedNumber(totalTienGiaNhap)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                      {formattedNumber(totalTienVonNhapHang)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      {formattedNumber(totalTienGiaBan)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      {formattedNumber(totalThanhTien)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      {formattedNumber(totalTienGiam)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      {formattedNumber(totalThanhToan)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      {formattedNumber(totalLoiNhuan)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Medicine
