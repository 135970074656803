import React from 'react'
import PropTypes from 'prop-types'

function CanVasBarCode(props) {
    return (
        <>
            <canvas id="barcode" className="hidden"></canvas>
        </>
    )
}

CanVasBarCode.propTypes = {}

export default CanVasBarCode
