import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as TypeAction from '../../../../../store/constants/constants'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { Input, notification, Typography, Radio, Select, Button, Modal } from 'antd'
import { MinusOutlined, PlusOutlined, DeleteFilled, RetweetOutlined } from '@ant-design/icons'
import { drugstoreService } from '../../../../../services/drugstore/drugstoreService'
import { getDetailInfoBN } from '../../../../../store/actions/drugstoreAction'
import ToastCus from '../../../../../utils/Toast'
import moment from 'moment'
import styled from 'styled-components'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const { TextArea } = Input
const BtnSolo = styled(Button)`
  padding: 4px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  height: fit-content !important;
`
const TableMedicine = ({
  check,
  lyDo,
  idLyDo,
  setIdLyDo,
  checkTraThuoc,
  setCheckTraThuoc,
  lyDoKhac, // lý do không lấy thuốc trước khi thanh toán
  setLyDoKhac, // lý do không lấy thuốc trước khi thanh toán
}) => {
  const dispatch = useDispatch()
  const findIndex = (id) => service?.DanhSachThuoc.findIndex((items) => items.idbnthuoc === id)
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { service, BNDetail, infoVC } = useSelector((state) => state.drugstoreReducer)
  const [isModal, setIsModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [listThuocKhacLo, setListThuocKhacLo] = useState([])
  const [thuocLoCu, setThuocLoCu] = useState(null) //info thuốc
  const [thuocLoMoi, setThuocLoMoi] = useState(null) //keyid thuốc
  const checkBsDangKeToaLai = !service?.BSKEDON || !service?.NGAYKEDON
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  const resetDoiSoLo = () => {
    setListThuocKhacLo([])
    setThuocLoCu(null)
    setThuocLoMoi(null)
  }
  const getListThuocKhacLo = async (infoThuoc) => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    try {
      setLoading(true)
      const { data } = await drugstoreService.getListThuocKhacLo(ID_BRANCH, infoThuoc?.idthuoc)
      let ArrTemp = await data?.result?.filter((item) => item?.tonKho > 0)
      if (ArrTemp?.length === 0) {
        ToastCus.fire({
          icon: 'error',
          title: 'Thuốc này không còn số lô hoặc hạn dùng khác!',
        })
        return
      } else {
        setListThuocKhacLo(
          ArrTemp?.map((item) => ({
            ...item,
            keyid: '' + item?.idThuoc + item?.soLo + item?.hanDung + item?.soHoaDon,
          })),
        )
        setThuocLoCu(infoThuoc)
        setThuocLoMoi(
          '' + infoThuoc?.idthuoc + infoThuoc?.solo + infoThuoc?.handung + infoThuoc?.sohoadon,
        )
        setIsModal(true)
      }
    } catch (error) {
      console.log(error)
      resetDoiSoLo()
    } finally {
      setLoading(false)
    }
  }
  const handleIncrement = (id) => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    const index = findIndex(id)
    if (index !== undefined && index !== -1) {
      // check tông kho
      if (service.DanhSachThuoc[index].tonkho === 0) {
        openNotification('Chỉnh số lượng thuốc', 'Số lượng thuốc tốn kho không đủ!')
        return
      }
      const count = service.DanhSachThuoc[index].soluong + 1
      if (count > service.DanhSachThuoc[index].soluongcu && checkTraThuoc) {
        openNotification('Chỉnh số lượng thuốc', 'Số lượng thuốc lấy quá giới hạn!')
        return
      }
      dispatch({
        type: TypeAction.INCREMENT_COUNT_DRUGSTORE,
        payload: {
          index,
          value: count,
        },
      })
    }
  }
  const checkThuoc =
    service?.DanhSachThuoc?.reduce((total, items) => total + items.soluong, 0) === 1
  const handleDecrement = (id) => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    const index = findIndex(id)
    if (index !== undefined && index !== -1) {
      if (checkThuoc && !checkTraThuoc) {
        openNotification(
          'Chỉnh số lượng thuốc',
          'Phải có ít nhất 1 loại thuốc hoặc chọn không lấy thuốc!',
        )
        return
      }
      const count = service.DanhSachThuoc[index].soluong - 1
      dispatch({
        type: TypeAction.DECREMENT_COUNT_DRUGSTORE,
        payload: {
          index,
          value: count,
        },
      })
    }
  }
  const onChange = (e) => {
    setIdLyDo(e.target.value)
    lyDo?.find((item) => item?.idld === e.target.value)
    const infoLd = lyDo?.find((item) => item?.idld === e.target.value)
    setLyDoKhac({ tenlydo: infoLd?.lydo, noidunglydokhac: null })
  }
  const handleOk = async () => {
    const infoThuocMoi = listThuocKhacLo?.find((item) => item?.keyid === thuocLoMoi)
    // console.log(
    //   service?.DanhSachThuoc?.find(
    //     (item) =>
    //       item?.idthuoc === infoThuocMoi?.idThuoc &&
    //       // item?.tonkho === infoThuocMoi?.tonKho &&
    //       item?.sohoadon === infoThuocMoi?.soHoaDon &&
    //       item?.handung === infoThuocMoi?.hanDung &&
    //       item?.solo === infoThuocMoi?.soLo,
    //   ),
    // )
    // return
    if (
      service?.DanhSachThuoc?.find(
        (item) =>
          item?.idthuoc === infoThuocMoi?.idThuoc &&
          // item?.tonkho === infoThuocMoi?.tonKho &&
          item?.sohoadon === infoThuocMoi?.soHoaDon &&
          item?.handung === infoThuocMoi?.hanDung &&
          item?.solo === infoThuocMoi?.soLo,
      )
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Toa đã có thuốc này rồi!',
      })
      return
    }
    try {
      setLoading(true)
      await drugstoreService.updateThuoc([
        {
          idbnthuoc: thuocLoCu?.idbnthuoc,
          soLo: infoThuocMoi?.soLo,
          hanDung: infoThuocMoi?.hanDung,
          soHoaDon: infoThuocMoi?.soHoaDon,
          soLuong:
            infoThuocMoi?.tonKho < thuocLoCu?.soluong ? infoThuocMoi?.tonKho : thuocLoCu?.soluong,
        },
      ])
      setIsModal(false)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      dispatch(getDetailInfoBN(BNDetail?.idbnttc, 4)) // idloaitt bán toa =4, xuất vắc xin =6
      setLoading(false)
      resetDoiSoLo()
    }
  }
  return (
    <>
      {contextHolder}

      <Modal
        width={600}
        open={isModal}
        onCancel={() => setIsModal(false)}
        okText={'Lưu'}
        onOk={handleOk}
        confirmLoading={loading}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Đổi số lô / hạn dùng</p>}
        //className="pb-3"
      >
        {/* <div className="grid grid-cols-6 text-center font-semibold">
          <div className="col-span-2">Tên thuốc</div>
          <div>Số lô</div>
          <div>Hạn dùng</div>
          <div>Số hóa đơn</div>
          <div>Tồn</div>
        </div> */}
        <Radio.Group
          onChange={(e) => setThuocLoMoi(e.target.value)}
          value={thuocLoMoi}
          className="grid grid-flow-row gap-5"
        >
          {listThuocKhacLo?.map((item) => (
            <Radio
              className="text-gray-500"
              value={item?.keyid}
              disabled={service?.DanhSachThuoc?.find(
                (thuocCu) =>
                  thuocCu?.idthuoc === item?.idThuoc &&
                  // item?.tonkho === infoThuocMoi?.tonKho &&
                  thuocCu?.sohoadon === item?.soHoaDon &&
                  thuocCu?.handung === item?.hanDung &&
                  thuocCu?.solo === item?.soLo,
              )}
            >
              <div className="flex">
                {item?.thuocVatTu?.tenBietDuoc}
                <>
                  &nbsp;-&nbsp;<p className="font-semibold">Tồn:&nbsp;</p>
                  {item?.tonKho < thuocLoCu?.soluong
                    ? `` + item?.tonKho + ` (Không đủ)`
                    : item?.tonKho}
                </>
              </div>
              <div className="flex text-xs italic">
                {item?.soLo ? (
                  <>
                    <p className="font-semibold">Số lô:&nbsp;</p>
                    {item?.soLo}
                  </>
                ) : (
                  ''
                )}
                {item?.hanDung ? (
                  <>
                    &nbsp;-&nbsp;<p className="font-semibold">Hạn dùng:&nbsp;</p>
                    {moment(item?.hanDung).format('DD/MM/YYYY')}
                  </>
                ) : (
                  ''
                )}
                {item?.soHoaDon ? (
                  <>
                    &nbsp;-&nbsp;<p className="font-semibold">Số hóa đơn:&nbsp;</p>
                    {item?.soHoaDon}
                  </>
                ) : (
                  ''
                )}
              </div>
            </Radio>
          ))}
        </Radio.Group>
      </Modal>
      {check ? (
        <div className="p-2 h-[470px]">
          <Typography.Title level={5}>Lý do không lấy thuốc</Typography.Title>
          <Radio.Group onChange={onChange} value={idLyDo} className="w-full">
            <div className="grid grid-flow-row gap-5 mt-3">
              {lyDo?.map((item) => (
                <div className="flex justify-start items-center">
                  <Radio key={`khonglaythuoc${item?.idld}`} value={item?.idld}>
                    {item?.lydo}
                  </Radio>
                  {`${item?.lydo}` === 'Khác' && item?.idld === idLyDo && (
                    <>
                      <Input
                        status={item?.idld === idLyDo && !lyDoKhac?.noidunglydokhac && 'error'}
                        size="small"
                        value={lyDoKhac?.noidunglydokhac}
                        onChange={(e) =>
                          setLyDoKhac({ tenlydo: `${item?.lydo}`, noidunglydokhac: e.target.value })
                        }
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          </Radio.Group>
        </div>
      ) : (
        <>
          {' '}
          <div className="w-full  h-[470px]">
            <div className="overflow-y-auto h-full">
              <table className="w-full">
                <thead className="bg-[#F3F6F9]">
                  <tr className="text-center">
                    <th className="pt-2 pb-2">Tên Thuốc</th>
                    <th className="w-[10%]">Số lô</th>
                    <th className="w-[8%]">Số lần/ngày</th>
                    <th className="w-[8%]">Liều dùng</th>
                    <th className="w-[10%]">Đơn giá lẻ</th>
                    <th className="w-[7%]">Số lượng</th>
                    <th className="w-[5%]">Tồn</th>
                    <th className="w-[10%]">Thành tiền</th>
                  </tr>
                </thead>
                <tbody>
                  {service?.DanhSachThuoc?.map((items) => (
                    <tr className="h-16">
                      <td className="text-start min-h-20 p-2">
                        <p>{items.tenbietduoc}</p>
                        {BNDetail?.trangthaikhambenh !== 4 && !checkBsDangKeToaLai && (
                          <BtnSolo
                            disabled={checkBsDangKeToaLai}
                            loading={loading}
                            onClick={() => {
                              getListThuocKhacLo(items)
                            }}
                            className="flex gap-0"
                          >
                            <RetweetOutlined
                              style={{ fontSize: 12, marginRight: '3px', padding: 0 }}
                            />
                            <span>Đổi số lô / hạn dùng</span>
                          </BtnSolo>
                        )}
                      </td>
                      <td className="text-center min-h-20 ">
                        {items.solo}
                        {/* <Select value={items.solo} className="w-full" size="small" options={[]} /> */}
                      </td>
                      <td className="text-center min-h-20">{items?.solanngay}</td>
                      <td className="text-center min-h-20">{items?.lieudung}</td>
                      <td className="text-end min-h-20 pr-5">
                        {items.dongia && formattedNumber(items.dongia)}
                      </td>
                      <td className="text-center min-h-20">
                        <div className="flex justify-center">
                          <div className=" flex items-center justify-center ">
                            {(BNDetail?.trangthaikhambenh !== 4 ||
                              (BNDetail?.trangthaikhambenh === 4 && checkTraThuoc)) &&
                              !checkBsDangKeToaLai && (
                                <button
                                  onClick={() => handleDecrement(items.idbnthuoc)}
                                  className={`w-10 h-8  ${
                                    items.soluong === 0
                                      ? 'cursor-not-allowed bg-slate-100 text-gray-400'
                                      : 'cursor-pointer bg-gray-200 text-gray-500'
                                  } rounded-lg `}
                                  disabled={items.soluong === 0}
                                >
                                  <MinusOutlined style={{ fontSize: 14 }} />
                                </button>
                              )}
                            <Input
                              className="text-center"
                              placeholder=" "
                              variant="borderless"
                              value={items.soluong}
                            />
                            {(BNDetail?.trangthaikhambenh !== 4 ||
                              (BNDetail?.trangthaikhambenh === 4 && checkTraThuoc)) &&
                              !checkBsDangKeToaLai && (
                                <button
                                  onClick={() => handleIncrement(items.idbnthuoc)}
                                  className="w-10 h-8 flex items-center justify-center bg-gray-200 text-gray-500 rounded-lg"
                                >
                                  <PlusOutlined style={{ fontSize: 14 }} />
                                </button>
                              )}
                          </div>
                        </div>
                      </td>
                      <td className="text-end  p-2 min-h-20 text-red-500">{items.tonkho}</td>
                      <td className="text-end p-2 min-h-20 pr-5">
                        {items.soluong && formattedNumber(items.soluong * items.dongia)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default TableMedicine
