import * as Yup from 'yup'

export const AddUserSchema = Yup.object().shape({
  idNhom: Yup.string().required(),
  idnv: Yup.string().required(),
  taiKhoan: Yup.string().required(),
  matKhau: Yup.string().required(),
  idChiNhanh: Yup.string().required(),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('matKhau'), null],
    '* Mật khẩu không giống nhau',
  ),
})
