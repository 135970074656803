import { https } from '../apiService'

export const tiemChungService = {
  getListBNChoTC: (idcn) =>
    https.get(`BenhNhanTheoDoiSauTiem/GetBenhNhanChoTheoDoiSauTiem?idcn=${idcn}`),
  getListBNDaTC: (idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanTheoDoiSauTiem/GetBenhNhanDaTheoDoiSauTiem?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getInfoBNTiemChung: (idbnttc) =>
    https.get(`BenhNhanTheoDoiSauTiem/GetThongTinBenhNhanTiemChung?idbnttc=${idbnttc}`),
  getInfoBNTheoDoiSauTiem: (idbnttc) =>
    https.get(`BenhNhanTheoDoiSauTiem/GetBenhNhanTheoDoiSauTiem?idbnttc=${idbnttc}`),
  postInfoBNTheoDoiSauTiem: (form) =>
    https.post(`BenhNhanTheoDoiSauTiem/AddBenhNhanTheoDoiSauTiem`, form),
  postInfoCanhBaoDoTuoi: (form) => https.post(`CanhBaoDoTuoi/AddCanhBaoDoTuoi`, form),
  putInfoCanhBaoDoTuoi: (form) => https.put(`CanhBaoDoTuoi/UpdateCanhBaoDoTuoi`, form),
  getListCanhBaoDoTuoi: (keyword, idnhom) =>
    https.get(`CanhBaoDoTuoi/GetAllCanhBaoDoTuoi`, { params: { keyword, idnhom } }),
  postInfoCanhBaoMuiTiem: (form) => https.post(`CanhBaoMuiTiem/AddCanhBaoMuiTiem`, form),
  putInfoCanhBaoMuiTiem: (form) => https.put(`CanhBaoMuiTiem/UpdateCanhBaoMuiTiem`, form),
  getListCanhBaoMuiTiem: (keyword, idnhom) =>
    https.get(`CanhBaoMuiTiem/GetAllCanhBaoMuiTiem`, { params: { keyword, idnhom } }),
  postInfoCanhBaoHoatChat: (form) => https.post(`CanhBaoHoatChat/AddCanhBaoHoatChat`, form),
  putInfoCanhBaoHoatChat: (form) => https.put(`CanhBaoHoatChat/UpdateCanhBaoHoatChat`, form),
  getListCanhBaoHoatChat: (keyword, idnhom) =>
    https.get(`CanhBaoHoatChat/GetAllCanhBaoHoatChat`, { params: { keyword, idnhom } }),
  getInfoCanhBaoHoatChat: (idcbhc) =>
    https.get(`CanhBaoHoatChat/GetInfoCanhBaoHoatChat`, { params: { idcbhc } }),
  postInfoCanhBaoKhoangCach: (form) => https.post(`CanhBaoKhoangCach/AddCanhBaoKhoangCach`, form),
  putInfoCanhBaoKhoangCach: (form) => https.put(`CanhBaoKhoangCach/UpdateCanhBaoKhoangCach`, form),
  getListCanhBaoKhoangCach: (keyword, idnhom) =>
    https.get(`CanhBaoKhoangCach/GetAllCanhBaoKhoangCach`, { params: { keyword, idnhom } }),
  getInfoCanhBaoKhoangCach: (idcbkc) =>
    https.get(`CanhBaoKhoangCach/GetInfoCanhBaoKhoangCach`, { params: { idcbkc } }),
}
