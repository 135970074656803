import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { logoToDataURI } from '../../assets/images/logoNhiBase64'
import { jsPDFCus } from '../jsPdfAddFont'
import { cloneDeep } from 'lodash'
import { logoSanToDataURI } from '../../assets/images/logoSanBase64'
import { logoLaoToDataURI } from '../../assets/images/logoLaoBase64'
import { generateLogo } from '../algorithm'
import 'moment/locale/vi'
moment.locale('vi')

export const generatePhieuXuatKho = (detailPT, detailThuocVT, PkDangNhap, isA5 = false) => {
  const doc = isA5 ? jsPDFCus('l', 'mm', 'a5') : jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width

  //header
  // switch (PkDangNhap.idChuyenKhoa) {
  //   case 1:
  //     doc.addImage(logoToDataURI, 'PNG', xPos, 0, 30, 38)
  //     doc.setFont('times', 'bold')
  //     doc.setFontSize(12)
  //     doc.text('HỆ THỐNG Y TẾ NHI ĐỒNG 315', 38, 11)
  //     doc.setFont('times', 'regular')
  //     doc.setFontSize(10)
  //     doc.text(detailPT.diaChiXuat ?? '', 38, 16)
  //     doc.text(
  //       `Điện thoại: ${detailPT.soDienThoaiXuat ?? '0901.315.315'} - Hotline: 0901.315.315`,
  //       38,
  //       20,
  //     )
  //     doc.text('Website: https://www.nhidong315.com', 38, 25)
  //     break
  //   case 2:
  //     doc.addImage(logoSanToDataURI, 'PNG', xPos - 3, 2, 35, 30)
  //     doc.setFont('times', 'bold')
  //     doc.setFontSize(12)
  //     doc.text('HỆ THỐNG Y TẾ PHỤ SẢN 315', 38, 11)
  //     doc.setFont('times', 'regular')
  //     doc.setFontSize(10)
  //     doc.text(detailPT.diaChiXuat ?? '', 38, 16)
  //     doc.text(
  //       `Điện thoại: ${detailPT.soDienThoaiXuat ?? '0901.315.315'} - Hotline: 0901.315.315`,
  //       38,
  //       20,
  //     )
  //     doc.text('Website: https://phusan315.com', 38, 25)
  //     break
  //   case 3:
  //     doc.addImage(logoLaoToDataURI, 'PNG', xPos - 2, 0, 18, 26)
  //     doc.setFont('times', 'bold')
  //     doc.setFontSize(12)
  //     doc.text('HỆ THỐNG Y TẾ TIM MẠCH - TIỂU ĐƯỜNG 315', 22, 10)
  //     doc.setFont('times', 'regular')
  //     doc.setFontSize(10)
  //     doc.text(detailPT.diaChiXuat ?? '', 22, 15)
  //     doc.text(
  //       `Điện thoại: ${detailPT.soDienThoaiXuat ?? '0901.315.315'} - Hotline: 0901.315.315`,
  //       22,
  //       20,
  //     )
  //     doc.text('Website: www.timmachtieuduong315.com', 22, 24)
  //     break

  //   default:
  //     break
  // }
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', detailPT.maPhieu, {
    displayValue: false,
  })

  const canvas = document.getElementById('barcode')
  // Convert canvas to an image
  const barcodeDataURL = canvas.toDataURL()
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFontSize(11)
  doc.text(detailPT.maPhieu, pageWidth - 46, 22)
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('PHIẾU XUẤT KHO KIÊM VẬN CHUYỂN NỘI BỘ', doc.internal.pageSize.width / 2, 35, {
    align: 'center',
  })
  doc.setFontSize(10)
  doc.setFont('times', 'regular')
  doc.text(`Bên giao: ${detailPT.tenNVXuat ?? ''}`, xPos, 45)
  doc.text(`Bên nhận: ${detailPT.tenNVNhan ?? ''}`, xPos, 50)
  doc.text(`Nơi giao: ${detailPT.tenChiNhanhXuat ?? ''}`, doc.internal.pageSize.width / 2 - 15, 45)
  doc.text(`Nơi nhận: ${detailPT.tenChiNhanhNhan ?? ''}`, doc.internal.pageSize.width / 2 - 15, 50)
  const textWidth1 = doc.getTextDimensions(`Từ kho: ${detailPT.tenKhoXuat ?? ''}`).w
  const textWidth2 = doc.getTextDimensions(`Đến kho: ${detailPT.tenKhoNhap ?? ''}`).w
  // Calculate the x-coordinate for alignment
  const x1 = doc.internal.pageSize.width - textWidth1 - 10
  const x2 = doc.internal.pageSize.width - textWidth2 - 10
  doc.text(`Từ kho: ${detailPT.tenKhoXuat ?? ''}`, x1, 45)
  doc.text(`Đến kho: ${detailPT.tenKhoNhap ?? ''}`, x2, 50)
  doc.text(`Ghi chú: ${detailPT.ghiChu ?? ''}`, xPos, 55)

  //table
  const headers = [
    'STT',
    'Tên Hàng',
    'Số lô',
    'Hạn dùng',
    'Tổng SL',
    'Quy cách',
    'Số HĐ',
    'Ghi chú',
  ]
  const cloneDetail = cloneDeep(detailThuocVT)
  const sortedData = cloneDetail.sort((a, b) => {
    const nameA = a.thuocVatTu?.tenBietDuoc.toUpperCase() // ignore upper and lowercase
    const nameB = b.thuocVatTu?.tenBietDuoc.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0 // names must be equal
  })
  const data = sortedData.map((item, index) => {
    const soLuongChan = Math.floor(item.soLuongLe / item.thuocVatTu?.quyCachDongGoi)
    const soLuongLe = item.soLuongLe - soLuongChan * item.thuocVatTu?.quyCachDongGoi
    let displaySoLuong

    if (soLuongChan > 0 && soLuongLe > 0) {
      displaySoLuong = `${soLuongChan} ${item.thuocVatTu?.donViChan} ${soLuongLe} ${item.thuocVatTu?.dvt}`
    } else if (soLuongChan > 0) {
      displaySoLuong = `${soLuongChan} ${item.thuocVatTu?.donViChan}`
    } else {
      displaySoLuong = `${soLuongLe} ${item.thuocVatTu?.dvt}`
    }
    return [
      index + 1,
      item.thuocVatTu?.tenBietDuoc,
      item.soLo,
      moment(item.hanDung).format('DD/MM/YYYY'),
      displaySoLuong,
      // item.thuocVatTu?.donViChan,
      // item.soLuongLe - Math.floor(item.soLuongLe/item.thuocVatTu?.quyCachDongGoi) * item.thuocVatTu?.quyCachDongGoi,
      // item.thuocVatTu?.dvt,
      // item.soLuongLe,
      item.quyCachDongGoi,
      item?.soHoaDon ?? '',
      item.ghiChu ?? '',
    ]
  })
  //columns for potrait
  const columnWidths = isA5 ? [12, 40, 25, 20, 25, 30, 25, 20] : [12, 40, 25, 20, 30, 30, 25, 20]

  doc.autoTable({
    head: [headers],
    body: data,
    startY: 60,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
      2: { cellWidth: columnWidths[2], halign: 'center' },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4], halign: 'center' },
      5: { cellWidth: columnWidths[5] },
      6: { cellWidth: columnWidths[6] },
      7: { cellWidth: columnWidths[7] },
      8: { cellWidth: columnWidths[8] },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 10,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 9,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'firstPage',
    margin: { left: isA5 ? xPos : xPos - 4 },
  })
  yPos = doc.autoTable.previous.finalY + 10
  //with A5 config
  if (isA5) {
    if (yPos > 125) {
      doc.addPage()
      yPos = 10
    }
  } else {
    if (yPos > 270) {
      doc.addPage()
      yPos = 10
    }
  }

  //ky ten
  doc.text(
    pageWidth -
      `..., ngày ${moment().format('DD')} tháng ${moment().format('MM')} năm ${moment().format('YYYY')}`
        .length *
        2,
    yPos,
    `..., ngày ${moment().format('DD')} tháng ${moment().format('MM')} năm ${moment().format('YYYY')}`,
  )
  const partWidth = pageWidth / 4
  doc.text('Người giao', xPos + 20, yPos + 8)
  doc.text('Người nhận', partWidth * 2 - 30, yPos + 8)
  doc.text('Thủ kho', partWidth * 3 - 30, yPos + 8)
  doc.text('Người lập phiếu', pageWidth - 50, yPos + 8)
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD')}/${moment().format('MM')}/${moment().format('YYYY')}`,
    xPos,
    doc.internal.pageSize.height - 5,
  )

  // doc.save('phieu-xuat-kho.pdf')

  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
