import { https } from '../apiService'

export const vanPhongPhamService = {
  getAllHangHoa: (idct) => https.get(`ThuocVatTu/GetHangHoaVPP?idct=${idct}`),
  getAllNguoiDuyet: () => https.get('VPPDatHang/GetNguoiDuyet'),
  getAllDatHang: (keyword, fromDate, toDate, pageIndex, idnv) =>
    https.get(
      `VPPDatHang/GetAllVPPDatHang?tuNgay=${fromDate}&denNgay=${toDate}&keyword=${keyword}&pageIndex=${pageIndex}&idNV=${idnv}`,
    ),
  addDonDatHang: (form) => https.post('VPPDatHang/AddVPPDatHang', form),
  getInfoDonHang: (id) => https.get(`VPPDatHang/GetInfoVPPDatHang?iddathang=${id}`),
  updateTrangThaiDonHang: (id, idTrangThai, nguoisua) =>
    https.put(
      `VPPDatHang/UpdateTrangThaiDatHang?iddathang=${id}&trangthai=${idTrangThai}&nguoiduyet=${nguoisua}`,
    ),
  updateTrangThaiListDonHang: (listDonDatHang) =>
    https.put(`VPPDatHang/UpdateListTrangThaiDatHang`,listDonDatHang),
  getAllDonHangByTrangThai: (
    keyword,
    fromDate,
    toDate,
    pageIndex,
    idTrangThai,
    idct,
    idcn,
    nguoiduyet,
  ) =>
    https.get(
      `VPPDatHang/GetAllVPPDatHangByTrangThai?idct=${idct}&keyword=${keyword}&idcn=${idcn}&tuNgay=${fromDate}&denNgay=${toDate}&trangthai=${idTrangThai}&nguoiduyet=${nguoiduyet}&pageIndex=${pageIndex}`,
    ),
  editDonDatHang: (form) => https.put('VPPDatHang/UpdateVPPChiTietDatHang', form),
  deleteDonHang: (id) => https.delete(`VPPDatHang/DeleteVPPDatHang?iddathang=${id}`),
  getAllDonHangByIdCtyIdCn: (keyword, fromDate, toDate, pageIndex, idct, idcn) =>
    https.get(
      `VPPDatHang/GetVPPDatHangByCT?keyword=${keyword}&idcn=${idcn}&idct=${idct}&tuNgay=${fromDate}&denNgay=${toDate}&pageIndex=${pageIndex}`,
    ),
  getInfoDonHangIncludeMoney: (id) =>
    https.get(`VPPDatHangTongHop/GetInfoVPPDatHangTongHop?iddathang=${id}`),
  getAllDonHangThuMuaDuyet: (keyword, idct, pageIndex) =>
    https.get(
      `VPPDatHangTongHop/GetInfoVPPDatHangTongHop?idct=${idct}&keyword=${keyword}&pageIndex=${pageIndex}`,
    ),
  updateSoLuongGiaoNhan: (form) => https.put(`VPPDatHang/UpdateSoLuongGiaoNhan`, form),
}
