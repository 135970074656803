import { https } from '../apiService'

export const dangKyKhamOnlineService = {
  getListDoiTac: (keyword) =>
    https.get(`DangKyOnline/GetAllDoiTac`, keyword && { params: { keyword } }),
  postDoiTacKH: (list) => https.post(`DangKyOnline/AddDoiTac`, list),
  putDoiTacKH: (form) => https.put(`DangKyOnline/UpdateDoiTac`, form),
  postKhachHangDT: (list) => https.post(`DangKyOnline/AddKhachHang`, list),
  putKhachHangDT: (form) => https.put(`DangKyOnline/UpdateKhachHang`, form),
  getListKhachHang: (keyword, idDoiTac, pageNumber) =>
    https.get(`DangKyOnline/GetAllKhachHang`, { params: { keyword, idDoiTac, pageNumber } }),
  putTrangThaiDangKyKhamOnl: (idbndkk, trangThaiDK, nguoiSua) =>
    https.put(
      `BenhNhanDangKyKham/UpdateTrangThaiKham?idbndkk=${idbndkk}&trangThaiDK=${trangThaiDK}&nguoiSua=${nguoiSua}`,
    ),
}
