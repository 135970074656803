import React, { useCallback, useEffect, useState } from 'react'
import {
  AuditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Select, notification, Table, ConfigProvider, Input, Divider, Tag } from 'antd'
import Button from '@mui/material/Button'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import { searchBN_Action } from '../../../store/actions/receiveAction'
import InfoBN from './InfoBN/InfoBN'
import Price from './Price/Price'
import { childrenClinicService } from '../../../services/childrenClinic/childrenClinicService'
import { formatNumber } from 'devextreme/localization'
import { recieveService } from '../../../services/receive/recieveService'
import PayModal from './Modal/PayModal'
import * as typeAction from '../../../store/constants/constants'
import CreateCustomer from '../../common/CreateCustomers/CreateCustomer'
import ToastCus from '../../../utils/Toast'
import { Link } from 'react-router-dom'

import 'moment/locale/vi'
moment.locale('vi')
const BanLe = () => {
  const { listBN } = useSelector((state) => state.receiveReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [api, contextHolder] = notification.useNotification()
  const [ThuocVT, setThuocVT] = useState([])
  const [thuocBN, setThuocBN] = useState([])
  const [idPhuongThuc, setIdPhuongThuc] = useState(1)
  const [InfoBNTTC, setInfoBNTTC] = useState({ nguoinha: null, dienthoainguoinha: null })
  const [isModal, setIsModal] = useState(false)
  const [xuatHD, setXuatHD] = useState(1)
  const [infoBN, setInfoBN] = useState(null)
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { BNThuoc, infoVC } = useSelector((state) => state.drugstoreReducer)
  const [tabLayThuoc, setTabLayThuoc] = useState(1)

  const dispatch = useDispatch()
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(searchBN_Action(keyword, ID_COMPANY))
    }, 300),
    [],
  )
  //---------- show modal ---------
  const showModalPay = () => {
    setIsModal(true)
  }
  // ----------cancel modal ---------
  const cancelModal = () => {
    setIsModal(false)
  }
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  //---------------- lấy danh thông tin bệnh nhân -----------
  const fetchInfoBN = async (id) => {
    try {
      const result = await recieveService.getInfoBN(id)
      setInfoBN(result.data)
      dispatch({
        type: typeAction.DISPATCH_INFO_VOUCHER_BANTOA,
        payload: null,
      })
    } catch (error) {
      console.log(error)
    }
  }
  // ---------  lây danh sách thuôc vật tư -----------//
  const fetchThuoc = async () => {
    const result = await childrenClinicService.getKetoaThuoc(ID_BRANCH)

    const newData = result.data
      .filter((items) => items.tonKho > 0)
      .map((items, index) => ({ ...items, ID: index, SL: 1 }))
    setThuocVT(newData)
  }
  const onChangThuoc = (value) => {
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    if (thuocBN.some((items) => value === items.ID)) {
      openNotificationWithIcon('Thêm thuốc vật tư', ' Thuốc này đã được chọn!')
    } else {
      const index = ThuocVT.findIndex((items) => items.ID === value)
      setThuocBN((preState) => [...preState, ThuocVT[index]])
    }
  }
  //--------- xứ lý xố lượng thuốc -----------//
  const handleCount = (e, ID) => {
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    const { value } = e.target
    const index = thuocBN.findIndex((items) => items.ID === ID)
    const parsedValue = parseFloat(value)
    if (parseFloat(value) < 1 || value.includes('.') || value === '' || value === null) {
      setThuocBN((preState) =>
        preState.map((item, idx) => (idx === index ? { ...item, SL: 1 } : item)),
      )
      return
    }
    if (parsedValue > thuocBN[index].tonKho) {
      openNotificationWithIcon('Thêm số lượng', 'Số lượng tồn kho không đủ!')
    } else {
      setThuocBN((preState) =>
        preState.map((item, idx) => (idx === index ? { ...item, SL: value } : item)),
      )
    }
  }
  const onClickCreateCustomer = () => {
    dispatch({
      type: typeAction.OPEN_MODAL_CREATE_CUSTOMER,
    })
  }
  //-----------delete khỏi mãng ---------
  const deleteThuocVatTu = (ID) => {
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    setThuocBN((preState) => preState.filter((item) => item.ID !== ID))
  }
  //---------- tổng tiền -----------//
  const tongGiaTien = thuocBN.reduce(
    (accumulator, currentItem) => accumulator + currentItem.SL * currentItem.thuocVatTu?.giaBan,
    0,
  )
  //---------- reset --------------//
  const resetForm = () => {
    cancelModal()
    setThuocBN([])
    setInfoBNTTC({ nguoinha: null, dienthoainguoinha: null })
    setXuatHD(1)
    setIdPhuongThuc(1)
    setInfoBN()
    setXuatHD(1)
    fetchThuoc()
  }
  const dataBNTTC = {
    idbn: infoBN?.idbn,
    ...InfoBNTTC,
    idcn: PkDangNhap?.idChiNhanh,
    idct: PkDangNhap?.idCongTy,
    idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
  }
  // form BN thanh toán
  const benhNhanTT = {
    // idbnttc: 0,
    idPhuongThuc: idPhuongThuc,
    hoaDonDienTu: xuatHD,
    tienThanhToan: tongGiaTien,
    // ngayThanhToan: '2024-06-15T08:49:16.207Z',
    // thuNgan: infoUser?.tenNV,
    thuNgan: infoUser?.tenNV,
    idThuNgan: infoUser?.idnv,
    idloaitt: 5,
    tienThuoc: tongGiaTien,
    idCaLamViec: infoUser?.dangNhap.idCaLamViec,
    idcn: PkDangNhap?.idChiNhanh,
    idct: PkDangNhap?.idCongTy,
    idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
  }
  useEffect(() => {
    fetchThuoc()
  }, [])
  return (
    <>
      {contextHolder}
          <div className="flex border rounded-lg h-full">
            <div className="border-r w-2/3">
              <div className="p-2">
                <div className="flex">
                  <Select
                    placeholder="Nhập tên thuốc"
                    showSearch
                    allowClear
                    onChange={onChangThuoc}
                    filterOption={(input, option) => {
                      const label = option?.label ?? ''
                      const tenBietDuoc = option?.tenBietDuoc ?? ''
                      const searchText = input.toLowerCase()
                      return (
                        label.toLowerCase().includes(searchText) ||
                        tenBietDuoc.toLowerCase().includes(searchText)
                      )
                    }}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    value={null}
                    className="w-full mr-2"
                    options={ThuocVT?.map((items) => ({
                      key: items.ID,
                      label: items.thuocVatTu.tenHoatChat,
                      tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                      value: items.ID,
                      ton: items.tonKho,
                      dvt: items.thuocVatTu.dvt,
                      hanDung: items.hanDung,
                    }))}
                    dropdownRender={(menu) => (
                      <div>
                        <div
                          className="flex bg-gray-100"
                          style={{ padding: '8px', fontWeight: 'bold' }}
                        >
                          <span className="w-3/6 text-center ">Tên thuốc</span>
                          <span className="w-1/6 text-center">Hạn dùng</span>
                          <span className="w-1/6 text-center">Tồn</span>
                          <span className="w-1/6 text-center">Đơn vị tính</span>
                        </div>
                        <div style={{ marginBottom: '8px' }}>{menu}</div>
                      </div>
                    )}
                    optionLabelProp="label"
                    optionRender={(options) => (
                      <ul className="flex">
                        <li className="w-3/6 ">
                          <p className="font-semibold w-full text-pretty">
                            {options.data.tenBietDuoc}
                          </p>
                          <span className="text-xs w-full text-gray-500">
                            {' '}
                            ({options.data.label})
                          </span>
                        </li>
                        <li className="w-1/6 border-x flex items-center justify-center">
                          {moment(options.data.hanDung).format('DD/MM/YYYY')}
                        </li>
                        <li className="w-1/6 border-r  flex items-center justify-center">
                          {options.data.ton}
                        </li>
                        <li className="w-1/6  flex items-center justify-center">
                          {options.data.dvt}
                        </li>
                      </ul>
                    )}
                  />
                </div>
              </div>
              <div className="h-[47.5rem]">
                {/* //h-[55%] border-b*/}
                <div className="h-[100%] p-2 pt-0 ">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      pagination={false}
                      scroll={{ y: 375 }}
                      dataSource={thuocBN}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          render: (text, record, index) => ++index,
                          width: 40,
                          align: 'center',
                        },
                        {
                          title: 'Tên hàng',
                          dataIndex: 'stt',
                          key: 'stt',
                          render: (text, record, index) => record.thuocVatTu.tenBietDuoc,
                        },
                        {
                          title: 'Số lô',
                          dataIndex: 'soLo',
                          key: 'soLo',
                          width: 100,
                          align: 'center',
                        },
                        {
                          title: 'Hạn dùng',
                          dataIndex: 'hanDung',
                          key: 'hanDung',
                          width: 90,
                          align: 'center',
                          render: (text, record, index) =>
                            text ? moment(text).format('DD/MM/YYYY') : '-',
                        },
                        {
                          title: 'Đơn vị',
                          dataIndex: 'stt',
                          key: 'stt',
                          align: 'center',
                          render: (text, record, index) => record.thuocVatTu.dvt,
                          width: 70,
                        },
                        {
                          title: 'Số lượng',
                          dataIndex: 'SL',
                          key: 'SL',
                          align: 'center',
                          render: (text, record, index) => (
                            <Input
                              size="small"
                              value={text}
                              type="Number"
                              min={1}
                              onChange={(e) => handleCount(e, record.ID)}
                            />
                          ),
                          width: 80,
                        },
                        {
                          title: 'Tồn',
                          dataIndex: 'tonKho',
                          key: 'tonKho',
                          width: 65,
                          align: 'center',
                        },
                        {
                          title: 'Đơn giá',
                          dataIndex: 'stt',
                          key: 'stt',
                          render: (text, record, index) => formatNumber(record.thuocVatTu.giaBan),
                          width: 100,
                          align: 'right',
                        },
                        {
                          title: 'Tổng tiền',
                          dataIndex: 'stt',
                          key: 'stt',
                          render: (text, record, index) =>
                            formatNumber(record.thuocVatTu.giaBan * record.SL),
                          width: 100,
                          align: 'right',
                        },
                        {
                          title: '',
                          dataIndex: 'action',
                          key: 'action',
                          render: (text, record, index) => (
                            <DeleteOutlined
                              onClick={() => deleteThuocVatTu(record.ID)}
                              className="text-red-500"
                              style={{ fontSize: 20 }}
                            />
                          ),
                          width: 40,
                          align: 'center',
                        },
                      ]}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-1/3 ">
              <div className="p-2">
                <div className="flex gap-2">
                  <Select
                    onSearch={debounceDropDown}
                    value={null}
                    onChange={fetchInfoBN}
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Tìm kiếm Tên bệnh nhân, Mã BN, SĐT, CCCD..."
                    suffixIcon={<SearchOutlined className=" " />}
                    options={listBN?.map((items) => ({
                      label: items.tenbenhnhan,
                      value: items.idbn,
                      MaBN: items.mabenhnhan,
                      ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
                      tinh: items.tentinhtp,
                      quan: items.tenquanhuyen,
                      xa: items.tenphuongxa,
                      gioiTinh: items.gioitinh,
                      diaChi: items.diachi,
                    }))}
                    optionRender={(options) => (
                      <ul className="border-b pb-2">
                        <li>
                          <div className="flex justify-between w-full">
                            <p className="font-semibold">
                              {options.data.label} - {options.data.MaBN}
                            </p>
                            <p> Ngày sinh: {options.data.ngaySinh} </p>
                          </div>

                          <i className="text-gray-500 text-wrap">
                            Địa chỉ: {options.data.diaChi}
                            {options.data.xa && `, ${options.data.xa}`}
                            {options.data.quan && `, ${options.data.quan}`}
                            {options.data.tinh && `, ${options.data.tinh}`}
                          </i>
                        </li>
                      </ul>
                    )}
                    showSearch
                    allowClear
                    className="w-full"
                  />
                  <button
                    onClick={onClickCreateCustomer}
                    className="group cursor-pointer outline-none hover:rotate-90 duration-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      className="stroke-sky-400 fill-none group-hover:fill-sky-100 "
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        strokeWidth="1.5"
                      ></path>
                      <path d="M8 12H16" strokeWidth="1.5"></path>
                      <path d="M12 16V8" strokeWidth="1.5"></path>
                    </svg>
                  </button>
                </div>
                <div className="mt-2">
                  <InfoBN InfoBNTTC={InfoBNTTC} setInfoBNTTC={setInfoBNTTC} infoBN={infoBN} />
                </div>
                <div className="mt-2">
                  <Price
                    thuocBN={thuocBN}
                    infoBN={infoBN}
                    xuatHD={xuatHD}
                    setXuatHD={setXuatHD}
                    setIdPhuongThuc={setIdPhuongThuc}
                    idPhuongThuc={idPhuongThuc}
                    tongGiaTien={tongGiaTien}
                  />
                </div>
                <div className="mt-16">
                  <Button
                    disabled={!infoBN || thuocBN.length === 0}
                    onClick={showModalPay}
                    className="w-full"
                    variant="contained"
                    size="small"
                    color="success"
                  >
                    Thanh toán
                  </Button>
                </div>
              </div>
            </div>
          </div>
      <PayModal
        benhNhanTT={benhNhanTT}
        resetForm={resetForm}
        dataBNTTC={dataBNTTC}
        thuocBN={thuocBN}
        cancelModal={cancelModal}
        isModal={isModal}
        tongGiaTien={tongGiaTien - (infoVC?.tongGiam ?? 0)}
      />
      <CreateCustomer />
    </>
  )
}

export default BanLe
