import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { versionService } from '../../services/version/versionService'
import { sieuAmService } from '../../services/SieuAm/sieuAmService'
import { generatePhieuSieuAm } from '../../utils/report/san/phieuSieuAm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* sieuAmSaga() {
  //-------------- ADD NEW VERSION ------------//
  yield takeLatest(typeAction.POST_BIEU_MAU_SIEU_AM, function* postBieuMau({ type, form, reset }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => sieuAmService.postBieuMau(form))
      const bieuMau = yield call(() => sieuAmService.getALLBieuMau(1)) // 1 là loại biểu mẫu siêu âm
      yield put({
        type: typeAction.DISPATCH_ALL_BIEU_MAU,
        payload: bieuMau.data,
      })
      reset()
      Toast.fire({
        icon: 'success',
        title: 'Thêm Biểu mẫu thành công!',
      })
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Thêm biểu mẫu thất bại!',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })

  //---------------- GET ALL  BIEU MAU--------------//
  yield takeLatest(typeAction.GET_ALL_BIEU_MAU, function* getBieuMau({ type, idLoaiBM }) {
    try {
      const bieuMau = yield call(() => sieuAmService.getALLBieuMau(idLoaiBM)) // 1 là loại biểu mẫu siêu âm, 2 là khám sức khỏe, 3 là nội soi
      yield put({
        type: typeAction.DISPATCH_ALL_BIEU_MAU,
        payload: bieuMau.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //------------------ GET INFO BENH NHAN --------------//a
  yield takeLatest(typeAction.GET_INFO_BN_SIEU_AM, function* getInfo({ type, id, idcdha }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => sieuAmService.getInfoBn(id, idcdha))

      yield put({
        type: typeAction.DISPATCH_INFO_BN_SIEU_AM,
        payload: result.data,
      })
      const history = yield call(() => sieuAmService.getHistory(id))
      yield put({
        type: typeAction.DISPATCH_HISTORY_BN_SIEU_AM,
        payload: history.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    } catch (err) {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      console.log(err)
    }
  })
  //------------------ GET INFO BENH NHAN CHO --------------//a
  yield takeLatest(typeAction.GET_INFO_BN_PENDING_SIEU_AM, function* getInfo({ type, id, idcdha }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => sieuAmService.getInfoBnPending(id, idcdha))

      yield put({
        type: typeAction.DISPATCH_INFO_BN_SIEU_AM,
        payload: result.data[0],
      })
      const history = yield call(() => sieuAmService.getHistory(id))
      yield put({
        type: typeAction.DISPATCH_HISTORY_BN_SIEU_AM,
        payload: history.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    } catch (err) {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      console.log(err)
    }
  })
  //----------------- SAVE BENH NHAN SIEU AM ------------//
  yield takeLatest(
    typeAction.POST_BENH_NHAN_SIEU_AM,
    function* postBnSieuAm({ type, form, idcn, reload, listImagePrint }) {
      try {
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        yield put({ type: typeAction.OPEN_LOADING_PAGE })
        const result = yield call(() => sieuAmService.postBnSieuAm(form, idcn))
        reload()
        const { idbnttc, idbncdha } = result.data
        //call api lay info de xuat report
        const ttBN = yield call(() => sieuAmService.getInfoBn(idbnttc, idbncdha))
        yield call(() => generatePhieuSieuAm(ttBN.data, listImagePrint, PkDangNhap))

        yield put({
          type: typeAction.RESET_BENN_NHAN_SIEU_AM,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
      } finally {
        yield put({ type: typeAction.CLOSE_LOADING_PAGE })
      }
    },
  )
}
