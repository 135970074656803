import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDoiTacSchema } from '../../../../schemas/addDoitacSchema'
import { https } from '../../../../services/apiService'
import { addPartner } from '../../../../store/actions/partnerAction'
import ToastCus from '../../../../utils/Toast'

function ModalCreatePartner({ isModalOpen, setIsModalOpen }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [cityData, setCityData] = useState(null)
  const [districtData, setDistrictData] = useState(null)
  const [wardsData, setWardsData] = useState(null)

  const dispatch = useDispatch()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maDoiTac: '',
      tenDoiTac: '',
      diaChi: '',
      idTinh: '',
      idQuan: '',
      idPhuong: '',
      dienThoai: '',
      maSoThue: '',
      fax: '',
      email: '',
      website: '',
      nguoiLienHe: '',
      diDong: '',
      ghiChu: '',
      taiKhoanNganHang: '',
      nganHang: '',
      tenTaiKhoan: '',
      tenVietTat: '',
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addDoiTacSchema,
  })

  const handleCloseModal = () => {
    setIsModalOpen(false)
    formik.resetForm()
  }

  const handleSubmit = async (values, actions) => {
    if (values.idTinh === '') {
      formik.setErrors({
        idTinh: 'require',
      })
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn tỉnh hoặc thành phố',
      })
      return
    }
    if (values.idQuan === '') {
      formik.setErrors({
        idQuan: 'require',
      })
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn quận hoặc huyện',
      })
      return
    }
    if (values.idPhuong === '') {
      formik.setErrors({
        idPhuong: 'require',
      })
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn phường hoặc xã',
      })
      return
    }
    dispatch(
      addPartner(
        {
          nguoiTao: infoUser.idnv,
          ...values,
        },
        handleCloseModal,
      ),
    )
  }

  async function getDataDistrict(idQuan) {
    try {
      const result = await https.get(`QuanHuyen/${idQuan}`)
      setDistrictData(result.data)
    } catch (err) {
      console.error(err)
    }
  }

  async function getDataWards(idPhuong) {
    try {
      const result = await https.get(`PhuongXa/${idPhuong}`)
      setWardsData(result.data)
    } catch (err) {
      console.error(err)
    }
  }

  async function getDataCity() {
    try {
      const result = await https.get(`tinh`)
      setCityData(result.data)
    } catch (err) {
      console.error(err)
    }
  }

  const handleCityChange = (value) => {
    getDataDistrict(value)
    setDistrictData([])
    setWardsData([])
    formik.setFieldValue('idTinh', value)
  }

  const handleDistrictChange = (value) => {
    getDataWards(value)
    setWardsData([])
    formik.setFieldValue('idQuan', value)
  }

  const handleWardChange = (value) => {
    formik.setFieldValue('idPhuong', value)
  }

  useEffect(() => {
    getDataCity()
  }, [])

  return (
    <Modal
      width={650}
      className="text-center"
      title="Thêm Đối tác"
      okText="Thêm"
      cancelText="Huỷ bỏ"
      open={isModalOpen}
      onOk={formik.handleSubmit}
      onCancel={handleCloseModal}
      maskClosable={false}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-3">
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Tên đối tác:
            </label>
            <Input
              status={formik.errors.tenDoiTac ? 'error' : ''}
              name="tenDoiTac"
              value={formik.values.tenDoiTac}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Mã đối tác:
            </label>
            <div className="flex w-full gap-2">
              <Input
                status={formik.errors.maDoiTac ? 'error' : ''}
                name="maDoiTac"
                value={formik.values.maDoiTac}
                onChange={formik.handleChange}
                size="small"
              />
              <label className="w-60 text-end">
                <span className="text-red-500 text-xs">(*)</span>Mã số thuế:
              </label>
              <Input
                status={formik.errors.maSoThue ? 'error' : ''}
                name="maSoThue"
                value={formik.values.maSoThue}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Email:</label>
            <div className="flex w-full gap-2">
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                size="small"
              />
              <label className="w-60 text-end">Fax:</label>
              <Input
                name="fax"
                value={formik.values.fax}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Tên viết tắt:</label>
            <Input
              status={formik.errors.tenVietTat ? 'error' : ''}
              name="tenVietTat"
              value={formik.values.tenVietTat}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Số điện thoại:</label>
            <Input
              status={formik.errors.dienThoai ? 'error' : ''}
              name="dienThoai"
              value={formik.values.dienThoai}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Website:</label>
            <Input
              name="website"
              value={formik.values.website}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Địa chỉ:</label>
            <Input
              name="diaChi"
              value={formik.values.diaChi}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-40 text-end ">
              <span className="text-red-500 text-xs">(*)</span>Tỉnh / Thành phố:
            </label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              status={formik.errors.idTinh ? 'error' : ''}
              onChange={handleCityChange}
              options={cityData?.map((tinh) => ({
                label: tinh.tenTinh,
                value: tinh.idTinh,
              }))}
              className="w-full truncate"
              placeholder="Chọn Tỉnh / Thành phố"
              size="small"
              name="idTinh"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-40 text-end ">
              <span className="text-red-500 text-xs">(*)</span>Quận / Huyện:
            </label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              status={formik.errors.idQuan ? 'error' : ''}
              onChange={handleDistrictChange}
              options={districtData?.map((quan) => ({
                label: quan.tenQuan,
                value: quan.idQuan,
              }))}
              className="w-full truncate"
              placeholder="Chọn Quận / Huyện"
              size="small"
              name="idQuan"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-40 text-end ">
              <span className="text-red-500 text-xs">(*)</span>Phường / Xã:
            </label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              status={formik.errors.idPhuong ? 'error' : ''}
              onChange={handleWardChange}
              options={wardsData?.map((phuong) => ({
                label: phuong.tenPhuong,
                value: phuong.idPhuong,
              }))}
              className="w-full truncate"
              placeholder="Chọn Phường / Xã"
              size="small"
              name="idPhuong"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Người liên hệ:</label>
            <div className="flex w-full gap-2">
              <Input
                name="nguoiLienHe"
                value={formik.values.nguoiLienHe}
                onChange={formik.handleChange}
                size="small"
              />
              <label className="w-72 text-end">Số điện thoại:</label>
              <Input
                name="diDong"
                value={formik.values.diDong}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Tài khoản NH:</label>
            <div className="flex w-full gap-2">
              <Input
                name="taiKhoanNganHang"
                value={formik.values.taiKhoanNganHang}
                onChange={formik.handleChange}
                size="small"
              />
              <label className="w-60 text-end">Ngân hàng:</label>
              <Input
                name="nganHang"
                value={formik.values.nganHang}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Tên tài khoản:</label>
            <Input
              name="tenTaiKhoan"
              value={formik.values.tenTaiKhoan}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Ghi chú:</label>
            <Input
              name="ghiChu"
              value={formik.values.ghiChu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
        </div>
        <div></div>
      </form>
    </Modal>
  )
}

ModalCreatePartner.propTypes = {}

export default ModalCreatePartner
