import React from 'react'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { useSelector } from 'react-redux'
const Index = ({ infoBN }) => {
  return (
    <div className="container mx-auto flex gap-1 flex-col">
      <ul className="flex gap-1 w-full">
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/2 text-gray-500">Chiều cao</p>
          <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
            {infoBN?.chieucao ?? 0} Cm
          </span>
        </li>
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/2 text-gray-500">Cân nặng</p>
          <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
            {infoBN?.cannang ?? 0} Kg
          </span>
        </li>
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/3 text-gray-500">Mạch</p>
          <span className="w-2/3 text-center font-semibold text-green-700 text-sm">
            {infoBN?.mach ?? 0} Lần/phút
          </span>
        </li>
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/2 text-gray-500">Huyết áp</p>
          <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
            {infoBN?.huyetap ?? 0} mmHg
          </span>
        </li>
      </ul>
      <ul className="flex gap-1 w-full">
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/2 text-gray-500">Nhiệt độ</p>
          <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
            {infoBN?.nhietdo ?? 0} &#8451;
          </span>
        </li>
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/3 text-gray-500">Nhịp thở</p>
          <span className="w-2/3 text-center font-semibold text-green-700 text-sm">
            {infoBN?.nhiptho ?? 0} Lần/Phút
          </span>
        </li>
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/2 text-gray-500">Spo2</p>
          <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
            {infoBN?.spO2 ?? 0} %
          </span>
        </li>
        <li
          className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <p className="w-1/2 text-gray-500">BMI</p>
          <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
            {infoBN?.cannang
              ? formatNumberVND(infoBN?.cannang / Math.pow(infoBN?.chieucao / 100, 2))
              : 0}
          </span>
        </li>
      </ul>
    </div>
  )
}

export default Index
