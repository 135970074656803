import React from 'react'
import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { updateKhuVuc } from '../../../store/actions/suCoAction'
const UpdateKhuVuc = ({ fetchVung, infoVung, isUpdate, setIsUpdate }) => {
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsUpdate(false)
  }
  const onReset = () => {
    fetchVung()
    handleCancel()
    formik.handleReset()
  }
  const handleSubmit = (values) => {
    dispatch(updateKhuVuc(values, onReset))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idvung: infoVung?.IDVUNG,
      tenvung: infoVung?.TENVUNG,
    },
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Cập nhập"
      cancelText="Huỷ bỏ"
      onCancel={handleCancel}
      open={isUpdate}
      title={<p className="font-semibold text-xl w-full text-center text-blue-500">KHU VỰC</p>}
    >
      <div>
        <label className="font-semibold">Tên khu vực:</label>
        <Input value={formik.values.tenvung} onChange={formik.handleChange} name="tenvung" />
      </div>
    </Modal>
  )
}

export default UpdateKhuVuc
