import * as typeAction from '../constants/constants'

export const getAllGoiKhamKhachHangCRM = () => ({
  type: typeAction.GET_ALL_GOIKHAM_KHACHHANG_CRM,
})

export const addGoiKhamKhachHangCRM = (form) => ({
  type: typeAction.ADD_GOIKHAM_KHACHHANG_CRM,
  form,
})

export const editGoiKhamKhachHangCRM = (form) => ({
  type: typeAction.EDIT_GOIKHAM_KHACHHANG_CRM,
  form,
})

export const getGoiKhamKhachHangCRMById = (id) => ({
  type: typeAction.GET_GOIKHAM_KHACHHANG_CRM_BY_ID,
  id,
})
