import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from 'antd'
import { debounce } from 'lodash'
import { childrenClinicService } from '../../../../../services/childrenClinic/childrenClinicService'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { https } from '../../../../../services/apiService'

function FormBenhNhanTTC({ formik, validateUpdateBNTTC }) {
  const [ChanDoan, setChanDoan] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)
  const [allTinhTrangRaVien, setAllTinhTrangRaVien] = useState([])

  const { loidan } = useSelector((state) => state.childrenClinicReducer)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }
  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleSlected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  const debounceDropDown = useCallback(
    debounce((keyword) => {
      fechChanDoan(keyword)
    }, 300),
    [],
  )

  const onClickChanDoan = (value, options, key, keyIDC) => {
    const newValue = options.mabenh + '-' + options.icD10TV + '/'
    formik.setFieldValue(key, newValue)
    formik.setFieldValue(keyIDC, value)
    //reset lai chan doan
    fechChanDoan('')
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`BenhNhanTTC/GetTinhTrangRaVien`)
        setAllTinhTrangRaVien(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  
  return (
    <form>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Lý do khám: </label>
        <Input
          status={formik.errors.lydokham ? 'error' : ''}
          onChange={formik.handleChange}
          value={formik.values.lydokham}
          name="lydokham"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Bệnh sử: </label>
        <Input
          status={formik.errors.benhsu ? 'error' : ''}
          onChange={formik.handleChange}
          value={formik.values.benhsu}
          name="benhsu"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Triệu chứng: </label>
        <Input
          status={formik.errors.trieuchung ? 'error' : ''}
          onChange={formik.handleChange}
          value={formik.values.trieuchung}
          name="trieuchung"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Chẩn đoán ban đầu: </label>
        <Input
          onChange={formik.handleChange}
          value={formik.values.chandoanbandau}
          name="chandoanbandau"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">ICD-10 CĐ ban đầu: </label>
        <Select
          showSearch
          filterOption={false}
          className="w-full"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
          onChange={(value, options) =>
            onClickChanDoan(value, options, 'chandoanbandau', 'idicD10BANDAU')
          }
          onSearch={debounceDropDown}
          suffixIcon={<SearchOutlined className=" " />}
          notFoundContent={null}
          value={formik.values.idicD10BANDAU}
          options={ChanDoan?.map((items) => ({
            label: items.mabenh + ' - ' + items.icD10TV,
            value: items.idicD10,
            mabenh: items.mabenh,
            ...items,
          }))}
          optionRender={(options) => (
            <ul className="flex">
              <li className="w-1/6">{options.data.mabenh}</li>
              <li className="w-5/6 border-l px-5">{options.data.label}</li>
            </ul>
          )}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Chẩn đoán: </label>
        <Input
          onChange={formik.handleChange}
          value={formik.values.chandoan}
          name="chandoan"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">IDC-10 CĐ: </label>
        <Select
          showSearch
          filterOption={false}
          className="w-full"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
          onChange={(value, options) => onClickChanDoan(value, options, 'chandoan', 'idchandoan')}
          onSearch={debounceDropDown}
          suffixIcon={<SearchOutlined className=" " />}
          notFoundContent={null}
          value={formik.values.idchandoan}
          options={ChanDoan?.map((items) => ({
            label: items.mabenh + ' - ' + items.icD10TV,
            value: items.idicD10,
            mabenh: items.mabenh,
            ...items,
          }))}
          optionRender={(options) => (
            <ul className="flex">
              <li className="w-1/6">{options.data.mabenh}</li>
              <li className="w-5/6 border-l px-5">{options.data.label}</li>
            </ul>
          )}
        />
      </div>

      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Lời dặn: </label>
        <Input
          status={formik.errors.loidan ? 'error' : ''}
          onChange={formik.handleChange}
          value={formik.values.loidan}
          name="loidan"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
        />
      </div>
      <div className="flex gap-1 mt-1">
        <label className="font-semibold w-44 text-end">Gợi ý lời dặn: </label>
        <Select
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={loidan?.map((items) => ({
            label: items.loiDan,
            value: items.loiDan,
          }))}
          placeholder="Chọn gọi ý lời dặn"
          showSearch
          status={formik.errors.idloidan ? 'error' : ''}
          onChange={handleSlected('loidan')}
          value={null}
          name="loidan"
          size="small"
          disabled={validateUpdateBNTTC}
          style={{ color: 'black' }}
          className="w-full truncate"
        />
      </div>

      {isExpanded && (
        <>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Quá trình bệnh lý: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.quatrinhbenhly}
              name="quatrinhbenhly"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tiền sử gia đình: </label>
            <Input
              placeholder="Không"
              onChange={formik.handleChange}
              value={formik.values.tieusugiadinh}
              name="tieusugiadinh"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tóm tắt kết quả CLS: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.kqcls}
              name="kqcls"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Toàn thân: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.toanthan}
              name="toanthan"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tuần hoàn: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.tuanhoan}
              name="tuanhoan"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Hô hấp: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.hohap}
              name="hohap"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tiêu hóa: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.tieuhoa}
              name="tieuhoa"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Thận-tiết niệu: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.thantietnieu}
              name="thantietnieu"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Thần kinh: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.thankinh}
              name="thankinh"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Cơ-xương-khớp: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.coxuongkhop}
              name="coxuongkhop"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tai-mũi-họng: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.taimuihong}
              name="taimuihong"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Răng-hàm-mặt: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.ranghammat}
              name="ranghammat"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Mắt: </label>
            <Input
              placeholder="Bình thường"
              onChange={formik.handleChange}
              value={formik.values.mat}
              name="mat"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Bệnh lý khác: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.benhlykhac}
              name="benhlykhac"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Đã xử lý: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.daxuly}
              name="daxuly"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Chẩn đoán ra viện: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.chandoanravien}
              name="chandoanravien"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">ICD-10 CĐ ra viện: </label>
            <Select
              showSearch
              filterOption={false}
              className="w-full"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
              onChange={(value, options) =>
                onClickChanDoan(value, options, 'chandoanravien', 'idicD10RAVIEN')
              }
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined className=" " />}
              notFoundContent={null}
              value={formik.values.idicD10RAVIEN}
              options={ChanDoan?.map((items) => ({
                label: items.mabenh + ' - ' + items.icD10TV,
                value: items.idicD10,
                mabenh: items.mabenh,
                ...items,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-1/6">{options.data.mabenh}</li>
                  <li className="w-5/6 border-l px-5">{options.data.label}</li>
                </ul>
              )}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Điều trị ngoại trú: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.dieutringoaitru}
              name="dieutringoaitru"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Diễn biến lâm sàng: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.dienbienlamsang}
              name="dienbienlamsang"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Bệnh kèm theo: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.benhkemtheo}
              name="benhkemtheo"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">ICD-10 Bệnh kèm theo: </label>
            <Select
              showSearch
              filterOption={false}
              className="w-full"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
              onChange={(value, options) =>
                onClickChanDoan(value, options, 'benhkemtheo', 'idicD10BENHKEMTHEO')
              }
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined className=" " />}
              notFoundContent={null}
              value={formik.values.idicD10BENHKEMTHEO}
              options={ChanDoan?.map((items) => ({
                label: items.mabenh + ' - ' + items.icD10TV,
                value: items.idicD10,
                mabenh: items.mabenh,
                ...items,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-1/6">{options.data.mabenh}</li>
                  <li className="w-5/6 border-l px-5">{options.data.label}</li>
                </ul>
              )}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Biến chứng: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.bienchung}
              name="bienchung"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">ICD-10 Biến chứng: </label>
            <Select
              showSearch
              filterOption={false}
              className="w-full"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
              onChange={(value, options) =>
                onClickChanDoan(value, options, 'bienchung', 'idicD10BIENCHUNG')
              }
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined className=" " />}
              notFoundContent={null}
              value={formik.values.idicD10BIENCHUNG}
              options={ChanDoan?.map((items) => ({
                label: items.mabenh + ' - ' + items.icD10TV,
                value: items.idicD10,
                mabenh: items.mabenh,
                ...items,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-1/6">{options.data.mabenh}</li>
                  <li className="w-5/6 border-l px-5">{options.data.label}</li>
                </ul>
              )}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Phương pháp điều trị: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.ppdieutri}
              name="ppdieutri"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Thanh toán lúc ra viện: </label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.ttlucravien}
              name="ttlucravien"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Hướng xử lý:</label>
            <Input
              onChange={formik.handleChange}
              value={formik.values.huongxuly}
              name="huongxuly"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tình trạng ra viện:</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              suffixIcon={<FilterOutlined />}
              onChange={(value) => formik.setFieldValue('tinhtrangravien', value)}
              options={allTinhTrangRaVien?.map((items) => ({
                label: items.tinhtrang,
                value: items.idttrv,
              }))}
              value={formik.values.idttrv}
              className="w-full text-start"
              size="small"
              disabled={validateUpdateBNTTC}
              style={{ color: 'black' }}
            />
          </div>
        </>
      )}
      <div className="flex justify-center mt-1">
        <button
          type="button"
          onClick={toggleExpand}
          className="cursor-pointer flex justify-between text-blue-500 px-1 py-1 rounded-md  tracking-wider    duration-500  w-[100px]"
        >
          {isExpanded ? (
            <>
              Thu gọn
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 animate-bounce"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            </>
          ) : (
            <>
              Mở rộng
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 animate-bounce"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </>
          )}
        </button>
      </div>
    </form>
  )
}

FormBenhNhanTTC.propTypes = {}

export default React.memo(FormBenhNhanTTC)
