import React, { useState } from 'react'
import * as typeAction from '../../../../store/constants/constants'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { Button } from 'antd'
import * as XLSX from 'xlsx'
import { useDispatch } from 'react-redux'
import fileMau from '../../../../data/Form/import_CRM.xlsx'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const ButtonImportExcel = ({ openNotificationWithIcon }) => {
  const dispatch = useDispatch()
  const handleDownloadFile = () => {
    // Tạo một đường dẫn đến tập tin bạn muốn tải xuống
    // Tạo một thẻ a ẩn
    const link = document.createElement('a')
    link.href = fileMau
    link.target = '_blank'
    link.download = 'Mẫu import danh sách bệnh nhân CRM.xlsx'
    // Thêm thẻ a vào DOM và kích hoạt sự kiện nhấp vào nó
    document.body.appendChild(link)
    link.click()

    // Xóa thẻ a sau khi tải xuống hoàn tất
    document.body.removeChild(link)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const fileName = file.name
    const extension = fileName.split('.').pop().toLowerCase()
    if (extension !== 'xlsx' && extension !== 'xls') {
      // kiểm tra xem có phải là file excel hay không
      openNotificationWithIcon('error', 'Chỉ chấp nhận tệp Excel (xlsx, xls)')
      return
    }
    const render = new FileReader()
    render.readAsArrayBuffer(file)
    render.onload = (e) => {
      const excelData = e.target.result
      const workbook = XLSX.read(excelData, {
        type: 'buffer',
        cellDates: true,
      })
      const worksheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[worksheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: null,
      })

      // Process the data to format the dates correctly
      for (let i = 0; i < data.length; i++) {
        const items = data[i]

        if (!items.TENBN) {
          openNotificationWithIcon('error', `Chưa có tên bệnh nhân, dòng: ${i + 1}`)
          return
        }
        if (!items.GIOITINH) {
          openNotificationWithIcon('error', `Chưa có giới tính , dòng: ${i + 1}`)
          return
        }
        if (items.GIOITINH !== 'Nam' && items.GIOITINH !== 'Nữ') {
          openNotificationWithIcon(
            'error',
            `Sai giới tính, dòng: ${i + 1}. Vui lòng nhập: Nam hoặc Nữ`,
          )
          return
        }
        if (!items.NGAYSINH) {
          openNotificationWithIcon('error', `Chưa có ngày sinh , dòng: ${i + 1}`)
          return
        }
        if (items.NGAYSINH) {
          let date = moment(items.NGAYSINH, 'DD/MM/YYYY')
          if (!isNaN(date)) {
            items.NGAYSINH = moment(items.NGAYSINH, 'DD/MM/YYYY').format('DD/MM/YYYY')
          } else {
            // If the date is not parsed correctly, log the issue
            openNotificationWithIcon(
              'error',
              `Sai ngày sinh, dòng: ${i + 1}. Vui lòng nhập: ngày/tháng/năm`,
            )
            return
          }
        }
        if (!items.MAKH) {
          openNotificationWithIcon('error', `Chưa có mã khách hàng , dòng: ${i + 1}`)
          return
        }
        if (!items.MAGK) {
          openNotificationWithIcon('error', `Chưa có mã gói khám , dòng: ${i + 1}`)
          return
        }
      }

      dispatch({
        type: typeAction.IMPORT_DATA_CRM,
        payload: data,
      })
    }
  }
  return (
    <>
      <div className="flex gap-5 items-center">
        <Button className="my-2 p-0 bg-blue-500 text-white">
          <label className="cursor-pointer px-4 py-1" for="file-upload">
            Chọn file danh sách
          </label>
          <input
            id="file-upload"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            value={''}
            onChange={handleFileChange}
          />
        </Button>
        <button
          onClick={handleDownloadFile}
          className="text-green-700 text-base cursor-pointer"
          size="small"
        >
          <VerticalAlignBottomOutlined />
          File mẫu
        </button>
      </div>
    </>
  )
}

export default ButtonImportExcel
