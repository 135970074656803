import React, { useEffect, useState, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import _ from 'lodash'
import * as typeAction from '../../../store/constants/constants'
import { Input, Menu, ConfigProvider, Table, Tooltip, Modal, Checkbox } from 'antd'
import { ExclamationCircleFilled, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  DetailBranch,
  deleteBranchByID,
  listBranchDetail_Action,
} from '../../../store/actions/BranchAction'
import CachedIcon from '@mui/icons-material/Cached'
import IconButton from '@mui/material/IconButton'
import { listAllChuyeKhoa_Action } from '../../../store/actions/thuocVTAction'
import ModalAddBranch from './Modal/ModalAddBranch'
import ModalInfoBranch from './Modal/ModalInfoBranch'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const Branch = () => {
  const ID_COMPANY_CHAN_VAN = 1
  const { listBranhDetail } = useSelector((state) => state.branchReducer)
  const [idCompany, setIdCompany] = useState(ID_COMPANY_CHAN_VAN)
  const [isLoading, setIsLoading] = useState(false)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [isModal, setIsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()
  const debounceDropDown = useCallback(
    _.debounce((keyWord, id) => {
      dispatch(listBranchDetail_Action(keyWord, id, setIsLoading))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const showModal = () => {
    setIsModal(true)
  }
  const onSearchInput = (e) => {
    const { value } = e.target
    debounceDropDown(value, idCompany)
  }
  const onReload = () => {
    dispatch(listBranchDetail_Action('', idCompany, setIsLoading))
  }
  const handleClickCompany = (e) => {
    setIdCompany(e.key)
    dispatch(listBranchDetail_Action('', e.key, setIsLoading))
  }
  //---------- show modal edit ----------//
  const showEdit = (id) => {
    setIsEdit(true)
    dispatch(DetailBranch(id))
  }

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: 'Bạn có muốn xoá chi nhánh này không',
      icon: <ExclamationCircleFilled />,
      content: `Chi nhánh: ${name}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deleteBranchByID(id, onReload))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  useEffect(() => {
    dispatch(listBranchDetail_Action('', ID_COMPANY_CHAN_VAN, setIsLoading))
    dispatch(listAllCompanyAction())
    dispatch(listAllChuyeKhoa_Action())
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã',
      dataIndex: 'machinhanh',
      key: 'machinhanh',
      align: 'center',
      width: 80,
    },
    {
      title: 'Tên chi nhánh ',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      sorter: {
        compare: (a, b) => {
          return (a?.diachi ?? '').localeCompare(b?.diachi ?? '')
        },
      },
    },
    {
      title: 'Tỉnh/TP',
      dataIndex: 'tentinh',
      key: 'tentinh',
      align: 'center',
      width: 120,
      // sorter: (a, b) => a.idtinh - b.idtinh,
      sorter: {
        compare: (a, b) => {
          return (a?.tentinh ?? '').localeCompare(b?.tentinh ?? '')
        },
      },
    },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      align: 'center',
      width: 140,
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaykhaitruong ?? '01/01/2000').localeCompare(
            b?.ngaykhaitruong ?? '01/01/2000',
          )
        },
      },
    },
    {
      title: 'Loại',
      dataIndex: 'loaichinhanh',
      key: 'loaichinhanh',
      align: 'center',
      width: 85,
      render: (text, record, index) =>
        text === 'VANPHONG' ? 'Văn phòng' : text === 'CHINHANH' ? 'Chi nhánh' : '',
      sorter: {
        compare: (a, b) => {
          return a?.loaichinhanh.localeCompare(b?.loaichinhanh)
        },
      },
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'tenchuyenkhoa',
      key: 'tenchuyenkhoa',
      align: 'center',
      width: 110,
      sorter: {
        compare: (a, b) => {
          return (a?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim() ?? '')?.localeCompare(
            b?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim() ?? '',
          )
        },
      },
      render: (text, record, index) => text?.replace('Chuyên khoa', '')?.trim(),
    },
    {
      title: 'Sử dụng',
      dataIndex: 'sudung',
      key: 'sudung',
      align: 'center',
      width: 85,
      render: (text, record, index) => (text === 1 ? <Checkbox checked /> : <Checkbox disabled />),
      sorter: (a, b) => a.sudung - b.sudung,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      render: (text, record, index) => (
        <>
          <ul className="flex justify-around">
            <li>
              <Tooltip title="Xem" color="#108ee9">
                <EyeOutlined
                  onClick={() => showEdit(record.idchinhanh)}
                  className="text-xl text-[#108ee9]  cursor-pointer"
                />
              </Tooltip>
            </li>
            {/* <li>
          <Tooltip title="Xoá" color="red">
            <DeleteOutlined
                onClick={()=>showDeleteConfirm(idchinhanh,tenchinhanh)}
              className="text-xl text-red-500  cursor-pointer"
            />
          </Tooltip>
        </li> */}
          </ul>
        </>
      ),
    },
  ]

  return (
    <>
      <div className="w-full h-full p-5 bg-[#E7E7E7]">
        <div className="bg-white h-full p-2 rounded-2xl">
          <div className="flex h-full border rounded-xl">
            <div className="w-1/5 p-2 border-r">
              <div className="">
                <div className="flex justify-between">
                  <h2 className="text-xl font-semibold text-gray-400">Công ty</h2>
                  <button className="flex items-center mr-2 bg-blue-500 gap-1 px-4 py-2 cursor-pointer text-white font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 hover:gap-1 hover:translate-x-2">
                    Tạo
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </div>

                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      components: {
                        Menu: {
                          itemHeight: 60,
                        },
                      },
                    }}
                  >
                    <Menu
                      onClick={handleClickCompany}
                      style={{ border: 'unset' }}
                      defaultSelectedKeys={['1']}
                    >
                      {listCompany?.map((items) => (
                        <Menu.Item key={items.idct} style={{ lineHeight: '30px' }}>
                          <h2 className="font-semibold text-base mt-1">{items.tenct}</h2>

                          <span className="flex items-center text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                              />
                            </svg>
                            {items.diachi}
                          </span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-4/5 ">
              <div className="p-2">
                <div className="flex justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <Input.Search
                      allowClear
                      onChange={onSearchInput}
                      placeholder="Nhập từ khoá"
                      className="w-96"
                    />
                    <IconButton onClick={onReload} color="primary" aria-label="Tải lại">
                      <CachedIcon />
                    </IconButton>
                  </div>
                  <div className="w-full flex justify-end items-center">
                    <div className="text-md font-semibold text-gray-500">
                      Tổng: <span className="text-gray-700">{listBranhDetail?.length ?? 0} CN</span>
                    </div>
                  </div>
                  <div className="w-1/4 flex justify-end items-center pr-1">
                    <button
                      onClick={showModal}
                      class="relative px-6 py-1 rounded-md bg-white isolation-auto z-10 border-2 border-blue-500        before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full duration-200 hover:text-white before:-right-full before:hover:right-0 before:rounded-full  before:bg-blue-500 before:-z-10  before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700"
                    >
                      Thêm chi nhánh
                    </button>
                  </div>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                      },
                    },
                  }}
                >
                  <Table
                    locale={{
                      triggerDesc: 'Sắp xếp giảm dần',
                      triggerAsc: 'Sắp xếp tăng dần',
                      cancelSort: 'Hủy sắp xếp',
                    }}
                    loading={isLoading}
                    pagination={false}
                    scroll={{
                      y: 700,
                    }}
                    columns={columns}
                    dataSource={listBranhDetail}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalInfoBranch />
      <ModalAddBranch isModal={isModal} setIsModal={setIsModal} onReload={onReload} />
      <ModalInfoBranch isEdit={isEdit} setIsEdit={setIsEdit} />
    </>
  )
}

export default Branch
