import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { demoModalCAction, infoBranchLogin } from '../../store/actions/BranchAction'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LayoutApp from '../../HOCs/LayoutApp'
import Dashboard from '../../components/layout/Dashboard/Dashboard'
import logoNhi from '../../assets/images/logo/logo_nhiDong315.png'
import logoSan from '../../assets/images/logo/logo_phuSan.jpg'
import logoMat from '../../assets/images/logo/Logo_mat.png'
import logoLao from '../../assets/images/logo/logo_laoKhoa.png'
import logo from '../../assets/images/logo/logoLogin/6logo.jpg'

import { LazyLoadImage } from 'react-lazy-load-image-component'
const Home = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const idBranch = localStorage.getItem('BRANH_LOGIN')
  useEffect(() => {
    dispatch(infoBranchLogin(idBranch))
  }, [])
  return (
    <>
      {infoUser?.tenNhom === 'Ban Giám đốc' ? (
        <Dashboard />
      ) : (
        <div className="h-full relative bg-cyan-50 border-t">
          <div className=" flex flex-col w-full justify-center items-center absolute top-36">
            <LazyLoadImage src={logo} alt="logo nhi" loading="lazy" />
            <div className="flex justify-center items-center">
              <img className="w-32" src={logoNhi} alt="logo" />
              <h2
                className="text-[#00AFEF] text-3xl font-semibold"
                style={{ fontFamily: 'Manrope, sans-serif' }}
              >
                HỆ THỐNG Y TẾ VÀ BỆNH VIỆN 315
              </h2>
            </div>
            <div className="text-[#00AFEF]  text-center -translate-y-6">
              <h2 className="font-semibold text-2xl" style={{ fontFamily: 'Manrope, sans-serif' }}>
                Chi nhánh {PkDangNhap?.tenChiNhanh}
              </h2>
              <div className="text-lg font-medium">
                <p>
                  <FmdGoodIcon /> {PkDangNhap?.diaChi}{' '}
                </p>
                <p>
                 {PkDangNhap?.dienThoai && (
                    <>
                      <LocalPhoneIcon /> {PkDangNhap?.dienThoai}  - 
                    </>
                  )}{' '}
                 <LocalPhoneIcon />{' '}
                  {PkDangNhap?.sohotline ? PkDangNhap?.sohotline : '0901.315.315'}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Home
