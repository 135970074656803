//###########  Action Reducer ##########
export const OPEN_LOADING = 'OPEN_LOADING'
export const CLOSE_LOADING = 'CLOSE_LOADING'
export const DISPATCH_LIST_BRANCH = 'DISPATCH_LIST_BRANCH'
export const DISPATCH_INFO_LOGIN = 'DISPATCH_INFO_LOGIN'
export const DISPATCH_LOGOUT_USER = 'DISPATCH_LOGOUT_USER'
export const DISPATCH_NGUON_KH = 'DISPATCH_NGUON_KH'
export const DISPATCH_PHONG_KHAM = 'DISPATCH_PHONG_KHAM'
export const DISPATCH_NGHE_NGHIEP = 'DISPATCH_NGHE_NGHIEP'
export const DISPATCH_HINH_THUC_TT = 'DISPATCH_HINH_THUC_TT'
export const DISPATCH_DAN_TOC = 'DISPATCH_DAN_TOC'
export const DISPATCH_DOI_TUONG = 'DISPATCH_DOI_TUONG'
export const DISPATCH_TINH_TP = 'DISPATCH_TINH_TP'
export const DISPATCH_QUOC_TICH = 'DISPATCH_QUOC_TICH'
export const DISPATCH_PHUONG_XA = 'DISPATCH_PHUONG_XA'
export const DISPATCH_QUAN_HUYEN = 'DISPATCH_QUAN_HUYEN'
export const OPEN_MODAL_MOCA = 'OPEN_MODAL_MOCA'
export const CLOSE_MODAL_MOCA = 'CLOSE_MODAL_MOCA'
export const DISPATCH_DEFAULT_BRANCH = 'DISPATCH_DEFAULT_BRANCH'
export const DISPATCH_USERNAME = 'DISPATCH_USERNAME'
export const DISPATCH_BRANCH_LOGIN = 'DISPATCH_BRANCH_LOGIN'
export const DISPATCH_lIST_CALAMVIEC = 'DISPATCH_lIST_CALAMVIEC'
export const DISPATCH_MENU = 'DISPATCH_MENU'
export const DISPATCH_BRANCH_NHAPKHO = 'DISPATCH_BRANCH_NHAPKHO'
export const DISPATCH_LIST_KHONHAP = 'DISPATCH_LIST_KHONHAP'
export const DISPATCH_DEFAULT_KHONHAP = 'DISPATCH_DEFAULT_KHONHAP'
export const DISPATCH_LIST_DOITAC = 'DISPATCH_LIST_DOITAC'
export const DISPATCH_INFO_DOITAC = 'DISPATCH_INFO_DOITAC'
export const DISPATCH_RESET_INFO_DOITAC = 'DISPATCH_RESET_INFO_DOITAC'
export const DISPATCH_ALL_THUOCVT = 'DISPATCH_ALL_THUOCVT'
export const DISPATCH_LIST_INFO_THUOCVT = 'DISPATCH_LIST_INFO_THUOCVT'
export const DISPATCH_INFO_CHI_TIET_KHO = 'DISPATCH_INFO_CHI_TIET_KHO'
export const DELETE_INFO_THUOCVT_BY_ID = 'DELETE_INFO_THUOCVT_BY_ID'
export const EDIT_INFO_SL_THUOCVT_BY_ID = 'EDIT_INFO_THUOCVT_BT_ID'
export const EDIT_PHI_VAN_CHUYEN_BY_ID = 'EDIT_PHI_VAN_CHUYEN_BY_ID'
export const EDIT_PHI_GIA_CONG_BY_ID = 'EDIT_PHI_GIA_CONG_BY_ID'
export const EDIT_PT_CK_VAT_BY_ID = 'EDIT_PT_CK_VAT_BY_ID'
export const EDIT_TIEN_CK_VAT_BY_ID = 'EDIT_TIEN_CK_VAT_BY_ID'
export const DISPATCH_VAT_THUOCVT = 'DISPATCH_VAT_THUOCVT'
export const DISPATCH_SOLO_THUOCVT = 'DISPATCH_SOLO_THUOCVT'
export const DISPATCH_HANDUNG_THUOCVT = 'DISPATCH_HANDUNG_THUOCVT'
export const RESET_INFO_THUOVT = 'RESET_INFO_THUOVT'
export const DISPATCH_LIST_PHIEU_NHAP = 'DISPATCH_LIST_PHIEU_NHAP'
export const DISPATCH_Ck_KHO_NHAN = 'DISPATCH_Ck_KHO_NHAN'
export const DISPATCH_CK_KHOVT = 'DISPATCH_CK_KHOVT'
export const DISPATCH_CK_KHO_CHI_TIET = 'DISPATCH_CK_KHO_CHI_TIET'
export const CHANGE_SL_THUOC_CK = 'CHANGE_SL_THUOC_CK'
export const CLOSE_THUOC_CK_BY_ID = 'CLOSE_THUOC_CK_BY_ID'
export const RESET_KHOVT_CK = 'RESET_KHOVT_CK'
export const OPEN_ADD_KHAM_DOAN = 'OPEN_ADD_KHAM_DOAN'
export const CLOSE_ADD_KHAM_DOAN = 'CLOSE_ADD_KHAM_DOAN'
export const IMPORT_DATA_KHAM_DOAN = 'IMPORT_DATA_KHAM_DOAN'
export const DISPATCH_LIST_ALL_BN_KHAM_DOAN = 'DISPATCH_LIST_ALL_BN_KHAM_DOAN'
export const DISPATCH_ALL_CTY_KHAM_DOAN = 'DISPATCH_ALL_CTY_KHAM_DOAN'
export const OPEN_ADD_CTY_KHAM_DOAN = 'OPEN_ADD_CTY_KHAM_DOAN'
export const CLOSE_ADD_CTY_KHAM_DOAN = 'CLOSE_ADD_CTY_KHAM_DOAN'
export const DISPATCH_INFO_CTY_KHAM_DOAN = 'DISPATCH_INFO_CTY_KHAM_DOAN'
export const OPEN_MODAL_EDIT_CTY_KHAM_DOAN = 'OPEN_MODAL_EDIT_CTY_KHAM_DOAN'
export const CLOSE_MODAL_EDIT_CTY_KHAM_DOAN = 'CLOSE_MODAL_EDIT_CTY_KHAM_DOAN'
export const OPEN_MODAL_IMPORT_KHAM_DOAN = 'OPEN_MODAL_IMPORT_KHAM_DOAN'
export const CLOSE_MODAL_IMPORT_KHAM_DOAN = 'CLOSE_MODAL_IMPORT_KHAM_DOAN'
export const RESET_DATA_BN_IMPORT = 'RESET_DATA_BN_IMPORT'
export const OPEN_MODAL_EDIT_BN_KHAM_DOAN = 'OPEN_MODAL_EDIT_BN_KHAM_DOAN'
export const CLOSE_MODAL_EDIT_BN_KHAM_DOAN = 'CLOSE_MODAL_EDIT_BN_KHAM_DOAN'
export const DISPATCH_INFO_BN_KHAM_DOAN = 'DISPATCH_INFO_BN_KHAM_DOAN'
export const ADD_LIST_INFO_SMS = 'ADD_LIST_INFO_SMS'
export const OPEN_IS_LOADING_TABLE_BN_KHAM_DOAN = 'OPEN_IS_LOADING_TABLE_BN_KHAM_DOAN'
export const CLOSE_IS_LOADING_TABLE_BN_KHAM_DOAN = 'CLOSE_IS_LOADING_TABLE_BN_KHAM_DOAN'
export const DISPATCH_INFO_PT_NHAP_KHO = 'DISPATCH_INFO_PT_NHAP_KHO'
export const OPEN_LOADING_TABLE_NHAP_KHO = 'OPEN_LOADING_TABLE_NHAP_KHO'
export const CLOSE_LOADING_TABLE_NHAP_KHO = 'CLOSE_LOADING_TABLE_NHAP_KHO'
export const DISPATCH_LIST_CREATE_CHUYEN_KHO = 'DISPATCH_LIST_CREATE_CHUYEN_KHO'
export const DISPATCH_LIST_PENDING_CHUYEN_KHO = 'DISPATCH_LIST_PENDING_CHUYEN_KHO'
export const DISPATCH_LIST_TRANSFER_CHUYEN_KHO = 'DISPATCH_LIST_TRANSFER_CHUYEN_KHO'
export const DISPATCH_LIST_RECEIVE_CHUYEN_KHO = 'DISPATCH_LIST_RECEIVE_CHUYEN_KHO'
export const DISPATCH_LIST_THUOCVT = 'DISPATCH_LIST_THUOCVT'
export const DISPATCH_INFO_KHO_VT = 'DISPATCH_INFO_KHO_VT'
export const DISPATCH_INFO_TRA_CUU_KHAM_DOAN = 'DISPATCH_INFO_TRA_CUU_KHAM_DOAN'
export const DISPATCH_INFO_COUNTRY = 'DISPATCH_INFO_COUNTRY'
export const DISPATCH_INFO_THUOVT_NHAP_KHO = 'DISPATCH_INFO_THUOVT_NHAP_KHO'
export const DISPATCH_LIST_TON_KHO = 'DISPATCH_LIST_TON_KHO'
export const DISPATCH_INFO_TON_kHO = 'DISPATCH_INFO_TON_kHO'
export const DISPATCH_TON_KHO_CHI_NHANH = 'DISPATCH_TON_KHO_CHI_NHANH'
export const PUSH_THUOC_VT_CHUYEN_KHO = 'PUSH_THUOC_VT_CHUYEN_KHO'
export const DISPATCH_TYPES_THUOC_VT = 'DISPATCH_TYPES_THUOC_VT'
export const DISPATCH_INFO_PT_CHUYEN_KHO = 'DISPATCH_INFO_PT_CHUYEN_KHO'
export const DISPATCH_INFO_THUOC_VAT_CK = 'DISPATCH_INFO_THUOC_VAT_CK'
export const DISPATCH_GROUPS_THUOC_VT = 'DISPATCH_GROUPS_THUOC_VT'
export const DISPATCH_GET_ALL_GROUPS_VT = 'DISPATCH_GET_ALL_GROUPS_VT'
export const DISPATCH_GROUPS_CHI_DINH = 'DISPATCH_GROUPS_CHI_DINH'
export const DISPATCH_CHI_DINH = 'DISPATCH_CHI_DINH'
export const DISPATCH_LIST_DANH_MUC_XET_NGHIEM = 'DISPATCH_LIST_DANH_MUC_XET_NGHIEM'
export const DISPATCH_LIST_ALL_COMPANY = 'DISPATCH_LIST_ALL_COMPANY'
export const DISPATCH_LIST_BN = 'DISPATCH_LIST_BN'
export const DISPATCH_GROUPS_XETNGHIEM = 'DISPATCH_GROUPS_XETNGHIEM'
export const DISPATCH_INFO_XET_NGHIEM = 'DISPATCH_INFO_XET_NGHIEM'
export const DISPATCH_INFO_BENH_NHAN = 'DISPATCH_INFO_BENH_NHAN'
export const DISPATCH_LIST_ALL_CĐHA = 'DISPATCH_LIST_ALL_CĐHA'
export const DISTPATCH_LIST_NGUOI_THAN = 'DISTPATCH_LIST_NGUOI_THAN'
export const DISTPATCH_LIST_XN_RECEIVE = 'DISTPATCH_LIST_XN_RECEIVE'
export const DISTPATCH_LIST_CDHA_RECEIVE = 'DISTPATCH_LIST_CDHA_RECEIVE'
export const DISTPATCH_LIST_KB_RECEIVE = 'DISTPATCH_LIST_KB_RECEIVE'
export const DELETE_CHI_DINH_BY_TYPE = 'DELETE_CHI_DINH_BY_TYPE'
export const RESET_SOTER_CHI_CHI_RECEIVE = 'RESET_SOTER_CHI_CHI_RECEIVE'
export const DISTPATCH_LIST_CARD_MEMBERSHIPS = 'DISTPATCH_LIST_CARD_MEMBERSHIPS'
export const DISTPATCH_LIST_DETAIL_BRANCH = 'DISTPATCH_LIST_DETAIL_BRANCH'
export const DISTPATCH_LIST_PARTNER = 'DISTPATCH_LIST_PARTNER'
export const DISPATCH_BRANCH_BUY = 'DISPATCH_BRANCH_BUY'
export const DISPATCH_GROUPS_VT_BRANCH_BUY = 'DISPATCH_GROUPS_VT_BRANCH_BUY'
export const DISPATCH_ALL_CHUYEN_KHOA = 'DISPATCH_ALL_CHUYEN_KHOA'
export const DISPATCH_LIST_TYPES_CARD = 'DISPATCH_LIST_TYPES_CARD'
export const DISPATCH_CHUYEN_KHOA_CARD = 'DISPATCH_CHUYEN_KHOA_CARD'
export const DISPATCH_INFO_BN_CARD = 'DISPATCH_INFO_BN_CARD'
export const DISPATCH_INFO_TYPE_CARD = 'DISPATCH_INFO_TYPE_CARD'
export const DISPATCH_ALL_CARD_RECEIVE = 'DISPATCH_ALL_CARD_RECEIVE'
export const DISPATCH_LIST_PAYMENT_CARD = 'DISPATCH_LIST_PAYMENT_CARD'
export const DISPATCH_INFO_PAYMENT_CARD = 'DISPATCH_INFO_PAYMENT_CARD'
export const DISPATCH_INFO_BN_PAYMENT_CARD = 'DISPATCH_INFO_BN_PAYMENT_CARD'
export const DISPATCH_ALL_TYPE_VT = 'DISPATCH_ALL_TYPE_VT'
export const DISPATCH_BN_PENDING = 'DISPATCH_BN_PENDING'
export const OPEN_LOADING_PAGE = 'OPEN_LOADING_PAGE'
export const CLOSE_LOADING_PAGE = 'CLOSE_LOADING_PAGE'
export const DISPATCH_HISTORY_PAYMENT_CARD = 'DISPATCH_HISTORY_PAYMENT_CARD'
export const DISPATCH_PAYMENT_CARD_RECEIVE = 'DISPATCH_PAYMENT_CARD_RECEIVE'
export const DISPATCH_MEMBER_CARD_RECEIVE = 'DISPATCH_MEMBER_CARD_RECEIVE'
export const DISPATCH_PAYMENT_CARD_CASHIER = 'DISPATCH_PAYMENT_CARD_CASHIER'
export const DISPATCH_MEMBER_CARD_CASHIER = 'DISPATCH_MEMBER_CARD_CASHIER'
export const DISPATCH_BN_CHO_KHAM = 'DISPATCH_BN_CHO_KHAM'
export const DISPATCH_INFO_BN_KHAM = 'DISPATCH_INFO_BN_KHAM'
export const DISPATCH_DETAIL_BN_KHAM = 'DISPATCH_DETAIL_BN_KHAM'
export const DISPATCH_STAFFS = 'DISPATCH_STAFFS' // export const DISPATCH_TENNHOM = 'DISPATCH_TENNHOM'
export const DISPATCH_LOI_DAN = 'DISPATCH_LOI_DAN'
export const DISPATCH_CHAN_DOAN = 'DISPATCH_CHAN_DOAN'
export const DISPATCH_LIST_PHIEU_THU = 'DISPATCH_LIST_PHIEU_THU'
export const DISPATCH_THUOC_KE_TOA_DOCTOR = 'DISPATCH_THUOC_KE_TOA_DOCTOR'
export const DISPATCH_THUOC_BN_DOCTOR = 'DISPATCH_THUOC_BN_DOCTOR'
export const DISPATCH_CASHIER_INFO_BN = 'DISPATCH_CASHIER_INFO_BN'
export const DISPATCH_CASHIER_BN_TTC = 'DISPATCH_CASHIER_BN_TTC'
export const DISPATCH_CASHIER_SERVICE = 'DISPATCH_CASHIER_SERVICE'
export const DISPATCH_DETAIL_PAYMENT_CASHIER = 'DISPATCH_DETAIL_PAYMENT_CASHIER'
export const DELETE_THUOC_BN = 'DELETE_THUOC_BN'
export const DISPATCH_SL_THUOC_BN = 'DISPATCH_SL_THUOC_BN'
export const DISPATCH_LIEU_DUNG = 'DISPATCH_LIEU_DUNG'
export const DISPATCH_LAN_DUNG = 'DISPATCH_LAN_DUNG'
export const DISPATCH_GHI_CHU_TOA_THUOC_BN = 'DISPATCH_GHI_CHU_TOA_THUOC_BN'
export const RESET_TOA_THUOC_BN_DOCTOR = 'RESET_TOA_THUOC_BN_DOCTOR'
export const DISPATCH_BN_LAY_THUOC = 'DISPATCH_BN_CHO_LAY_THUOC'
export const DISPATCH_INFO_BN_NHA_THUOC = 'DISPATCH_INFO_BN_NHA_THUOC'
export const DISPATCH_SERVICE_NHA_THUOC = 'DISPATCH_SERVICE_NHA_THUOC'
export const DISPATCH_DETAI_BN_NHA_THUOC = 'DISPATCH_DETAI_BN_NHA_THUOC'
export const OPEN_MODAL_CHECK_IN = 'OPEN_MODAL_CHECK_IN'
export const CLOSE_MODAL_CHECK_IN = 'CLOSE_MODAL_CHECK_IN'
export const INCREMENT_COUNT_DRUGSTORE = 'UPDATE_COUNT_DRUGSTORE'
export const DISPATCH_INFO_BRANCH = 'DISPATCH_INFO_BRANCH'
export const DISPATCH_STAFFS_BRANCH = 'DISPATCH_STAFFS_BRANCH'
export const DISPATCH_COUNTRY_BRANCH = 'DISPATCH_COUNTRY_BRANCH'
export const DISPATCH_QUAN_BRANCH = 'DISPATCH_QUAN_BRANCH'
export const DISPATCH_PHUONG_BRANCH = 'DISPATCH_PHUONG_BRANCH'
export const DISPATCH_BRANCH_BY_COMPANY = 'DISPATCH_BRANCH_BY_COMPANY'
export const DISPATCH_BRANCH_CHUYEN_KHO_BY_COMPANY = 'DISPATCH_BRANCH_CHUYEN_KHO_BY_COMPANY'
export const DISPATCH_BRANCH_PT_CHUYEN_KHO = 'DISPATCH_BRANCH_PT_CHUYEN_KHO'
export const DISPATCH_BRANCH_PT_NHAP_KHO = 'DISPATCH_BRANCH_PT_NHAP_KHO'
export const DISPATCH_BRANCH_TON_KHO = 'DISPATCH_BRANCH_TON_KHO'
export const DISPATCH_MSDK_THU_MUA = 'DISPATCH_MSDK_THU_MUA'
export const DISPATCH_GROUPS_THE_KHO = 'DISPATCH_GROUPS_THE_KHO'
export const DISPATCH_KHO_THE_KHO = 'DISPATCH_KHO_THE_KHO'
export const DISPATCH_DUONG_DUNG = 'DISPATCH_DUONG_DUNG'
export const DISPATCH_HISTORY_THE_KHO = 'DISPATCH_HISTORY_THE_KHO'
export const DECREMENT_COUNT_DRUGSTORE = 'DECREMENT_COUNT_DRUGSTORE'
export const RESET_STORE_DRUGSTORE = 'RESET_STORE_DRUGSTORE'
export const DISPATCH_LIST_USER = 'DISPATCH_LIST_USER'
export const DISPATCH_PHIEU_THU_THUOC = 'DISPATCH_PHIEU_THU_THUOC'
export const DISPATCH_HISTORY_XET_NGHIEM = 'DISPATCH_HISTORY_XET_NGHIEM'
export const DISPATCH_DETAIL_BN_XN = 'DISPATCH_DETAIL_BN_XN'
export const DISPATCH_LIST_DICH_VU = 'DISPATCH_LIST_DICH_VU'
export const DISPATCH_GROUPS_DICH_VU = 'DISPATCH_GROUPS_DICH_VU'
export const DISPATCH_INFO_DICH_VU = 'DISPATCH_INFO_DICH_VU'
export const DISPATCH_HISTORY_DICH_VU = 'DISPATCH_HISTORY_DICH_VU'
export const DISPATCH_DETAIL_DICH_VU = 'DISPATCH_DETAIL_DICH_VU'
export const DISPATCH_FILE_IMPORT_THU_MUA = 'DISPATCH_FILE_IMPORT_THU_MUA'
export const UPDATE_PROGRESS_THU_MUA = 'UPDATE_PROGRESS_THU_MUA'
export const DISPATCH_LIST_THE_DICH_VU = 'DISPATCH_LIST_THE_DICH_VU'
export const DISTPATCH_LIST_KB_RECEIVE_APPLY_MEMBER_CARD =
  'DISTPATCH_LIST_KB_RECEIVE_APPLY_MEMBER_CARD'
export const DISTPATCH_LIST_XN_CASHIER = 'DISTPATCH_LIST_XN_CASHIER'
export const DISTPATCH_LIST_CDHA_CASHIER = 'DISTPATCH_LIST_CDHA_CASHIER'
export const DISTPATCH_LIST_KB_CASHIER = 'DISTPATCH_LIST_KB_CASHIER'
export const DISTPATCH_LIST_KB_CASHIER_APPLY_MEMBER_CARD =
  'DISTPATCH_LIST_KB_CASHIER_APPLY_MEMBER_CARD'
export const DISTPATCH_XN_CASHIER = 'DISTPATCH_XN_CASHIER'
export const DISTPATCH_CDHA_CASHIER = 'DISTPATCH_CDHA_CASHIER'
export const DISTPATCH_KB_CASHIER = 'DISTPATCH_KB_CASHIER'
export const DISPATCH_CASHIER_BN_TTC_PT = 'DISPATCH_CASHIER_BN_TTC_PT'
export const DELETE_CHI_DINH_CASHIER = 'DELETE_CHI_DINH_CASHIER'
export const CHANGE_PRICE_THUOC_VAT_TU = 'CHANGE_PRICE_THUOC_VAT_TU'
export const DISPATCH_CUSTOMER = 'DISPATCH_CUSTOMER'
export const OPEN_MODAL_CREATE_CUSTOMER = 'OPEN_MODAL_CREATE_CUSTOMER'
export const CLOSE_MODAL_CREATE_CUSTOMER = 'CLOSE_MODAL_CREATE_CUSTOMER'
export const DISPATCH_HISTORY_KHAM_PK = 'DISPATCH_HISTORY_KHAM_PK'
export const DISPATCH_SET_ARR_XN_CASHIER = 'DISPATCH_SET_ARR_XN_CASHIER'
export const DISPATCH_SET_ARR_KB_CASHIER = 'DISPATCH_SET_ARR_KB_CASHIER'
export const DISPATCH_SET_ARR_CDHA_CASHIER = 'DISPATCH_SET_ARR_CDHA_CASHIER'
export const CHANGE_MSDK_NHAP_KHO = 'CHANGE_MSDK_NHAP_KHO'
export const CHANGE_NONG_DO_NHAP_KHO = 'CHANGE_NONG_DO_NHAP_KHO'
export const DISPATCH_TONG_GIAM_CASHIER = 'DISPATCH_TONG_GIAM_CASHIER'
export const DISPATCH_TONG_THANH_TOAN_CASHIER = 'DISPATCH_TONG_THANH_TOAN_CASHIER'
export const DISPATCH_SET_TONG_GIAM_CASHIER = 'DISPATCH_SET_TONG_GIAM_CASHIER'
export const DISPATCH_SET_TONG_THANH_TOAN_CASHIER = 'DISPATCH_SET_TONG_THANH_TOAN_CASHIER'
export const RESET_PHIEUTHU_CASHIER = 'RESET_PHIEUTHU_CASHIER'
export const DISPATCH_BN_DA_KHAM = 'DISPATCH_BN_DA_KHAM'
export const DISPATCH_BN_DANG_KHAM = 'DISPATCH_BN_DANG_KHAM'
export const DISPATCH_TONG_GIAM_TTV = 'DISPATCH_TONG_GIAM_TTV'
export const DISPATCH_TONG_THANH_TOAN_TTV = 'DISPATCH_TONG_THANH_TOAN_TTV'
export const DISPATCH_SET_TONG_GIAM_TTV = 'DISPATCH_SET_TONG_GIAM_TTV'
export const DISPATCH_SET_TONG_THANH_TOAN_TTV = 'DISPATCH_SET_TONG_THANH_TOAN_TTV'
export const DISPATCH_GIA_TRUOC_VAT_NHAP_KHO = 'DISPATCH_GIA_TRUOC_VAT_NHAP_KHO'
export const DISPATCH_GHI_CHU_NHAP_KHO = 'DISPATCH_GHI_CHU_NHAP_KHO'
export const DISPATCH_HISTORY_MEMBER_CARD = 'DISPATCH_HISTORY_MEMBER_CARD'
export const DISPACTH_TIEN_CHIEC_KHAU_NHAP_KHO = 'DISPACTH_TIEN_CHIEC_KHAU_NHAP_KHO'
export const DISPATCH_LIST_GROUP_CARD = 'DISPATCH_LIST_GROUP_CARD'
export const DISPATCH_SO_LAN_KHAM_MEMBER_CARD = 'DISPATCH_SO_LAN_KHAM_MEMBER_CARD'
export const OPEN_MODAL_VERSION = 'OPEN_MODAL_VERSION'
export const CLOSE_MODAL_VERSION = 'CLOSE_MODAL_VERSION'
export const DISPATCH_SL_CHAN_CHUYEN_KHO = 'DISPATCH_SL_CHAN_CHUYEN_KHO'
export const DISPATCH_DETAIL_TOATHUOC_BN_KHAM = 'DISPATCH_DETAIL_TOATHUOC_BN_KHAM'
export const DISPATCH_CHANGE_KET_QUA_XET_NGHIEM = 'DISPATCH_CHANGE_KET_QUA_XET_NGHIEM'
export const DISPATCH_CHANGE_CHI_SO_XET_NGHIEM = 'DISPATCH_CHANGE_CHI_SO_XET_NGHIEM'
export const DISPATCH_LIST_BN_CHO_THUTHUAT = 'DISPATCH_LIST_BN_CHO_THUTHUAT'
export const DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT = 'DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT'
export const DISPATCH_INFO_BN_DV_THUTHUAT = 'DISPATCH_INFO_BN_DV_THUTHUAT'
export const DISPATCH_LIST_VT_THUTHUAT = 'DISPATCH_LIST_VT_THUTHUAT'
export const DISPATCH_LIST_VT_BN_THUTHUAT = 'DISPATCH_LIST_VT_BN_THUTHUAT'
export const DISPATCH_ADD_VT_BN_THUTHUAT = 'DISPATCH_ADD_VT_BN_THUTHUAT'
export const DISPATCH_DELETE_VT_BN_THUTHUAT = 'DISPATCH_DELETE_VT_BN_THUTHUAT'
export const DISPATCH_EDIT_VT_SL_BN_THUTHUAT = 'DISPATCH_EDIT_VT_SL_BN_THUTHUAT'
export const DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT = 'DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT'
export const DISPATCH_BN_CHO_KHAM_SAN = 'DISPATCH_BN_CHO_KHAM_SAN'
export const DISPATCH_HISTORY_KHAM_PK_SAN = 'DISPATCH_HISTORY_KHAM_PK_SAN'
export const DISPATCH_INFO_BN_KHAM_SAN = 'DISPATCH_INFO_BN_KHAM_SAN'
export const DISPATCH_DETAIL_BN_KHAM_SAN = 'DISPATCH_DETAIL_BN_KHAM_SAN'
export const DISPATCH_DETAIL_TOATHUOC_BN_KHAM_SAN = 'DISPATCH_DETAIL_TOATHUOC_BN_KHAM_SAN'
export const DISPATCH_LOI_DAN_SAN = 'DISPATCH_LOI_DAN_SAN'
export const DISPATCH_CHAN_DOAN_SAN = 'DISPATCH_CHAN_DOAN_SAN'
export const DISPATCH_THUOC_KE_TOA_DOCTOR_SAN = 'DISPATCH_THUOC_KE_TOA_DOCTOR_SAN'
export const DISPATCH_THUOC_BN_DOCTOR_SAN = 'DISPATCH_THUOC_BN_DOCTOR_SAN'
export const DISPATCH_SL_THUOC_BN_SAN = 'DISPATCH_SL_THUOC_BN_SAN'
export const DISPATCH_LIEU_DUNG_SAN = 'DISPATCH_LIEU_DUNG_SAN'
export const DISPATCH_LAN_DUNG_SAN = 'DISPATCH_LAN_DUNG_SAN'
export const DISPATCH_GHI_CHU_TOA_THUOC_BN_SAN = 'DISPATCH_GHI_CHU_TOA_THUOC_BN_SAN'
export const RESET_TOA_THUOC_BN_DOCTOR_SAN = 'RESET_TOA_THUOC_BN_DOCTOR_SAN'
export const DELETE_THUOC_BN_SAN = 'DELETE_THUOC_BN_SAN'
export const DISPATCH_BN_DANG_KHAM_SAN = 'DISPATCH_BN_DANG_KHAM_SAN'
export const DISPATCH_BN_DA_KHAM_SAN = 'DISPATCH_BN_DA_KHAM_SAN'
export const DISPATCH_DETAIL_LOAIKHAM_BN_KHAM_SAN = 'DISPATCH_DETAIL_LOAIKHAM_BN_KHAM_SAN'
export const DISPATCH_LIST_DICH_VU_LOAITHE = 'DISPATCH_LIST_DICH_VU_LOAITHE'
export const DISPATCH_INFO_LOAITHE = 'DISPATCH_INFO_LOAITHE'
export const DISPATCH_LIST_TYPES_CARD_BY_CN = 'DISPATCH_LIST_TYPES_CARD_BY_CN'
export const DISPATCH_ALL_BIEU_MAU = 'DISPATCH_ALL_BIEU_MAU'
export const DISPATCH_INFO_BN_SIEU_AM = 'DISPATCH_INFO_BN_SIEU_AM'
export const RESET_BENN_NHAN_SIEU_AM = 'RESET_BENN_NHAN_SIEU_AM'
export const DISPATCH_BN_CHO_KHAM_PHCN = 'DISPATCH_BN_CHO_KHAM_PHCN'
export const DISPATCH_BN_DANG_KHAM_PHCN = 'DISPATCH_BN_DANG_KHAM_PHCN'
export const DISPATCH_BN_DA_KHAM_PHCN = 'DISPATCH_BN_DA_KHAM_PHCN'
export const DISPATCH_BN_CHO_KHAM_TMTD = 'DISPATCH_BN_CHO_KHAM_TMTD'
export const DISPATCH_BN_DANG_KHAM_TMTD = 'DISPATCH_BN_DANG_KHAM_TMTD'
export const DISPATCH_BN_DA_KHAM_TMTD = 'DISPATCH_BN_DA_KHAM_TMTD'
export const RESET_STORE_CLINIC = 'RESET_STORE_CLINIC'
export const DISPATCH_HISTORY_BN_SIEU_AM = 'DISPATCH_HISTORY_BN_SIEU_AM'
export const DISPATCH_INFO_BN_TAP_VLTL = 'DISPATCH_INFO_BN_TAP_VLTL'
export const DISPATCH_LIST_BN_CHO_VLTL = 'DISPATCH_LIST_BN_CHO_VLTL'
export const DISPATCH_LIST_BN_DA_VLTL = 'DISPATCH_LIST_BN_DA_VLTL'
export const DISPATCH_INFO_SO_LAN_BN_TAP_VLTL = 'DISPATCH_INFO_SO_LAN_BN_TAP_VLTL'
export const DISPATCH_NOTE_CHUYEN_KHO = 'DISPATCH_NOTE_CHUYEN_KHO'
export const DISPATCH_BN_CHO_DKX = 'DISPATCH_BN_CHO_DKX'
export const DISPATCH_BN_DA_DKX = 'DISPATCH_BN_DA_DKX'
export const DISPATCH_INFO_BN_DKX = 'DISPATCH_INFO_BN_DKX'
export const DISPATCH_LIST_BN_CHO_TRONGKINH = 'DISPATCH_LIST_BN_CHO_TRONGKINH'
export const DISPATCH_LIST_BN_DA_TRONGKINH = 'DISPATCH_LIST_BN_DA_TRONGKINH'
export const DISPATCH_BN_CHO_KHAM_SANG_LOC = 'DISPATCH_BN_CHO_KHAM_SANG_LOC'
export const DISPATCH_BN_DANG_KHAM_SANG_LOC = 'DISPATCH_BN_DANG_KHAM_SANG_LOC'
export const DISPATCH_BN_DA_KHAM_SANG_LOC = 'DISPATCH_BN_DA_KHAM_SANG_LOC'
export const DISPATCH_INFO_BN_KSL = 'DISPATCH_INFO_BN_KSL'
export const DISPATCH_LIST_TRONG_KINH_DKX = 'DISPATCH_LIST_TRONG_KINH_DKX'
export const DISPATCH_LIST_GONG_KINH_DKX = 'DISPATCH_LIST_GONG_KINH_DKX'
export const DISPATCH_LIST_TRONG_KINH_BN_DKX = 'DISPATCH_LIST_TRONG_KINH_BN_DKX'
export const DISPATCH_LIST_GONG_KINH_BN_DKX = 'DISPATCH_LIST_GONG_KINH_BN_DKX'
export const DISPATCH_ADD_LIST_TRONG_KINH_BN_DKX = 'DISPATCH_ADD_LIST_TRONG_KINH_BN_DKX'
export const DISPATCH_ADD_LIST_GONG_KINH_BN_DKX = 'DISPATCH_ADD_LIST_GONG_KINH_BN_DKX'
export const DISPATCH_EDIT_SOLUONG_GK_BN_DKX = 'DISPATCH_EDIT_SOLUONG_GK_BN_DKX'
export const DISPATCH_EDIT_DOITAC_TK_BN_DKX = 'DISPATCH_EDIT_DOITAC_TK_BN_DKX'
export const DISPATCH_EDIT_LOAIGONG_TK_BN_DKX = 'DISPATCH_EDIT_LOAIGONG_TK_BN_DKX'
export const DISPATCH_EDIT_GHICHU_TK_BN_DKX = 'DISPATCH_EDIT_GHICHU_TK_BN_DKX'
export const DISPATCH_DEL_LIST_TRONG_KINH_BN_DKX = 'DISPATCH_DEL_LIST_TRONG_KINH_BN_DKX'
export const DISPATCH_DEL_LIST_GONG_KINH_BN_DKX = 'DISPATCH_DEL_LIST_GONG_KINH_BN_DKX'
export const DISPATCH_INFO_TRONG_VA_GONG_KINH_BN_DKX = 'DISPATCH_INFO_TRONG_VA_GONG_KINH_BN_DKX'
export const DISPATCH_LIST_BN_DV_DKX = 'DISPATCH_LIST_BN_DV_DKX'
export const DISPATCH_LIST_TON_VAC_XIN_KSL = 'DISPATCH_LIST_TON_VAC_XIN_KSL'
export const DISPATCH_MUI_TOA_THUOC_BN = 'DISPATCH_MUI_TOA_THUOC_BN'
export const DISPATCH_BN_LAY_THUOC_XVX = 'DISPATCH_BN_LAY_THUOC_XVX'
export const DISPATCH_ONCHANGE_COUNT_LE_NHAP_KHO = 'DISPATCH_ONCHANGE_COUNT_LE_NHAP_KHO'
export const DISPATCH_LIST_BN_CHO_TIEMCHUNG = 'DISPATCH_LIST_BN_CHO_TIEMCHUNG'
export const DISPATCH_LIST_BN_DA_TIEMCHUNG = 'DISPATCH_LIST_BN_DA_TIEMCHUNG'
export const DISPATCH_INFO_BN_TIEMCHUNG = 'DISPATCH_INFO_BN_TIEMCHUNG'
export const DISPATCH_DETAIL_TOATHUOC_BN_KHAM_TC = 'DISPATCH_DETAIL_TOATHUOC_BN_KHAM_TC'
export const DISPATCH_INFO_BN_THEO_DOI_SAU_TIEM = 'DISPATCH_INFO_BN_THEO_DOI_SAU_TIEM'
export const DISPATCH_PHIEU_THU_VACXIN = 'DISPATCH_PHIEU_THU_VACXIN'
export const DISPATCH_LICHSU_TIEMCHUNG_KSL = 'DISPATCH_LICHSU_TIEMCHUNG_KSL'
export const DISPATCH_INFO_PHIEUTHU_TTV = 'DISPATCH_INFO_PHIEUTHU_TTV'
export const DISPATCH_INFO_BN_THU2_CARD = 'DISPATCH_INFO_BN_THU2_CARD'
export const DISPATCH_LIST_COMBO_TIEPNHAN = 'DISPATCH_LIST_COMBO_TIEPNHAN'
export const DISPATCH_LIST_XET_NGHIEM_LOAITHE = 'DISPATCH_LIST_XET_NGHIEM_LOAITHE'
export const DISPATCH_LIST_CDHA_LOAITHE = 'DISPATCH_LIST_CDHA_LOAITHE'
export const DISPATCH_MEMBER_CARD_KHAM = 'DISPATCH_MEMBER_CARD_KHAM'
export const DISPATCH_TONKHO_TON_HOP = 'DISPATCH_TONKHO_TON_HOP'
export const DISPATCH_LIST_CTGIAMGIA_TIEPNHAN = 'DISPATCH_LIST_CTGIAMGIA_TIEPNHAN'
export const DISPATCH_LIST_BN_CHO_KSK = 'DISPATCH_LIST_BN_CHO_KSK'
export const DISPATCH_LIST_BN_DANG_KSK = 'DISPATCH_LIST_BN_DANG_KSK'
export const DISPATCH_LIST_BN_DA_KSK = 'DISPATCH_LIST_BN_DA_KSK'
export const DISPATCH_INFO_BN_KSK = 'DISPATCH_INFO_BN_KSK'
export const DISPATCH_CO_KE_TOA_KSK = 'DISPATCH_CO_KE_TOA_KSK'
export const DISPATCH_SO_LAN_THUOC_NHI = 'DISPATCH_SO_LAN_THUOC_NHI'
export const NGAY_THUOC_NHI = 'NGAY_THUOC_NHI'
export const DISPATCH_GIA_VAT_NHAP_KHO = 'DISPATCH_GIA_VAT_NHAP_KHO'
export const DISPATCH_BNTT_TOATHUOC = 'DISPATCH_BNTT_TOATHUOC'
export const DISPATCH_LIST_THUOC_TRA = 'DISPATCH_LIST_THUOC_TRA'
export const OPEN_LOADING_TABLE = 'OPEN_LOADING_TABLE'
export const CLOSE_LOADING_TABLE = 'CLOSE_LOADING_TABLE'
export const PUSH_PRODUCTS_COPY_CHUYEN_KHO = 'PUSH_PRODUCTS_COPY_CHUYEN_KHO'
export const DISPACTH_PRICE_TTV_CT_GIAM = 'DISPACTH_PRICE_TTV_CT_GIAM'
export const DISPATCH_LICHSU_MUA_TTV = 'DISPATCH_LICHSU_MUA_TTV'
export const RESET_STORE_LICHSU = 'RESET_STORE_LICHSU'
export const DISPATCH_GROUPS_NHAN_SU_CO = 'DISPATCH_GROUPS_NHAN_SU_CO'
export const DISPATCH_NHAN_SU_CO = 'DISPATCH_NHAN_SU_CO'
export const DISPATCH_INFO_BN_CARD_TANGSAN = 'DISPATCH_INFO_BN_CARD_TANGSAN'
export const DISPATCH_INFO_BN_THU2_CARD_TANGSAN = 'DISPATCH_INFO_BN_THU2_CARD_TANGSAN'
export const DISPATCH_DANH_MUC_NHAN = 'DISPATCH_DANH_MUC_NHAN'
export const DISPATCH_INFO_NHAN = 'DISPATCH_INFO_NHAN'
export const DISPATCH_THUC_HIEN_BY_VUNG = 'DISPATCH_THUC_HIEN_BY_VUNG'
export const DISPATCH_DANH_SACH_BAO_HONG = 'DISPATCH_DANH_SACH_BAO_HONG'
export const DISPATCH_GET_VUNG_BAO_HONG = 'DISPATCH_GET_VUNG_BAO_HONG'
export const DISPATCH_NHAN_VIEN_SU_CO = 'DISPATCH_NHAN_VIEN_SU_CO'
export const DISPATCH_INFO_BAO_HONG = 'DISPATCH_INFO_BAO_HONG'
export const DISPATCH_ALL_HANG_MUC_SU_CO = 'DISPATCH_ALL_HANG_MUC_SU_CO'
export const DISPATCH_KT_THU_THIEN_BAO_HONG = 'DISPATCH_KT_THU_THIEN_BAO_HONG'
export const GET_INMAGE_BAO_HONG = 'GET_INMAGE_BAO_HONG'
export const DISPATCH_CHI_NHANH_VUNG = 'DISPATCH_CHI_NHANH_VUNG'
export const DISPATCH_INFO_XU_LY_BAO_HONG = 'DISPATCH_INFO_XU_LY_BAO_HONG'
export const DISPATCH_DANH_SACH_BAO_HONG_HOANG_THANH = 'DISPATCH_DANH_SACH_BAO_HONG_HOANG_THANH'
export const RESET_BS_KHAM_CAPNHAT = 'RESET_BS_KHAM_CAPNHAT'
export const DISPATCH_INFO_VOUCHER_BANTOA = 'DISPATCH_INFO_VOUCHER_BANTOA'
export const DISPATCH_UU_DAI_TAI_KHAM_TIEP_NHAN = 'DISPATCH_UU_DAI_TAI_KHAM_TIEP_NHAN'
export const POST_PHIEU_XUAT_NOI_BO = 'POST_PHIEU_XUAT_NOI_BO'
export const DISTPATCH_THONG_KE_DONG_MO_CA = 'DISTPATCH_THONG_KE_DONG_MO_CA'
export const DISPATCH_NGAYHENTIEM_TOA_THUOC_BN = 'DISPATCH_NGAYHENTIEM_TOA_THUOC_BN'
export const POST_PHIEU_XUAT_TRA_NCC = 'POST_PHIEU_XUAT_TRA_NCC'
export const DISPACTH_HISTORY_STAFF_SU_CO = 'DISPACTH_HISTORY_STAFF_SU_CO'
export const DISPATCH_THONG_KE_BAO_HONG = 'DISPATCH_THONG_KE_BAO_HONG'
export const DISPATCH_LIST_VPP_NHAPKHO = 'DISPATCH_LIST_VPP_NHAPKHO'
//############ API #######################################################################
export const DISPATCH_CHANGE_PRICE_THUC_TRA = 'DISPATCH_CHANGE_PRICE_THUC_TRA'
export const GET_LIST_BRANCH_API = 'GET_LIST_BRANCH_API'
export const GET_LOGIN_API = 'GET_LOGIN_API'
export const LOGOUT_USER = 'LOGOUT_USER'
export const GET_ALL_SELECT_CLINIC = 'GET_ALL_SELECT_CLINIC'
export const GET_QUAN_API = 'GET_QUAN_API'
export const GET_XA_API = 'GET_XA_API'
export const GET_DEFAULT_BRANCH = 'GET_DEFAULT_BRANCH'
export const GET_ID_BRANCH_LOGIN = 'GET_ID_BRANCH_LOGIN'
export const GET_LIST_CALAMVIEC = 'GET_CALAMVIEC'
export const GET_LIST_MENU = 'GET_LIST_MENU'
export const GET_BRANCH_NHAPKHO = 'GET_BRANCH_NHAPKHO'
export const GET_LIST_KHONHAP = 'GET_LIST_KHONHAP'
export const GET_LIST_DOITAC = 'GET_LIST_DOITAC'
export const GET_INFO_DOITCA = 'GET_INFO_DOITCA'
export const GET_THUOCVT_BY_KEYWORD = 'GET_THUOCVT_BY_KEYWORD'
export const GET_INFO_THUOCVT = 'GET_INFO_THUOCVT'
export const POST_PHIEU_NHAP_KHO = 'POST_PHIEU_NHAP_KHO'
export const GET_ALL_PHIEU_NHAP = 'GET_ALL_PHIEU_NHAP'
export const GET_LIST_Ck_KHO_NHAN = 'GET_LIST_Ck_KHO_NHAN'
export const GET_CK_KHOVT = 'GET_CK_KHOVT'
export const POST_PHIEU_CK = 'POST_PHIEU_CK'
export const GET_ALL_BN_KHAM_DOAN = 'GET_ALL_BN_KHAM_DOAN'
export const POST_BN_KHAM_DOAN = 'POST_BN_KHAM_DOAN'
export const GET_ALL_LIST_CTY = 'GET_ALL_LIST_CTY'
export const POST_CTY_KHAM_DOAN = 'POST_CTY_KHAM_DOAN'
export const GET_INFO_CTY_KHAM_DOAN = 'GET_INFO_CTY_KHAM_DOAN'
export const EDIT_CTY_KHAM_DOAN_BY_ID = 'PUT_CTY_KHAM_DOAN_BY_ID'
export const DELETE_BN_KHAM_DOAN_BY_ID = 'DELETE_BN_KHAM_DOAN_BY_ID'
export const IMPORT_LIST_BN_KHAM_DOAN = 'IMPORT_LIST_BN_KHAM_DOAN'
export const GET_INFO_BN_KHAM_DOAN = 'GET_INFO_BN_KHAM_DOAN'
export const UPDATE_INFO_BN_KHAM_DOAN = 'UPDATE_INFO_BN_KHAM_DOAN'
export const SEND_SMS_BN_KHAM_DOAN = 'SEND_SMS_BN_KHAM_DOAN'
export const DELETE_CTY_KHAM_DOAN = 'DELETE_CTY_KHAM_DOAN'
export const SEARCH_BN_KHAM_DOAN = 'SEARCH_BN_KHAM_DOAN'
export const DELETE_PHIEU_NHAP_KHO = 'DELETE_PHIEU_NHAP_KHO'
export const GET_INFO_PT_NHAP_KHO = 'GET_INFO_PT_NHAP_KHO'
export const GET_FILTER_PT_NHAP_KHO = 'GET_FILTER_PT_NHAP_KHO'
export const GET_INFO_BRANCH_LOGIN = 'GET_INFO_BRANCH_LOGIN'
export const GET_FILTER_CREATE_CHUYEN_KHO = 'GET_FILTER_PT_CHUYEN_KHO'
export const GET_ALL_THUOC_VT = 'GET_ALL_THUOC_VT'
export const POST_THUOC_VT = 'POST_THUOC_VT'
export const DELETE_THUOC_VT = 'DELETE_THUOC_VT'
export const GET_INFO_KHO_VT = 'GET_INFO_KHO_VT'
export const LOGIN_TRA_CUU_KHAM_DOAN = 'LOGIN_TRA_CUU_KHAM_DOAN'
export const GET_INFO_COUNTRY = 'GET_INFO_COUNTRY'
export const GET_BRANCH_AND_KHO_VT_DEFAULT = 'GET_BRANCH_AND_KHO_VT_DEFAULT'
export const UPDATE_INFO_PT_NHAP_KHO = 'UPDATE_INFO_PT_NHAP_KHO'
export const GET_LIST_TON_KHO_VT = 'GET_LIST_TON_KHO_VT'
export const GET_TON_KHO_CHI_NHANH = 'GET_TON_KHO_CHI_NHANH'
export const UPDATE_STATUS_CK = 'UPDATE_STATUS_CK'
export const GET_FILTER_PENDING_CHUYEN_KHO = 'GET_FILTER_PENDING_CHUYEN_KHO'
export const GET_FILTER_TRANFER_CHUYEN_KHO = 'GET_FILTER_TRANFER_CHUYEN_KHO'
export const GET_FILTER_RECEVIE_CHUYEN_KHO = 'GET_FILTER_RECEVIE_CHUYEN_KHO'
export const UPDATE_KHO_CHI_TIET = 'UPDATE_KHO_CHI_TIET'
export const DELETE_KHO_CHI_TIET = 'DELETE_KHO_CHI_TIET'
export const RESET_STORE = 'RESET_STORE'
export const GET_LIST_TYPES_THUOC_VT = 'GET_LIST_TYPES_THUOC_VT'
export const GET_INFO_PT_CHUYEN_KHO = 'GET_INFO_PT_CHUYEN_KHO'
export const UPDATE_INFO_PT_CHUYEN_KHO = 'UPDATE_INFO_PT_CHUYEN_KHO'
export const DELETE_KHO_CHI_TIET_CHUEN_KHO = 'DELETE_KHO_CHI_TIET_CHUEN_KHO'
export const GET_ALL_LIST_GROUPS_THUOC_VT = 'GET_ALL_LIST_GROUPS_THUOC_VT'
export const UPDATE_THUOC_VAT_TU = 'UPDATE_THUOC_VAT_TU'
export const UPDATE_INFO_USER_LOCASTORAGE = 'UPDATE_INFO_USER_LOCASTORAGE'
export const POST_CA_LAM_VIEC = 'POST_CA_LAM_VIEC'
export const GET_LIST_GROUPS_THUOC_VT_BY_TYPE = 'GET_LIST_GROUPS_THUOC_VT_BY_TYPE'
export const GET_GROUPS_CHI_DINH = 'GET_GROUPS_CHI_DINH'
export const GET_CHI_DINH = 'GET_CHI_DINH'
export const GET_LIST_DANH_MUC_XET_NGHIEM = 'GET_LIST_DANH_MUC_XET_NGHIEM'
export const GET_LIST_ALL_COMPANY = 'GET_LIST_ALL_COMPANY'
export const CREATE_BENH_NHAN = 'CREATE_BENH_NHAN'
export const SEARCH_BN = 'SEARCH_BN'
export const GET_LIST_GROUPS_XETNGHIEM = 'GET_LIST_GROUPS_XETNGHIEM'
export const SEARCH_XET_NGHIEM_BY_GRUOPS = 'SEARCH_XET_NGHIEM_BY_GRUOPS'
export const GET_INFO_XET_NGHIEM = 'GET_INFO_XET_NGHIEM'
export const POST_NEW_DANH_MUC_XET_NGHIEM = 'POST_NEW_DANH_MUC_XET_NGHIEM'
export const DELETE_DANH_MUC_XET_NGHIEM = 'DELETE_DANH_MUC_XET_NGHIEM'
export const UPDATE_DANH_MUC_XET_NGHIEM = 'UPDATE_DANH_MUC_XET_NGHIEM'
export const GET_GROUPS_XET_NGHIEM_BY_BRANCH = 'GET_GROUPS_XET_NGHIEM_BY_BRANCH'
export const GET_INFO_BENH_NHAN = 'GET_INFO_BENH_NHAN'
export const GET_XET_NGHIEM_RECIEVE = 'GET_XET_NGHIEM_RECIEVE'
export const GET_LIST_ALL_CĐHA = 'GET_LIST_ALL_CĐHA'
export const GET_GROUPS_CĐHA = 'GET_GROUPS_CĐHA'
export const GET_CHI_DINH_CĐHA_BY_GROUPS = 'GET_CHI_DINH_CĐHA_BY_GROUPS'
export const GET_PK_BY_CHI_NHANH = 'GET_PK_BY_CHI_NHANH'
export const ADD_BN_KHAM_BENH = 'ADD_BN_KHAM_BENH'
export const GET_LIST_CARD_MEMEBERSHIP = 'GET_LIST_CARD_MEMEBERSHIP'
export const GET_LIST_DETAIL_BRANCH = 'GET_LIST_DETAIL_BRANCH'
export const GET_LIST_PARTNER = 'GET_LIST_PARTNER'
export const GET_LIST_BRACNH_BUY = 'GET_LIST_BRACNH_BUY'
export const GET_GROUPS_VT_BRANCH_BUY = 'GET_GROUPS_VT_BRANCH_BUY'
export const UPDATE_STATUS_BRANCH_BUY = 'UPDATE_STATUS_BRANCH_BUY'
export const GET_ALL_CHUYEN_KHOA = 'GET_ALL_CHUYEN_KHOA'
export const GET_ALL_TYPE_CARD = 'GET_ALL_TYPE_CARD'
export const GET_INFO_BN_CARD = 'GET_INFO_BN_CARD'
export const GET_INFO_TYPES_CARD = 'GET_INFO_TYPES_CARD'
export const ADD_NEW_CARD_BN = 'ADD_NEW_CARD_BN'
export const GET_ALL_CARD_RECEIVE = 'GET_ALL_CARD_RECEIVE'
export const INFO_CARD_BN = 'INFO_CARD_BN'
export const ACTIVE_CARD = 'ACTIVE_CARD'
export const GET_ALL_PAYMEMNT_CARD = 'GET_ALL_PAYMEMNT_CARD'
export const GET_INFO_PAYMENR_CARD = 'GET_INFO_PAYMENR_CARD'
export const GET_ALL_TYPES_THUOC_VT = 'GET_ALL_TYPES_THUOC_VT'
export const GET_BN_PEDING = 'GET_BN_PEDING'
export const CHANGE_PASSWORD_USER = 'CHANGE_PASSWORD_USER'
export const POST_RECHARGE_PAYMENT_CARD = 'POST_RECHARGE_PAYMENT_CARD'
export const GET_HISTORY_PAYMENT_CARD = 'GET_HISTORY_PAYMENT_CARD'
export const GET_BN_CHO_KHAM_BY_PK = 'GET_BN_CHO_KHAM_BY_PK'
export const GET_INFO_DETAIL_BN_KHAM = 'GET_INFO_DETAIL_BN_KHAM'
export const UPDATE_DETAIL_BN_KHAM = 'UPDATE_DETAIL_BN_KHAM'
export const GET_DANH_SACH_PHIEU_THU = 'GET_DANH_SACH_PHIEU_THU'
export const GET_SUGGEST_DOCTOR = 'GET_SUGGEST_DOCTOR'
export const GET_THUOC_KE_TOA_DOCTOR = 'GET_THUOC_KE_TOA_DOCTOR'
export const GET_INFO_PT_CASHIER = 'GET_INFO_PT_CASHIER'
export const GET_BN_CHO_LAY_THUOC = 'GET_BN_CHO_LAY_THUOC'
export const GET_BN_DA_LAY_THUOC = 'GET_BN_DA_LAY_THUOC'
export const GET_DETAIL_BN_NHA_THUOC = 'GET_DETAIL_BN_NHA_THUOC'
export const POST_CLOCK_IN_WORK = 'POST_CLOCK_IN_WORK'
export const GET_INFO_BRANCH = 'GET_INFO_BRANCH'
export const GET_QUAN_BY_TINH_BRANCH = 'GET_QUAN_BY_TINH_BRANCH'
export const GET_COUNTRY_AND_STAFFS_BRANCH = 'GET_COUNTRY_AND_STAFFS_BRANCH'
export const GET_HUYEN_BY_QUAN_BRANCH = 'GET_HUYEN_BY_QUAN_BRANCH'
export const ADD_NEW_BRANCH = 'ADD_NEW_BRANCH'
export const DELETE_BRANCH = 'DELETE_BRANCH'
export const GET_BRANCH_NHAP_KHO_BY_COMPANY = 'GET_BRANCH_NHAP_KHO_BY_COMPANY'
export const GET_BRANCH_CHUYEN_KHO_BT_COMPANY = 'GET_BRANCH_CHUYEN_KHO_BT_COMPANY'
export const GET_BRANCH_PT_CHUYEN_KHO = 'GET_BRANCH_PT_CHUYEN_KHO'
export const UPDATE_XAC_NHAN_NHAP_KHO = 'UPDATE_XAC_NHAN_NHAP_KHO'
export const GET_BRANCH_NHAP_KHO_PT = 'GET_BRANCH_NHAP_KHO_PT'
export const GET_BRANCH_TON_KHO = 'GET_BRANCH_TON_KHO'
export const GET_THUOC_VT_BY_KEYWORD = 'GET_THUOC_VT_BY_KEYWORD'
export const GET_HISTORY_MSDK = 'GET_HISTORY_MSDK'
export const ADD_PARTNER = 'ADD_PARTNER'
export const DELETE_PARTNER = 'DELETE_PARTNER'
export const EDIT_PARTNER = 'EDIT_PARTNER'
export const SEARCH_PARTNER = 'SEARCH_PARTNER'
export const GET_GROUPS_THE_KHO = 'GET_GROUPS_THE_KHO'
export const GET_KHO_THE_KHO = 'GET_KHO_THE_KHO'
export const GET_DUONG_DUNG_THUOC = 'GET_DUONG_DUNG_THUOC'
export const UPDATE_BRANCH = 'UPDATE_BRANCH'
export const FILTER_HISTORY_THE_KHO = 'FILTER_HISTORY_THE_KHO'
export const POST_BN_THUOC = 'POST_BN_THUOC'
export const POST_PAYMENT_CARD = 'POST_PAYMENT_CARD'
export const ADD_NEW_CHI_DINH_KHAM = 'ADD_NEW_CHI_DINH_KHAM'
export const GET_ACCOUNT_BY_PAGEINDEX = 'GET_ACCOUNT_BY_PAGEINDEX'
export const GET_PHIEU_THU_THUOC = 'GET_PHIEU_THU_THUOC'
export const GET_INFO_BN_XET_NGHIEM = 'GET_INFO_BN_XET_NGHIEM'
export const UPDATE_BN_XET_NGHIEM = 'UPDATE_BN_XET_NGHIEM'
export const GET_LIST_DICH_VU = 'GET_LIST_DICH_VU'
export const GET_LIST_GROUPS_DICH_VU = 'GET_LIST_GROUPS_DICH_VU'
export const GET_INFO_DICH_VU = 'GET_INFO_DICH_VU'
export const POST_NEW_DICH_VU = 'POST_NEW_DICH_VU'
export const DELETE_DICH_VU = 'DELETE_DICH_VU'
export const UPDATE_DICH_VU = 'UPDATE_DICH_VU'
export const IMPORT_THU_MUA_HANG_LOAT = 'IMPORT_THU_MUA_HANG_LOAT'
export const POST_THE_DICH_VU = 'POST_THE_DICH_VU'
export const GET_LIST_THE_DICH_VU = 'GET_LIST_THE_DICH_VU'
export const UPDATE_NEW_PRICE_CHI_DINH = 'UPDATE_NEW_PRICE_CHI_DINH'
export const GET_LIST_DISCOUNT = 'GET_LIST_DISCOUNT'
export const DISPATCH_SET_ARR_XN = 'DISPATCH_SET_ARR_XN'
export const DISPATCH_SET_ARR_KB = 'DISPATCH_SET_ARR_KB'
export const DISPATCH_SET_ARR_CDHA = 'DISPATCH_SET_ARR_CDHA'
export const REMOVE_PRICE_DISCOUNT = 'REMOVE_PRICE_DISCOUNT'
export const REMOVE_LIST_DISCOUNT = 'REMOVE_LIST_DISCOUNT'
export const POST_APPLY_MEMBER_CARD = 'POST_APPLY_MEMBER_CARD'
export const DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC = 'DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC'
export const SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC = 'SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC'
export const POST_DV_MEMBER_CARD = 'POST_DV_MEMBER_CARD'
export const POST_BN_BAN_LE = 'POST_BN_BAN_LE'
export const UPDATE_NEW_PRICE_CHI_DINH_CASHIER = 'UPDATE_NEW_PRICE_CHI_DINH_CASHIER'
export const DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC_CASHIER =
  'DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC_CASHIER'
export const GET_LIST_DISCOUNT_CASHIER = 'GET_LIST_DISCOUNT_CASHIER'
export const REMOVE_LIST_DISCOUNT_CASHIER = 'REMOVE_LIST_DISCOUNT_CASHIER'
export const SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC_CASHIER = 'SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC_CASHIER'
export const GET_CUSTOMER_BY_KEYWORD = 'GET_CUSTOMER_BY_KEYWORD'
export const ADD_NEW_CUSTOMER = 'ADD_NEW_CUSTOMER'
export const POST_CASHIER_APPLY_MEMBER_CARD = 'POST_CASHIER_APPLY_MEMBER_CARD'
export const SEARCH_XET_NGHIEM_BY_GROUPS_KEYWORD = 'SEARCH_XET_NGHIEM_BY_GROUPS_KEYWORD'
export const ADD_NEW_USER = 'ADD_NEW_USER'
export const PUT_PHIEUTHU_CASHIER = 'PUT_PHIEUTHU_CASHIER'
export const UPDATE_USER = 'UPDATE_USER'
export const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER'
export const GET_BN_DA_KHAM_BY_PK = 'GET_BN_DA_KHAM_BY_PK'
export const DISPATCH_TRACUU_LIST_BN = 'DISPATCH_TRACUU_LIST_BN'
export const DISPATCH_TRACUU_INFO_BENH_NHAN = 'DISPATCH_TRACUU_INFO_BENH_NHAN'
export const GET_ALL_BN = 'GET_ALL_BN'
export const SEARCH_TRACUU_BN = 'SEARCH_TRACUU_BN'
export const GET_INFO_TRACUU_BENH_NHAN = 'GET_INFO_TRACUU_BENH_NHAN'
export const UPDATE_BN_TRACUU = 'UPDATE_BN_TRACUU'
export const GET_LIST_DISCOUNT_TTV = 'GET_LIST_DISCOUNT_TTV'
export const REMOVE_LIST_DISCOUNT_TTV = 'REMOVE_LIST_DISCOUNT_TTV'
export const UPDATE_NEW_PRICE_CHI_DINH_TTV = 'UPDATE_NEW_PRICE_CHI_DINH_TTV'
export const RESET_FORM_TAO_TTV = 'RESET_FORM_TAO_TTV'
export const GET_HISTORY_MEMBER_CARD = 'GET_HISTORY_MEMBER_CARD'
export const PUT_PAUSE_MEMBER_CARD = 'PUT_PAUSE_MEMBER_CARD'
export const PUT_DISABLE_MEMBER_CARD = 'PUT_DISABLE_MEMBER_CARD'
export const PUT_RUESE_MEMBER_CARD = 'PUT_RUESE_MEMBER_CARD'
export const GET_SO_LAN_KHAM_MEMBER_CARD = 'GET_SO_LAN_KHAM_MEMBER_CARD'
export const GET_BN_CHINHANH_CTY = 'GET_BN_CHINHANH_CTY'
export const SEARCH_DV_BY_KEYWORD = 'SEARCH_DV_BY_KEYWORD'
export const ADD_CĐHA = 'ADD_CĐHA'
export const DELETE_CĐHA = 'DELETE_CĐHA'
export const EDIT_CĐHA = 'EDIT_CĐHA'
export const PUT_CREATE_MEMBER_CARD_TTT = 'PUT_CREATE_MEMBER_CARD_TTT'
export const SEARCH_CĐHA = 'SEARCH_CĐHA'
export const GET_ALL_LIST_DICH_VU = 'GET_ALL_LIST_DICH_VU'
export const GET_LIST_TON_KHO_CN_VT = 'GET_LIST_TON_KHO_CN_VT'
export const DELETE_BN_THUOC_BS_KHONG_KE_TOA = 'DELETE_BN_THUOC_BS_KHONG_KE_TOA'
export const PUT_BN_THUOC_BS_KE_TOA_LAI = 'PUT_BN_THUOC_BS_KE_TOA_LAI'
export const GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT_NHOMXN =
  'GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT_NHOMXN'
export const GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT = 'GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT'
export const DISPATCH_SET_LIST_PT_BAN_LE = 'DISPATCH_SET_LIST_PT_BAN_LE'
export const GET_PHIEU_THU_THUOC_BAN_LE = 'GET_PHIEU_THU_THUOC_BAN_LE'
export const GET_LIST_BN_CHO_THUTHUAT = 'GET_LIST_BN_CHO_THUTHUAT'
export const GET_LIST_BN_DATHUCHIEN_THUTHUAT = 'GET_LIST_BN_DATHUCHIEN_THUTHUAT'
export const GET_INFO_BN_DV_THUTHUAT = 'GET_INFO_BN_DV_THUTHUAT'
export const GET_LIST_VT_THUTHUAT = 'GET_LIST_VT_THUTHUAT'
export const POST_LIST_VT_BN_THUTHUAT = 'POST_LIST_VT_BN_THUTHUAT'
export const GET_INFO_TRACUU_BENH_NHAN_TT = 'GET_INFO_TRACUU_BENH_NHAN_TT'
export const ADD_NEW_VERSION = 'ADD_NEW_VERSION'
export const DELETE_VERSION_BY_ID = 'DELETE_VERSION_BY_ID'
export const GET_BN_CHO_KHAM_BY_PK_SAN = 'GET_BN_CHO_KHAM_BY_PK_SAN'
export const GET_INFO_DETAIL_BN_KHAM_SAN = 'GET_INFO_DETAIL_BN_KHAM_SAN'
export const UPDATE_DETAIL_BN_KHAM_SAN = 'UPDATE_DETAIL_BN_KHAM_SAN'
export const GET_SUGGEST_DOCTOR_SAN = 'GET_SUGGEST_DOCTOR_SAN'
export const GET_THUOC_KE_TOA_DOCTOR_SAN = 'GET_THUOC_KE_TOA_DOCTOR_SAN'
export const ADD_NEW_CHI_DINH_KHAM_SAN = 'ADD_NEW_CHI_DINH_KHAM_SAN'
export const GET_BN_DA_KHAM_BY_PK_SAN = 'GET_BN_DA_KHAM_BY_PK_SAN'
export const DELETE_BN_THUOC_BS_KHONG_KE_TOA_SAN = 'DELETE_BN_THUOC_BS_KHONG_KE_TOA_SAN'
export const PUT_BN_THUOC_BS_KE_TOA_LAI_SAN = 'PUT_BN_THUOC_BS_KE_TOA_LAI_SAN'
export const UPDATE_VERSION = 'UPDATE_VERSION'
export const GET_LIST_DICH_VU_LOAITHE = 'GET_LIST_DICH_VU_LOAITHE'
export const GET_LIST_TYPES_CARD_BY_CN = 'GET_LIST_TYPES_CARD_BY_CN'
export const PUT_INFO_LOAITHE = 'PUT_INFO_LOAITHE'
export const POST_BIEU_MAU_SIEU_AM = 'POST_BIEU_MAU_SIEU_AM'
export const GET_ALL_BIEU_MAU = 'GET_ALL_BIEU_MAU'
export const GET_DANH_SACH_PHIEU_THU_CASHIER = 'GET_DANH_SACH_PHIEU_THU_CASHIER'
export const GET_INFO_BN_SIEU_AM = 'GET_INFO_BN_SIEU_AM'
export const POST_BENH_NHAN_SIEU_AM = 'POST_BENH_NHAN_SIEU_AM'
export const GET_CUSTOMER_BY_IDCT = 'GET_CUSTOMER_BY_IDCT'
export const GET_BN_CHO_KHAM_PHCN = 'GET_BN_CHO_KHAM_PHCN'
export const GET_BN_DANG_VA_DA_KHAM_PHCN = 'GET_BN_DANG_VA_DA_KHAM_PHCN'
export const GET_BN_CHO_KHAM_TMTD = 'GET_BN_CHO_KHAM_TMTD'
export const GET_BN_DANG_VA_DA_KHAM_TMTD = 'GET_BN_DANG_VA_DA_KHAM_TMTD'
export const GET_LIST_BN_CHO_VLTL = 'GET_LIST_BN_CHO_VLTL'
export const GET_LIST_BN_DA_VLTL = 'GET_LIST_BN_DA_VLTL'
export const GET_INFO_BN_TAP_VLTL = 'GET_INFO_BN_TAP_VLTL'
export const DISPATCH_LIST_MONITOR = 'DISPATCH_LIST_MONITOR'
export const DISPATCH_INFO_BN_MONITOR = 'DISPATCH_INFO_BN_MONITOR'
export const DISPATCH_HISTORY_MONITOR = 'DISPATCH_HISTORY_MONITOR'
export const GET_INFO_BN_MONITOR = 'GET_INFO_BN_MONITOR'
export const ADD_BN_MONITOR = 'ADD_BN_MONITOR'
export const GET_ALL_BN_CHO_MONITOR = 'GET_ALL_BN_CHO_MONITOR'
export const GET_INFO_BN_PENDING_SIEU_AM = 'GET_INFO_BN_PENDING_SIEU_AM'
export const POST_BN_TAP_VLTL = 'POST_BN_TAP_VLTL'
export const GET_INFO_SO_LAN_BN_TAP_VLTL = 'GET_INFO_SO_LAN_BN_TAP_VLTL'
export const PUT_UPGRADE_MEMBER_CARD = 'PUT_UPGRADE_MEMBER_CARD'
export const PUT_EXTEND_MEMBER_CARD = 'PUT_EXTEND_MEMBER_CARD'
export const DISPATCH_INFO_BN_DIEN_TIM = 'DISPATCH_INFO_BN_DIEN_TIM'
export const RESET_BENN_NHAN_DIEN_TIM = 'RESET_BENN_NHAN_DIEN_TIM'
export const DISPATCH_HISTORY_BN_DIEN_TIM = 'DISPATCH_HISTORY_BN_DIEN_TIM'
export const GET_INFO_BN_DIEN_TIM = 'GET_INFO_BN_DIEN_TIM'
export const POST_BENH_NHAN_DIEN_TIM = 'POST_BENH_NHAN_DIEN_TIM'
export const GET_INFO_BN_PENDING_DIEN_TIM = 'GET_INFO_BN_PENDING_DIEN_TIM'
export const ACCPECT_NHAP_KHO_MODAL = 'ACCPECT_NHAP_KHO_MODAL'
export const UPLOAD_FILE_NHAP_KHO = 'UPLOAD_FILE_NHAP_KHO'
export const DELETE_FILE_NHAP_KHO = 'DELETE_FILE_NHAP_KHO'
export const SEARCH_XET_NGHIEM_BY_CTY_KEYWORD = 'SEARCH_XET_NGHIEM_BY_CTY_KEYWORD'
export const SEARCH_XET_NGHIEM_BY_GROUPS_AND_CTY_KEYWORD =
  'SEARCH_XET_NGHIEM_BY_GROUPS_AND_CTY_KEYWORD'
export const GET_BN_CHINHANH_CTY_SKB = 'GET_BN_CHINHANH_CTY_SKB'
export const GET_ALL_BN_SKB = 'GET_ALL_BN_SKB'
export const SEARCH_TRACUU_BN_SKB = 'SEARCH_TRACUU_BN_SKB'
export const DISPATCH_TRACUU_LIST_BN_SKB = 'DISPATCH_TRACUU_LIST_BN_SKB'
export const GET_BN_CHO_DKX = 'GET_BN_CHO_DKX'
export const GET_BN_DA_DKX = 'GET_BN_DA_DKX'
export const GET_INFO_BN_DKX = 'GET_INFO_BN_DKX'
export const DELETE_PHIEU_CHUYEN_KHO = 'DELETE_PHIEU_CHUYEN_KHO'
export const POST_INFO_BN_DKX = 'POST_INFO_BN_DKX'
export const PUT_INFO_BN_DKX = 'PUT_INFO_BN_DKX'
export const GET_LIST_BN_CHO_TRONGKINH = 'GET_LIST_BN_CHO_TRONGKINH'
export const GET_LIST_BN_DA_TRONGKINH = 'GET_LIST_BN_DA_TRONGKINH'
export const GET_BN_CHO_KHAM_SANG_LOC = 'GET_BN_CHO_KHAM_SANG_LOC'
export const GET_BN_DANG_VA_DA_KHAM_SANG_LOC = 'GET_BN_DANG_VA_DA_KHAM_SANG_LOC'
export const GET_INFO_BN_KSL = 'GET_INFO_BN_KSL'
export const POST_INFO_BN_KSL = 'POST_INFO_BN_KSL'
export const ADD_NEW_THUOC_DETAIL_NHAP_KHO = 'ADD_NEW_THUOC_DETAIL_NHAP_KHO'
export const GET_LIST_TRONG_VA_GONG_KINH_DKX = 'GET_LIST_TRONG_VA_GONG_KINH_DKX'
export const POST_LIST_TRONG_VA_GONG_KINH_DKX = 'POST_LIST_TRONG_VA_GONG_KINH_DKX'
export const UPDATE_DETAIL_BN_KHAM_SANG_LOC = 'UPDATE_DETAIL_BN_KHAM_SANG_LOC'
export const GET_BN_CHO_LAY_THUOC_XVX = 'GET_BN_CHO_LAY_THUOC_XVX'
export const GET_BN_DA_LAY_THUOC_XVX = 'GET_BN_DA_LAY_THUOC_XVX'
export const UPLOAD_FILE_CHUYEN_KHO = 'UPLOAD_FILE_CHUYEN_KHO'
export const DELETE_FILE_CHUYEN_KHO = 'DELETE_FILE_CHUYEN_KHO'
export const PRINTER_CHUYEN_KHO = 'PRINTER_CHUYEN_KHO'
export const GET_LIST_BN_CHO_TIEMCHUNG = 'GET_LIST_BN_CHO_TIEMCHUNG'
export const GET_LIST_BN_DA_TIEMCHUNG = 'GET_LIST_BN_DA_TIEMCHUNG'
export const GET_INFO_BN_TIEMCHUNG = 'GET_INFO_BN_TIEMCHUNG'
export const POST_INFO_BN_THEO_DOI_SAU_TIEM = 'POST_INFO_BN_THEO_DOI_SAU_TIEM'
export const GET_PHIEU_THU_VACXIN = 'GET_PHIEU_THU_VACXIN'
export const DISPATCH_ALL_CTY_CRM = 'DISPATCH_ALL_CTY_CRM'
export const DISPATCH_INFO_CTY_CRM = 'DISPATCH_INFO_CTY_CRM'
export const GET_ALL_CTY_CRM = 'GET_ALL_CTY_CRM'
export const DELETE_CTY_CRM = 'DELETE_CTY_CRM'
export const ADD_CTY_CRM = 'ADD_CTY_CRM'
export const GET_CTY_CRM_BY_ID = 'GET_CTY_CRM_BY_ID'
export const EDIT_CTY_CRM = 'EDIT_CTY_CRM'
export const DISPATCH_ALL_LH_CRM = 'DISPATCH_ALL_LH_CRM'
export const DISPATCH_INFO_LH_CRM = 'DISPATCH_INFO_LH_CRM'
export const GET_ALL_LH_CRM = 'GET_ALL_LH_CRM'
export const ADD_LH_CRM = 'ADD_LH_CRM'
export const EDIT_LH_CRM = 'EDIT_LH_CRM'
export const GET_CHITIET_LH_CRM = 'GET_CHITIET_LH_CRM'
export const ADD_CHITIET_LH_CRM = 'ADD_CHITIET_LH_CRM'
export const DISPATCH_INFO_CHITIET_LH_CRM = 'DISPATCH_INFO_CHITIET_LH_CRM'
export const GET_THONGTIN_LH_CRM = 'GET_THONGTIN_LH_CRM'
export const PUT_LIST_DV_BN_TRONGKINH = 'PUT_LIST_DV_BN_TRONGKINH'
export const DISPATCH_LIST_PHANCONG_CRM = 'DISPATCH_LIST_PHANCONG_CRM'
export const DISPATCH_INFO_PHANCONG_CRM = 'DISPATCH_INFO_PHANCONG_CRM'
export const GET_ALL_PHANCONG_LIENHE_CRM = 'GET_ALL_PHANCONG_LIENHE_CRM'
export const GET_DETAIL_PHANCONG_LIENHE_CRM = 'GET_DETAIL_PHANCONG_LIENHE_CRM'
export const GET_TRANGTHAI_PHANCONG_LIENHE_CRM = 'GET_TRANGTHAI_PHANCONG_LIENHE_CRM'
export const DISPATCH_TRANGTHAI_PHANCONG_CRM = 'DISPATCH_TRANGTHAI_PHANCONG_CRM'
export const ADD_LIST_PHANCONG_LIENHE_CRM = 'ADD_LIST_PHANCONG_LIENHE_CRM'
export const EDIT_PHANCONG_LIENHE_CRM = 'EDIT_PHANCONG_LIENHE_CRM'
export const DISPATCH_ALL_KHACHHANG_CRM = 'DISPATCH_ALL_KHACHHANG_CRM'
export const DISPATCH_INFO_KHACHHANG_CRM = 'DISPATCH_INFO_KHACHHANG_CRM'
export const GET_ALL_KHACHHANG_CRM = 'GET_ALL_KHACHHANG_CRM'
export const ADD_KHACHHANG_CRM = 'ADD_KHACHHANG_CRM'
export const EDIT_KHACHHANG_CRM = 'EDIT_KHACHHANG_CRM'
export const GET_KHACHHANG_CRM_BY_ID = 'GET_KHACHHANG_CRM_BY_ID'
export const GET_ALL_GOIKHAM_CRM = 'GET_ALL_GOIKHAM_CRM'
export const ADD_GOIKHAM_CRM = 'ADD_GOIKHAM_CRM'
export const EDIT_GOIKHAM_CRM = 'EDIT_GOIKHAM_CRM'
export const GET_GOIKHAM_CRM_BY_ID = 'GET_GOIKHAM_CRM_BY_ID'
export const DISPATCH_ALL_GOIKHAM_CRM = 'DISPATCH_ALL_GOIKHAM_CRM'
export const DISPATCH_INFO_GOIKHAM_CRM = 'DISPATCH_INFO_GOIKHAM_CRM'
export const GET_BN_CHO_KHAM_MAT = 'GET_BN_CHO_KHAM_MAT'
export const GET_INFO_PHIEUTHU_TTV = 'GET_INFO_PHIEUTHU_TTV'
export const GET_ALL_GOIKHAM_KHACHHANG_CRM = 'GET_ALL_GOIKHAM_KHACHHANG_CRM'
export const ADD_GOIKHAM_KHACHHANG_CRM = 'ADD_GOIKHAM_KHACHHANG_CRM'
export const EDIT_GOIKHAM_KHACHHANG_CRM = 'EDIT_GOIKHAM_KHACHHANG_CRM'
export const GET_GOIKHAM_KHACHHANG_CRM_BY_ID = 'GET_GOIKHAM_KHACHHANG_CRM_BY_ID'
export const DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM = 'DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM'
export const DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM = 'DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM'
export const GET_INFO_DETAIL_COMPANY_CRM = 'GET_INFO_DETAIL_COMPANY_CRM'
export const DISPATCH_LOAD_TONG_GIAM_CASHIER = 'DISPATCH_LOAD_TONG_GIAM_CASHIER'
export const DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER = 'DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER'
export const GET_ALL_BN_CRM = 'GET_ALL_BN_CRM'
export const DISPATCH_LIST_ALL_BN_CRM = 'DISPATCH_LIST_ALL_BN_CRM'
export const DELETE_BN_CRM_BY_ID = 'DELETE_BN_CRM_BY_ID'
export const POST_BN_CRM = 'POST_BN_CRM'
export const GET_ALL_KH_CRM = 'GET_ALL_KH_CRM'
export const DISPATCH_ALL_KH_BN_CRM = 'DISPATCH_ALL_KH_BN_CRM'
export const GET_INFO_BN_CRM = 'GET_INFO_BN_CRM'
export const DISPATCH_INFO_BN_CRM = 'DISPATCH_INFO_BN_CRM'
export const ADD_LIST_INFO_SMS_CRM = 'ADD_LIST_INFO_SMS_CRM'
export const SEND_SMS_BN_CRM = 'SEND_SMS_BN_CRM'
export const UPDATE_INFO_BN_CRM = 'UPDATE_INFO_BN_CRM'
export const IMPORT_DATA_CRM = 'IMPORT_DATA_CRM'
export const IMPORT_LIST_BN_CRM = 'IMPORT_LIST_BN_CRM'
export const GET_GOIKHAM_CRM_BY_ID_KH = 'GET_GOIKHAM_CRM_BY_ID_KH'
export const GET_ALL_LH_CRM_BY_ID_NV = 'GET_ALL_LH_CRM_BY_ID_NV'
export const GET_LIST_VTTH_THEOKHO_THUTHUAT = 'GET_LIST_VTTH_THEOKHO_THUTHUAT'
export const GET_PHANCONG_LIENHE_IDNV_CRM = 'GET_PHANCONG_LIENHE_IDNV_CRM'
export const GET_LIST_CD_XN_TIEPNHAN = 'GET_LIST_CD_XN_TIEPNHAN'
export const GET_LIST_CD_CDHA_TIEPNHAN = 'GET_LIST_CD_CDHA_TIEPNHAN'
export const CHECK_IMPORT_DANH_SACH_VAT_TU = 'CHECK_IMPORT_DANH_SACH_VAT_TU'
export const SEARCH_BN_CRM = 'SEARCH_BN_CRM'
export const SET_INFO_VOUCHER_DOITAC_TTV = 'SET_INFO_VOUCHER_DOITAC_TTV'
export const GET_APPLY_VOUCHER_TIEPNHAN = 'GET_APPLY_VOUCHER_TIEPNHAN'
export const CHUYEN_KHO_COMPANY = 'CHUYEN_KHO_COMPANY'
export const UPDATE_CHUYEN_KHO_CTY = 'UPDATE_CHUYEN_KHO_CTY'
export const GET_LIST_XET_NGHIEM_LOAITHE = 'GET_LIST_XET_NGHIEM_LOAITHE'
export const GET_LIST_CDHA_LOAITHE = 'GET_LIST_CDHA_LOAITHE'
export const GET_TON_KHO_TONG_HOP = 'GET_TON_KHO_TONG_HOP'
export const GET_LIST_BN_CHO_KSK = 'GET_LIST_BN_CHO_KSK'
export const GET_LIST_BN_DANG_VA_DA_KSK = 'GET_LIST_BN_DANG_VA_DA_KSK'
export const GET_INFO_BN_KSK = 'GET_INFO_BN_KSK'
export const POST_INFO_BN_KSK = 'POST_INFO_BN_KSK'
export const UPDATE_SERVICE_BN = 'UPDATE_SERVICE_BN'
export const COPY_LIST_HANG_HOA = 'COPY_LIST_HANG_HOA'
export const DISPATCH_LIST_NGUOI_DUYET = 'DISPATCH_LIST_NGUOI_DUYET'
export const DISPATCH_LIST_DATHANG = 'DISPATCH_LIST_DATHANG'
export const DISPATCH_LIST_HANGHOA = 'DISPATCH_LIST_HANGHOA'
export const GET_ALL_HANG_HOA = 'GET_ALL_HANG_HOA'
export const GET_ALL_NGUOI_DUYET = 'GET_ALL_NGUOI_DUYET'
export const GET_ALL_DAT_HANG = 'GET_ALL_DAT_HANG'
export const UPDATE_STATUS_NHAP_KHO_TOOL = 'UPDATE_STATUS_NHAP_KHO_TOOL'
export const ADD_DON_DAT_HANG = 'ADD_DON_DAT_HANG'
export const UPDATE_PAYMENT_BN = 'UPDATE_PAYMENT_BN'
export const POST_BN_TRA_THUOC = 'POST_BN_TRA_THUOC'
export const GET_INFO_NGUOI_NHA_BN = 'GET_INFO_NGUOI_NHA_BN'
export const DISPATCH_INFO_DONHANG = 'DISPATCH_INFO_DONHANG'
export const GET_INFO_DON_HANG = 'GET_INFO_DON_HANG'
export const EDIT_DON_DAT_HANG = 'EDIT_DON_DAT_HANG'
export const UPDATE_TRANG_THAI_DON_DAT_HANG = 'UPDATE_TRANG_THAI_DON_DAT_HANG'
export const GET_ALL_DON_DAT_HANG_BY_TRANG_THAI = 'GET_ALL_DON_DAT_HANG_BY_TRANG_THAI'
export const DELETE_DON_HANG = 'DELETE_DON_HANG'
export const GET_ALL_DON_DAT_HANG_BY_IDCT_IDCN = 'GET_ALL_DON_DAT_HANG_BY_IDCT_IDCN'
export const POST_BN_TRA_TOA_CHO_BS = 'POST_BN_TRA_TOA_CHO_BS'
export const GET_INFO_DON_HANG_INCLUDE_MONEY = 'GET_INFO_DON_HANG_INCLUDE_MONEY'
export const DISPATCH_CLEAR_INFO_DONHANG = 'DISPATCH_CLEAR_INFO_DONHANG'
export const ADD_HANG_HOA_CHUYEN_KHO = 'ADD_HANG_HOA_CHUYEN_KHO'
export const UPDATE_TTV_TOOL = 'UPDATE_TTV_TOOL'
export const PUT_BN_THUOC_BS_KE_TOA_LAI_VACXIN_KSL = 'PUT_BN_THUOC_BS_KE_TOA_LAI_VACXIN_KSL'
export const GET_XET_NGHIEM_BY_CONDITION = 'GET_XET_NGHIEM_BY_CONDITION'
export const GET_LIST_THUOC_TRA = 'GET_LIST_THUOC_TRA'
export const GET_ALL_DON_DAT_HANG_THU_MUA_DUYET = 'GET_ALL_DON_DAT_HANG_THU_MUA_DUYET'
export const GET_PHIEU_CHUYEN_KHO = 'GET_PHIEU_CHUYEN_KHO'
export const GET_BRANCH_NOT_VP = 'GET_BRANCH_NOT_VP'
export const UPDATE_SERVICE_PHIEU_THU = 'UPDATE_SERVICE_PHIEU_THU'
export const NHAN_DON_DAT_HANG = 'NHAN_DON_DAT_HANG'
export const POST_PHAN_ANH_KHACH_HANG = 'POST_PHAN_ANH_KHACH_HANG'
export const UPDATE_CHI_TIET_THANH_TOAN_PT = 'UPDATE_CHI_TIET_THANH_TOAN_PT'
export const UPDATE_BNTT_GHI_CHU_HD = 'UPDATE_BNTT_GHI_CHU_HD'
// Đơn vị tính
export const ADD_DON_VI_TINH = 'ADD_DON_VI_TINH'
export const DELETE_DON_VI_TINH = 'DELETE_DON_VI_TINH'
export const UPDATE_DON_VI_TINH = 'UPDATE_DON_VI_TINH'
export const GET_BY_MA_DON_VI_TINH = 'GET_BY_MA_DON_VI_TINH'
export const DISPATCH_BY_MA_DON_VI_TINH = 'DISPATCH_BY_MA_DON_VI_TINH'
export const GET_ALL_DON_VI_TINH = 'GET_ALL_DON_VI_TINH'
export const DISPATCH_ALL_DON_VI_TINH = 'DISPATCH_ALL_DON_VI_TINH'
export const PUT_PHAN_ANH_KHACH_HANG = 'PUT_PHAN_ANH_KHACH_HANG'
export const POST_LIST_XULY_PHANANH = 'POST_LIST_XULY_PHANANH'
export const COPY_PRODUCTS_VPP = 'COPY_PRODUCTS_VPP'
export const COPY_PRODUCTS_CHUYEN_KHO = 'COPY_PRODUCTS_CHUYEN_KHO'
export const GET_INO_TTV_THU_NGAN = 'GET_INO_TTV_THU_NGAN'
export const GET_ALL_DANHMUC_SEARCH_TTV = 'GET_ALL_DANHMUC_SEARCH_TTV'
export const GET_NHAN_SU_CO = 'GET_NHAN_SU_CO'
export const ADD_NEW_NHAN = 'ADD_NEW_NHAN'
export const GET_INFO_BN_CARD_TANGSAN = 'GET_INFO_BN_CARD_TANGSAN'
export const POST_TTV_NHI_TANGSAN = 'POST_TTV_NHI_TANGSAN'
//sms
export const DISPATCH_LIST_BN_SMS = 'DISPATCH_LIST_BN_SMS'
export const DISPATCH_LIST_TEMPLATE_SMS = 'DISPATCH_LIST_TEMPLATE_SMS'
export const GET_LIST_TEMPLATE_SMS = 'GET_LIST_TEMPLATE_SMS'
export const GET_BN_SMS = 'GET_BN_SMS'

export const GET_BN_CHO_BS_KE_TOA_LAI = 'GET_BN_CHO_BS_KE_TOA_LAI'
export const GET_BN_CHO_BS_KE_TOA_LAI_XVX = 'GET_BN_CHO_BS_KE_TOA_LAI_XVX'
export const PUT_BNTT_VA_BNCTTT_PHIEUTHU_TOATHUOC = 'PUT_BNTT_VA_BNCTTT_PHIEUTHU_TOATHUOC'
export const POST_LOAI_THE = 'POST_LOAI_THE'
export const POST_DOI_TAC_KH = 'POST_DOI_TAC_KH'
export const PUT_DOI_TAC_KH = 'PUT_DOI_TAC_KH'
export const POST_KHACH_HANG_DT = 'POST_KHACH_HANG_DT'
export const PUT_KHACH_HANG_DT = 'PUT_KHACH_HANG_DT'
export const POST_DANH_MUC_NHAN_SU_CO = 'POST_DANH_MUC_NHAN_SU_CO'
export const GET_NHAN_DANH_MUC = 'GET_NHAN_DANH_MUC'
export const GET_DANH_MUC_BY_NHAN = 'GET_DANH_MUC_BY_NHAN'
export const GET_INFO_NHAN_SU_CO = 'GET_INFO_NHAN_SU_CO'
export const UPDATE_NHAN_SU_CO = 'UPDATE_NHAN_SU_CO'
export const DELETE_NHAN_SU_CO = 'DELETE_NHAN_SU_CO'
export const GET_NHAN_VIEN_SU_CO_BY_VUNG = 'GET_NHAN_VIEN_SU_CO_BY_VUNG'
export const GET_DANH_SACH_BAO_HONG = 'GET_DANH_SACH_BAO_HONG'
export const ADD_VUNG_BAO_HONG = 'ADD_VUNG_BAO_HONG'
export const GET_VUNG_BAO_HONG = 'GET_VUNG_BAO_HONG'
export const CREATE_BAO_HONG = 'CREATE_BAO_HONG'
export const GET_DANH_SACH_NHAN_VIEN_SU_CO = 'GET_DANH_SACH_NHAN_VIEN_SU_CO'
export const POST_NHAN_VIEN_BAO_HONG = 'POST_NHAN_VIEN_BAO_HONG'
export const DELETE_NHAN_VIEN_BAO_HONG = 'DELETE_NHAN_VIEN_BAO_HONG'
export const GET_INFO_BAO_HONG = 'GET_INFO_BAO_HONG'
export const GET_ALL_HANG_MUC = 'GET_ALL_HANG_MUC'
export const GET_INFO_NHAN_BY_HANG_MUC = 'GET_INFO_NHAN_BY_HANG_MUC'
export const DELETE_FILE_BAO_HONG = 'DELETE_FILE_BAO_HONG'
export const GET_KT_THUC_HIEN_BAO_HONG = 'GET_KT_THUC_HIEN_BAO_HONG'
export const DELETE_NHAN_VIEN_THUC_HIEN_DETAIL = 'DELETE_NHAN_VIEN_THUC_HIEN_DETAIL'
export const UPDATE_BAO_HONG = 'UPDATE_BAO_HONG'
export const GET_IMGAE_BAO_HONG = 'GET_IMGAE_BAO_HONG'
export const POST_CHI_NHANH_VUNG = 'POST_CHI_NHANH_VUNG'
export const GET_CHI_NHANH_BY_VUNG = 'GET_CHI_NHANH_BY_VUNG'
export const GET_INFO_XU_LY_BAO_HONG = 'GET_INFO_XU_LY_BAO_HONG'
export const POST_IMAGE_BAO_HONG = 'POST_IMAGE_BAO_HONG'
export const UPDATE_XU_LY_SU_CO = 'UPDATE_XU_LY_SU_CO'
export const UPLOAD_FILE_XU_LY = 'UPLOAD_FILE_XU_LY'
export const DELETE_FILE_XULY = 'DELETE_FILE_XULY'
export const UPLOAD_FILE_BAO_HONG = 'UPLOAD_FILE_BAO_HONG'
export const GET_DANH_SACH_BAO_HONG_HOANG_THANH = 'GET_DANH_SACH_BAO_HONG_HOANG_THANH'
export const POST_NHAN_VIEN_VUNG = 'POST_NHAN_VIEN_VUNG'
export const DELETE_BAO_HONG = 'DELETE_BAO_HONG'
export const UPDATE_SU_CO_BAO_HONG = 'UPDATE_SU_CO_BAO_HONG'
export const DELETE_NHAN_VIEN_VUNG = 'DELETE_NHAN_VIEN_VUNG'
export const DELETE_CHI_NHANH_VUNG_BAO_HONG = 'DELETE_CHI_NHANH_VUNG_BAO_HONG'
export const UPDATE_KHU_VUC = 'UPDATE_KHU_VUC'
export const DELETE_VUNG_BAO_HONG = 'DELETE_VUNG_BAO_HONG'
export const TTV_BENH_NHAN_NHI = 'TTV_BENH_NHAN_NHI'
export const GET_DANH_SACH_BENH_NHAN_KHONG_LAY_THUOC = 'GET_DANH_SACH_BENH_NHAN_KHONG_LAY_THUOC'
export const POST_PHIEU_XUAT_SU_DUNG = 'POST_PHIEU_XUAT_SU_DUNG'
export const GET_ALL_UU_DAI_TAI_KHAM = 'GET_ALL_UU_DAI_TAI_KHAM'
export const GET_DICH_VU_BY_CONG_TY = 'GET_DICH_VU_BY_CONG_TY'
export const ADD_NEW_UU_DAI_TAI_KHAM = 'ADD_NEW_UU_DAI_TAI_KHAM'
export const DELETE_UU_DAI_TAI_KHAM = 'DELETE_UU_DAI_TAI_KHAM'
export const GET_UU_DAI_TAI_KHAM_TIEP_NHAN = 'GET_UU_DAI_TAI_KHAM_TIEP_NHAN'
export const PUT_LIST_VT_BN_THUTHUAT = 'PUT_LIST_VT_BN_THUTHUAT'
export const GET_LIST_TYPES_THUOC_GPP = 'GET_LIST_TYPES_THUOC_GPP'
export const DISPATCH_TYPES_THUOC_GPP = 'DISPATCH_TYPES_THUOC_GPP'
export const GET_THUOC_GPP_BY_ID = 'GET_THUOC_GPP_BY_ID'
export const DISPATCH_THUOC_GPP_BY_ID = 'DISPATCH_THUOC_GPP_BY_ID'
export const GET_BN_CHINHANH_CTY_MAT = 'GET_BN_CHINHANH_CTY_MAT'
export const CALCULATOR_SERVICE_BY_MEMBERCARD = 'CALCULATOR_SERVICE_BY_MEMBERCARD'
export const GET_HISTORY_GPP = 'GET_HISTORY_GPP'
export const DISPATCH_HISTORY_GPP = 'DISPATCH_HISTORY_GPP'
export const TOO_GOP_BENH_AN = 'TOO_GOP_BENH_AN'
export const THONG_KE_DONG_MO_CA = 'THONG_KE_DONG_MO_CA'
export const UPDATE_TRANG_THAI_LIST_DON_DAT_HANG = 'UPDATE_TRANG_THAI_LIST_DON_DAT_HANG'
export const UPDATE_NGAY_TAI_KHAM_TOOL = 'UPDATE_NGAY_TAI_KHAM_TOOL'

export const PUT_NHAN_XULY_PHANANH = 'PUT_NHAN_XULY_PHANANH'
export const PUT_THONGTIN_XULY_PHANANH = 'PUT_THONGTIN_XULY_PHANANH'
export const PUT_DAXULY_PHANANH = 'PUT_DAXULY_PHANANH'
export const DISPATCH_TRACUU_LIST_BN_MAT = 'DISPATCH_TRACUU_LIST_BN_MAT'
export const DISPATCH_TRACUU_INFO_BENH_NHAN_MAT = 'DISPATCH_TRACUU_INFO_BENH_NHAN_MAT'
export const SEARCH_TRACUU_BN_MAT = 'SEARCH_TRACUU_BN_MAT'
export const UPDATE_BN_TRACUU_MAT = 'UPDATE_BN_TRACUU_MAT'
export const GET_INFO_TRACUU_BENH_NHAN_MAT = 'GET_INFO_TRACUU_BENH_NHAN_MAT'
export const GET_INFO_TRACUU_BENH_NHAN_TT_MAT = 'GET_INFO_TRACUU_BENH_NHAN_TT_MAT'
export const GET_ALL_BN_MAT = 'GET_ALL_BN_MAT'
export const GET_BN_CHINHANH_CTY_TRACUU_MAT = 'GET_BN_CHINHANH_CTY_TRACUU_MAT'
export const DELETE_DANH_MUC_NHAN = 'DELETE_DANH_MUC_NHAN'
export const UPDATE_DANH_MUC_NHAN = 'UPDATE_DANH_MUC_NHAN'
export const GET_BRANCH_BY_CHUYEN_KHOA_AND_COMPANY = 'GET_BRANCH_BY_CHUYEN_KHOA_AND_COMPANY'
export const POST_GIAVATTU_DV_THUTHUAT_HOAHONG = 'POST_GIAVATTU_DV_THUTHUAT_HOAHONG'
export const PUT_GIAVATTU_DV_THUTHUAT_HOAHONG = 'PUT_GIAVATTU_DV_THUTHUAT_HOAHONG'
export const DELETE_GIAVATTU_DV_THUTHUAT_HOAHONG = 'DELETE_GIAVATTU_DV_THUTHUAT_HOAHONG'
export const PUT_TRANGTHAI_DKK_ONL = 'PUT_TRANGTHAI_DKK_ONL'
export const CHUYEN_NHAN_VIEN_THUC_HIEN_BAO_HONG = 'CHUYEN_NHAN_VIEN_THUC_HIEN_BAO_HONG'
export const GET_HISTORY_STAFF_SU_CO = 'GET_HISTORY_STAFF_SU_CO'
export const GET_DANH_SACH_THONG_KE_BAO_HONG = 'GET_DANH_SACH_THONG_KE_BAO_HONG'
export const PUT_CALAMVIEC_PHIEUTHU_TOOL = 'PUT_CALAMVIEC_PHIEUTHU_TOOL'
export const PUT_LOAIKHAM_PHIEUTHU_TOOL = 'PUT_LOAIKHAM_PHIEUTHU_TOOL'
export const PUT_NGUOIBAN_TTV_TOOL = 'PUT_NGUOIBAN_TTV_TOOL'
export const DELETE_PHIEU_KHO_TOOL = 'DELETE_PHIEU_KHO_TOOL'
export const GET_DANH_SACH_THUOC_BAN = 'GET_DANH_SACH_THUOC_BAN'
export const GET_INFO_FORM_PHIEUNHAP_VPP = 'GET_INFO_FORM_PHIEUNHAP_VPP'
export const POST_LIST_PHIEUNHAP_VPP = 'POST_LIST_PHIEUNHAP_VPP'
