import { https } from "../apiService"

export const benhnhanCRMService = {
    getAllBNCRM: () => https.get('CRMBenhNhan/SearchBenhNhanByCondition'),
    deleteBNCRMById: (id) => https.delete(`CRMBenhNhan/DeleteBenhNhan/${id}`),
    postBNCRM: (form) => https.post('CRMBenhNhan/AddListBenhNhan',form),
    getAllKHCRM: () => https.get('CRMKhachHang/GetAll'),
    sendSMS:(sdt) => https.post(`CRMBenhNhan/SendSMS?phoneNumber=${sdt}`),
    getInfoBNCRMById: (id) => https.get(`CRMBenhNhan/GetBenhNhanByIDBN?IDBN=${id}`),
    postInfoBNCRMById: (id,form) => https.put(`CRMBenhNhan/UpdateBenhNhan?id=${id}`,form),
    postBNCRMByExcel: (form) => https.post('CRMBenhNhan/ImportListBenhNhan',form),
    searchBNCRM: (idkh, keyword) => https.get(`CRMBenhNhan/SearchBenhNhanByCondition?idKH=${idkh}&keyword=${keyword}`),
}