import { Input, Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import 'moment/locale/vi'
moment.locale('vi')
const InfoBN = ({ infoBN }) => {
  const today = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBNTiemChung } = useSelector((state) => state.tiemChungReducer)
  //   const handleSelected = (value) => {
  //     formik.setFieldValue('donviguimau', value)
  //   }
  return (
    <div className="p-2 border rounded-md">
      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Tên BN: </label>
              <Input value={infoBNTiemChung?.TENBENHNHAN} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Mã BN: </label>
              <Input value={infoBNTiemChung?.MABENHNHAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Địa chỉ: </label>
              <Input
                value={
                  '' +
                  (infoBNTiemChung?.DIACHI?.length > 0 ? infoBNTiemChung?.DIACHI : '') +
                  (infoBNTiemChung?.TENPHUONG?.length > 0
                    ? ', ' + infoBNTiemChung?.TENPHUONG
                    : '') +
                  (infoBNTiemChung?.TENQUAN?.length > 0 ? ', ' + infoBNTiemChung?.TENQUAN : '') +
                  (infoBNTiemChung?.TENTINH?.length > 0 ? ', ' + infoBNTiemChung?.TENTINH : '')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">SĐT: </label>
              <Input value={infoBNTiemChung?.DIENTHOAI} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Thực hiện: </label>
              <Input
                value={
                  infoBNTiemChung
                    ? infoBNTiemChung?.DanhSachVatTuTieuHao?.length > 0
                      ? infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.nguoiThucHien
                      : infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb !== 4
                        ? infoUser?.tenNV
                        : ''
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày: </label>
              <Input
                value={
                  infoBNTiemChung
                    ? infoBNTiemChung?.DanhSachVatTuTieuHao?.length > 0
                      ? infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.ngayThucHien &&
                        moment(infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.ngayThucHien).format(
                          'DD/MM/YYYY',
                        )
                      : infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb !== 4
                        ? today.format('DD/MM/YYYY')
                        : ''
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            {/* <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Thực hiện: </label>
              <Input value={infoBNTiemChung ? infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.nguoiThucHien ? infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.nguoiThucHien : infoUser?.tenNV : ''} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày: </label>
              <Input value={infoBNTiemChung ? infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.ngayThucHien ? moment(infoBNTiemChung?.DanhSachVatTuTieuHao[0]?.ngayThucHien).format('DD/MM/YYYY') : today.format('DD/MM/YYYY') : ''} variant="filled" readOnly />
            </div> */}
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Chẩn đoán: </label>
              <Input value={infoBNTiemChung?.CHANDOAN} variant="filled" readOnly />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Ghi chú: </label>
              <Input value={infoBNTiemChung?.GHICHU} variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 flex flex-col">
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Ngày sinh: </label>
              <Input
                value={
                  infoBNTiemChung?.NGAYSINH &&
                  moment(infoBNTiemChung?.NGAYSINH).format('DD/MM/YYYY')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Giới tính: </label>
              <Input value={infoBNTiemChung?.GIOITINH} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Đối tượng: </label>
              <Input value={infoBNTiemChung?.DOITUONG} variant="filled" readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Mã TCQG: </label>
              <Input value={infoBNTiemChung?.MATCQG} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lý do: </label>

              <Input variant="filled" value={infoBNTiemChung?.LIDO} readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lời dặn: </label>
              <Input value={infoBNTiemChung?.LOIDAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Bác sĩ CĐ: </label>
            <Input variant="filled" value={infoBNTiemChung?.BSCHANDOAN} readOnly />
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Triệu chứng: </label>
            <Input variant="filled" value={infoBNTiemChung?.TRIEUCHUNG} readOnly />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex gap-2 flex-col mt-2">
        <ul className="flex gap-2 w-full">
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/2 text-gray-500 pl-5">Chiều cao</p>
            <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
              {infoBN?.chieucao ?? 0} Cm
            </span>
          </li>
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/2 text-gray-500 pl-5">Cân nặng</p>
            <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
              {infoBN?.cannang ?? 0} Kg
            </span>
          </li>
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/3 text-gray-500 pl-5">Mạch</p>
            <span className="w-2/3 text-center font-semibold text-green-700 text-sm">
              {infoBN?.mach ?? 0} Lần/phút
            </span>
          </li>
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/2 text-gray-500 pl-5">Huyết áp</p>
            <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
              {infoBN?.huyetap ?? 0} mmHg
            </span>
          </li>
        </ul>
        <ul className="flex gap-2 w-full">
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/2 text-gray-500 pl-5">Nhiệt độ</p>
            <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
              {infoBN?.nhietdo ?? 0} &#8451;
            </span>
          </li>
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/3 text-gray-500 pl-5">Nhịp thở</p>
            <span className="w-2/3 text-center font-semibold text-green-700 text-sm">
              {infoBN?.nhiptho ?? 0} Lần/Phút
            </span>
          </li>
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/2 text-gray-500 pl-5">Spo2</p>
            <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
              {infoBN?.spO2 ?? 0} %
            </span>
          </li>
          <li
            className="w-[25%] p-1 rounded-md flex justify-between items-center bg-white "
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <p className="w-1/2 text-gray-500 pl-5">BMI</p>
            <span className="w-1/2 text-center font-semibold text-green-700 text-sm">
              {infoBN?.cannang
                ? formatNumberVND(infoBN?.cannang / Math.pow(infoBN?.chieucao / 100, 2))
                : 0}
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default InfoBN
