// theo sau giá 3 số 0
export const formatTTTTChiDinh = (value) => {
  switch (value) {
    case 1:
      return ''
    case 2:
      return 'Đã thu'
    case 3:
      return ''
    default:
      return value
  }
}
