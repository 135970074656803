import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listPhanCong: null,
  infoPhanCong: null,
  listTrangThaiPhanCong: null,
}

const phanconglienheReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_PHANCONG_CRM:
        draft.listPhanCong = payload
        break
      case typeAction.DISPATCH_INFO_PHANCONG_CRM:
        draft.infoPhanCong = payload
        break
      case typeAction.DISPATCH_TRANGTHAI_PHANCONG_CRM:
        draft.listTrangThaiPhanCong = payload
        break
      default:
        return state
    }
  })
}

export default phanconglienheReducer
