import { Button } from "@mui/material";
import { Input, Modal } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCtyKhamDoanSchema } from "../../../../schemas/editCtyKhamDoanSchema";
import { editCtyCRM } from "../../../../store/actions/congtyCRMAction";

const ModalDetail = ({show,setShow}) => {
    const { infoCty } = useSelector((state) => state.congtyCRMReducer);
    const { infoUser } = useSelector((state) => state.userReducer)

    const dispatch = useDispatch();
    const handleCancel = () => {
        setShow(false)
    };
    const handleEditCtyKhamDoan = (value) => {
    //   dispatch(editCtyCRM(value, onLoad));
      handleCancel();
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        idct: infoCty?.idct,
        mact: infoCty?.mact,
        tenct: infoCty?.tenct,
        diachi: infoCty?.diachi,
        dienthoai: infoCty?.dienthoai,
        fax: infoCty?.fax,
        email: infoCty?.email,
        website: infoCty?.website,
        ghichu: infoCty?.ghichu,
        nguoisua: infoUser?.idnv,
      },
      // validationSchema: editCtyKhamDoanSchema,
      onSubmit: (value) => handleEditCtyKhamDoan(value),
    });
  return (
    <>
      <Modal
        footer={null}
        className="text-center"
        title="Chi tiết công ty "
        open={show}
        onCancel={handleCancel}
      >
        <form className="text-start">
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Tên công ty{" "}
              {infoCty ? `- Mã CT: ${infoCty.mact}` : ""}
            </label>
            <Input
              value={formik.values.tenct}
              onChange={formik.handleChange}
              name="tenct"
              status={formik.errors.tenct ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Địa chỉ
            </label>
            <Input
              value={formik.values.diachi}
              onChange={formik.handleChange}
              name="diachi"
              status={formik.errors.diachi ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">
             Điện thoại
            </label>
            <Input
              value={formik.values.dienthoai}
              onChange={formik.handleChange}
              name="dienthoai"
              status={formik.errors.dienthoai ? "error" : ""}
            />
            {formik.errors.dienthoai && (
              <span className="text-red-500">{formik.errors.dienthoai}</span>
            )}
          </div>
          <div>
            <label className="font-semibold">Fax</label>
            <Input
              value={formik.values.fax}
              onChange={formik.handleChange}
              name="fax"
              // status={formik.errors.fax ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">Email</label>
            <Input
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              // status={formik.errors.email ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">website</label>
            <Input
              value={formik.values.website}
              onChange={formik.handleChange}
              name="website"
              //   status={formik.errors.website ? "error" : ""}
            />
          </div>
          <div>
            <label className="font-semibold">Ghi Chú</label>
            <Input
              value={formik.values.ghichu}
              onChange={formik.handleChange}
              name="ghichu"
              //   status={formik.errors.website ? "error" : ""}
            />
          </div>
          <div className="flex flex-col gap-2 mt-2">
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              variant="contained"
              color="success"
              size="small"
            >
              Lưu
            </Button>
            <Button
              type="button"
              onClick={handleCancel}
              variant="outlined"
              size="small"
            >
              Huỷ bỏ
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalDetail