import { Button, Input, Select } from 'antd'
import React, { useState } from 'react'
import { PrinterOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import style from './style.module.css'
import { useSelector } from 'react-redux'
import { sieuAmService } from '../../../../services/SieuAm/sieuAmService'
import { generatePhieuSieuAm } from '../../../../utils/report/san/phieuSieuAm'

const Template = ({ formik, editorState, setEditorState }) => {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBNDienTim } = useSelector((state) => state.dienTimReducer)
  const onEditorStateChange = (newState) => {
    setEditorState(newState)
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="p-2 border-b flex justify-between items-center">
          <div>
            <Button
              type="primary"
              disabled={infoBNDienTim?.infoDienTim || !infoBNDienTim}
              onClick={formik.handleSubmit}
            >
              Lưu kết quả
            </Button>
          </div>
        </div>
        <div>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
              inline: {
                options: ['bold', 'italic'],
              },
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              },
              fontFamily: {
                options: ['Times New Roman'],
              },
            }}
            editorState={editorState}
            toolbarClassName={style.toolbarClassName}
            wrapperClassName={style.wrapperClassName}
            editorClassName={style.editorClassName}
            onEditorStateChange={infoBNDienTim?.infoDienTim ? null : onEditorStateChange}
          />
        </div>
      </div>

      <div className="p-2 border-t">
        <h2 className="font-semibold">KẾT LUẬN</h2>
        <Input.TextArea
          style={{
            height: 100,
            resize: 'none',
          }}
          name="KETLUAN"
          onChange={formik.handleChange}
          value={formik.values.KETLUAN}
          readOnly={infoBNDienTim?.infoDienTim}
        />
      </div>
    </div>
  )
}

export default Template
