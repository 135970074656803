import * as typeAction from '../constants/constants'

export const infoBNMonitor = (idbnttc) => ({
  type: typeAction.GET_INFO_BN_MONITOR,
  idbnttc,
})

export const addBNMonitor = (form, onLoad) => ({
  type: typeAction.ADD_BN_MONITOR,
  form,
  onLoad
})


export const getAllBNCho = (idcn) => ({
  type: typeAction.GET_ALL_BN_CHO_MONITOR,
  idcn,
})
