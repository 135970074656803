import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNhanVienDetail } from '../../../../store/actions/suCoAction'
const DeletStaff = ({ openDel, setOpenDel, staff }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [reason, setReason] = useState(null)
  const dispatch = useDispatch()
  const handlecancel = () => {
    setOpenDel(false)
  }
  const reset = () => {
    setOpenDel(false)
    setReason(null)
  }
  const handlDelete = () => {
    const form = {
      idnvbh: staff?.idnvbh,
      idbaohong: staff?.idbaohong,
      lydo: reason,
      nguoitao: infoUser?.idnv,
    }
    dispatch(deleteNhanVienDetail(form, reset))
  }

  return (
    <Modal
      onOk={handlDelete}
      okText="Xoá"
      cancelText="Huỷ"
      title={
        <>
          <ExclamationCircleFilled style={{ color: 'orange' }} /> Bạn có chắc muốn xoá người thực
          hiện ?
        </>
      }
      open={openDel}
      onCancel={handlecancel}
      okButtonProps={{
        disabled: !reason,
      }}
    >
      <p className="font-semibold mb-2">Kĩ thuật: {staff?.tennhanvien} </p>
      <Input placeholder="Nhập lý do" value={reason} onChange={(e) => setReason(e.target.value)} />
    </Modal>
  )
}

export default DeletStaff
