import * as typeAction from '../.../../constants/constants'

export const getListThuocGPP_Action = () => ({
  type: typeAction.GET_LIST_TYPES_THUOC_GPP,
})

export const getThuocVatTu_GiaGPPByID_Action = (id) => ({
  type: typeAction.GET_THUOC_GPP_BY_ID,
  id,
})
//############# LẤY DANH SÁCH THUỐC VẬT TƯ THEO KEYWORD ############
export const historyGPPThuocVT = (idThuoc) => ({
  type: typeAction.GET_HISTORY_GPP,
  idThuoc,
})
