import { DeleteOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Image, Skeleton, Table, Input, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { xetNghiemService } from '../../../../../../services/xetNghiem/xetNghiemService'
import { sieuAmService } from '../../../../../../services/SieuAm/sieuAmService'
import { infoBNSieuAm as infoBNSieuAmAction } from '../../../../../../store/actions/sieuAmAction'
import { noiSoiService } from '../../../../../../services/NoiSoi/noiSoiService'
// import { infoBNXetNghiem } from '../../../../../../store/actions/xetNghiemAction'
// import InfoBN from '../../../../SieuAm/InfoBN/InfoBN'
// import InfoPhieu from '../../../../SieuAm/InfoPhieu/InfoPhieu'
import { useFormik } from 'formik'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import Template from '../../../../SieuAm/Template/Template'
import moment from 'moment'
import { calculateAge } from '../../../../../../utils/calculateAge'
import Images from '../../../../SieuAm/ImageSA/Images'
import 'moment/locale/vi'
moment.locale('vi')
const FormKQCDHA = () => {
  const dispatch = useDispatch()
  const today = moment()
  const formatDateAPI = 'YYYY-MM-DD'
  const [dateForm, setDateForm] = useState(today.format(formatDateAPI))
  const [dateTo, setDateTo] = useState(today.format(formatDateAPI))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const { detailXN } = useSelector((state) => state.xetNghiemReducer)
  const [BNdone, setBnDone] = useState([])
  const [detailXN, setDetailXN] = useState([])
  const [image, setImage] = useState([])
  const [listImagePrint, setListImagePrint] = useState([])
  const [infoNhomCDHA, setInfoNhomCDHA] = useState(null)
  // ----------- lấy danh bệnh nhân đã thực hiên ------------//
  const fetchBnDone = async (idcn, dateform, dateto) => {
    try {
      const { data: dataSieuAm } = await sieuAmService.getBNDoneBtBranch(idcn, dateform, dateto)
      const { data: dataNoiSoi } = await noiSoiService.getBNDoneBtBranch(idcn, dateform, dateto)
      setBnDone((prev) => [
        ...prev,
        ...dataSieuAm?.map((item) => ({ ...item, nhomcdha: 1 })),
        ...dataNoiSoi?.map((item) => ({ ...item, nhomcdha: 2 })),
      ]) // nhomcdha: 1 là siêu âm - nhomcdha: 2 là nội soi
    } catch (error) {
      console.log(error)
    }
  }
  // --------- onClick chi tiết bệnh nhân xét nghiệm --------//
  const onClickXemDetailXN = async (record) => {
    try {
      const result = await xetNghiemService.getDetailXN(record.idbnttc)
      setDetailXN(result?.data?.DanhSachXetNghiem)
    } catch (error) {
      console.log(error)
    }
  }
  const onClickBn = (record) => {
    dispatch(infoBNSieuAmAction(record.idbnttc, record.idbncdha))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // IDBNTTC: infoBNSieuAm?.idbnttc,
      // // IDBM: 1,
      // NGAYSIEUAM: null,
      // BSCHIDINH: infoBNSieuAm?.bschidinh,
      // BSSIEUAM: infoUser?.tenNV,
      // CHANDOANSOBO: infoBNSieuAm?.chuanDoanSoBo || null,
      // LOAISIEUAM: infoBNSieuAm?.loaisieuam,
      // IDBNCDHA: infoBNSieuAm?.idbncdha,
      // BIEUMAU: null,
      // PARA: infoBNSieuAm?.para,
      // files: null,
      // KETLUAN: infoBNSieuAm?.ketluan,
    },
    // onSubmit: (values) => handleSubmit(values),
  })
  const columnsDetailXN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã CĐHA',
      dataIndex: 'machidinh',
      key: 'machidinh',
      width: 150,
    },
    {
      title: 'Tên CĐHA',
      dataIndex: 'tenchidinh',
      key: 'tenchidinh',
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 90,
    },
    {
      title: 'Kết quả',
      dataIndex: 'ketqua',
      key: 'ketqua',
      width: 200,
      align: 'center',
      // render: (text, record, index) => (
      //     <Input onChange={handleValue(record.ID)} value={text} min={0} type="number" size="small" />
      // ),
    },
    {
      title: 'Mẫu gửi',
      dataIndex: 'maugui',
      key: 'maugui',
      width: 150,
      align: 'center',
    },
  ]
  const columnsListXN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      key: 'mabenhnhan',
      width: 120,
      align: 'center',
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      width: 100,
      align: 'center',
      key: 'ngaysinh',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) => (
        <ul className="flex justify-center">
          <li
            onClick={() => onClickBn(record)}
            className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded"
          >
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
        </ul>
      ),
    },
  ]
  useEffect(() => {
    // fetchBranchByCompany()
    // fetchBNPendingXN()
    fetchBnDone(PkDangNhap?.idChiNhanh, dateForm, dateTo)
  }, [])
  useEffect(() => {
    if (infoBNSieuAm?.linkhinhanh) {
      const images = infoBNSieuAm.linkhinhanh
        .split(';')
        .map((item) => ({ url: item, checked: false }))
      setImage(images)
    } else {
      setImage([])
    }
    if (infoBNSieuAm?.bieumau) {
      const convertJson = JSON.parse(infoBNSieuAm?.bieumau)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else {
      setEditorState(null)
    }

    if (infoBNSieuAm) {
      setInfoNhomCDHA(BNdone?.find((item) => item?.idbncdha === infoBNSieuAm?.idbncdha)?.nhomcdha)
    } else {
      setInfoNhomCDHA(null)
    }
  }, [infoBNSieuAm, infoBNSieuAm?.linkhinhanh, infoBNSieuAm?.bieumau])
  return (
    <>
      <div className="w-full mt-5">
        <Divider
          orientation="left"
          style={{
            margin: '10px 0',
          }}
        >
          <span className="">Danh sách kết quả</span>
        </Divider>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            bordered
            columns={columnsListXN}
            pagination={false}
            dataSource={BNdone}
            scroll={{ y: 170 }}
          />
        </ConfigProvider>
      </div>
      <div className="w-full mt-10">
        <Divider
          orientation="left"
          style={{
            margin: '10px 0',
          }}
        >
          <span className="">Thông tin kết quả</span>
        </Divider>
        <div className="flex">
          <div className="w-1/2">
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Tên BN:</label>
              <Input value={infoBNSieuAm?.tenbenhnhan} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Mã BN:</label>
              <Input value={infoBNSieuAm?.mabenhnhan} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Ngày sinh:</label>
              <Input
                value={
                  infoBNSieuAm?.ngaysinh && moment(infoBNSieuAm?.ngaysinh).format('DD/MM/YYYY')
                }
                size="small"
                variant="filled"
                readOnly
              />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Giới tính:</label>
              <Input value={infoBNSieuAm?.gioitinh} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Tuổi :</label>
              <Input
                value={infoBNSieuAm?.ngaysinh ? calculateAge(infoBNSieuAm?.ngaysinh) : null}
                size="small"
                variant="filled"
                readOnly
              />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Địa chỉ :</label>
              <Input value={infoBNSieuAm?.diachi} size="small" variant="filled" readOnly />
            </div>
          </div>
          <div className="w-1/2 pl-4">
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">
                Ngày {(infoNhomCDHA === 1 && 'SA') || (infoNhomCDHA === 2 && 'N.Soi')}:
              </label>
              <Input
                value={
                  infoBNSieuAm?.ngaysieuam &&
                  moment(infoBNSieuAm?.ngaysieuam).format('DD/MM/YYYY HH:mm:ss')
                }
                size="small"
                variant="filled"
                readOnly
              />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">BS Chỉ định:</label>
              <Input value={infoBNSieuAm?.bschidinh} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">
                BS {(infoNhomCDHA === 1 && 'Siêu âm') || (infoNhomCDHA === 2 && 'Nội soi')}:
              </label>
              <Input value={infoBNSieuAm?.bssieuam} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">CĐ sơ bộ:</label>
              <Input value={infoBNSieuAm?.chandoansobo} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">
                Loại {(infoNhomCDHA === 1 && 'SA') || (infoNhomCDHA === 2 && 'N.Soi')}:
              </label>
              <Input value={infoBNSieuAm?.loaisieuam} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">Para:</label>
              <Input value={infoBNSieuAm?.para} size="small" variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mb-2">
          {image?.map((item) => (
            <div key={item.id} className="border rounded-md w-1/6 min-h-28 overflow-hidden  ">
              <div className="overflow-hidden border-b">
                <Image src={item.url} />
              </div>
              <div className=" flex justify-around items-center"></div>
            </div>
          ))}
        </div>
        <div className="border m-2">
          <Editor
            editorState={editorState}
            toolbar={{
              options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
              inline: {
                options: ['bold', 'italic'],
              },
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              },
              fontFamily: {
                options: ['Times New Roman'],
              },
            }}
            // toolbarClassName={style.toolbarClassName}
            // wrapperClassName={style.wrapperClassName}
            // editorClassName={style.editorClassName}
            // onEditorStateChange={onEditorStateChange}
          />
        </div>
        <div className="p-2">
          <h2 className="font-semibold">KẾT LUẬN</h2>
          <Input.TextArea
            style={{
              height: 120,
              resize: 'none',
            }}
            name="KETLUAN"
            // onChange={formik.handleChange}
            value={infoBNSieuAm?.ketluan}
          />
        </div>
        {/* <Template
                    formik={formik}
                    editorState={editorState}
                    setEditorState={setEditorState}
                /> */}
        {/* <ConfigProvider
                    theme={{
                        token: {
                            padding: 5,
                        },
                    }}
                >
                    <Table bordered columns={columnsDetailXN} pagination={false} dataSource={detailXN} />
                </ConfigProvider> */}
      </div>
    </>
  )
}

export default FormKQCDHA
