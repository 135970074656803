import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'

export const generatePhieuGuiChiDinhLayMau = (detailXN, PkDangNhap, benhNhanTTC) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width

  //logo
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', `PCD${detailXN.IDBNTTC}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `PCD${detailXN.IDBNTTC}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(11)
  doc.text(text, textX, 22)

  // Title
  doc.setFont('times', 'bold')
  doc.setFontSize(13)
  doc.text('PHIẾU CHỈ ĐỊNH', doc.internal.pageSize.width / 2, 30, { align: 'center' })

  // Patient Info
  doc.setFontSize(10)
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(detailXN.TENBENHNHAN, xPos + textWidthName, 42)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên: ', xPos, 42)
  doc.text(`Phái: ${benhNhanTTC.benhNhan.gioiTinh}`, xPos + 70, 42)
  doc.text(
    `Ngày sinh: ${moment(benhNhanTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`,
    pageWidth -
      doc.getTextDimensions(
        `Ngày sinh: ${moment(benhNhanTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`,
      ).w -
      10,
    42,
  )

  const chanDoanSoBo = `Chẩn đoán sơ bộ: ${benhNhanTTC.chandoanbandau ?? ''}`
  if (chanDoanSoBo.length > 70) {
    const wordsArray = splitLongWord(chanDoanSoBo, 70)
    doc.text(wordsArray[0], xPos, 47)
    doc.text(wordsArray[1], xPos, 52)
    yPos = 52
  } else {
    doc.text(chanDoanSoBo, xPos, 47)
    yPos = 47
  }
  yPos += 5
  // Table
  const headers = ['STT', 'Tên chỉ định']
  const data = detailXN.DanhSachXetNghiem.map((item, index) => [index + 1, item.tenchidinh])

  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      0: { cellWidth: 10, halign: 'center' },
      1: { cellWidth: 120, halign: 'left' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 9,
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff',
      textColor: '#000',
      fontStyle: 'bold',
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff',
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  // Footer
  doc.setFont('times', 'regular')
  doc.setFontSize(11)
  yPos = doc.autoTable.previous.finalY + 10

  const dateText =  `Ngày ${moment(new Date()).format('DD')} tháng ${moment(new Date()).format('MM')} năm ${moment(new Date()).format('YYYY')}`
  const doctorText = detailXN.BSCHIDINH ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.text('Bác sĩ chỉ định', pageWidth - 48, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  // Auto print
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
