import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

export function checkHetHanTTV(dateString) {
  const ngayKetThuc = moment(dateString, 'YYYY-MM-DD')
  const today = moment()
  const Years = today.diff(ngayKetThuc, 'years')
  const Months = today.diff(ngayKetThuc, 'months')
  const Days = today.diff(ngayKetThuc, 'days')
  if (Years > 0 || Months > 0 || Days > 0) return true
  return false
}
