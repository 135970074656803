import dayjs from 'dayjs'
import moment from 'moment/moment'
import { generateCheckbox } from '../algorithm'
import { calculateAgeForReport } from '../calculateAge'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../splitWordForReport'

// phieu kiem truoc tiem chung moi (A5)
// export const generatePhieuKiemTruocTiemChung = ({
//   infoBN,
//   infoBNTTC,
//   infoKSL,
//   tenVacXin,
//   PkDangNhap,
// }) => {
//   const doc = jsPDFCus('p', 'mm', 'a5')
//   let yPos = 10
//   let xPos = 8
//   let ruleWidth = 55 // for text of price is longer
//   const pageWidth = doc.internal.pageSize.width
//   const pageHeight = doc.internal.pageSize.height
//   doc.setFont('times', 'bold')
//   doc.setFontSize(11)
//   doc.text('HỆ THỐNG PHÒNG KHÁM', xPos, yPos)
//   doc.setFontSize(10)
//   doc.text('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', pageWidth - 85, yPos)
//   yPos += 5
//   doc.text('NHI ĐỒNG 315', xPos + 13, yPos)
//   doc.text('Độc lập - Tự do - Hạnh phúc', pageWidth - 70, yPos)
//   yPos += 8
//   doc.text(
//     'BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI ĐỐI TƯỢNG TỪ 1 THÁNG TUỔI TRỞ LÊN',
//     doc.internal.pageSize.width / 2,
//     yPos,
//     {
//       align: 'center',
//     },
//   )
//   yPos += 5
//   doc.text('TẠI CÁC CƠ SỞ TIÊM CHỦNG NGOÀI BỆNH VIỆN', doc.internal.pageSize.width / 2, yPos, {
//     align: 'center',
//   })
//   yPos += 8
//   doc.setFont('times', 'regular')
//   doc.text(`Họ tên trẻ: ${infoBN?.tenBenhNhan ?? ''}`, xPos, yPos)
//   doc.text('Nam', pageWidth - 40, yPos)
//   if (infoBN?.gioiTinh?.includes('Nam')) {
//     generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, true)
//   } else {
//     generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, false)
//   }
//   doc.setFontSize(11)
//   doc.text('Nữ', pageWidth - 25, yPos)
//   if (infoBN?.gioiTinh?.includes('Nữ')) {
//     generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, true)
//   } else {
//     generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, false)
//   }
//   doc.setFontSize(11)
//   yPos += 4
//   doc.text(
//     `Tuổi: ${infoBN?.ngaySinh && calculateAgeForReport(infoBN?.ngaySinh)}, sinh ngày ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('DD')} tháng ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('MM')} năm ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('YYYY')}`,
//     xPos,
//     yPos,
//   )
//   yPos += 4
//   //   const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
//   const diaChiBN = `Địa chỉ: ${infoBN?.diaChi + ', ' ?? ''}${infoBN?.tenPhuongXa + ', ' ?? ''}${infoBN?.tenQuanHuyen + ', ' ?? ''}${infoBN?.tenTinhTP + ', ' ?? ''}`
//   if (diaChiBN.length > 75) {
//     const wordsArray = splitLongWord(diaChiBN, 75)
//     for (let index = 0; index < wordsArray.length; index++) {
//       doc.text(wordsArray[index], xPos, yPos)
//       yPos += 5
//     }
//     yPos += 5
//   } else {
//     doc.text(diaChiBN, xPos, yPos)
//   }
//   yPos += 4
//   doc.text(`Họ tên bố/mẹ: ${infoBNTTC?.tennguoinha ?? ''}`, xPos, yPos)
//   doc.text(`Điện thoại: ${infoBNTTC?.dienthoainguoinha ?? ''}`, pageWidth - 60, yPos)
//   yPos += 4
//   doc.text(`Cân nặng: ${infoBNTTC?.cannang ?? '...'} kg`, xPos, yPos)
//   doc.text(`Nhiệt độ: ${infoBNTTC?.nhietdo ?? '...'} °C`, xPos + 35, yPos)
//   doc.text(`Mã tiêm chủng quốc gia: ${infoBN?.maTCQG ?? ''}`, pageWidth - 70, yPos)
//   yPos += 4
//   doc.setFont('times', 'bold')
//   doc.text('I. Khám sàng lọc', xPos, yPos)
//   doc.setFontSize(10)
//   doc.setFont('times', 'regular')
//   yPos += 5
//   //table
//   doc.line(xPos - 2, yPos, pageWidth - 3, yPos)
//   doc.text('1. Phản vệ độ III trở lên sau lần tiêm chủng trước', xPos, yPos + 3)
//   doc.text('(vắc xin có cùng thành phần)', xPos, yPos + 7)
//   doc.text('Không', xPos + 97, yPos + 5)
//   generateCheckbox(doc, xPos + 110, yPos + 1.5, 4, 4, infoKSL?.phanvedoiii === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 5)
//   generateCheckbox(doc, xPos + 130, yPos + 1.5, 4, 4, infoKSL?.phanvedoiii === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 8, pageWidth - 3, yPos + 8)
//   doc.text('2. Phản vệ độ II sau lần tiêm chủng trước', xPos, yPos + 11)
//   doc.text(' (vắc xin có cùng thành phần)', xPos, yPos + 15)
//   doc.text('Không', xPos + 97, yPos + 13)
//   generateCheckbox(doc, xPos + 110, yPos + 9.5, 4, 4, infoKSL?.phanvedoii === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 13)
//   generateCheckbox(doc, xPos + 130, yPos + 9.5, 4, 4, infoKSL?.phanvedoii === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 16, pageWidth - 3, yPos + 16)
//   doc.text('3. Đang mắc bệnh cấp tính hoặc bệnh mạn tính tiến triển*', xPos, yPos + 21)
//   doc.text('Không', xPos + 97, yPos + 21)
//   generateCheckbox(doc, xPos + 110, yPos + 17.5, 4, 4, infoKSL?.benhcaptinhmantinh === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 21)
//   generateCheckbox(doc, xPos + 130, yPos + 17.5, 4, 4, infoKSL?.benhcaptinhmantinh === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 24, pageWidth - 3, yPos + 24)
//   doc.text('4. Sốt/Hạ thân nhiệt: ', xPos, yPos + 27)
//   doc.text('(sốt: nhiệt độ ≥ 38°C; Hạ thân nhiệt: nhiệt độ ≤ 35,5°C)', xPos, yPos + 31)
//   doc.text('Không', xPos + 97, yPos + 29)
//   generateCheckbox(doc, xPos + 110, yPos + 25.5, 4, 4, infoKSL?.sothathannhiet === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 29)
//   generateCheckbox(doc, xPos + 130, yPos + 25.5, 4, 4, infoKSL?.sothathannhiet === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 32, pageWidth - 3, yPos + 32)
//   doc.text('5. Suy giảm miễn dịch chưa xác định mức độ hoặc mức độ nặng,', xPos, yPos + 35)
//   doc.text('có biểu hiện lâm sàng nghi nhiễm HIV.', xPos, yPos + 39)
//   doc.text('Không', xPos + 97, yPos + 37)
//   generateCheckbox(doc, xPos + 110, yPos + 33.5, 4, 4, infoKSL?.suygiammiendich === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 37)
//   generateCheckbox(doc, xPos + 130, yPos + 33.5, 4, 4, infoKSL?.suygiammiendich === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 40, pageWidth - 3, yPos + 40)
//   doc.text('6. Đang hoặc mới kết thúc đợt điều trị corticoid liều cao (tương ', xPos, yPos + 43)
//   doc.text('đương prednison >2mg/kg/ngày), hóa trị, xạ trị, gammaglobulin**', xPos, yPos + 47)
//   doc.text('Không', xPos + 97, yPos + 45)
//   generateCheckbox(doc, xPos + 110, yPos + 41.5, 4, 4, infoKSL?.corticoid === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 45)
//   generateCheckbox(doc, xPos + 130, yPos + 41.5, 4, 4, infoKSL?.corticoid === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 48, pageWidth - 3, yPos + 48)
//   doc.text('7. Phản ứng tăng dần sau các lần tiêm chủng trước', xPos, yPos + 51)
//   doc.text('(vắc xin có cùng thành phần).', xPos, yPos + 55)
//   doc.text('Không', xPos + 97, yPos + 53)
//   generateCheckbox(doc, xPos + 110, yPos + 49.5, 4, 4, infoKSL?.phanungtang === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 53)
//   generateCheckbox(doc, xPos + 130, yPos + 49.5, 4, 4, infoKSL?.phanungtang === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 56, pageWidth - 3, yPos + 56)
//   doc.text('8. Nghe tim bất thường', xPos, yPos + 61)
//   doc.text('Không', xPos + 97, yPos + 61)
//   generateCheckbox(doc, xPos + 110, yPos + 57.5, 4, 4, infoKSL?.timbatthuong === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 61)
//   generateCheckbox(doc, xPos + 130, yPos + 57.5, 4, 4, infoKSL?.timbatthuong === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 64, pageWidth - 3, yPos + 64)
//   doc.text('9. Nhịp thở, nghe phổi bất thường', xPos, yPos + 69)
//   doc.text('Không', xPos + 97, yPos + 69)
//   generateCheckbox(doc, xPos + 110, yPos + 65.5, 4, 4, infoKSL?.nhipthophoibatthuong === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 69)
//   generateCheckbox(doc, xPos + 130, yPos + 65.5, 4, 4, infoKSL?.nhipthophoibatthuong === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 72, pageWidth - 3, yPos + 72)
//   doc.text('10. Tri giác bất thường (li bì hoặc kích thích)', xPos, yPos + 77)
//   doc.text('Không', xPos + 97, yPos + 77)
//   generateCheckbox(doc, xPos + 110, yPos + 73.5, 4, 4, infoKSL?.trigiacbatthuong === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 77)
//   generateCheckbox(doc, xPos + 130, yPos + 73.5, 4, 4, infoKSL?.trigiacbatthuong === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 80, pageWidth - 3, yPos + 80)
//   doc.text('11. Cân nặng < 2 kg', xPos, yPos + 85)
//   doc.text('Không', xPos + 97, yPos + 85)
//   generateCheckbox(doc, xPos + 110, yPos + 81.5, 4, 4, infoKSL?.cannang === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 85)
//   generateCheckbox(doc, xPos + 130, yPos + 81.5, 4, 4, infoKSL?.cannang === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 88, pageWidth - 3, yPos + 88)
//   doc.text('12. Các chống chỉ định khác, nếu có ghi rõ: ', xPos, yPos + 91)
//   doc.text(
//     infoKSL?.ghichu && infoKSL?.khac === 1
//       ? infoKSL?.ghichu
//       : '...................................................................................................',
//     xPos,
//     yPos + 95,
//   )
//   doc.text(
//     '...................................................................................................',
//     xPos,
//     yPos + 100,
//   )
//   doc.text('Không', xPos + 97, yPos + 93)
//   generateCheckbox(doc, xPos + 110, yPos + 89.5, 4, 4, infoKSL?.khac === 0 ? true : false)
//   doc.text('Có', xPos + 120, yPos + 93)
//   generateCheckbox(doc, xPos + 130, yPos + 89.5, 4, 4, infoKSL?.khac === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 105, pageWidth - 3, yPos + 105)
//   doc.line(xPos - 2, yPos, xPos - 2, yPos + 105)
//   doc.line(xPos + 95, yPos, xPos + 95, yPos + 105)
//   doc.line(xPos + 118, yPos, xPos + 118, yPos + 105)
//   doc.line(pageWidth - 3, yPos, pageWidth - 3, yPos + 105)
//   yPos += 110
//   doc.setFont('times', 'italic')
//   doc.text(
//     '*Không hoãn tiêm vắc xin đối với trẻ có bệnh nhẹ (ho, sổ mũi, tiêu chảy mức độ nhẹ... ',
//     xPos,
//     yPos,
//   )
//   yPos += 5
//   doc.text('và không sốt), bú tốt, ăn uống tốt', xPos, yPos)
//   yPos += 5
//   doc.text(
//     '** Trừ kháng huyết thanh viêm gan B. Tiêu chuẩn này chỉ áp dụng với vắc xin sống giảm độc lực',
//     xPos,
//     yPos,
//   )

//   //page 2
//   doc.addPage()
//   yPos = 10
//   doc.setFont('times', 'bold')
//   doc.text('II. Kết luận', xPos, yPos)
//   yPos -= 5
//   doc.line(xPos - 2, yPos + 8, pageWidth - 3, yPos + 8)
//   doc.text('_Đủ điều kiện tiêm chủng ngay ', xPos, yPos + 12)
//   doc.setFont('times', 'regular')
//   doc.text(
//     ' (Tất cả đều',
//     xPos + doc.getTextDimensions('_Độ điều kiện tiếm chủng ngay').w + 2,
//     yPos + 13,
//   )
//   doc.setFont('times', 'bold')
//   doc.text(
//     'KHÔNG ',
//     xPos + doc.getTextDimensions('_Đủ điều kiện tiêm chủng ngay (Tất cả đều ').w,
//     yPos + 13,
//   )
//   doc.setFont('times', 'regular')
//   doc.text(
//     ' có điểm bất thường)',
//     xPos + doc.getTextDimensions('_ Đủ điều kiện tiêm chủng ngay ( Tất cả đều KHÔNG ').w + 2,
//     yPos + 13,
//   )
//   generateCheckbox(doc, xPos + 125, yPos + 9.5, 4, 4, infoKSL?.tiemchung === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 16, pageWidth - 3, yPos + 16)
//   doc.setFont('times', 'regular')
//   doc.text('Loại vắc xin tiêm chủng lần này:', xPos, yPos + 19)
//   doc.text(
//     tenVacXin ??
//       '....................................................................................................',
//     xPos,
//     yPos + 23,
//   )

//   doc.line(xPos - 2, yPos + 24, pageWidth - 3, yPos + 24)
//   doc.text('_Chống chỉ định tiêm chủng (Khi', xPos, yPos + 28)
//   doc.setFont('times', 'bold')
//   doc.text('CÓ', xPos + doc.getTextDimensions('_Chống chỉ định tiêm chủng (Khi ').w - 2, yPos + 28)
//   doc.setFont('times', 'regular')
//   doc.text(
//     'điểm bất thường tại mục 1, 12)',
//     xPos + doc.getTextDimensions('_Chống chỉ định tiêm chủng (Khi CÓ ').w + 2,
//     yPos + 28,
//   )
//   generateCheckbox(doc, xPos + 125, yPos + 24.5, 4, 4, infoKSL?.chongchidinhtiemchung === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 32, pageWidth - 3, yPos + 32)
//   doc.text('_Tạm hoãn tiêm chủng (Khi ', xPos, yPos + 36)
//   doc.setFont('times', 'bold')
//   doc.text('CÓ', xPos + doc.getTextDimensions('_Tạm hoãn tiêm chủng (Khi ').w - 3, yPos + 36)
//   doc.setFont('times', 'regular')
//   doc.text(
//     'bất kỳ một điểm bất thường tại các mục từ 2 đến 11)',
//     xPos + doc.getTextDimensions('_Tạm hoãn tiêm chủng (Khi CÓ ').w,
//     yPos + 36,
//   )
//   generateCheckbox(doc, xPos + 125, yPos + 32.5, 4, 4, infoKSL?.tamhoantiemchung === 1 ? true : false)

//   doc.line(xPos - 2, yPos + 40, pageWidth - 3, yPos + 40)

//   doc.line(pageWidth - 3, yPos + 8, pageWidth - 3, yPos + 40)
//   doc.line(xPos - 2, yPos + 8, xPos - 2, yPos + 40)

//   doc.line(xPos + 122, yPos + 8, xPos + 122, yPos + 16)
//   doc.line(xPos + 122, yPos + 24, xPos + 122, yPos + 40)

//   yPos += 40
//   doc.text('Đề nghị khám sàng lọc tại bệnh viện: ', xPos, yPos + 5)
//   doc.text('Không', xPos + 58, yPos + 5)
//   generateCheckbox(doc, xPos + 70, yPos + 1.5, 4, 4, infoKSL?.benhvien === 0 ? true : false)
//   doc.text('Có', xPos + 85, yPos + 5)
//   generateCheckbox(doc, xPos + 90.1, yPos + 1.5, 4, 4, infoKSL?.benhvien === 1 ? true : false)

//   yPos += 10
//   const lydo = `*Lý do: ${infoKSL?.lydo ?? ''}`
//   if (lydo.length > 75) {
//     const wordsArray = splitLongWord(lydo, 75)
//     for (let index = 0; index < wordsArray.length; index++) {
//       doc.text(wordsArray[index], xPos, yPos)
//       yPos += 5
//     }
//     yPos += 5
//   } else {
//     doc.text(lydo, xPos, yPos)
//   }
//   yPos += 5
//   const formattedDateTime = `${moment(new Date()).format('hh')} giờ ${moment(new Date()).format('mm')} , ngày ${moment(new Date()).format('DD')} tháng ${moment(new Date()).format('MM')} năm ${moment(new Date()).format('YYYY')}`
//   doc.text(pageWidth - 65, yPos, formattedDateTime)
//   yPos += 5
//   doc.text('Tôi đã được tư vấn và đồng ý tiêm chủng cho trẻ', xPos, yPos)
//   doc.text(
//     pageWidth - 5 - 'Bác sĩ  thực hiện sàng lọc'.length * 2,
//     yPos,
//     'Bác sĩ  thực hiện sàng lọc',
//   )
//   yPos += 5
//   doc.text('(Ký và ghi rõ họ tên)', xPos + 18, yPos)
//   doc.text('(Ký và ghi rõ họ tên)', pageWidth - 55, yPos)
//   yPos += 30
//   doc.text('Thời gian tiêm….......giờ…........phút', xPos, yPos)
//   doc.text('Tên điều dưỡng tiêm: ', pageWidth - 60, yPos)
//   yPos += 5
//   doc.text('Tình trạng sức khoẻ sau tiêm ≥ 30 phút tại cơ sở:', xPos, yPos)
//   yPos += 5
//   doc.text('Tổng trạng…...........................................................', xPos, yPos)
//   doc.text('Tình trạng tại vị trí tiêm:', pageWidth - 60, yPos)
//   yPos += 5
//   doc.text('Trẻ khoẻ, ra về: ', xPos, yPos)
//   doc.text('Có', xPos + 30, yPos)
//   doc.rect(xPos + 25, yPos - 3, 4, 4)
//   doc.setFontSize(18)
//   // doc.text(xPos + 25.1, yPos + 0.5, '\u25A0')
//   doc.setFontSize(10)
//   doc.text('Không, ghi rõ:', xPos + 45, yPos)
//   doc.rect(xPos + 40.1, yPos - 3, 4, 4)
//   doc.setFontSize(18)
//   // doc.text(xPos + 40.1, yPos + 0.5, '\u25A0')
//   doc.setFontSize(10)
//   doc.text('Thời gian về:…....giờ…......phút,', pageWidth - 60, yPos)
//   yPos += 5
//   doc.text('......................................................................', xPos, yPos)
//   doc.text('ngày:…..../…...../20……..', pageWidth - 60, yPos)
//   yPos += 5
//   doc.text('Đã được hướng dẫn theo dõi sau tiêm:', xPos, yPos)
//   doc.text('Ký và ghi rõ họ tên:', pageWidth - 60, yPos)

//   // doc.save('Phieu-thu.pdf')
//   // auto printer
//   const blob = doc.output('blob')
//   const url = URL.createObjectURL(blob)
//   const printWindow = window.open(url)
//   if (printWindow) {
//     printWindow.addEventListener(
//       'load',
//       function () {
//         printWindow.print()
//       },
//       true,
//     )
//   }
// }

// phieu kiem truoc tiem chung (tnet)

export const generatePhieuKiemTruocTiemChung = ({
  infoBN,
  infoBNTTC,
  infoKSL,
  tenVacXin,
  PkDangNhap,
}) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  doc.setFont('times', 'bold')
  doc.setFontSize(10)
  doc.text('HỆ THỐNG Y TẾ NHI ĐỒNG 315', xPos + 4, yPos)
  doc.setFontSize(9)
  doc.text('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', pageWidth - 75, yPos)
  yPos += 4
  doc.setFont('times', 'regular')
  doc.setFontSize(8)
  doc.text(PkDangNhap.diaChi ?? '', xPos, yPos)
  doc.text('Độc lập - Tự do - Hạnh phúc', pageWidth - 60, yPos)
  yPos += 4

  doc.text(
    `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
    xPos + 2,
    yPos,
  )
  yPos += 6
  doc.setFont('times', 'bold')
  doc.setFontSize(9)
  doc.text(
    'BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI ĐỐI TƯỢNG TỪ 1 THÁNG TUỔI TRỞ LÊN',
    doc.internal.pageSize.width / 2,
    yPos,
    {
      align: 'center',
    },
  )
  yPos += 4
  doc.text('TẠI CÁC CƠ SỞ TIÊM CHỦNG NGOÀI BỆNH VIỆN', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  yPos += 6
  doc.setFont('times', 'regular')
  doc.text(`Họ tên trẻ: ${infoBN?.tenBenhNhan ?? ''}`, xPos, yPos)
  doc.text('Nam', pageWidth - 40, yPos)
  if (infoBN?.gioiTinh?.includes('Nam')) {
    generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, false)
  }
  doc.setFontSize(9)
  doc.text('Nữ', pageWidth - 25, yPos)
  if (infoBN?.gioiTinh?.includes('Nữ')) {
    generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, false)
  }
  doc.setFontSize(9)
  yPos += 4
  doc.text(
    `Tuổi: ${infoBN?.ngaySinh && calculateAgeForReport(infoBN?.ngaySinh)}, sinh ngày ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('DD')} tháng ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('MM')} năm ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('YYYY')}`,
    xPos,
    yPos,
  )
  yPos += 4
  //   const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
  const diaChiBN = `Địa chỉ: ${infoBN?.diaChi + ', ' ?? ''}${infoBN?.tenPhuongXa + ', ' ?? ''}${infoBN?.tenQuanHuyen + ', ' ?? ''}${infoBN?.tenTinhTP + ', ' ?? ''}`
  if (diaChiBN.length > 80) {
    const wordsArray = splitLongWordFitWidth(doc, diaChiBN, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(diaChiBN, xPos, yPos)
    yPos += 4
  }
  doc.text(`Họ tên bố/mẹ: ${infoBNTTC?.tennguoinha ?? ''}`, xPos, yPos)
  doc.text(`Điện thoại: ${infoBNTTC?.dienthoainguoinha ?? ''}`, pageWidth - 60, yPos)
  yPos += 4
  doc.text(`Cân nặng: ${infoBNTTC?.cannang ?? '...'} kg`, xPos, yPos)
  doc.text(`Nhiệt độ: ${infoBNTTC?.nhietdo ?? '...'} °C`, xPos + 35, yPos)
  doc.text(`Mã tiêm chủng quốc gia: ${infoBN?.maTCQG ?? ''}`, pageWidth - 70, yPos)
  yPos += 2
  doc.setFont('times', 'bold')
  doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  doc.text('I. Khám sàng lọc', xPos, yPos + 4)
  doc.setFont('times', 'regular')
  doc.setFontSize(9)
  doc.text('Không', xPos + 108, yPos + 4)
  doc.text('Có', xPos + 124, yPos + 4)
  yPos += 5
  //table
  doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  doc.text(
    '1. Phản vệ độ III trở lên sau lần tiêm chủng trước (vắc xin có cùng thành phần)',
    xPos,
    yPos + 4,
  )
  generateCheckbox(doc, xPos + 111, yPos + 1, 3, 3, infoKSL?.phanvedoiii === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.phanvedoiii === 1 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  doc.text('2. Phản vệ độ II sau lần tiêm chủng trước (vắc xin có cùng thành phần)', xPos, yPos + 9)
  generateCheckbox(doc, xPos + 111, yPos + 6, 3, 3, infoKSL?.phanvedoii === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 6, 3, 3, infoKSL?.phanvedoii === 1 ? true : false)

  doc.line(xPos - 2, yPos + 10, pageWidth - 8, yPos + 10)
  doc.text('3. Đang mắc bệnh cấp tính hoặc bệnh mạn tính tiến triển*', xPos, yPos + 14)
  generateCheckbox(
    doc,
    xPos + 111,
    yPos + 11,
    3,
    3,
    infoKSL?.benhcaptinhmantinh === 0 ? true : false,
  )
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 11,
    3,
    3,
    infoKSL?.benhcaptinhmantinh === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 15, pageWidth - 8, yPos + 15)
  doc.text(
    '4. Sốt/Hạ thân nhiệt: (sốt: nhiệt độ ≥ 38°C; Hạ thân nhiệt: nhiệt độ ≤ 35,5°C)',
    xPos,
    yPos + 19,
  )
  generateCheckbox(doc, xPos + 111, yPos + 16, 3, 3, infoKSL?.sothathannhiet === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 16, 3, 3, infoKSL?.sothathannhiet === 1 ? true : false)

  doc.line(xPos - 2, yPos + 20, pageWidth - 8, yPos + 20)
  doc.text(
    '5. Suy giảm miễn dịch chưa xác định mức độ hoặc mức độ nặng, có biểu hiện lâm',
    xPos,
    yPos + 24,
  )
  doc.text(' sàng nghi nhiễm HIV.', xPos, yPos + 28)

  generateCheckbox(doc, xPos + 111, yPos + 22, 3, 3, infoKSL?.suygiammiendich === 0 ? true : false)
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 22,
    3,
    3,
    infoKSL?.suygiammiendich === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 30, pageWidth - 8, yPos + 30)
  doc.text(
    '6. Đang hoặc mới kết thúc đợt điều trị corticoid liều cao (tương đương ',
    xPos,
    yPos + 34,
  )
  doc.text('prednison >2mg/kg/ngày), hóa trị, xạ trị, gammaglobulin**', xPos, yPos + 38)

  generateCheckbox(doc, xPos + 111, yPos + 33, 3, 3, infoKSL?.corticoid === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 33, 3, 3, infoKSL?.corticoid === 1 ? true : false)

  doc.line(xPos - 2, yPos + 40, pageWidth - 8, yPos + 40)
  doc.text(
    '7. Phản ứng tăng dần sau các lần tiêm chủng trước (vắc xin có cùng thành phần).',
    xPos,
    yPos + 44,
  )
  generateCheckbox(doc, xPos + 111, yPos + 41, 3, 3, infoKSL?.phanungtang === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 41, 3, 3, infoKSL?.phanungtang === 1 ? true : false)

  doc.line(xPos - 2, yPos + 45, pageWidth - 8, yPos + 45)
  doc.text('8. Nghe tim bất thường', xPos, yPos + 49)
  generateCheckbox(doc, xPos + 111, yPos + 46, 3, 3, infoKSL?.timbatthuong === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 46, 3, 3, infoKSL?.timbatthuong === 1 ? true : false)

  doc.line(xPos - 2, yPos + 50, pageWidth - 8, yPos + 50)
  doc.text('9. Nhịp thở, nghe phổi bất thường', xPos, yPos + 54)
  generateCheckbox(
    doc,
    xPos + 111,
    yPos + 51,
    3,
    3,
    infoKSL?.nhipthophoibatthuong === 0 ? true : false,
  )
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 51,
    3,
    3,
    infoKSL?.nhipthophoibatthuong === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 55, pageWidth - 8, yPos + 55)
  doc.text('10. Tri giác bất thường (li bì hoặc kích thích)', xPos, yPos + 59)
  generateCheckbox(doc, xPos + 111, yPos + 56, 3, 3, infoKSL?.trigiacbatthuong === 0 ? true : false)
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 56,
    3,
    3,
    infoKSL?.trigiacbatthuong === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 60, pageWidth - 8, yPos + 60)
  doc.text('11. Cân nặng < 2 kg', xPos, yPos + 64)
  generateCheckbox(doc, xPos + 111, yPos + 61, 3, 3, infoKSL?.cannang === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 61, 3, 3, infoKSL?.cannang === 1 ? true : false)

  doc.line(xPos - 2, yPos + 65, pageWidth - 8, yPos + 65)
  doc.text('12. Các chống chỉ định khác, nếu có ghi rõ: ', xPos, yPos + 69)
  doc.text(infoKSL?.ghichu && infoKSL?.khac === 1 ? infoKSL?.ghichu : '', xPos, yPos + 73)
  generateCheckbox(doc, xPos + 111, yPos + 68, 3, 3, infoKSL?.khac === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 68, 3, 3, infoKSL?.khac === 1 ? true : false)

  doc.line(xPos - 2, yPos + 75, pageWidth - 8, yPos + 75)

  doc.line(xPos - 2, yPos - 5, xPos - 2, yPos + 75)
  doc.line(xPos + 105, yPos - 5, xPos + 105, yPos + 75)
  doc.line(xPos + 120, yPos - 5, xPos + 120, yPos + 75)
  doc.line(pageWidth - 8, yPos - 5, pageWidth - 8, yPos + 75)
  yPos += 78
  doc.setFontSize(8)
  doc.setFont('times', 'italic')
  doc.text(
    '*Không hoãn tiêm vắc xin đối với trẻ có bệnh nhẹ (ho, sổ mũi, tiêu chảy mức độ nhẹ... và không sốt), bú tốt, ăn uống tốt',
    xPos,
    yPos,
  )
  yPos += 3
  doc.text(
    '** Trừ kháng huyết thanh viêm gan B. Tiêu chuẩn này chỉ áp dụng với vắc xin sống giảm độc lực',
    xPos,
    yPos,
  )
  yPos += 3
  doc.setFont('times', 'bold')
  doc.text('II. Kết luận', xPos, yPos)
  yPos -= 7
  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  doc.text('- Đủ điều kiện tiêm chủng ngay (Tất cả đều KHÔNG có điểm bất thường)', xPos, yPos + 12)
  doc.setFont('times', 'regular')
  generateCheckbox(doc, xPos + 125, yPos + 9, 3, 3, infoKSL?.tiemchung === 1 ? true : false)

  doc.line(xPos - 2, yPos + 13, pageWidth - 8, yPos + 13)
  doc.setFont('times', 'regular')

  const loaiVacXin = `Loại vắc xin tiêm chủng lần này: ${tenVacXin ?? ''}`
  let tempYpos = yPos + 16
  if (doc.getTextWidth(loaiVacXin) > 120) {
    doc.setFontSize(7)
    const wordsArray = splitLongWordFitWidth(doc, loaiVacXin, pageWidth - 10)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(8)
  } else {
    tempYpos = tempYpos + 2
    doc.text(loaiVacXin, xPos, tempYpos)
  }

  doc.line(xPos - 2, yPos + 22, pageWidth - 8, yPos + 22)

  doc.text('- Chống chỉ định tiêm chủng (Khi CÓ điểm bất thường tại mục 1, 12)', xPos, yPos + 26)

  generateCheckbox(
    doc,
    xPos + 125,
    yPos + 23,
    3,
    3,
    infoKSL?.chongchidinhtiemchung === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 27, pageWidth - 8, yPos + 27)
  doc.text(
    '- Tạm hoãn tiêm chủng (Khi CÓ bất kỳ một điểm bất thường tại các mục từ 2 đến 11)',
    xPos,
    yPos + 31,
  )
  generateCheckbox(doc, xPos + 125, yPos + 28, 3, 3, infoKSL?.tamhoantiemchung === 1 ? true : false)

  doc.line(xPos - 2, yPos + 32, pageWidth - 8, yPos + 32)
  doc.line(pageWidth - 8, yPos + 8, pageWidth - 8, yPos + 32)
  doc.line(xPos - 2, yPos + 8, xPos - 2, yPos + 32)

  doc.line(xPos + 122, yPos + 8, xPos + 122, yPos + 13)
  doc.line(xPos + 122, yPos + 22, xPos + 122, yPos + 32)

  yPos += 32
  doc.text('Đề nghị khám sàng lọc tại bệnh viện: ', xPos, yPos + 4)
  doc.text('Không', xPos + 58, yPos + 4)
  generateCheckbox(doc, xPos + 70, yPos + 1.5, 3, 3, infoKSL?.benhvien === 0 ? true : false)
  doc.text('Có', xPos + 85, yPos + 4)
  generateCheckbox(doc, xPos + 90.1, yPos + 1.5, 3, 3, infoKSL?.benhvien === 1 ? true : false)
  yPos += 7
  const lydo = `*Lý do: ${infoKSL?.lydo ?? ''}`
  if (doc.getTextWidth(lydo) > 120) {
    const wordsArray = splitLongWordFitWidth(doc, lydo, pageWidth - 15)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 4
    }
  } else {
    doc.text(lydo, xPos, yPos)
  }
  yPos += 4
  doc.setFont('times', 'italic')
  const formattedDateTime = `Hồi ${moment(infoBN.ngayKham ?? new Date()).format('hh')} giờ ${moment(infoBN.ngayKham ?? new Date()).format('mm')} , ngày ${moment(infoBN.ngayKham ?? new Date()).format('DD')} tháng ${moment(infoBN.ngayKham ?? new Date()).format('MM')} năm ${moment(infoBN.ngayKham ?? new Date()).format('YYYY')}`
  doc.text(pageWidth - 65, yPos, formattedDateTime)
  doc.text(xPos + 2, yPos, formattedDateTime)
  yPos += 3
  doc.setFont('times', 'bold')
  doc.text('Tôi đã được tư vấn và đồng ý tiêm chủng cho trẻ', xPos, yPos)
  doc.text(
    pageWidth - 5 - 'Bác sĩ thực hiện sàng lọc'.length * 2,
    yPos,
    'Bác sĩ thực hiện sàng lọc',
  )
  const doctorText = infoKSL?.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(formattedDateTime)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15
  const dateCenterX = dateTextX + dateTextWidth / 2
  const doctorTextX = dateCenterX - doctorTextWidth / 2
  doc.text(doctorText, doctorTextX, yPos + 15)
  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
