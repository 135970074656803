import React, { useState } from 'react'
import CalendarHeader from './components/CalendarHeader'
import CalendarControls from './components/CalendarControls'
import CalendarGrid from './components/CalendarGrid'
import { startOfWeek } from 'date-fns'

export default function Lichlamviectheoca() {
  const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [days, setDays] = useState([])
  const [branchId, setBranchId] = useState(null) // Add state for branch ID
  const [positions, setPositions] = useState([])
  const [loadingGrid, setLoadingGrid] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState('') // Add state for selected position

  const handleBranchChange = (id) => {
    setBranchId(id)
  }

  const handleLoad = () => {
    setLoadingGrid(true)
  }
  const handlePositionChange = (id) => {
    setSelectedPosition(id) // Update selected position
  }

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
      <div className=" bg-white mb-4 border-dotted border-b-0  p-2 border rounded-sm">
        {/* <div className="bg-[#e6f4ff] border-[#91caff] border p-2 mb-2 rounded-sm text-center">
          <p className="mb-2">
            Bạn phải hoàn tất việc xếp lịch làm việc cho tuần sau trước ...
          </p>
          <p className="mb-2">
            Sau đó lịch làm việc sẽ được gửi tự động tới nhân viên và bạn không thay đổi được.
          </p>
          <p>
            Nếu muốn bổ sung lịch làm việc bạn chỉ có thể tạo ca bổ sung
          </p>
        </div> */}
        <CalendarHeader
          setDays={setDays}
          setCurrentWeek={setCurrentWeek}
          onBranchChange={handleBranchChange}
          positions={positions}
          onLoad={setLoadingGrid}
          setPositions={setPositions}
          selectedPosition={selectedPosition} // Pass selected position to CalendarHeader
          handlePositionChange={handlePositionChange} // Pass handlePositionChange to CalendarHeader
        />
      </div>
      <div className=" bg-white  border-dotted border-b-0  p-2 border rounded-sm min-h-[46rem]">
        <CalendarControls />
        <CalendarGrid
          currentWeek={currentWeek}
          days={days}
          branchId={branchId}
          onLoad={handleLoad}
          selectedPosition={selectedPosition}
        />
      </div>
    </div>
  )
}
