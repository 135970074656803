import { produce } from "immer";
import * as typeAction from "../constants/constants";
const initialState = {
  ListCĐHA: null,
};

const CĐHAReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState; // reset store khi logOut
      case typeAction.DISPATCH_LIST_ALL_CĐHA:
        draft.ListCĐHA = payload;
        break;
      default:
        return state;
    }
  });
};

export default CĐHAReducer;