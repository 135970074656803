import moment from "moment";
import { formatNumberVND } from "../../../../utils/formatNumberVND";

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 60,
    align:'center',
    render:(text,_,index) =>  ++index
    
  },
  {
    title: 'Ngày chứng từ',
    dataIndex: 'ngaythanhtoan',
    key: 'ngaythanhtoan',
    width: 150,
    render:(text) => text ? moment(text).format('DD/MM/YYYY HH:mm:ss'): '-'
    
  },
  {
    title: 'Số chứng từ',
    dataIndex: 'machungtu',
    key: 'machungtu',
    width: 130,
  },
  {
    title: 'Mã công cụ',
    dataIndex: 'mathe',
    key: 'mathe',
    width: 100,
  },
  {
    title: 'Mã bệnh nhân',
    dataIndex: 'mabenhnhan',
    key: 'mabenhnhan',
    width: 150,

  },
  {
    title: 'Tên công cụ',
    dataIndex: 'tenbenhnhan',
    key: 'tenbenhnhan',
    width: 200,

  },
  {
    title: 'Ngày tăng',
    dataIndex: 'ngayhieuluc',
    key: 'ngayhieuluc',
    width: 90,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
  },
  {
    title: 'Ngày tính phân bổ',
    dataIndex: 'ngayhieuluc',
    key: 'ngayhieuluc',
    width: 140,
     render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
  },
  {
    title: 'Ngày kết thúc pb',
    dataIndex: 'ngayketthuc',
    key: 'ngayketthuc',
    width: 130,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
  },
  {
    title: 'Số kỳ phân bổ',
    dataIndex: 'sothang',
    key: 'sothang',
    width: 120,
  },
  {
    title: 'ĐVT',
    dataIndex: 'dvt',
    key: 'dvt',
    width: 60,
    render:() => 'Tháng'
  },
  {
    title: 'Mã bộ phận',
    dataIndex: 'machinhanh',
    key: 'machinhanh',
    width: 100,
  },
  {
    title: 'Tên bộ phận',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    width: 250,
  },
  {
    title: 'Nguyên giá',
    dataIndex: 'tienthanhtoan',
    key: 'tienthanhtoan',
    width: 100,
    align:'right',
    render:(text) => formatNumberVND(text ?? 0)
  },
  {
    title: 'Gt phân bổ kỳ',
    dataIndex: 'giaphanbo',
    key: 'giaphanbo',
    width: 120,
    align:'right',
    render:(text) => formatNumberVND(text ?? 0)
  },
]
