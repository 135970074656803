import Button from '@mui/material/Button'
import { Input, Modal, Popconfirm, Select } from 'antd'
import { useFormik } from 'formik'
import moment from 'moment'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AttachmentIcon from '@mui/icons-material/Attachment'
import {
  deleteFileCuyenKho,
  getListKhoNhanAction,
  updateChuyenKho_Action,
  uploadFileCuyenKho,
} from '../../../../store/actions/chuyenKhoAction'
import PrintIcon from '@mui/icons-material/Print'
import IconButton from '@mui/material/IconButton'
import TableVT from './TableVT'
import { generatePhieuXuatKho } from '../../../../utils/report/phieuXuatKho'
import { branchService } from '../../../../services/branch/branchService'
import 'moment/locale/vi'
moment.locale('vi')
const ModalDetail = ({ open, cancelModal, statusUpdate }) => {
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const fileInputRef = useRef(null)
  const { detailThuocVT, detailPT, KhoNhan } = useSelector((state) => state.chuyenKhoReducer)
  const { defaultKhoNhap } = useSelector((state) => state.NhapKhoReducer)
  const [branch, setBranch] = useState([])
  const [idKhoNhan, setIdKhoNhan] = useState('')
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const handleSave = () => {
    setShow(false)
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  //----- UPLOAD FILE-------//
  const handleFileChange = (event) => {
    let file = event.target.files[0]
    const formData = new FormData()
    formData.append('IdNhapXuat', detailPT?.idNhapXuat)
    formData.append('HoaDonFiles', file)
    dispatch(uploadFileCuyenKho(detailPT?.idNhapXuat, formData))
  }
  const handleCloseModal = () => {
    cancelModal()
    setShow(false)
  }
  // lấy danh sách công ty
  const fetchBranch = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  // submit update
  const handleSubmitUpdate = (values) => {
    const fd = new FormData()
    for (let key in values) {
      fd.append(key, values[key])
    }
    handleSave()
    dispatch(updateChuyenKho_Action(fd, values.IdNhapXuat))
  }
  // XỬ LÝ CHỌN CHI NHÁNH NHẬN KHO TRONG CHUYỂN KHO
  const handleBranchNhanKho = (id) => {
    dispatch(getListKhoNhanAction(id))
    setIdKhoNhan('')
  }
  // CHỌN CHI NHÁNH NHẬN HÀNG HÓA
  const handleKhoNhan = (id) => {
    setIdKhoNhan(id)
    formik.setFieldValue('IdKhoNhap', id)
  }
  // update thông tin phiếu nhập xuất
  const handleUpdate = () => {
    setShow(true)
  }
  //-------delete file nhập kho ---------/
  const deleteFile = (url) => {
    if (detailPT?.nhanVienXuat === infoUser?.idnv) {
      const form = {
        idNhapXuat: detailPT?.idNhapXuat,
        hoaDonFiles: url,
      }
      dispatch(deleteFileCuyenKho(detailPT?.idNhapXuat, form))
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IdNhapXuat: detailPT?.idNhapXuat,
      NgayXuat: detailPT?.ngayXuat,
      MaPhieu: detailPT?.maPhieu,
      TenPhieu: detailPT?.tenPhieu,
      IdKhoNhap: detailPT?.idKhoNhap,
      IdKhoXuat: detailPT?.idKhoXuat,
      GhiChu: detailPT?.ghiChu,
      TrangThai: detailPT?.trangThai,
    },
    onSubmit: (values) => handleSubmitUpdate(values),
  })
  useEffect(() => {
    fetchBranch()
  }, [])
  return (
    <>
      <Modal
        className="text-center"
        title="Chi tiết chuyển kho"
        open={open}
        footer={null}
        onCancel={handleCloseModal}
        width={1400}
      >
        <div className="flex gap-5">
          <div className="w-2/3 text-start">
            <div className="flex items-center ">
              <h2 className="font-semibold text-base text-start mr-4">
                <span> {detailPT?.maPhieu}</span>
              </h2>
              <Popconfirm
                title="In phiếu chuyển kho"
                onConfirm={() => generatePhieuXuatKho(detailPT, detailThuocVT, PkDangNhap)}
                onCancel={() => generatePhieuXuatKho(detailPT, detailThuocVT, PkDangNhap, true)}
                okText="A4"
                cancelText="A5"
                icon={<PrintIcon color="primary" />}
                cancelButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
                okButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
                className="cursor-pointer"
              >
                <PrintIcon color="primary" />
              </Popconfirm>
            </div>
            <TableVT detailThuocVT={detailThuocVT} statusUpdate={statusUpdate} />
          </div>
          <form
            className="text-start w-1/3 flex flex-col gap-2 border p-2 rounded-lg min-h-[600px]"
            onSubmitCapture={formik.handleSubmit}
          >
            <div className="flex justify-between items-center">
              <h2 className="font-semibold text-lg text-blue-500 ">Thông tin phiếu</h2>
              {statusUpdate && (
                <div className="text-end ">
                  {show ? (
                    <>
                      {' '}
                      <Button variant="outlined" size="small" onClick={handleSave}>
                        Huỷ
                      </Button>{' '}
                      <Button
                        type="submit"
                        // onClick={handleSave}
                        variant="contained"
                        size="small"
                      >
                        Lưu
                      </Button>
                    </>
                  ) : (
                    <Button
                      disabled={detailPT?.nhanVienXuat !== infoUser?.idnv}
                      onClick={handleUpdate}
                      size="small"
                      variant="text"
                    >
                      <FormOutlined style={{ fontSize: 20 }} />
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="flex">
              <label className="font-semibold w-1/3">Trạng thái:</label>
              <p className="bg-green-700  text-white px-2 rounded-md  text-start">
                {detailPT?.tenTrangThai}
              </p>
            </div>
            <div className="flex">
              <label className="font-semibold  w-1/3">Ngày chuyển:</label>
              <p>{moment(formik.values.NgayXuat).format('DD/MM/YYYY hh:mm:ss')}</p>
            </div>
            <div className="flex">
              <label className="font-semibold  w-1/3">Người chuyển:</label>
              <p>{detailPT?.tenNVXuat}</p>
            </div>
            <div className="flex">
              <label className="font-semibold  w-1/3">Chi nhánh chuyển:</label>
              <p>{detailPT?.tenChiNhanhXuat}</p>
            </div>
            <div className="flex">
              <label className="font-semibold  w-1/3"> Kho chuyển:</label>
              <p>{detailPT?.tenKhoXuat}</p>
            </div>
            {detailPT?.tenNVNhan && (
              <div className="flex">
                <label className="font-semibold  w-1/3"> Người nhận:</label>
                <p>{detailPT?.tenNVNhan}</p>
              </div>
            )}

            {detailPT?.ngayNhan && (
              <div className="flex">
                <label className="font-semibold  w-1/3"> Ngày nhận:</label>
                <p>{moment(detailPT?.ngayNhan).format('DD/MM/YYYY HH:mm:ss')}</p>
              </div>
            )}

            <div className="flex">
              <label className="font-semibold  w-1/3">Chi nhánh nhận:</label>
              {show ? (
                <Select
                  onChange={handleBranchNhanKho}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  size="small"
                  className="w-2/3"
                  options={branch?.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))}
                />
              ) : (
                <p>{detailPT?.tenChiNhanhNhan}</p>
              )}
            </div>
            <div className="flex">
              <label className="font-semibold  w-1/3"> Kho nhận:</label>
              {show ? (
                <Select
                  onChange={handleKhoNhan}
                  size="small"
                  className="w-2/3"
                  value={idKhoNhan}
                  options={KhoNhan?.map((items) => ({
                    label: items.tenKho,
                    value: items.idKhoCN,
                  }))}
                />
              ) : (
                <p>{detailPT?.tenKhoNhap}</p>
              )}
            </div>
            <div className="flex">
              <label className="font-semibold  w-1/3">Tên phiếu:</label>
              {show ? (
                <Input
                  name="TenPhieu"
                  onChange={formik.handleChange}
                  className="w-2/3"
                  size="small"
                  value={formik.values.TenPhieu}
                />
              ) : (
                <p>{detailPT?.tenPhieu}</p>
              )}
            </div>
            <div className="flex">
              <label className="font-semibold w-1/3">Ghi chú</label>
              <Input.TextArea
                readOnly={!show}
                variant={show ? 'outlined' : 'borderless'}
                style={{ height: 120, resize: 'none' }}
                name="GhiChu"
                onChange={formik.handleChange}
                value={show ? formik.values.GhiChu : detailPT?.ghiChu}
                size="small"
                className="w-2/3"
              />
            </div>
            <div>
              <div className="mb-2 flex justify-end mr-2">
                <input
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  disabled={detailPT?.nhanVienXuat !== infoUser?.idnv}
                  onClick={handleButtonClick}
                  size="small"
                  variant="outlined"
                  startIcon={<AttachmentIcon />}
                >
                  Đính kèm
                </Button>
              </div>
              <ul className="flex gap-2 mb-2 flex-wrap">
                {detailPT?.fileHoaDon?.split(';').map((url, index) => {
                  const isImage =
                    url.endsWith('.jpg') ||
                    url.endsWith('.jpeg') ||
                    url.endsWith('.png') ||
                    url.endsWith('.gif') ||
                    url.endsWith('.bmp')
                  return (
                    <li key={index} className="relative group ">
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {isImage ? (
                          <img
                            src={url}
                            alt={`Image ${index + 1}`}
                            className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                          />
                        ) : (
                          <img
                            src="https://www.svgrepo.com/download/144578/pdf.svg"
                            alt={`PDF ${index + 1}`}
                            className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                          />
                        )}
                      </a>
                      <div
                        disabled
                        onClick={() => deleteFile(url)}
                        className={`absolute top-0 left-0 bg-white hover:bg-slate-200 p-1 transition duration-300 group-hover:scale-125 rounded  ${detailPT?.nhanVienXuat === infoUser?.idnv ? 'text-red-500 ' : 'text-gray-400'}  cursor-pointer`}
                      >
                        <DeleteOutlined style={{ fontSize: 18 }} />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default ModalDetail
