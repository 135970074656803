import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import {
  DatePicker,
  Select,
  ConfigProvider,
  Table,
  Breadcrumb,
  Pagination,
  Button,
  Typography,
  TreeSelect,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { FormOutlined, HomeOutlined, AuditOutlined } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import { Link, useNavigate } from 'react-router-dom'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { reportService } from '../../../../../services/Report/reportService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { branchService } from '../../../../../services/branch/branchService'
import { https } from '../../../../../services/apiService'
import 'moment/locale/vi'
import { debounce } from 'lodash'
import { formatNumber } from 'devextreme/localization'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

const Detail = ({ ChuyenKhoa }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([BranchLogin])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [khamBenh, setKhamBenh] = useState(0)
  const [sucKhoeTC, setSucKhoeTC] = useState(0)
  const [sucKhoe, setSucKhoe] = useState(0)
  const [toaThuoc, setToaThuoc] = useState(0)
  const [sanLoc, setSanLoc] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = (page) => {
    fetchReport(page)
  }
  //-------- export excel-----------//
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Lợi nhuận chi tiết.xlsx')
  }

  // ------------ lấy danh sách report theo chi nhánh ------------//
  //ThongKeLuotKham/LuotKhamChiTietByCondition?idCongTy=1&idChiNhanh=11&fromDate=11&toDate=11
  const fetchReport = async (page) => {
    setIsLoading(true)
    try {
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/LuotKhamChiTietByCondition?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}${idChiNhanhs}&pageNumber=${page}`,
        )
        setTotalPages(data.totalPage)
        setData((prev) => [...prev, ...data.data])
        setKhamBenh(data.tongPhiKhamBenh)
        setSucKhoeTC(data.tongTiemChung)
        setSucKhoe(data.tongKhamSucKhoe)
        setToaThuoc(data.tongSucKhoeToaThuoc)
        setSanLoc(data.tongSanLocTiemChung)
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/LuotKhamChiTietByCondition?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}&pageNumber=${page}`,
        )
        setData((prev) => [...prev, ...data.data])
        setTotalPages(data.totalPage)
        setKhamBenh(data.tongPhiKhamBenh)
        setSucKhoeTC(data.tongTiemChung)
        setSucKhoe(data.tongKhamSucKhoe)
        setToaThuoc(data.tongSucKhoeToaThuoc)
        setSanLoc(data.tongSanLocTiemChung)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa('')
  }, [])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    fetchReport(page)
  }, [page])

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      const tableBody = document.querySelector('.table-luotkham-chitiet .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 50 &&
        !isLoading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [isLoading],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-luotkham-chitiet .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-64 flex gap-1">
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className="w-64 flex gap-1">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton
              onClick={() => {
                //reset page
                setPage(1)
                //reset data
                setData([])
                onLoad(1)
              }}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              loading={loadingExport}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: '66vh',
            }}
            className="table-luotkham-chitiet"
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                fixed: 'left',
                width: 120,
              },
              {
                title: 'Tên bệnh nhân',
                dataIndex: 'tenbn',
                key: 'tenbn',
                fixed: 'left',
                width: 250,
              },
              {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                width: 100,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              },
              {
                title: 'Giới tính',
                key: 'gioitinh',
                dataIndex: 'gioitinh',
                width: 80,
                align: 'center',
              },
              {
                title: 'Đối tượng',
                dataIndex: 'tendoituong',
                key: 'tendoituong',
                width: 150,
                align: 'center',
              },
              {
                title: 'Địa chỉ',
                dataIndex: 'diachi',
                key: 'diachi',
                width: 500,
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenchinhanh',
                key: 'tenchinhanh',
                width: 250,
              },
              {
                title: 'Phí khám bệnh',
                dataIndex: 'phikhambenh',
                key: 'phikhambenh',
                width: 100,
                align: 'center',
              },
              {
                title: 'Khám sức khoẻ tiêm chủng',
                dataIndex: 'khamsuckhoetiemchung',
                key: 'khamsuckhoetiemchung',
                width: 100,
                align: 'center',
              },
              {
                title: 'Khám Sức khoẻ',
                dataIndex: 'khamsuckhoe',
                key: 'khamsuckhoe',
                width: 100,
                align: 'center',
              },
              {
                title: 'Sức khoẻ có toa thuốc',
                dataIndex: 'khamsuckhoecotoathuoc',
                key: 'khamsuckhoecotoathuoc',
                width: 100,
                align: 'center',
              },
              {
                title: 'Tư vấn khám sàn lọc có tiêm chủng',
                dataIndex: 'tuvankhamsangloctiemchung',
                key: 'tuvankhamsangloctiemchung',
                width: 100,
                align: 'center',
              },
            ]}
            summary={(pageData) => {
              const cells = Array.from({ length: 13 }, (_, index) => (
                <Table.Summary.Cell key={index} align="center" index={index}>
                  <p className="font-semibold">
                    {index === 8
                      ? formatNumber(khamBenh)
                      : index === 9
                        ? formatNumber(sucKhoeTC)
                        : index === 10
                          ? formatNumber(sucKhoe)
                          : index === 11
                            ? formatNumber(toaThuoc)
                            : index === 12
                              ? formatNumber(sanLoc)
                              : null}
                  </p>
                </Table.Summary.Cell>
              ))
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>{cells}</Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default Detail
