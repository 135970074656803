import { Tabs } from 'antd'
import React, { useState, useEffect } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import * as TypeAction from '../../../store/constants/constants'
import Receive from '../Receive/Receive'
import InfoPhieu from './InfoPhieu/InfoPhieu'
import ListPT from './ListPT/ListPT'
import TabDangKyKham from './TabDangKyKham/TabDangKyKham'
import TabVoucher from './TabVoucher/TabVoucher'
import { useDispatch } from 'react-redux'
import { BNPendingByBranch } from '../../../store/actions/receiveAction'
import { cashierService } from '../../../services/cashier/cashierService'
const STATUS_KHAM = 1
const Cashier = () => {
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const [activeTabs, setActiveTabs] = useState('1')
  const [BNChuaThu, setBNChuaThu] = useState([])
  const [BnDkkOnline, setBnDkkOnline] = useState(null)
  const dispatch = useDispatch()
  const onChange = (value) => {
    setActiveTabs(value)
  }
  const fecthBNchuaThu = async () => {
    try {
      const result = await cashierService.getBNChuaThu(ID_BRANCH_LOGIN)
      setBNChuaThu(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let intervalId
    if (activeTabs == '2') {
      dispatch(BNPendingByBranch(STATUS_KHAM, ID_BRANCH_LOGIN))
      fecthBNchuaThu()
      intervalId = setInterval(() => {
        dispatch(BNPendingByBranch(STATUS_KHAM, ID_BRANCH_LOGIN))
        fecthBNchuaThu()
      }, 3000)
    } else if (activeTabs == '3') {
      dispatch({
        type: TypeAction.RESET_PHIEUTHU_CASHIER,
      })
    } else if (activeTabs == '4') {
      setBnDkkOnline(null)
    }
    // Cleanup interval when component unmounts or activeTab changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [activeTabs, dispatch, ID_BRANCH_LOGIN])
  return (
    <>
      <div className="p-2  bg-[#E8E8E8] h-full">
        <div
          className="bg-white rounded-md border p-2 h-full"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <Tabs activeKey={activeTabs} onChange={onChange} className="h-full">
            <Tabs.TabPane tab="Tiếp nhận" key="1">
              <Receive BnDkkOnline={BnDkkOnline} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Thu ngân" key="2">
              <InfoPhieu BNChuaThu={BNChuaThu} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Phiếu thu" key="3">
              <ListPT />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Đăng ký khám online" key="4">
              <TabDangKyKham
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
                BnDkkOnline={BnDkkOnline}
                setBnDkkOnline={setBnDkkOnline}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Voucher" key="5">
              <TabVoucher />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default Cashier
