import {
  CloseOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, ConfigProvider, Input, Modal, Select, Space, Table, Tooltip } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  ListDichVuAction,
  deleteDichVuAction,
  getAllDichVu,
  getInfoDichVuAction,
  listGroupsDichVuAction,
  searchDanhMucDVAction,
} from '../../../store/actions/dichVuAction'
import ToastCus from '../../../utils/Toast'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalAdd from './Modal/ModalAdd'
import ModalCreate from './Modal/ModalCreate'
import ModalEdit from './Modal/ModalEdit'
// import ModalChangePriceCN from './Modal/ModalChangePriceCN'
// import ModalEdit from './Modal/ModalEdit'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const PhongKham = () => {
  const now = moment()
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [listBranch, setListBranch] = useState([])
  const [branch, setBranch] = useState('all')
  const [loading, setLoading] = useState(false)

  const [rowActive, setRowActive] = useState(null)
  const [listPhongKham, setListPhongKham] = useState([])
  const [listBranchUse, setListBranchUse] = useState([])

  const { ListDichVu, groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const [loadingTable, setLoadingTable] = useState(false)
  const searchInput = useRef(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalCreate, setIsModalCreate] = useState(false)
  const [isModalEdit, setIsModalEdit] = useState(false)

  const [searchGroup, setSearchGroup] = useState(1)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  // const [listBranch, setListBranch] = useState(null)
  const [loadingCN, setLoadingCN] = useState(false)
  const [showEditPriceCN, setShowEditPriceCN] = useState(false)

  const handleDeleteCNPK = async (idcn, idpk) => {
    try {
      await https.delete(`DeleteChiNhanhPK`, { params: { idcn, idpk } })
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      getListBranchUse(rowActive?.idPK, company)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  const showConfirm = async (record) => {
    confirm({
      title: 'Xóa chi nhánh phòng khám',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xóa ${rowActive?.tenPK ?? ''} của chi nhánh ${record?.tenchinhanh ?? ''}`,
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        handleDeleteCNPK(record?.idchinhanh, record?.idpk)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const getListPhongKham = async () => {
    setLoading(true)
    try {
      const { data } = await https.get(`PhongKham`)
      //format to show list branch
      setListPhongKham(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const getListBranchUse = async (idpk, idct) => {
    setLoadingCN(true)
    try {
      const { data } = await https.get(`GetChiNhanhPKByIDPK`, { params: { idpk, idct } })
      setListBranchUse(data)
      // // Update listBranch based on fetched datacompany    company
      // const updatedListBranch = listBranch.map((item) => {
      //   const found = data.find((val) => val.idchinhanh === item.idchinhanh)
      //   return found ? { ...item, ...found } : { ...item, sudung: 0 }
      // })
      // updatedListBranch.sort((a, b) => b.sudung - a.sudung)
      // // setListBranch(updatedListBranch)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi lấy thông tin dịch vụ chi nhánh',
      })
      setRowActive(null)
      //setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    } finally {
      setLoadingCN(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  // const handleCancelEdit = () => {
  //   setIsModalCreate(false)
  // }
  // const handleShowModalEdit = (item) => {
  //   setIsModalCreate(true)
  //   // dispatch(getInfoDichVuAction(item.iddv))
  // }
  const onLoad = () => {
    dispatch(ListDichVuAction(searchGroup, company))
  }
  // handle delete danh mục xét nghiệm
  const showDeleteConfirm = (iddv, tendv) => {
    confirm({
      title: 'Bạn có muốn xóa loại dịch vụ ?',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có chắc muốn xóa loại dịch vụ ${tendv}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteDichVuAction(iddv, onLoad))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setIsModalCreate(false)
    setIsModalEdit(false)
  }

  useEffect(() => {
    // dispatch(ListDichVuAction(searchGroup, company))
    // dispatch(listGroupsDichVuAction())
    dispatch(listAllCompanyAction())
  }, [])

  const handleInputSelect = (name, value) => {
    if (name === 'ct') setCompany(value)
    else if (name === 'cn') setBranch(value)
    // setRowActive(null)
    // setCompany(value)
    // // setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    // dispatch(ListDichVuAction(searchGroup, value))
  }

  const handleSearch = useCallback(
    debounce((keyword, company, searchGroup) => {
      //search theo tất cả
      dispatch(searchDanhMucDVAction(searchGroup, company, keyword))
    }, 300),
    [],
  )
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã PK',
      dataIndex: 'maPK',
      key: 'maPK',
      width: 120,
    },
    {
      title: 'Tên PK',
      dataIndex: 'tenPK',
      key: 'tenPK',
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoiSua',
      key: 'nguoiSua',
      width: 220,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaySua',
      key: 'ngaySua',
      width: 120,
      align: 'center',
      render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : '-'),
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoiTao',
      key: 'nguoiTao',
      width: 220,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngayTao',
      key: 'ngayTao',
      width: 120,
      align: 'center',
      render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : '-'),
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'tenChuyenKhoa',
      key: 'tenChuyenKhoa',
      width: 100,
      align: 'center',
      render: (text, record, index) =>
        text?.replace('Chuyên khoa', '').replace('Tim mạch tiểu đường', 'TMTĐ')?.trim(),
      fixed: 'right',
    },
    // {
    //   title: 'Sử dụng',
    //   dataIndex: 'suDung',
    //   key: 'suDung',
    //   width: 70,
    //   align: 'center',
    //   fixed: 'right',
    //   render: (text, record, index) => <Checkbox checked={record?.suDung === 1} />, //        text === 1 ? <Checkbox checked /> : <Checkbox disabled />,
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <ul className="flex justify-around">
            <li>
              <Tooltip title="Chỉnh sửa" color="green">
                <EditOutlined
                  onClick={() => setIsModalEdit(true)}
                  className="text-xl text-green-500  cursor-pointer"
                />
              </Tooltip>
            </li>
            {/* <li>
              <Tooltip title="Xoá" color="red">
                <DeleteOutlined
                  // onClick={(e) => {
                  //   e.stopPropagation()
                  //   showDeleteConfirm(item.iddv, item.tenDichVu)
                  // }}
                  className="text-xl text-red-500  cursor-pointer"
                />
              </Tooltip>
            </li> */}
          </ul>
        </>
      ),
    },
  ]

  const onChange = (value) => {
    setSearchGroup(value)
    setIdChuyenKhoa(groupsDichVu.find((item) => item.idNhom === value).idChuyenKhoa)
    dispatch(ListDichVuAction(value, company))
  }

  const handleSearchCN = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleResetCN = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchCN(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchCN(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleResetCN(clearFilters)
              handleSearchCN('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleCheckBox = (e, record) => {
    const { checked } = e.target
    const updatedListBranch = listBranchUse.map((item) =>
      item.idchinhanh === record.idchinhanh ? { ...item, sudung: checked ? 1 : 0 } : item,
    )
    setListBranchUse(updatedListBranch)
  }

  // const getListBranchUse = async (iddv) => {
  //   setLoadingCN(true)
  //   try {
  //     const { data } = await https.get(
  //       `DichVuChiNhanh/GetChiNhanhByIDDV?iddv=${iddv}&idct=${company}&idck=${idChuyenKhoa}`,
  //     )
  //     // Update listBranch based on fetched data
  //     const updatedListBranch = listBranch.map((item) => {
  //       const found = data.find((val) => val.idchinhanh === item.idchinhanh)
  //       return found ? { ...item, ...found } : { ...item, sudung: 0 }
  //     })
  //     updatedListBranch.sort((a, b) => b.sudung - a.sudung)
  //     // setListBranch(updatedListBranch)
  //   } catch (error) {
  //     console.log(error)
  //     ToastCus.fire({
  //       icon: 'error',
  //       title: 'Có lỗi xảy ra khi lấy thông tin dịch vụ chi nhánh',
  //     })
  //     setRowActive(null)
  //     //setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
  //   } finally {
  //     setLoadingCN(false)
  //   }
  // }

  const handleClickRow = (row) => {
    setRowActive(row)
    getListBranchUse(row?.idPK, company)
  }
  const fetchAllChiNhanhByCT = async (idct) => {
    setLoading(true)
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT`, { params: { idct } })
      //format to show list branch
      setListBranch(data)
      setBranch('all')
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // const getAllCnByIdct = async (idct, idchuyenkhoa) => {
  //   setLoadingCN(true)
  //   try {
  //     const { data } = await https.get(
  //       `ChiNhanh/GetChiNhanhByCondition?idct=${idct}&idChuyenKhoa=${idchuyenkhoa}`,
  //     )
  //     //format to show list branch
  //     const list = data.map((item) => {
  //       return {
  //         idchinhanh: item.idchinhanh,
  //         tenchinhanh: item.tenchinhanh,
  //         sudung: 0,
  //         dongia: 0,
  //         tienguimau: 0,
  //         tienchietkhau: 0,
  //       }
  //     })
  //     setListBranch(list)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setLoadingCN(false)
  //   }
  // }

  useEffect(() => {
    if (rowActive) getListBranchUse(rowActive?.idPK, company)
  }, [company])

  // useEffect(() => {
  //   getAllCnByIdct(company, idChuyenKhoa)
  // }, [idChuyenKhoa])

  useEffect(() => {
    getListPhongKham()
  }, [])

  const handleUpdate = async () => {
    setLoadingCN(true)
    try {
      await https.put(
        'UpdateSuDungChiNhanhPhongKham',
        listBranchUse.map((item) => ({
          suDung: item.sudung,
          idCN: item.idchinhanh,
          id: rowActive.idPK, //item.idcnpk, //rowActive.idPK,
        })),
      )
      ToastCus.fire({
        icon: 'success',
        text: 'Cập nhật thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: 'Cập nhật thất bại!',
      })
    } finally {
      setLoadingCN(false)
    }
  }

  const handleSelect = (e) => {
    setListBranchUse((prev) => prev.map((item) => ({ ...item, sudung: e.target.checked ? 1 : 0 })))
    // if () {
    // } else {
    //   setListBranchUse((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    // }
  }

  const columnsCN = [
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...getColumnSearchProps('tenchinhanh'),
    },
    // {
    //   title: (
    //     <div className="flex justify-center flex-col items-center">
    //       <p>Sử dụng</p>
    //       <Checkbox
    //         checked={listBranchUse?.every((item) => item.sudung === 1)}
    //         onChange={handleSelect}
    //         disabled={!rowActive || loadingCN}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'sudung',
    //   width: 90,
    //   align: 'center',
    //   key: 'sudung',
    //   render: (text, record, index) => (
    //     <Checkbox
    //       disabled={!rowActive}
    //       checked={record.sudung === 1}
    //       onChange={(e) => {
    //         handleCheckBox(e, record)
    //       }}
    //     />
    //   ),
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      render: (text, record, index) => (
        <ul className="flex justify-around">
          {rowActive && (
            <li>
              <Tooltip title="Xóa" color="red">
                <CloseOutlined
                  onClick={() => {
                    showConfirm(record)
                  }}
                  className="text-xl text-red-500  cursor-pointer"
                />
              </Tooltip>
            </li>
          )}
        </ul>
      ),
    },
  ]

  return (
    <>
      <div className="p-5 bg-[#EFEFEF] h-full">
        <div
          className="flex w-full items-center gap-5 p-2 bg-white rounded-md"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex w-full gap-3">
            <Select
              showSearch
              className="w-80"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input?.toLowerCase()?.trim())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={(value) => handleInputSelect('ct', value)}
              value={company}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
                key: items.idct,
              }))}
            />
            {/* <Select
              showSearch
              className="w-80"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => handleInputSelect('cn', value)}
              value={branch}
              options={[
                { label: "Tất cả", value: "all", key: "all" },
                ...(listBranch
                  ? listBranch.map((items) => ({
                    label: items.tenchinhanh,
                    value: items.idchinhanh,
                    key: items.idchinhanh,
                  }))
                  : []),
              ]}
            /> */}
          </div>
          <div className="flex gap-5 ml-auto">
            <Button
              onClick={() => {
                setIsModalCreate(true)
              }}
              className="flex items-center"
              type="primary"
            >
              <PlusOutlined /> Tạo phòng khám
            </Button>
            {/* <Button className="flex items-center ml-4" type="primary" disabled>
              <PlusOutlined /> Tạo nhóm dịch vụ
            </Button> */}
          </div>
        </div>
        <div className="flex gap-2 mt-2">
          <div
            className="w-2/3 bg-white rounded-md"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <div className="p-2 ">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                    },
                  },
                }}
              >
                <Table
                  loading={loadingTable}
                  pagination={false}
                  bordered
                  scroll={{
                    x: 1300,
                    y: 745,
                  }}
                  rowClassName={(record, index) => {
                    if (record?.idPK === rowActive?.idPK) {
                      return 'bg-gray-100'
                    }
                    return null
                  }}
                  onRow={(row) => {
                    return {
                      onClick: () => {
                        handleClickRow(row)
                      }, // click row
                    }
                  }}
                  columns={columns}
                  dataSource={listPhongKham}
                  // dataSource={ListDichVu?.map((item, index) => ({
                  //   STT: ++index,
                  //   ...item,
                  //   action: (
                  //     <ul className="flex justify-around">
                  //       <li>
                  //         <Tooltip title="Chỉnh sửa" color="green">
                  //           <EditOutlined
                  //             onClick={(e) => {
                  //               e.stopPropagation()
                  //               handleShowModalEdit(item)
                  //             }}
                  //             className="text-xl text-green-500  cursor-pointer"
                  //           />
                  //         </Tooltip>
                  //       </li>
                  //       <li>
                  //         <Tooltip title="Xoá" color="red">
                  //           <DeleteOutlined
                  //             onClick={(e) => {
                  //               e.stopPropagation()
                  //               showDeleteConfirm(item.iddv, item.tenDichVu)
                  //             }}
                  //             className="text-xl text-red-500  cursor-pointer"
                  //           />
                  //         </Tooltip>
                  //       </li>
                  //     </ul>
                  //   ),
                  // }))}
                />
              </ConfigProvider>
            </div>
          </div>

          <div
            className="w-1/3 h-full bg-white rounded-md"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <div className="px-2 pt-2 flex justify-between text-base items-center">
              <div className="flex gap-1 w-3/5 ">
                {rowActive && (
                  <>
                    <span>Tên:</span>
                    <p className="font-semibold ">{rowActive?.tenPK}</p>
                  </>
                )}
              </div>
              <div className="w-1/5 flex justify-end">
                <Button
                  onClick={() => setIsModalOpen(true)}
                  disabled={!rowActive || loadingCN}
                  className="h-7 w-full ml-2"
                  type="primary"
                  size="small"
                >
                  <PlusOutlined /> Thêm
                </Button>
              </div>
              {/* <div className="w-1/5 flex justify-end">
                <Button
                  onClick={handleUpdate}
                  disabled={!rowActive || loadingCN}
                  className="h-7 w-full ml-2"
                  type="primary"
                  size="small"
                >
                  Lưu
                </Button>
              </div> */}
            </div>

            <div className="p-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                }}
              >
                <Table
                  loading={loadingCN}
                  scroll={{ y: 690 }}
                  // loading={isLoadingBranch}
                  pagination={false}
                  columns={columnsCN}
                  dataSource={listBranchUse}
                  bordered
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalAdd
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          rowActive={rowActive}
          company={company}
          getListBranchUse={getListBranchUse}
        />
      )}
      {isModalCreate && (
        <ModalCreate
          isModalCreate={isModalCreate}
          handleCancel={handleCancel}
          getListPhongKham={getListPhongKham}
        />
      )}
      {isModalEdit && (
        <ModalEdit
          isModalEdit={isModalEdit}
          handleCancel={handleCancel}
          getListPhongKham={getListPhongKham}
          rowActive={rowActive}
        />
      )}
      {/* {isModalEdit && (
        <ModalEdit
          isModalEdit={isModalEdit}
          handleCancelEdit={handleCancelEdit}
          searchGroup={searchGroup}
          company={company}
        />
      )}
      {showEditPriceCN && (
        <ModalChangePriceCN
          rowActive={rowActive}
          showEditPriceCN={showEditPriceCN}
          setShowEditPriceCN={setShowEditPriceCN}
          // getListBranchUse={getListBranchUse}
        />
      )} */}
    </>
  )
}

export default PhongKham
