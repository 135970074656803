import React from "react";
// import InfoBN from "./InfoBN/InfoBN";
// import Index from "./Index/Index";
import InfoBN from "../../ChildrenClinic/General/InfoBN/InfoBN";
import Index from "../../ChildrenClinic/General/Index/Index";
import TabDiagnostic from "./Tab/TabDiagnostic";
// import History from "./History/History";


const General = ({ defaultValuePK }) => {
  return (
    <div className="flex flex-col gap-2 h-full">
      <div
        className="bg-white rounded-md"
        style={{
          boxShadow:
            "0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)",
        }}
      >
        <InfoBN />
      </div>
      <div>
        <Index />
      </div>
      <div className="flex gap-2 h-full">
        <div
          className="w-full bg-white rounded-md"
          style={{
            boxShadow:
              "0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)",
          }}
        >
          <TabDiagnostic defaultValuePK={defaultValuePK} />
        </div>
        {/* <div
          className="w-1/4 bg-white rounded-md"
          style={{
            boxShadow:
              "0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)",
          }}
        >
            <History/>
        </div> */}
      </div>
    </div>
  );
};

export default General;
