import { Table, Upload, Button, Space, Typography } from 'antd'
import { UploadOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

const { Text } = Typography

// Định nghĩa các cột cho bảng
const columns = [
  {
    title: 'Tên tệp',
    dataIndex: 'fileName',
    key: 'fileName',
    render: (text) => <a href="#">{text}</a>,
  },
  {
    title: 'Kích thước',
    dataIndex: 'size',
    key: 'size',
  },
  {
    title: 'Ngày tải lên',
    dataIndex: 'uploadDate',
    key: 'uploadDate',
  },
  {
    title: 'Hành động',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a href="#">
          <DownloadOutlined /> Tải xuống
        </a>
        <a href="#" style={{ color: 'red' }}>
          <DeleteOutlined /> Xóa
        </a>
      </Space>
    ),
  },
]

export default function Dinhkem() {
  const [data, setData] = useState([])

  const handleUpload = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
      const newFile = {
        key: file.uid,
        fileName: file.name,
        size: (file.size / 1024).toFixed(2) + ' KB',
        uploadDate: new Date().toLocaleDateString(),
      }
      setData((prevData) => [...prevData, newFile])
    }, 0)
  }

  return (
    <div className=" p-2 border mx-2 mt-2 rounded-md">
      <div style={{ padding: '20px', borderRadius: '8px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <Upload customRequest={handleUpload} showUploadList={false}>
            <Button type="primary" icon={<UploadOutlined />}>
              Tải lên tệp
            </Button>
          </Upload>
        </div>
        <div style={{ height: '300px' }}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            scroll={{ y: 240 }}
          />
        </div>
      </div>
    </div>
  )
}
