import React from 'react'
import logo from '../../../../../assets/images/logo/MemLogo_315_Logo.png'
const NotFound = () => {
  return (
    <div className="col-span-7 flex flex-col items-center justify-center mr-10 mt-[10rem]">
      <img src={logo} alt="Logo" className="mb-4" />
      <p className="text-center text-gray-500">
        Hãy chọn
        <span className="font-bold text-blue-500"> chi nhánh </span>
        và
        <span className="font-bold text-blue-500"> chức vụ </span>
        để xem lịch làm việc của nhân viên
      </p>
    </div>
  )
}

export default NotFound
