import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { xetNghiemService } from '../../services/xetNghiem/xetNghiemService'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { monitorService } from '../../services/monitor/monitorService'
import { generateMonitorTimThai } from '../../utils/report/san/monitorTimThai'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* monitorSaga() {
  yield takeLatest(
    typeAction.GET_INFO_BN_MONITOR,
    function* infoBNMonitor({ type, idbnttc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const infoDetail = yield call(() => monitorService.getDetaiMonitor(idbnttc))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_MONITOR,
          payload: infoDetail.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_ALL_BN_CHO_MONITOR,
    function* getBNCho({ type, idcn }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const result = yield call(() => monitorService.getBNPendingMonitor(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_MONITOR,
          payload: result.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.ADD_BN_MONITOR,
    function* addBNMonitor({ type, form, onLoad }) {
      try {
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        //get BN TTC
        const result = yield call(() => monitorService.AddBnMonitor(form))
        const bnttc = yield call(() => monitorService.getInfoBNTTC(result.data.idbnttc))
        yield call(() => generateMonitorTimThai(bnttc.data,result.data,PkDangNhap))
        //call lai ds benh nhan cho
        onLoad()
        
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công',
        })
      } catch (error) {
        console.log(error)
       
        Toast.fire({
            icon: 'error',
            title: 'Lưu thất bại',
          })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  
}
