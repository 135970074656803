import * as Yup from "yup";

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Vui lòng nhập mật khẩu cũ!"),
  newPassword: Yup.string()
    .required("Vui lòng mật khẩu mới!")
    .min(6, "Mật khẩu mới phải có ít nhất 6 ký tự"),
  confirmPassword: Yup.string()
    .required("Vui lòng nhập xát thực mật khẩu!")
    .oneOf([Yup.ref("newPassword"), null], "Không khớp với mật khẩu mới"),
});
