import axios from 'axios'
import { https } from '../apiService'

export const khoVTservice = {
  getAllVT: () => https.get('/ThuocVatTu/AllThuoc'),
  addThuocVT: (form) => https.post('ThuocVatTu/ThemThuocVatTu', form),
  deleteThuocVT: (id) => https.delete(`ThuocVatTu/${id}`),
  getInfoVT: (id) => https.get(`ThuocVatTu/find/${id}`),
  getListCountry: () => https.get('QuocTich'),
  getListTonkho: (id) => https.get(`TonKho/ChiNhanh/${id}`),
  getTonKhoByConditionForData: (
    IDCongTy,
    IDChiNhanh,
    IDKhoCN,
    toDate,
    //  pageNumber,
    keyword,
  ) =>
    https.get('TonKho/GetTonKhoDenNgay', {
      params: {
        IDCongTy,
        IDChiNhanh,
        IDKhoCN,
        toDate,
        //  pageNumber,
        keyword,
      },
    }),
  getTonKhoCondition: (IDCongTy, IDChiNhanh, IDKhoCN) =>
    https.get('TonKho/GetTonKhoByCondition', {
      params: {
        IDCongTy,
        IDChiNhanh,
        IDKhoCN,
      },
    }),
  getListTonkhoByKhoCN: (idKhoCN) => https.get(`TonKho/${idKhoCN}`),
  getThuocVT: (page) => https.get(`ThuocVatTu/GetPage?pageIndex=${page}`),
  getListTypesThuocVT: () => https.get('ThuocVatTuPhanLoai'),
  getListGroupsThuocVT: (idTypes) =>
    https.get(`ThuocVatTuNhom/SearchNhomThuoc?idPhanLoai=${idTypes}`),
  getAllGroupThuocVT: () => https.get('ThuocVatTuNhom'),
  updateThuocVTById: (form) => https.put(`ThuocVatTu/${form.idThuoc}`, form),
  SearchByCondition: (keyword, idCT, idPhanLoai, idNhom, pageIndex) =>
    https.get('ThuocVatTu/SearchByCondition', {
      params: {
        keyword,
        idCT,
        idPhanLoai,
        idNhom,
        pageIndex,
      },
    }),
  getListBranchBuy: (id) => https.get(`ThuocVatTuChiNhanhBan/ByIDThuoc?idThuoc=${id}`),
  putBranchBuy: (form) => https.put('ThuocVatTuChiNhanhBan/UpdateTrangThaiBan', form),
  getAllChuyenKhoa: () => https.get('ChuyenKhoa/AllChuyenKhoa'),
  getMSDKThuoc: (idThuoc) =>
    https.get(`ThuocVatTuMSDK/GetThuocVatTuMSDKByIDTHUOC?idThuoc=${idThuoc}`),
  fetchDuongDung: () => https.get('DuongDung/GetAllDuongDung'),
  getTheKho: (IDCT, IDChiNhanh, IDKhoCN, fromDate, toDate, IDThuoc) =>
    https.get('TheKho/ByCondition', {
      params: {
        IDCT,
        IDChiNhanh,
        IDKhoCN,
        fromDate,
        toDate,
        IDThuoc,
      },
    }),
  checkMaHangHoa: (id, data) => https.post(`ThuocVatTu/CheckMaThuoc/${id}`, data),
  getTonKhoTongHop: (IDCongTy, IDChiNhanh, IDKhoCN, keyword) =>
    https.get('TonKho/GetTonKhoTongHop', {
      params: {
        IDCongTy,
        IDChiNhanh,
        IDKhoCN,
        keyword,
      },
    }),
  getThuocVatTuGiaBan: (keyword, idct, idnhom, idType) =>
    https.get(
      `ThuocVatTu/GetThuocVatTuGiaBan?keyword=${keyword}&idct=${idct}${idnhom}&idloai=${idType}`,
    ),
  SearchThuocCanhBao: (keyword, idCT, idPhanLoai, idNhom) =>
    https.get('ThuocVatTu/SearchThuocCanhBao', {
      params: {
        keyword,
        idCT,
        idPhanLoai,
        idNhom,
      },
    }),
}
