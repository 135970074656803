import { logoToDataURI } from '../assets/images/logoNhiBase64'
import { logoSanToDataURI } from '../assets/images/logoSanBase64'
import { logoLaoToDataURI } from '../assets/images/logoLaoBase64'
import { logoMatToDataUI } from '../assets/images/logoMatBase64'
import { splitLongWord } from './splitWordForReport'

//new version using hash table
export const getListMenu = (data) => {
  const menuMap = data.reduce((acc, item) => {
    if (item.idMenuCha === 0) {
      acc[item.idMenu] = { ...item, listChildMenu: [] }
    }
    return acc
  }, {})
  for (const item in menuMap) {
    // console.log(item);
    data.forEach((element) => {
      if (+element.idMenuCha === +item) {
        menuMap[item].listChildMenu.push(element)
      }
    })
  }
  return Object.values(menuMap)
}

//format data for tree
export const formatDataTreeMenu = (data) =>
  data.map((item) => {
    return {
      title: item.tenMenu,
      key: item.idMenu,
      children: item.listChildMenu.map((child) => {
        return {
          title: child.tenMenu,
          key: child.idMenu,
        }
      }),
    }
  })

export const getArrayCheckedByListMenu = (data) => {
  return data.reduce((acc, item) => {
    // Add the key of the current item to the accumulator
    acc.push(item.key)
    // If the current item has children, recursively extract keys from children
    if (item.children) {
      const childKeys = item.children.reduce((childAcc, childItem) => {
        childAcc.push(childItem.key)
        return childAcc
      }, [])
      acc = acc.concat(childKeys)
    }
    return acc
  }, [])
}

export const generateLogo = (doc, xPos, PkDangNhap) => {
  switch (PkDangNhap.idChuyenKhoa) {
    case 2:
      doc.addImage(logoSanToDataURI, 'PNG', xPos - 4, 2, 22, 24)
      doc.setFont('times', 'bold')
      doc.setFontSize(11)
      doc.text('HỆ THỐNG Y TẾ PHỤ SẢN 315', 27.5, 10)
      doc.setFont('times', 'regular')
      doc.setFontSize(8)
      doc.text(PkDangNhap.diaChi ?? '', 27.5, 14)
      doc.text(
        `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
        27.5,
        18,
      )
      doc.text('Website: www.phusan315.com', 27.5, 22)
      break
    case 3:
      doc.addImage(logoLaoToDataURI, 'PNG', xPos - 4, 2, 22, 24)
      doc.setFont('times', 'bold')
      doc.setFontSize(9)
      doc.text('HỆ THỐNG Y TẾ TIM MẠCH - TIỂU ĐƯỜNG 315', 27.5, 10)
      doc.setFont('times', 'regular')
      doc.setFontSize(7)
      doc.text(PkDangNhap.diaChi ?? '', 27.5, 14)
      doc.text(
        `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
        27.5,
        18,
      )
      doc.text('Website: www.timmachtieuduong315.com', 27.5, 22)
      break
    case 4:
      doc.addImage(logoMatToDataUI, 'PNG', xPos - 4, 2, 22, 24)
      doc.setFont('times', 'bold')
      doc.setFontSize(10)
      doc.text('HỆ THỐNG Y TẾ MẮT 315', 27.5, 10)
      doc.setFont('times', 'regular')
      doc.setFontSize(8)
      doc.text(PkDangNhap.diaChi ?? '', 27.5, 14)
      doc.text(
        `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
        27.5,
        18,
      )
      doc.text('Website: www.mat315.com', 27.5, 22)
      break
    default:
      doc.addImage(logoToDataURI, 'PNG', xPos - 4, 2, 22, 24)
      doc.setFont('times', 'bold')
      doc.setFontSize(11)
      doc.text('HỆ THỐNG Y TẾ NHI ĐỒNG 315', 27.5, 10)
      doc.setFont('times', 'regular')
      doc.setFontSize(8)
      doc.text(PkDangNhap.diaChi ?? '', 27.5, 14)
      doc.text(
        `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - ${PkDangNhap.sohotline ?? '0901.315.315'}`,
        27.5,
        18,
      )
      doc.text('Website: www.nhidong315.com', 27.5, 22)
      break
  }
}
export const generateThuVietNamese = (datetimeString) => {
  const date = new Date(datetimeString) // Parse the datetime string
  const options = { weekday: 'short' }
  const shortWeekday = date.toLocaleDateString('vi-VN', options)
  switch (shortWeekday) {
    case 'Th 2':
      return 'Thứ 2'
    case 'Th 3':
      return 'Thứ 3'
    case 'Th 4':
      return 'Thứ 4'
    case 'Th 5':
      return 'Thứ 5'
    case 'Th 6':
      return 'Thứ 6'
    case 'Th 7':
      return 'Thứ 7'
    case 'CN':
      return 'Chủ nhật'
    default:
      break
  }
}

export const generateCheckbox = (doc, xPos, yPos, length, width, checked = false) => {
  doc.setLineWidth(0.2)
  doc.rect(xPos, yPos, length, width)
  if (checked) {
    //draw border of checkbox
    doc.setLineWidth(0.5) // Make lines thicker
    // draw custom icon check mark (font not support icon check mark)
    doc.moveTo(xPos + length * 0.25, yPos - 0.5 + width * 0.7) // Higher starting point
    doc.lineTo(xPos + length * 0.5, yPos - 0.5 + width * 0.85) // First line
    doc.lineTo(xPos + length * 0.75, yPos - 0.5 + width * 0.4) // Second line

    // Optionally stroke the path to render the lines
    doc.stroke() // Ensure the lines are drawn
  }
  doc.setLineWidth(0.2)
}

export const generateDot = (doc, text, xPos, yPos) => {
  const textDimensions = doc.getTextDimensions(text)
  const pageWidth = doc.internal.pageSize.width // Get the page width
  const availableSpace = pageWidth - 10 - (xPos + textDimensions.w) // Calculate available space for dots
  const dotWidth = doc.getTextDimensions('.').w // Get the width of a single dot

  // Ensure availableSpace is not negative
  const dotCount = Math.max(0, Math.floor(availableSpace / dotWidth)) // Calculate the number of dots that can fit
  const dotStrings = '.'.repeat(dotCount) // Create a string of dots

  return {
    content: dotStrings,
    positionX: xPos + textDimensions.w, // Position dots immediately after the text
    yPos: yPos,
  }
}

export const generateDotWithWidth = (doc, text, xPos, yPos, width) => {
  const textDimensions = doc.getTextDimensions(text)
  const availableSpace = width - 10 - (xPos + textDimensions.w) // Calculate available space for dots
  const dotWidth = doc.getTextDimensions('.').w // Get the width of a single dot
  // Ensure availableSpace is not negative
  const dotCount = Math.max(0, Math.floor(availableSpace / dotWidth)) // Calculate the number of dots that can fit
  const dotStrings = '.'.repeat(dotCount) // Create a string of dots

  return {
    content: dotStrings,
    positionX: xPos + textDimensions.w, // Position dots immediately after the text
    yPos: yPos,
  }
}

export function isWithin24Hours(ngaysieuam) {
  // Parse the ngaysieuam string into a Date object
  const ngaysieuamDate = new Date(ngaysieuam)

  // Get the current time
  const currentTime = new Date()

  // Calculate the difference in milliseconds
  const timeDifference = currentTime - ngaysieuamDate

  // Convert 24 hours to milliseconds
  const twentyFourHours = 24 * 60 * 60 * 1000

  // Check if the difference is less than or equal to 24 hours
  return timeDifference <= twentyFourHours
}


//using only for kham benh mat
export const checkNewPage = (doc, yPos, contentHeight) => {
  const pageWidth = doc.internal.pageSize.width // Get the page width
  const pageHeight = doc.internal.pageSize.height // Get the page height
  const bottomMargin = 5 // end of page margin

  if (yPos + contentHeight > pageHeight - bottomMargin) {
    doc.addPage()
    const centerPage = pageWidth / 2
    const leftPage = (pageWidth / 2 + pageWidth) / 2
    const rightPage = (pageWidth - pageWidth / 2) / 2
    doc.setFont('times', 'bold')
    doc.text('BỘ PHẬN', centerPage, 10, { align: 'center' })
    doc.text('MẮT PHẢI', leftPage, 10, { align: 'center' })
    doc.text('MẮT TRÁI', rightPage, 10, { align: 'center' })
    return 18 // Reset yPos for the new page
  }
  return yPos
}

// Function to calculate content height dynamically
export const calculateContentHeightBenhAnMat = (doc, field) => {
  let contentHeight = 4

  // Title height (adjust if the font size is larger)
  contentHeight += 4

  // Checkbox labels height
  contentHeight += 6

  // Description for MT (if it's long, it could take multiple lines)
  const motaMT = `Mô tả: ${field.mt ?? ''}`
  if (motaMT.length > 0) {
    if (doc.getTextWidth(motaMT) > 40) {
      const wordsArray = splitLongWord(motaMT, 45)
      contentHeight += wordsArray.length * 4 // Adding height for each line
    } else {
      contentHeight += 4
    }
  } else {
    contentHeight += 4 // Default height if empty
  }

  // Description for MP (similar logic to MT)
  const motaMP = `Mô tả: ${field.mp ?? ''}`
  if (motaMP.length > 0) {
    if (doc.getTextWidth(motaMP) > 40) {
      const wordsArray = splitLongWord(motaMP, 45)
      contentHeight += wordsArray.length * 4
    } else {
      contentHeight += 4
    }
  } else {
    contentHeight += 4
  }

  return contentHeight
}
