import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Input,
  Modal,
  Select,
  Space,
  Table,
} from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createEditDVSchema } from '../../../../schemas/createEditDVSchema'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import TabsChiDinh from '../TabsChiDinh/TabsChiDinh'
import moment from 'moment'
import { addGoiKhamCRM } from '../../../../store/actions/goikhamCRMAction'
import { addNewGoiKhamSchema } from '../../../../schemas/addNewGoiKhamSchema'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')

const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const dateFormat = 'DD/MM/YYYY'

const ModalAddGoiDV = ({ open, setOpen, onLoad }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [dataInTableDV, setDataInTableDV] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    const dataFormat = dataInTableDV.map((item) => {
      delete item.donGia
      delete item.donVi
      delete item.idCheck
      delete item.maDichVu
      delete item.tenDichVu
      return item
    })
    dispatch(addGoiKhamCRM({ goiKham: value, dichVuKhams: dataFormat }, onLoad))
    formik.resetForm()
    setOpen(false)
  }
  const disabledStartDate = (current) => {
    return current && current > dayjs().endOf('day')
  }
  // Hàm kiểm tra ngày cho khamdenngay
  const disabledEndDate = (current) => {
    const startDate = dayjs(formik.values.khamtungay)
    return current && current < startDate
  }
  const formik = useFormik({
    initialValues: {
      tengoikham: '',
      khamtungay: moment().format(formatDB),
      khamdenngay: moment().format(formatDB),
      ghichu: '',
      nguoitao: infoUser.idnv,
      tennguoitao: infoUser.tenNV,
    },
    validationSchema: addNewGoiKhamSchema,
    onSubmit: (value) => handleSubmit(value),
  })

  const handleDeleteInTable = (record) => {
    setDataInTableDV((prev) => prev.filter((item) => item.idCheck !== record.idCheck))
  }

  const handleDatePicker = (date, dateString, key) => {
    const value = moment(dateString, dateFormat).format(formatDB)
    formik.setFieldValue(key, value)
    if (key === 'khamtungay') {
      const khamdenngayDate = moment(formik.values.khamdenngay)
      const khamtungayDate = moment(dateString, dateFormat)
      if (khamdenngayDate.isBefore(khamtungayDate)) {
        formik.setFieldValue('khamdenngay', value)
      }
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const columnsDichVuChoose = [
    {
      title: 'Mã DV',
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      align: 'center',
      className: 'text-sm',
      width: 120,
      ...getColumnSearchProps('maDichVu'),
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      align: 'left',
      className: 'text-sm',
      ...getColumnSearchProps('tenDichVu'),
    },
    {
      title: 'ĐVT',
      dataIndex: 'donVi',
      key: 'donVi',
      align: 'center',
      className: 'text-sm',
      width: 100,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'donGia',
      key: 'donGia',
      className: 'text-sm',
      align: 'right',
      render: (text, record) => {
        return formatNumberVND(record.donGia)
      },
      width: 100,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      className: 'text-sm',
    },
  ]

  return (
    <>
      <Modal
        style={{
          top: 20,
        }}
        width={1000}
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo gói khám</h2>}
        onOk={formik.handleSubmit}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div className="flex w-full gap-1 items-center">
            <div className="flex w-full gap-1 items-center">
              <label className="w-32 text-end">
                <span className="text-red-500">(*)</span>Tên gói:
              </label>
              <Input
                status={formik.errors.tengoikham ? 'error' : ''}
                name="tengoikham"
                value={formik.values.tengoikham}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-[150px] text-end">Từ ngày:</label>
              <DatePicker
                allowClear={false}
                value={dayjs(formik.values.khamtungay)}
                onChange={(date, dateString) => handleDatePicker(date, dateString, 'khamtungay')}
                format={dateFormat}
                className="w-full"
              />
            </div>
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-28 text-end">Đến ngày:</label>
              <DatePicker
                allowClear={false}
                value={dayjs(formik.values.khamdenngay)}
                onChange={(date, dateString) => handleDatePicker(date, dateString, 'khamdenngay')}
                format={dateFormat}
                className="w-full"
              />
            </div>
          </div>
          <div className="flex w-full gap-1 items-center">
            <div className="flex w-full gap-1 items-center">
              <label className="w-32 text-end">Ghi chú:</label>
              <Input
                status={formik.errors.ghichu ? 'error' : ''}
                name="ghichu"
                value={formik.values.ghichu}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </form>
        <Divider style={{ marginTop: 15, marginBottom: 15 }} />
        <div className="overflow-auto h-[280px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{ y: 220 }}
              bordered
              pagination={false}
              columns={columnsDichVuChoose}
              dataSource={
                dataInTableDV.map((item) => ({
                  ...item,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg text-red-500">
                        <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                      </li>
                    </ul>
                  ),
                })) || []
              }
            />
          </ConfigProvider>
        </div>
        <Divider style={{ marginTop: 15, marginBottom: 15 }} />
        <div>
          <TabsChiDinh dataInTableDV={dataInTableDV} setDataInTableDV={setDataInTableDV} />
        </div>
      </Modal>
    </>
  )
}

export default ModalAddGoiDV
