import { Checkbox, Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createLienHeSchema } from '../../../../schemas/createLienHeSchema'
import { https } from '../../../../services/apiService'
import { editLienHeCRM, getLienHeCRM } from '../../../../store/actions/lienheCRMAction'
const dateFormat = 'DD-MM-YYYY'

const ModalEdit = ({ openEditLienHe, setOpenEditLienHe }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoLienHe } = useSelector((state) => state.lienheCRMReducer)
  const dispatch = useDispatch()
  const [allCongTy, setAllCongTy] = useState([])
  // xử lí form thêm dịch vụ
  const onLoad = () => {
    dispatch(getLienHeCRM(infoLienHe?.idct))
  }
  const handleSubmit = (value) => {
    dispatch(editLienHeCRM(value, onLoad))
    setOpenEditLienHe(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idttlh: infoLienHe?.idttlh,
      idct: infoLienHe?.idct,
      nguoilienhe: infoLienHe?.nguoilienhe,
      dienthoailienhe: infoLienHe?.dienthoailienhe,
      emaillienhe: infoLienHe?.emaillienhe,
      ghichu: infoLienHe?.ghichu,
      nguoisua: infoUser?.idnv,
      sudung: infoLienHe?.sudung,
      tencongty: infoLienHe?.tencongty,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: createLienHeSchema,
  })

  useEffect(() => {
    const getAllCongTy = async () => {
      try {
        const { data } = await https.get('CRMCongTy/GetAllCRMCongTy')
        setAllCongTy(data.map((item) => ({ value: item.idct, label: item.tenct })))
      } catch (error) {
        console.log(error)
      }
    }
    getAllCongTy()
  }, [])

  return (
    <>
      <Modal
        okText="Cập nhật"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Thông tin liên hệ</h2>}
        onOk={formik.handleSubmit}
        open={openEditLienHe}
        onCancel={() => setOpenEditLienHe(false)}
        okButtonProps={{ disabled: infoUser.tenNhom === 'Sales' }}
      >
        <form className="text-start flex flex-col gap-2">
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Công ty:</label>
            {/* <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              value={formik.values.idct}
              options={allCongTy?.map(({ value, label }) => ({
                label: label,
                value: value,
              }))}
              className="w-full bg-white"
              disabled
            /> */}
            <Input
              value={formik.values.tencongty}
              name="tencongty"
              className="w-full bg-gray-200 cursor-default"
              readOnly
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold w-32">Họ và Tên :</label>
            <Input
              value={formik.values.nguoilienhe}
              name="nguoilienhe"
              onChangeCapture={formik.handleChange}
              status={formik.errors.nguoilienhe && 'error'}
              className="w-full"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold w-32">SĐT :</label>
            <Input
              value={formik.values.dienthoailienhe}
              name="dienthoailienhe"
              onChangeCapture={formik.handleChange}
              status={formik.errors.dienthoailienhe && 'error'}
              className="w-full"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold w-32">Email:</label>
            <Input
              value={formik.values.emaillienhe}
              name="emaillienhe"
              onChangeCapture={formik.handleChange}
              status={formik.errors.emaillienhe && 'error'}
              className="w-full"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold w-32">Ghi chú :</label>
            <Input
              value={formik.values.ghichu}
              name="ghichu"
              onChangeCapture={formik.handleChange}
              status={formik.errors.ghichu && 'error'}
              className="w-full"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div>
            <div className="flex  items-center ">
              <label className="font-semibold w-24">Sử dụng :</label>
              <Checkbox
                checked={formik.values.sudung}
                onChange={() => formik.setFieldValue('sudung', !formik.values.sudung)}
                disabled={infoUser.tenNhom === 'Sales'}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEdit
