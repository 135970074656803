import Swal from 'sweetalert2'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { toolService } from '../../services/tool/toolService'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* toolSaga() {
  yield takeLatest(
    typeAction.UPDATE_PAYMENT_BN,
    function* updatePayBN({ type, idLoai, maPhieu, date, setMaPhieu }) {
      try {
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
        yield call(() => toolService.updatePay(idLoai, maPhieu, date))
        setMaPhieu(null)
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        yield console.log(err)
      }
    },
  )
  yield takeLatest(typeAction.GET_ALL_UU_DAI_TAI_KHAM, function* getUuDai({ type, setData }) {
    try {
      const { data } = yield call(() => toolService.getAllUuDaiTK())
      setData(data)
    } catch (err) {
      yield console.log(err)
    }
  })
  yield takeLatest(
    typeAction.ADD_NEW_UU_DAI_TAI_KHAM,
    function* addNewUuDai({ type, form, fetchData }) {
      try {
        yield call(() => toolService.postUuDaiTK(form))
        fetchData()
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.DELETE_UU_DAI_TAI_KHAM,
    function* deleteUuDai({ type, ID, fetchData, openNotificationWithIcon }) {
      try {
        yield call(() => toolService.deleteUuDai(ID))
        fetchData()
        openNotificationWithIcon('Xoá ưu đãi tái khám thành công')
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_DICH_VU_BY_CONG_TY,
    function* getDichvuByCT({ type, idct, keyword, setData }) {
      try {
        const { data } = yield call(() => toolService.getDichVu(idct, keyword))
        setData(data)
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  yield takeLatest(typeAction.UPDATE_TTV_TOOL, function* updateTTV({ type, form, reset }) {
    try {
      yield toolService.updateTTV(form)
      reset()
      Toast.fire({
        icon: 'success',
        title: 'Lưu thành công!',
      })
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Lưu thất bại!',
      })
      yield console.log(err)
    }
  })
  yield takeLatest(
    typeAction.UPDATE_NGAY_TAI_KHAM_TOOL,
    function* updateTaiKham({ type, form, reset }) {
      try {
        yield toolService.putTaiKham(form)
        reset()
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        yield console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.TOO_GOP_BENH_AN,
    function* gopBenhAn({ type, reset, idbn, idbndelete }) {
      try {
        reset()
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield toolService.putGopBenhAn(idbn, idbndelete)

        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Gộp thành công!',
        })
      } catch (err) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Gộp thất bại!',
        })
        yield console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_CALAMVIEC_PHIEUTHU_TOOL,
    function* putCaLamViecCuaPhieuThuSaga({ type, form, reset }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          toolService.putCaLamViecCuaPhieuThu(
            form?.maPhieuThu,
            form?.idCaLamViec,
            form?.idLoaiTT,
            form?.idNguoiSua,
          ),
        )
        yield call(() => reset())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_LOAIKHAM_PHIEUTHU_TOOL,
    function* putLoaiKhamCuaPhieuThuSaga({ type, form, reset }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          toolService.putLoaiKhamCuaPhieuThu(form?.maPhieuThu, form?.idLoaiKham, form?.idNguoiSua),
        )
        yield call(() => reset())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.DELETE_PHIEU_KHO_TOOL,
    function* deletePhieuKho({ type, maPhieu, reset }) {
      try {
        yield call(() => toolService.deletePhieuKho(maPhieu))
        reset()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_NGUOIBAN_TTV_TOOL,
    function* putNguoiBanTTVSaga({ type, form, reset }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          toolService.putNguoiBanTTV(
            form?.maPhieuThu,
            form?.idNguoiBan ?? null,
            form?.idNguoiTao ?? null,
          ),
        )
        yield call(() => reset())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
