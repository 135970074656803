import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, Divider, Input, Space, Table, Tabs, Tooltip } from 'antd'
import Highlighter from 'react-highlight-words'
import { https } from '../../../../services/apiService'
import { cardService } from '../../../../services/card/cardService'
import { cloneDeep, debounce } from 'lodash'
import { DeleteOutlined, PlusOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'

function TabTTV({ dataTTV, setDataTTV }) {
  const [listTTV, setListTTV] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filterCK, setFilterCK] = useState([])

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleAddTTV = (infoLoaiThe) => {
    if (dataTTV?.find((item) => item?.idlt === infoLoaiThe?.idlt)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Loại thẻ này đã được chọn!',
      })
      return
    }
    setDataTTV((prev) => [...prev, { ...infoLoaiThe }])
  }
  const handleRemoveTTV = (infoLoaiThe) => {
    setDataTTV((prev) => [...prev?.filter((item) => item?.idlt !== infoLoaiThe?.idlt)])
  }
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const { data } = await cardService.listTypeCard(null, null) //idck, keyword, null để get all
        for (const item of data) {
          setFilterCK((prev) => {
            return prev?.findIndex((itemCK) => itemCK?.value === item?.idchuyenkhoa) === -1
              ? [
                  ...prev,
                  {
                    value: item?.idchuyenkhoa,
                    text: item?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim(),
                  },
                ]
              : [...prev]
          })
        }
        setListTTV(data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  return (
    <div>
      <div className="overflow-auto h-[265px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            // loading={loadingTable}
            scroll={{ y: 180 }}
            bordered
            pagination={false}
            dataSource={dataTTV}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Tên loại thẻ',
                dataIndex: 'loaithe',
                key: 'loaithe',
              },
              {
                title: 'Chuyên khoa',
                dataIndex: 'tenchuyenkhoa',
                key: 'tenchuyenkhoa',
                align: 'center',
                width: 100,
                render: (text, record, index) => text?.replace('Chuyên khoa', '')?.trim(),
              },
              {
                title: 'Đơn giá',
                dataIndex: 'tiensudung',
                key: 'tiensudung',
                align: 'right',
                width: 100,
                render: (text, record, index) => formatNumberVND(text ?? 0),
              },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 40,
                render: (text, record, index) => (
                  <>
                    <CloseOutlined
                      className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                      onClick={() => handleRemoveTTV(record)}
                    />
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <div>
        <div className="overflow-auto h-[264px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{ y: 180 }}
              pagination={false}
              loading={isLoading}
              bordered
              dataSource={listTTV}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 40,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Tên loại thẻ',
                  dataIndex: 'loaithe',
                  key: 'loaithe',
                  ...getColumnSearchProps('loaithe'),
                },
                {
                  title: 'Chuyên khoa',
                  dataIndex: 'tenchuyenkhoa',
                  key: 'tenchuyenkhoa',
                  align: 'center',
                  width: 115,
                  render: (text, record, index) => text?.replace('Chuyên khoa', '')?.trim(),
                  filters: filterCK,
                  onFilter: (value, record) => record?.idchuyenkhoa === value,
                },
                {
                  title: 'Đơn giá',
                  dataIndex: 'tiensudung',
                  key: 'tiensudung',
                  align: 'right',
                  width: 100,
                  render: (text, record, index) => formatNumberVND(text ?? 0),
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  align: 'center',
                  width: 40,
                  render: (text, record, index) => (
                    <>
                      <PlusOutlined
                        className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                        onClick={() => handleAddTTV(record)}
                      />
                    </>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default TabTTV
