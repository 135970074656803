import React, { useEffect, useState } from 'react'
import * as typeAction from '../../../../store/constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Cascader,
  DatePicker,
  Mentions,
  Select,
  TreeSelect,
  Radio,
} from 'antd'
import { useFormik } from 'formik'
import moment from 'moment'
import { postBNKhamDoan } from '../../../../store/actions/khamDoanAction'
import { addBNKhamDoanSchema } from '../../../../schemas/addBNKhamDoanSchema'
import { https } from '../../../../services/apiService'
import { postBNCRM } from '../../../../store/actions/benhnhanCRMAction'
import { benhnhanCRMSchema } from '../../../../schemas/benhnhanCRMSchema'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')

const dateFormat = 'DD/MM/YYYY'
const dateFormatAPI = 'YYYY-MM-DD'
const ModalAdd = () => {
  const dispatch = useDispatch()
  const { modalAddKhamDoan } = useSelector((state) => state.modalReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listKH, setListKh] = useState([])
  const [listGK, setListGK] = useState([])
  const now = moment()
  function disabledDate(current) {
    // Hàm này nhận vào một đối số là ngày hiện tại và trả về true nếu ngày đó nên bị vô hiệu hóa, ngược lại trả về false
    // Ví dụ: Vô hiệu hóa tất cả các ngày trong quá khứ
    return current && current >= moment().startOf('day')
  }
  const formik = useFormik({
    initialValues: {
      tenbn: '',
      gioitinh: '',
      ngaysinh: null,
      sodienthoai: '',
      ghichu: '',
      idkhgk: '',
      idkh: '',
      nguoitao: infoUser?.idnv,
    },
    onSubmit: (value) => handleAddBN(value),
    validationSchema: benhnhanCRMSchema,
  })
  const handleChangeGioiTinh = (e) => {
    const value = e.target.value
    formik.setFieldValue('gioitinh', value)
  }
  const handleAddBN = (value) => {
    if (!value.idkh) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn khách hàng',
      })
      return
    }
    if (!value.idkhgk) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn gói khám',
      })
      return
    }
    if (!value.tenbn) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập tên bệnh nhân',
      })
      return
    }
    if (!value.gioitinh) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui chọn giới tính',
      })
      return
    }
    if (!value.ngaysinh) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn ngày sinh',
      })
      return
    }

    dispatch(postBNCRM(value))
    handleCancel()
    formik.handleReset()
  }

  const handleOk = () => {
    dispatch({
      type: typeAction.CLOSE_ADD_KHAM_DOAN,
    })
  }

  const handleCancel = () => {
    formik.handleReset()
    dispatch({
      type: typeAction.CLOSE_ADD_KHAM_DOAN,
    })
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await https.get('CRMKhachHang/GetAll')
      setListKh(data)
    })()
  }, [])

  const getGoiKhamByKhachHang = async (value) => {
    try {
      const { data } = await https.get(`CRMKhachHangGoiKham/GetByIDKH?IDKH=${value}`)
      setListGK(data.result)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeKhachHang = (value) => {
    if (listGK.length > 0) {
      setListGK([])
      formik.setFieldValue('idkhgk', '')
    }
    formik.setFieldValue('idkh', value)
    getGoiKhamByKhachHang(value)
  }

  return (
    <>
      <Modal
        className="text-center"
        footer={null}
        title="Thêm bệnh nhân"
        open={modalAddKhamDoan}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form className="text-start">
          <div>
            <label class="block text-sm font-medium text-gray-700" for="password">
              <span className="text-red-500">(*)</span>
              Chọn khách hàng
            </label>
            <div class="mt-1">
              <Select
                value={formik.values.idkh}
                className=" w-full "
                required=""
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                type="password"
                name="idkh"
                options={listKH?.map((item) => ({
                  label: item.tenkh,
                  value: item.idkh,
                }))}
                onChange={handleChangeKhachHang}
                status={formik.errors.idkh ? 'error' : ''}
              />
              {formik.errors.idkh && (
                <span className="text-red-500">* Vui lòng chọn khách hàng</span>
              )}
            </div>
          </div>
          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-700" for="password">
              <span className="text-red-500">(*)</span>
              Chọn Gói khám
            </label>
            <div class="mt-1">
              <Select
                value={formik.values.idkhgk}
                className=" w-full "
                required=""
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                type="password"
                name="idkhgk"
                options={listGK?.map((item) => ({
                  label: item.crmGoiKham.tengoikham,
                  value: item.idkhgk,
                }))}
                onChange={(value) => formik.setFieldValue('idkhgk', value)}
                status={formik.errors.idkhgk ? 'error' : ''}
              />
              {formik.errors.idkhgk && (
                <span className="text-red-500">* Vui lòng chọn gói khám</span>
              )}
            </div>
          </div>
          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-700" for="username">
              <span className="text-red-500">(*)</span>
              Tên bệnh nhân
            </label>
            <div class="mt-1">
              <Input
                value={formik.values.tenbn}
                onChangeCapture={formik.handleChange}
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
                autocomplete="Tên bệnh nhân"
                type="text"
                name="tenbn"
                id="username"
                status={formik.errors.tenbn ? 'error' : ''}
              />
              {formik.errors.tenbn && (
                <span className="text-red-500">* Vui lòng nhập tên bệnh nhân</span>
              )}
            </div>
          </div>
          <div className="flex mt-2 ">
            <div class=" flex flex-col w-1/2 ">
              <span class="mr-3 text-gray-700 font-medium">
                <span className="text-red-500">(*)</span>Giới tính:
              </span>
              <Radio.Group
                name="gioitinh"
                onChange={handleChangeGioiTinh}
                value={formik.values.gioitinh}
                options={[
                  {
                    label: 'Nam',
                    value: 'Nam',
                  },
                  {
                    label: 'Nữ',
                    value: 'Nữ',
                  },
                ]}
              />
              {formik.errors.gioitinh && (
                <span className="text-red-500">* Vui lòng chọn giới tính</span>
              )}
            </div>

            <div class="w-1/2">
              <label class="block text-sm font-medium text-gray-700" for="dob">
                <span className="text-red-500">(*)</span>
                Ngày sinh
              </label>
              <div class="mt-1">
                <DatePicker
                  placeholder="Ngày sinh"
                  disabledDate={disabledDate}
                  name="ngaysinh"
                  format={dateFormat}
                  onChange={(date, dateString) => {
                    formik.setFieldValue(
                      'ngaysinh',
                      moment(dateString, dateFormat).format(dateFormatAPI),
                    )
                  }}
                  className="appearance-none block w-full  border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required=""
                  type="date"
                  id="dob"
                  status={formik.errors.ngaysinh ? 'error' : ''}
                />
              </div>
              {formik.errors.ngaysinh && (
                <span className="text-red-500">* Vui lòng chọn ngày sinh</span>
              )}
            </div>
          </div>

          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-700" for="email">
              Số điện thoại
            </label>
            <div class="mt-1">
              <Input
                value={formik.values.sodienthoai}
                onChangeCapture={formik.handleChange}
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required=""
                type="text"
                name="sodienthoai"
              />
              {/* {formik.errors.sodienthoai && (
                <span className="text-red-500">Vui lòng nhập số điện thoại</span>
              )} */}
            </div>
          </div>

          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-700" for="confirm-password">
              Ghi chú
            </label>
            <div class="mt-1">
              <Input
                value={formik.values.ghichu}
                onChange={formik.handleChange}
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                autocomplete="current-password"
                type="text"
                name="ghichu"
                id="confirm-password"
              />
            </div>
          </div>

          <div class="mt-6">
            <button
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="button"
              onClick={formik.handleSubmit}
            >
              Thêm bệnh nhân
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd
