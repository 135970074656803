import { https } from '../apiService'

export const lienheCRMService = {
  getLienHeByIdNv: (idnv, idct) => https.get(`CRMPhanCongLienHe/GetByCondition?IDNV=${idnv}&IDCT=${idct}`),
  getAllLienHe: () => https.get('CRMThongTinLienHe/GetAllCRMThongTinLienHe'),
  getCompanyByIDCompany: (IDCT) =>
    https.get('CRMThongTinLienHe/GetByIDCT', {
      params: {
        IDCT,
      },
    }),
  // deleteCty: (idcty) => https.get('CRMCongTy/GetAllCRMCongTy'),
  addThongTinLH: (form) => https.post('CRMThongTinLienHe/AddThongTinLienHe', form),
  editThongTinLH: (form) => https.put('CRMThongTinLienHe/UpdateThongTinLienHe', form),
  addChiTietLH: (form) => https.post('CRMChiTietLienHe/ThemChiTietLienHe', form),
  getThongTinLienHe: (idttlh) => https.get(`CRMThongTinLienHe/GetCongTyByIDTTLH?idTTLH=${idttlh}`),
  getCompanyContactByNV: (idnv) => https.get(`CRMPhanCongLienHe/GetByIDNV?IDNV=${idnv}`),
  // getThongTinLHById: (idttlh) => https.get(`CRMCongTy/GetCongTyByIDCT?idct=${idttlh}`),
}
