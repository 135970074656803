import { Button, DatePicker, Input, Modal, Select, Tabs, InputNumber } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { addVoucherSchema } from '../../../../schemas/addVoucherSchema'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import DanhSachTheGiamGia from './DanhSachTheGiamGia'
import DichVuGiamGia from './DichVuGiamGia'
import TabDoiTuong from './TabDoiTuong'
import TabChiNhanh from './TabChiNhanh'
import TabKhungGio from './TabKhungGio'
import TabTTV from './TabTTV'
import TabHangHoa from './TabHangHoa'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')

function ModalCreateVoucher({ showAddModal, setShowAddModal, getAllChuongTrinh, maChuongTrinh }) {
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [loading, setLoading] = useState(false)
  const [dataInTable, setDataInTable] = useState([])
  const [loaiGiaTri, setloaiGiaTri] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [checkTaoMa, setCheckTaoMa] = useState(false)
  const [dataDichVu, setDataDichVu] = useState([])
  const [dataDoiTuong, setDataDoiTuong] = useState([])
  const [dataChiNhanh, setDataChiNhanh] = useState([])
  const [dataKhungGio, setDataKhungGio] = useState([])
  const [dataTTV, setDataTTV] = useState([])
  const [dataHangHoa, setDataHangHoa] = useState([])

  const [nextSequence, setNextSequence] = useState(1) // State to hold the next sequential number

  useEffect(() => {
    let latestSequenceNumber = maChuongTrinh + 1
    setNextSequence(latestSequenceNumber)
  }, [])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mact: `VC${nextSequence.toString().padStart(4, '0')}`,
      tenct: '',
      batdau: null,
      ketthuc: null,
      idttdt: 0,
      idttcn: 0,
      idtttg: 0, //thời gian
      idttgt: 0, //giới tính
      idcombo: 0,
      loaigiatri: 1,
      giatri: null,
      soluongma: null,
      dodaima: null,
      kytudau: '',
      kytucuoi: '',
      ghichu: '',
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addVoucherSchema,
  })

  const handleCloseModal = () => {
    setShowAddModal(false)
    formik.resetForm()
  }
  const handleSubmitMaDoiTac = async () => {
    if (!formik.values.tenct || formik.values.tenct?.trim().length === 0) {
      formik.setFieldValue('tenct', null)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập tên chương trình!',
      })
      return
    }
    setLoading(true)
    try {
      await https.post(`Voucher/AddChuongTrinhDoiTac`, {
        mact: formik.values.mact,
        tenct: formik.values.tenct,
        ghichu: formik.values.ghichu,
        nguoitao: infoUser?.tenNV,
      })
      handleCloseModal()
      getAllChuongTrinh()
      ToastCus.fire({
        icon: 'success',
        title: 'Tạo mã đối tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Tạo mã đối tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }
  const handleSubmit = async (values, actions) => {
    if (values.giatri === null) formik.setFieldValue('giatri', 0)
    if (!values.batdau || !values.ketthuc) {
      !values.batdau &&
        formik.setErrors({
          batdau: 'require',
        })
      !values.ketthuc &&
        formik.setErrors({
          ketthuc: 'require',
        })
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn ngày bắt đầu và ngày kết thúc!',
      })
      return
    }
    if (values.batdau > values.ketthuc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc!',
      })
      return
    }
    if (!values.giatri) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị!',
      })
      return
    }
    if (values.idcombo === 0) {
      ////// nếu loại chương trình không phải combo => voucher thì xét thêm điều kiện của mã voucher
      //check all giatri equal
      if (dataInTable.length < 1) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng tạo mã voucher hoặc thêm mã voucher!',
        })
        return
      }
      const isAllGiatriEqual = dataInTable.every((item) => +item.giatri === +dataInTable[0].giatri)
      if (!isAllGiatriEqual) {
        ToastCus.fire({
          icon: 'error',
          title: 'Giá trị của mã voucher phải bằng nhau!',
        })
        return
      }
      //check giá trị trong table với giá trị nhập trong chương trình
      if (+dataInTable[0].giatri !== formik.values.giatri) {
        ToastCus.fire({
          icon: 'error',
          title: 'Giá trị chương trình và voucher không bằng nhau!',
        })
        return
      }
    }
    if (values.giatri > 100 && values.loaigiatri === 2) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị phải nhỏ hơn hoặc bằng hơn 100!',
      })
      return
    }
    if (values.idttdt === 1 && dataDoiTuong?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn đối tượng được áp dụng!',
      })
      return
    }
    if (values.idttcn === 1 && dataChiNhanh?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chi nhánh được áp dụng!',
      })
      return
    }
    if (values.idtttg === 1 && dataKhungGio?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn khung giờ được áp dụng!',
      })
      return
    }
    setLoading(true)
    try {
      const { data } = await https.post(`VoucherDanhSach/ThemVoucher`, {
        ...values,
        nguoitao: infoUser?.tenNV,
        idcn: PkDangNhap?.idChiNhanh,
        mavoucher:
          values.idcombo === 0 && dataInTable?.length > 0
            ? dataInTable?.map((item) => item.mavoucher)
            : [],
        services: dataDichVu?.map((item) => ({
          iddv: item.iddv,
          tendv: item.tenDichVu,
          madichvu: item.maDichVu,
          dvt: item.donVi,
          dongia: item.donGia,
          loaivoucherdichvu: item.idloaidv, //1,// 1 - Dịch vụ, 2- Xét nghiệm, 3 - Chẩn đóan hình ảnh
          nguoitao: infoUser?.tenNV,
        })),
        loaithe: dataTTV?.map((item) => ({
          idlt: item?.idlt,
          loaithe: item?.loaithe,
          tiensudung: item?.tiensudung,
        })),
        doituong:
          values.idttdt === 1 && dataDoiTuong?.length > 0
            ? dataDoiTuong?.map((item) => ({
                iddt: item?.iddt,
                tendoituong: item?.tendoituong,
                nguoitao: infoUser?.tenNV,
              }))
            : [],
        chinhanh:
          values.idttcn === 1 && dataChiNhanh?.length > 0
            ? dataChiNhanh?.map((item) => ({
                idcn: item?.idcn,
                tenchinhanh: item?.tenChiNhanh,
                nguoitao: infoUser?.tenNV,
              }))
            : [],
        hanghoa: dataHangHoa?.map((item) => ({
          idthuoc: item?.idthuoc,
          mathuoc: item?.mathuoc,
          tenthuoc: item?.tenbietduoc, //
          giaban: item?.giaban,
          dvt: item?.dvt,
        })),
        thoigian:
          values.idtttg === 1 && dataKhungGio?.length > 0
            ? dataKhungGio?.map((item) => ({
                tugio: item?.tugio,
                dengio: item?.dengio,
                nguoitao: infoUser?.tenNV,
              }))
            : [],
      })
      handleCloseModal()
      getAllChuongTrinh()
      ToastCus.fire({
        icon: 'success',
        title: 'Tạo chương trình thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Tạo chương trình thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`Voucher/GetVoucherLoaiGiaTri`)
        setloaiGiaTri(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (formik.values.idcombo === 1 || formik.values.idcombo === 3) {
      formik.setFieldValue('loaigiatri', 2)
      // formik.setFieldValue('idttdt', 0)
      // formik.setFieldValue('idttcn', 0)
    }
    if (formik.values.idttdt === 0) setDataDoiTuong([])
    if (formik.values.idttcn === 0) setDataChiNhanh([])
    if (formik.values.idtttg === 0) setDataKhungGio([])
  }, [
    formik?.values?.idcombo,
    formik?.values?.idttdt,
    formik?.values?.idttcn,
    formik?.values?.idtttg,
  ])
  // useEffect(() => {
  //   if (formik.values.idttdt === 0) setDataDoiTuong([])
  //   if (formik.values.idttcn === 0) setDataChiNhanh([])
  // }, [formik?.values?.idttdt,formik?.values?.idttcn,])
  const handleLoaiGiaTriChange = (value) => {
    formik.setFieldValue('loaigiatri', value)
    formik.setFieldValue('giatri', 0)
  }
  const handleCreateMaVoucher = async () => {
    if (formik.values.giatri === null) formik.setFieldValue('giatri', 0)
    if (formik.values.soluongma === null) formik.setFieldValue('soluongma', 0)
    if (formik.values.dodaima === null) formik.setFieldValue('dodaima', 0)
    formik.setErrors({
      soluongma: !formik.values.soluongma ? 'require' : null,
      dodaima: !formik.values.dodaima ? 'require' : null,
      giatri: !formik.values.giatri ? 'require' : null,
    })
    // if (!formik.values.dodaima) formik.setErrors({ dodaima: 'require', })
    // if (!formik.values.giatri) formik.setErrors({ giatri: 'require', })
    if (!formik.values.soluongma || !formik.values.dodaima) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập số lượng và độ dài mã!',
      })
      return
    }
    if (+formik.values.dodaima <= formik.values.kytudau.length + formik.values.kytucuoi.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Độ dài mã không hợp lệ!',
      })
      return
    }
    if (!formik.values.giatri) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị!',
      })
      return
    }
    if (formik.values.giatri > 100 && formik.values.loaigiatri === 2) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị phải nhỏ hơn hoặc bằng hơn 100!',
      })
      return
    }
    setLoadingTable(true)
    try {
      const { data } = await https.post(`VoucherDanhSach/ListVoucher`, {
        soluongMa: formik.values.soluongma,
        doDaiMa: formik.values.dodaima,
        kyTuDau: formik.values.kytudau,
        kyTuCuoi: formik.values.kytucuoi,
      })
      const formatData = data.map((item) => ({
        mavoucher: item,
        giatri: formik.values.giatri,
        nguoitao: infoUser.tenNV,
        ngaytao: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        nguoikichhoat: '',
        ngaykichhoat: '',
        trangthai: 1,
      }))
      setDataInTable((prev) => [...prev, ...formatData])
      setCheckTaoMa(true)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
    } finally {
      setLoadingTable(false)
    }
  }
  const optTuyChon = [
    {
      key: 0,
      value: 0,
      label: 'Tất cả',
    },
    {
      key: 1,
      value: 1,
      label: 'Tùy chỉnh',
    },
  ]
  const optLoaiCT = [
    {
      value: 0,
      label: 'Voucher',
    },
    {
      value: 1,
      label: 'Combo',
    },
    {
      value: 2,
      label: 'Mã Đối tác',
    },
    {
      value: 3,
      label: 'Giảm giá',
    },
  ]
  return (
    <Modal
      style={{
        top: 20,
      }}
      width={950}
      className="text-center"
      title="Thêm chương trình"
      okText="Xác nhận"
      cancelText="Huỷ bỏ"
      open={showAddModal}
      onOk={() => {
        return formik.values.idcombo === 2 ? handleSubmitMaDoiTac() : formik.handleSubmit()
      }}
      onCancel={handleCloseModal}
      maskClosable={false}
      confirmLoading={loading}
      okButtonProps={{ disabled: loadingTable }}
    >
      <form className="text-start mt-5">
        <div
          className={`grid ${formik.values.idcombo !== 2 ? 'grid-rows-5' : 'grid-rows-2'} gap-1`}
        >
          <div className="grid grid-cols-6">
            <div className="font-semibold w-full text-end pr-1">
              <span className="text-red-500 text-xs">(*) </span>Tên chương trình:
            </div>
            <div className="col-span-3">
              <Input
                status={formik.errors.tenct ? 'error' : ''}
                name="tenct"
                value={formik.values.tenct}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
            <div className="font-semibold w-full text-end pr-1">
              <span className="text-red-500 text-xs">(*) </span>Mã chương trình:
            </div>
            <div>
              <Input
                status={formik.errors.mact ? 'error' : ''}
                name="mact"
                value={formik.values.mact}
                onChange={formik.handleChange}
                size="small"
                readOnly
                variant="filled"
              />
            </div>
          </div>
          <div className="grid grid-cols-6">
            <div className="font-semibold w-full text-end pr-1">
              {/* <span className="text-red-500 text-xs">(*) </span> */}
              Ghi chú:
            </div>
            <div className="col-span-3">
              <Input
                name="ghichu"
                value={formik.values.ghichu}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
            {formik.values.idcombo !== 2 ? (
              <>
                <div className="font-semibold w-full text-end pr-1">Đối tượng:</div>
                <div>
                  <Select
                    onChange={(value) => {
                      formik.setFieldValue('idttdt', value)
                    }}
                    value={formik.values.idttdt}
                    name="idttdt"
                    options={optTuyChon}
                    className="w-full"
                    placeholder="Chọn ĐT áp dụng..."
                    size="small"
                    // disabled={formik.values.idcombo === 1}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="font-semibold w-full text-end pr-1">Loại chương trình:</div>
                <div>
                  <Select
                    disabled={checkTaoMa}
                    // onChange={handleLoaiGiaTriChange}
                    onChange={(value) => {
                      formik.setFieldValue('idcombo', value)
                    }}
                    value={formik.values.idcombo}
                    name="idcombo"
                    options={optLoaiCT}
                    className="w-full"
                    placeholder="Chọn loại chương trình..."
                    size="small"
                  />
                </div>
              </>
            )}
          </div>
          {formik.values.idcombo !== 2 && (
            <>
              <div className="grid grid-cols-6">
                <div className="font-semibold w-full text-end pr-1">
                  <span className="text-red-500 text-xs">(*) </span>Bắt đầu:
                </div>
                <div>
                  <DatePicker
                    placeholder="Chọn ngày..."
                    status={formik.errors.batdau ? 'error' : ''}
                    name="batdau"
                    size="small"
                    className="w-full"
                    minDate={dayjs(now)}
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      formik.setFieldValue(
                        'batdau',
                        moment(dateString, dateFormat).format(dateFormatAPI),
                      )
                    }}
                  />
                </div>
                <div className="font-semibold w-full text-end pr-1">
                  <span className="text-red-500 text-xs">(*) </span>Kết thúc:
                </div>
                <div>
                  <DatePicker
                    placeholder="Chọn ngày..."
                    status={formik.errors.ketthuc ? 'error' : ''}
                    minDate={formik.values.batdau ? dayjs(formik.values.batdau) : dayjs(now)}
                    format={dateFormat}
                    name="ketthuc"
                    size="small"
                    className="w-full"
                    onChange={(date, dateString) => {
                      formik.setFieldValue(
                        'ketthuc',
                        moment(dateString, dateFormat).format(dateFormatAPI),
                      )
                    }}
                  />
                </div>
                <div className="font-semibold w-full text-end pr-1">Chi nhánh:</div>
                <div>
                  <Select
                    // onChange={handleLoaiGiaTriChange}
                    onChange={(value) => {
                      formik.setFieldValue('idttcn', value)
                    }}
                    value={formik.values.idttcn}
                    name="idttcn"
                    options={optTuyChon}
                    className="w-full"
                    placeholder="Chọn CN áp dụng..."
                    size="small"
                    // disabled={formik.values.idcombo === 1}
                  />
                </div>
              </div>
              {formik.values.idcombo === 0 && (
                <>
                  <div className="grid grid-cols-6">
                    <div className="font-semibold w-full text-end pr-1">Kí tự đầu:</div>
                    <div>
                      <Input
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        status={formik.errors.kytudau ? 'error' : ''}
                        name="kytudau"
                        value={formik.values.kytudau}
                        onChange={formik.handleChange}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">Kí tự cuối:</div>
                    <div>
                      <Input
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        status={formik.errors.kytucuoi ? 'error' : ''}
                        name="kytucuoi"
                        value={formik.values.kytucuoi}
                        onChange={formik.handleChange}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      {/* <span className="text-red-500 text-xs">(*) </span> */}
                      Loại giá trị:
                    </div>
                    <div>
                      <Select
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        onChange={handleLoaiGiaTriChange}
                        value={formik.values.loaigiatri}
                        //value={formik.values.loaigiatri === 2 ? 'Phần trăm' : 'Thành tiền'}
                        options={loaiGiaTri?.map((value) => ({
                          label: value.ten,
                          value: value.id,
                        }))}
                        className="w-full"
                        placeholder="Chọn loại giá trị..."
                        size="small"
                        name="loaigiatri"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-6">
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>
                      Số lượng mã:
                    </div>
                    <div>
                      <Input
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        type="number"
                        step={1}
                        min={0}
                        // status={!formik.values.soluongma ? 'error' : ''}
                        status={
                          !formik.values.soluongma
                            ? formik.values.soluongma === null
                              ? ''
                              : 'error'
                            : ''
                        }
                        name="soluongma"
                        value={formik.values.soluongma}
                        onChange={formik.handleChange}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>
                      Độ dài mã:
                    </div>
                    <div>
                      <Input
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        type="number"
                        step={1}
                        min={0}
                        status={
                          !formik.values.dodaima
                            ? formik.values.dodaima === null
                              ? ''
                              : 'error'
                            : ''
                        }
                        name="dodaima"
                        value={formik.values.dodaima}
                        onChange={formik.handleChange}
                        size="small"
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>Giá trị:
                    </div>
                    <div>
                      <InputNumber
                        className="w-full"
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        step={1}
                        min={0}
                        formatter={(value) => {
                          const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                          return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        max={999999999}
                        defaultValue={0}
                        // status={!formik.values.giatri ? 'error' : ''}
                        status={
                          !formik.values.giatri
                            ? formik.values.giatri === null
                              ? ''
                              : 'error'
                            : ''
                        }
                        name="giatri"
                        value={formik.values.giatri}
                        onChange={(value) => {
                          if (value > 100 && formik.values.loaigiatri === 2) {
                            // formik.setFieldValue('giatri', 100)
                            ToastCus.fire({
                              icon: 'error',
                              title: 'Giá trị tối đa 100%',
                            })
                            return
                          }
                          formik.setFieldValue('giatri', value)
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="grid grid-cols-6">
                {/* <div className="col-span-4"></div> */}
                <div className="font-semibold w-full text-end pr-1">Loại chương trình:</div>
                <div>
                  <Select
                    disabled={checkTaoMa}
                    // onChange={handleLoaiGiaTriChange}
                    onChange={(value) => {
                      formik.setFieldValue('idcombo', value)
                    }}
                    value={formik.values.idcombo}
                    name="idcombo"
                    options={optLoaiCT}
                    className="w-full"
                    placeholder="Chọn loại chương trình..."
                    size="small"
                  />
                </div>
                {formik.values.idcombo === 0 && (
                  <>
                    <div className="font-semibold w-full text-end pr-1">Khung giờ:</div>
                    <div>
                      <Select
                        // onChange={handleLoaiGiaTriChange}
                        onChange={(value) => {
                          formik.setFieldValue('idtttg', value)
                        }}
                        value={formik.values.idtttg}
                        name="idtttg"
                        options={optTuyChon}
                        className="w-full"
                        placeholder="Chọn KG áp dụng..."
                        size="small"
                        // disabled={formik.values.idcombo === 1}
                      />
                    </div>
                    <div className="font-semibold w-full text-end pr-1">Giới tính:</div>
                    <div>
                      <Select
                        // onChange={handleLoaiGiaTriChange}
                        onChange={(value) => {
                          formik.setFieldValue('idttgt', value)
                        }}
                        value={formik.values.idttgt}
                        name="idttgt"
                        options={[
                          {
                            key: 0,
                            value: 0,
                            label: 'Tất cả',
                          },
                          {
                            key: 1,
                            value: 1,
                            label: 'Nam',
                          },
                          {
                            key: 2,
                            value: 2,
                            label: 'Nữ',
                          },
                          {
                            key: 3,
                            value: 3,
                            label: 'Khác',
                          },
                        ]}
                        className="w-full"
                        placeholder="Chọn GT áp dụng..."
                        size="small"
                        // disabled={formik.values.idcombo === 1}
                      />
                    </div>
                  </>
                )}
                {(formik.values.idcombo === 1 || formik.values.idcombo === 3) && (
                  <>
                    <div className="font-semibold w-full text-end pr-1">
                      {/* <span className="text-red-500 text-xs">(*) </span> */}
                      Loại giá trị:
                    </div>
                    <div>
                      <Input
                        // status={formik.errors.tenct ? 'error' : ''}
                        // name="tenct"
                        // onChange={formik.handleChange}
                        readOnly
                        variant="filled"
                        value={formik.values.loaigiatri === 2 ? 'Phần trăm' : 'Thành tiền'}
                        size="small"
                      />
                      {/* <Select
                  disabled={true}
                  style={{ color: 'black' }}
                  onChange={handleLoaiGiaTriChange}
                  // defaultValue={1}
                  options={loaiGiaTri?.map((value) => ({
                    label: value.ten,
                    value: value.id,
                  }))}
                  className="w-full"
                  placeholder="Chọn loại giá trị"
                  size="small"
                  name="loaigiatri"
                /> */}
                    </div>
                    <div className="font-semibold w-full text-end pr-1">
                      <span className="text-red-500 text-xs">(*) </span>Giá trị:
                    </div>
                    <div>
                      <InputNumber
                        className="w-full"
                        disabled={checkTaoMa}
                        style={{ color: 'black' }}
                        step={1}
                        min={0}
                        formatter={(value) => {
                          const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                          return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        max={999999999}
                        defaultValue={0}
                        // status={!formik.values.giatri ? 'error' : ''}
                        status={
                          !formik.values.giatri
                            ? formik.values.giatri === null
                              ? ''
                              : 'error'
                            : ''
                        }
                        name="giatri"
                        value={formik.values.giatri}
                        onChange={(value) => {
                          if (value > 100 && formik.values.loaigiatri === 2) {
                            ToastCus.fire({
                              icon: 'error',
                              title: 'Giá trị tối đa 100%',
                            })
                            return
                          }
                          formik.setFieldValue('giatri', value)
                        }}
                        size="small"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {formik.values.idcombo === 0 && (
          <>
            <div className="w-full flex justify-end items-center mt-2">
              <Button
                disabled={checkTaoMa}
                type="primary"
                className=""
                onClick={handleCreateMaVoucher}
              >
                Tạo mã
              </Button>
            </div>
          </>
        )}
      </form>
      {formik.values.idcombo !== 2 && (
        <>
          <Tabs
            className="h-full"
            defaultActiveKey="1"
            items={[
              formik.values.idcombo === 0 && {
                label: 'Voucher',
                key: '1',
                children: (
                  <div className="h-full">
                    <DanhSachTheGiamGia
                      formik={formik}
                      dataInTable={dataInTable}
                      setDataInTable={setDataInTable}
                      loadingTable={loadingTable}
                      setLoadingTable={setLoadingTable}
                      setCheckTaoMa={setCheckTaoMa}
                      checkTaoMa={checkTaoMa}
                    />
                  </div>
                ),
              },
              {
                label: 'Dịch vụ',
                key: '2',
                children: (
                  <div className="h-full">
                    <DichVuGiamGia dataDichVu={dataDichVu} setDataDichVu={setDataDichVu} />
                  </div>
                ),
              },
              {
                label: 'Thẻ thành viên',
                key: '3',
                children: (
                  <div className="h-full">
                    <TabTTV dataTTV={dataTTV} setDataTTV={setDataTTV} />
                  </div>
                ),
              },
              {
                label: 'Hàng hóa',
                key: '4',
                children: (
                  <div className="h-full">
                    <TabHangHoa dataHangHoa={dataHangHoa} setDataHangHoa={setDataHangHoa} />
                  </div>
                ),
              },
              formik.values.idttdt === 1 && {
                label: 'Đối tượng',
                key: '5',
                children: (
                  <div className="h-full">
                    <TabDoiTuong dataDoiTuong={dataDoiTuong} setDataDoiTuong={setDataDoiTuong} />
                  </div>
                ),
              },
              formik.values.idttcn === 1 && {
                label: 'Chi nhánh',
                key: '6',
                children: (
                  <div className="h-full">
                    <TabChiNhanh dataChiNhanh={dataChiNhanh} setDataChiNhanh={setDataChiNhanh} />
                  </div>
                ),
              },
              formik.values.idtttg === 1 && {
                label: 'Khung giờ',
                key: '7',
                children: (
                  <div className="h-full">
                    <TabKhungGio dataKhungGio={dataKhungGio} setDataKhungGio={setDataKhungGio} />
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  )
}

ModalCreateVoucher.propTypes = {}

export default ModalCreateVoucher
