import { Checkbox, Input, Select, Button } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { drugstoreService } from '../../../../../services/drugstore/drugstoreService'
import * as typeAction from '../../../../../store/constants/constants'
import ToastCus from '../../../../../utils/Toast'
import styled from 'styled-components'
import _, { cloneDeep } from 'lodash'
const BtnApply = styled(Button)`
  color: #1da57a;
  border-color: #1da57a;
  background-color: #fff;

  &:hover {
    color: #fff !important;
    border-color: #1da57acc !important;
    background-color: #1da57acc !important;
  }
`
const BtnUnApply = styled(Button)`
  color: #ff4d4f;
  border-color: #ff4d4f;
  background-color: #fff;

  &:hover {
    color: #fff !important;
    border-color: #ff4d4f !important;
    background-color: #ff4d4f !important;
  }
`
const Pay = ({ totalPrice, formik_Billing }) => {
  const dispatch = useDispatch()
  const { BNThuoc, infoBN, service, BNDetail, BNTT, infoVC } = useSelector(
    (state) => state.drugstoreReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [maVoucher, setMaVoucher] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleSelected = (name) => (value) => {
    formik_Billing.setFieldValue(name, value)
  }
  const handleCheckboxChange = () => {
    formik_Billing.setFieldValue('hoaDonDienTu', formik_Billing.values.hoaDonDienTu === 1 ? 0 : 1)
  }
  const handleUnApplyVoucher = (event) => {
    // event.stopPropagation()
    // console.log('Unapply button clicked')
    // return
    try {
      setLoading(true)
      dispatch({
        type: typeAction.DISPATCH_INFO_VOUCHER_BANTOA,
        payload: null,
      })
      ToastCus.fire({
        icon: 'success',
        title: 'Hủy áp dụng voucher thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }
  const handleApplyVoucher = async (event) => {
    // event.stopPropagation()
    // console.log('Apply button clicked')
    // return
    if (
      !service?.DanhSachThuoc?.filter(
        (item) => item?.idnhomthuoc === 179 || item?.idnhomthuoc === 205,
      )?.length
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Không tìm thấy hàng hóa có thể áp dụng voucher!',
      })
      return
    }
    // console.log(service?.DanhSachThuoc, maVoucher)
    // return
    try {
      setLoading(true)
      let formatData = service?.DanhSachThuoc?.map((item) => ({
        idthuoc: item?.idthuoc,
        dongia: item?.dongia * item?.soluong,
        mavoucher: null, ///////////////
        ptgiam: Math.round(0),
        tiengiam: Math.round(0),
        thanhtien: Math.round(item?.dongia * item?.soluong),
        trangthaitt: 1,
        nguoikichhoat: infoUser?.tenNV,
        iddt: infoBN?.idDoiTuong ?? -1,
        idgt:
          ((infoBN?.gioiTinh === `Nam` && 1) ||
            (infoBN?.gioiTinh === `Nữ` && 2) ||
            (infoBN?.gioiTinh === `Khác` && 3)) ??
          -1,
        idcn: PkDangNhap?.idChiNhanh,
        // loaivoucherdichvu:
        //   (item.type === 'KB' && 1) || (item.type === 'XN' && 2) || (item.type === 'CDHA' && 3), /////////
      }))
      let listMaVC = []
      const vouchers = maVoucher?.split(',')?.filter((vc) => _.isString(vc) && vc?.length > 0)
      if (vouchers?.filter((item, index) => vouchers?.indexOf(item) !== index)?.length > 0) {
        ToastCus.fire({
          icon: 'error',
          title: `Voucher bị trùng!`,
        })
        return
      }
      for (let i = 0; i < vouchers.length; i++) {
        const vc = vouchers[i]
        listMaVC.push(vc)
        formatData = formatData?.map((item) => ({
          ...item,
          mavoucher: vc, // gán mã voucher hiện tại
        }))
        const { data } = await drugstoreService.postApplyVoucherHangHoa(formatData) // https.post(`VoucherDanhSach/ApplyVoucher`, formatData);
        formatData = [...data] // cập nhật formatData với kết quả từ API
      }
      dispatch({
        type: typeAction.DISPATCH_INFO_VOUCHER_BANTOA,
        payload: {
          tongGiam: formatData?.reduce((tong, item) => tong + item?.tiengiam, 0),
          chuoiMaVc: maVoucher,
          listMaVc: listMaVC,
          listVTHHGiam: formatData,
          listVTHHTruocGiam: service?.DanhSachThuoc,
        },
      })
      ToastCus.fire({
        icon: 'success',
        title: 'Áp dụng voucher thành công!',
      })
    } catch (error) {
      console.log(error)
      const message = error?.response?.data?.message
      ToastCus.fire({
        icon: 'error',
        title: (message ? `${message}!\n` : '') + 'Áp dụng voucher thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!infoVC) setMaVoucher(null)
  }, [infoVC])
  return (
    <div className="border rounded ">
      <div className="border-b flex text-lg font-semibold text-gray-500 p-2">
        <h2 className="w-1/2">Thanh toán</h2>
        <h2 className="w-1/2 text-black text-center">
          Tổng trả:{' '}
          <span>
            {formattedNumber(totalPrice - (BNTT?.tiengiamgia ?? infoVC?.tongGiam ?? 0))} VNĐ
          </span>
        </h2>
      </div>

      <div className=" bg-slate-100 p-2">
        {/* <div className="flex gap-2">
          <Input placeholder="Nhập mã khuyển mãi" />
          <Button className="w-40" size="small" variant="contained">
            Áp dụng
          </Button>
        </div> */}
        <div className="flex gap-2 mt-2">
          <div className="w-[400px]  ">
            <Select
              onChange={handleSelected('idPhuongThuc')}
              defaultValue={formik_Billing.values.idPhuongThuc}
              className="w-full"
              size="small"
              options={[
                { label: 'Thanh toán', value: 1 },
                { label: 'Công nợ', value: 2 },
              ]}
            />
          </div>
          <div>
            <label className="mr-2">Xuất HĐ: </label>
            <Checkbox
              checked={formik_Billing.values.hoaDonDienTu === 1}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
      </div>
      <div className="text-base flex flex-col gap-3 p-3 pl-10 pr-10 h-[13.6rem]">
        <div className="flex">
          <label className="w-1/2">Tổng tiền:</label>
          <h1 className="w-1/2 text-end">{formattedNumber(totalPrice)} VNĐ</h1>
        </div>
        <div className="flex">
          <label className="w-1/2"> % Giảm:</label>
          <h1 className="w-1/2 text-end">0 VNĐ</h1>
        </div>
        <div className="flex">
          <label className="w-1/2">Tiền giảm:</label>
          <h1 className="w-1/2 text-end">0 VNĐ</h1>
        </div>
        <div className="flex">
          <label className="w-1/2">Giảm giá Voucher:</label>
          <h1 className="w-1/2 text-end">
            {formattedNumber(BNTT?.tiengiamgia ?? infoVC?.tongGiam ?? 0)} VNĐ
          </h1>
        </div>
        <div className="flex">
          <label className="w-1/2 flex justify-between items-center">
            Voucher:
            <span className="w-full flex justify-center items-center">
              <BtnApply
                name="apply"
                loading={loading}
                onClick={handleApplyVoucher}
                className={`h-[1.5rem] ${maVoucher?.length > 0 && service?.DanhSachThuoc?.length > 0 && !infoVC && !BNTT ? '' : 'hidden'}`}
                icon={<CheckOutlined style={{ fontSize: 15 }} />}
              ></BtnApply>
              <BtnUnApply
                name="unapply"
                loading={loading}
                onClick={handleUnApplyVoucher}
                // className="h-[1.5rem]"
                className={`h-[1.5rem] ${infoVC ? '' : 'hidden'}`}
                icon={<CloseOutlined style={{ fontSize: 15 }} />}
              ></BtnUnApply>
            </span>
          </label>
          <Input
            disabled={loading || !service || infoVC || BNTT}
            style={{ color: 'black' }}
            size="small"
            className="w-full"
            value={BNTT?.mavoucher ?? maVoucher}
            onChange={(e) =>
              setMaVoucher(
                e.target.value
                  ?.trim()
                  ?.replaceAll(' ', '')
                  ?.replaceAll(';', ',')
                  ?.replaceAll('-', ',')
                  ?.replaceAll('/', ','),
              )
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Pay
