import { https } from '../apiService'

export const detailCardService = {
  postListTheDichVu: (list) => https.post(`TheDichVu/AddTheDichVu`, list),
  getListTheDichVuByIdLT: (idlt) => https.get(`TheDichVu/GetTheDichVuByIDLT/${idlt}`),
  getInfoLoaiThe: (idlt) => https.get(`TheLoai/GetLoaiTheByID?idlt=${idlt}`),
  putListTheDichVu: (list, idlt) => https.put(`TheDichVu/UpdateTheDichVu?idlt=${idlt}`, list),
  // deleteListTheDichVu: (list) => https.delete(`TheDichVu/DeleteTheDichVu`, list),
  putInfoLoaiThe: (idlt, form) => https.put(`TheLoai/UpdateLoaiThe/${idlt}`, form),
  // getDichVuByIdNhomDV: (idnhom) => https.get(`DichVu/GetDichVuByIdNhomDV?idNhomDv=${idnhom}`),
  getListXNGiaTong: (idcn) =>
    https.get(`XetNghiemDanhMuc/GetGiaTongXetNghiemDanhMucByIDCN`, { params: { idcn } }),
  getListChiNhanhByLoaiThe: (idlt, idct) =>
    https.get(`TheLoai/GetChiNhanhByLoaiThe`, { params: { idlt, idct } }),
  putInfoGiaChiNhanhLoaiThe: (form) => https.put(`TheLoai/UpdateTheLoaiChiNhanh`, form),
  getXetNghiemByCTy: (id) => https.get(`XetNghiemDanhMuc/SearchXetNghiemDanhMucByIDCT?idct=${id}`),
}
