import { FilterOutlined, PrinterOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Image,
  Input,
  Select,
  Table,
  Tabs,
} from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { debounce } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import daymatphai from '../../../../assets/images/benhanmat/daymatphai.jpg'
import daymattrai from '../../../../assets/images/benhanmat/daymattrai.jpg'
import matphai from '../../../../assets/images/benhanmat/matphai.jpg'
import mattrai from '../../../../assets/images/benhanmat/mattrai.jpg'
import { https } from '../../../../services/apiService'
import { childrenClinicService } from '../../../../services/childrenClinic/childrenClinicService'
import ToastCus from '../../../../utils/Toast'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { benhAnMat } from '../../../../utils/report/benhAnMat'
import 'moment/locale/vi'
moment.locale('vi')

const formatNameCompare = (string) => {
  const dotIndex = string.indexOf('.')
  if (dotIndex !== -1) {
    return string.slice(string.indexOf('.') + 1).trim()
  }

  return string.trim()
}

const columnsDV = [
  {
    title: 'Mã chỉ định',
    dataIndex: 'maChiDinh',
    key: 'maChiDinh',
    align: 'center',
    className: 'text-sm',
    width: 150,
  },

  {
    title: 'Tên chỉ định',
    dataIndex: 'tenChiDinh',
    key: 'tenChiDinh',
    align: 'left',
    className: 'text-sm',
  },
  {
    title: 'ĐVT',
    dataIndex: 'dvt',
    key: 'dvt',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Đơn giá',
    dataIndex: 'dongia',
    key: 'dongia',
    align: 'right',
    className: 'text-sm',
    width: 150,
    render: (text) => formatNumberVND(text),
  },
  {
    title: 'Tiền giảm giá',
    dataIndex: 'tiengiamgia',
    key: 'tiengiamgia',
    align: 'right',
    className: 'text-sm',
    width: 150,
    render: (text) => formatNumberVND(text),
  },
  {
    title: 'Tiền thanh toán',
    dataIndex: 'tienthanhtoan',
    key: 'tienthanhtoan',
    align: 'right',
    className: 'text-sm',
    width: 150,
    render: (text) => formatNumberVND(text),
  },
]

const columnsThuoc = [
  {
    title: 'Mã thuốc',
    dataIndex: 'mathuoc',
    align: 'left',
    key: 'mathuoc',
    align: 'center',
    className: 'text-sm',
    width: 150,
  },
  {
    title: 'Tên thuốc',
    dataIndex: 'tenbietduoc',
    align: 'left',
    key: 'tenbietduoc',
    align: 'left',
    className: 'text-sm',
  },
  {
    title: 'ĐVT',
    dataIndex: 'dvt',
    key: 'dvt',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Liều dùng',
    dataIndex: 'lieudung',
    key: 'lieudung',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Số lần/ngày',
    dataIndex: 'solanngay',
    key: 'solanngay',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'SL kê',
    dataIndex: 'soluongke',
    key: 'soluongke',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'SL bán',
    dataIndex: 'soluongban',
    key: 'soluongban',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Đơn giá',
    dataIndex: 'dongia',
    key: 'dongia',
    align: 'right',
    className: 'text-sm',
    render: (text) => formatNumberVND(text),
    width: 120,
  },
  {
    title: 'Thành tiền',
    dataIndex: 'thanhtien',
    key: 'thanhtien',
    align: 'right',
    className: 'text-sm',
    render: (text) => formatNumberVND(text),
    width: 120,
  },
]

const listItemClasses =
  'flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200'
const activeListItemClasses =
  'flex items-center border py-2 rounded-md bg-blue-200 cursor-pointer -translate-y-1 duration-200'

function HistoryKhamBenh({ open }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN } = useSelector((state) => state.traCuuBnMatReducer)
  const [listLichSu, setListLichSu] = useState([])
  const [listLichSuDefault, setListLichSuDefault] = useState([])
  const [toaThuoc, setToaThuoc] = useState([])
  const [listDV, setListDV] = useState([])
  const [activeItemId, setActiveItemId] = useState(null) // State to manage active item
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState(null)
  const [listLyDoKhongKeToa, setListLyDoKhongKeToa] = useState([])
  const [ChanDoan, setChanDoan] = useState([])
  const [infoBNTTC, setInfoBNTTC] = useState(null)
  const [infoBNMat, setInfoBNMat] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [nguoinha, setNguoiNha] = useState([])

  const [bmi, setBmi] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('NguoiNhaBenhNhan/GetAllNguoiNha')
        setNguoiNha(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (infoBNTTC) {
      const chieucao = infoBNTTC.chieucao / 100
      const bmi = infoBNTTC.cannang / (chieucao * chieucao)
      setBmi(bmi.toFixed(2))
    }
  }, [infoBNTTC])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (infoBN) {
        try {
          const { data } = await https.get(
            `BenhNhanThongTinChung/GetLichSuKhamBenhMat/${infoBN.idbn}`,
          )
          //filter k lay ban le
          const dataFilter = data.filter((item) => item.ngaykham !== null)
          setListLichSu(dataFilter)
          setListLichSuDefault(dataFilter)
        } catch (error) {
          console.log(error)
        }
      }
    })()
  }, [infoBN])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nguoiSua: infoUser?.tenNV,
      idbnttc: infoBNTTC?.idbnttc,
      ngaytaikham: infoBNTTC?.ngaytaikham,
      bskham: infoBNTTC?.bskham,
      lydokham: infoBNTTC?.lydokham,
      benhsu: infoBNTTC?.benhsu,
      trieuchung: infoBNTTC?.trieuchung,
      chandoan: infoBNTTC?.chandoan,
      idchandoan: infoBNTTC?.idchandoan,
      loidan: infoBNTTC?.loidan,
      tiensubanthan: infoBNTTC?.tiensubanthan,
      diung: infoBNTTC?.diung,
      quatrinhbenhly: infoBNTTC?.quatrinhbenhly,
      tieusugiadinh: infoBNTTC?.tieusugiadinh,
      toanthan: infoBNTTC?.toanthan,
      cacbophan: infoBNTTC?.cacbophan,
      chandoanbandau: infoBNTTC?.chandoanbandau,
      idicD10BANDAU: infoBNTTC?.idicD10BANDAU,
      daxuly: infoBNTTC?.daxuly,
      chandoanravien: infoBNTTC?.chandoanravien,
      idicD10RAVIEN: infoBNTTC?.idicD10RAVIEN,
      dieutringoaitru: infoBNTTC?.dieutringoaitru,
      dienbienlamsang: infoBNTTC?.dienbienlamsang,
      benhkemtheo: infoBNTTC?.benhkemtheo,
      ppdieutri: infoBNTTC?.ppdieutri,
      ttlucravien: infoBNTTC?.ttlucravien,
      huongxuly: infoBNTTC?.huongxuly,
      lydokolaythuoc: infoBNTTC?.lydokolaythuoc,
      tinhtrangravien: infoBNTTC?.tinhtrangravien,
      benhlykhac: infoBNTTC?.benhlykhac,
      mat: infoBNTTC?.mat,
      ranghammat: infoBNTTC?.ranghammat,
      taimuihong: infoBNTTC?.taimuihong,
      coxuongkhop: infoBNTTC?.coxuongkhop,
      thankinh: infoBNTTC?.thankinh,
      thantietnieu: infoBNTTC?.thantietnieu,
      tieuhoa: infoBNTTC?.tieuhoa,
      hohap: infoBNTTC?.hohap,
      tuanhoan: infoBNTTC?.tuanhoan,
      bienchung: infoBNTTC?.bienchung,
      kqcls: infoBNTTC?.kqcls,
      idicD10BENHKEMTHEO: infoBNTTC?.idicD10BENHKEMTHEO,
      idicD10BIENCHUNG: infoBNTTC?.idicD10BIENCHUNG,
      tennguoinha: infoBNTTC?.tennguoinha,
      dienthoainguoinha: infoBNTTC?.dienthoainguoinha,
      nguoinha: infoBNTTC?.nguoinha,
      // thông tin sinh trắc
      chieucao: infoBNTTC?.chieucao,
      cannang: infoBNTTC?.cannang,
      mach: infoBNTTC?.mach,
      huyetap: infoBNTTC?.huyetap,
      nhietdo: infoBNTTC?.nhietdo,
      nhiptho: infoBNTTC?.nhiptho,
      spo2: infoBNTTC?.spO2,
    },
    onSubmit: (values) => handleSubmit(values),
  })

  const formikBnMat = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: infoBNTTC?.idbnttc,
      nguoisua: infoUser?.idnv,
      tiensutaimat: infoBNMat?.TIENSUTAIMAT,
      chandoanravieN_MT: infoBNMat?.CHANDOANRAVIEN_MT,
      idchandoanravieN_MT: infoBNMat?.IDCHANDOANRAVIEN_MT,
      chandoanravieN_MP: infoBNMat?.CHANDOANRAVIEN_MP,
      idchandoanravieN_MP: infoBNMat?.IDCHANDOANRAVIEN_MP,
      cacxetnghiemcanlam: infoBNMat?.CACXETNGHIEMCANLAM,
      tomtatbenhan: infoBNMat?.TOMTATBENHAN,
      tienluonggan: infoBNMat?.TIENLUONGGAN,
      tienluongxa: infoBNMat?.TIENLUONGXA,
      tuvangiaoducsuckhoe: infoBNMat?.TUVANGIAODUCSUCKHOE,
      noikhoa: infoBNMat?.NOIKHOA,
      phauthuat: infoBNMat?.PHAUTHUAT,
      thuthuatthuchien: infoBNMat?.THUTHUATTHUCHIEN,
      tinhtrangravieN_TOANTRANG: infoBNMat?.TINHTRANGRAVIEN_TOANTRANG,
      tinhtrangravieN_MP: infoBNMat?.TINHTRANGRAVIEN_MP,
      tinhtrangravieN_MT: infoBNMat?.TINHTRANGRAVIEN_MT,
      khongkinH_MT: infoBNMat?.KHONGKINH_MT,
      khongkinH_MP: infoBNMat?.KHONGKINH_MP,
      cokinH_MT: infoBNMat?.COKINH_MT,
      cokinH_MP: infoBNMat?.COKINH_MP,
      nhanaP_MT: infoBNMat?.NHANAP_MT,
      nhanaP_MP: infoBNMat?.NHANAP_MP,
      hocmaT_MT: infoBNMat?.HOCMAT_MT,
      hocmaT_MP: infoBNMat?.HOCMAT_MP,
      tinhtrangnhancaU_MT: infoBNMat?.TINHTRANGNHANCAU_MT,
      tinhtrangnhancaU_MP: infoBNMat?.TINHTRANGNHANCAU_MP,
      vannhaN_MT: infoBNMat?.VANNHAN_MT,
      vannhaN_MP: infoBNMat?.VANNHAN_MP,
      ledaO_MT: infoBNMat?.LEDAO_MT,
      ledaO_MP: infoBNMat?.LEDAO_MP,
      mimaT_MT: infoBNMat?.MIMAT_MT,
      mimaT_MP: infoBNMat?.MIMAT_MP,
      ketmaC_MT: infoBNMat?.KETMAC_MT,
      ketmaC_MP: infoBNMat?.KETMAC_MP,
      giacmaC_MT: infoBNMat?.GIACMAC_MT,
      giacmaC_MP: infoBNMat?.GIACMAC_MP,
      cungmaC_MT: infoBNMat?.CUNGMAC_MT,
      cungmaC_MP: infoBNMat?.CUNGMAC_MP,
      hinhvemotA_MT: infoBNMat?.HINHVEMOTA_MT,
      hinhvemotA_MP: infoBNMat?.HINHVEMOTA_MP,
      tienphonG_MT: infoBNMat?.TIENPHONG_MT,
      tienphonG_MP: infoBNMat?.TIENPHONG_MP,
      mongmaT_MT: infoBNMat?.MONGMAT_MT,
      mongmaT_MP: infoBNMat?.MONGMAT_MP,
      dongtU_MT: infoBNMat?.DONGTU_MT,
      dongtU_MP: infoBNMat?.DONGTU_MP,
      thethuytinH_MT: infoBNMat?.THETHUYTINH_MT,
      thethuytinH_MP: infoBNMat?.THETHUYTINH_MP,
      dichkinH_MT: infoBNMat?.DICHKINH_MT,
      dichkinH_MP: infoBNMat?.DICHKINH_MP,
      daymaT_MT: infoBNMat?.DAYMAT_MT,
      daymaT_MP: infoBNMat?.DAYMAT_MP,
      thilucravieN_MT: infoBNMat?.THILUCRAVIEN_MT,
      thilucravieN_MP: infoBNMat?.THILUCRAVIEN_MP,
      thilucchinhkinH_MT: infoBNMat?.THILUCCHINHKINH_MT,
      thilucchinhkinH_MP: infoBNMat?.THILUCCHINHKINH_MP,
      nhanapravieN_MT: infoBNMat?.NHANAPRAVIEN_MT,
      nhanapravieN_MP: infoBNMat?.NHANAPRAVIEN_MP,
    },
  })

  const fetchMissingOption = async (key) => {
    const currentValue = formik.values[key]
    if (currentValue && !ChanDoan.some((item) => item.idicD10 === currentValue)) {
      try {
        const response = await https.get(`ChanDoan/GetICD10ByID?idICD=${currentValue}`)
        const newOption = response.data
        setChanDoan((prevOptions) => [...prevOptions, newOption])
      } catch (error) {
        console.error('Error fetching data for missing option:', error)
      }
    }
  }

  useEffect(() => {
    const idsToCheck = ['idchandoan', 'idicD10BENHKEMTHEO', 'idicD10BIENCHUNG']
    idsToCheck.forEach((id) => {
      if (formik.values[id]) {
        fetchMissingOption(id)
      }
    })
  }, [formik.values.idchandoan, formik.values.idicD10BENHKEMTHEO, formik.values.idicD10BIENCHUNG])

  useEffect(() => {
    fetchMissingOption('idchandoanravieN_MP')
    fetchMissingOption('idchandoanravieN_MT')
  }, [formikBnMat.values.idchandoanravieN_MP, formikBnMat.values.idchandoanravieN_MP])

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const valueBnMat = formikBnMat.values
      await https.put(`BenhNhanTTC`, values)
      await https.put(`BenhNhanMat/UpdateBenhAnMat`, valueBnMat)
      await getInfoBNTTC(infoBNTTC.idbnttc)
      await getInfoBNMat(infoBNTTC.idbnttc)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật bệnh án thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật bệnh án thất bại',
      })
    } finally {
      setLoading(false)
    }
  }

  const getDVbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanDichVu/GetBenhNhanDichVu/${idbnttc}`)
      setListDV(
        data.map((item) => ({
          maChiDinh: item.madichvu,
          tenChiDinh: item.tendichvu,
          dvt: item.donvitinh,
          dongia: item.dongia,
          tiengiamgia: item.tiengiamgia,
          tienthanhtoan: item.tienthanhtoan,
        })),
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getInfoBNTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`)
      setInfoBNTTC(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getInfoBNMat = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanMat/GetThongTinBenhNhanDoKhucXa?idbnttc=${idbnttc}`)
      setInfoBNMat(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getCDHAbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanCDHA/GetBenhNhanCDHA/${idbnttc}`)
      setListDV((prev) => [
        ...prev,
        ...data.map((item) => ({
          dvt: item.dvt,
          maChiDinh: item.macdha,
          tenChiDinh: item.tencdha,
          dongia: item.dongia,
          tiengiamgia: item.tiengiamgia,
          tienthanhtoan: item.tienthanhtoan,
        })),
      ])
      //   setListDV(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const getXNbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanXetNghiem/GetBenhNhanXetNghiem/${idbnttc}`)
      setListDV((prev) => [
        ...prev,
        ...data.map((item) => ({
          dvt: item.dvt,
          maChiDinh: item.maxn,
          tenChiDinh: item.tenxn,
          dongia: item.dongia,
          tiengiamgia: item.tiengiamgia,
          tienthanhtoan: item.tienthanhtoan,
        })),
      ])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getToaThuocbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanThuoc/GetDanhSachToaThuocLichSu?idbnttc=${idbnttc}`)
      setToaThuoc(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleShowDetail = async (item) => {
    setLoading(true)
    setActiveItemId(item.idbnttc)
    try {
      const promises = [
        getInfoBNTTC(item.idbnttc),
        getInfoBNMat(item.idbnttc),
        getDVbyIdBenhNhanTTC(item.idbnttc),
        getCDHAbyIdBenhNhanTTC(item.idbnttc),
        getXNbyIdBenhNhanTTC(item.idbnttc),
        getToaThuocbyIdBenhNhanTTC(item.idbnttc),
      ]

      await Promise.all(promises)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const onClickFilter = () => {
    if (!time) {
      setListLichSu(listLichSuDefault)
      return
    }
    const data = listLichSuDefault.filter(
      (item) => dayjs(item.ngaykham).format('DD/MM/YYYY') === time,
    )
    setListLichSu(data)
  }

  const fetchListLyDoKhongKeToa = async () => {
    try {
      const result = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoKhongKeToa(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fechChanDoan('')
    fetchListLyDoKhongKeToa()
  }, [])

  const debounceDropDown = useCallback(
    debounce((keyword) => {
      fechChanDoan(keyword)
    }, 300),
    [],
  )

  const onClickChanDoan = (value, options, key, keyIDC) => {
    const newValue = options.mabenh + '-' + options.icD10TV + '/'
    formik.setFieldValue(key, newValue)
    formik.setFieldValue(keyIDC, value)
    //reset lai chan doan
  }

  const onClickChanDoanMat = (value, options, key, keyIDC) => {
    const newValue = options.mabenh + '-' + options.icD10TV + '/'
    formikBnMat.setFieldValue(key, newValue)
    formikBnMat.setFieldValue(keyIDC, value)
    //reset lai chan doan
  }

  const handlePrintBenhAnMat = (infoBNTTC, infoBNMat) => {
    const icdObj = {
      chandoan: ChanDoan.find((item) => item.idicD10 === infoBNTTC.idchandoan)?.mabenh,
      chandoanbandau: ChanDoan.find((item) => item.idicD10 === infoBNTTC.idicD10BANDAU)?.mabenh,
      chandoanravien: ChanDoan.find((item) => item.idicD10 === infoBNTTC.idicD10RAVIEN)?.mabenh,
      benhkemtheo: ChanDoan.find((item) => item.idicD10 === infoBNTTC.idicD10BENHKEMTHEO)?.mabenh,
      bienchung: ChanDoan.find((item) => item.idicD10 === infoBNTTC.idicD10BIENCHUNG)?.mabenh,
    }
    benhAnMat(infoBNTTC, infoBNMat, icdObj)
  }

  const handleUpdateThongTinNguoiNha = async () => {
    const { idbnttc, tennguoinha, dienthoainguoinha, nguoinha, nguoiSua } = formik.values
    setLoading(true)
    try {
      await https.put(`BenhNhanTTC`, {
        idbnttc,
        tennguoinha,
        dienthoainguoinha,
        nguoinha,
        nguoiSua,
      })
      //get new value
      await getInfoBNTTC(infoBNTTC.idbnttc)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật thông tin người nhà thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật thông tin người nhà thất bại',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDatePicker = (name) => (data, dateString) => {
    const formatDate = moment(dateString, 'DD/MM/YYYY')
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .format('YYYY-MM-DDTHH:mm:ss')
    formik.setFieldValue(name, formatDate)
  }

  useEffect(() => {
    if (open === false) {
      //reset
      setInfoBNTTC(null)
      setListLichSuDefault([])
      setListLichSu([])
      setActiveItemId(null)
      formik.resetForm()
    }
  }, [open])

  const validateUpdateBNTTC =
    infoUser?.idnv !== infoBNTTC?.idbskham || moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 7

  const checkNguoiTao =
    (infoUser?.tenNV && formatNameCompare(infoUser?.tenNV)) ===
      (infoBNTTC?.nguoitao && formatNameCompare(infoBNTTC?.nguoitao)) &&
    moment().diff(moment(infoBNTTC?.ngaykham), 'days') <= 7
  return (
    <div>
      <h2 className="text-gray-500 font-semibold text-lg mb-2 ">Lịch sử khám bệnh</h2>
      <div className="flex gap-2">
        <div className="w-1/6 pr-2 border-r-2 h-full">
          <div className="flex">
            <DatePicker
              placeholder="Chọn ngày khám"
              onChange={(value, dateString) => {
                setTime(dateString)
              }}
              format={'DD/MM/YYYY'}
              className="w-full"
            />
            <Button
              onClick={onClickFilter}
              type="primary"
              icon={<FilterOutlined />}
              className="ml-2"
            ></Button>
          </div>
          {/* date picker time */}

          <ul className=" flex flex-col gap-2 mt-2 rounded-md h-[680px] overflow-auto p-2">
            {listLichSu.map((item) => (
              <li
                key={item.idbnttc}
                onClick={() => handleShowDetail(item)}
                className={item.idbnttc === activeItemId ? activeListItemClasses : listItemClasses}
              >
                <p className="w-full text-center font-semibold">
                  {moment(item.ngaykham).format('DD/MM/YYYY HH:mm')}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {infoBNTTC && listLichSuDefault.length > 0 && (
          <div className="relative w-5/6">
            <div className="flex items-center mb-2">
              <h2 className="text-gray-500 font-semibold text-lg mb-2 mt-2">Thông tin khám bệnh</h2>
              <Button
                type="primary"
                className="ml-auto"
                onClick={formik.handleSubmit}
                icon={<SaveOutlined />}
                disabled={validateUpdateBNTTC}
                loading={loading}
              >
                Lưu
              </Button>

              <Button
                disabled={loading}
                onClick={() => handlePrintBenhAnMat(infoBNTTC, infoBNMat)}
                type="primary"
                icon={<PrinterOutlined />}
                className="ml-2"
              >
                In bệnh án mắt
              </Button>
            </div>
            <div className="container mx-auto flex gap-1 flex-col">
              <div className="grid grid-cols-4 gap-1 w-full">
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Chiều cao</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.chieucao}
                    name="chieucao"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Cm</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Cân nặng</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.cannang}
                    name="cannang"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Kg</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Mạch</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.mach}
                    name="mach"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Lần/phút</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Huyết áp</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.huyetap}
                    name="huyetap"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">mmHg</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Nhiệt độ</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.nhietdo}
                    name="nhietdo"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">&#8451;</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Nhịp thở</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.nhiptho}
                    name="nhiptho"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Lần/Phút</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Spo2</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.spo2}
                    name="spo2"
                    disabled={
                      infoUser?.idnv !== infoBNTTC?.idbskham ||
                      moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 30
                    }
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">%</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">BMI</p>
                  <span className="text-center font-semibold text-green-700 text-sm">{bmi}</span>
                </li>
              </div>
            </div>
            <div>
              <div className="flex gap-1 mt-1 items-center">
                <div className="flex gap-1 mt-1 w-1/2">
                  <div className="flex gap-1 mt-1 w-1/2 items-center">
                    <label className="font-semibold w-32 ml-4">Người nhà: </label>
                    <Select
                      showSearch
                      className="w-full"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => formik.setFieldValue('nguoinha', value)}
                      value={formik.values.nguoinha}
                      options={nguoinha?.map((items) => ({
                        label: items.ten,
                        value: items.ten,
                      }))}
                      size="small"
                      disabled={!checkNguoiTao}
                    />
                  </div>
                  <div className="flex gap-1 mt-1 w-1/2 items-center">
                    <label className="font-semibold w-32 ml-4">SĐT: </label>
                    <Input
                      readOnly={!checkNguoiTao}
                      name="dienthoainguoinha"
                      size="small"
                      className={!checkNguoiTao ? 'w-full cursor-default bg-gray-200' : 'w-full'}
                      value={formik.values.dienthoainguoinha}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex gap-1 mt-1 w-1/2">
                  <div className="w-3/4 flex gap-1 items-center">
                    <label className="font-semibold w-32 ml-4">Tên người nhà: </label>
                    <Input
                      readOnly={!checkNguoiTao}
                      name="tennguoinha"
                      size="small"
                      className={!checkNguoiTao ? 'w-full cursor-default bg-gray-200' : 'w-full'}
                      value={formik.values.tennguoinha}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="w-1/4 flex gap-1">
                    {checkNguoiTao && (
                      <Button
                        type="primary"
                        className="ml-4"
                        onClick={handleUpdateThongTinNguoiNha}
                      >
                        Cập nhật
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-1 mt-1 items-center">
                <div className="flex gap-1 mt-1 w-1/4">
                  <label className="font-semibold w-32 ml-4">Toa thuốc: </label>
                  <Input
                    readOnly
                    name="toathuoc"
                    size="small"
                    className="w-full cursor-default bg-gray-200"
                    value={`TT${infoBNTTC?.benhNhan?.maBenhNhan}`}
                  />
                </div>
                <div className="flex gap-1 mt-1 w-1/4 items-center">
                  <label className="font-semibold w-32 ml-4">STT: </label>
                  <Input
                    readOnly
                    value={infoBNTTC?.sttkham}
                    name="stt"
                    size="small"
                    className="w-full cursor-default bg-gray-200"
                  />
                </div>
                <div className="flex gap-1 mt-1 w-1/4 items-center">
                  <label className="font-semibold w-32 ml-4">Ngày khám: </label>
                  <DatePicker
                    allowClear={false}
                    disabled
                    className="w-full cursor-default bg-gray-200"
                    value={dayjs(infoBNTTC?.ngaykham)}
                    size="small"
                  />
                </div>
                <div className="flex gap-1 mt-1 w-1/4 items-center">
                  <label className="font-semibold w-32 ml-4">Tái khám: </label>
                  <DatePicker
                    minDate={dayjs(infoBNTTC?.ngaykham)}
                    onChange={handleDatePicker('ngaytaikham')}
                    allowClear={false}
                    disabled={validateUpdateBNTTC}
                    className="w-full "
                    value={
                      formik.values.ngaytaikham
                        ? dayjs(formik.values.ngaytaikham, 'YYYY-MM-DDTHH:mm:ss')
                        : null
                    }
                    format="DD/MM/YYYY"
                    size="small"
                  />
                </div>
              </div>
              {infoBNTTC?.lydokolaythuoc ? (
                <div className="flex gap-1 mt-1">
                  <div className="flex gap-1 mt-1 w-1/4">
                    <Checkbox
                      checked={infoBNTTC?.lydokolaythuoc}
                      className="ml-4 flex items-center"
                    >
                      Không kê toa
                    </Checkbox>
                  </div>
                  <div className="flex gap-1 mt-1 w-3/4">
                    <label className="font-semibold w-64 ml-4">Lý do không kê toa: </label>
                    <Select
                      disabled
                      className="w-full"
                      size="small"
                      value={infoBNTTC?.lydokolaythuoc}
                      options={listLyDoKhongKeToa.map((item) => ({
                        key: item.idld,
                        value: item.idld,
                        label: item.lydo,
                      }))}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="flex gap-1 mt-2">
                <label className="font-semibold w-14 ">Ghi chú: </label>
                <Input size="small" value={infoBNTTC?.ghichu} />
              </div>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: 'Thông tin chung',
                    key: 1,
                    children: (
                      <form>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Lý do khám: </label>
                          <Input
                            status={formik.errors.lydokham ? 'error' : ''}
                            onChange={formik.handleChange}
                            value={formik.values.lydokham}
                            name="lydokham"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Bệnh sử: </label>
                          <Input
                            status={formik.errors.benhsu ? 'error' : ''}
                            onChange={formik.handleChange}
                            value={formik.values.benhsu}
                            name="benhsu"
                            size="small"
                          />
                        </div>

                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Chẩn đoán(MT, MP): </label>
                          <Input
                            onChange={formik.handleChange}
                            value={formik.values.chandoan}
                            name="chandoan"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">ICD-10 CĐ: </label>
                          <Select
                            showSearch
                            filterOption={false}
                            className="w-full"
                            size="small"
                            onChange={(value, options) =>
                              onClickChanDoan(value, options, 'chandoan', 'idchandoan')
                            }
                            onSearch={debounceDropDown}
                            suffixIcon={<SearchOutlined className=" " />}
                            notFoundContent={null}
                            value={formik.values.idchandoan}
                            options={ChanDoan?.map((items) => ({
                              label: items.mabenh + ' - ' + items.icD10TV,
                              value: items.idicD10,
                              mabenh: items.mabenh,
                              ...items,
                            }))}
                            optionRender={(options) => (
                              <ul className="flex">
                                <li className="w-1/6">{options.data.mabenh}</li>
                                <li className="w-5/6 border-l px-5">{options.data.label}</li>
                              </ul>
                            )}
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Tiền sử bản thân: </label>
                          <Input
                            placeholder="Không"
                            onChange={formik.handleChange}
                            value={formik.values.tiensubanthan}
                            name="tiensubanthan"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Tiền sử dị ứng: </label>
                          <Input
                            placeholder="Không"
                            onChange={formik.handleChange}
                            value={formik.values.diung}
                            name="diung"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Tiền sử tai mắt: </label>
                          <Input
                            placeholder="Không"
                            onChange={formikBnMat.handleChange}
                            value={formikBnMat.values.tiensutaimat}
                            name="tiensutaimat"
                            size="small"
                          />
                        </div>
                        <div className="flex gap-1 mt-1">
                          <label className="font-semibold w-64 text-end">Tiền sử gia đình: </label>
                          <Input
                            placeholder="Không"
                            onChange={formik.handleChange}
                            value={formik.values.tieusugiadinh}
                            name="tieusugiadinh"
                            size="small"
                          />
                        </div>
                        {isExpanded && (
                          <>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">Toàn thân: </label>
                              <Input
                                placeholder="Bình thường"
                                onChange={formik.handleChange}
                                value={formik.values.toanthan}
                                name="toanthan"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Chẩn đoán ra viện(MT):{' '}
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.chandoanravieN_MT}
                                name="chandoanravieN_MT"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                ICD-10 CĐ ra viện(MT):{' '}
                              </label>
                              <Select
                                showSearch
                                filterOption={false}
                                className="w-full"
                                size="small"
                                onChange={(value, options) =>
                                  onClickChanDoanMat(
                                    value,
                                    options,
                                    'chandoanravieN_MT',
                                    'idchandoanravieN_MT',
                                  )
                                }
                                onSearch={debounceDropDown}
                                suffixIcon={<SearchOutlined className=" " />}
                                notFoundContent={null}
                                value={formikBnMat.values.idchandoanravieN_MT}
                                options={ChanDoan?.map((items) => ({
                                  label: items.mabenh + ' - ' + items.icD10TV,
                                  value: items.idicD10,
                                  mabenh: items.mabenh,
                                  ...items,
                                }))}
                                optionRender={(options) => (
                                  <ul className="flex">
                                    <li className="w-1/6">{options.data.mabenh}</li>
                                    <li className="w-5/6 border-l px-5">{options.data.label}</li>
                                  </ul>
                                )}
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Chẩn đoán ra viện(MP):{' '}
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.chandoanravieN_MP}
                                name="chandoanravieN_MP"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                ICD-10 CĐ ra viện(MP):{' '}
                              </label>
                              <Select
                                showSearch
                                filterOption={false}
                                className="w-full"
                                size="small"
                                onChange={(value, options) =>
                                  onClickChanDoanMat(
                                    value,
                                    options,
                                    'chandoanravieN_MP',
                                    'idchandoanravieN_MP',
                                  )
                                }
                                onSearch={debounceDropDown}
                                suffixIcon={<SearchOutlined className=" " />}
                                notFoundContent={null}
                                value={formikBnMat.values.idchandoanravieN_MP}
                                options={ChanDoan?.map((items) => ({
                                  label: items.mabenh + ' - ' + items.icD10TV,
                                  value: items.idicD10,
                                  mabenh: items.mabenh,
                                  ...items,
                                }))}
                                optionRender={(options) => (
                                  <ul className="flex">
                                    <li className="w-1/6">{options.data.mabenh}</li>
                                    <li className="w-5/6 border-l px-5">{options.data.label}</li>
                                  </ul>
                                )}
                              />
                            </div>

                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Bệnh kèm theo(MT, MP):{' '}
                              </label>
                              <Input
                                onChange={formik.handleChange}
                                value={formik.values.benhkemtheo}
                                name="benhkemtheo"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                ICD-10 Bệnh kèm theo:{' '}
                              </label>
                              <Select
                                showSearch
                                filterOption={false}
                                className="w-full"
                                size="small"
                                onChange={(value, options) =>
                                  onClickChanDoan(
                                    value,
                                    options,
                                    'benhkemtheo',
                                    'idicD10BENHKEMTHEO',
                                  )
                                }
                                onSearch={debounceDropDown}
                                suffixIcon={<SearchOutlined className=" " />}
                                notFoundContent={null}
                                value={formik.values.idicD10BENHKEMTHEO}
                                options={ChanDoan?.map((items) => ({
                                  label: items.mabenh + ' - ' + items.icD10TV,
                                  value: items.idicD10,
                                  mabenh: items.mabenh,
                                  ...items,
                                }))}
                                optionRender={(options) => (
                                  <ul className="flex">
                                    <li className="w-1/6">{options.data.mabenh}</li>
                                    <li className="w-5/6 border-l px-5">{options.data.label}</li>
                                  </ul>
                                )}
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Biến chứng(MT, MP):{' '}
                              </label>
                              <Input
                                onChange={formik.handleChange}
                                value={formik.values.bienchung}
                                name="bienchung"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                ICD-10 Biến chứng:{' '}
                              </label>
                              <Select
                                showSearch
                                filterOption={false}
                                className="w-full"
                                size="small"
                                onChange={(value, options) =>
                                  onClickChanDoan(value, options, 'bienchung', 'idicD10BIENCHUNG')
                                }
                                onSearch={debounceDropDown}
                                suffixIcon={<SearchOutlined className=" " />}
                                notFoundContent={null}
                                value={formik.values.idicD10BIENCHUNG}
                                options={ChanDoan?.map((items) => ({
                                  label: items.mabenh + ' - ' + items.icD10TV,
                                  value: items.idicD10,
                                  mabenh: items.mabenh,
                                  ...items,
                                }))}
                                optionRender={(options) => (
                                  <ul className="flex">
                                    <li className="w-1/6">{options.data.mabenh}</li>
                                    <li className="w-5/6 border-l px-5">{options.data.label}</li>
                                  </ul>
                                )}
                              />
                            </div>

                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">Hướng điều trị:</label>
                              <Input
                                onChange={formik.handleChange}
                                value={formik.values.huongxuly}
                                name="huongxuly"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Các xét nghiệm cần làm:
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.cacxetnghiemcanlam}
                                name="cacxetnghiemcanlam"
                                size="small"
                                placeholder="Không"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Tóm tắt bệnh án:
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tomtatbenhan}
                                name="tomtatbenhan"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">Tiên lượng gần:</label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tienluonggan}
                                name="tienluonggan"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">Tiên lượng xa:</label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tienluongxa}
                                name="tienluongxa"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Tư vấn, giáo dục sức khỏe:
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tuvangiaoducsuckhoe}
                                name="tuvangiaoducsuckhoe"
                                size="small"
                                placeholder="Không"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">Nội khoa:</label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.noikhoa}
                                name="noikhoa"
                                size="small"
                                placeholder="Không"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">Phẫu thuật:</label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.phauthuat}
                                name="phauthuat"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Thủ thuật thực hiện:
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.thuthuatthuchien}
                                name="thuthuatthuchien"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Tình trạng ra viện (toàn trạng):
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tinhtrangravieN_TOANTRANG}
                                name="tinhtrangravieN_TOANTRANG"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Tình trạng ra viện (mắt phải):
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tinhtrangravieN_MP}
                                name="tinhtrangravieN_MP"
                                size="small"
                              />
                            </div>
                            <div className="flex gap-1 mt-1">
                              <label className="font-semibold w-64 text-end">
                                Tình trạng ra viện (mắt trái):
                              </label>
                              <Input
                                onChange={formikBnMat.handleChange}
                                value={formikBnMat.values.tinhtrangravieN_MT}
                                name="tinhtrangravieN_MT"
                                size="small"
                              />
                            </div>
                            {/* 2 mắt */}
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Không kính:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.khongkinH_MT}
                                    name="khongkinH_MT"
                                    size="small"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.khongkinH_MP}
                                    name="khongkinH_MP"
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Có kính:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.cokinH_MT}
                                    name="cokinH_MT"
                                    size="small"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.cokinH_MP}
                                    name="cokinH_MP"
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Nhãn áp:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.nhanaP_MT}
                                    name="nhanaP_MT"
                                    size="small"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.nhanaP_MP}
                                    name="nhanaP_MP"
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Hốc mắt:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.hocmaT_MT}
                                    name="hocmaT_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.hocmaT_MP}
                                    name="hocmaT_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Tình trạng nhãn cầu:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.tinhtrangnhancaU_MT}
                                    name="tinhtrangnhancaU_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.tinhtrangnhancaU_MP}
                                    name="tinhtrangnhancaU_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Vận nhãn:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.vannhaN_MT}
                                    name="vannhaN_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.vannhaN_MP}
                                    name="vannhaN_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Lệ đạo:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.ledaO_MT}
                                    name="ledaO_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.ledaO_MP}
                                    name="ledaO_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Mi mắt:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.mimaT_MT}
                                    name="mimaT_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.mimaT_MP}
                                    name="mimaT_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Kết mạc:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.ketmaC_MT}
                                    name="ketmaC_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.ketmaC_MP}
                                    name="ketmaC_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Giác mạc:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.giacmaC_MT}
                                    name="giacmaC_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.giacmaC_MP}
                                    name="giacmaC_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Củng mạc:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.cungmaC_MT}
                                    name="cungmaC_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.cungmaC_MP}
                                    name="cungmaC_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Hình vẽ mô tả:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-4 flex-col justify-center items-center">
                                  <Image src={mattrai} width={280} height={200} />
                                  {/* <div className="flex gap-2 w-full">
                                    <label className="w-24 text-end">Mắt trái</label>
                                    <Input
                                      onChange={formikBnMat.handleChange}
                                      value={formikBnMat.values.hinhvemotA_MT}
                                      name="hinhvemotA_MT"
                                      size="small"
                                      placeholder="Không"
                                      className="w-full"
                                    />
                                  </div> */}
                                </div>
                                <div className="w-1/2 flex gap-4 flex-col justify-center items-center">
                                  <Image src={matphai} width={280} height={200} />
                                  {/* <div className="flex gap-2 w-full">
                                    <label className="w-24 text-end">Mắt phải</label>
                                    <Input
                                      onChange={formikBnMat.handleChange}
                                      value={formikBnMat.values.hinhvemotA_MP}
                                      name="hinhvemotA_MP"
                                      size="small"
                                      placeholder="Không"
                                      className="w-full"
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Tiền phòng:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.tienphonG_MT}
                                    name="tienphonG_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.tienphonG_MP}
                                    name="tienphonG_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Mống mắt:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.mongmaT_MT}
                                    name="mongmaT_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.mongmaT_MP}
                                    name="mongmaT_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Đồng tử:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.dongtU_MT}
                                    name="dongtU_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.dongtU_MP}
                                    name="dongtU_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Thể thủy tinh:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.thethuytinH_MT}
                                    name="thethuytinH_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.thethuytinH_MP}
                                    name="thethuytinH_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Dịch kính:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.dichkinH_MT}
                                    name="dichkinH_MT"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.dichkinH_MP}
                                    name="dichkinH_MP"
                                    size="small"
                                    placeholder="Không"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Đáy mắt:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-4 flex-col justify-center items-center">
                                  <Image src={daymattrai} width={220} height={200} />
                                  <div className="flex gap-2 w-full">
                                    <label className="w-24 text-end">Mắt trái</label>
                                    <Input
                                      onChange={formikBnMat.handleChange}
                                      value={formikBnMat.values.daymaT_MT}
                                      name="daymaT_MT"
                                      size="small"
                                      placeholder="Không"
                                      className="w-full"
                                    />
                                  </div>
                                </div>
                                <div className="w-1/2 flex gap-4 flex-col justify-center items-center">
                                  <Image src={daymatphai} width={220} height={200} />
                                  <div className="flex gap-2 w-full">
                                    <label className="w-24 text-end">Mắt phải</label>
                                    <Input
                                      onChange={formikBnMat.handleChange}
                                      value={formikBnMat.values.daymaT_MP}
                                      name="daymaT_MP"
                                      size="small"
                                      placeholder="Không"
                                      className="w-full"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Thị lực ra viện:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.thilucravieN_MT}
                                    name="thilucravieN_MT"
                                    size="small"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.thilucravieN_MP}
                                    name="thilucravieN_MP"
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Thị lực chỉnh kính:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.thilucchinhkinH_MT}
                                    name="thilucchinhkinH_MT"
                                    size="small"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.thilucchinhkinH_MP}
                                    name="thilucchinhkinH_MP"
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 mt-1">
                              <p className="font-semibold w-64 text-end">Nhãn áp ra viện:</p>
                              <div className="w-full flex gap-4">
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt trái</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.nhanapravieN_MT}
                                    name="nhanapravieN_MT"
                                    size="small"
                                  />
                                </div>
                                <div className="w-1/2 flex gap-2">
                                  <label className="w-24 text-end">Mắt phải:</label>
                                  <Input
                                    onChange={formikBnMat.handleChange}
                                    value={formikBnMat.values.nhanapravieN_MP}
                                    name="nhanapravieN_MP"
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="flex justify-center mt-1">
                          <button
                            type="button"
                            onClick={toggleExpand}
                            className="cursor-pointer flex justify-between text-blue-500 px-1 py-1 rounded-md  tracking-wider    duration-500  w-[100px]"
                          >
                            {isExpanded ? (
                              <>
                                Thu gọn
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="size-6 animate-bounce"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                Mở rộng
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="size-6 animate-bounce"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </>
                            )}
                          </button>
                        </div>
                      </form>
                    ),
                  },
                ]}
              />
            </div>

            <Divider />
            <h2 className="text-gray-500 font-semibold text-lg mb-2 mt-2">Dịch vụ</h2>
            <div className="mt-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                }}
              >
                <Table
                  bordered
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                  scroll={{ y: 200 }}
                  columns={columnsDV}
                  dataSource={listDV}
                  loading={loading}
                  footer={() => (
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Tổng: {formatNumberVND(listDV.reduce((a, b) => a + b.tienthanhtoan, 0))}
                    </div>
                  )}
                />
              </ConfigProvider>
            </div>
            <Divider />
            {!infoBNTTC?.lydokolaythuoc && (
              <>
                <h2 className="text-gray-500 font-semibold text-lg mb-2">Toa thuốc</h2>
                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                        },
                      },
                    }}
                  >
                    <Table
                      bordered
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: false,
                      }}
                      scroll={{ y: 200 }}
                      columns={columnsThuoc}
                      dataSource={toaThuoc}
                      loading={loading}
                      footer={() => (
                        <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          Tổng: {formatNumberVND(toaThuoc.reduce((a, b) => a + b.thanhtien, 0))}
                        </div>
                      )}
                    />
                  </ConfigProvider>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

HistoryKhamBenh.propTypes = {}

export default React.memo(HistoryKhamBenh)
