import * as typeAction from '../constants/constants'
export const getList_BN_SMS = (chuyenKhoa, numberTP, numberTTV, setLoadingBN) => ({
  type: typeAction.GET_BN_SMS,
  chuyenKhoa,
  numberTP,
  numberTTV,
  setLoadingBN
})

export const getList_Template_SMS = () => ({
  type: typeAction.GET_LIST_TEMPLATE_SMS,
})
