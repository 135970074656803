import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  ConfigProvider,
  Input,
  DatePicker,
  Table,
  Tooltip,
  Modal,
  Select,
  Popconfirm,
  TreeSelect,
} from 'antd'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { putTrangThaiDangKyKhamOnlAction } from '../../../../store/actions/dangKyKhamAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { cashierService } from '../../../../services/cashier/cashierService'
import { branchService } from '../../../../services/branch/branchService'
import * as typeAction from '../../../../store/constants/constants'
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  FilterOutlined,
  FormOutlined,
} from '@ant-design/icons'
import _, { cloneDeep } from 'lodash'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import dayjs from 'dayjs'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
// import styled from 'styled-components'
// const SoDienThoai = styled(Button)`
//   all: unset;
//   width: 100%;
//   height: 22px;
//   display: block !important;
//   &:hover,
//   &:active {
//     all: unset !important;
//   }
//   &:hover *,
//   &:active * {
//     width: 100%;
//     display: block !important;
//   }
// `
function TabDangKyKham({ activeTabs, setActiveTabs, BnDkkOnline, setBnDkkOnline }) {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { listBranch, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([ID_BRANCH])
  const [company, setCompany] = useState(ID_COMPANY)
  const ruleUser = ['Lễ tân '].includes(infoUser?.tenNhom)
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [listDangKyKham, setListDangKyKham] = useState([])
  const [rowActive, setRowActive] = useState(null)
  const [filterTrangThai, setFilterTrangThai] = useState('all')
  //   const [listNhomVacXin, setListNhomVacXin] = useState([])
  //   const [listVacXin, setListVacXin] = useState([])
  //   const [nhomVX, setNhomVX] = useState(null)
  //   const [isModal, setIsModal] = useState(false)
  //   const [vacXinEdit, setVacXinEdit] = useState(null)

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const confirm = (e) => {
    if (!rowActive?.idbndkk) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thử lại!',
      })
      return
    }
    dispatch(
      putTrangThaiDangKyKhamOnlAction(
        rowActive?.idbndkk,
        rowActive?.trangthaidk + 1,
        infoUser?.idnv,
        getListDangKyKham,
      ),
    )
  }
  const getListDangKyKham = async () => {
    try {
      setIsLoading(true)
      // const { data } = await cashierService.getListDangKyKham(dateForm, dateTo, keyword, Branch)
      const { data } = await cashierService.getListDangKyKham(
        dateForm,
        dateTo,
        keyword?.length ? keyword?.trim() : keyword,
        (value?.includes('all') ? branch : value)
          ?.map((item) => `idChiNhanh=${value?.includes('all') ? item?.idChiNhanh : item}`)
          ?.join('&')
          ?.toString(),
        null, // id đối tác
        filterTrangThai === 'all' ? null : filterTrangThai,
      )

      setListDangKyKham(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const handleSelected = (value) => {
    return value !== 'all' ? setBranch(value) : setBranch(null)
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(ID_COMPANY)
    getListDangKyKham()
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 1,
      width: 45,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày hẹn',
      dataIndex: 'ngaydk',
      key: 2,
      width: 90,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaydk ?? '').localeCompare(b?.ngaydk ?? '')
        },
      },
    },
    {
      title: 'Giờ hẹn',
      dataIndex: 'giodkkham',
      key: 3,
      width: 80,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => text,
      sorter: {
        compare: (a, b) => {
          return (a?.giodkkham ?? '').localeCompare(b?.giodkkham ?? '')
        },
      },
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      key: 4,
      width: 140,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
        },
      },
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      key: 5,
      width: 250,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
        },
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'sodienthoaidk',
      key: 6,
      width: 120,
      render: (text, record, index) => (record.idbn === rowActive?.idbn ? <p>{text}</p> : ''),
      sorter: {
        compare: (a, b) => {
          return (a?.sodienthoaidk ?? '').localeCompare(b?.sodienthoaidk ?? '')
        },
      },
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 7,
      align: 'center',
      width: 95,
      render: (text, record, index) => text && moment(text, 'DD-MMM-YY').format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
        },
      },
    },
    {
      title: 'Giới tính',
      dataIndex: 'gioitinh',
      key: 8,
      align: 'center',
      width: 85,
      sorter: {
        compare: (a, b) => {
          return (a?.gioitinh ?? '').localeCompare(b?.gioitinh ?? '')
        },
      },
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 9,
      sorter: {
        compare: (a, b) => {
          return (a?.diachi ?? '').localeCompare(b?.diachi ?? '')
        },
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 10,
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'tennguoisua',
      key: 11,
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 12,
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'tentrangthaidk',
      key: 13,
      width: 120,
      align: 'center',
      fixed: 'right',
      // filters: [
      // ],
      // onFilter: (value, record) => record?.idtrangthai === value,
      sorter: {
        compare: (a, b) => {
          return (a?.trangthaidk ?? 0) - (b?.trangthaidk ?? 0)
        },
      },
      render: (text, record, index) => (
        <>
          <span
            className={`cursor-pointer px-2 py-1 rounded-full text-xs font-medium ${(record?.trangthaidk === 1 && 'bg-amber-200 text-amber-800') || (record?.trangthaidk === 2 && 'bg-sky-200 text-sky-800') || (record?.trangthaidk === 3 && 'bg-indigo-200 text-indigo-800') || (record?.trangthaidk === 4 && 'bg-emerald-200 text-emerald-800')}`}
          >
            {record?.trangthaidk === 4 ? ( //đã hoàn thành không chuyển nữa
              <>{text}</>
            ) : (
              <Popconfirm
                title="Chuyển trạng thái đăng ký khám online"
                description={
                  <>
                    <div>Bạn có muốn chuyển trạng thái đăng ký khám online?</div>
                    <div className="font-semibold">
                      {`${record?.tentrangthaidk} => ${(record?.trangthaidk === 1 && 'Đã tiếp nhận') || (record?.trangthaidk === 2 && 'Đã đến khám') || (record?.trangthaidk === 3 && 'Đã hoàn thành')}`}
                    </div>
                  </>
                }
                onConfirm={confirm}
                okText="Xác nhận"
                cancelText="Huỷ bỏ"
                placement="bottomRight"
              >
                {text}
              </Popconfirm>
            )}
          </span>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 12,
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) =>
        record?.trangthaidk === 1 && (
          <>
            <Tooltip title="Tiếp nhận" color="green">
              <FormOutlined
                className="text-lg text-green-500 cursor-pointer font-semibold flex items-center justify-center"
                onClick={() => {
                  setActiveTabs('1')
                  setBnDkkOnline(record?.idbn)
                }}
              />
            </Tooltip>
            {/* <div className="flex items-center justify-center gap-3">
            <div>1</div>
            <div>2</div>
          </div> */}
          </>
        ),
    },
  ]
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start items-center gap-3">
          <div className="flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          {ruleUser ? (
            <>{/* <h2>{PkDangNhap?.tenChiNhanh}</h2> */}</>
          ) : (
            <>
              <div className="w-[17rem]">
                <Select
                  showSearch
                  value={company}
                  onChange={(value) => {
                    setCompany(value)
                    fetchBranch(value)
                    setValue('all')
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={listCompany?.map(({ idct, tenct }) => ({
                    label: tenct,
                    value: idct,
                  }))}
                  className="w-full"
                />
              </div>
              <div className="w-[17rem] flex gap-2">
                <TreeSelect
                  {...tProps}
                  style={{ flexWrap: 'unset' }}
                  className="w-full h-8 overflow-clip"
                />
              </div>
            </>
          )}
          <Select
            suffixIcon={<FilterOutlined />}
            value={filterTrangThai}
            options={[
              {
                key: 'all',
                value: 'all',
                label: 'Tất cả trạng thái',
              },
              {
                key: 1,
                value: 1,
                label: 'Mới đăng ký',
              },
              {
                key: 2,
                value: 2,
                label: 'Đã tiếp nhận',
              },
              {
                key: 3,
                value: 3,
                label: 'Đã đến khám',
              },
              {
                key: 4,
                value: 4,
                label: 'Đã hoàn thành',
              },
            ]}
            className="w-60"
            onChange={(value) => setFilterTrangThai(value)}
          />
          <Input
            placeholder="Nhập từ khóa..."
            prefix={<SearchOutlined className="pr-1" />}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            value={keyword}
            className="w-60"
          />
          <IconButton
            onClick={() => {
              setKeyword((prev) => (prev?.trim()?.length > 0 ? prev?.trim() : ''))
              getListDangKyKham()
            }}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                setRowActive(record)
              }, // click row
            }
          }}
          scroll={{ x: 2050, y: 700 }}
          // pagination={{
          //   pageSize: 20,
          //   showSizeChanger: false,
          // }}
          // scroll={{ y: 715 }}
          pagination={false}
          loading={isLoading}
          columns={columns}
          bordered
          dataSource={listDangKyKham}
          // dataSource={[
          //   1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          // ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default TabDangKyKham
