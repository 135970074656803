import { PrinterOutlined } from '@ant-design/icons'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button, Checkbox, Divider, Drawer, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { formattedNumber } from '../../../../../utils/formattedNumber'
// import { generatePhieuBanHang2 } from '../../../../../utils/report/phieuBanHang2'
// import { generatePhieuThuTiepNhan } from '../../../../../utils/report/phieuThuTiepNhan'
import { generatePhieuThuTienTTV } from '../../../../../utils/report/phieuThuTienTTV'
import 'moment/locale/vi'
moment.locale('vi')
const DrawerPT = ({ open, onClose }) => {
  const { infoPhieuThuTTV, detailCard, infoBN, infoBNthu2 } = useSelector(
    (state) => state.cartReducer,
  )
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const totalPrice = [...ArrXN, ...ArrCĐHA, ...ArrKB]?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0

  const handlePrint = () => {
    const formPrint = {
      infoBN: infoBN,
      infoBNthu2: infoBNthu2 ?? null,
      infoTTV: { ...infoPhieuThuTTV, tungay: detailCard?.tungay, denngay: detailCard?.denngay },
      infoTT: infoPhieuThuTTV,
      PkDangNhap: PkDangNhap,
      maphieuthu: infoPhieuThuTTV?.maphieuthu,
    }
    generatePhieuThuTienTTV(formPrint)
  }

  return (
    <Drawer
      title={'Chi tiết phiếu thu'}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={handlePrint} type="primary" icon={<PrinterOutlined />}></Button>
        </Space>
      }
    >
      <div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Thông tin bệnh nhân</h2>
        <div>
          <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Tên BN :</label>
              <p>{infoBN?.tenBenhNhan}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Mã BN :</label>
              <p>{infoBN?.maBenhNhan}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Đối tượng :</label>
              <p>{infoBN?.tenDoiTuong}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Ngày sinh :</label>
              <p>{infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY')}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Nghề nghiệp :</label>
              <p>{infoBN?.tenNgheNghiep}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Dân tộc :</label>
              <p>{infoBN?.tenDanToc}</p>
              {/* <label className="font-semibold w-20">Phiếu thu :</label>
              <p>{infoPhieuThuTTV?.maphieuthu}</p> */}
            </div>
          </div>
          <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Điện thoại :</label>
              <p>{infoBN?.dienThoai}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Giới tính :</label>
              <p>{infoBN?.gioiTinh}</p>
            </div>
          </div>
          {/* <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Người thu :</label>
              <p>{infoPhieuThuTTV?.nguoithu}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Mã TCQG :</label>
              <p>{infoBN?.maTCQG}</p>
            </div>
          </div> */}
          <div className="flex">
            <label className="font-semibold w-32">Địa chỉ : </label>
            <p>{infoBN?.diaChi}</p>
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
      </div>
      {infoBNthu2 &&
        infoPhieuThuTTV?.sothanhvien === 2 && ( //&& infoPhieuThuTTV?.caploaithe >= 2
          <>
            <div>
              <h2 className="text-gray-500 font-semibold text-lg mb-2">Thông tin bệnh nhân tặng</h2>
              <div>
                <div className="flex">
                  <div className="flex w-3/5 items-center ">
                    <label className="font-semibold w-32">Tên BN :</label>
                    <p>{infoBNthu2?.tenBenhNhan}</p>
                  </div>
                  <div className="flex w-2/5 items-center ">
                    <label className="font-semibold w-28">Mã BN :</label>
                    <p>{infoBNthu2?.maBenhNhan}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex w-3/5 items-center ">
                    <label className="font-semibold w-32">Đối tượng :</label>
                    <p>{infoBNthu2?.tenDoiTuong}</p>
                  </div>
                  <div className="flex w-2/5 items-center ">
                    <label className="font-semibold w-28">Ngày sinh :</label>
                    <p>
                      {infoBNthu2?.ngaySinh && moment(infoBNthu2?.ngaySinh).format('DD/MM/YYYY')}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex w-3/5 items-center ">
                    <label className="font-semibold w-32">Nghề nghiệp :</label>
                    <p>{infoBN?.tenNgheNghiep}</p>
                  </div>
                  <div className="flex w-2/5 items-center ">
                    <label className="font-semibold w-28">Dân tộc :</label>
                    <p>{infoBNthu2?.tenDanToc}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex w-3/5 items-center ">
                    <label className="font-semibold w-32">Điện thoại :</label>
                    <p>{infoBNthu2?.dienThoai}</p>
                  </div>
                  <div className="flex w-2/5 items-center ">
                    <label className="font-semibold w-28">Giới tính :</label>
                    <p>{infoBNthu2?.gioiTinh}</p>
                  </div>
                </div>
                <div className="flex">
                  <label className="font-semibold w-32">Địa chỉ : </label>
                  <p>{infoBNthu2?.diaChi}</p>
                </div>
              </div>
              <Divider style={{ margin: 15 }} />
            </div>
          </>
        )}

      <div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết thanh toán</h2>
        <div className="flex flex-col gap-1">
          <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Người thu :</label>
              <p>{infoPhieuThuTTV?.nguoithu}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Phiếu thu :</label>
              <p>{infoPhieuThuTTV?.maphieuthu}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-3/5 items-center ">
              <label className="font-semibold w-32">Voucher :</label>
              <p>{infoPhieuThuTTV?.mavoucher}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-28">Ngày thu :</label>
              <p>
                {infoPhieuThuTTV?.ngaythanhtoan &&
                  moment(infoPhieuThuTTV?.ngaythanhtoan).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Tiền mặt :</label>
              <p>{infoPhieuThuTTV?.tienmat && formattedNumber(infoPhieuThuTTV?.tienmat)}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Chuyển khoản :</label>
              <p>{infoPhieuThuTTV?.chuyenkhoan && formattedNumber(infoPhieuThuTTV?.chuyenkhoan)}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Thẻ thanh toán :</label>
              <p>{infoPhieuThuTTV?.thett && formattedNumber(infoPhieuThuTTV?.thett)}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Momo :</label>
              <p>{infoPhieuThuTTV?.momo && formattedNumber(infoPhieuThuTTV?.momo)}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">VNPay :</label>
              <p>{infoPhieuThuTTV?.vnpay && formattedNumber(infoPhieuThuTTV?.vnpay)}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">ZaloPay :</label>
              <p>{infoPhieuThuTTV?.zalopay && formattedNumber(infoPhieuThuTTV?.zalopay)}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Máy POS :</label>
              <p>{infoPhieuThuTTV?.pos && formattedNumber(infoPhieuThuTTV?.pos)}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Phương thức TT :</label>
              {/* <p>Thanh toán</p> */}
              <p>{infoPhieuThuTTV?.phuongthuc}</p>
            </div>
            <div className="flex w-2/5 items-center ">
              <label className="font-semibold w-32">Xuất HĐ điện tử :</label>
              <Checkbox checked={infoPhieuThuTTV?.hoadondientu === 1} />
            </div>
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
      </div>
      <div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết thẻ thành viên</h2>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell c>Tên thẻ</TableCell>
                <TableCell align="right">Đơn giá</TableCell>
                <TableCell align="right">% giảm</TableCell>
                <TableCell align="right">Tiền giảm</TableCell>
                <TableCell align="right">Thành tiền</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={infoPhieuThuTTV?.idthe}>
                <TableCell>{infoPhieuThuTTV?.tenthe}</TableCell>
                <TableCell align="right">
                  {infoPhieuThuTTV?.tiensudung && formattedNumber(infoPhieuThuTTV?.tiensudung)}
                </TableCell>
                <TableCell align="right">
                  {infoPhieuThuTTV?.tiengiamgia
                    ? infoPhieuThuTTV?.tiensudung &&
                      Number(
                        (infoPhieuThuTTV?.tiengiamgia / infoPhieuThuTTV?.tiensudung) * 100,
                      ).toFixed(2)
                    : 0}
                </TableCell>
                <TableCell align="right">
                  {infoPhieuThuTTV?.tiengiamgia ? formattedNumber(infoPhieuThuTTV?.tiengiamgia) : 0}
                </TableCell>
                <TableCell align="right">
                  {infoPhieuThuTTV?.tienthanhtoan &&
                    formattedNumber(infoPhieuThuTTV?.tienthanhtoan)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} colSpan={3} />
                <TableCell colSpan={1}>Tổng giảm</TableCell>
                <TableCell align="right">
                  {infoPhieuThuTTV?.tiengiamgia && formattedNumber(infoPhieuThuTTV?.tiengiamgia)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>Tổng tiền</TableCell>
                <TableCell align="right">
                  {infoPhieuThuTTV?.tienthanhtoan &&
                    formattedNumber(infoPhieuThuTTV?.tienthanhtoan)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Drawer>
  )
}

export default DrawerPT
