import { DatePicker, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { putNguoiBanTTVAction } from '../../../../store/actions/toolAction'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import ToastCus from '../../../../utils/Toast'
import _ from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')

const ModalUpdateNguoiBanTTV = ({ openCapNhatNguoiBanTTV, setOpenCapNhatNguoiBanTTV }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [form, setForm] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [listNhanVien, setListNhanVien] = useState([])
  const hanldeSubmit = () => {
    if (!form?.maPhieuThu) {
      setForm((prev) => ({
        maPhieuThu: prev?.maPhieuThu ?? null,
        idNguoiTao: prev?.idLoaiKham ?? null,
        tenNguoiTao: prev?.tenNguoiTao ?? null,
        idNguoiBan: prev?.idLoaiKham ?? null,
        tenNguoiBan: prev?.tenNguoiBan ?? null,
      }))
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    handleCancel()
    dispatch(putNguoiBanTTVAction(form, reset))
  }
  const handleCancel = () => {
    setOpenCapNhatNguoiBanTTV(false)
  }
  const reset = () => {
    setForm(null)
    handleCancel()
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        fetchListNhanVien(keyword)
      }
    }, 300),
    [],
  )
  const fetchListNhanVien = async (keyword) => {
    try {
      const result = await nhanvienService.getSearch(keyword)
      setListNhanVien(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Modal
      loading={isLoading}
      onOk={hanldeSubmit}
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      title="Cập nhập người bán TTV"
      open={openCapNhatNguoiBanTTV}
      onCancel={handleCancel}
    >
      <div className="grid grid-flow-row items-center gap-2 pt-2 pb-2">
        <div>
          <label className="font-semibold text-base">Mã phiếu thu</label>
          <Input
            status={form && !form?.maPhieuThu && 'error'}
            placeholder="Nhập mã phiếu thu PT..."
            name="maphieuthu"
            value={form?.maPhieuThu}
            onChange={(e) => setForm((prev) => ({ ...prev, maPhieuThu: e.target.value?.trim() }))}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base">Người tạo</label>
          <Select
            showSearch
            allowClear
            className="w-full"
            onSearch={debounceDropDown}
            value={form?.idNguoiTao}
            onChange={(value, opt) => {
              setForm((prev) => ({ ...prev, idNguoiTao: value, tenNguoiTao: opt?.label ?? null }))
            }}
            filterOption={false}
            notFoundContent={null}
            placeholder={'Tìm tên nhân viên...'}
            suffixIcon={<SearchOutlined className=" " />}
            options={listNhanVien?.map((items) => ({
              label: items.tennv,
              value: items.id,
              manv: items.manv,
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-3/4">{options.data.label}</li>
                <li className="w-1/4 border-x text-center">{options.data.manv}</li>
              </ul>
            )}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base">Người bán</label>
          <Select
            showSearch
            allowClear
            className="w-full"
            onSearch={debounceDropDown}
            value={form?.idNguoiBan}
            onChange={(value, opt) => {
              setForm((prev) => ({ ...prev, idNguoiBan: value, tenNguoiBan: opt?.label ?? null }))
            }}
            filterOption={false}
            notFoundContent={null}
            placeholder={'Tìm tên nhân viên...'}
            suffixIcon={<SearchOutlined className=" " />}
            options={listNhanVien?.map((items) => ({
              label: items.tennv,
              value: items.id,
              manv: items.manv,
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-3/4">{options.data.label}</li>
                <li className="w-1/4 border-x text-center">{options.data.manv}</li>
              </ul>
            )}
          />
        </div>
        <span className="text-red-500 mt-2">
          * Lưu ý: Để rỗng khi không muốn cập nhật, sẽ giữ lại người cũ
        </span>
      </div>
    </Modal>
  )
}

export default ModalUpdateNguoiBanTTV
