import moment from "moment";
import { formatNumberVND } from "../../../../../utils/formatNumberVND";

export const columns = [
  {
    title: 'Ngày',
    dataIndex: 'ngay',
    key: 'ngay',
    width: 100,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenChiNhanh',
    key: 'tenChiNhanh',
    width: 250,
  },
  {
    title: 'Nhóm dịch vụ',
    dataIndex: 'tenNhomDV',
    key: 'tenNhomDV',
    width: 250,
  },
  {
    title: 'Mã dịch vụ',
    dataIndex: 'maDV',
    key: 'maDV',
    width: 250,
  },
  {
    title: 'Tên dịch vụ',
    dataIndex: 'tenDV',
    key: 'tenDV',
    width: 250,
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'dvt',
    key: 'dvt',
    width: 250,
  },
  {
    title: 'Số lượng',
    dataIndex: 'soLuong',
    key: 'soLuong',
    width: 250,
  },
  {
    title: 'Đơn giá',
    dataIndex: 'donGia',
    key: 'donGia',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
  {
    title: 'Thành tiền',
    dataIndex: 'thanhTien',
    key: 'thanhTien',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
  {
    title: '% Giảm giá',
    dataIndex: 'ptGiamGia',
    key: 'ptGiamGia',
    width: 250,
  },
  {
    title: 'Tiền giảm',
    dataIndex: 'tienGiam',
    key: 'tienGiam',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
  {
    title: 'Thanh toán',
    dataIndex: 'thanhToan',
    key: 'thanhToan',
    width: 250,
    fixed: 'right',
    render:(text) => formatNumberVND(text)
  },
]
