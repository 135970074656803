import * as typeAction from '../constants/constants'

export const getBranchNhapKho = (idChiNhanh) => ({
  type: typeAction.GET_BRANCH_NHAPKHO,
  idChiNhanh,
})

export const getlistDoitac = () => ({
  type: typeAction.GET_LIST_DOITAC,
})
export const getInfoDoitac = (payload) => ({
  type: typeAction.GET_INFO_DOITCA,
  payload,
})

export const searchThuocVT = (keyword, company) => ({
  type: typeAction.GET_THUOCVT_BY_KEYWORD,
  keyword,
  company,
})
export const fetchInfoThuocVT = (idThuoc, setSelectedItem) => ({
  type: typeAction.GET_INFO_THUOCVT,
  setSelectedItem,
  payload: idThuoc,
})
export const addPhieuNhapKho = (formData, ListThuocVT, xuatPhieuNhapKho) => ({
  type: typeAction.POST_PHIEU_NHAP_KHO,
  payload: formData,
  ListThuocVT,
  xuatPhieuNhapKho,
})
export const getAllPhieuNhapKho = () => ({
  type: typeAction.GET_ALL_PHIEU_NHAP,
})
export const deletePhieuNhapKhoAction = (idPhieu, filter) => ({
  type: typeAction.DELETE_PHIEU_NHAP_KHO,
  idPhieu,
  filter,
})
export const getInfoPTNhapByIdAction = (idNhapXuat) => ({
  type: typeAction.GET_INFO_PT_NHAP_KHO,
  idNhapXuat,
})
export const filterPTNhapKhoAction = (filter) => ({
  type: typeAction.GET_FILTER_PT_NHAP_KHO,
  filter,
})
export const UpdatePTNhapKhoAction = (form, idNhapXuat) => ({
  type: typeAction.UPDATE_INFO_PT_NHAP_KHO,
  form,
  idNhapXuat,
})
export const updateKhoChiTietAction = (form, idNhapXuat, cancel) => ({
  type: typeAction.UPDATE_KHO_CHI_TIET,
  form,
  idNhapXuat,
  cancel,
})
export const DeleteKhoChiTietAction = (id, idNhapXuat) => ({
  type: typeAction.DELETE_KHO_CHI_TIET,
  id,
  idNhapXuat,
})
// LẤY DANH SÁCH CHI NHÁNH KHO NHẬP THEO CÔNG TY
export const branchNhapKhoByCompany = (IDCompany) => ({
  type: typeAction.GET_BRANCH_NHAP_KHO_BY_COMPANY,
  IDCompany,
})
// Cập nhập xát nhân nhập kho
export const updateXacNhan = (idNhapXuat, filter) => ({
  type: typeAction.UPDATE_XAC_NHAN_NHAP_KHO,
  idNhapXuat,
  filter,
})
// Cập nhập xát nhân nhập kho
export const branchNhapKhoPT = (IDCompany) => ({
  type: typeAction.GET_BRANCH_NHAP_KHO_PT,
  IDCompany,
})
//-------- upload file --------//
export const uploadFileNhap = (id, form) => ({
  type: typeAction.UPLOAD_FILE_NHAP_KHO,
  id,
  form,
})
//-------- upload file --------//
export const deleteFileNhap = (id, form) => ({
  type: typeAction.DELETE_FILE_NHAP_KHO,
  id,
  form,
})
//---------update nhập kho ------///
export const acceptNhapKho = (id) => ({
  type: typeAction.ACCPECT_NHAP_KHO_MODAL,
  id,
})
//--------- add thuốc vật tư kho chi tiết ----------//
export const addnewDetailNhapKho = (index, id, idNhapXuat, cancel) => ({
  type: typeAction.ADD_NEW_THUOC_DETAIL_NHAP_KHO,
  index,
  id,
  idNhapXuat,
  cancel,
})
//---------LẤY HANG HOÁ THEO MA HANG ------///
export const getHangHoaByMaHang = (mahang, setMaHang, setSelectedItem, formik) => ({
  type: typeAction.COPY_LIST_HANG_HOA,
  mahang,
  setMaHang,
  setSelectedItem,
  formik,
})
//---------LẤY HANG HOÁ VPP ------///
export const getHangVPPKhoTong = (mahang, setMaHang, iddoitac, setSelectedItem, formik) => ({
  type: typeAction.COPY_PRODUCTS_VPP,
  mahang,
  iddoitac,
  setMaHang,
  setSelectedItem,
  formik,
})
//---------LẤY HANG HOÁ THEO MA HANG ------///
export const updateStatusNhapKho = (maPhieu, status) => ({
  type: typeAction.UPDATE_STATUS_NHAP_KHO_TOOL,
  maPhieu,
  status,
})

export const getInfoFormPhieuNhapVPPAction = (infodt, setIsModalNhapKho) => ({
  type: typeAction.GET_INFO_FORM_PHIEUNHAP_VPP,
  infodt,
  setIsModalNhapKho,
})
export const postListPhieuNhapVPPAction = (form, isPrint, resetFormPhieuNhapVpp, setIsLoading) => ({
  type: typeAction.POST_LIST_PHIEUNHAP_VPP,
  form,
  isPrint,
  resetFormPhieuNhapVpp,
  setIsLoading,
})
