import React, { useEffect } from 'react'
import LayoutApp from '../../../../HOCs/LayoutApp'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getInfoPTNhapByIdAction } from '../../../../store/actions/NhapKhoAction'

const DetailNhapKho = () => {
  const dispatch = useDispatch()
  const {id} = useParams()
  useEffect(() => {
    dispatch(getInfoPTNhapByIdAction(id))
  },[])
  return (
   <>
     <div className='h-full bg-[#EFEFEF]'>
      <h2>detail</h2>
     </div>
   </>
  )
}

export default DetailNhapKho