import * as typeAction from '../constants/constants'

export const getAllGoiKhamCRM = () => ({
  type: typeAction.GET_ALL_GOIKHAM_CRM,
})

export const addGoiKhamCRM = (form, onLoad) => ({
  type: typeAction.ADD_GOIKHAM_CRM,
  form,
  onLoad,
})

export const editGoiKhamCRM = (form, onLoad) => ({
  type: typeAction.EDIT_GOIKHAM_CRM,
  form,
  onLoad,
})

export const getGoiKhamCRMById = (id) => ({
  type: typeAction.GET_GOIKHAM_CRM_BY_ID,
  id,
})

export const getGoiKhamKhachHangCRMByKHId = (id) => ({
  type: typeAction.GET_GOIKHAM_CRM_BY_ID_KH,
  id,
})
