import React, { useEffect, useMemo, useState } from 'react'
import { Table, ConfigProvider } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { formatTTTTChiDinh } from '../../../../utils/formatTTTTChiDinh'
import { CloseOutlined } from '@ant-design/icons'
import * as typeAction from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
const TableChiDinh = ({ CTGiamGiaApply, setCTGiamGiaApply, setIsDelete }) => {
  const dispatch = useDispatch()
  const { ArrXN, ArrCĐHA, ArrKB, discountArr, infoChuongTrinhGiamGiaDoiTac } = useSelector(
    (state) => state.cashierReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const [newArr, setNewArr] = useState([])
  const [tongThanhTien, setTongThanhTien] = useState(0)
  const [tongGiam, setTongGiam] = useState(0)
  const [tongThanhToan, setTongThanhToan] = useState(0)
  const hasLetan = newArr.some((item) => item.letan) // check có gí trị nào của lễ tân thêm hay không
  useEffect(() => {
    let Arr = [...ArrKB, ...ArrXN, ...ArrCĐHA] // nối các mãng lại với nhau
    let arrTemp = []
    Arr.map((item) => {
      arrTemp.push({ ...item, trangthaitt: item.trangthaitt ?? item.idtrangthaitt })
    })
    let arrDaSapXep = arrTemp
      .sort((a, b) => a.trangthaitt - b.trangthaitt)
      .sort((a, b) => (a?.letan ?? 0) - (b?.letan ?? 0))
    // sắp xếp danh sách chỉ định trên phiếu theo trạng thái thanh toán
    setNewArr(arrDaSapXep)
  }, [ArrXN, ArrCĐHA, ArrKB])
  useEffect(() => {
    setTongThanhTien(newArr.reduce((tong, item) => tong + item.dongia, 0))
    setTongGiam(newArr.reduce((tong, item) => tong + item.tiengiamgia, 0))
    setTongThanhToan(newArr.reduce((tong, item) => tong + item.tienthanhtoan, 0))
  }, [newArr])
  const deleteMaCTGGApply = (mact) => {
    if (CTGiamGiaApply?.length > 0 && mact?.length > 0 && CTGiamGiaApply.includes(mact)) {
      setCTGiamGiaApply((prev) => {
        return prev
          ?.replaceAll(mact + ',', '')
          ?.replaceAll(mact, '')
          ?.replaceAll(' ', '')
          ?.replaceAll(',,', ',')
      })
    }
  }
  const handleClickXoaChiDinh = (cd) => {
    if (discountArr?.length > 0 || infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng bỏ voucher hoặc đối tác trước xóa chỉ định!',
      })
      return
    }
    let Arr, index
    if (cd?.iddichvu) {
      index = ArrKB.findIndex((item) => item.iddichvu === cd.iddichvu && item.trangthaitt === 3) // chỉ tìm cd chưa thu
      if (index !== -1) {
        deleteMaCTGGApply(ArrKB[index]?.ctgg?.MACT ?? null)
        // nếu tìm thấy
        Arr = [...ArrKB]
        Arr.splice(index, 1) // xóa
        dispatch({
          // cập nhật lại tổng tiền giảm
          type: typeAction.DISPATCH_TONG_GIAM_CASHIER,
          payload: -ArrKB[index].tiengiamgia ?? 0,
        })
        dispatch({
          // cập nhật lại tổng tiền thanh toán
          type: typeAction.DISPATCH_TONG_THANH_TOAN_CASHIER,
          payload: -ArrKB[index].tienthanhtoan ?? 0,
        })
        dispatch({
          // cập nhật lại list cd dv
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: Arr,
        })
      }
    } else if (cd?.idxn) {
      index = ArrXN.findIndex((item) => item.idxn === cd.idxn && item.trangthaitt === 3) // chỉ tìm cd chưa thu
      if (index !== -1) {
        deleteMaCTGGApply(ArrXN[index]?.ctgg?.MACT ?? null)
        // nếu tìm thấy
        Arr = [...ArrXN]
        Arr.splice(index, 1) // xóa
        dispatch({
          // cập nhật lại tổng tiền giảm
          type: typeAction.DISPATCH_TONG_GIAM_CASHIER,
          payload: -ArrXN[index].tiengiamgia ?? 0,
        })
        dispatch({
          // cập nhật lại tổng tiền thanh toán
          type: typeAction.DISPATCH_TONG_THANH_TOAN_CASHIER,
          payload: -ArrXN[index].tienthanhtoan ?? 0,
        })
        dispatch({
          // cập nhật lại list cd xn
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: Arr,
        })
      }
    } else if (cd?.idcdha) {
      index = ArrCĐHA.findIndex((item) => item.idcdha === cd.idcdha && item.idtrangthaitt === 3) // chỉ tìm cd chưa thu
      if (index !== -1) {
        deleteMaCTGGApply(ArrCĐHA[index]?.ctgg?.MACT ?? null)
        // nếu tìm thấy
        Arr = [...ArrCĐHA]
        Arr.splice(index, 1) // xóa
        dispatch({
          // cập nhật lại tổng tiền giảm
          type: typeAction.DISPATCH_TONG_GIAM_CASHIER,
          payload: -ArrCĐHA[index].tiengiamgia ?? 0,
        })
        dispatch({
          // cập nhật lại tổng tiền thanh toán
          type: typeAction.DISPATCH_TONG_THANH_TOAN_CASHIER,
          payload: -ArrCĐHA[index].tienthanhtoan ?? 0,
        })
        dispatch({
          // cập nhật lại list cd cdha
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: Arr,
        })
      }
    }
  }
  const handleDeleteDB = (item) => {
    if (item.idbnxn) {
      const index = ArrXN.findIndex((i) => i.idbnxn === item.idbnxn)
      let updatedArrXN = [...ArrXN]
      updatedArrXN.splice(index, 1)
      dispatch({
        type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
        payload: updatedArrXN,
      })
      setIsDelete((prevState) => ({
        ...prevState,
        XN: [
          ...prevState.XN,
          {
            id: item.idbnxn,
            donGia: item.dongia,
            status: item.trangthaitt,
            discount: item.tiengiamgia || 0,
          },
        ],
      }))
    } else if (item.idbndv) {
      const index = ArrKB.findIndex((i) => i.idbndv === item.idbndv)
      let updateArrKB = [...ArrKB]
      updateArrKB.splice(index, 1)
      dispatch({
        type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
        payload: updateArrKB,
      })
      setIsDelete((prevState) => ({
        ...prevState,
        DV: [
          ...prevState.DV,
          {
            id: item.idbndv,
            donGia: item.dongia,
            status: item.trangthaitt,
            discount: item.tiengiamgia || 0,
          },
        ],
      }))
    } else if (item.idbncdha) {
      const index = ArrCĐHA.findIndex((i) => i.idbncdha === item.idbncdha)
      let updateArrCDHA = [...ArrCĐHA]
      updateArrCDHA.splice(index, 1)
      dispatch({
        type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
        payload: updateArrCDHA,
      })
      setIsDelete((prevState) => ({
        ...prevState,
        CDHA: [
          ...prevState.CDHA,
          {
            id: item.idbncdha,
            donGia: item.dongia,
            status: item.idtrangthaitt,
            discount: item.tiengiamgia || 0,
          },
        ],
      }))
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenChiDinh',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvitinh',
      align: 'center',
      width: 60,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'tienChiDinh',
      align: 'right',
      width: 90,
    },
    {
      title: '%Giảm',
      dataIndex: 'ptGiam',
      align: 'center',
      width: 60,
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tienGiam',
      align: 'right',
      width: 90,
    },
    {
      title: 'Thanh toán',
      dataIndex: 'tienThanhToan',
      align: 'right',
      width: 90,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      width: 90,
    },
    {
      dataIndex: 'action',
      width: 50,
      align: 'center',
    },
  ]
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
            },
          },
        }}
      >
        <Table
          className="w-full"
          scroll={{
            y: 185,
          }}
          dataSource={newArr?.map((item, index) => ({
            STT: ++index,
            tenChiDinh: item.tendichvu ?? item.tenxn ?? item.tencdha,
            donvitinh: item.donvitinh ?? item.dvt,
            tienChiDinh: formatNumberVND(item?.dongia),
            ptGiam: formatNumberVND(item?.ptgiamgia),
            tienGiam: formatNumberVND(item?.tiengiamgia),
            tienThanhToan: formatNumberVND(item?.tienthanhtoan),
            status: formatTTTTChiDinh(item?.trangthaitt ?? item?.idtrangthaitt),
            // action: (
            //   <span
            //     className={'text-red-500 cursor-pointer ' + (item?.letan ? '' : 'hidden')}
            //     onClick={() => {
            //       handleClickXoaChiDinh(item)
            //     }}
            //   >
            //     <CloseOutlined />
            //   </span>
            // ),

            action: (() => {
              if (item?.letan) {
                return (
                  <span
                    className="text-red-500 cursor-pointer"
                    onClick={() => handleClickXoaChiDinh(item)}
                  >
                    <CloseOutlined />
                  </span>
                )
              }
              if ((infoUser.idNhom === 1 || infoUser.idNhom === 28) && !hasLetan) {
                return (
                  <span
                    className="text-red-500 cursor-pointer"
                    onClick={() => handleDeleteDB(item)}
                  >
                    <CloseOutlined />
                  </span>
                )
              }
              return null
            })(),
          }))}
          pagination={false}
          columns={columns}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell className="text-right" index={3}>
                  <span className="font-semibold">{formatNumberVND(tongThanhTien)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell className="text-right" index={5}>
                  <span className="font-semibold">{formatNumberVND(tongGiam)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-right" index={6}>
                  <span className="font-semibold">{formatNumberVND(tongThanhToan)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default TableChiDinh
