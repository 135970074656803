// src/components/layout/ChildrenClinic/General/Tab/TabDiagnostic.jsx
import React, { useEffect, useState } from 'react'
import { Segmented } from 'antd'
import FormCD from './FormCD/FormCD'
import FormCLS from './FormCLS/FormCLS'
import FormKQXN from './FormKQXN/FormKQXN'
import FormKQCDHA from '../../../MaternityClinic/General/Tab/FormKQCDHA/FormKQCDHA'
import { useFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { updateBnDetail } from '../../../../../store/actions/childrenClinicAction'
import { ChildrenClinicSchema } from '../../../../../schemas/ChilrenClinicSchema'
import HistoryTongQuat from './HistoryTongQuat/HistoryTongQuat'
import Index from '../Index/Index'
import 'moment/locale/vi'
moment.locale('vi')

const tab_CD = 'Chẩn đoán'
const tab_CLS = 'Chỉ định cận lâm sàng'
const tab_KQXN = 'Kết quả xét nghiệm'
const tab_KQCDHA = 'Kết quả CĐHA'
const tab_LSTQ = 'Lịch sử tổng quát'

const TabDiagnostic = ({ defaultValuePK, formBenhNhan }) => {
  const dispatch = useDispatch()
  const now = moment()
  const SO_NGAY_TAI_KHAM = 2
  const initialStartDate = now.add(SO_NGAY_TAI_KHAM, 'days').format('YYYY-MM-DD')
  const { DetailBN, ThuocBN, DetailToaThuocBN, infoBN, historyKham, NgayThuoc, listTonDuoc } =
    useSelector((state) => state.childrenClinicReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [date, setDate] = useState(initialStartDate)
  const [count, setCount] = useState(SO_NGAY_TAI_KHAM)
  const [checkBox, setCheckBox] = useState(false)
  const [segmentedValue, setSegmentedValue] = useState(tab_CD)
  const [isPrintToaThuocKhiLuu, setIsPrintToaThuocKhiLuu] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      ngaytaikham: DetailBN?.ngaytaikham,
      trangthaikhambenh: 2,
      bskham: DetailBN?.bskham,
      lydokham: DetailBN?.lydokham,
      benhsu: DetailBN?.benhsu,
      trieuchung: DetailBN?.trieuchung,
      idchandoan: DetailBN?.idchandoan,
      chandoan: DetailBN?.chandoan,
      idloidan: DetailBN?.idloidan,
      loidan: DetailBN?.loidan,
      tiensubanthan: DetailBN?.tiensubanthan,
      diung: DetailBN?.diung,
      quatrinhbenhly: DetailBN?.quatrinhbenhly,
      tieusugiadinh: DetailBN?.tieusugiadinh,
      toanthan: DetailBN?.toanthan,
      cacbophan: DetailBN?.cacbophan,
      chandoanbandau: DetailBN?.chandoanbandau,
      idicD10BANDAU: DetailBN?.idicD10BANDAU,
      daxuly: DetailBN?.daxuly,
      chandoanravien: DetailBN?.chandoanravien,
      idicD10RAVIEN: DetailBN?.idicD10RAVIEN,
      dieutringoaitru: DetailBN?.dieutringoaitru,
      dienbienlamsang: DetailBN?.dienbienlamsang,
      benhkemtheo: DetailBN?.benhkemtheo,
      idicD10BENHKEMTHEO: DetailBN?.idicD10BENHKEMTHEO,
      ppdieutri: DetailBN?.ppdieutri,
      ttlucravien: DetailBN?.ttlucravien,
      huongxuly: DetailBN?.huongxuly,
      lydokolaythuoc: DetailBN?.lydokolaythuoc,
      chieucao: DetailBN?.chieucao,
      cannang: DetailBN?.cannang,
      mach: DetailBN?.mach,
      huyetap: DetailBN?.huyetap,
      nhietdo: DetailBN?.nhietdo,
      nhiptho: DetailBN?.nhiptho,
      spO2: DetailBN?.spO2,
      ghichu: DetailBN?.ghichu,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: ChildrenClinicSchema,
  })

  const handleSegmentedChange = (value) => {
    setSegmentedValue(value)
  }
  const handleSubmit = (value) => {
    value.idbskham = infoUser?.idnv
    value.bskham = infoUser?.tenNV
    value.ngaytaikham = moment(date).format('YYYY-MM-DD')
    // value.chieucao = DetailBN?.chieucao;
    // value.cannang = DetailBN?.cannang;
    // value.mach = DetailBN?.mach
    // value.huyetap = DetailBN?.huyetap
    // value.nhietdo = DetailBN?.nhietdo
    // value.nhiptho = DetailBN?.nhiptho
    // value.spO2 = DetailBN?.spO2
    value.giorapk = now.format('YYYY-MM-DDTHH:mm:ss')
    // value.bmi = DetailBN?.cannang ? formatNumberVND(DetailBN?.cannang / Math.pow(DetailBN?.chieucao / 100, 2)) : 0;
    const newArr = []
    if (!checkBox && ThuocBN?.length > 0) {
      // lấy thuốc
      for (let item of ThuocBN) {
        newArr.push({
          idbnttc: DetailBN?.idbnttc,
          idthuoc: item?.idthuoc,
          tenBietDuoc: item?.tenBietDuoc,
          tenHoatChat: item?.tenhoatchat,
          duongDung: item?.duongDung,
          dvt: item?.dvt,
          hamLuong: item?.hamLuong,
          ghiChu: item?.ghiChu,
          lieuDung: `${item?.lieuDung ?? 0}`,
          soLanNgay: `${item?.soLanNgay ?? 0}`,
          bsKeDon: infoUser?.tenNV,
          donViDung: item?.donViDung,
          donGia: item?.giaBan,
          isGongKinh: item?.isGongKinh,
          soLuong: item?.soLuong,
          idkhocn: item?.idkhocn,
        })
        // let soLuongLay = item?.soLuong
        // let thuocLay = {
        //   idbnttc: DetailBN?.idbnttc,
        //   idthuoc: item?.idthuoc,
        //   tenBietDuoc: item?.tenBietDuoc,
        //   tenHoatChat: item?.tenhoatchat,
        //   duongDung: item?.duongDung,
        //   dvt: item?.dvt,
        //   hamLuong: item?.hamLuong,
        //   ghiChu: item?.ghiChu,
        //   lieuDung: `${item?.lieuDung ?? 0}`,
        //   soLanNgay: `${item?.soLanNgay ?? 0}`,
        //   bsKeDon: infoUser?.tenNV,
        //   donViDung: item?.donViDung,
        //   donGia: item?.giaBan,
        //   isGongKinh: item?.isGongKinh,
        //   soLuong: item?.soLuong,
        // }
        // listTonDuoc
        //   ?.filter((itemTD) => itemTD?.idThuoc === item?.idthuoc)
        //   ?.sort((a, b) => (a?.hanDung ?? '').localeCompare(b?.hanDung ?? ''))
        //   ?.map((itemTD) => {
        //     thuocLay = {
        //       ...thuocLay,
        //       soLo: itemTD?.soLo,
        //       idkhocn: itemTD?.idKhoCN,
        //       hanDung: itemTD?.hanDung,
        //       soHoaDon: itemTD?.soHoaDon,
        //     }
        //     if (soLuongLay <= itemTD?.tonKho) {
        //       newArr.push({ ...thuocLay })
        //       return
        //     } else {
        //       newArr.push({ ...thuocLay, soLuong: itemTD?.tonKho })
        //       soLuongLay -= itemTD?.tonKho
        //     }
        //   })
        // if (thuocLay?.isGongKinh) {
        //   newArr.push({
        //     ...thuocLay,
        //     soLo: item?.solo,
        //     idkhocn: item?.idkhocn,
        //     hanDung: item?.handung,
        //     soHoaDon: item?.sohoadon,
        //   })
        // }
        // const { ton, ...other } = item
        // other.ngayKeDon = now.format('YYYY-MM-DDTHH:mm:ss')
        // other.idbnttc = DetailBN?.idbnttc
        // newArr.push(other)
      }
    } else value.trangthaikhambenh = 4 // không lấy thuốc
    // console.log(newArr)
    // return
    console.log(value);
    dispatch(
      updateBnDetail(
        infoBN,
        formBenhNhan.values,
        value,
        defaultValuePK,
        newArr,
        isPrintToaThuocKhiLuu,
      ),
    )
  }
  const calculateDiffDays = (start, end) => {
    const startDate = moment(start).startOf('day')
    const endDate = moment(end).startOf('day')
    return endDate.diff(startDate, 'days')
  }

  useEffect(() => {
    const today = moment().startOf('day')
    const diffDays = formik.values.ngaytaikham
      ? calculateDiffDays(today, formik.values.ngaytaikham)
      : 0
    setCount(diffDays)
  }, [date, formik.values.ngaytaikham])
 //--------- set ngày tái khám mặt định-------------//
  useEffect(() => {
    if (!DetailBN?.ngaytaikham) {
      formik.setFieldValue('ngaytaikham', initialStartDate)
    }
    const diffDays = DetailBN?.ngaytaikham
      ? calculateDiffDays(DetailBN?.ngaykham, DetailBN?.ngaytaikham)
      : SO_NGAY_TAI_KHAM
      setCount(diffDays)
  }, [DetailBN])
  //   const selectedDate = moment(date).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  // }, [date])
  // useEffect(() => {
  //   if (NgayThuoc !== count) {
  //     dispatch({
  //       type: typeAction.NGAY_THUOC_NHI,
  //       payload: count,
  //     })
  //   }
  // }, [count])
  useEffect(() => {
    setIsPrintToaThuocKhiLuu(false)
    setCheckBox(
      infoBN && !DetailToaThuocBN?.DanhSachThuoc?.length && DetailBN?.lydokolaythuoc ? true : false,
    )
  }, [DetailBN])
  return (
    <div>
      <div>
        <Index />
      </div>
      <div className="flex gap-2 mt-3">
        <div
          className="w-full bg-white rounded-md"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="mt-3 px-2">
            <div className="flex justify-between">
              <Segmented
                options={[tab_CD, tab_LSTQ, tab_CLS, tab_KQXN, tab_KQCDHA]}
                value={segmentedValue}
                onChange={handleSegmentedChange}
              />
            </div>
            <div>
              {segmentedValue === tab_CD ? (
                <FormCD
                  formBenhNhan={formBenhNhan}
                  checkBox={checkBox}
                  setCheckBox={setCheckBox}
                  formik={formik}
                  setDate={setDate}
                  date={date}
                  count={count}
                  setCount={setCount}
                  isPrintToaThuocKhiLuu={isPrintToaThuocKhiLuu}
                  setIsPrintToaThuocKhiLuu={setIsPrintToaThuocKhiLuu}
                  infoDKX={null}
                />
              ) : segmentedValue === tab_LSTQ ? (
                <HistoryTongQuat historyKham={historyKham} />
              ) : segmentedValue === tab_KQXN ? (
                <FormKQXN />
              ) : segmentedValue === tab_CLS ? (
                <FormCLS formik={formik} />
              ) : (
                <FormKQCDHA />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabDiagnostic
