import { Input, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateStatusNhapKho } from '../../../../store/actions/NhapKhoAction'

const ModalUpdateNhapKho = ({ updateKho, setUpdateKho }) => {
  const [value, setValue] = useState(null)
  const [maPhieu, setMaPhieu] = useState(null)
  const dispatch = useDispatch()
  const handleOk = () => {
    dispatch(updateStatusNhapKho(maPhieu,value))
    setMaPhieu(null)
    setValue(null)
  }
  const handleCancel = () => {
    setUpdateKho(false)
  }
  const isValidMaHang = maPhieu && maPhieu.startsWith("PN") && maPhieu.length === 14;
  return (
    <Modal
    okButtonProps={{ disabled: !isValidMaHang }} // Vô hiệu hóa nút OK nếu không hợp lệ
      onOk={handleOk}
      title="Cập nhập trạng thái khám bệnh"
      open={updateKho}
      onCancel={handleCancel}
    >
      <label className="font-semibold" htmlFor="">
        Nhập mã phiếu nhập kho{' '}
      </label>
      <Input
        value={maPhieu}
        placeholder="Tên phiếu PN....."
        onChange={(e) => setMaPhieu(e.target.value)}
      />
      <Select
        value={value}
        onChange={(value) => setValue(value)}
        className="w-full mt-2"
        placeholder="Chọn trạng thái"
        options={[
          {
            label: 'Chưa nhập',
            value: 5,
          },
        ]}
      />
    </Modal>
  )
}
export default ModalUpdateNhapKho
