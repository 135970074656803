// src/components/layout/ChildrenClinic/General/Index/Index.jsx
import React from 'react'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import { useSelector } from 'react-redux'
import EditableSpan from './EditableSpan'

const Index = () => {
  const { DetailBN } = useSelector((state) => state.childrenClinicReducer)

  const calculateBMI = (weight, height) => {
    if (!weight || !height) return 0
    return formatNumberVND(weight / Math.pow(height / 100, 2))
  }

  return (
    <div className="container mx-auto flex gap-2">
      <ul className="flex gap-2 w-full">
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Chiều cao</p>
          <EditableSpan value={DetailBN?.chieucao ?? 0} unit="Cm" field="chieucao" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Cân nặng</p>
          <EditableSpan value={DetailBN?.cannang ?? 0} unit="Kg" field="cannang" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Mạch</p>
          <EditableSpan value={DetailBN?.mach ?? 0} unit="Lần/phút" field="mach" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Huyết áp</p>
          <EditableSpan value={DetailBN?.huyetap ?? 0} unit="mmHg" field="huyetap" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Nhiệt độ</p>
          <EditableSpan value={DetailBN?.nhietdo ?? 0} unit="&#8451;" field="nhietdo" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Nhịp thở</p>
          <EditableSpan value={DetailBN?.nhiptho ?? 0} unit="Lần/Phút" field="nhiptho" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">Spo2</p>
          <EditableSpan value={DetailBN?.spO2 ?? 0} unit="%" field="spO2" />
        </li>
        <li
          className="w-[12.5%] p-1 rounded-md flex justify-between items-center bg-white"
          style={{ boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)' }}
        >
          <p className="pl-2 text-gray-500">BMI</p>
          <EditableSpan
            value={calculateBMI(DetailBN?.cannang, DetailBN?.chieucao)}
            unit=""
            field="bmi"
            isEditable={false}
          />
        </li>
      </ul>
    </div>
  )
}

export default Index
