import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { dangKyKhamOnlineService } from '../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import ToastCus from '../../utils/Toast'
export function* dangKyKhamSaga() {
  // THÊM GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.PUT_TRANGTHAI_DKK_ONL,
    function* putTrangThaiDangKyKhamOnlSaga({
      type,
      idbndkk,
      trangThaiDK,
      nguoiSua,
      getListDangKyKham,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          dangKyKhamOnlineService.putTrangThaiDangKyKhamOnl(idbndkk, trangThaiDK, nguoiSua),
        )
        yield call(() => getListDangKyKham())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
