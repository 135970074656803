import { ContainerOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import { IconButton, Tooltip } from '@mui/material'
import { Button, ConfigProvider, DatePicker, Input, Modal, Pagination, Select, Table } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  getAllDonHangByTrangThai,
  getInfoDonHang,
  updateTrangThaDatHang,
} from '../../../store/actions/vanPhongPhamAction'
import ToastCus from '../../../utils/Toast'
import { trangThaiDatHang } from '../../../utils/trangThaiDatHang'
import ModalEdit from './Modal/ModalEdit'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const { confirm } = Modal

function NhanHang(props) {
  const { listDatHang } = useSelector((state) => state.vanPhongPhamReducer)
  const dispatch = useDispatch()
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [openEdit, setOpenEdit] = useState(false)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState(+localStorage.getItem('BRANH_LOGIN'))
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [keyword, setKeyword] = useState('')

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
    },
    {
      title: 'Chi nhánh đặt',
      dataIndex: 'chinhanhdat',
      key: 'chinhanhdat',
      width: 150,
    },
    {
      title: 'Bộ phận duyệt',
      dataIndex: 'chinhanhnhan',
      key: 'chinhanhnhan',
      width: 150,
    },
    {
      title: 'Tên đơn hàng',
      dataIndex: 'tendonhang',
      key: 'tendonhang',
      width: 200,
    },
    {
      title: 'Thời gian đặt hàng',
      dataIndex: 'thoigiandathang',
      key: 'thoigiandathang',
      width: 100,
      align: 'center',
    },
    {
      title: 'Người đặt hàng',
      dataIndex: 'nguoidat',
      key: 'nguoidat',
      width: 120,
    },
    // {
    //   title: 'Ngày đặt',
    //   dataIndex: 'ngaydat',
    //   key: 'ngaydat',
    //   width: 80,
    //   align: 'center',
    // },
    // {
    //   title: 'Người duyệt',
    //   dataIndex: 'nguoiduyet',
    //   key: 'nguoiduyet',
    //   width: 120,
    // },
    // {
    //   title: 'Ngày duyệt',
    //   dataIndex: 'ngayduyet',
    //   key: 'ngayduyet',
    //   width: 80,
    //   align: 'center',
    // },

    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 120,
    },
    {
      title: 'Trạng thái',
      key: 'trangthai',
      dataIndex: 'trangthai',
      width: 50,
      align: 'center',
      fixed: 'right',
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
    },
  ]

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const handleShowModalDetail = (record) => {
    setOpenEdit(true)
    //call api get info
    //dispatch
    dispatch(getInfoDonHang(record.iddathang))
  }

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setChiNhanh('')
    getAllCN_By_idct(value)
  }

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
  }

  const onLoad = () => {
    dispatch(
      getAllDonHangByTrangThai(
        keyword,
        dateFrom,
        dateTo,
        1,
        trangThaiDatHang.nhapHang,
        company,
        chiNhanh,
      ),
    )
  }

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
  }, [])

  const handleChangePage = (keyword, dateFrom, dateTo, company, chiNhanh, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(
      getAllDonHangByTrangThai(
        keyword,
        dateFrom,
        dateTo,
        page,
        trangThaiDatHang.nhapHang,
        company,
        chiNhanh,
      ),
    )
  }

  const showConfirm = (item) => {
    confirm({
      title: (
        <div>
          <p>Xác nhận nhận hàng:</p>
          <p className="font-bold">{item?.tendonhang}</p>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      content: `Nhận hàng thành công (sẽ không được chỉnh sửa số lượng)`,
      okText: 'Xác nhận',
      cancelText: 'Huỷ bỏ',
      onOk() {
        ;(async () => {
          try {
            const { data } = await https.get(
              `VPPDatHang/GetInfoVPPDatHang?iddathang=${item.iddathang}`,
            )
            let check = false
            const { DanhSachHangHoa } = data[0]
            if (DanhSachHangHoa.length > 0) {
              for (let i = 0; i < DanhSachHangHoa.length; i++) {
                const item = DanhSachHangHoa[i]
                //check sl duyet
                if (item.soluonggiao === null || item.soluongnhan === null) {
                  ToastCus.fire({
                    icon: 'error',
                    title: 'Chưa xác nhận số lượng giao/nhận',
                  })
                  check = true
                  return
                }
                if (item.nguoigiao === null || item.nguoigiao === null) {
                  ToastCus.fire({
                    icon: 'error',
                    title: 'Chưa nhập người giao/ngày giao',
                  })
                  check = true
                  return
                }
              }
            }
            if (!check) {
              //update trang thai dat hang ve 7 hoan thanh don hang
              dispatch(
                updateTrangThaDatHang(item.iddathang, trangThaiDatHang.hoanThanh, '', onLoad),
              )
            }
          } catch (error) {
            console.log(error)
          }
        })()
      },
      onCancel() {},
    })
  }

  //setTotalPage
  useEffect(() => {
    if (listDatHang?.totalPages) setTotalPage(listDatHang?.totalPages)
  }, [listDatHang?.totalPages])

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
      <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
        <div className="flex gap-5 justify-between w-full">
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
          />
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleChiNhanh}
            value={chiNhanh}
            options={[
              {
                tenchinhanh: 'Tất cả',
                idchinhanh: '',
              },
              ...listChiNhanh,
            ]?.map((items) => ({
              label: items.tenchinhanh,
              value: items.idchinhanh,
            }))}
          />
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-64">
            <Input
              className="w-full"
              placeholder="Nhập từ khóa..."
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
            />
          </div>
          <div className="flex gap-3 mr-auto">
            <IconButton
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() =>
                dispatch(
                  getAllDonHangByTrangThai(
                    keyword,
                    dateFrom,
                    dateTo,
                    1,
                    trangThaiDatHang.nhapHang,
                    company,
                    chiNhanh,
                  ),
                )
              }
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="ml-auto">
            {/* <Button
              //   onClick={() => exportToExcel(search, chiNhanh, company, dateFrom, dateTo)}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              //   loading={loadingExport}
              //   disabled={loadingExport}
            >
              Xuất Excel
            </Button> */}
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              bordered
              scroll={{
                y: 800,
              }}
              pagination={false}
              columns={columns}
              dataSource={listDatHang?.data?.map((item, idx) => ({
                ...item,
                stt: (currentPage - 1) * 20 + (idx + 1),
                thoigiandathang: item.thoigiandathang
                  ? moment(item.thoigiandathang).format('DD/MM/YYYY HH:mm')
                  : '',
                ngaydat: item.ngaydat ? moment(item.ngaydat).format('DD/MM/YYYY') : '',
                trangthai:
                  item.trangThaiDatHang === trangThaiDatHang.hoanThanh ? (
                    <Button
                      size="small"
                      type="default"
                      style={{
                        fontSize: 12,
                        padding: 2,
                      }}
                      disabled
                    >
                      Nhận hàng thành công
                    </Button>
                  ) : (
                    <Button
                      onClick={() => showConfirm(item)}
                      size="small"
                      type="primary"
                      style={{
                        fontSize: 12,
                        padding: 2,
                      }}
                    >
                      Hoàn thành
                    </Button>
                  ),
                action: (
                  <>
                    <Tooltip title="Xem chi tiết" color="#108ee9" className="ml-4">
                      <ContainerOutlined
                        onClick={() => {
                          handleShowModalDetail(item)
                        }}
                        className="text-xl text-[#108ee9]  cursor-pointer"
                      />
                    </Tooltip>
                  </>
                ),
              }))}
            />
          </ConfigProvider>
          <div className="flex justify-end">
            <Pagination
              className="mt-5"
              showSizeChanger={false}
              current={+useSearch.get('page')}
              simple
              defaultCurrent={1}
              pageSize={20}
              total={20 * totalPage}
              onChange={(page, pagesize) => {
                handleChangePage(keyword, dateFrom, dateTo, company, chiNhanh, page)
              }}
            />
          </div>
        </div>
      </div>
      {openEdit && <ModalEdit openEdit={openEdit} setOpenEdit={setOpenEdit} />}
    </div>
  )
}

NhanHang.propTypes = {}

export default NhanHang
