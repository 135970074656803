import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  groupNhan: [],
  Nhans: [],
  danhMucNhan: [],
  infoNhan: null,
  thucHien: [],
  DanhSachBaoHong: [],
  vungBaoHong: [],
  nhanVien: [],
  DetailBaoHong: null,
  AllDanhMuc: [],
  KTBaoHong: [],
  imgBaoHong: null,
  ChiNhanhVung: [],
  InfoXuLy: null,
  DanhSachHoangThanh: [],
  HistoryStaff: [],
  ThongKe:[]
}

const suCoReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.DISPATCH_GROUPS_NHAN_SU_CO:
        draft.groupNhan = payload
        break
      case typeAction.DISPATCH_NHAN_SU_CO:
        draft.Nhans = payload
        break
        case typeAction.DISPATCH_THONG_KE_BAO_HONG:
        draft.ThongKe = payload
        break
      case typeAction.DISPATCH_DANH_MUC_NHAN:
        draft.danhMucNhan = payload
        break
      case typeAction.DISPACTH_HISTORY_STAFF_SU_CO:
        draft.HistoryStaff = payload
        break
      case typeAction.DISPATCH_INFO_NHAN:
        draft.infoNhan = payload
        break
      case typeAction.DISPATCH_THUC_HIEN_BY_VUNG:
        draft.thucHien = payload
        break
      case typeAction.DISPATCH_DANH_SACH_BAO_HONG:
        draft.DanhSachBaoHong = payload
        break
      case typeAction.DISPATCH_GET_VUNG_BAO_HONG:
        draft.vungBaoHong = payload
        break
      case typeAction.DISPATCH_NHAN_VIEN_SU_CO:
        draft.nhanVien = payload
        break
      case typeAction.DISPATCH_INFO_BAO_HONG:
        draft.DetailBaoHong = payload
        break
      case typeAction.DISPATCH_ALL_HANG_MUC_SU_CO:
        draft.AllDanhMuc = payload
        break
      case typeAction.DISPATCH_KT_THU_THIEN_BAO_HONG:
        draft.KTBaoHong = payload
        break
      case typeAction.GET_INMAGE_BAO_HONG:
        draft.imgBaoHong = payload
        break
      case typeAction.DISPATCH_CHI_NHANH_VUNG:
        draft.ChiNhanhVung = payload
        break
      case typeAction.DISPATCH_INFO_XU_LY_BAO_HONG:
        draft.InfoXuLy = payload
        break
      case typeAction.DISPATCH_DANH_SACH_BAO_HONG_HOANG_THANH:
        draft.DanhSachHoangThanh = payload
        break
      default:
        return state
    }
  })
}

export default suCoReducer
