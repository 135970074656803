import { Tabs } from 'antd'
import React from 'react'
import BanLe from './BanLe'
import PhieuBanLe from './PhieuBanLe'
import Producs from '../Selling/Producs/Producs'

const LayoutBanLe = () => {
  const LoaiThanhToan = 5 // bán lẻ
  return (
    <div className="h-full p-2 bg-[#EFEFEF]">
      <div className="bg-white p-2 rounded-xl h-full">
        <Tabs
          items={[
            {
              key: 1,
              label: 'Bản lẻ',
              children: <BanLe />,
            },
            {
              key: 2,
              label: 'Phiếu thu',
              children: <PhieuBanLe />,
            },
            {
              key: 3,
              label: 'Hàng hoá',
              children: <Producs IDLoaiThanhToan={LoaiThanhToan} />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default LayoutBanLe
