import * as typeAction from '../constants/constants'

export const BNChoKhamSangLocAction = (idcn) => ({
  type: typeAction.GET_BN_CHO_KHAM_SANG_LOC,
  idcn,
})
export const BNDangVaDaKhamSangLocAction = (idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_BN_DANG_VA_DA_KHAM_SANG_LOC,
  idcn,
  tuNgay,
  denNgay,
})
export const getInfoBNKhamSangLocAction = (idbnttc, idbn, idcn) => ({
  type: typeAction.GET_INFO_BN_KSL,
  idbnttc,
  idbn,
  idcn,
})
export const postInfoBNKhamSangLocAction = (form, idcn, tungay, denngay, formPrint) => ({
  type: typeAction.POST_INFO_BN_KSL,
  form,
  idcn,
  tungay,
  denngay,
  formPrint,
})
export const updateBnDetailKSL = (
  infoBN,
  formBenhNhan,
  form,
  idcnPk,
  newArr,
  formksl,
  idcn,
  tungay,
  denngay,
  formPrint,
) => ({
  type: typeAction.UPDATE_DETAIL_BN_KHAM_SANG_LOC,
  infoBN,
  formBenhNhan,
  form,
  idcnPk,
  newArr,
  formksl,
  idcn,
  tungay,
  denngay,
  formPrint,
})
