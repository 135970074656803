import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { ConfigProvider, DatePicker, Input, InputNumber, Select, Table } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import _, { cloneDeep } from 'lodash'
import React, { memo, useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../../../store/constants/constants'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import InputMSDK from './InputMSDK'
import ToastCus from '../../../../../utils/Toast'

moment.locale('vi')
const TableChiTiet = memo(({ selectedItem, infoCN }) => {
  const [isFocused, setIsFocused] = useState(false)
  const { listVPP } = useSelector((state) => state.NhapKhoReducer)
  const today = moment().startOf('day') // Set to the start of today
  // const { listHang } = useSelector((state) => state.NhapKhoReducer)
  const newInputRef = useRef(null)
  useEffect(() => {
    if (selectedItem && newInputRef.current) {
      newInputRef.current.focus()
    }
  }, [selectedItem])
  // //------------ tính giá tiền ------------//
  // const tongTien = infoCN?.list?.reduce(
  //   (total, items) =>
  //     total +
  //     (items?.khoChiTiet?.soLuong * items?.khoChiTiet?.soLuongDongGoi +
  //       items?.khoChiTiet?.countLe) *
  //       items?.khoChiTiet?.donGiaMua,
  //   0,
  // )
  // const tienCK = infoCN?.list?.reduce((total, items) => (total += items?.khoChiTiet?.ckTruocVat), 0)
  // const vat5 = infoCN?.list?.reduce((total, items) => (total += items?.khoChiTiet?.vat5), 0)
  // const vat8 = infoCN?.list?.reduce((total, items) => (total += items?.khoChiTiet?.vat8), 0)
  // const vat10 = infoCN?.list?.reduce((total, items) => (total += items?.khoChiTiet?.vat10), 0)
  // const thucTra = infoCN?.list?.reduce((total, items) => (total += items?.khoChiTiet?.thucTra), 0)
  // const today = moment();
  const dispatch = useDispatch()
  // thay đổi số lượng thuốc

  const handleTinhTien = (khoChiTiet) => {
    let khoSL = cloneDeep(khoChiTiet)
    // tổng tiền
    var total = (khoSL.soLuongDongGoi * khoSL.soLuong + khoSL.countLe) * khoSL.donGiaMua
    // tổng tiền chiết khấu trước VAT
    var totalCKVAT = total - (total * khoSL.ptckTruocVat) / 100
    // tổng sau tiền VAT
    var totalVAT = totalCKVAT + khoSL.ptVAT * totalCKVAT
    // tinh toán
    khoSL.ckTruocVat = total - totalCKVAT
    khoSL.tongTienTruocVAT = totalCKVAT
    khoSL.thanhTien = totalVAT
    khoSL.thucTra = totalVAT
    khoSL.tienVAT = totalVAT - totalCKVAT
    if (khoSL.ptVAT === 0.08) {
      khoSL.vat8 = totalVAT - totalCKVAT
    } else if (khoSL.ptVAT === 0.05) {
      khoSL.vat5 = totalVAT - totalCKVAT
    } else if (khoSL.ptVAT === 0.1) {
      khoSL.vat10 = totalVAT - totalCKVAT
    }
    return khoSL
  }
  const onChangSLChan = (value, idx, cthang) => {
    // value = parseFloat(value?.replace(/[^0-9]/g, ''))
    if (value * cthang?.QUYCACHDONGGOI + cthang?.khoChiTiet?.countLe > cthang?.slVPPmua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Số lượng nhập không được quá số lượng đã mua!',
      })
      return
    }
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.soLuong = _.isNaN(value) || value < 0 || !value ? 0 : value
        infoVPP.list[idx].khoChiTiet = handleTinhTien(infoVPP.list[idx].khoChiTiet)
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
  }
  const onChangeCountLe = (value, idx, cthang) => {
    if (cthang?.khoChiTiet?.soLuong * cthang?.QUYCACHDONGGOI + value > cthang?.slVPPmua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Số lượng nhập không được quá số lượng đã mua!',
      })
      return
    }
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.countLe = _.isNaN(value) || value < 0 || !value ? 0 : value
        infoVPP.list[idx].khoChiTiet = handleTinhTien(infoVPP.list[idx].khoChiTiet)
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // dispatch({
    //   type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
    //   payload: listVPP?.map((item) =>
    //     item?.idcn === infoCN?.idcn
    //       ? {
    //           ...item,
    //           list: item?.list?.map((itemL, index) =>
    //             index === idx
    //               ? {
    //                   ...itemL,
    //                   khoChiTiet: { ...itemL?.khoChiTiet, countLe: _.isNaN(value) ? 0 : value },
    //                 }
    //               : itemL,
    //           ),
    //         }
    //       : item,
    //   ),
    // })
  }
  const handlePhiGiaCong = (idx) => (value) => {
    // const { value } = e.target
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.phiGiaCong = _.isNaN(value) || value < 0 || !value ? 0 : value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // dispatch({
    //   type: typeAction.EDIT_PHI_GIA_CONG_BY_ID,
    //   payload: {
    //     id,
    //     value: Number(value),
    //   },
    // })
  }
  // thay đổi giá phi phát sing
  const handlePhiVanChuyen = (idx) => (value) => {
    // const { value } = e.target
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.phiVanChuyen =
          _.isNaN(value) || value < 0 || !value ? 0 : value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // const value = e.target.value
    // dispatch({
    //   type: typeAction.EDIT_PHI_VAN_CHUYEN_BY_ID,
    //   payload: {
    //     id,
    //     value: Number(value),
    //   },
    // })
  }
  // %CK trước VAT
  const ptChiecKhauVAT = (idx) => (value) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.ptckTruocVat =
          _.isNaN(value) || value < 0 || !value ? 0 : value > 100 ? 100 : parseFloat(value)
        infoVPP.list[idx].khoChiTiet = handleTinhTien(infoVPP.list[idx].khoChiTiet)
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // const { value } = e.target
    // // Nếu value là null, undefined hoặc chuỗi rỗng thì gán value = 0,
    // // nếu không thì giới hạn giá trị trong khoảng từ 0 đến 100
    // let newValue =
    //   value === null || value === undefined || value === ''
    //     ? 0
    //     : Math.max(0, Math.min(100, Number(value)))
    // dispatch({
    //   type: typeAction.EDIT_PT_CK_VAT_BY_ID,
    //   payload: {
    //     id,
    //     value: newValue,
    //   },
    // })
  }
  const priceTruocVAT = (value, idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        let khoct = cloneDeep(infoVPP.list[idx].khoChiTiet)
        let newValue = _.isNaN(value) || value < 0 || !value ? 0 : value
        khoct.tongTienTruocVAT = newValue
        let tong = newValue / (1 - khoct.ptckTruocVat / 100)
        khoct.ckTruocVat = (tong * khoct.ptckTruocVat) / 100
        khoct.donGiaMua = tong / (khoct.soLuong * khoct.soLuongDongGoi + khoct.countLe)
        khoct.tienVAT = khoct.ptVAT * newValue
        khoct.thanhTien = newValue + khoct.ptVAT * newValue
        khoct.thucTra = newValue + khoct.ptVAT * newValue
        if (khoct.ptVAT === 0.08) {
          khoct.vat8 = khoct.ptVAT * newValue
        } else if (khoct.ptVAT === 0.05) {
          khoct.vat5 = khoct.ptVAT * newValue
        } else if (khoct.ptVAT === 0.1) {
          khoct.vat10 = khoct.ptVAT * newValue
        }
        infoVPP.list[idx].khoChiTiet = khoct
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // // let { value } = e.target
    // const index = infoCN?.list?.findIndex((items) => items?.id === id)
    // // value = Number(value)
    // dispatch({
    //   type: typeAction.DISPATCH_GIA_TRUOC_VAT_NHAP_KHO,
    //   payload: {
    //     index,
    //     value,
    //   },
    // })
  }
  const handleVAT = (idx) => (value) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        let khoct = cloneDeep(infoVPP.list[idx].khoChiTiet)
        khoct.ptVAT = parseFloat(value)
        khoct.tienVAT = Number(
          (
            ((khoct.soLuong * khoct.soLuongDongGoi + khoct.countLe) * khoct.donGiaMua -
              khoct.ckTruocVat) *
            khoct.ptVAT
          ).toFixed(0),
        )
        khoct.vat5 = khoct.ptVAT === 0.05 ? khoct.tienVAT : 0
        khoct.vat8 = khoct.ptVAT === 0.08 ? khoct.tienVAT : 0
        khoct.vat10 = khoct.ptVAT === 0.1 ? khoct.tienVAT : 0
        khoct.thanhTien =
          (khoct.soLuong * khoct.soLuongDongGoi + khoct.countLe) * khoct.donGiaMua +
          khoct.tienVAT -
          khoct.ckTruocVat
        //THỰC TRẢ
        khoct.thucTra = khoct.thanhTien
        // (khoct.soLuong * khoct.soLuongDongGoi + khoct.countLe) * khoct.donGiaMua +
        // khoct.tienVAT -
        // khoct.ckTruocVat
        infoVPP.list[idx].khoChiTiet = khoct
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // dispatch({
    //   type: typeAction.DISPATCH_VAT_THUOCVT,
    //   payload: {
    //     id,
    //     value,
    //   },
    // })
  }
  const onChangePriceVAT = (value, idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        let khoct = cloneDeep(infoVPP.list[idx].khoChiTiet)
        khoct.tienVAT = _.isNaN(value) || value < 0 || !value ? 0 : value

        khoct.vat5 = khoct.ptVAT === 0.05 ? khoct.tienVAT : 0
        khoct.vat8 = khoct.ptVAT === 0.08 ? khoct.tienVAT : 0
        khoct.vat10 = khoct.ptVAT === 0.1 ? khoct.tienVAT : 0
        // if (khoct.ptVAT === 0.05) {
        //   khoct.vat5 = newValue
        // } else if (khoct.ptVAT === 0.08) {
        //   khoct.vat8 = newValue
        // }
        // if (khoct.ptVAT === 0.1) {
        //   khoct.vat10 = newValue
        // }
        khoct.thanhTien = khoct.tongTienTruocVAT + khoct.tienVAT
        khoct.thucTra = khoct.thanhTien

        infoVPP.list[idx].khoChiTiet = khoct
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // const index = infoCN?.list?.findIndex((items) => items?.id === id)
    // dispatch({
    //   type: typeAction.DISPATCH_GIA_VAT_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value || 0,
    //   },
    // })
  }
  const changePriceThucTra = (value, idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.thucTra = _.isNaN(value) || value < 0 || !value ? 0 : value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // const index = infoCN?.list?.findIndex((items) => items?.id === id)
    // dispatch({
    //   type: typeAction.DISPATCH_CHANGE_PRICE_THUC_TRA,
    //   payload: {
    //     index,
    //     value: value || 0,
    //   },
    // })
  }

  const Changenhietdo = (value, idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.nhietDo = value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // let { value } = e.target
    // const index = infoCN?.list?.findIndex((items) => items?.id === id)

    // dispatch({
    //   type: typeAction.CHANGE_NONG_DO_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value,
    //   },
    // })
  }
  // handle số lô
  const hanldChangeSoLo = (idx) => (e) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.soLo = e.target.value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // const value = e.target.value
    // dispatch({
    //   type: typeAction.DISPATCH_SOLO_THUOCVT,
    //   payload: {
    //     id,
    //     value,
    //   },
    // })
  }
  const handleHanDung = (idx, dateString) => {
    const date = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.hanDung = date
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })

    // dispatch({
    //   type: typeAction.DISPATCH_HANDUNG_THUOCVT,
    //   payload: {
    //     date,
    //     id,
    //   },
    // })
  }
  const changeGhichu = (value, idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.ghiChu = value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // let { value } = e.target
    // const index = infoCN?.list?.findIndex((items) => items?.id === id)

    // dispatch({
    //   type: typeAction.DISPATCH_GHI_CHU_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value,
    //   },
    // })
  }
  // const ChangePrice = (e, id) => {
  //   let { value } = e.target
  //   const index = infoCN?.list?.findIndex((items) => items?.id === id)
  //   value = parseFloat(value.replace(/[^0-9]/g, ''))

  //   dispatch({
  //     type: typeAction.CHANGE_PRICE_THUOC_VAT_TU,
  //     payload: {
  //       index,
  //       value: value || 0,
  //     },
  //   })
  // }
  // const Changemsdk = (e, id) => {
  //   let { value } = e.target
  //   const index = infoCN?.list?.findIndex((items) => items?.id === id)

  //   dispatch({
  //     type: typeAction.CHANGE_MSDK_NHAP_KHO,
  //     payload: {
  //       index,
  //       value: value,
  //     },
  //   })
  // }
  // remote infoThuocByID
  const addInfoThuocById = (idthuoc, idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        let thuocNew = cloneDeep(infoVPP.list[idx])
        thuocNew.khoChiTiet.tienVAT = 0
        thuocNew.khoChiTiet.ptVAT = 0.05
        thuocNew.khoChiTiet.soLuong = 0
        thuocNew.khoChiTiet.countLe = 0 //
        thuocNew.khoChiTiet.vat5 = 0
        thuocNew.khoChiTiet.vat8 = 0
        thuocNew.khoChiTiet.vat10 = 0
        thuocNew.khoChiTiet.thanhTien = 0
        thuocNew.khoChiTiet.thucTra = 0
        thuocNew.khoChiTiet.tongTienTruocVAT = 0
        infoVPP.list.push(thuocNew)
        newArr.push({
          ...infoVPP,
          list: infoVPP.list.sort((a, b) =>
            (a?.TENBIETDUOC ?? '')
              .toLowerCase()
              .localeCompare((b?.TENBIETDUOC ?? '').toLowerCase()),
          ),
        })
      } else newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // dispatch({
    //   type: typeAction.DELETE_INFO_THUOCVT_BY_ID,
    //   payload: id,
    // })
  }
  const deleteInfoThuocById = (idx) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        newArr.push({ ...infoVPP, list: infoVPP.list.filter((vpp, index) => index != idx) })
      } else newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // dispatch({
    //   type: typeAction.DELETE_INFO_THUOCVT_BY_ID,
    //   payload: id,
    // })
  }
  const onChangeCK = (value, id) => {
    const index = infoCN?.list?.findIndex((items) => items?.id === id)
    dispatch({
      type: typeAction.DISPACTH_TIEN_CHIEC_KHAU_NHAP_KHO,
      payload: {
        index,
        value: value || 0,
      },
    })
  }

  const handleMaSoDangKyChange = (value, id) => {
    // const index = infoCN?.list?.findIndex((items) => items?.id === id)
    // dispatch({
    //   type: typeAction.CHANGE_MSDK_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value,
    //   },
    // })
  }
  const columns = useMemo(
    () => [
      {
        key: 1,
        title: 'Thông tin hàng',
        children: [
          {
            key: '1.1',
            title: 'STT',
            dataIndex: 'STT',
            width: 40,
            fixed: true,
            align: 'center',
          },
          {
            key: '1.2',
            title: 'Tên hàng',
            dataIndex: 'TENHANG',
            width: 200,
            fixed: true,
          },
          {
            key: '1.3',
            title: 'Mã hàng',
            dataIndex: 'MAHANG',
            width: 70,
            fixed: true,
          },
        ],
      },
      {
        key: 2,
        title: 'Đơn vị chẵn',
        children: [
          {
            key: '2.1',
            title: 'SL',
            dataIndex: 'SLCHAN',
            width: 60,
            align: 'center',
            editable: true,
          },
          {
            key: '2.2',
            title: 'Đơn vị',
            dataIndex: 'DVCHAN',
            align: 'center',
            width: 60,
          },
          {
            key: '2.3',
            title: 'Quy cách',
            dataIndex: 'QUYCACH',
            align: 'center',
            width: 70,
          },
          {
            key: '2.4',
            title: 'SL quy đổi',
            dataIndex: 'SLQUYDOI',
            align: 'center',
            width: 70,
          },
          {
            key: '2.5',
            title: 'Đơn giá',
            dataIndex: 'DGCHAN',
            align: 'right',
            width: 80,
          },
        ],
      },
      {
        key: 3,
        title: 'Đơn vị lẻ',
        children: [
          {
            key: '3.1',
            title: 'SL',
            dataIndex: 'countLe',
            width: 80,
            align: 'center',
          },
          {
            key: '3.2',
            title: 'Đơn vị',
            dataIndex: 'DVLE',
            align: 'center',
            width: 60,
          },
          {
            key: '3.3',
            title: 'Tổng Lẻ',
            dataIndex: 'SLLE',
            width: 80,
            align: 'center',
          },
        ],
      },
      {
        key: 4,
        title: 'Thành tiền',
        children: [
          {
            key: '4.1',
            title: 'Tổng tiền',
            dataIndex: 'TONGTIEN',
            align: 'right',
            width: 90,
          },
          {
            key: '4.2',
            title: 'P. Gia công',
            dataIndex: 'PHIGIACONG',
            width: 75,
            align: 'center',
          },
          {
            key: '4.3',
            title: 'P. Vận chuyển',
            dataIndex: 'PHIVANCHUYEN',
            width: 89,
            align: 'center',
          },
          {
            key: '4.4',
            title: '%CK trước VAT',
            dataIndex: 'CKTRUOCVAT',
            width: 95,
            align: 'center',
          },
          {
            key: '4.5',
            title: 'Tiền CK trước VAT',
            dataIndex: 'TIENCKTRUOCVAT',
            width: 110,
            align: 'right',
          },
          {
            key: '4.6',
            title: 'Thành tiền',
            dataIndex: 'THANHTIENTRVAT',
            width: 120,
            align: 'right',
          },
          {
            key: '4.7',
            title: 'VAT',
            dataIndex: 'VAT',
            width: 80,
            align: 'center',
          },
          {
            key: '4.8',
            title: 'Tiền %VAT',
            dataIndex: 'TIENVAT',
            width: 100,
            align: 'center',
          },
          {
            key: '4.9',
            title: 'Thành tiền',
            dataIndex: 'THANHTIEN',
            width: 120,
            align: 'right',
          },
          {
            key: '4.10',
            title: 'Thực trả',
            dataIndex: 'THUCTRA',
            width: 120,
            align: 'right',
          },
        ],
      },
      {
        key: 5,
        title: 'Chi tiết',
        children: [
          {
            key: '5.1',
            title: 'Nhiệt độ',
            dataIndex: 'NHIETDO',
            align: 'center',
            width: 100,
          },
          {
            key: '5.2',
            title: 'Mã Số DK',
            dataIndex: 'MASODANGKY',
            align: 'center',
            width: 180,
          },
          {
            key: '5.3',
            title: 'Số lô',
            dataIndex: 'SOLO',
            width: 90,
            align: 'center',
          },
          {
            key: '5.4',
            title: 'Hạn dùng',
            dataIndex: 'HANDUNG',
            align: 'center',
            width: 120,
          },
          {
            key: '5.5',
            title: 'Ghi chú',
            dataIndex: 'GHICHU',
            align: 'center',
            width: 150,
          },
        ],
      },
      {
        key: 6,
        title: '',
        dataIndex: 'ACTION',
        align: 'center',
        fixed: 'right',
        width: 60,
        // render: (text, record, index) => (
        //   <div className="flex justify-center items-center gap-1">
        //     <PlusOutlined className="text-white bg-green-500 p-1 rounded-md cursor-pointer hover:bg-green-400" />
        //     <CloseOutlined className="text-white bg-red-500 p-1 rounded-md cursor-pointer hover:bg-red-400" />
        //   </div>
        // ),
      },
    ],
    [infoCN],
  )
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 12,
          },
        }}
      >
        <Table
          bordered
          pagination={false}
          scroll={{
            y: 250,
          }}
          columns={columns}
          dataSource={useMemo(
            () => [
              ...infoCN?.list?.map((items, index) => ({
                key: index,
                STT: ++index,
                TENHANG: items?.TENBIETDUOC,
                MAHANG: items?.MATHUOC,
                QUYCACH: items?.QUYCACHDONGGOI,
                SLQUYDOI: items?.QUYCACHDONGGOI * items?.khoChiTiet?.soLuong,
                SLCHAN: (
                  <InputNumber
                    ref={selectedItem === items?.id ? newInputRef : null}
                    min={0}
                    defaultValue={1}
                    className="p-0 text-center w-full"
                    size="small"
                    value={items?.khoChiTiet?.soLuong}
                    // type="number"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => {
                      onChangSLChan(value, index - 1, items)
                    }}
                  />
                ),
                countLe: (
                  <InputNumber
                    className="w-full"
                    size="small"
                    value={items?.khoChiTiet?.countLe}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => onChangeCountLe(value, index - 1, items)}
                  />
                ),
                DVCHAN: items?.DONVICHAN,
                DGCHAN: formattedNumber(items?.khoChiTiet?.donGiaMua * items?.QUYCACHDONGGOI),
                SLLE: (
                  <div
                    className={
                      items?.khoChiTiet?.soLuong * items?.QUYCACHDONGGOI +
                        items?.khoChiTiet?.countLe >
                      0
                        ? ''
                        : 'text-red-500'
                    }
                  >
                    {items?.khoChiTiet?.soLuong * items?.QUYCACHDONGGOI +
                      items?.khoChiTiet?.countLe}
                  </div>
                ),
                // () => {
                //   const tongLe =
                //     items?.khoChiTiet?.soLuong * items?.QUYCACHDONGGOI + items?.khoChiTiet?.countLe
                //   return
                // },
                DVLE: items?.DVT,
                // DGLE: (
                //   <Input
                //     size="small"
                //     onChange={(e) => ChangePrice(e, items?.id)}
                //     value={formattedNumber(items?.GIAMUA)}
                //   />
                // ),
                TONGTIEN: formattedNumber(
                  (items?.khoChiTiet?.soLuong * items?.QUYCACHDONGGOI +
                    items?.khoChiTiet?.countLe) *
                    items?.khoChiTiet?.donGiaMua,
                ),
                PHIGIACONG: (
                  <InputNumber
                    value={items?.khoChiTiet?.phiGiaCong}
                    onChange={handlePhiGiaCong(index - 1)}
                    // type="number"
                    min={0}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    defaultValue={0}
                    className="p-0 text-center w-full"
                    size="small"
                  />
                ),
                PHIVANCHUYEN: (
                  <InputNumber
                    value={items?.khoChiTiet?.phiVanChuyen}
                    onChange={handlePhiVanChuyen(index - 1)}
                    // type="number"
                    min={0}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    defaultValue={0}
                    className="p-0 text-center w-full"
                    size="small"
                  />
                  // <Input
                  //   value={items?.khoChiTiet?.phiVanChuyen}
                  //   onChange={handlePhiVanChuyen(items?.id)}
                  //   type="number"
                  //   defaultValue={0}
                  //   className="p-0 text-center"
                  // />
                ),
                CKTRUOCVAT: (
                  <InputNumber
                    value={items?.khoChiTiet?.ptckTruocVat}
                    onChange={ptChiecKhauVAT(index - 1)}
                    // type="number"
                    min={0}
                    max={100}
                    step={1}
                    // formatter={(value) => {
                    //   const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                    //   return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    // }}
                    // parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    defaultValue={0}
                    className="p-0 text-center w-full"
                    size="small"
                  />
                  // <Input
                  //   value={items?.khoChiTiet?.ptckTruocVat}
                  //   onChange={ptChiecKhauVAT(items?.id)}
                  //   defaultValue={0}
                  //   className="p-0 text-center"
                  // />
                ),
                TIENCKTRUOCVAT: formattedNumber(Math.round(items?.khoChiTiet?.ckTruocVat)),

                THANHTIENTRVAT: (
                  <InputNumber
                    className="w-full"
                    size="small"
                    value={items?.khoChiTiet?.tongTienTruocVAT}
                    step="0.001"
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => priceTruocVAT(value, index - 1)}
                  />
                  // <Input
                  //   type="Number"
                  //   size="small"
                  //   onChange={(e) => priceTruocVAT(e, items?.id)}
                  //   value={items?.khoChiTiet?.tongTienTruocVAT}
                  // />
                ),
                VAT: (
                  <Select
                    onChange={handleVAT(index - 1)}
                    className="w-full  h-[22px]"
                    value={items?.khoChiTiet?.ptVAT}
                    options={[
                      { label: '0%', value: 0 },
                      { label: '5%', value: 0.05 },
                      { label: '8%', value: 0.08 },
                      { label: '10%', value: 0.1 },
                    ]}
                  />
                ),
                TIENVAT: (
                  <InputNumber
                    onFocus={() => {
                      setIsFocused(true)
                    }}
                    onBlur={() => {
                      setIsFocused(false)
                    }}
                    step="0.001"
                    size="small"
                    value={
                      !isFocused
                        ? Math.round(items?.khoChiTiet?.tienVAT)
                        : items?.khoChiTiet?.tienVAT
                    }
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => onChangePriceVAT(value, index - 1)}
                  />
                ),
                THANHTIEN: formattedNumber(items?.khoChiTiet?.thanhTien),
                THUCTRA: (
                  <InputNumber
                    size="small"
                    className="w-full "
                    value={Math.round(items?.khoChiTiet?.thucTra)}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => changePriceThucTra(value, index - 1)}
                  />
                  // <Input
                  //   onChange={(e) => changePriceThucTra(e, items?.id)}
                  //   size="small"
                  //   value={items?.khoChiTiet?.thucTra}
                  // />
                ),
                NHIETDO: (
                  <Input
                    size="small"
                    onChange={(e) => Changenhietdo(e.target.value, index - 1)}
                    value={items?.khoChiTiet?.nhietDo}
                  />
                ),
                MASODANGKY: (
                  // <Input
                  //   onChange={(e) => Changemsdk(e, items?.id)}
                  //   size="small"
                  //   value={items?.khoChiTiet?.maSoDangKy}
                  // />
                  <InputMSDK items={items} idx={index - 1} infoCN={infoCN} />
                ),
                SOLO: (
                  <Input
                    value={items?.khoChiTiet?.soLo}
                    onChange={hanldChangeSoLo(index - 1)}
                    className="p-0 text-center"
                  />
                ),
                HANDUNG: (
                  <DatePicker
                    placeholder=" "
                    size="small"
                    onChange={(date, dateString) => {
                      handleHanDung(index - 1, dateString)
                    }}
                    value={
                      items?.khoChiTiet?.hanDung !== '' && items?.khoChiTiet?.hanDung !== null
                        ? dayjs(items?.khoChiTiet?.hanDung, 'YYYY-MM-DD')
                        : ''
                    }
                    allowClear={false}
                    disabledDate={(current) => current && current < today}
                    format={'DD/MM/YYYY'}
                  />
                ),
                GHICHU: (
                  <Input
                    onChange={(e) => changeGhichu(e.target.value, index - 1)}
                    size="small"
                    value={items?.khoChiTiet?.GHICHU}
                  />
                ),
                ACTION: (
                  <div className="flex justify-center items-center gap-1">
                    <PlusOutlined
                      className="text-white bg-green-500 p-1 rounded-md cursor-pointer hover:bg-green-400"
                      onClick={() => addInfoThuocById(items?.IDTHUOC, index - 1)}
                    />
                    {infoCN?.list?.filter((ct) => ct?.IDTHUOC === items?.IDTHUOC)?.length >= 2 && (
                      <CloseOutlined
                        className="text-white bg-red-500 p-1 rounded-md cursor-pointer hover:bg-red-400"
                        onClick={() => deleteInfoThuocById(index - 1)}
                      />
                    )}
                  </div>
                ),
              })),
            ],
            [infoCN],
          )}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {columns?.map((item) =>
                  item?.children ? (
                    item?.children?.map((itemChild) => (
                      <Table.Summary.Cell
                        key={itemChild?.key}
                        index={itemChild?.key}
                        className={
                          ['4.1', '4.2', '4.3', '4.5', '4.6', '4.8', '4.9', '4.10'].includes(
                            itemChild?.key,
                          ) && 'text-end'
                        }
                      >
                        {itemChild?.key === '4.1' && //Tổng tiền
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) =>
                                (total +=
                                  (items?.khoChiTiet?.soLuong * items?.QUYCACHDONGGOI +
                                    items?.khoChiTiet?.countLe) *
                                  items?.khoChiTiet?.donGiaMua),
                              0,
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.2' && //P. Gia công
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) => (total += items?.khoChiTiet?.phiGiaCong),
                              0,
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.3' && //P. Vận chuyển
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) => (total += items?.khoChiTiet?.phiVanChuyen),
                              0,
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.5' && //Tiền CK trước VAT
                          formattedNumber(
                            Math.round(
                              infoCN?.list?.reduce(
                                (total, items) => (total += items?.khoChiTiet?.ckTruocVat),
                                0,
                              ),
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.6' && //Thành tiền (trước vat)
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) => (total += items?.khoChiTiet?.tongTienTruocVAT),
                              0,
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.8' && //Tiền %VAT
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) => (total += items?.khoChiTiet?.tienVAT),
                              0,
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.9' && //Thành tiền (sau vat)
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) => (total += items?.khoChiTiet?.thanhTien),
                              0,
                            ) ?? 0,
                          )}
                        {itemChild?.key === '4.10' && //Thực trả
                          formattedNumber(
                            infoCN?.list?.reduce(
                              (total, items) => (total += items?.khoChiTiet?.thucTra),
                              0,
                            ) ?? 0,
                          )}
                      </Table.Summary.Cell>
                    ))
                  ) : (
                    <Table.Summary.Cell index={item?.key}></Table.Summary.Cell>
                  ),
                )}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
})

export default TableChiTiet
