import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listBN: null,
  listTemplate: null,
}

const smsReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_BN_SMS:
        draft.listBN = payload
        break
      case typeAction.DISPATCH_LIST_TEMPLATE_SMS:
        draft.listTemplate = payload
        break
      default:
        return state
    }
  })
}

export default smsReducer
