import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNhanVienBaoHong, deleteNhanVienDetail } from '../../../../store/actions/suCoAction'

const DeletStaffTable = ({ open, setOpen,staff, fetchData }) => {
    const { infoUser } = useSelector((state) => state.userReducer)
    const [reason, setReason] = useState(null)
    const dispatch = useDispatch()
    const handlecancel = () => {
        setOpen(false)
    }
    const reset = () => {
        setOpen(false)
      setReason(null)
      fetchData()
    }
    const handlDelete = () => {
      const form = {
        idnvbh: staff?.idnvbh,
        idbaohong: staff?.idbaohong,
        lydo: reason,
        nguoitao: infoUser?.idnv,
      }
      dispatch(deleteNhanVienBaoHong(form, reset))
    }
  return (
    <Modal
    onOk={handlDelete}
    okText="Xoá"
    cancelText="Huỷ"
    title={
      <>
        <ExclamationCircleFilled style={{ color: 'orange' }} /> Bạn có chắc muốn xoá người thực
        hiện ?
      </>
    }
    open={open}
    onCancel={handlecancel}
    okButtonProps={{
      disabled: !reason,
    }}
  >
    <p className="font-semibold mb-2">Kĩ thuật: {staff?.tennhanvien} </p>
    <Input placeholder="Nhập lý do" value={reason} onChange={(e) => setReason(e.target.value)} />
  </Modal>
  )
}

export default DeletStaffTable