import { Input } from 'antd'

import React from 'react'

const CustomInput = ({ formik, show, ...props }) => {
  return (
    <>
      <label htmlFor={props.id} className="font-semibold text-gray-500 w-1/3">
        {props.label}
      </label>
      <Input
        className="w-2/3"
        {...props}
        value={show ? formik.values[props.name] : props.defaultValue}
        variant={show ? 'outlined' : 'borderless'}
        onChange={formik.handleChange}
      />
    </>
  )
}

export default CustomInput
