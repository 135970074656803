import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
const { TextArea } = Input

const ModalAdd = ({ isOpenAdd, setIsOpenAdd }) => {
  const handleSubmit = (value, action) => {
    action.resetForm()
    setIsOpenAdd(false)
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: (value, action) => {
      handleSubmit(value, action)
    },
  })

  return (
    <Modal
      open={isOpenAdd}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      onCancel={() => setIsOpenAdd(false)}
      title={<h2 className="text-blue-500 text-xl text-center">Tạo SMS</h2>}
    >
      <form className="text-start">
        <div>
          <label className="font-semibold  text-base">Mã</label>
          <Input
            value={formik.values.DIENTHOAILIENHE}
            name="DIENTHOAILIENHE"
            onChangeCapture={formik.handleChange}
            status={formik.errors.DIENTHOAILIENHE && 'error'}
          />
        </div>
        <div>
          <label className="font-semibold  text-base">Tên</label>
          <Input
            value={formik.values.DIENTHOAILIENHE}
            name="DIENTHOAILIENHE"
            onChangeCapture={formik.handleChange}
            status={formik.errors.DIENTHOAILIENHE && 'error'}
          />
        </div>
        <div>
          <label className="font-semibold  text-base">Nội dung</label>
          <TextArea
            style={{ height: 200, resize: 'none' }}
            rows={2}
          />
        </div>
      </form>
    </Modal>
  )
}

export default ModalAdd
