import { Input } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const InfoPhieu = ({ formik }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBNSieuAm, history } = useSelector((state) => state.sieuAmReducer)
  return (
    <div>
      <h2 className="border-y text-blue-500 font-semibold ml-1 p-2">Thông tin phiếu siêu âm </h2>
      <div className="flex flex-col gap-2 p-2">
        <div className="flex">
          <label className="w-24 font-semibold">Ngày SA:</label>
          <Input
            value={
              infoBNSieuAm
                ? history?.length > 0
                  ? history[0]?.ngaysieuam &&
                    moment(history[0]?.ngaysieuam).format('DD/MM/YYYY HH:mm:ss')
                  : today.format('DD/MM/YYYY HH:mm:ss')
                : ''
            }
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">BS Chỉ định:</label>
          <Input
            size="small"
            value={formik.values.BSCHIDINH?.replace('null', '')}
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">BS Siêu âm:</label>
          <Input
            value={
              infoBNSieuAm ? (history?.length > 0 ? history[0]?.bssieuam : infoUser?.tenNV) : ''
            }
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">CĐ sơ bộ:</label>
          <Input
            size="small"
            value={formik.values.CHANDOANSOBO?.replace('null', '')}
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">Loại SA:</label>
          <Input size="small" value={formik.values.LOAISIEUAM} variant="filled" readOnly />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">Para:</label>
          <Input
            // status={formik.errors.para ? 'error' : ''}
            onChange={formik.handleChange}
            value={formik.values.PARA}
            name="PARA"
            // type="Number"
            size="small"
          />
        </div>
        {/* <div className="flex">
          <label className="w-24 font-semibold">Biểu mẫu:</label>
          <Input size="small" variant="filled" readOnly />
        </div> */}
      </div>
    </div>
  )
}

export default InfoPhieu
