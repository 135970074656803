import * as typeAction from '../constants/constants'
export const customerByKeyword = (keyword, idct, setIsLoading) => ({
  type: typeAction.GET_CUSTOMER_BY_KEYWORD,
  keyword,
  idct,
  setIsLoading,
})
// --------- tạo hồ hơ  khách háng  --------//
export const addCustomer = (form, cancelModal) => ({
  type: typeAction.ADD_NEW_CUSTOMER,
  form,
  cancelModal,
})

export const getCustomerByIdct = (idct, pageIndex) => ({
  type: typeAction.GET_CUSTOMER_BY_IDCT,
  idct,
  pageIndex,
})
